import React, { useState } from 'react';
import { Body, Card, Button } from '@walmart-web/livingdesign-components';
import CustomLabel from '../CustomLabel';
import storeWithPin from '../../../../assets/images/storeWithPin.svg';
import lens from '../../../../assets/images/leadingIcon.svg';
import { useTranslation } from 'react-i18next';
import LocSearchContainer from '../../../Location/components/LocationSearch/LocSearchContainer';
import './style.scss';

const JobSearch = () => {
	const [isOpen, setIsOpen] = useState(false);

	const { t } = useTranslation();

	const handleJobSearchClick = () => {
		setIsOpen(true);
	};

	return (
		<>
			<Card size="medium" UNSAFE_className="jobsearch_card">
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						padding: '16px'
					}}>
					<CustomLabel icon={lens} title={t('jobTracker.keepSearching')} />
					<Body as="span" size="small" UNSAFE_className="jobsearch_info">
						{t('jobTracker.wantToApplyMore')}
					</Body>
					<Button
						size="medium"
						UNSAFE_className="jobsearch_button"
						variant="primary"
						onClick={handleJobSearchClick}>
						{t('landingPage.jobSearchTitle')}
					</Button>
				</div>
				<img src={storeWithPin} alt="walmart store location" width={150} height={150} />
			</Card>
			<LocSearchContainer isOpen={isOpen} setIsOpen={setIsOpen} />
		</>
	);
};

export default JobSearch;
