import React, { useEffect } from 'react';
import style from './SelfSchedule.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ThankYouScreen from './components/ThankYouScreen';
import { t } from 'i18next';
import { useSelfSchedule } from './hooks/useSelfSchedule';
import HRAvailability from './components/HRAvailability';
import { Body, Button, Display } from '@walmart-web/livingdesign-components';
import { loadData } from 'redux/SelfSchedule/request';
import { useMediaQuery } from 'react-responsive';
import HRAvailabilitySC from './components/HRAvailabilitySC';
import SelfScheduleTourFooter from './components/SelfScheduleTourFooter';
import { ReactComponent as Clock } from '@livingdesign/pictograms/dist/svg/Home/Clock.svg';
import { selfScheduleEventCodeMap } from 'config/const';

const Icon = require('@livingdesign/icons');

const SelfSchedule = () => {
	const { encId } = useParams();
	const selfSchedule = useSelector((state) => state.selfSchedule);
	const global = useSelector((state) => state.global);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isMobile = useMediaQuery({ maxWidth: 425 });

	const {
		displaySeeMore,
		interviewConfirmed,
		introText,
		subText,
		isSelfScheduleSC,
		storeAddress,
		handleToggleSeeMore,
		setDisplaySeeMore,
		setInterviewConfirmed,
		setStatesForSH,
		setStatesForSC
	} = useSelfSchedule();

	useEffect(() => {
		if (global && global.error.message) {
			navigate('../error');
		}
	}, [global, global.error.message]);

	useEffect(() => {
		if (!selfSchedule.dataLoaded) {
			dispatch(loadData(encId));
		}
	}, [encId]);

	useEffect(() => {
		setDisplaySeeMore(!isSelfScheduleSC && selfSchedule.displaySeeMore);
	}, [selfSchedule.displaySeeMore, isSelfScheduleSC]);

	useEffect(() => {
		setInterviewConfirmed(selfSchedule.interviewConfirmed);
	}, [selfSchedule.interviewConfirmed]);

	useEffect(() => {
		if (selfSchedule.dataLoaded) {
			if (
				selfSchedule.eventCode === selfScheduleEventCodeMap.SC_INTERVIEW_EVENT_CODE ||
				selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_2 ||
				selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_1 ||
				selfSchedule.eventCode === selfScheduleEventCodeMap.SC_RJPT_EVENT_CODE ||
				selfSchedule.eventCode === selfScheduleEventCodeMap.SC_FT_EVENT_CODE
			) {
				setStatesForSC();
			} else {
				setStatesForSH();
			}
		}
	}, [selfSchedule.dataLoaded]);

	if (interviewConfirmed) {
		return <ThankYouScreen />;
	}
	if (!selfSchedule.dataLoaded) {
		return <></>;
	}

	return (
		<div className={style.Container}>
			<div>
				<div className={style.Self_Schedule}>
					<Display UNSAFE_className={style.Intro_Text} as="h1" size="medium" weight={400}>
						{t(introText)}
					</Display>
					{isMobile && isSelfScheduleSC ? null : (
						<Body as="p" size="medium" weight={400} UNSAFE_className={style.Intro_Subtext}>
							{`${t(subText.split(',')[0])} ${t(subText.split(',')[1])}`}
						</Body>
					)}
					<div>
						<div className={style.slot_time_n_address}>
							<div className={style.slot_time}>
								<div style={{ display: 'flex' }}>
									<Clock style={{ width: '30px', height: '30px' }} />
								</div>
								&nbsp;
								<div>
									<div>{t(selfSchedule.duration)}</div>
									{isSelfScheduleSC && (
										<div className={style.duration_subtext}>{t('selfSchedule.dependent')}</div>
									)}
								</div>
							</div>
							<div className={style.address}>
								<div>
									<Icon.Location size="medium" />
								</div>
								&nbsp;
								<div>
									{selfSchedule.eventCode === selfScheduleEventCodeMap.SH_PRESCREEN_EVENT_CODE
										? `${t(storeAddress.split(',')[0])} ${storeAddress.split(',')[1]}`
										: storeAddress}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={style.availabilityContainer}>
				{isSelfScheduleSC ? <HRAvailabilitySC /> : <HRAvailability />}
			</div>
			<div className={style.footer_content}>
				<div>
					{displaySeeMore ? (
						<div className={style.footer_button}>
							<Button
								size="medium"
								variant="primary"
								style={{ backgroundColor: 'none' }}
								onClick={handleToggleSeeMore}>
								{selfSchedule.seeMore ? (
									<>{t('selfSchedule.seeMore')}</>
								) : (
									<>{t('selfSchedule.seeLess')}</>
								)}
							</Button>
						</div>
					) : (
						''
					)}
				</div>
				<div>
					{isSelfScheduleSC ? (
						<SelfScheduleTourFooter />
					) : (
						<Body as="p" size="medium" weight={400} UNSAFE_className={style.footer_text}>
							{t('selfSchedule.noDatesToChoose')}
						</Body>
					)}
				</div>
			</div>
		</div>
	);
};

export default SelfSchedule;
