import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequestErrorOccured } from 'redux/slices/globalSlice';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';

const getPreference = createAsyncThunk('preference/getPreference', async (applicantId, { dispatch }) => {
	try {
		const baseURL = environment.eaPreferenceServiceBaseGetUrl + `/preference?applicantId=${applicantId}`;
		const response = await axiosInstance.get(baseURL, applicantId);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});

const savePreference = createAsyncThunk('preference/savePreference', async (prefRequest, { dispatch }) => {
	try {
		const baseURL = environment.eaPreferenceServiceBasePostUrl + '/preference';
		const response = await axiosInstance.post(baseURL, prefRequest);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});

export { savePreference, getPreference };
