import React, { useState } from 'react';
import { Store, Truck } from '@livingdesign/icons';
import ShiftFilterItem from './ShiftFilterItem';
import styles from './ShiftFilter.module.css';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Body, Tag } from '@walmart-web/livingdesign-components';

const shiftsOfStores = [
	{ title: 'opening', startFrom: '4:00am', startTo: '7:00am', id: 10 },
	{ title: 'morning', startFrom: '7:00am', startTo: '9:00am', id: 11 },
	{ title: 'midShift', startFrom: '8:00am', startTo: '11:00am', id: 12 },
	{ title: 'closing', startFrom: '1:00pm', startTo: '4:00pm', id: 13 },
	{ title: 'overnight', startFrom: '8:00pm', startTo: '11:00pm', id: 14 }
];

const shiftsOfUsWarehouses = [
	{ title: 'wd1', startFrom: '3:00am', startTo: '10:30am', id: 1 },
	{ title: 'wd2', startFrom: '11:00am', startTo: '5:30pm', id: 2 },
	{ title: 'wd3', startFrom: '6:00pm', startTo: '2:30am', id: 3 },
	{ title: 'we1', startFrom: '3:00am', startTo: '10:30am', id: 4 },
	{ title: 'we2', startFrom: '11:00am', startTo: '5:30pm', id: 5 },
	{ title: 'we3', startFrom: '6:00pm', startTo: '2:30am', id: 6 }
];

const shiftsOfPrWarehouses = [
	{ title: 'shift1', startFrom: '3:00am', startTo: '10:00am', id: 7 },
	{ title: 'shift2', startFrom: '10:30am', startTo: '6:00pm', id: 8 },
	{ title: 'shift3', startFrom: '6:30pm', startTo: '2:30am', id: 9 }
];

const storeShiftOptions = [
	{
		facilityType: 'stores',
		icon: <Store />,
		options: shiftsOfStores
	}
];

const usScShiftOptions = [
	{
		facilityType: 'warehouses',
		icon: <Truck />,
		options: shiftsOfUsWarehouses
	}
];
const prScShiftOptions = [
	{
		facilityType: 'warehouses',
		icon: <Truck />,
		options: shiftsOfPrWarehouses
	}
];

const ShiftFilterNav = (props) => {
	const [tab, setTab] = useState('US');
	const { t } = useTranslation();

	const getFacilityType = (i) => {
		return i.workgroupId < 5000 ? 'store' : 'warehouse';
	};

	const getFacilityList = () => {
		let warehouseJobs = 0;
		let storeJobs = 0;
		props.jobs.map((index) => {
			if (getFacilityType(index) === 'store') {
				storeJobs++;
			} else {
				warehouseJobs++;
			}
		});
		return { store: storeJobs, warehouse: warehouseJobs };
	};

	const warehouseJobs = getFacilityList().warehouse;
	const storeJobs = getFacilityList().store;

	const countCountrySpecificWarehouseJobsPreferred = () => {
		let usWarehouseJobs = 0;
		let prWareHouseJobs = 0;
		props.jobs.forEach((index) => {
			if (getFacilityType(index) === 'warehouse') {
				if (index.countryCode != null && index.countryCode === 'US') {
					usWarehouseJobs++;
				} else if (index.countryCode != null && index.countryCode === 'PR') {
					prWareHouseJobs++;
				}
			}
		});
		return { usWarehouseJobs: usWarehouseJobs, prWareHouseJobs: prWareHouseJobs };
	};

	const usWarehouseJobs = countCountrySpecificWarehouseJobsPreferred().usWarehouseJobs;
	const prWareHouseJobs = countCountrySpecificWarehouseJobsPreferred().prWareHouseJobs;

	const filterStore = useSelector((state) => state.jobSearch);

	const showShiftCondition = (option) => {
		return (
			(option.facilityType === 'stores' && storeJobs <= 0) ||
			(option.facilityType === 'stores' &&
				!filterStore.filters.shift.some((i) => i >= 9) &&
				filterStore.filters.facility === 2) ||
			(option.facilityType === 'warehouses' && warehouseJobs <= 0) ||
			(option.facilityType === 'warehouses' &&
				!filterStore.filters.shift.some((i) => i < 9) &&
				filterStore.filters.facility === 1)
		);
	};

	const getFilterType = () => {
		if (usWarehouseJobs > 0 && prWareHouseJobs > 0) {
			return 3; // navigation US PR will be shown only if jobs has Warehouse preferred in both US and PR
		}
		if (usWarehouseJobs > 0) {
			return 1;
		}
		if (prWareHouseJobs > 0) {
			return 2;
		}
		return 0;
	};
	const filterType = getFilterType();

	return (
		<div>
			{/* Common Shifts for US & PR Stores */}
			{storeJobs > 0 && (
				<ShiftFilterItem
					showShiftCondition={showShiftCondition}
					addShift={props.addShift}
					jobs={props.jobs}
					shiftOptions={storeShiftOptions}
				/>
			)}

			{/* SC Shifts are different for US & PR */}
			{warehouseJobs > 0 && (
				<Tag
					size="medium"
					color="purple"
					variant="secondary"
					leading={<Truck />}
					UNSAFE_style={{ marginBottom: '16px' }}>
					{t('jobFilter.warehouses')}
				</Tag>
			)}
			{filterType === 3 && (
				<div className={styles.switchTabsContainer}>
					<div className={styles.switchTabs}>
						<Body
							as="div"
							size="small"
							UNSAFE_className={`${styles.switchTabs__tab} ${
								tab === 'US' ? styles.switchTabs__tab_activate : ''
							}`}
							UNSAFE_style={{ color: tab === 'US' ? '#FFFFFF' : '#2E2F32' }}
							onClick={() => setTab('US')}>
							{t('general.usCountryName')}
						</Body>
						<Body
							as="div"
							size="small"
							UNSAFE_className={`${styles.switchTabs__tab} ${
								tab === 'PR' ? styles.switchTabs__tab_activate : ''
							}`}
							UNSAFE_style={{ color: tab === 'PR' ? '#FFFFFF' : '#2E2F32' }}
							onClick={() => setTab('PR')}>
							{t('general.prCountryName')}
						</Body>
					</div>
				</div>
			)}
			{((filterType === 3 && tab === 'US') || filterType === 1) && (
				<ShiftFilterItem
					showShiftCondition={showShiftCondition}
					addShift={props.addShift}
					jobs={props.jobs}
					shiftOptions={usScShiftOptions}
				/>
			)}
			{((filterType === 3 && tab === 'PR') || filterType === 2) && (
				<ShiftFilterItem
					showShiftCondition={showShiftCondition}
					addShift={props.addShift}
					jobs={props.jobs}
					shiftOptions={prScShiftOptions}
				/>
			)}
		</div>
	);
};

export default ShiftFilterNav;
