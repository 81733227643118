import { Card, Heading, Body, Divider, Checkbox } from '@walmart-web/livingdesign-components';
import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Trans, useTranslation } from 'react-i18next';

/**
 * Renders the Step6: Background Check for the Portarait and Desktop views.
 */

const BackgroundCheck = (props) => {
	const { t } = useTranslation();

	const [checkConfirmBox, setCheckConfirmBox] = useState(false);

	const handleCheckConfirmBox = (e) => {
		setCheckConfirmBox(!checkConfirmBox);
	};

	useEffect(() => {
		window.scroll(0, 0);
	 },[])

	useEffect(() => {
		if (checkConfirmBox) {
			props.handleChangeCurrentStepStatus(true);
		} else {
			props.handleChangeCurrentStepStatus(false);
		}
	}, [checkConfirmBox]);

	return (
		<Card UNSAFE_className="background_check_card_container">
			<Heading children={t('vjoBackgroundCheck.background_check')} size="small" />
			<Body
				as="p"
				children={t('vjoBackgroundCheck.complete_steps')}
				size="medium"
				UNSAFE_className="background_check_complete_steps"
			/>
			<Body
				as="p"
				children={<Trans>{t('vjoBackgroundCheck.steps')}</Trans>}
				size="medium"
				UNSAFE_className="background_check_steps"
			/>
			<Divider />
			<Checkbox
				label={t('vjoBackgroundCheck.confirm_details')}
				onChange={(e) => handleCheckConfirmBox(e)}
				checked={checkConfirmBox}
				UNSAFE_className="info_correct"
			/>
		</Card>
	);
};

export default BackgroundCheck;
