import { createSlice } from '@reduxjs/toolkit';
import { callHiringHelper } from './request';

const initialState = { triggerHH: false, error: {} };

const hiringHelperSlice = createSlice({
	name: 'hiringHelper',
	initialState,
	reducers: {
		setTriggerHH: (state, action) => {
			state.triggerHH = action.payload;
		}
	},
	extraReducers(builder) {
		builder
			.addCase(callHiringHelper.fulfilled, (state, action) => {
				state.triggerHH = false;
			})
			.addCase(callHiringHelper.rejected, (state, action) => {
				state.error = { message: 'Something went wrong' };
				state.triggerHH = false;
			});
	}
});

export const { setTriggerHH } = hiringHelperSlice.actions;

export default hiringHelperSlice.reducer;

