import React, { useEffect } from 'react';
import './style.module.css';
import { useSelector } from 'react-redux';
import AvailMainComp from 'pages/Availability/AvailMainComp/AvailMainComp';
import { AvailabilityHelper } from '../../../Availability/AvailabilityHelper';

const ShiftAvailability = (props) => {
	const { isRenewPanelClicked } = useSelector((state) => state.renew);
	const availStore = useSelector((state) => state.availMain);
	const { validateRenewAvailability } = AvailabilityHelper();

	useEffect(() => {
		validateAvailabilityPanel();
		// eslint-disable-next-line
	}, [props.expanded]);

	useEffect(() => {
		validateAvailabilityPanel();
		// eslint-disable-next-line
	}, [availStore.employmentType, availStore.shiftPreference, availStore.scheduleAvailability]);

	const validateAvailabilityPanel = () => {
		if (isRenewPanelClicked.panel2 === true && props.expanded === 'panel2') {
			validateRenewAvailability();
		}
	};

	return (
		<div className="renew_shift_availability_wrapper">
			<AvailMainComp isRenew={true} />
		</div>
	);
};

export default ShiftAvailability;
