import {createSlice} from '@reduxjs/toolkit';
import {getScheduleCompleted, initiateCandidateDrugScreen, isDrugScreenRequired} from './request';

const initialState = {data: {}, loading: false, error: {}};

const vjoDrugScreenSlice = createSlice({
    name: 'vjoDrugScreen',
    initialState,
    reducers: {
      callGetVjoDsEndpoint : (state, action) => {
        state.data.shouldCallGetVjoDsEndpoint = action.payload;
      },
      refreshDrugScreenCheck : (state) => {
        state.data.drugScreenCheck = null;
      },
    },
    extraReducers(builder) {
        builder
          .addCase(getScheduleCompleted.fulfilled, (state, action) => {
            state.data.scheduleCompleted = action.payload;
          })
          .addCase(initiateCandidateDrugScreen.fulfilled, (state, action) => {
            state.data.drugScreenCheck = action.payload;
          })
          .addCase(isDrugScreenRequired.fulfilled, (state, action) => {
            state.data.isDrugScreenRequiredResponse = action.payload;
          })
          .addCase(getScheduleCompleted.rejected, (state) => {
            state.error = {message: 'Something went wrong'};
          })
          .addCase(initiateCandidateDrugScreen.rejected, (state) => {
            state.error = {message: 'Something went wrong'};
          })
          .addCase(isDrugScreenRequired.rejected, (state) => {
            state.error = {message: 'Something went wrong'}
          });
    }
});

export const {callGetVjoDsEndpoint, refreshDrugScreenCheck} = vjoDrugScreenSlice.actions;

export default vjoDrugScreenSlice.reducer;