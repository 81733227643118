import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequestErrorOccured } from "redux/slices/globalSlice";
import axiosInstance from "../../utils/axiosInstance";
import { environment } from "../../utils/environment/environment";

const checkRegisteredUser = createAsyncThunk(
    "accounts/checkRegisteredUser",
    async (payload, { dispatch }) => {
        try {
            const checkRegisteredUserUrl =
                environment.hcServiceBasePostUrl + "/checkRegisteredUser";
            const response = await axiosInstance.post(
                checkRegisteredUserUrl,
                payload
            );
            return response.data;
        } catch (error) {
            dispatch(apiRequestErrorOccured(error));
        }
    }
);

const getStepInd = createAsyncThunk("accounts/getStepInd", async (payload, { dispatch }) => {
    try {
        const getStepIndurl = environment.hcServiceBasePostUrl + "/getStepInd";
        const response = await axiosInstance.post(getStepIndurl, payload);
        return response.data;
    } catch (error) {
        dispatch(apiRequestErrorOccured(error));
    }
});

const createAccount = createAsyncThunk("accounts/createAccount", async (payload, { dispatch }) => {
  try {
	const createAccountUrl =
	environment.envName === 'dev' && window.location.hostname === 'localhost'
		? environment.hcServiceBasePostUrl + '/createLogin'
		: '/HiringCenter/createAccount';
      const response = await axiosInstance.post(createAccountUrl, payload);
      return response.data;
  } catch (error) {
    dispatch(apiRequestErrorOccured(error));
  }
});

const forgotPassword = createAsyncThunk('accounts/forgotPassword', async (payload, { dispatch }) => {
	try {
		const forgotPasswordUrl = environment.hcServiceBasePostUrl + '/forgotPassword';
		const response = await axiosInstance.post(forgotPasswordUrl, payload);
		return response.data;
	} catch (error) {
        dispatch(apiRequestErrorOccured(error));
	}
});

const resetPassword = createAsyncThunk('accounts/resetPassword', async (payload, { dispatch }) => {
	try {
		const resetPasswordUrl = environment.hcServiceBasePostUrl + '/resetPassword';
		const response = await axiosInstance.post(resetPasswordUrl, payload);
		return response.data;
	} catch (error) {
        dispatch(apiRequestErrorOccured(error));
	}
});

const validateResetPasswordLink = createAsyncThunk('accounts/validateResetPasswordLink', async (payload, { dispatch }) => {
	try {
		const validateResetPasswordLinkUrl = environment.hcServiceBasePostUrl + '/validateResetPasswordLink';
		const response = await axiosInstance.post(validateResetPasswordLinkUrl, payload);
		return response.data;
	} catch (error) {
        dispatch(apiRequestErrorOccured(error));
	}
});

const saveHcApplicationStatus = createAsyncThunk('jobHistoy/saveHcApplicationStatus', async (request, { dispatch }) => {
	try {
		const baseUrl = environment.hcServiceBasePostUrl + '/saveHcApplicationStatus'
		const response = await axiosInstance.post(baseUrl, request);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});

export { checkRegisteredUser, getStepInd, createAccount, forgotPassword, resetPassword, validateResetPasswordLink, saveHcApplicationStatus };
