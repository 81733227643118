import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDocuSignUrl, checkDocuSignResponse } from 'redux/Review/request';

/**
 * Custom hook to consume the logics for VJO step indicators
 * @returns getReviewDocuSignUrl - callback to get the DocSign Url
 * @returns checkReviewDocuSignResponse - callback to get the DocSign response
 */
export const useReviewDocuSign = () => {
	const dispatch = useDispatch();

	const account = useSelector((state) => state.account);
	const docuSignInfo = useSelector((state) => state?.Review?.data?.docuSignInfo);
	const docuSignResp = useSelector((state) => state?.Review?.data?.docuSignResp);

	const getReviewDocuSignUrl = useCallback(() => {
		if (account){
			const docuSignRequest = {
				storeNbr: account?.data?.auth?.vjo?.storeNbr,
				isSupplyChain: account?.data?.auth?.supplyChain,
				requestInput: {
					applicantId: account?.data?.auth?.vjo?.applicantId,
					requisitionId: account?.data?.auth?.vjo?.reqsnId,
					jobOfferNbr: account?.data?.auth?.vjo?.jobOfferNbr,
					virtualJobOfferFlag: true,
					returnUrl: window.origin
				}
			};
			dispatch(getDocuSignUrl(docuSignRequest));
		}
	}, [account, dispatch]);

	const checkReviewDocuSignResponse = useCallback(() => {
		if(account){
			const docuSignRequest = {
				storeNbr: account?.data?.auth?.vjo?.storeNbr,
				envelopeId: account?.data?.auth?.vjo?.envId,
				jobOfferNbr: account?.data?.auth?.vjo?.jobOfferNbr
 			};
			dispatch(checkDocuSignResponse(docuSignRequest));
		}
	}, [account, dispatch]);

	return {
		getReviewDocuSignUrl,
		checkReviewDocuSignResponse,
		docuSignInfo,
		docuSignResp,
	};
};

