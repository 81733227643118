import React from 'react';
import style from '../Assessment.module.css';
import { t } from 'i18next';
import useAssessment from '../hooks/useAssessment';
import { Alert, Button, Tag } from '@walmart-web/livingdesign-components';
import moment from 'moment';

const Icon = require('@livingdesign/icons');

const AssessmentItem = (props) => {
	const { handleTakeAssessment } = useAssessment();
	const isAssmtFailed = props.statusVal === 3;
	const isAssmtPassed = props.statusVal === 4;

	const asmtActionContainer = () => {
		if (props.statusVal === 4) {
			return (
				<div className={style.assessment_complete} >
					<Tag color='green' leading={<Icon.Check />} variant='tertiary' >
						{t('assessment.completed')}
					</Tag>
				</div>
			);
		} else if (props.statusVal === 1 || props.statusVal === 2) {
			return <Button
				variant="primary"
				onClick={() => handleTakeAssessment(props.assessmentCode)}

				aria-label={t(`assessment.${props.assessmentCode}`)}
				role="link">
				{t('assessment.takeAssessment')}
			</Button>;
		} else {
			return (
				<div className={style.assessment_complete} >
					<Tag color='red' leading={<Icon.Close />} variant='tertiary' >
						{t('assessment.failed')}
					</Tag>
				</div>
			);
		}
	};
	return (
		<>
			<div className={style.assessment_item}>
				<div className={style.left}>
					<div className={style.assessment_title}>{t(`assessment.${props.assessmentCode}`)}</div>
					{isAssmtPassed ? (
						<div className={style.assessment_duration}>
							{`${t('assessment.activeAsmtRetakeAfter')} ${moment(
								props.assessmentExpdate,
								'YYYY-MM-DD'
							).format('MM/DD/YYYY')}`}
						</div>
					) : (
						!isAssmtFailed && (
							<div className={style.assessment_duration}>{t('assessment.estimatedTime')}</div>
						)
					)}
				</div>
				<div className={style.right}>
					{asmtActionContainer()}
				</div>
			</div>
			{isAssmtFailed && (
				<div className={style.assessment_failed}>
					<Alert variant="error">
						{`${t('assessment.assessmentRetake')} ${moment(
							props.assessmentExpdate,
							'YYYY-MM-DD'
						).format('MM/DD/YYYY')}`}
					</Alert>
				</div>
			)}
		</>
	);
};

export default AssessmentItem;
