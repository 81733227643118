import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import { GTM } from '../../../config/const';
import { sendGtmEvent } from '../../../utils/GoogleTagManagerUtils';
import { executionErrorOccured, updateLoading, wotcDownErrorOccured } from '../../../redux/slices/globalSlice';
import {
	updateQuestionnaireAnswer,
	updateQuestionnaireCompletedStatus,
	updateQuestionnaireLanguage,
	updateQuestionnaireZipCode,
	updateScreeningId,
	updateSubmissionScreenStatus,
	updateValidationError
} from 'redux/TaxQuestionnaire/slice';
import { getWOTCQuestions } from 'redux/TaxQuestionnaire/request';
import { getPersonalInfo } from 'redux/AboutYou/request';
import { updatePersonalInfo } from 'redux/AboutYou/slice';
import { setWotcCompleted, setWotcDownError } from 'redux/Renew/slice';

import {
	answerableWotcElementTypes,
	nextButtonTexts,
	wotcElementTypes,
	wotcLanguages
} from './util';

const useTaxQuestionnaire = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const global = useSelector((state) => state.global);
	const language = useSelector((state) => state.global?.language);
	const renew = useSelector((state) => state.renew);
	const questionnaireLanguage = useSelector((state) => state.questionnaire?.language);
	const questionnaireCompleted = useSelector(
		(state) => state.questionnaire?.questionnaireCompleted
	);
	const validationError = useSelector((state) => state.questionnaire?.validationError || {});
	const screeningId = useSelector((state) => state.questionnaire?.screeningId);
	const legalFirstName = useSelector(
		(state) => state.aboutYou?.applInfo?.firstName || state.aboutYou?.firstName
	);
	const legalLastName = useSelector(
		(state) => state.aboutYou?.applInfo?.lastName || state.aboutYou?.lastName
	);
	const zipCode = useSelector((state) => state.aboutYou?.applInfo?.zipCode);
	const questionnaireZipCode = useSelector((state) => state.questionnaire?.zipCode);
	const applicantId =
		global.applicantId && global.applicantId !== 0 ? global.applicantId : cookie.load('userId');
	const [taxQuestions, setTaxQuestions] = useState([]);
	const [activeTaxQuestionsIndex, setActiveTaxQuestionsIndex] = useState(0);
	const [isSubmitButtonState, setIsSubmitButtonState] = useState([]);
	const [taxQuestionsAnswer, setTaxQuestionsAnswer] = useState({});

	useEffect(() => {
		if (activeTaxQuestionsIndex >= 0 && activeTaxQuestionsIndex < isSubmitButtonState.length) {
			dispatch(updateSubmissionScreenStatus(isSubmitButtonState[activeTaxQuestionsIndex]));
		} else {
			dispatch(updateSubmissionScreenStatus(false));
		}
	}, [isSubmitButtonState, activeTaxQuestionsIndex]);

	const handleUpdatePersonalInfo = () => {
		const request = {
			applicantId,
			emailId: global.email ? global.email : cookie.load('emailId')
		};
		dispatch(updateLoading(true));
		dispatch(getPersonalInfo(request))
			.then((res) => {
				dispatch(updatePersonalInfo(res.payload));
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const checkQuestionnaireStatusAndRedirect = () => {
		if (questionnaireCompleted) {
			navigate('../minimum-qualifications');
		}
	};

	const handleDefaultValuesOnSubmit = (questionsIndex) => {
		const updatedTaxQuestionsAnswer = taxQuestionsAnswer;
		if (questionsIndex >= 0 && questionsIndex < taxQuestions.length) {
			taxQuestions[questionsIndex]?.forEach((question) => {
				if (
					!question.defaultValue &&
					!taxQuestionsAnswer[question.elementId] &&
					question.elementType === wotcElementTypes.multipleChoiceSingleAnswerInput
				) {
					question.answerOptions.forEach((answer) => {
						if (answer.value === 'No') {
							updatedTaxQuestionsAnswer[question.elementId] = {
								elementId: question.elementId,
								elementType: question.elementType,
								value: answer.value
							};
						}
					});
				}
			});
			setTaxQuestionsAnswer(updatedTaxQuestionsAnswer);
		}
		return updatedTaxQuestionsAnswer;
	};

	const fetchPreamble = () => {
		setTaxQuestions([]);
		setIsSubmitButtonState([]);
	};

	const getQuestionIdsWithErrorMsg = (questionsSet) => {
		const questionIdsWithErrorMsg = questionsSet.reduce((obj, { messageText, elementId }) => {
			if (messageText?.length) {
				obj[elementId] = messageText;
			}
			return obj;
		}, {});

		return questionIdsWithErrorMsg;
	};

	const checkResponseForError = (questionIdsWithErrorMsg) => {
		return Object.keys(questionIdsWithErrorMsg)?.length && taxQuestions?.length;
	};

	const handleUpdateTaxQuestions = (questionsSet, isSubmitButton) => {
		const questionIdsWithErrorMsg = getQuestionIdsWithErrorMsg(questionsSet);

		// If experian returns an error with same set of questions
		if (checkResponseForError(questionIdsWithErrorMsg)) {
			const updatedTaxQuestions = [...taxQuestions];
			if (activeTaxQuestionsIndex >= 0 && activeTaxQuestionsIndex < updatedTaxQuestions.length) {
				updatedTaxQuestions[activeTaxQuestionsIndex] = taxQuestions[activeTaxQuestionsIndex]?.map(
					(question) => {
						question.messageText = questionIdsWithErrorMsg[question.elementId];
						return question;
					}
				);
				setTaxQuestions(updatedTaxQuestions);
			}
		} else {
			setActiveTaxQuestionsIndex(taxQuestions.length);
			setTaxQuestions([...taxQuestions, questionsSet]);
			setIsSubmitButtonState([...isSubmitButtonState, isSubmitButton]);
		}
	};

	const getSelectedAnswers = () => {
		const currentQuestionsSet =
			activeTaxQuestionsIndex >= 0 && activeTaxQuestionsIndex < taxQuestions.length
				? taxQuestions[activeTaxQuestionsIndex]
				: [];
		const currentAnswerSet =
			currentQuestionsSet
				.map(({ elementId, elementType }) => ({
					elementId,
					elementType,
					value: taxQuestionsAnswer[elementId]?.value
				}))
				?.filter(({ elementType }) => answerableWotcElementTypes.includes(elementType)) || [];
		return currentAnswerSet;
	};

	const fetchQuestions = (screeningId, fromRenew) => {
		dispatch(updateLoading(true));
		const currentAnswerSet = getSelectedAnswers();
		const addressDetails = {
			postalCode : zipCode
		}
		const personalDetails = {
			legalFirstName,
			legalLastName,
			addressDetails
		}
		const applicantInformation = {
			applicantId,
			personalDetails
		}
		const localeOptions = {
			defaultLocale: wotcLanguages[language] || wotcLanguages['EN']
		}
		const options = {
			localeOptions
		}
		dispatch(
			getWOTCQuestions({
				applicantInformation,
				options,
				wotcQuestions: {
					screeningId,
					inputs: currentAnswerSet
				}
			})
		)
			.then((res) => {
				dispatch(updateLoading(false));
				if(res.error){
					if(renew.isRenewPanelClicked['panel4']){
						dispatch(setWotcDownError(true));
					} else {
						dispatch(wotcDownErrorOccured(res.error));
					}
				}
				if (res.payload?.screeningId) {
					dispatch(updateScreeningId(res.payload.screeningId));
				}

				let isSubmitButton = false;
				if (res.payload?.nextButtonText && typeof res.payload.nextButtonText === 'string') {
					if (nextButtonTexts.submit.includes(res.payload.nextButtonText.toLowerCase())) {
						isSubmitButton = true;
					} else {
						isSubmitButton = false;
					}
				}
				if (res.payload?.elements && !res.payload?.complete) {
					handleUpdateTaxQuestions(res.payload.elements, isSubmitButton);
				} else if (res.payload?.complete) {
					dispatch(updateQuestionnaireCompletedStatus(true));
					sendGtmEvent(GTM.EVENTS.APPLICATION_STEP5_WOTC);
					if(fromRenew){
						dispatch(setWotcCompleted(true));
					} else {
						navigate('../minimum-qualifications');
					}
				}
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const handleSubmit = (isPreamble, fromRenew) => {
		if (isPreamble) {
			if (!screeningId || questionnaireLanguage !== language || questionnaireZipCode !== zipCode) {
				fetchQuestions('');
				dispatch(updateQuestionnaireLanguage(language));
				dispatch(updateQuestionnaireZipCode(zipCode));
			} else {
				fetchQuestions(screeningId,fromRenew);
			}
		} else {
			removeQuestionsErrorText(activeTaxQuestionsIndex);
			// select NO options if user hasn't selected them
			const updatedTaxQuestionsAnswer = handleDefaultValuesOnSubmit(activeTaxQuestionsIndex);
			dispatch(updateQuestionnaireAnswer(updatedTaxQuestionsAnswer));

			fetchQuestions(screeningId,fromRenew);
			
		}
		scroll?.scrollToTop?.();
	};

	const handlePrevious = (isPreamble) => {
		if (isPreamble) {
			navigate('../job-history');
		} else if (activeTaxQuestionsIndex === 0) {
			fetchPreamble();
		} else {
			removeQuestionsErrorText(activeTaxQuestionsIndex);
			removeTaxQuestionsAfterIndex(activeTaxQuestionsIndex - 1);
			setActiveTaxQuestionsIndex((index) => index - 1);
		}
		scroll?.scrollToTop?.();
	};

	const removeQuestionsErrorText = (taxQuestionsIndex) => {
		if (taxQuestionsIndex < 0 || taxQuestionsIndex >= taxQuestions.length) {
			return;
		}
		const updatedTaxQuestions = taxQuestions;
		updatedTaxQuestions[taxQuestionsIndex] = taxQuestions[taxQuestionsIndex]?.map((question) => {
			question.messageText = null;
			return question;
		});
		setTaxQuestions(updatedTaxQuestions);
	};

	const removeTaxQuestionsAfterIndex = (taxQuestionsIndex) => {
		if (taxQuestionsIndex >= taxQuestions.length - 1) {
			return;
		}
		const updatedTaxQuestions =
			taxQuestionsIndex >= 0 ? taxQuestions.slice(0, taxQuestionsIndex + 1) : taxQuestions;
		const updatedIsSubmitButtonState =
			taxQuestionsIndex >= 0
				? isSubmitButtonState.slice(0, taxQuestionsIndex + 1)
				: isSubmitButtonState;
		setTaxQuestions(updatedTaxQuestions);
		setIsSubmitButtonState(updatedIsSubmitButtonState);
	};

	const onChangeAnsCallback = (elementId, elementType, value) => {
		taxQuestionsAnswer[elementId] = { elementId, elementType, value };
		setTaxQuestionsAnswer({ ...taxQuestionsAnswer });
	};

	const onValidationCallback = (elementId, error) => {
		dispatch(
			updateValidationError({
				[elementId]: { error, isVisible: validationError[elementId]?.isVisible }
			})
		);
	};

	const validateAnswers = (questions) => {
		let flag = false;
		const updatedValidationError = { ...validationError };
		questions?.forEach((question) => {
			const error = validationError[question.elementId]?.error;
			if (error) {
				flag = true;
			}
			// Errors visible only after calling validateAnswers
			updatedValidationError[question.elementId] = { error, isVisible: !!error };
		});
		dispatch(updateValidationError(updatedValidationError));
		return !flag;
	};

	return {
		taxQuestions,
		activeTaxQuestionsIndex,
		handlePrevious,
		handleSubmit,
		onChangeAnsCallback,
		onValidationCallback,
		validateAnswers,
		handleUpdatePersonalInfo,
		checkQuestionnaireStatusAndRedirect
	};
};

export default useTaxQuestionnaire;
