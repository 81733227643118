import React from 'react';

const xmlParentTag = '<?xml version="1.0"?>';

export const storeSvg = [
	xmlParentTag,
	'<svg width="30" height="42" viewBox="0 0 30 42" fill="none" xmlns="http://www.w3.org/2000/svg">',
	'<g clip-path="url(#clip0_10150_568749)">',
	'<path d="M23.25 24.212C23.25 30.3406 14.7643 41.1549 14.7643 41.1549C15.2357 41.041 6.75 30.3121 6.75 23.7121C7.69286 18.5264 11.851 16.1978 15.2357 16.1978C18.6204 16.1978 23.25 18.5549 23.25 24.212Z" fill="#0071DC"/>',
	'<path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C23.2843 0 30 6.08959 30 15.0307C30 22.0404 25 31.0302 15 42C5 31.0251 0 22.0353 0 15.0307C0 6.08299 6.71573 0 15 0ZM15 3C8.12436 3 3 7.98229 3 15.0307C3 20.5127 6.81525 27.9029 14.5841 36.9977L15 37.479L15.4163 36.9985C22.9075 28.2322 26.7225 21.0473 26.9854 15.6258L27 15.0307C27 7.98639 21.873 3 15 3ZM15 19.4136C17.0711 19.4136 18.75 17.7346 18.75 15.6636C18.75 13.5925 17.0711 11.9136 15 11.9136C12.9289 11.9136 11.25 13.5925 11.25 15.6636C11.25 17.7346 12.9289 19.4136 15 19.4136Z" fill="#0071DC"/>',
	'<rect width="30" height="30.0019" rx="15" fill="#0071DC"/>',
	'<path fill-rule="evenodd" clip-rule="evenodd" d="M15.3375 7.3127L19.5 10.4346H22.3125C22.6232 10.4346 22.875 10.6864 22.875 10.9971V22.8096H16.125V18.8721H13.875V22.8096H7.125V10.9971C7.125 10.6864 7.37684 10.4346 7.6875 10.4346H10.5L14.6625 7.3127C14.8625 7.1627 15.1375 7.1627 15.3375 7.3127ZM19.125 11.5594L15 8.4657L10.875 11.5594H8.25V21.6844H12.75V17.7469H17.25V21.6844H21.75V11.5594H19.125Z" fill="white"/>',
	'</g>',
	'<defs>',
	'<clipPath id="clip0_10150_568749">',
	'<rect width="30" height="42" fill="white"/>',
	'</clipPath>',
	'</defs>',
	'</svg>'
].join('\n');

export const toggleStoreSvg = [
	xmlParentTag,
	'<svg width="40" height="56" viewBox="0 0 40 56" fill="none" xmlns="http://www.w3.org/2000/svg">',
	'<g clip-path="url(#clip0_10150_568769)">',
	'<path d="M31 32.2829C31 40.4543 19.6857 54.8734 19.6857 54.8734C20.3143 54.7214 9 40.4163 9 31.6163C10.2571 24.702 15.8013 21.5972 20.3143 21.5972C24.8272 21.5972 31 24.74 31 32.2829Z" fill="#FFC220"/>',
	'<path fill-rule="evenodd" clip-rule="evenodd" d="M20 0C31.0457 0 40 8.11945 40 20.0409C40 29.3873 33.3333 41.3736 20 56C6.66667 41.3668 0 29.3805 0 20.0409C0 8.11065 8.9543 0 20 0ZM20 4C10.8325 4 4 10.6431 4 20.0409C4 27.3503 9.087 37.2039 19.4454 49.3302L20 49.972L20.5551 49.3314C30.5433 37.6429 35.6299 28.0631 35.9805 20.8344L36 20.0409C36 10.6485 29.164 4 20 4ZM20 25.8848C22.7614 25.8848 25 23.6462 25 20.8848C25 18.1233 22.7614 15.8848 20 15.8848C17.2386 15.8848 15 18.1233 15 20.8848C15 23.6462 17.2386 25.8848 20 25.8848Z" fill="#FFC220"/>',
	'<rect width="40" height="40.0025" rx="20" fill="#FFC220"/>',
	'<path fill-rule="evenodd" clip-rule="evenodd" d="M20.45 9.7501L26 13.9126H29.75C30.1642 13.9126 30.5 14.2484 30.5 14.6626V30.4126H21.5V25.1626H18.5V30.4126H9.5V14.6626C9.5 14.2484 9.83579 13.9126 10.25 13.9126H14L19.55 9.7501C19.8167 9.5501 20.1833 9.5501 20.45 9.7501ZM25.5 15.4126L20 11.2876L14.5 15.4126H11V28.9126H17V23.6626H23V28.9126H29V15.4126H25.5Z" fill="black"/>',
	'</g>',
	'<defs>',
	'<clipPath id="clip0_10150_568769">',
	'<rect width="40" height="56" fill="white"/>',
	'</clipPath>',
	'</defs>',
	'</svg>'
].join('\n');

export const blackStoreSvg = [
	xmlParentTag,
	'<svg width="30" height="42" viewBox="0 0 30 42" fill="none" xmlns="http://www.w3.org/2000/svg">',
	'<g clip-path="url(#clip0_10150_568759)">',
	'<path d="M23.25 24.212C23.25 30.3406 14.7643 41.1549 14.7643 41.1549C15.2357 41.041 6.75 30.3121 6.75 23.7121C7.69286 18.5264 11.851 16.1978 15.2357 16.1978C18.6204 16.1978 23.25 18.5549 23.25 24.212Z" fill="black"/>',
	'<path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C23.2843 0 30 6.08959 30 15.0307C30 22.0404 25 31.0302 15 42C5 31.0251 0 22.0353 0 15.0307C0 6.08299 6.71573 0 15 0ZM15 3C8.12436 3 3 7.98229 3 15.0307C3 20.5127 6.81525 27.9029 14.5841 36.9977L15 37.479L15.4163 36.9985C22.9075 28.2322 26.7225 21.0473 26.9854 15.6258L27 15.0307C27 7.98639 21.873 3 15 3ZM15 19.4136C17.0711 19.4136 18.75 17.7346 18.75 15.6636C18.75 13.5925 17.0711 11.9136 15 11.9136C12.9289 11.9136 11.25 13.5925 11.25 15.6636C11.25 17.7346 12.9289 19.4136 15 19.4136Z" fill="black"/>',
	'<rect width="30" height="30.0019" rx="15" fill="black"/>',
	'<path fill-rule="evenodd" clip-rule="evenodd" d="M15.3375 7.3127L19.5 10.4346H22.3125C22.6232 10.4346 22.875 10.6864 22.875 10.9971V22.8096H16.125V18.8721H13.875V22.8096H7.125V10.9971C7.125 10.6864 7.37684 10.4346 7.6875 10.4346H10.5L14.6625 7.3127C14.8625 7.1627 15.1375 7.1627 15.3375 7.3127ZM19.125 11.5594L15 8.4657L10.875 11.5594H8.25V21.6844H12.75V17.7469H17.25V21.6844H21.75V11.5594H19.125Z" fill="white"/>',
	'</g>',
	'<defs>',
	'<clipPath id="clip0_10150_568759">',
	'<rect width="30" height="42" fill="white"/>',
	'</clipPath>',
	'</defs>',
	'</svg>'
].join('\n');

export const warehouseSvg = [
	xmlParentTag,
	'<svg width="30" height="42" viewBox="0 0 30 42" fill="none" xmlns="http://www.w3.org/2000/svg">',
	'<g clip-path="url(#clip0_10150_568754)">',
	'<path d="M23.25 24.212C23.25 30.3406 14.7643 41.1549 14.7643 41.1549C15.2357 41.041 6.75 30.3121 6.75 23.7121C7.69286 18.5264 11.851 16.1978 15.2357 16.1978C18.6204 16.1978 23.25 18.5549 23.25 24.212Z" fill="#63327E"/>',
	'<path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C23.2843 0 30 6.08959 30 15.0307C30 22.0404 25 31.0302 15 42C5 31.0251 0 22.0353 0 15.0307C0 6.08299 6.71573 0 15 0ZM15 3C8.12436 3 3 7.98229 3 15.0307C3 20.5127 6.81525 27.9029 14.5841 36.9977L15 37.479L15.4163 36.9985C22.9075 28.2322 26.7225 21.0473 26.9854 15.6258L27 15.0307C27 7.98639 21.873 3 15 3ZM15 19.4136C17.0711 19.4136 18.75 17.7346 18.75 15.6636C18.75 13.5925 17.0711 11.9136 15 11.9136C12.9289 11.9136 11.25 13.5925 11.25 15.6636C11.25 17.7346 12.9289 19.4136 15 19.4136Z" fill="#63327E"/>',
	'<rect width="30" height="30" rx="15" fill="#63327E"/>',
	'<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6875 8.8125C16.9723 8.8125 17.2076 9.02412 17.2449 9.29867L17.25 9.375V10.5H20.0625C20.1964 10.5 20.325 10.5477 20.4259 10.6331L20.4829 10.6888L22.7329 13.22C22.8061 13.3024 22.8531 13.404 22.869 13.5119L22.875 13.5938V18.375C22.875 18.6598 22.6634 18.8951 22.3888 18.9324L22.3125 18.9375H21.75C21.75 20.1801 20.7426 21.1875 19.5 21.1875C18.2574 21.1875 17.25 20.1801 17.25 18.9375H16.125H13.3125C13.3125 20.1801 12.3051 21.1875 11.0625 21.1875C9.81986 21.1875 8.8125 20.1801 8.8125 18.9375H7.6875C7.40273 18.9375 7.16738 18.7259 7.13013 18.4513L7.125 18.375V9.375C7.125 9.09023 7.33662 8.85488 7.61117 8.81763L7.6875 8.8125H16.6875ZM19.8097 11.625H17.25V17.8125H17.551C17.94 17.14 18.6672 16.6875 19.5 16.6875C20.3328 16.6875 21.06 17.14 21.449 17.8125H21.75V15H19.7794C19.4688 15 19.2169 14.7482 19.2169 14.4375C19.2169 14.1527 19.4285 13.9174 19.7031 13.8801L19.7794 13.875H21.75V13.8075L19.8097 11.625ZM16.125 11.0625V17.8125H13.0115C12.6225 17.14 11.8953 16.6875 11.0625 16.6875C10.2297 16.6875 9.50255 17.14 9.11351 17.8125H8.25V9.9375H16.125V10.5V11.0625ZM9.9375 18.9375C9.9375 18.3162 10.4412 17.8125 11.0625 17.8125C11.6838 17.8125 12.1875 18.3162 12.1875 18.9375C12.1875 19.5588 11.6838 20.0625 11.0625 20.0625C10.4412 20.0625 9.9375 19.5588 9.9375 18.9375ZM18.375 18.9375C18.375 18.3162 18.8787 17.8125 19.5 17.8125C20.1213 17.8125 20.625 18.3162 20.625 18.9375C20.625 19.5588 20.1213 20.0625 19.5 20.0625C18.8787 20.0625 18.375 19.5588 18.375 18.9375Z" fill="white"/>',
	'</g>',
	'<defs>',
	'<clipPath id="clip0_10150_568754">',
	'<rect width="30" height="42" fill="white"/>',
	'</clipPath>',
	'</defs>',
	'</svg>'
].join('\n');

export const toggleWarehouseSvg = [
	xmlParentTag,
	'<svg width="40" height="56" viewBox="0 0 40 56" fill="none" xmlns="http://www.w3.org/2000/svg">',
	'<g clip-path="url(#clip0_10150_568774)">',
	'<path d="M31 32.2824C31 40.4538 19.6857 54.8729 19.6857 54.8729C20.3143 54.7209 9 40.4158 9 31.6158C10.2571 24.7015 15.8013 21.5967 20.3143 21.5967C24.8272 21.5967 31 24.7395 31 32.2824Z" fill="#FFC220"/>',
	'<path fill-rule="evenodd" clip-rule="evenodd" d="M20 0C31.0457 0 40 8.11945 40 20.0409C40 29.3873 33.3333 41.3736 20 56C6.66667 41.3668 0 29.3805 0 20.0409C0 8.11065 8.9543 0 20 0ZM20 4C10.8325 4 4 10.6431 4 20.0409C4 27.3503 9.087 37.2039 19.4454 49.3302L20 49.972L20.5551 49.3314C30.5433 37.6429 35.6299 28.0631 35.9805 20.8344L36 20.0409C36 10.6485 29.164 4 20 4ZM20 25.8848C22.7614 25.8848 25 23.6462 25 20.8848C25 18.1233 22.7614 15.8848 20 15.8848C17.2386 15.8848 15 18.1233 15 20.8848C15 23.6462 17.2386 25.8848 20 25.8848Z" fill="#FFC220"/>',
	'<rect width="40" height="40" rx="20" fill="#FFC220"/>',
	'<path fill-rule="evenodd" clip-rule="evenodd" d="M22.25 11.75C22.6297 11.75 22.9435 12.0322 22.9932 12.3982L23 12.5V14H26.75C26.9285 14 27.0999 14.0636 27.2345 14.1775L27.3106 14.2517L30.3106 17.6267C30.4082 17.7366 30.4709 17.872 30.492 18.0158L30.5 18.125V19.25V24.5C30.5 24.8797 30.2178 25.1935 29.8518 25.2432L29.75 25.25H29C29 26.9069 27.6569 28.25 26 28.25C24.3431 28.25 23 26.9069 23 25.25H21.5H17.75C17.75 26.9069 16.4069 28.25 14.75 28.25C13.0931 28.25 11.75 26.9069 11.75 25.25H10.25C9.8703 25.25 9.55651 24.9678 9.50685 24.6018L9.5 24.5V12.5C9.5 12.1203 9.78215 11.8065 10.1482 11.7568L10.25 11.75H22.25ZM29 18.5H26.3726L26.2708 18.5068C25.9048 18.5565 25.6226 18.8703 25.6226 19.25C25.6226 19.6642 25.9584 20 26.3726 20H29V23.75H28.5987C28.0799 22.8533 27.1104 22.25 26 22.25C24.8896 22.25 23.9201 22.8533 23.4013 23.75H23V15.5H26.413L29 18.41V18.5ZM21.5 14.75V23.75H17.3487C16.8299 22.8533 15.8604 22.25 14.75 22.25C13.6396 22.25 12.6701 22.8533 12.1513 23.75H11V13.25H21.5V14V14.75ZM13.25 25.25C13.25 24.4216 13.9216 23.75 14.75 23.75C15.5784 23.75 16.25 24.4216 16.25 25.25C16.25 26.0784 15.5784 26.75 14.75 26.75C13.9216 26.75 13.25 26.0784 13.25 25.25ZM24.5 25.25C24.5 24.4216 25.1716 23.75 26 23.75C26.8284 23.75 27.5 24.4216 27.5 25.25C27.5 26.0784 26.8284 26.75 26 26.75C25.1716 26.75 24.5 26.0784 24.5 25.25Z" fill="black"/>',
	'</g>',
	'<defs>',
	'<clipPath id="clip0_10150_568774">',
	'<rect width="40" height="56" fill="white"/>',
	'</clipPath>',
	'</defs>',
	'</svg>'
].join('\n');

export const blackWarehouseSvg = [
	xmlParentTag,
	'<svg width="30" height="42" viewBox="0 0 30 42" fill="none" xmlns="http://www.w3.org/2000/svg">',
	'<g clip-path="url(#clip0_10150_568764)">',
	'<path d="M23.25 24.212C23.25 30.3406 14.7643 41.1549 14.7643 41.1549C15.2357 41.041 6.75 30.3121 6.75 23.7121C7.69286 18.5264 11.851 16.1978 15.2357 16.1978C18.6204 16.1978 23.25 18.5549 23.25 24.212Z" fill="black"/>',
	'<path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C23.2843 0 30 6.08959 30 15.0307C30 22.0404 25 31.0302 15 42C5 31.0251 0 22.0353 0 15.0307C0 6.08299 6.71573 0 15 0ZM15 3C8.12436 3 3 7.98229 3 15.0307C3 20.5127 6.81525 27.9029 14.5841 36.9977L15 37.479L15.4163 36.9985C22.9075 28.2322 26.7225 21.0473 26.9854 15.6258L27 15.0307C27 7.98639 21.873 3 15 3ZM15 19.4136C17.0711 19.4136 18.75 17.7346 18.75 15.6636C18.75 13.5925 17.0711 11.9136 15 11.9136C12.9289 11.9136 11.25 13.5925 11.25 15.6636C11.25 17.7346 12.9289 19.4136 15 19.4136Z" fill="black"/>',
	'<rect width="30" height="30" rx="15" fill="black"/>',
	'<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6875 8.8125C16.9723 8.8125 17.2076 9.02412 17.2449 9.29867L17.25 9.375V10.5H20.0625C20.1964 10.5 20.325 10.5477 20.4259 10.6331L20.4829 10.6888L22.7329 13.22C22.8061 13.3024 22.8531 13.404 22.869 13.5119L22.875 13.5938V18.375C22.875 18.6598 22.6634 18.8951 22.3888 18.9324L22.3125 18.9375H21.75C21.75 20.1801 20.7426 21.1875 19.5 21.1875C18.2574 21.1875 17.25 20.1801 17.25 18.9375H16.125H13.3125C13.3125 20.1801 12.3051 21.1875 11.0625 21.1875C9.81986 21.1875 8.8125 20.1801 8.8125 18.9375H7.6875C7.40273 18.9375 7.16738 18.7259 7.13013 18.4513L7.125 18.375V9.375C7.125 9.09023 7.33662 8.85488 7.61117 8.81763L7.6875 8.8125H16.6875ZM19.8097 11.625H17.25V17.8125H17.551C17.94 17.14 18.6672 16.6875 19.5 16.6875C20.3328 16.6875 21.06 17.14 21.449 17.8125H21.75V15H19.7794C19.4688 15 19.2169 14.7482 19.2169 14.4375C19.2169 14.1527 19.4285 13.9174 19.7031 13.8801L19.7794 13.875H21.75V13.8075L19.8097 11.625ZM16.125 11.0625V17.8125H13.0115C12.6225 17.14 11.8953 16.6875 11.0625 16.6875C10.2297 16.6875 9.50255 17.14 9.11351 17.8125H8.25V9.9375H16.125V10.5V11.0625ZM9.9375 18.9375C9.9375 18.3162 10.4412 17.8125 11.0625 17.8125C11.6838 17.8125 12.1875 18.3162 12.1875 18.9375C12.1875 19.5588 11.6838 20.0625 11.0625 20.0625C10.4412 20.0625 9.9375 19.5588 9.9375 18.9375ZM18.375 18.9375C18.375 18.3162 18.8787 17.8125 19.5 17.8125C20.1213 17.8125 20.625 18.3162 20.625 18.9375C20.625 19.5588 20.1213 20.0625 19.5 20.0625C18.8787 20.0625 18.375 19.5588 18.375 18.9375Z" fill="white"/>',
	'</g>',
	'<defs>',
	'<clipPath id="clip0_10150_568764">',
	'<rect width="30" height="42" fill="white"/>',
	'</clipPath>',
	'</defs>',
	'</svg>'
].join('\n');

export const mapSvg = (
	<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.04879 0.092538L8.95121 1.90761C9.07791 1.96655 9.2235 1.96977 9.35269 1.91649L13.3094 0.284866C13.5647 0.179592 13.857 0.301203 13.9622 0.556491C13.9872 0.616949 14 0.68171 14 0.747106V11.6654C14 11.8679 13.8778 12.0505 13.6906 12.1277L9.35269 13.9165C9.2235 13.9698 9.07791 13.9665 8.95121 13.9076L5.04879 12.0925C4.92209 12.0336 4.7765 12.0304 4.64731 12.0837L0.690615 13.7153C0.435326 13.8206 0.143033 13.6989 0.0377597 13.4437C0.0128288 13.3832 0 13.3184 0 13.253V2.33473C0 2.13222 0.122162 1.9497 0.309385 1.87249L4.64731 0.0836582C4.7765 0.0303849 4.92209 0.033605 5.04879 0.092538ZM13 1.49308L9.73392 2.84097L9.6 2.8879V12.7319L13 11.3301V1.49308ZM5.299 1.3109L5.29978 11.1188L5.47052 11.1858L8.6 12.6409V2.8419L8.52948 2.81434L5.299 1.3109ZM4.299 1.3079L1 2.66808V12.5051L4.299 11.1469V1.3079Z"
			fill="black"
		/>
	</svg>
);

export const mapSearchSvg = (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15 8C15 4.54822 12.1965 1.75 8.73815 1.75C5.27982 1.75 2.47629 4.54822 2.47629 8C2.47629 8.00002 2.47629 8.00004 2.47629 8.00006L1.85225 7.37714C1.67895 7.20416 1.40993 7.18495 1.21536 7.31948L1.14622 7.37714C0.972922 7.55011 0.953666 7.81861 1.08846 8.01281L1.14622 8.08183L2.64393 9.5767C2.81723 9.74967 3.08624 9.76889 3.28081 9.63435L3.34995 9.5767L4.84766 8.08183C5.04262 7.88723 5.04262 7.57173 4.84766 7.37714C4.67436 7.20416 4.40535 7.18495 4.21078 7.31948L4.14163 7.37714L3.47277 8.0448C3.47409 8.03004 3.47476 8.0151 3.47476 8C3.47476 5.09862 5.83126 2.74658 8.73815 2.74658C11.645 2.74658 14.0015 5.09862 14.0015 8C14.0015 10.9014 11.645 13.2534 8.73815 13.2534C7.16279 13.2534 5.70189 12.5592 4.70739 11.3785C4.52995 11.1679 4.21503 11.1407 4.00399 11.3177C3.79294 11.4948 3.7657 11.8092 3.94313 12.0198C5.12538 13.4234 6.86482 14.25 8.73815 14.25C12.1965 14.25 15 11.4518 15 8ZM3.02045 8.4963C3.00565 8.49762 2.99067 8.49829 2.97553 8.49829C2.97549 8.49829 2.97546 8.49829 2.97542 8.49829L2.99694 8.51977L3.02045 8.4963Z"
			fill="black"
		/>
	</svg>
);
