import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';
import { apiRequestErrorOccured, updateLoading } from '../slices/globalSlice';

const fetchExternalPersonalDetails = createAsyncThunk(
	'vjo/fetchExternalPersonalDetails',
	async (data, { dispatch }) => {
		dispatch(updateLoading(true));
		try {
			const baseURL = environment.hcServiceBasePostUrl + `/fetchExternalPersonalDetails`;
			const response = await axiosInstance.post(baseURL, data);
			dispatch(updateLoading(false));
			return response.data;
		} catch (error) {
			dispatch(apiRequestErrorOccured(error));
		}
	}
);

const saveExternalPersonalDetails = createAsyncThunk(
	'vjo/saveExternalPersonalDetails',
	async (data, { dispatch }) => {
		dispatch(updateLoading(true));
		try {
			const baseURL = environment.hcServiceBasePostUrl + `/saveExternalPersonalDetails`;
			const response = await axiosInstance.post(baseURL, data);
			dispatch(updateLoading(false));
			return response.data;
		} catch (error) {
			dispatch(apiRequestErrorOccured(error));
		}
	}
);

export { fetchExternalPersonalDetails, saveExternalPersonalDetails };
