import React, { useState, useEffect } from 'react';
import {
	BottomSheet,
	BottomSheetCloseButton,
	Heading,
	Modal,
	Select,
	Checkbox,
	Divider,
	Button,
	Body
} from '@walmart-web/livingdesign-components';
import style from './CopyAvailableHoursPopUp.module.css';
import { useTranslation } from 'react-i18next';
import { AvailabilityHelper } from '../AvailabilityHelper';
import { useDisplay } from '../../../utils/useDisplay';

const CopyAvailableHoursPopUp = (props) => {
	const { t } = useTranslation();
	const [pasteButton, setPasteButton] = useState(false);
	const [startTimeCopy, setStartTimeCopy] = useState('');
	const [endTimeCopy, setEndTimeCopy] = useState('');
	const [dismonCheckBox, setDisMonCheckBox] = useState(false);
	const [distueCheckBox, setDisTueCheckBox] = useState(false);
	const [diswedCheckBox, setDisWedCheckBox] = useState(false);
	const [disthuCheckBox, setDisThuCheckBox] = useState(false);
	const [disfriCheckBox, setDisFriCheckBox] = useState(false);
	const [dissatCheckBox, setDisSatCheckBox] = useState(false);
	const [dissunCheckBox, setDisSunCheckBox] = useState(false);
	const [chkmonCheckBox, setChkMonCheckBox] = useState(false);
	const [chktueCheckBox, setChkTueCheckBox] = useState(false);
	const [chkwedCheckBox, setChkWedCheckBox] = useState(false);
	const [chkthuCheckBox, setChkThuCheckBox] = useState(false);
	const [chkfriCheckBox, setChkFriCheckBox] = useState(false);
	const [chksatCheckBox, setChkSatCheckBox] = useState(false);
	const [chksunCheckBox, setChkSunCheckBox] = useState(false);
	const { isLandscape } = useDisplay();
	const { validateTiming, dayObj } = AvailabilityHelper();

	const onChangeCopy = (day) => {
		if (day === 'Mon') {
			const checked = !chkmonCheckBox;
			setChkMonCheckBox(checked);
			if (
				checked ||
				chktueCheckBox ||
				chkwedCheckBox ||
				chkthuCheckBox ||
				chkfriCheckBox ||
				chksatCheckBox ||
				chksunCheckBox
			) {
				setPasteButton(true);
			} else {
				setPasteButton(false);
			}
		}
		if (day === 'Tue') {
			const checked = !chktueCheckBox;
			setChkTueCheckBox(checked);
			if (
				chkmonCheckBox ||
				checked ||
				chkwedCheckBox ||
				chkthuCheckBox ||
				chkfriCheckBox ||
				chksatCheckBox ||
				chksunCheckBox
			) {
				setPasteButton(true);
			} else {
				setPasteButton(false);
			}
		}
		if (day === 'Wed') {
			const checked = !chkwedCheckBox;
			setChkWedCheckBox(checked);
			if (
				chkmonCheckBox ||
				chktueCheckBox ||
				checked ||
				chkthuCheckBox ||
				chkfriCheckBox ||
				chksatCheckBox ||
				chksunCheckBox
			) {
				setPasteButton(true);
			} else {
				setPasteButton(false);
			}
		}
		if (day === 'Thu') {
			const checked = !chkthuCheckBox;
			setChkThuCheckBox(checked);
			if (
				chkmonCheckBox ||
				chktueCheckBox ||
				chkwedCheckBox ||
				checked ||
				chkfriCheckBox ||
				chksatCheckBox ||
				chksunCheckBox
			) {
				setPasteButton(true);
			} else {
				setPasteButton(false);
			}
		}
		if (day === 'Fri') {
			const checked = !chkfriCheckBox;
			setChkFriCheckBox(checked);
			if (
				chkmonCheckBox ||
				chktueCheckBox ||
				chkwedCheckBox ||
				chkthuCheckBox ||
				checked ||
				chksatCheckBox ||
				chksunCheckBox
			) {
				setPasteButton(true);
			} else {
				setPasteButton(false);
			}
		}
		if (day === 'Sat') {
			const checked = !chksatCheckBox;
			setChkSatCheckBox(checked);
			if (
				chkmonCheckBox ||
				chktueCheckBox ||
				chkwedCheckBox ||
				chkthuCheckBox ||
				chkfriCheckBox ||
				checked ||
				chksunCheckBox
			) {
				setPasteButton(true);
			} else {
				setPasteButton(false);
			}
		}
		if (day === 'Sun') {
			const checked = !chksunCheckBox;
			setChkSunCheckBox(checked);
			if (
				chkmonCheckBox ||
				chktueCheckBox ||
				chkwedCheckBox ||
				chkthuCheckBox ||
				chkfriCheckBox ||
				chksatCheckBox ||
				checked
			) {
				setPasteButton(true);
			} else {
				setPasteButton(false);
			}
		}
	};

	const onClickPaste = () => {
		const copyValueArr = [];
		if (chkmonCheckBox) {
			const stTime = startTimeCopy;
			const etTime = endTimeCopy;
			const mon = dayObj('Mon', stTime, etTime, chkmonCheckBox);
			copyValueArr.push(mon);
		}
		if (chktueCheckBox) {
			const stTime = startTimeCopy;
			const etTime = endTimeCopy;
			const tue = dayObj('Tue', stTime, etTime, chktueCheckBox);
			copyValueArr.push(tue);
		}
		if (chkwedCheckBox) {
			const stTime = startTimeCopy;
			const etTime = endTimeCopy;
			const wed = dayObj('Wed', stTime, etTime, chkwedCheckBox);
			copyValueArr.push(wed);
		}
		if (chkthuCheckBox) {
			const stTime = startTimeCopy;
			const etTime = endTimeCopy;
			const thu = dayObj('Thu', stTime, etTime, chkthuCheckBox);
			copyValueArr.push(thu);
		}
		if (chkfriCheckBox) {
			const stTime = startTimeCopy;
			const etTime = endTimeCopy;
			const fri = dayObj('Fri', stTime, etTime, chkfriCheckBox);
			copyValueArr.push(fri);
		}
		if (chksatCheckBox) {
			const stTime = startTimeCopy;
			const etTime = endTimeCopy;
			const sat = dayObj('Sat', stTime, etTime, chksatCheckBox);
			copyValueArr.push(sat);
		}
		if (chksunCheckBox) {
			const stTime = startTimeCopy;
			const etTime = endTimeCopy;
			const sun = dayObj('Sun', stTime, etTime, chksunCheckBox);
			copyValueArr.push(sun);
		}

		props.copyValue(copyValueArr);
		props.setIsOpen(false);
	};
	const handleShiftTimings = (e) => {
		const value = e.target.value;
		props.copyValueArr.forEach((val) => {
			if (value === val.day) {
				setStartTimeCopy(val.startTime);
				setEndTimeCopy(val.endTime);
			}
		});
	};
	useEffect(() => {
		setDisMonCheckBox(false);
		setDisTueCheckBox(false);
		setDisWedCheckBox(false);
		setDisThuCheckBox(false);
		setDisFriCheckBox(false);
		setDisSatCheckBox(false);
		setDisSunCheckBox(false);
		setChkMonCheckBox(false);
		setChkTueCheckBox(false);
		setChkWedCheckBox(false);
		setChkThuCheckBox(false);
		setChkFriCheckBox(false);
		setChkSatCheckBox(false);
		setChkSunCheckBox(false);

		props.copyValueArr.map((val, index) => {
			if (index === 0) {
				setStartTimeCopy(val.startTime);
				setEndTimeCopy(val.endTime);
			}
			if (val.day === 'Mon' && val.checked === true) {
				setChkMonCheckBox(false);
				if (validateTiming(val.startTime, val.endTime)) {
					setDisMonCheckBox(true);
				}
			}
			if (val.day === 'Tue' && val.checked === true) {
				setChkTueCheckBox(false);
				if (validateTiming(val.startTime, val.endTime)) {
					setDisTueCheckBox(true);
				}
			}
			if (val.day === 'Wed' && val.checked === true) {
				setChkWedCheckBox(false);
				if (validateTiming(val.startTime, val.endTime)) {
					setDisWedCheckBox(true);
				}
			}
			if (val.day === 'Thu' && val.checked === true) {
				setChkThuCheckBox(false);
				if (validateTiming(val.startTime, val.endTime)) {
					setDisThuCheckBox(true);
				}
			}
			if (val.day === 'Fri' && val.checked === true) {
				setChkSatCheckBox(false);
				if (validateTiming(val.startTime, val.endTime)) {
					setDisFriCheckBox(true);
				}
			}
			if (val.day === 'Sat' && val.checked === true) {
				if (validateTiming(val.startTime, val.endTime)) {
					setDisSatCheckBox(true);
				}
			}
			if (val.day === 'Sun' && val.checked === true) {
				setChkSunCheckBox(false);
				if (validateTiming(val.startTime, val.endTime)) {
					setDisSunCheckBox(true);
				}
			}
		});
		// eslint-disable-next-line
	}, [props]);

	return (
		<React.Fragment>
			{isLandscape ? (
				<>
					<Modal
						isOpen={props.isOpen}
						onClose={() => props.setIsOpen(false)}
						title={t('availability.copy')}>
						<div className={style.copy}>
							<div>
								<Body as="div" size="small" weight={700} aria-label={t('availability.copyAvailableHours')}>
									{t('availability.copyAvailableHours')}
								</Body>
							</div>
							<div>
								<Select
									onChange={(e) => {
										handleShiftTimings(e);
									}}>
									{props.copyValueArr.map((val, index) => {
										return (
											val.startTime !== '--' &&
											val.endTime !== '--' && (
												<option key={index} value={val.day}>
													{val.day + ' (' + val.startTime + '-' + val.endTime + ')'}
												</option>
											)
										);
									})}
								</Select>
							</div>
							<div>
								<Body as="div" size="small" weight={700}>
									{t('availability.copytValues')}
								</Body>
							</div>
							<div>
								<Checkbox
									label={t('availability.mon')}
									checked={chkmonCheckBox}
									onChange={() => onChangeCopy('Mon')}
									disabled={dismonCheckBox}
								/>
							</div>
							<div>
								<Checkbox
									label={t('availability.tue')}
									checked={chktueCheckBox}
									onChange={() => onChangeCopy('Tue')}
									disabled={distueCheckBox}
								/>
							</div>
							<div>
								<Checkbox
									label={t('availability.wed')}
									checked={chkwedCheckBox}
									onChange={() => onChangeCopy('Wed')}
									disabled={diswedCheckBox}
								/>
							</div>
							<div>
								<Checkbox
									label={t('availability.thu')}
									checked={chkthuCheckBox}
									onChange={() => onChangeCopy('Thu')}
									disabled={disthuCheckBox}
								/>
							</div>
							<div>
								<Checkbox
									label={t('availability.fri')}
									checked={chkfriCheckBox}
									onChange={() => onChangeCopy('Fri')}
									disabled={disfriCheckBox}
								/>
							</div>
							<div>
								<Checkbox
									label={t('availability.sat')}
									checked={chksatCheckBox}
									onChange={() => onChangeCopy('Sat')}
									disabled={dissatCheckBox}
								/>
							</div>
							<div>
								<Checkbox
									label={t('availability.sun')}
									checked={chksunCheckBox}
									onChange={() => onChangeCopy('Sun')}
									disabled={dissunCheckBox}
								/>
							</div>
							<div>
								<Divider />
							</div>
							<Button
								size="medium"
								variant="primary"
								disabled={!pasteButton}
								onClick={onClickPaste}>
								{t('availability.paste')}
							</Button>
						</div>
					</Modal>
				</>
			) : (
				<>
					<BottomSheet
						isOpen={props.isOpen}
						onClose={() => props.setIsOpen(false)}
						title={({ titleId }) => (
							<div className={style.title}>
								<BottomSheetCloseButton
									className={style.closebutton}
									aria-label={t('ariaLabelTexts.closeDialog')}
									onClick={() => props.setIsOpen(false)}
								/>
								<Heading size="medium" id={titleId}>
									{t('availability.copy')}
								</Heading>
							</div>
						)}>
						<div className={style.copy}>
							<div>
								<Heading size="small">{t('availability.copyAvailableHours')}</Heading>
							</div>
							<div>
								<Select
									onChange={(e) => {
										handleShiftTimings(e);
									}}>
									{props.copyValueArr.map((val, index) => {
										return (
											val.startTime !== '--' &&
											val.endTime !== '--' && (
												<option key={index} value={val.day}>
													{val.day + ' (' + val.startTime + '-' + val.endTime + ')'}
												</option>
											)
										);
									})}
								</Select>
							</div>
							<div>
								<Heading size="small">{t('availability.copytValues')}</Heading>
							</div>

							<div>
								<Checkbox
									label={t('availability.mon')}
									checked={chkmonCheckBox}
									onChange={() => onChangeCopy('Mon')}
									disabled={dismonCheckBox}
								/>
							</div>
							<div>
								<Checkbox
									label={t('availability.tue')}
									checked={chktueCheckBox}
									onChange={() => onChangeCopy('Tue')}
									disabled={distueCheckBox}
								/>
							</div>
							<div>
								<Checkbox
									label={t('availability.wed')}
									checked={chkwedCheckBox}
									onChange={() => onChangeCopy('Wed')}
									disabled={diswedCheckBox}
								/>
							</div>
							<div>
								<Checkbox
									label={t('availability.thu')}
									checked={chkthuCheckBox}
									onChange={() => onChangeCopy('Thu')}
									disabled={disthuCheckBox}
								/>
							</div>
							<div>
								<Checkbox
									label={t('availability.fri')}
									checked={chkfriCheckBox}
									onChange={() => onChangeCopy('Fri')}
									disabled={disfriCheckBox}
								/>
							</div>
							<div>
								<Checkbox
									label={t('availability.sat')}
									checked={chksatCheckBox}
									onChange={() => onChangeCopy('Sat')}
									disabled={dissatCheckBox}
								/>
							</div>
							<div>
								<Checkbox
									label={t('availability.sun')}
									checked={chksunCheckBox}
									onChange={() => onChangeCopy('Sun')}
									disabled={dissunCheckBox}
								/>
							</div>
							<div>
								<Divider />
							</div>
							<Button
								size="medium"
								variant="primary"
								disabled={!pasteButton}
								onClick={onClickPaste}>
								{t('availability.paste')}
							</Button>
						</div>
					</BottomSheet>
				</>
			)}
		</React.Fragment>
	);
};
export default CopyAvailableHoursPopUp;
