import { Divider, Button, Body } from '@walmart-web/livingdesign-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import LocationSearchTextField from './LocationSearchTextField';
import { executionErrorOccured, updateLoading } from '../../../../redux/slices/globalSlice';
import { getAllFacilities, getListOfCities } from '../../../../redux/Location/request';
import { useDisplay } from '../../../../utils/useDisplay';
import {
	setClickLocText,
	updateLocationSearch,
	updateStoresList
} from '../../../../redux/Location/slice';
import states from '../../../../assets/fixtures/statesData.json';
import style from './LocationSearch.module.css';

const LocationSearch = (props) => {
	const { isLandscape } = useDisplay();
	//this prop is used to capture the type of location search (zip/city,state)
	const [locationSearchType, setLocationSearchType] = useState('');

	//this prop is used to capture the input for location
	const [searchText, setSearchText] = useState('');

	//this prop is used for setting errors.
	const [errorMessage, setErrorMessage] = useState('');

	//this prop is used for setting suggestion array
	const [citySuggestions, setCitySuggestions] = useState([]);

	const [disableSearchButton, setdisableSearchButton] = useState(true);

	const [currentlyFocusedSuggestionId, setCurrentlyFocusedSuggestionId] = useState(null);

    const handleSuggestionFocus = (id) => {
        setCurrentlyFocusedSuggestionId(id);
      }

	//to validate zip code
	const regex = /^[0-9]*$/;
	const locationCurrentLocation = 'location.currentLocation';

	const global = useSelector((state) => state.global);
	const location = useSelector((state) => state.location);

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const [hasAlreadyFetchedAllCities, setHasAlreadyFetchedAllCities] = useState(false);

	useEffect(() => {
		setLocationSearchType('');
		if (!props.showSearch) {
			setSearchText(location.location.value);
			setdisableSearchButton(true);
		}

		if (!hasAlreadyFetchedAllCities && (!location.allCities || location.allCities.length === 0)) {
			dispatch(updateLoading(true));
			dispatch(getListOfCities())
				.then((res) => {
					dispatch(updateLoading(false));
					setHasAlreadyFetchedAllCities(true);
				})
				.catch(() => {
					dispatch(executionErrorOccured());
				});
		}
	}, [dispatch, location.location.value, props.showSearch]);

	const handleClickLocText = () => {
		dispatch(setClickLocText(true));
	};
	const handleCancel = () => {
		dispatch(setClickLocText(false));
	};

	//this function validates zip code
	const onChangeSearchText = (event) => {
		setErrorMessage('');
		const { value } = event.target;
		setSearchText(value);

		if (value === '') {
			setCitySuggestions([]);
			setdisableSearchButton(true);
		} else {
			if (regex.test(value)) {
				setCitySuggestions([]);
				setLocationSearchType('zipSearch');
				setdisableSearchButton(false);
			} else {
				setdisableSearchButton(true);

				const filteredCities = value.startsWith(',')
					? []
					: location.allCities.filter(
							(a) =>
								a.toLowerCase().startsWith(value.toLowerCase()) ||
								a.toUpperCase().includes(', ' + states[value.toUpperCase()])
					  );
				setCitySuggestions(filteredCities);
				setLocationSearchType('citySearch');
			}
		}
	};

	//pad leading zeros to zipcode for PR states
	const padLeadingZeros = (zipCode) => {
		let newZipCode = zipCode;
		let padLen = 5 - newZipCode.length;
		while (padLen-- > 0) {
			newZipCode = '0'.concat(newZipCode);
		}
		return newZipCode;
	};

	const handleCurrentLocClick = () => {
		setLocationSearchType('latLongSearch');

		setSearchText(t(locationCurrentLocation));
		//     global.location.coordinates.city +
		//         ", " +
		//         global.location.coordinates.state
		// );
		setCitySuggestions([]);
		setdisableSearchButton(false);
		if (!props.showSearch) {
			onClickSearchStores('latLongSearch');
		}
	};

	//This function handles on click of any suggestion
	const handleSuggestionClick = (val) => {
		setSearchText(val);
		setCitySuggestions([]);
		setdisableSearchButton(false);
		if (!props.showSearch) {
			onClickSearchStores(val);
		}
	};

	const validCityState = (city, stateName) => {
		const i = location.allCities.findIndex((citySt) => {
			return citySt.toUpperCase() === city.toUpperCase() + ', ' + stateName.toUpperCase();
		});

		return i !== -1;
	};
	/*
  this function is triggered after submitting either zipcode / city,state
  stores the search result in redux store , closes the bottomsheet and resets the fields
  */
	const onClickSearchStores = (e) => {
		let searchVal = searchText;
		if (typeof e === 'function' || typeof e === 'object') {
			e.preventDefault();
		} else if (e === 'latLongSearch') {
			searchVal = t(locationCurrentLocation);
		} else {
			searchVal = e;
		}
		let searchQuery = '';
		let errorMsg = t('location.zipOrCityError');
		let doNotSearch = false;
		if (locationSearchType === 'zipSearch' || regex.test(searchVal)) {
			errorMsg = t('location.zipError');
			if (searchVal.length > 5) {
				doNotSearch = true;
			} else {
				const zipCode = searchVal.length < 5 ? padLeadingZeros(searchVal) : searchVal;
				setSearchText(zipCode);
				searchQuery = '&zipCode=' + zipCode;
			}
		} else if (
			locationSearchType === 'latLongSearch' ||
			e === 'latLongSearch' ||
			t(locationCurrentLocation) === searchVal
		) {
			const loc = global.location.coordinates;
			searchQuery = '&latitude=' + loc.lat + '&longitude=' + loc.long + '&state=' + loc.state;

			errorMsg = t('location.latLongError');
			if (!validCityState(loc.city, loc.state)) {
				doNotSearch = true;
			}
		} else {
			if (!searchVal.includes(', ')) {
				doNotSearch = true;
			}
			if (searchText === location.location.value) {
				errorMsg = '';
			}
			if (!doNotSearch) {
				const locationArray = searchVal.split(',');

				const city = locationArray[0].trim().toUpperCase();
				let stateName = locationArray[1].trim().toUpperCase();

				if (Object.keys(states).includes(stateName)) {
					stateName = states[stateName];
				}

				const i = location.allCities.findIndex((citySt) => {
					return citySt.toUpperCase() === city + ', ' + stateName;
				});

				if (i === -1) {
					doNotSearch = true;
				} else {
					searchVal = location.allCities[i];
				}
				if (locationSearchType === 'citySearch' || searchVal.includes(', ')) {
					searchQuery = '&city=' + city + '&state=' + stateName;
					errorMsg = t('location.cityStateError');
				}
			}
		}
		const requestInput = 'applicantId=' + global.applicantId + searchQuery;

		if (doNotSearch) {
			setErrorMessage(errorMsg);
			setdisableSearchButton(true);
			if (props.showSearch) {
				return;
			}
		} else {
			dispatch(updateLoading(true));
			dispatch(getAllFacilities(requestInput))
				.then((res) => {
					dispatch(
						updateLocationSearch({
							type: locationSearchType,
							value: searchVal
						})
					);
					if(res.payload.responseCode === 1001){
						setErrorMessage(errorMsg);
					}
					else{
						const data = res.payload.response;
						
						if (data.length === 0) {
							setErrorMessage(errorMsg);
							setdisableSearchButton(true);
						} else {
							setErrorMessage('');
							dispatch(updateStoresList(data));
							typeof props.setIsOpen === 'function' && props.setIsOpen(false);
							dispatch(setClickLocText(false));
							setSearchText('');
							dispatch(updateLoading(false));
							navigate('../location-search');
						}
					}
					dispatch(updateLoading(false));
				})
				.catch(() => {
					dispatch(executionErrorOccured());
				});
		}
	};

	const { t } = useTranslation();

	return (
		<div className={location.clickLocText ? style.locSearchContainer : null}>
			<form
				onSubmit={onClickSearchStores}
				onClick={handleClickLocText}
				className={style.locSearchTextForm}>
				<LocationSearchTextField
					searchText={searchText}
					setSearchText={onChangeSearchText}
					suggestions={citySuggestions}
					locationSearchType={locationSearchType}
					handleSuggestionClick={handleSuggestionClick}
					handleCurrentLocClick={handleCurrentLocClick}
					errorMessage={errorMessage}
					showCurrentLoc={props.showCurrentLoc}
					currentlyFocusedSuggestionId = {currentlyFocusedSuggestionId}
					handleSuggestionFocus = {handleSuggestionFocus}
				/>
				{props.showSearch && (
					<div>
						<div style={{ margin: '16px 0' }}>
							<Divider />
						</div>
						<Button
							disabled={disableSearchButton}
							isFullWidth
							size="medium"
							variant="primary"
							onClick={onClickSearchStores}>
							{t('location.search')}
						</Button>
					</div>
				)}
			</form>
			{!isLandscape && location.clickLocText && global.path === 'location-search' && (
				<Body
					as="div"
					size="medium"
					UNSAFE_className={style.cancel}
					UNSAFE_style={{ color: '#FFFFFF' }}
					onClick={handleCancel}>
					{t('general.cancel')}
				</Body>
			)}
		</div>
	);
};

export default LocationSearch;
