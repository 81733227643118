import { createSlice } from "@reduxjs/toolkit";
import {getPersonalInfo} from './request'

const initialState = {
    firstName: "",
    lastName: "",
    ssn: "",
    applInfo: {},
    abotYouReqStep1:{},
    postObj : {},
    aboutYouReq: {
        applicantId: "",
        checkRehire: false,
        countryCode: "",
        hcTestResponse: [
            {
                applicantId: "",
                pretestCode: 20,
                revisionNbr: 3,
                questionNbr: 4,
                answerNbr: "",
            },
            {
                applicantId: "",
                pretestCode: 20,
                revisionNbr: 3,
                questionNbr: 44,
                answerNbr: "",
            },
            {
                applicantId: "",
                pretestCode: 20,
                revisionNbr: 3,
                questionNbr: 5,
                answerNbr: "",
            },
            {
                applicantId: "",
                pretestCode: 20,
                revisionNbr: 3,
                questionNbr: 6,
                answerNbr: "",
            },
        ],
        parentEmailId: "",
        minor: false,
        responseCode:"",
        responseMessage:""
    },
};

export const aboutYouSlice = createSlice({
    name: "aboutYou",
    initialState,
    reducers: {
        updateFirstName: (state, action) => {
            state.firstName = action.payload;
        },
        updateLasttName: (state, action) => {
            state.lastName = action.payload;
        },
        updateSSN: (state, action) => {
            state.ssn = action.payload;
        },
        updatePersonalInfo: (state, action) => {
            state.applInfo = action.payload;
        },
        updateAboutYouReqStep1: (state, action) => {
            state.abotYouReqStep1 = action.abotYouReqStep1;
        },
        updateApplicantId: (state, action) => {
            state.aboutYouReq.applicantId = action.payload.applicantId;
        },
        updateCheckHire: (state, action) => {
            if(!state.applInfo?.identificationNbr?.length){
                state.aboutYouReq.checkRehire = action.payload.checkRehire;
            }
        },
        updateCountryCodeAboutYou: (state, action) => {
            state.aboutYouReq.countryCode = action.payload.countryCode;
        },
        updateminorStatus: (state, action) => {
           
            let minor= action.payload.minor;
            state.aboutYouReq = { ...state.aboutYouReq,minor:minor };
        },
        updateParentEmail: (state, action) => {
            let email = action.payload.parentEmailId;
            state.aboutYouReq = { ...state.aboutYouReq,parentEmailId:email };
        },
        updatePostObject: (state, action) => {
            state.postObj= action.payload;
        },
        updateAnswer: (state, action) => {

            let ques = action.payload.ques;
            let ans = action.payload.ans;
            let hct = state.aboutYouReq.hcTestResponse.find(
                (res) => res.questionNbr === ques
            );
            hct.answerNbr = ans;
            state.aboutYouReq = { ...state.aboutYouReq };
        },
        updateRequestApplicantId: (state, action) => {
             state.aboutYouReq.hcTestResponse.map(
                (res) => res.applicantId =  action.payload.applicantId
            );
            state.aboutYouReq = { ...state.aboutYouReq };
        },
        updateResponseCodeAndMessage:(state,action)=>{
            state.responseCode = action.payload.responseCode;
            state.responseMessage = action.payload.responseMessage;
        },
        updateAboutYouHcTestReponseAnswer:(state, action) => {
            let hcTest = state.applInfo.hcTestResponse;
            let testIndex = hcTest.findIndex(obj=>obj.questionNbr === action.payload.questionNbr 
                && obj.pretestCode === action.payload.pretestCode 
                && obj.revisionNbr === action.payload.revisionNbr);
            hcTest[testIndex >= 0 ? testIndex : hcTest.length]=action.payload;
            state.applInfo = { ...state.applInfo, hcTestResponse: hcTest }
        },
        updateAboutYouParentInfo:(state, action) => {
            state.applInfo = { ...state.applInfo, parentFullName: action.payload.parentFullName, parentEmailId: action.payload.parentEmailId}
        }
       
    },
    extraReducers(builder) {
        builder.addCase(getPersonalInfo.fulfilled, (state, action) => {
            state.applInfo = action.payload;
        });
    },
});

export const {
    updateFirstName,
    updateLasttName,
    updateSSN,
    updatePersonalInfo,
    updateAboutYouRequest,
    updateAnswer,
    updateApplicantId,
    updateCheckHire,
    updateCountryCodeAboutYou,
    updateParentEmail,
    updateminorStatus,
    updateAboutYouReqStep1,
    updatePostObject,
    updateRequestApplicantId,
    updateResponseCodeAndMessage,
    updateAboutYouHcTestReponseAnswer,
    updateAboutYouParentInfo
} = aboutYouSlice.actions;

export default aboutYouSlice.reducer;
