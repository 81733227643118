import { useCallback, useEffect, useState } from 'react';
import { isEmpty, omit } from 'lodash';
import {
	emailPattern,
	parseMobileNumber,
	isValidNumber,
	removeInvalidChars,
	validateZip
} from '../../../utils/validation';
import {
	formatIncompletePhoneNumber,
	isValidPhoneNumber,
	parsePhoneNumber
} from 'libphonenumber-js';
import { keys } from '../../../pages/RenewApplication/Components/ContactInformation';
import { useDispatch, useSelector } from 'react-redux';
import { saveContactInfo, contactPanelError, zipCodeError } from '../../../redux/Renew/slice';

const useForm = (data) => {
	const dispatch = useDispatch();

	const global = useSelector((state) => state.global);
	const { isZipCodeError } = useSelector((state) => state.renew);
	//Form values
	const [values, setValues] = useState({
		...data,
		phoneNumber: data?.phoneNumber
			? formatIncompletePhoneNumber(data?.phoneNumber)
			: data?.phoneNumber
	});
	//Errors
	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (values[keys.isStudent] && !isMinor() && errors.hasOwnProperty(keys.parentConsentName)) {
			const errorObj = omit(errors, keys.parentConsentName);
			setErrors(errorObj);
		}
		if (values[keys.isStudent] && !isMinor() && errors.hasOwnProperty(keys.parentConsentMail)) {
			const errorObj = omit(errors, keys.parentConsentMail);
			setErrors(errorObj);
		}
		// eslint-disable-next-line
	}, [values[keys.isStudent], values[keys.age]]);

	const validateFields = (value) => {
		return value !== '' && value !== null && value !== undefined;
	};

	const validate = (name, value, error) => {
		let errorMsg = null;
		let isValid = false;
		//A function to validate each input values
		switch (name) {
			case keys.firstName:
				isValid = validateFields(value);
				errorMsg = 'renew.firstNameError';
				break;
			case keys.parentConsentName:
				if (isMinor()) {
					isValid = validateFields(value);
					errorMsg = 'renew.parentNameError';
				}
				break;
			case keys.parentConsentMail:
				if (isMinor()) {
					isValid = emailPattern.exec(value?.trim());
					errorMsg = 'renew.emailError';
				}
				break;
			case keys.lastName:
				isValid = validateFields(value);
				errorMsg = 'renew.lastNameError';
				break;
			case keys.zipCode:
				isValid = validateZip(value);
				if (isZipCodeError) {
					isValid = false;
				}
				errorMsg = 'renew.zipError';
				break;
			case keys.phoneNumber:
			case keys.altContactNumber:
				if (error) {
					isValid = true;
				}
				if (error === '') {
					if (name === 'alternatePhoneNumber') {
						isValid = isEmpty(value?.trim()) ? true : isValidPhoneNumber(value, global.countryCode);
					} else {
						isValid = !isEmpty(value?.trim()) && isValidPhoneNumber(value, global.countryCode);
					}
				}
				errorMsg = 'renew.phoneError';
				break;
			case keys.isStudent:
				isValid = validateFields(value);
				errorMsg = 'renew.studentError';
				break;
			case keys.age:
				isValid = validateFields(value);
				errorMsg = 'renew.ageError';
				break;
			default:
				break;
		}
		setErrorMsg(name, isValid, errorMsg);
		return !isValid ? errorMsg : null;
	};

	const updateFormValue = (data) => {
		setValues(data);
	};
	const isMinor = () => {
		const age = values[keys.age];
		const isStudent = values[keys.isStudent];
		// 119 for highSchool , age is <16 then 93, between 16-17 94
		return isStudent === 119 || age === 93 || age === 94;
	};
	const handleContactInfoSave = () => {
		const isValid = isRenewFormValid();
		if (!isValid) {
			dispatch(contactPanelError(true));
			dispatch(zipCodeError(false));
			return;
		}
		const valuesToSave = values;
		dispatch(contactPanelError(false));
		dispatch(saveContactInfo(valuesToSave));
	};

	// A Method to handle form checkbox
	const handleCheckboxChange = (event) => {
		event.persist();
		const id = event.target.id;
		const val = event.target.checked;
		setValues({
			...values,
			[id]: val === true ? 11 : 12
		});
	};

	const handleRadioChange = (event, name) => {
		event.persist();
		const id = name;
		let val = event.target.name;
		val = typeof val === 'string' ? parseInt(val) : val;
		validate(id, val, '');
		setValues({
			...values,
			[name]: val
		});
	};

	//A method to handle form inputs
	const handleTextChange = (event) => {
		//To stop default events
		event.persist();

		const id = event.target.id;
		let val = event.target.value;

		if (id === keys.firstName) {
			if (val === '') {
				val = '';
			}
			val = removeInvalidChars(val, global.countryCode);
			val = val?.slice(0, 15);
		}

		if (id === keys.middleName) {
			if (val === '') {
				val = '';
			}
			val = removeInvalidChars(val, global.countryCode);
			val = val?.slice(0, 1);
		}

		if (id === keys.lastName) {
			if (val === '') {
				val = '';
			}
			val = removeInvalidChars(val, global.countryCode);
			val = val?.slice(0, 20);
		}

		if (id === keys.parentConsentName) {
			if (val === '') {
				val = '';
			}
			val = removeInvalidChars(val, global.countryCode);
			val = val?.slice(0, 40);
		}

		if (id === keys.zipCode) {
			const valid = validateZipCode(val);
			if (val === '') {
				val = '';
			} else {
				if (!valid) {
					return;
				}
			}
		}

		let phnError = false;
		let charError = false;
		if ((id === keys.phoneNumber || id === keys.altContactNumber) && val?.length > 0) {
			const value = id === keys.phoneNumber ? 'phoneNumber' : 'alternatePhoneNumber';
			const parsedMobileNumber = parseMobileNumber(val.substr(val.indexOf('(') + 1));
			const isValidMobileNumber = isValidNumber(parsedMobileNumber);
			if (!isValidMobileNumber) {
				if (val?.length > 14 || (val?.length > 10 && val.indexOf('(') === -1)) {
					val = values[value];
				} else {
					charError = true;
				}
			} else if (val && val.length <= 6) {
				val = val.length > 1 ? parsePhoneNumber(val, global.countryCode).nationalNumber : val;
			} else {
				if (val.indexOf('0') === 0 || val.indexOf('1') === 0) {
					val = val.substr(1, 7);
				}
				val = formatIncompletePhoneNumber(val, global.countryCode);
			}

			if (
				typeof val === 'undefined' ||
				val === '' ||
				parsedMobileNumber.length < 10 ||
				isValidPhoneNumber(val, global.countryCode) !== true
			) {
				phnError = true;
			}
			if (charError) {
				if (val?.length > 1) {
					charError = false;
					val = values[value];
				} else {
					val = '';
				}
			}
		}
		validate(id, val, charError || !phnError);
		setValues({
			...values,
			[id]: val
		});
	};

	const isRenewFormValid = useCallback(() => {
		const formKeys = Object.values(keys);
		const errorObj = {};

		for (const key of formKeys) {
			const val = values[key];
			const errorMsg = validate(key, val, '');
			if (errorMsg?.length > 0) {
				errorObj[key] = errorMsg;
			}
		}

		if (Object.keys(errorObj).length > 0) {
			setErrors(errorObj);
			return false;
		}
		return true;
		// eslint-disable-next-line
	}, [values, isZipCodeError]);

	const setErrorMsg = (id, isValid, errorMsg) => {
		if (!isValid) {
			setErrors({
				...errors,
				[id]: errorMsg
			});
		} else {
			const newObj = omit(errors, id);
			setErrors(newObj);
		}
	};

	const validateZipCode = (value) => {
		if (typeof value === 'undefined' || !value) {
			return false;
		}
		const regex = /^[0-9]*$/;
		return regex.test(value);
	};

	return {
		values,
		errors,
		handleTextChange,
		handleCheckboxChange,
		handleRadioChange,
		handleContactInfoSave,
		updateFormValue,
		isMinor,
		isRenewFormValid
	};
};

export default useForm;
