import { Body, Button, Divider } from '@walmart-web/livingdesign-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import PropTypes from 'prop-types';
import { useVjoStepIndicator } from '../../../hooks/useVjoStepIndicator';
import { useDisplay } from 'utils/useDisplay';
/**
 * Virtual Job Offer footer view
 */
const FooterView = ({ showDeclineOffer }) => {
	const { t } = useTranslation();
	const { updateStepIndicator } = useVjoStepIndicator();
	const { isPortrait } = useDisplay();

	return (
		<>
			<Divider UNSAFE_className="divider_block" />
			<div className="job_question_block">
				<Body
					size="medium"
					weight={400}
					UNSAFE_className="job_question_begin"
					children={t('virtualJobOffer.questionToBegin')}
				/>
			</div>
			<div className="vjo_footer_buttons">
				<Button
					size={isPortrait ? 'small' : 'medium'}
					children={t('virtualJobOffer.declineJobOffer')}
					UNSAFE_className="vjo_footer_button"
					onClick={showDeclineOffer}
				/>
				<Button
					size={isPortrait ? 'small' : 'medium'}
					variant="primary"
					children={t('virtualJobOffer.getStarted')}
					UNSAFE_className="vjo_footer_button"
					onClick={updateStepIndicator}
				/>
			</div>
		</>
	);
};
/**
 * PropTypes for the FooterView component
 */
FooterView.propTypes = {
	showDeclineOffer: PropTypes.func.isRequired
};
export default FooterView;
