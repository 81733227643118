import { createSlice } from '@reduxjs/toolkit';
import { environment } from '../../utils/environment/environment';
import cookie from 'react-cookies';
import { getGeneralizedErrorMsg } from 'utils/errorDecorator';
import { t } from 'i18next';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
	key : 'global',
	version : 1,
	storage : storage,
	whitelist : ['isCpRedirect', 'preferedStoresFromRedirect', 'preferedJobsFromRedirect']
}

const initialState = {
	countryCode: 'US',
	location: {
		loaded: false,
		coordinates: { lat: '', long: '' }
	},
	acceptedDisclaimer: false,
	timeZone: '',
	loading: false,
	language: 'EN',
	preferredLangOpen: false,
	languageToggle: {
		display: true
	},
	applicantId: 0,
	emailId: '',
	error: {
		open: false,
		message: '',
		title: ''
	},
	subHeader: {
		title: '',
		routeTo: '',
		display: false
	},
	path: '',
	showSpark: true,
	dialog: {
		open: false,
		title: '',
		message: ''
	},
	jobType: '',
	displayFooter: true,
	jobCountryType: '',
	preferredJobWarehouse: '',
	isCpRedirect : false,
	preferedStoresFromRedirect : [],
	preferedJobsFromRedirect : []
};

const hiringCenterPath = '/HiringCenter';

export const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		updateCountryCode: (state, action) => {
			state.countryCode = action.payload === 'PR' ? 'PR' : 'US';
			state.language = action.payload === 'PR' ? 'ES' : 'EN';
		},
		updateTimeZone: (state, action) => {
			state.timeZone = action.payload;
		},
		updateLoading: (state, action) => {
			state.loading = action.payload;
		},
		updateLocation: (state, action) => {
			state.location = action.payload;
		},
		updateLanguage: (state, action) => {
			state.language = action.payload;
			state.preferredLangOpen = false;
		},
		updatePreferredLangOpen: (state, action) => {
			state.preferredLangOpen = action.payload;
		},
		updateLanguageToggle: (state, action) => {
			state.languageToggle = action.payload;
		},
		loggedInApplicantId: (state, action) => {
			state.applicantId = action.payload;
		},
		loggedInEmailId: (state, action) => {
			state.emailId = action.payload;
		},
		errorOccured: (state, action) => {
			if (state.error.message === ''  || action?.payload?.message === '') {
				state.error = action.payload;
			}
		},
		executionErrorOccured: (state) => {
			if(state.error.message===''){
				state.error = {
					title: 'general.sorry',
					message: 'general.somethingWentWrong',
					canBeTranslated: true
				};
			}
			state.loading = false;
		},
		apiRequestErrorOccured: (state, action) => {
			state.error = {
				title : t('general.error'),
				message : getGeneralizedErrorMsg(action.payload),
				canBeTranslated : false
			}
			state.loading = false;
		},
		wotcDownErrorOccured: (state) => {
			state.error = {
				title: 'wotc.error',
				message: 'wotc.continueForward',
				canBeTranslated: true,
				isWotcError: true
			}
		},
		subHeader: (state, action) => {
			state.subHeader = action.payload;
		},
		path: (state, action) => {
			state.path = action.payload;
		},
		showSpark: (state, action) => {
			state.showSpark = action.payload;
		},
		acceptedDisclaimer: (state, action) => {
			state.acceptedDisclaimer = action.payload;
		},
		updateGlobalDialog: (state, action) => {
			state.dialog = action.payload;
		},
		openGlobalDialog: (state, action) => {
			state.dialog.open = action.payload;
		},
		updateJobType: (state, action) => {
			state.jobType = action.payload;
		},
		updateJobCountryType: (state, action) => {
			state.jobCountryType = action.payload;
		},
		updatePreferredJobWarehouse: (state, action) => {
			state.preferredJobWarehouse = action.payload;
		},
		updateIsCpRedirect : (state, action) => {
			state.isCpRedirect = action.payload;
		},
		updatePreferedStoresFromRedirect : (state, action) => {
			state.preferedStoresFromRedirect = action.payload;
			state.isCpRedirect = !!(
				state.preferedStoresFromRedirect?.length || state.preferedJobsFromRedirect?.length
			);
		},
		removePreferredStoreFromCRM : (state, action) => {
			if (action.payload.checked) {
                return;
            }
            state.preferedStoresFromRedirect = state.preferedStoresFromRedirect.filter(
                (store) => store !== +action.payload.storeNumber
            );
			state.isCpRedirect = !!(
				state.preferedStoresFromRedirect?.length || state.preferedJobsFromRedirect?.length
			);
		},
		updatePreferedJobsFromRedirect : (state, action) => {
			state.preferedJobsFromRedirect = action.payload;
			state.isCpRedirect = !!(
				state.preferedStoresFromRedirect?.length || state.preferedJobsFromRedirect?.length
			);
		},
		onLogOut: (state) => {
			state.loading = true;
			sessionStorage.clear();
			localStorage.clear();
			let updatedCookies = document.cookie;
			updatedCookies.split(';').forEach((c) => {
				document.cookie = c
					.replace(/^ +/, '')
					.replace(/=.*/, '=;expires='.concat(new Date().toUTCString()).concat(';path=/'));
			});
			
			updatedCookies.split(';').forEach((c) => {
				document.cookie = c
					.replace(/^ +/, '')
					.replace(
						/=.*/,
						'=;expires='.concat(new Date().toUTCString()).concat(';path=/HiringCenter')
					);
			});

			cookie.remove('userId', { path: hiringCenterPath });
			cookie.remove('emailId', { path: hiringCenterPath });
			cookie.remove('applSessionToken', { path: hiringCenterPath });
			cookie.remove('firstName', { path: hiringCenterPath });
			cookie.remove('lastName', { path: hiringCenterPath });
			state.emailId = '';
			state.applicantId = 0;

			if (environment.isPingfed === 'true') {
				window.location.replace(environment.pingFedLogoutUrl);
			} else {
				window.location.href = '/HiringCenter/disclaimer';
			}
		},
		displayFooter: (state, action) => {
			state.displayFooter = action.payload;
		}
	}
});

const persistedReducer = persistReducer(persistConfig, globalSlice.reducer);

// Action creators are generated for each case reducer function
export const {
	updateCountryCode,
	updateTimeZone,
	updateLoading,
	updateLocation,
	updateLanguage,
	updatePreferredLangOpen,
	updateLanguageToggle,
	loggedInApplicantId,
	loggedInEmailId,
	errorOccured,
  	executionErrorOccured,
  	apiRequestErrorOccured,
	wotcDownErrorOccured,
	subHeader,
	path,
	showSpark,
	acceptedDisclaimer,
	updateGlobalDialog,
	openGlobalDialog,
	updateJobType,
	onLogOut,
	displayFooter,
	updateJobCountryType,
	updatePreferredJobWarehouse,
	updateIsCpRedirect,
	updatePreferedJobsFromRedirect,
	updatePreferedStoresFromRedirect,
	removePreferredStoreFromCRM
} = globalSlice.actions;

export default persistedReducer;
