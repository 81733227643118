import { Body } from '@walmart-web/livingdesign-components';
import React, { useEffect } from 'react';
import './style.scss';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { displayFooter } from 'redux/slices/globalSlice';
import { getOutagePeriod } from 'redux/Disclaimer/request';
import { useNavigate } from 'react-router';

const SplashScreen = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getOutagePeriod())
			.unwrap()
			.then((result) => {
				if (!result.maintanance) {
					navigate('../disclaimer');
				}
			});
	}, []);
	useEffect(() => {
		dispatch(displayFooter(false));
	});
	return (
		<Body as="div" UNSAFE_className="splashscreen_container">
			<div className={'image_container'}>
				<img alt="maintenance" src={require('../../assets/images/maintenance.png')} />
			</div>
			<div className="splashscreen_text">
				<div className="splashscreen_title">{t('general.maintenance')}</div>
				<div className="splashscreen_subtext">{t('general.checkBackLater')}</div>
			</div>
			<div className="row">
				<img
					className={'walmart_title'}
					src={require('../../../src/assets/images/walmart-title.png')}
					alt="walmart-logo"
				/>
			</div>
		</Body>
	);
};

export default SplashScreen;
