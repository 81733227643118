import React from 'react';
import { Heading } from '@walmart-web/livingdesign-components';
import './style.scss';

const CustomLabel = (props) => {
  return (
    <div className="custom_label">
      <img src={props.icon} alt="label header"/>
      <Heading as="h1" size="small" weight={700}>
        {props.title}
      </Heading>
    </div>
  );
};

export default CustomLabel;
