import { Checkbox, Select, Body } from '@walmart-web/livingdesign-components';
import mystyle from './Day.module.css';
import React, { useState, useEffect } from 'react';
import {
	shiftTimings,
	shiftTimingsOpeneing,
	shiftTimingsMorning,
	shiftTimingsMidShift,
	shiftTimingsClosing,
	shiftTimingsOvernight,
	shiftIds,
	FULL_TIME_MIN_HOURS,
	FULL_TIME_MAX_HOURS
} from '../../../config/const';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AvailabilityHelper } from '../AvailabilityHelper';
import renewstyle from '../../RenewApplication/Components/ShiftAvailability/style.module.css';

const Day = (props) => {
	const { t } = useTranslation();
	const [checked, setChecked] = useState(false);
	const [arr, setArr] = useState([]);
	const [toArr, setToArr] = useState(shiftTimings);
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const day = props.label;
	const [selectDisabledStart, setSelectDisabledStart] = useState(true);
	const [selectDisabledEnd, setSelectDisabledEnd] = useState(true);
	const availMainRedux = useSelector((state) => state.availMain);
	const { validateTiming, validateTimingSingleIp } = AvailabilityHelper();
	const isFullTime = availMainRedux?.employmentType?.fullTime;

	const uniq = (a) => {
		return Array.from(new Set(a));
	};

	const onChangeCheckBox = () => {
		let isCheck = !checked;
		setChecked(isCheck);
		if (!isCheck) {
			const st = '--';
			const et = '--';
			isCheck = false;
			setStartTime(st);
			setEndTime(et);
			setSelectDisabledStart(true);
			setSelectDisabledEnd(true);
			props.scheduleAvail(st, et, day, isCheck, false, false);
		} else {
			const st = '--';
			const et = '--';
			setStartTime(st);
			setEndTime(et);
			setSelectDisabledStart(false);
			props.scheduleAvail(st, et, day, isCheck, false, false);
			copyPrompt();
		}
	};

	const copyPrompt = () => {
		const timing = availMainRedux.scheduleAvailability;
		const timeArr = [];
		const dayArr = [];

		if (validateTiming(timing.monStartTime, timing.monEndTime)) {
			timeArr.push(timing.monStartTime);
			timeArr.push(timing.monEndTime);
			dayArr.push('Mon');
		}
		if (validateTiming(timing.tueStartTime, timing.tueEndTime)) {
			timeArr.push(timing.tueStartTime);
			timeArr.push(timing.tueEndTime);
			dayArr.push('Tue');
		}

		if (validateTiming(timing.wedStartTime, timing.wedEndTime)) {
			timeArr.push(timing.wedStartTime);
			timeArr.push(timing.wedEndTime);
			dayArr.push('Wed');
		}
		if (validateTiming(timing.thuStartTime, timing.thuEndTime)) {
			timeArr.push(timing.thuStartTime);
			timeArr.push(timing.thuEndTime);
			dayArr.push('Thu');
		}

		if (validateTiming(timing.friStartTime, timing.friEndTime)) {
			timeArr.push(timing.friStartTime);
			timeArr.push(timing.friEndTime);
			dayArr.push('Fri');
		}
		if (validateTiming(timing.satStartTime, timing.satEndTime)) {
			timeArr.push(timing.satStartTime);
			timeArr.push(timing.satEndTime);
			dayArr.push('Sat');
		}

		if (validateTiming(timing.sunStartTime, timing.sunEndTime)) {
			timeArr.push(timing.sunStartTime);
			timeArr.push(timing.sunEndTime);
			dayArr.push('Sun');
		}

		if (timeArr.length === 2) {
			props.copyPrompt(timeArr[0], timeArr[1], dayArr[0], true);
		}
	};

	const handleShiftTimingsTo = (e) => {
		const value = e.target.value;
		setEndTime(value);
		if (!isFullTime) {
			setToArr(uniq(shiftTimings));
		}
		props.scheduleAvail(startTime, value, day, checked, false, false);
	};
	const handleShiftTimings = (e) => {
		const value = e.target.value;
		setStartTime(value);
		let toTime = endTime;
		if (isFullTime) {
			toTime = autoIncrementTiming(value, FULL_TIME_MIN_HOURS);
			setEndTime('');
			populateEndTime(toTime);
		} else {
			setToArr(uniq(shiftTimings));
		}
		props.scheduleAvail(value, toTime, day, checked, false, true);
		setSelectDisabledEnd(false);
	};

	const populateEndTime = (eTime) => {
		const maxEndTime = autoIncrementTiming(eTime, FULL_TIME_MAX_HOURS-FULL_TIME_MIN_HOURS);
		const minIndex = shiftTimings.indexOf(eTime);
		const maxIndex = shiftTimings.indexOf(maxEndTime);
		if (minIndex !== -1 && maxIndex !== -1) {
			if (minIndex > maxIndex) {
				populateToArray(minIndex, maxIndex);
			} else {
				setToArr(uniq(shiftTimings.slice(minIndex, maxIndex + 1)));
			}
		}
	};

	const populateToArray = (minIndex, maxIndex) => {
		const sArr = [];
		const eArr = shiftTimings.slice(0, maxIndex + 1);
		sArr.push(shiftTimings.slice(minIndex, shiftTimings.length));
		const outArr = sArr[0].concat(eArr);
		setToArr(uniq(outArr));
	};

	const autoIncrementTiming = (value, hourstoIncrement) => {
		return moment('Mon Feb 07 2011 ' + value)
			.add(hourstoIncrement, 'hours')
			.format('h:mm a');
	};

	useEffect(() => {
		setChecked(props.checked);
		setStartTime(props.startTime);
		setEndTime(props.endTime);
		splitTimeBasedOnShift();
		if (isFullTime) {
			if (validateTiming(props.startTime, props.endTime)) {
				const endTiming = autoIncrementTiming(props.startTime, FULL_TIME_MIN_HOURS);
				populateEndTime(endTiming);
			}
			if (props.endTime === '--' || props.endTime === '') {
				setSelectDisabledEnd(true);
			}
		} else {
			setToArr(uniq(shiftTimings));
		}
		if (props.startTime !== '' && props.endTime !== '') {
			if (props.checked) {
				setSelectDisabledStart(false);
				if (props.startTime !== '--' && props.endTime !== '--') {
					setSelectDisabledEnd(false);
				}
			} else {
				setSelectDisabledStart(true);
				setSelectDisabledEnd(true);
			}
		} else if (props.startTime !== '') {
			setSelectDisabledStart(false);
			setSelectDisabledEnd(false);
		} else {
			setSelectDisabledStart(true);
			setSelectDisabledEnd(true);
		}
		// eslint-disable-next-line
	}, [
		props.startTime,
		props.endTime,
		availMainRedux.shiftPreference.shift,
		availMainRedux.employmentType
	]);

	useEffect(() => {
		if (
			isFullTime &&
			validateTiming(props.startTime, props.endTime) &&
			!toArr.includes(props.endTime)
		) {
			setRefreshValues();
		}
		// eslint-disable-next-line
	}, [toArr]);

	const splitTimeBasedOnShift = () => {
		let startIndex = 0;
		let endIndex = 0;
		availMainRedux.shiftPreference.shift.forEach((sh) => {
			if (sh === shiftIds.OPENING_SHIFT_ID) {
				startIndex = shiftTimingsOpeneing.startTime;
				endIndex = shiftTimingsOpeneing.endTime;
			} else if (sh === shiftIds.MORNING_SHIFT_ID) {
				if (startIndex === 0) {
					startIndex = shiftTimingsMorning.startTime;
				}
				endIndex = shiftTimingsMorning.endTime;
			} else if (sh === shiftIds.MID_SHIFT_SHIFT_ID) {
				if (startIndex === 0) {
					startIndex = shiftTimingsMidShift.startTime;
				}
				endIndex = shiftTimingsMidShift.endTime;
			} else if (sh === shiftIds.CLOSING_SHIFT_ID) {
				if (startIndex === 0) {
					startIndex = shiftTimingsClosing.startTime;
				}
				endIndex = shiftTimingsClosing.endTime;
			} else if (sh === shiftIds.OVERNIGHT_SHIFT_ID) {
				if (startIndex === 0) {
					startIndex = shiftTimingsOvernight.startTime;
				}
				endIndex = shiftTimingsOvernight.endTime;
			}
		});
		setArr(uniq(shiftTimings.slice(startIndex, endIndex + 1)));
		refereshTimeOnEmpShift(startIndex, endIndex);
	};

	const refereshTimeOnEmpShift = (startIndex, endIndex) => {
		const shiftRangeArr = shiftTimings.slice(startIndex, endIndex + 1);
		if (
			props.startTime !== '' &&
			props.startTime !== '--' &&
			shiftRangeArr.indexOf(props.startTime) === -1
		) {
			setRefreshValues();
		}
	};

	const setRefreshValues = () => {
		setStartTime('--');
		setEndTime('--');
		setChecked(false);
		setSelectDisabledStart(false);
		setSelectDisabledEnd(false);
		props.scheduleAvail('--', '--', day, false, true, false);
	};

	const block = () => {
		return <>{props.showTitle && <div style={{height: '25px'}}/>}</>
	}
	const title = (title) => {
		return <>{props.showTitle && <Body as='div' size='small' weight={700} UNSAFE_className={mystyle.title}>{t(title)}</Body>} </>
	}

	return (
		<div className={mystyle.avail} style={{ width: '100%' }}>
			<div className={!props.isRenew ? mystyle.shift : renewstyle.shift}>
				<div className={!props.isRenew ? mystyle.checkBox1 : renewstyle.checkBox1}>
					{block()}
					<Checkbox label={props.label} checked={checked} onChange={onChangeCheckBox} />
				</div>
				<div>
					{title('availability.earliestTime')}
					<Select
						disabled={selectDisabledStart}
						onChange={(e) => {
							handleShiftTimings(e);
						}}>
						<option defaultValue={startTime} defaultChecked>
							{startTime === '' ? '--' : startTime}
						</option>

						{checked ? (
							arr.map((val, index) => {
								return (
									<>
										{val === startTime ? (
											''
										) : (
											<option key={index} value={val}>
												{val}
											</option>
										)}
									</>
								);
							})
						) : (
							<option value=""> {validateTimingSingleIp(startTime)} </option>
						)}
					</Select>
				</div>
				<div className={mystyle.to}>
					{block()}
					<Body as="p" size="medium">
						{' '}
						{t('availability.to')}
					</Body>
				</div>
				<div>
					{title('availability.latestTime')}
					<Select
						disabled={selectDisabledEnd}
						onChange={(e) => {
							handleShiftTimingsTo(e);
						}}>
						<option defaultValue={endTime} defaultChecked>
							{endTime === '' ? '--' : endTime}
						</option>
						{checked ? (
							toArr.map((val, index) => {
								return val === startTime ? (
									<option key={index} value={val} disabled>
										{val}
									</option>
								) : (
									<>
										{val === endTime ? (
											''
										) : (
											<option key={index} value={val}>
												{val}
											</option>
										)}
									</>
								);
							})
						) : (
							<option value=""> {validateTimingSingleIp(endTime)} </option>
						)}
					</Select>
				</div>
			</div>
		</div>
	);
};
export default Day;
