import { useVirtualJobOffer } from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getDeclineOffer} from 'redux/DeclineOffer/request';
import {useNavigate} from 'react-router';
import {isEmptyObject} from 'utils/validation';

/**
 * Custom hook to handle decline offer logics
 * @returns onDeclineOffer - callback to decline offer
 */
export const useDeclineOffer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {account, isSupplyChain} = useVirtualJobOffer();
  const declineOffer = useSelector((state) => state.declineOffer?.data);

  const [isOfferDeclined, setIsOfferDeclined] = useState(false);

  useEffect(() => {
    if (!isEmptyObject(declineOffer)) {
      setIsOfferDeclined(true);
    }
  }, [declineOffer, navigate]);

  // Decline offer callback
  const onDeclineOffer = useCallback(
    (reasonCode) => {
      const {applicantId, storeNbr, reqsnId, jobOfferNbr} =
        account?.data?.auth?.vjo;
      if (applicantId && storeNbr && reqsnId && jobOfferNbr && reasonCode) {
        dispatch(
          getDeclineOffer({
            storeNbr: storeNbr,
            isSupplyChain: isSupplyChain,
            requestInput: {
              applicantId: applicantId,
              requisitionId: reqsnId,
              jobOfferNbr: jobOfferNbr,
              reasonCode: Number(reasonCode),
              nextStepInd: 1,
            },
          }),
        );
      }
    },
    [account, dispatch, isSupplyChain],
  );
  return {
    onDeclineOffer,
    isOfferDeclined,
  };
};
