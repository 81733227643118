import {
  Body,
  Card,
  Grid,
  GridColumn,
} from '@walmart-web/livingdesign-components';
import {isEmpty} from 'lodash';
import {useVirtualJobOffer} from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import JobHourItem from './JobHourItem';

const JobHoursContainer = () => {
  const {t} = useTranslation();
  const {jobAvailability, isSupplyChain} = useVirtualJobOffer();

  const JobInfoData = useMemo(() => {
    if (isEmpty(jobAvailability)) {
      return [];
    }
    return [
      {
        title: t('days.sat'),
        Start: jobAvailability?.sat_start_time,
        End: jobAvailability?.sat_end_time,
      },
      {
        title: t('days.sun'),
        Start: jobAvailability?.sun_start_time,
        End: jobAvailability?.sun_end_time,
      },
      {
        title: t('days.mon'),
        Start: jobAvailability?.mon_start_time,
        End: jobAvailability?.mon_end_time,
      },
      {
        title: t('days.tue'),
        Start: jobAvailability?.tue_start_time,
        End: jobAvailability?.tue_end_time,
      },
      {
        title: t('days.wed'),
        Start: jobAvailability?.wed_start_time,
        End: jobAvailability?.wed_end_time,
      },
      {
        title: t('days.thu'),
        Start: jobAvailability?.thu_start_time,
        End: jobAvailability?.thu_end_time,
      },
      {
        title: t('days.fri'),
        Start: jobAvailability?.fri_start_time,
        End: jobAvailability?.fri_end_time,
      },
    ];
  }, [jobAvailability, t]);

  if (JobInfoData?.length === 0) {
    return null;
  }
  return (
    <Card UNSAFE_className='job_hour_container'>
      <Body
        children={
          isSupplyChain
            ? t('virtualJobOffer.SChoursTitle')
            : t('virtualJobOffer.hoursTitle')
        }
        weight={700}
        size='medium'
      />
      <Grid hasGutter={false} className='job_hour_grid'>
        {JobInfoData.map((hourDetail, index) => {
          return (
            <GridColumn
              sm={1}
              key={index}
              UNSAFE_className='job_hour_grid_column'>
              <JobHourItem
                day={hourDetail?.title}
                startTime={hourDetail?.Start}
                endTime={hourDetail?.End}
              />
            </GridColumn>
          );
        })}
      </Grid>
      <Body
        children={
          isSupplyChain
            ? t('virtualJobOffer.SChoursDescription')
            : t('virtualJobOffer.hoursDescription')
        }
        size='medium'
        UNSAFE_className='job_hour_description'
      />
    </Card>
  );
};

export default JobHoursContainer;
