import React from 'react';
import { Heading, Body, Tag, Button } from '@walmart-web/livingdesign-components';
import { ChevronRight, Store, Truck } from '@livingdesign/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import rightArrow from '../../../../../../assets/images/remove.svg';
import './style.scss';

const AddressBox = (props) => {
  const {
    isStore,
    storeInfo: { streetAddress, address, storeName, storeNbr }
  } = props.storeInfo;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (e) => {
    navigate(`/applied-job-tracker/${storeNbr}`);
  };

  return (
    <div className="addressbox_container" onClick={(e) => handleClick(e)}>
      <div>
        <Heading as="h1" size="small" UNSAFE_className="addressbox_heading">
          {storeName}
        </Heading>
        <Body as="p" size="small" UNSAFE_className="addressbox_storeInfo">
          {`${streetAddress}\n${address}`}
        </Body>
        <Tag
          leading={isStore ? <Store size="small" /> : <Truck size="small" />}
          color = {isStore ? "blue" : "purple"}
        >
          {t(isStore ? 'general.store' : 'general.warehouse')}
        </Tag>
      </div>
      {props?.isRenew ? (
        <Button  trailing={ <img src={rightArrow} alt="right arrow"/>} size="small" variant="tertiary">Remove</Button>

      ) : (
        <ChevronRight size='medium' />
      )}
    </div>
  );
};

export default AddressBox;
