import {
  Body,
  Button,
  Card,
  Heading,
} from '@walmart-web/livingdesign-components';
import AlertDialog from 'components/AlertDialog';
import JobPreference from 'pages/RenewApplication/Components/ReviewJobPreference';
import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import { useNavigate } from 'react-router';
import {useUpdatePreference} from './hook/useUpdatePreference';
import './styles.scss';

/**
 * Update Preference screen.
 * @returns - memoized view
 */
const UpdatePreference = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {updatePreference, isUpdated, ALERT_ACTIONS, onAlertActionClick} =
    useUpdatePreference();

  const AlertActions = useMemo(() => {
    return (
      <div className='preference_alert_action'>
        <Button
          size='medium'
          variant='secondary'
          children={t('updatePreference.no')}
          UNSAFE_className='preference_alert_button'
          onClick={() => onAlertActionClick(ALERT_ACTIONS.NO)}
        />
        <Button
          size='medium'
          variant='primary'
          children={t('updatePreference.yes')}
          UNSAFE_className='preference_alert_button'
          onClick={() => onAlertActionClick(ALERT_ACTIONS.YES)}
        />
      </div>
    );
  }, [t, onAlertActionClick, ALERT_ACTIONS.YES, ALERT_ACTIONS.NO]);

  const contentView = useMemo(() => {
    return (
			<Card size="small" UNSAFE_className="upd_pref_body">
				<>
					<Heading size="small" weight={700} children={t('updatePreference.header')} />
					<JobPreference vjoHandler={updatePreference} />
				</>
			</Card>
		);
  }, [t, updatePreference]);

  const onAlertClose = () => {
    navigate('../login');
  };

  return (
    <div>
      <div className='upd_pref_header'>
        <Heading
          children={t('updatePreference.title')}
          UNSAFE_className='upd_header_title'
        />
      </div>
      {contentView}
      <AlertDialog
        isOpen={isUpdated}
        onClose={onAlertClose}
        title={t('updatePreference.title')}
        children={<Body children={t('updatePreference.alertTitle')} />}
        actions={AlertActions}
      />
    </div>
  );
};

export default memo(UpdatePreference);
