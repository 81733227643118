import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import CustomTextField from 'components/CustomTextField/CustomTextField';
import RadioList from 'components/RadioList/RadioList';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomDateField from 'components/CustomDateField/CustomDateField';
import { getInputLabel, removeHtmlTags, wotcElementTypes } from './util';

import styles from './TaxQuestionnaire.module.scss';

const Questions = ({ data, onChangeAnsCallback, onValidationCallback }) => {
	const questionnaireAns = useSelector((state) => state.questionnaire?.questionnaireAns || {});
	const isSubmissionScreen = useSelector((state) => state.questionnaire?.isSubmissionScreen);
	const validationError = useSelector((state) => state.questionnaire?.validationError || {});

	// For showing error from third party apis. Won't disable "continue" button
	const showAdditionalError = (currentValue, savedValue, error) => {
		return currentValue === savedValue ? error : null;
	};

	const getInputOptionsComponent = (data) => {
		const commonProps = {
			defaultValue: questionnaireAns[data.elementId]?.value || data.defaultValue,
			label: removeHtmlTags(data.labelText),
			showAdditionalError: (value) =>
				showAdditionalError(value, questionnaireAns[data.elementId]?.value, data.messageText),
			data: data.answerOptions,
			onChangeCallback: (value) => onChangeAnsCallback?.(data.elementId, data.elementType, value),
			onValidationCallback: (error) => onValidationCallback?.(data.elementId, error)
		};
		let props;
		switch (data.elementType) {
			case wotcElementTypes.multipleChoiceSingleAnswerInput:
				props = { ...commonProps, id: data.elementId, label: data.labelText };
				return <RadioList {...props} />;
			case wotcElementTypes.textFieldInput:
				props = {
					...commonProps,
					emptyValidation: isSubmissionScreen,
					hideError: !validationError[data.elementId]?.isVisible
				};
				return <CustomTextField {...props} />;
			case wotcElementTypes.dateInput:
				props = {
					...commonProps,
					hideError: !validationError[data.elementId]?.isVisible
				};
				return <CustomDateField {...props} />;
			case wotcElementTypes.dropDownListInput:
				props = { ...commonProps, id: data.elementId };
				return <CustomSelect {...props} />;
			default:
				return null;
		}
	};

	return (
		<React.Fragment>
			{data?.map((question) => {
				const OptionsComponent = getInputOptionsComponent(question);
				const LabelComponent = getInputLabel(question.elementType, question.labelText, isSubmissionScreen);
				return (
					<div key={`${question.elementId}`} className={styles.question}>
						{LabelComponent}
						{OptionsComponent}
					</div>
				);
			})}
		</React.Fragment>
	);
};

Questions.propTypes = {
	data: PropTypes.array,
	onChangeAnsCallback: PropTypes.func,
	handleDisableContinue: PropTypes.func
};

export default Questions;
