import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailability } from 'redux/Availability/request';
import { getsupplyChainAvailability } from 'redux/SupplyChainAvailability/request';
import { getExternalJobOfferInfo, finalizeJobOffer } from 'redux/VirtualJobOffer/request';
import { updateFinalizingJobOfferFlag } from 'redux/VirtualJobOffer/slice';
import { getStepInd } from 'redux/Account/request';
import { updateLoading } from 'redux/slices/globalSlice';

/**
 * Custom hook to handle virtual job offer logics
 * @returns fetchVirtualJobOffer - Initaite virtual job offer API.
 * @returns jobOffer - Data related to the jobOffer.
 * @returns jobAvailability - Job hour for availability/SC availability.
 * @returns account - Applicant Info.
 * @returns isSupplyChain - true/false
 * @returns nextStepInd - returns the next step count of the VJO
 */

export const useVirtualJobOffer = () => {
	const dispatch = useDispatch();

	const virtualJobOfferInfo = useSelector((state) => state.virtualJobOffer?.data);
	const finalizeJobOfferResp = useSelector(
		(state) => state.virtualJobOffer?.data?.finalizeJobOffer
	);
	const isFinalizingJobOffer = useSelector((state) => state.virtualJobOffer?.isFinalizingJobOffer);

	const account = useSelector((state) => state.account);
	const isSupplyChain = account?.data?.auth?.supplyChain;
	const nextStepInd = account?.data?.auth?.vjo?.nextStepInd;
	const availability = useSelector((state) => state?.availability?.data);
	const supplyChainAvailability = useSelector((state) => state?.supplyChainAvailability?.data);
	const scPreboarding = useSelector((state) => state.virtualJobOffer?.data?.scPreboarding);

	/**
	 * Required data for the job offer.
	 */
	const jobOffer = useMemo(() => {
		if (_.isEmpty(virtualJobOfferInfo)) {
			return null;
		}
		const {
			jobName,
			jobDesc,
			employmentType,
			rateOfPay,
			schldDate,
			store,
			hiringMangerFullName,
			jobOfferNbr
		} = virtualJobOfferInfo;
		const { storeNbr, streetAddr, cityAddr, stateAddr, zipCode } = store;
		return {
			jobName: isSupplyChain ? jobDesc : jobName,
			employmentType,
			rateOfPay,
			schldDate,
			storeNbr,
			streetAddr,
			cityAddr,
			stateAddr,
			zipCode,
			hiringMangerFullName,
			jobOfferNbr
		};
	}, [virtualJobOfferInfo, isSupplyChain]);

	/**
	 * Call virtualJobOffer API
	 */
	const fetchVirtualJobOffer = useCallback(() => {
		const { applicantId, storeNbr, reqsnId } = account?.data?.auth?.vjo;
		if (applicantId && storeNbr && reqsnId) {
			dispatch(
				getExternalJobOfferInfo({
					storeNbr: storeNbr,
					requestInput: {
						applicantId: applicantId,
						requisitionId: reqsnId,
						isSupplyChain: isSupplyChain
					}
				})
			);
		}
		// Fetch Supply chain availability if isSupplyChain is true
		if (isSupplyChain && applicantId && reqsnId) {
			dispatch(
				getsupplyChainAvailability({
					storeNbr: storeNbr,
					requestInput: {
						applicantId: applicantId,
						requisitionId: reqsnId
					}
				})
			);
			return;
		}
		// Get Availability
		if (applicantId) {
			dispatch(getAvailability(applicantId));
		}
	}, [account, dispatch, isSupplyChain]);

	const fetchFinalizeJobOffer = useCallback(() => {
		const { applicantId, storeNbr, reqsnId, jobOfferNbr } = account?.data?.auth?.vjo;
		if (applicantId && storeNbr && jobOfferNbr) {
			dispatch(
				finalizeJobOffer({
					storeNbr: storeNbr,
					requestInput: {
						applicantId: applicantId,
						requisitionId: reqsnId,
						jobOfferNbr: jobOfferNbr,
						verbalIndicator: 'N',
						tempUserLoginId: '0',
						contUserId: '',
						contWinNbr: '0',
						imprnWinNbr: '0',
						isSupplyChain: isSupplyChain
					}
				})
			);
		}
	}, [account, dispatch, isSupplyChain]);

	const isJobOfferFinalized = useMemo(() => {
		const responseCode = finalizeJobOfferResp?.responseCode;
		return responseCode === '200';
	}, [finalizeJobOfferResp]);

	const updateFinalizingJobOffer = useCallback(
		(value) => {
			dispatch(updateFinalizingJobOfferFlag(value));
		},
		[dispatch]
	);

	const callGetStepInd = useCallback(() => {
		const { applicantId, jobOfferNbr } = account?.data?.auth?.vjo;
		if (applicantId && jobOfferNbr) {
			dispatch(updateLoading(true));
			dispatch(
				getStepInd({
					applicantId: applicantId,
					jobOfferNbr: jobOfferNbr
				})
			).then(() => dispatch(updateLoading(false)));
		}
	}, [account, dispatch]);

	return {
		fetchVirtualJobOffer,
		fetchFinalizeJobOffer,
		updateFinalizingJobOffer,
		callGetStepInd,
		jobOffer,
		jobAvailability: isSupplyChain ? supplyChainAvailability : availability,
		account,
		isSupplyChain,
		nextStepInd,
		finalizeJobOfferResp,
		isFinalizingJobOffer,
		isJobOfferFinalized,
		scPreboarding
	};
};
