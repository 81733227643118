import { createSlice } from '@reduxjs/toolkit';
import { getCountryDetails } from './request';

const initialState = {
  countryDetails : {},
  error : {}
};

const disclaimerSlice = createSlice({
  name: 'disclaimer',
  initialState,
  reducers: {},
  extraReducers(builder){
    builder.addCase(getCountryDetails.fulfilled, (state, action)=>{
      state.countryDetails = action.payload;
    })
    builder.addCase(getCountryDetails.rejected, (state, action)=>{
        state.error = {message : "something went wrong"}
    })
  }
});

export default disclaimerSlice.reducer;
