import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmScheduleSlot, confirmScheduleSlotForTour } from 'redux/SelfSchedule/request';
import {
	updateIsSelfScheduleSC,
	updateSeeMore,
	updateSelectedSlot
} from '../../../redux/SelfSchedule/slice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { selfScheduleEventCodeMap, selfScheduleType } from 'config/const';

export const useSelfSchedule = () => {
	const { t } = useTranslation();
	const [displaySeeMore, setDisplaySeeMore] = useState(false);
	const [interviewConfirmed, setInterviewConfirmed] = useState(false);
	const [introText, setIntroText] = useState('');
	const [subText, setSubText] = useState('');
	const [isSelfScheduleSC, setIsSelfScheduleSC] = useState(false);
	const [storeAddress, setStoreAddress] = useState('');

	const dispatch = useDispatch();

	const selfSchedule = useSelector((state) => state.selfSchedule);

	const handleOnTimePick = (startTime) => {
		if (selfSchedule.selectedTimeSlot === startTime) {
			handleConfirm();
		}
		dispatch(updateSelectedSlot(startTime));
	};

	const setStatesForSH = () => {
		setIntroText('selfSchedule.letsgo');

		if (selfSchedule.eventCode === selfScheduleEventCodeMap.SH_PRESCREEN_EVENT_CODE) {
			const text = 'selfSchedule.plsSelectDateTime,selfSchedule.virtualMeetAndGreet';
			setSubText(text);
			const formattedPhoneNumber = selfSchedule.phoneNumber
				? selfSchedule.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3!')
				: '';
			const virtualMeetText = `${'selfSchedule.NoStoreVisit'},${formattedPhoneNumber}`;
			setStoreAddress(virtualMeetText);
		} else {
			const text = 'selfSchedule.plsSelectDateTime,selfSchedule.interview';
			setSubText(text);
			setStoreAddress(selfSchedule.storeAddress);
		}
	};

	const setStatesForSC = () => {
		setIsSelfScheduleSC(true);
		setIntroText(
			selfSchedule.eventCode === selfScheduleEventCodeMap.SC_INTERVIEW_EVENT_CODE
				? 'selfSchedule.scheduleYourInterview'
				: selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_1 ||
				  selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_2
				? 'selfSchedule.scheduleYourOrientation'
				: 'selfSchedule.scheduleYourTour'
		);
		setSubText(t('selfSchedule.plsSelectDateAndTimeSC'));
		setStoreAddress(selfSchedule.storeAddress);
		dispatch(updateIsSelfScheduleSC(true));
	};

	function getRequestData(selectedTime, trnsResp) {
		return {
			scheduledTs:
				selfSchedule.eventCode === selfScheduleEventCodeMap.SC_INTERVIEW_EVENT_CODE ||
				selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_1 ||
				selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_2 ||
				selfSchedule.eventCode === selfScheduleEventCodeMap.SC_RJPT_EVENT_CODE ||
				selfSchedule.eventCode === selfScheduleEventCodeMap.SC_FT_EVENT_CODE
					? selectedTime
					: selectedTime.startTime,
			eventId: null,
			hrEventId: trnsResp.oldHrEventId,
			eventSeqNbr: 0,
			schedWinNbr: null,
			schedStoreNbr: null,
			cndctStoreNbr: null,
			conductTs: null,
			statusCode: 0,
			applicants: null,
			conductors: null,
			attendedApplicants: null,
			firstName: null,
			lastName: null,
			middleInitial: null,
			count: 0,
			eventCode: trnsResp.eventCode,
			reasonCode: 0,
			requisitionId: 0,
			jobCode: 0,
			deptNbr: 0,
			mdseDivNbr: 0,
			baseDivNbr: 0,
			updateWinNbr: 0,
			lastChgTs: null,
			applicantCount: 0,
			associateCount: 0,
			spanishPref: false
		};
	}

	const getPayloadForHRAvailability = (trnsResp, hrWinNbr, selectedTime) => {
		const requestInput = getRequestData(selectedTime, trnsResp);
		if (selfSchedule.isSelfScheduleSC) {
			requestInput.requisitionId = trnsResp.requisitionId;
			requestInput.schedWinNbr = hrWinNbr;
		} else {
			requestInput.actionCode = trnsResp.actionTypeCode;
		}
		requestInput.candidateList = [
			{
				applicantId: trnsResp.applicantId,
				applSeqNbr: 0,
				requisitionId: trnsResp.requisitionId,
				hrEventId: trnsResp.oldHrEventId,
				actionCode: trnsResp.actionTypeCode,
				eventSeqNbr: 0
			}
		];

		return {
			storeNbr: [trnsResp.storeNbr],
			requestInput: requestInput,
			actionTypeCode: trnsResp.actionTypeCode,
			eventCode: trnsResp.eventCode
		};
	};

	const handleConfirm = () => {
		const trnsResp = selfSchedule.responseData;
		const selectedSlot = selfSchedule.selectedTimeSlot;
		const selectedInterData = getinterviewSelectedSlot(
			selfSchedule.intrDateAndTimeSlots,
			selfSchedule.dateIndex,
			selfSchedule.selectedTimeSlot
		);
		let hrWinNbr = 0;
		if (selectedInterData) {
			hrWinNbr = selectedInterData?.hrWinNbr ? selectedInterData.hrWinNbr : 0;
		}
		//case HR Availability
		const reqPayload = getPayloadForHRAvailability(trnsResp, hrWinNbr, selectedSlot);

		console.log(typeof selectedSlot);
		const interviewSlot = {
			date:
				typeof selectedSlot === 'object'
					? moment(selectedSlot.date).format('dddd, MM-DD-YYYY')
					: moment(selectedSlot).format('dddd, MM-DD-YYYY'),
			startTime: typeof selectedSlot === 'object' ? selectedSlot.startTime : selectedSlot
		};
		dispatch(confirmScheduleSlot({ reqPayload, interviewSlot }));
	};

	const getinterviewSelectedSlot = (timeSlots, selectedDateIndex, selectedTime) => {
		let itrIndex = 0;
		let interTimeObj = {};
		Object.keys(timeSlots).forEach(function (key) {
			let slotArray = timeSlots[key];
			interTimeObj[itrIndex] = slotArray;
			itrIndex++;
		});
		let resinterSlot =
			interTimeObj && interTimeObj[selectedDateIndex] ? interTimeObj[selectedDateIndex] : [];
		let finalInterSlot = resinterSlot.filter((item) => item.startTime === selectedTime);
		return finalInterSlot ? finalInterSlot[0] : {};
	};

	const getOrientationSelectedSlot = (timeSlots, selectedDateIndex, selectedTime) => {
		let itrIndex = 0;
		let orientTimeObj = {};
		Object.keys(timeSlots).forEach(function (key) {
			let slotArray = timeSlots[key];
			orientTimeObj[itrIndex] = slotArray;
			itrIndex++;
		});

		let resOrientSlot =
			orientTimeObj && orientTimeObj[selectedDateIndex] ? orientTimeObj[selectedDateIndex] : [];
		let finalOrientSlot = resOrientSlot.filter((item) => item.time === selectedTime);
		return finalOrientSlot ? finalOrientSlot[0] : {};
	};

	const handleConfirmOrientation = () => {
		const trnsResp = selfSchedule.responseData;
		const selectedSlot = getOrientationSelectedSlot(
			selfSchedule.orientDateAndTimeSlots,
			selfSchedule.dateIndex,
			selfSchedule.selectedTimeSlot
		);
		let requestInput = {};

		const reqData = {
			prevHrEventId: trnsResp.oldHrEventId,
			hrEventId: selectedSlot.hrEventId,
			manualSs: false,
			applicants: [
				{
					applicantId: trnsResp.applicantId,
					applSeqNbr: 0,
					requisitionId: trnsResp.requisitionId
				}
			]
		};

		requestInput = reqData;

		const reqPayload = {
			loggedInWinNbr: selectedSlot.hrAscWin,
			facilityNbr: trnsResp.storeNbr,
			requestInput: requestInput,
			actionTypeCode: trnsResp.actionTypeCode,
			eventCode: trnsResp.eventCode,
			eventId: selectedSlot.hrEventId,
			applicantId: trnsResp.applicantId,
			requisitionId: trnsResp.requisitionId
		};
		const interviewSlot = {
			date: moment(selectedSlot.startDate).format('MM-DD-YYYY'),
			startTime: moment(selectedSlot.time, 'hh:mm a').format('LT')
		};
		dispatch(confirmScheduleSlot({ reqPayload, interviewSlot }));
	};

	const getTourSelectedSlot = (timeSlots, dateIndexForTour, selectedTimeSlot) => {
		timeSlots = timeSlots[dateIndexForTour];
		let finalTourSlot = timeSlots.filter(
			(item) => item?.scheduleTs?.split(' ')[1]?.substring(0, 8) === selectedTimeSlot
		);
		return finalTourSlot ? finalTourSlot[0] : {};
	};

	const handleConfirmTour = () => {
		const trnsResp = selfSchedule.responseData;

		const selectedSlot = getTourSelectedSlot(
			selfSchedule.dateAndTimeSlots,
			selfSchedule.dateIndexForTour,
			selfSchedule.selectedTimeSlot
		);
		let requestInput = {};
		const datta = selectedSlot
			? selectedSlot.eventStartTs.split(' ')[0] +
			  ' ' +
			  selectedSlot.eventStartTs.split(' ')[1].slice(0, 5)
			: null;
		const schedWin = selectedSlot ? selectedSlot.schedWinNbr : null;
		let oldHrEventId = trnsResp.oldHrEventId;
		oldHrEventId = oldHrEventId != null ? oldHrEventId : 0;

		const reqData = { scheduledTs: datta, schedWinNbr: schedWin };
		requestInput = reqData;
		requestInput.candidateList = [
			{
				applicantId: trnsResp.applicantId,
				applSeqNbr: 0,
				requisitionId: trnsResp.requisitionId,
				hrEventId: oldHrEventId
			}
		];

		const reqPayload = {
			storeNbr: [trnsResp.storeNbr],
			requestInput: requestInput,
			actionTypeCode: trnsResp.actionTypeCode,
			eventCode: trnsResp.eventCode
		};

		const interviewSlot = {
			date: moment(selfSchedule.dateIndexForTour).format('DD-MM-YYYY'),
			startTime: datta
		};
		dispatch(confirmScheduleSlotForTour({ reqPayload, interviewSlot }));
	};

	const handleToggleSeeMore = () => {
		dispatch(updateSeeMore(!selfSchedule.seeMore));
	};

	const getScheduleType = () => {
		if (
			selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_1 ||
			selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_2
		) {
			return selfScheduleType.SC_ORIENTATION;
		} else if (
			selfSchedule.eventCode === selfScheduleEventCodeMap.SC_RJPT_EVENT_CODE ||
			selfSchedule.eventCode === selfScheduleEventCodeMap.SC_FT_EVENT_CODE
		) {
			return selfScheduleType.SC_TOUR;
		} else {
			return selfScheduleType.SC_INTERVIEW;
		}
	};

	return {
		displaySeeMore,
		interviewConfirmed,
		introText,
		subText,
		isSelfScheduleSC,
		storeAddress,
		handleOnTimePick,
		handleConfirmOrientation,
		setDisplaySeeMore,
		handleToggleSeeMore,
		setInterviewConfirmed,
		setStatesForSH,
		setStatesForSC,
		handleConfirm,
		handleConfirmTour,
		getScheduleType
	};
};
