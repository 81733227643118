import {
	Alert,
	Body,
	Caption,
	Checkbox,
	FormGroup,
	Link,
	Radio,
	TextField
} from '@walmart-web/livingdesign-components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPersonalInfo } from '../../../../redux/Renew/request';
import { formatPhoneNumber } from '../../../../utils/validation';
import useForm from '../../hooks/useForm';
import './style.scss';

export const keys = {
	firstName: 'firstName',
	middleName: 'middleName',
	lastName: 'lastName',
	zipCode: 'zipCode',
	phoneNumber: 'phoneNumber',
	altContactNumber: 'alternatePhoneNumber',
	textOpt: 'textOpt',
	isStudent: 'isStudent',
	age: 'age',
	parentConsentName: 'parentConsentName',
	parentConsentMail: 'parentConsentMail'
};

function ContactInformation(props) {
	const { t } = useTranslation();
	const { personalInfo, isRenewPanelClicked, isZipCodeError } = useSelector((state) => state.renew);
	const { applicantId, emailId } = useSelector((state) => state?.global);
	const dispatch = useDispatch();
	const global = useSelector((state) => state.global);
	const account = useSelector((state) => state.account);
	const {
		handleTextChange,
		handleCheckboxChange,
		handleRadioChange,
		handleContactInfoSave,
		updateFormValue,
		isMinor,
		errors,
		values
	} = useForm(null);
	useEffect(() => {
		dispatch(getPersonalInfo({ applicantId: applicantId, emailId: emailId }));
		// eslint-disable-next-line
	}, [applicantId]);

	useEffect(() => {
		if (personalInfo?.data?.hasOwnProperty('applicantId')) {
			const contactInfoObj = {};
			contactInfoObj[keys.firstName] = personalInfo?.data?.firstName?.trim();
			contactInfoObj[keys.middleName] = personalInfo?.data?.middleName?.trim();
			contactInfoObj[keys.lastName] = personalInfo?.data?.lastName?.trim();
			contactInfoObj[keys.zipCode] = personalInfo?.data?.zipCode?.trim();
			const age = personalInfo?.data?.hcTestResponse?.find(
				({ questionNbr, pretestCode, revisionNbr }) =>
					questionNbr === 4 && pretestCode === 20 && revisionNbr === 3
			);
			const isStudent = personalInfo?.data?.hcTestResponse?.find(
				({ questionNbr, pretestCode, revisionNbr }) =>
					questionNbr === 44 && pretestCode === 20 && revisionNbr === 3
			);
			const textOpt = personalInfo?.data?.hcTestResponse?.find(
				({ questionNbr, pretestCode, revisionNbr }) =>
					questionNbr === 40 && pretestCode === 20 && revisionNbr === 3
			);
			contactInfoObj[keys.textOpt] = textOpt?.answerNbr;
			contactInfoObj[keys.isStudent] = isStudent?.answerNbr;
			contactInfoObj[keys.age] = age?.answerNbr;
			contactInfoObj[keys.parentConsentName] = personalInfo?.data?.parentFullName?.trim();
			contactInfoObj[keys.parentConsentMail] = personalInfo?.data?.parentEmailId?.trim();
			if (personalInfo?.data?.phoneNumber) {
				const phoneNumber = personalInfo?.data?.phoneNumber.replace(/\-/g, '');
				contactInfoObj[keys.phoneNumber] = formatPhoneNumber(phoneNumber);
			}
			if (personalInfo?.data?.alternatePhoneNumber) {
				const altContactNumber = personalInfo?.data?.alternatePhoneNumber.replace(/\-/g, '');
				contactInfoObj[keys.altContactNumber] = formatPhoneNumber(altContactNumber);
			}
			updateFormValue(contactInfoObj);
		}
		// eslint-disable-next-line
	}, [personalInfo]);

	useEffect(() => {
		validateContactPanel();
		// eslint-disable-next-line
	}, [props.expanded]);

	useEffect(() => {
		validateContactPanel();
		// eslint-disable-next-line
	}, [values,global.language]);

	useEffect(() => {
		if (isZipCodeError) {
			validateContactPanel();
		}
		// eslint-disable-next-line
	}, [isZipCodeError]);

	const validateContactPanel = () => {
		if (
			isRenewPanelClicked.panel1 === true &&
			personalInfo?.data?.applicantId &&
			props.expanded === 'panel1'
		) {
			handleContactInfoSave();
		}
	};

	const getIsCheckedValue = (paramName, optionName) => {
		return values[paramName] === optionName;
	};

	return (
		<div className="contact_info_wrapper">
			<div className="row mb">
				<div className="column ">
					<TextField
						id={keys.firstName}
						label={t('renew.firstName')}
						UNSAFE_className="renew_textfields"
						value={values[keys.firstName]}
						textFieldProps={{
							maxLength: 15
						}}
						error={errors.hasOwnProperty(keys.firstName) ? t(errors[keys.firstName]) : null}
						onChange={handleTextChange}
					/>
				</div>
				<div className="column ">
					<TextField
						id={keys.middleName}
						label={t('renew.middleName')}
						UNSAFE_className="renew_textfields"
						value={values[keys.middleName]}
						textFieldProps={{
							maxLength: 1
						}}
						onChange={handleTextChange}
						error={errors.hasOwnProperty(keys.middleName) ? t(errors[keys.middleName]) : null}
					/>
				</div>

				<div className="column ">
					<TextField
						id={keys.lastName}
						label={t('renew.lastName')}
						UNSAFE_className="renew_textfields"
						textFieldProps={{
							maxLength: 20
						}}
						value={values[keys.lastName]}
						onChange={handleTextChange}
						error={errors.hasOwnProperty(keys.lastName) ? t(errors[keys.lastName]) : null}
					/>
				</div>
			</div>
			<div className="row">
				<div className="column ">
					<TextField
						id={keys.zipCode}
						label={t('renew.zipCode')}
						UNSAFE_className="renew_textfields"
						textFieldProps={{
							maxLength: 5
						}}
						value={values[keys.zipCode]}
						onChange={handleTextChange}
						error={errors.hasOwnProperty(keys.zipCode) ? t(errors[keys.zipCode]) : null}
					/>
				</div>
				<div className="column ">
					<TextField
						id={keys.phoneNumber}
						label={t('renew.phoneNumber')}
						UNSAFE_className="renew_textfields"
						value={values[keys.phoneNumber]}
						onChange={handleTextChange}
						error={errors.hasOwnProperty(keys.phoneNumber) ? t(errors[keys.phoneNumber]) : null}
					/>
				</div>
				<div className="column">
					<TextField
						id={keys.altContactNumber}
						label={t('renew.altContactNumber')}
						UNSAFE_className="renew_textfields"
						value={values[keys.altContactNumber] || ''}
						onChange={handleTextChange}
						error={
							errors.hasOwnProperty(keys.altContactNumber) ? t(errors[keys.altContactNumber]) : null
						}
					/>
				</div>
			</div>
			<div className="column m">
				<Checkbox
					label={t('createAccount.acceptMobileText')}
					id="textOpt"
					UNSAFE_className="text_opt_checkbox"
					checked={values['textOpt'] === 11 ? true : false}
					onChange={handleCheckboxChange}
				/>
				<Caption UNSAFE_style={{color:'#74767C'}}>
					{t('renew.textOptDescription')}
					<Link
						UNSAFE_style={{
							textDecoration: 'none',
							fontSize: '13px',
							color: '#0071DC'
						}}
						aria-label={t('ariaLabelTexts.clickHere')}
						target="_blank"
						href="https://corporate.walmart.com/privacy-security">
						{t('renew.clickHere')}
					</Link>
					{t('renew.textOptDescTrilling')}
				</Caption>
			</div>
			<div className="column m">
				<Body size="medium">{t('renew.isStudentHeading')}</Body>
				<Caption UNSAFE_style={{padding: '4px 0 10px 0', color:'#74767C'}}>{t('renew.isStudentDescription')}</Caption>
				<div className="row m">
					<FormGroup
						id="isStudent"
						error={errors.hasOwnProperty(keys.isStudent) ? t(errors[keys.isStudent]) : null}
						UNSAFE_className="radioGroup">
						<Radio
							label={t('renew.isStudentRadioTitles.highSchool')}
							name={119}
							onChange={(event) => handleRadioChange(event, 'isStudent')}
							checked={getIsCheckedValue('isStudent', 119)}
						/>
						<Radio
							label={t('renew.isStudentRadioTitles.collegeTradeSchool')}
							name={120}
							onChange={(event) => handleRadioChange(event, 'isStudent')}
							checked={getIsCheckedValue('isStudent', 120)}
						/>
						<Radio
							label={t('renew.isStudentRadioTitles.no')}
							name={118}
							onChange={(event) => handleRadioChange(event, 'isStudent')}
							checked={getIsCheckedValue('isStudent', 118)}
						/>
					</FormGroup>
				</div>
			</div>
			<div className="column m">
				<Body size="medium">{t('renew.ageTitle')}</Body>
				<Caption UNSAFE_style={{padding: '4px 0 10px 0', color:'#74767C'}}>{t('renew.ageCaption')}</Caption>
				<div className="row m">
					<FormGroup
						id="age"
						defaultChecked={'lessthen16'}
						error={errors.hasOwnProperty(keys.age) ? t(errors[keys.age]) : null}
						UNSAFE_className="radioGroup">
						<Radio
							label={t('renew.ageRadioTitles.option1')}
							name={93}
							onChange={(event) => handleRadioChange(event, 'age')}
							checked={getIsCheckedValue('age', 93)}
							disabled={account.data.auth.prJobsPreferred || account.data.auth.scJobsPreferred}
						/>
						<Radio
							label={t('renew.ageRadioTitles.option2')}
							name={94}
							onChange={(event) => handleRadioChange(event, 'age')}
							checked={getIsCheckedValue('age', 94)}
							disabled={account.data.auth.prJobsPreferred || account.data.auth.scJobsPreferred}
						/>
						<Radio
							label={t('renew.ageRadioTitles.option3')}
							name={95}
							onChange={(event) => handleRadioChange(event, 'age')}
							checked={getIsCheckedValue('age', 95)}
						/>
					</FormGroup>
				</div>
			</div>
			{isMinor() ? (
				<div className="column renew_minor_consent ">
					<Alert variant="info">{t('renew.minorConcern')}</Alert>
					<div className="row mt minor">
						<div className="column">
							<TextField
								id={keys.parentConsentName}
								label={t('student.parentName')}
								UNSAFE_className="renew_minor_textfields"
								value={values[keys.parentConsentName]}
								onChange={handleTextChange}
								textFieldProps={{
									maxLength: 40
								}}
								error={
									errors.hasOwnProperty(keys.parentConsentName)
										? t(errors[keys.parentConsentName])
										: null
								}
							/>
						</div>
						<div className="column">
							<TextField
								id={keys.parentConsentMail}
								label={t('student.parentEmail')}
								UNSAFE_className="renew_minor_textfields"
								value={values[keys.parentConsentMail]}
								onChange={handleTextChange}
								error={
									errors.hasOwnProperty(keys.parentConsentMail)
										? t(errors[keys.parentConsentMail])
										: null
								}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
}

export default ContactInformation;
