import React, { useState } from 'react';
import { Body, Button } from '@walmart-web/livingdesign-components';
import FeedbackCollector from '../FeedbackCollector';
import './style.scss';
import { useTranslation } from 'react-i18next';

const Feedback = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(true);
  };

  return (
    <div className="feedback_container">
      <Body as="div" size="medium" weight={400}>
        {t('feedback.feedbackDesc')}
      </Body>
      <Button size="small" variant="secondary" onClick={handleButtonClick}>
      {t('feedback.giveFeedback')}
      </Button>
      <FeedbackCollector isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default Feedback;
