import { createSlice } from '@reduxjs/toolkit';
import cookie from 'react-cookies';

const initialState = {
	availability: {
		applicantId: '',
		employmentType: {
			fullTime: false,
			partTime: false,
			temporary: false
		},
		scheduleAvailability: {
			anyHours: '',
			sunStartTime: '',
			sunEndTime: '',
			monStartTime: '',
			monEndTime: '',
			tueStartTime: '',
			tueEndTime: '',
			wedStartTime: '',
			wedEndTime: '',
			thuStartTime: '',
			thuEndTime: '',
			friStartTime: '',
			friEndTime: '',
			satStartTime: '',
			satEndTime: ''
		},
		shiftPreference: {
			shift: []
		}
	},
	employmentType: {
		fullTime: false,
		partTime: false,
		temporary: false
	},
	shiftPreference:{
		shift: []
	},
	scheduleAvailability: {
		anyHours: '',
		sunStartTime: '',
		sunEndTime: '',
		monStartTime: '',
		monEndTime: '',
		tueStartTime: '',
		tueEndTime: '',
		wedStartTime: '',
		wedEndTime: '',
		thuStartTime: '',
		thuEndTime: '',
		friStartTime: '',
		friEndTime: '',
		satStartTime: '',
		satEndTime: ''
	},
	isEmploymentTypeUpdateNeeded : false,
	areShiftsUpdateNeeded : false,
	selectedAndAutoFilledEmpTypes : {
		fullTime: false,
		partTime: false,
		temporary: false
	},
	selectedAndAutoFilledshiftPreference:{
		shift: []
	},
	triggerAutoFillCheckInRenew : false
};
export const availMainSlice = createSlice({
	name: 'availMain',
	initialState,
	reducers: {
		updateAvailability: (state, action) => {
			state.availability = action.payload;
		},
		updateShiftPreference: (state, action) => {
			state.shiftPreference = action.payload;
		},
		updateEmploymentType: (state, action) => {
			state.employmentType = action.payload;
		},
		updateScheduleAvailability: (state, action) => {
			state.scheduleAvailability = action.payload;
		},
		updateGlobalAvailability :(state, action) => {
			let availability = action.payload
			state.availability = availability;
			state.employmentType = availability.employmentType;
			state.shiftPreference = availability.shiftPreference;
			state.scheduleAvailability = availability.scheduleAvailability;
		},
		setIsEmploymentTypeUpdateNeeded : (state, action) =>{
			state.isEmploymentTypeUpdateNeeded = action.payload
		},
		setAreShiftsUpdateNeeded : (state, action) =>{
			state.areShiftsUpdateNeeded = action.payload
		},
		updateSelectedAndAutoFilledShiftPreference: (state, action) => {
			state.selectedAndAutoFilledshiftPreference = action.payload;
		},
		updateSelectedAndAutoFilledEmploymentType: (state, action) => {
			state.selectedAndAutoFilledEmpTypes = action.payload;
		},
		updateTriggerAutoFillCheckInRenew : (state, action) =>{
			state.triggerAutoFillCheckInRenew = action.payload;
		}
	}
});

export const { updateAvailability,updateGlobalAvailability ,updateShiftPreference,
				updateEmploymentType,updateScheduleAvailability, setIsEmploymentTypeUpdateNeeded, 
				setAreShiftsUpdateNeeded, updateSelectedAndAutoFilledEmploymentType, 
				updateSelectedAndAutoFilledShiftPreference, 
				updateTriggerAutoFillCheckInRenew } = availMainSlice.actions;

export default availMainSlice.reducer;
