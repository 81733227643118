import { Body, Button, Checkbox, WizardFooter } from '@walmart-web/livingdesign-components';
import { GTM } from 'config/const';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { callHiringHelper } from 'redux/HiringHelper/request';
import { postRenewData, rehireCheck } from 'redux/Renew/request';
import { updateLoading, errorOccured, onLogOut, executionErrorOccured } from 'redux/slices/globalSlice';
import { sendGtmEvent } from 'utils/GoogleTagManagerUtils';
import { useNavigate } from 'react-router-dom';
import {
	renewPanelClicked,
	contactPanelError,
	renewAvailPanelError,
	jobPanelError,
	zipCodeError,
	navigateLocation,
	noJobPreference,
	updateTriggerRenewAction
} from 'redux/Renew/slice';
import PreEmployMentModal from 'pages/Application/AboutYou/AboutYouPersonalInfo/PreEmploymentModal';
import ExitModal from 'pages/Application/AboutYou/AboutYouPersonalInfo/ExitModal';
import { updateAccountAuth } from '../../../../redux/Account/slice';
import JobPreferenceAlert from 'pages/RenewApplication/Components/ReviewJobPreference/JobPreferenceAlert';
import cookie from 'react-cookies';
import './style.scss';
import { useDisplay } from 'utils/useDisplay';
import { updateTriggerAutoFillCheckInRenew } from 'redux/slices/availMainSlice';
/**
 * Renew footer view
 */
const FooterView = () => {
	const { t } = useTranslation();
	const { isPortrait } = useDisplay();
	const navigate = useNavigate();
	const [isAck, setIsAck] = useState(false);
	const dispatch = useDispatch();
	const global = useSelector((state) => state.global);
	const account = useSelector((state) => state.account);
	const {
		personalInfo,
		dataToRenew,
		isContactPanelError,
		isRenewAvailPanelError,
		isJobPanelError,
		isWotcCompleted
	} = useSelector((state) => state?.renew);
	const applicantId = global.applicantId;
	const { contactInfo, jobPreference } = dataToRenew;
	const availStore = useSelector((state) => state.availMain);
	const renewStore = useSelector((state) => state.renew);
	const [rehireStatus, setRehireStatus] = useState(false);
	const [rehireValue, setRehireValue] = useState('');
	const [exit, setExit] = useState(false);
	const [exitText, setExitText] = useState('');
	const [renewCompleted, setRenewCompleted] = useState(false);

	const handleIAck = () => {
		setIsAck(!isAck);
	};

	const getAvailability = () => {
		return {
			applicantId: applicantId,
			jobType: global.jobType,
			employmentType: availStore.employmentType,
			scheduleAvailability: availStore.scheduleAvailability,
			shiftPreference: availStore.shiftPreference
		};
	};

	const disableRenew = () => {
		return !(
			checkCondition(isContactPanelError) &&
			checkCondition(isRenewAvailPanelError) &&
			checkCondition(isJobPanelError) && 
			checkCondition(!isWotcCompleted) &&
			jobPreference &&
			isAck
		);
	};

	const checkCondition = (value) => {
		return value !== '' && !value;
	};

	const getParentConsent = (value, personalData) => {
		if (value) {
			if (contactInfo?.age !== 95 || (contactInfo?.age === 95 && contactInfo?.isStudent === 119)) {
				return value;
			} else {
				return personalData ? personalData : '';
			}
		} else {
			return personalData ? personalData : '';
		}
	};

	const handleModal = () => {
		setRehireStatus(false);
	};

	const handlePreEmployment = (value) => {
		if (value.yes) {
			setRehireValue('Y');
		} else {
			setRehireValue('N');
		}
	};

	useEffect(() => {
		if (rehireValue) {
			callRenewApi();
		}
		// eslint-disable-next-line
	}, [rehireValue]);

	useEffect(()=>{
		if(renewStore.triggerRenewAction){
			submitRenew();
		}

	},[renewStore.triggerRenewAction])

	const handleExit = (value) => {
		setExit(value);
		dispatch(onLogOut());
	};

	const handleSubmitRenew = () =>{
		dispatch(updateTriggerAutoFillCheckInRenew(true));
	}
	const submitRenew = async () => {
		const personalInfoData = personalInfo.data;
		cookie.save('autoFillCutoverModalAlreadyShown', 'Y');
		dispatch(updateTriggerRenewAction(false));
		dispatch(updateTriggerAutoFillCheckInRenew(false));
		if (isRehireCandidate()) {
			const data = {};
			data['applicantId'] = applicantId;
			data['lastName'] = contactInfo?.lastName;
			data['partialDob'] = personalInfoData?.partialDob;
			data['partSsnNbr'] = personalInfoData?.partSsnNbr;
			data['zipCode'] = contactInfo?.zipCode;
			dispatch(updateLoading(true));
			dispatch(rehireCheck(data)).then((res) => {
				dispatch(updateLoading(false));
				if (res?.payload?.response === true) {
					setRehireStatus(true);
				} else {
					callRenewApi();
				}
			});
		} else {
			callRenewApi();
		}
	};

	const isRehireCandidate = () => {
		const persnlInfo = personalInfo.data;
		const identificationNbr = persnlInfo?.identificationNbr ? persnlInfo?.identificationNbr : '';
		return (
			identificationNbr === '' &&
			identificationNbr?.length !== 9 &&
			((persnlInfo?.lastName && persnlInfo?.lastName !== contactInfo?.lastName) ||
				(persnlInfo?.zipCode && persnlInfo?.zipCode !== contactInfo?.zipCode)) &&
			persnlInfo?.partSsnNbr &&
			persnlInfo?.partSsnNbr.length === 6
		);
	};

	const callRenewApi = () => {
		sendGtmEvent(GTM.EVENTS.APPLICATION_RENEW);
		const { deletedStoreList, deletedJobList } = jobPreference;
		const personalInfoData = personalInfo.data;
		const availablityPref = getAvailability();
		const renewPostData = {
			sendWelcomeMessage: contactInfo?.textOpt === 11 ? true : false,
			applicantId: applicantId,
			firstName: contactInfo.firstName,
			middleName: contactInfo.middleName,
			lastName: contactInfo.lastName,
			streetAddress: personalInfoData?.streetAddress || '',
			zipCode: contactInfo.zipCode?.trim(),
			partialDob: personalInfoData?.partialDob || '',
			partSsnNbr: personalInfoData?.partSsnNbr || '',
			phoneNumber: contactInfo?.phoneNumber?.replace(/[(\-)\s]/g, ''),
			alternatePhoneNumber: contactInfo?.alternatePhoneNumber?.replace(/[(\-)\s]/g, ''),
			parentFullName: getParentConsent(
				contactInfo?.parentConsentName,
				personalInfo?.data?.parentFullName
			),
			parentEmailId: getParentConsent(
				contactInfo?.parentConsentMail,
				personalInfo?.data?.parentEmailId
			),
			userId: global.email,
			fileLanguageCode: global.language === 'ES' ? '102' : '101',
			identificationNbr: personalInfoData?.identificationNbr || '',
			countryCode: global.countryCode,
			applStatusCode: account.stepNbr, //need to check  why 3
			latitude: '', //will be populated in backend
			longitude: '', //will be populated in backend
			state: personalInfoData?.state || '',
			city: personalInfoData?.city || '',
			translatedInd: global.language === 'EN' ? 'E' : 'S',
			hcTestResponse: contactInfo.hcTestResponse,
			availability: availablityPref,
			removeStoreList: deletedStoreList,
			removeJobList: deletedJobList,
			formerAssociate: rehireValue,
			minor: contactInfo.isStudent === 119 || contactInfo.age === 93 || contactInfo.age === 94
		};
		dispatch(updateLoading(true));
		dispatch(postRenewData(renewPostData))
			.then((res) => {
				dispatch(updateLoading(false));
				if (account?.data?.auth?.stepNbr >= 850) {
					dispatch(callHiringHelper(applicantId));
				}
				if (res?.payload?.responseCode === '200' || res?.payload?.responseCode === '201') {
					setRenewCompleted(true);
				} else if (res?.payload?.responseCode === '300') {
					dispatchPostRenew();
					const responseText = res?.payload?.responseMessage?.split('#@#');
					if (global.language === 'ES') {
						setExitText(responseText[2]);
					} else {
						setExitText(responseText[1]);
					}
					setRehireStatus(false);
					setExit(true);
				} else if (res?.payload?.response?.data?.responseCode === '444') {
					dispatch(zipCodeError(true));
				} else {
					dispatchPostRenew();
					dispatch(executionErrorOccured());
				}
			})
			.catch(() => {
				dispatchPostRenew();
				dispatch(executionErrorOccured());
			});
	};

	const dispatchPostRenew = () => {
		dispatch(renewPanelClicked({ panel1: false, panel2: false, panel3: false }));
		dispatch(contactPanelError(''));
		dispatch(renewAvailPanelError(''));
		dispatch(jobPanelError(''));
		dispatch(zipCodeError(''));
	};

	const navigateToErrorPage = () => {
		const errorObj = {
			open: false,
			title: 'general.error',
			message: 'general.systemErrorText',
			url: '/HiringCenter/error',
			canBeTranslated : true
		};
		dispatch(errorOccured(errorObj));
	};

	const handleClose = () => {
		handleAction();
	};
	const handleYes = () => {
		dispatch(navigateLocation(true));
		handleAction();
	};
	const handleNo = () => {
		handleAction();
	};

	const handleAction = () => {
		setRenewCompleted(false);
		dispatchPostRenew();
		dispatch(noJobPreference(''));
		dispatch(updateAccountAuth({}));
		navigate('../login');
	};

	return (
		<div className="renew_footer">
			{!isPortrait ? (
				<WizardFooter
				data-testid="wizardFooter"
					previousActionProps={{
						UNSAFE_className: 'wizard_footer_hide_previous'
					}}
					nextActionProps={{
						UNSAFE_className: 'wizard_footer_next',
						disabled: disableRenew(),
						onClick: () => {
							handleSubmitRenew();
						},
						children: t('renew.renew')
					}}>
					<Body as="div" UNSAFE_style={{ minWidth: '65vw' }}>
						<Checkbox checked={isAck} onChange={handleIAck} label={t('renew.acknowledge')} />
					</Body>
				</WizardFooter>
			) : (
				<div className="renew_acknowledge">
					<Body>
						<Checkbox checked={isAck} onChange={handleIAck} label={t('renew.acknowledge')} />
					</Body>
					<Button
						disabled={disableRenew()}
						className="renew_submit_btn"
						size="medium"
						variant="primary"
						onClick={handleSubmitRenew}>
						{t('renew.renew')}
					</Button>
				</div>
			)}
			{rehireStatus && (
				<PreEmployMentModal
					rehireStatus={rehireStatus}
					handleModal={handleModal}
					handlePreEmployment={handlePreEmployment}
				/>
			)}
			{exit && <ExitModal exit={exit} handleExit={handleExit} exitText={exitText} />}

			{renewCompleted && (
				<JobPreferenceAlert
					renewCompleted={renewCompleted}
					handleYes={handleYes}
					handleNo={handleNo}
					handleClose={handleClose}
				/>
			)}
		</div>
	);
};

export default FooterView;
