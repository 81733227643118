import {createSlice} from '@reduxjs/toolkit';
import {getDeclineOffer} from './request';

const initialState = {data: {}, loading: false, error: {}};

const declineOfferSlice = createSlice({
  name: 'declineOffer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDeclineOffer.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(getDeclineOffer.rejected, (state) => {
        state.error = {message: 'Something went wrong'};
      });
  },
});

export const {externalJobOfferInfo} = declineOfferSlice.actions;

export default declineOfferSlice.reducer;
