import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	subHeader,
	updateLanguage,
	loggedInApplicantId,
	loggedInEmailId
} from 'redux/slices/globalSlice';
import { useMediaQuery } from 'react-responsive';
import AssessmentDesktopScreen from './components/AssessmentDesktopScreen';
import AssessmentMobileScreen from './components/AssessmentMobileScreen';
import { useNavigate } from 'react-router-dom';
import { getAssessments } from 'redux/Assessment/request';
import useAssessment from './hooks/useAssessment';
import ApplicationSubmitModal from './components/ApplicationSubmitModal';
import style from './Assessment.module.css';
import { Button } from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import cookie from 'react-cookies';

const Assessment = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { t, i18n } = useTranslation();
	const global = useSelector((state) => state.global);
	const assessment = useSelector((state) => state.assessment);
	const account = useSelector((state) => state.account);

	const isDesktop = useMediaQuery({ minWidth: 851 });
	const { handleResponse, handleSubmitApplication, handleSubmit } = useAssessment();

	const iPadLandscape = useMediaQuery({
		minWidth: 426,
		maxWidth: 850,
		orientation: 'landscape'
	});

	useEffect(() => {
		if (global && global.error.message) {
			navigate('../error');
		}
	}, [global, global.error.message]);

	useEffect(() => {
		dispatch(subHeader({ display: true, title: 'welcomePage.assessment' }));
		i18n.changeLanguage(
			(cookie.load('languagePref') ? cookie.load('languagePref') : 'EN').toLowerCase()
		);
		dispatch(updateLanguage(cookie.load('languagePref') ? cookie.load('languagePref') : 'EN'));

		let applId = global.applicantId;
		let emailId = global.emailId;
		if (!applId || applId === 0) {
			applId = cookie.load('userId') ? cookie.load('userId') : 0;
			dispatch(loggedInApplicantId(applId));
		}
		if (!emailId || emailId === '') {
			emailId = cookie.load('emailId') ? cookie.load('emailId') : '';
			dispatch(loggedInEmailId(emailId));
		}
		dispatch(getAssessments()).then((data) => {
			handleResponse(data.payload);
		});
	}, []);

	return (
		<>
			{isDesktop || iPadLandscape ? <AssessmentDesktopScreen /> : <AssessmentMobileScreen />}
			<div className={style.bottom_layout}>
				<Button
					variant="primary"
					size="medium"
					disabled={assessment.assessmentStatus && assessment.assessmentStatus.assessmentNeeded}
					onClick={
						account?.data?.auth?.stepNbr < 850 ||
						(!account?.data?.auth?.stepNbr && cookie.load('stepNbr') < 850)
							? handleSubmitApplication
							: handleSubmit
					}
					UNSAFE_className={style.submitButton}>
					{t('general.submit')}
				</Button>
			</div>
			{assessment.showModal ? <ApplicationSubmitModal /> : ''}
		</>
	);
};

export default Assessment;
