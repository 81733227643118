import React, { useState, useEffect } from 'react';
import style from './VisaStatus.module.css';
import commonStyles from '../../../../Style/commonStyle.module.css';
import {
	Body,
	FormGroup,
	Radio,
	Button,
	Modal,
	Caption
} from '@walmart-web/livingdesign-components';
import { Check } from '@livingdesign/icons';
import { animateScroll as scroll } from 'react-scroll';
import { useSelector, useDispatch } from 'react-redux';
import { updateAboutYouHcTestReponseAnswer, updateAnswer, updateCheckHire } from '../../../../redux/AboutYou/slice';
import { useTranslation, Trans } from 'react-i18next';
import { aboutApplicantStep3 } from '../../../../redux/AboutYou/request';
import { executionErrorOccured, onLogOut, updateLoading } from '../../../../redux/slices/globalSlice';
import { sendGtmEvent } from '../../../../utils/GoogleTagManagerUtils';
import { GTM } from '../../../../config/const';

const VisaStatus = (props) => {
	const global = useSelector((state) => state.global);
	const { t } = useTranslation();
	const lang = global.language;
	const dispatch = useDispatch();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [sponsorshipRequired, setsponsorshipRequired] = useState(false);
	const [sponsorshipNotRequired, setsponsorshipNotRequired] = useState(false);
	const [documentAvailable, setdocumentAvailable] = useState(false);
	const [documentNotAvailable, setdocumentNotAvailable] = useState(false);
	const [disableButton, setDisableButton] = useState({
		sponsorShip: true,
		document: true
	});
	const [success, setSuccess] = useState(false);
	const aboutYou = useSelector((state) => state.aboutYou);

	const [visaResponse, setVisaResponse] = useState({
		responseCode: '',
		responseMessage: ''
	});
	useEffect(() => {
		dispatch(updateCheckHire({ checkRehire: false }));
		const applInfo = aboutYou.applInfo;
		let visaStatus = false;
		let docStatus = false;
		if (Object.keys(applInfo).length !== 0) {
			applInfo.hcTestResponse.forEach((obj) => {
				if (obj.questionNbr === 5 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
					if (obj.answerNbr === 11) {
						visaStatus = false;
						setsponsorshipRequired(true);
					} else if (obj.answerNbr === 12) {
						setsponsorshipNotRequired(true);
						visaStatus = true;
					}
					setDisableButton((prevState) => ({
						...prevState,
						sponsorShip: false
					}));
					dispatch(
						updateAnswer({
							ques: obj.questionNbr,
							ans: obj.answerNbr
						})
					);
				}
				if (obj.questionNbr === 6 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
					if (obj.answerNbr === 11) {
						setdocumentAvailable(true);
						docStatus = true;
					} else if (obj.answerNbr === 12) {
						setdocumentNotAvailable(true);
						docStatus = false;
					}
					setDisableButton((prevState) => ({
						...prevState,
						document: false
					}));
					dispatch(
						updateAnswer({
							ques: obj.questionNbr,
							ans: obj.answerNbr
						})
					);
				}
			});
			if (visaStatus && docStatus) {
				visaComplete();
			}
		}

		// eslint-disable-next-line
	}, []);

	const visaComplete = (e) => {
		props.handleMilitaryScreen(true);
		setSuccess(true);
		scroll.scrollToBottom();
	};

	const handleButton = (e) => {
		const req = aboutYou.aboutYouReq;
		dispatch(updateLoading(true));
		dispatch(aboutApplicantStep3(req))
			.then((res) => {
				settingResponse(res);
				dispatch(updateAboutYouHcTestReponseAnswer(aboutYou.aboutYouReq.hcTestResponse[2]));
				dispatch(updateAboutYouHcTestReponseAnswer(aboutYou.aboutYouReq.hcTestResponse[3]));
				if (res.payload.responseCode === '300') {
					sendGtmEvent(GTM.EVENTS.NOT_ELIGIBLE_VISA_DOCUMENT_REQUIREMENT);
					setModalIsOpen(true);
				} else if (res.payload.responseCode === '200' || res.payload.responseCode === '201') {
					props.handleMilitaryScreen(true);
					setSuccess(true);
					scroll.scrollToBottom();
				}
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const handleSponsorship = (e) => {
		props.handleMilitaryScreen(false);
		setSuccess(false);
		const value = e.target.name;

		if (value === 'visaSponsorshipRequired') {
			setsponsorshipRequired(true);
			setsponsorshipNotRequired(false);
			dispatch(
				updateAnswer({
					ques: 5,
					ans: 11
				})
			);
		} else if (value === 'visaSponsorshipNotRequired') {
			setsponsorshipRequired(false);
			setsponsorshipNotRequired(true);
			dispatch(
				updateAnswer({
					ques: 5,
					ans: 12
				})
			);
		}
		setDisableButton((prevState) => ({ ...prevState, sponsorShip: false }));
	};

	const handleDocument = (e) => {
		props.handleMilitaryScreen(false);
		setSuccess(false);
		const value = e.target.name;

		if (value === 'documentsAvailable') {
			setdocumentAvailable(true);
			setdocumentNotAvailable(false);
			dispatch(
				updateAnswer({
					ques: 6,
					ans: 11
				})
			);
		} else if (value === 'documentsNotAvailable') {
			setdocumentAvailable(false);
			setdocumentNotAvailable(true);
			dispatch(
				updateAnswer({
					ques: 6,
					ans: 12
				})
			);
		}
		setDisableButton((prevState) => ({ ...prevState, document: false }));
	};
	const settingResponse = (res) => {
		setVisaResponse({
			responseCode: res.payload.responseCode,
			responseMessage: res.payload.responseMessage
		});
	};

	const displayMessage = () => {

		return (sponsorshipRequired && documentNotAvailable ?
			'visa.ques1YesQues2No'
			: documentAvailable && sponsorshipRequired ?
				'visa.ques1Yes' :
				'visa.ques2No'
		)
	};

	return (
		<FormGroup UNSAFE_style={{marginBottom: 0}}>
			<div className={style.page} id="visaScreen">
				<div>
					<Body as="p" size="large" weight={700}>
						{t('visa.title')}
					</Body>
				</div>
			<fieldset className={style.fieldSet}>
				<div>
					<legend>
						<Body as="p" size="medium" weight={400}>
							{t('visa.ques1')}
						</Body>
					</legend>
					<Caption as="span" size="small" weight={400}>
						{t('visa.ques1Text')}
					</Caption>
				</div>
				<div>
					<Radio className={style.radio}
						label={t('visa.yes')}
						name="visaSponsorshipRequired"
						onChange={(e) => {
							handleSponsorship(e);
						}}
						checked={sponsorshipRequired}
					/>
				</div>
				<div>
					<Radio className={style.radio}
						label={t('visa.no')}
						name="visaSponsorshipNotRequired"
						onChange={(e) => {
							handleSponsorship(e);
						}}
						checked={sponsorshipNotRequired}
					/>
				</div>
			</fieldset>
			<fieldset className={style.fieldSet}>
				<div>
					<legend>
						<Body as="p" size="medium" weight={400}>
							{t('visa.ques2')}
						</Body>
					</legend>
				</div>
				<div>
					<Radio className={style.radio}
						label={t('visa.yes')}
						name="documentsAvailable"
						onChange={(e) => {
							handleDocument(e);
						}}
						checked={documentAvailable}
					/>
				</div>
				<div>
					<Radio className={style.radio}
						label={t('visa.no')}
						name="documentsNotAvailable"
						onChange={(e) => {
							handleDocument(e);
						}}
						checked={documentNotAvailable}
					/>
				</div>
			</fieldset>
				{!success ? (
					<div>
						<Button
							size="small"
							variant="primary"
							onClick={handleButton}
							disabled={!disableButton.sponsorShip && !disableButton.document ? false : true}>
							{t('visa.next')}
						</Button>
					</div>
				) : (
					<div style={{ width: '100%' }}>
						<div className={style.successLogo}>
							<Check size="small" />
						</div>

						<Body as="p" size="small" weight={400} UNSAFE_style={{ color: '#1D5F02' }}>
							{t('visa.complete')}
						</Body>
					</div>
				)}
			</div>
			{modalIsOpen && (
				<Modal onClose={() => setModalIsOpen(false)} isOpen={modalIsOpen} size="medium" title={t('visa.sorry')}>
					<div style={{ color: '#74767C', paddingTop: '12px' }}>
						<Body as="p" size="medium" weight={400}>
							<Trans>{t(displayMessage())}</Trans>
						</Body>
					</div>
				</Modal>
			)}
		</FormGroup>
	);
};

export default VisaStatus;
