import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateLoading, apiRequestErrorOccured } from "redux/slices/globalSlice";
import axiosInstance from "../../utils/axiosInstance";
import { environment } from '../../utils/environment/environment';
import cookie from 'react-cookies';

const getAssessments = createAsyncThunk(
    "assessments/getAssessments",
    async (args, { dispatch }) => {
        dispatch(updateLoading(true));
        const applicantId = global.applicantId && global.applicantId !== 0 ? global.applicantId : cookie.load("userId");
        const payload = {
            "applId": applicantId,
            "applSqlNbr": 0,
            countryCode: global.countryCode === 'PR' ? 'PR' : 'US'
        }
        
        try {
            const url = environment.hcServiceBasePostUrl + "/getApplAsmtInfo";
            const response = await axiosInstance.post(url, payload)
            dispatch(updateLoading(false))
            return response.data;
        } catch (error) {
			dispatch(apiRequestErrorOccured(error));
        }
    }
)

const takeAssessment = createAsyncThunk(
    "assessments/takeAssessment",
    async (args, { dispatch }) => {
        const auth = args.account.data.auth;
        const postObj = args.postObj;
        axiosInstance.post(environment.hcServiceBasePostUrl + "/getLaunchDetails", postObj).then((res) => {
            cookie.save('isDisclaimerAccepted', 'Y');
            cookie.save('fromAssessment', 'Y');
            cookie.save('isApplCompleted', auth.isApplCompleted + '');
            console.log('Assessment URL ', res.data)
            dispatch(updateLoading(false));
            window.location.href = res.data.assessmentURL
        }).catch((error) => {
			dispatch(apiRequestErrorOccured(error));
        })
    }
)

export { getAssessments, takeAssessment };