import React from 'react';
import { Card } from '@walmart-web/livingdesign-components';
import { useSelector } from 'react-redux';
import storeLocation from '../../../../assets/images/store_location.svg';
import CustomLabel from '../CustomLabel';
import AddressBox from './components/AddressBox';
import './style.scss';
import { useTranslation } from 'react-i18next';

const Locations = () => {
	const { t } = useTranslation();
	const locationDetails = useSelector((state) => state.postApplyDashboard.data.locationDetails);
	const storesList = (locationDetails && Object.keys(locationDetails)) || [];
	return storesList.length !== 0 ? (
		<Card size="medium" UNSAFE_className="location_card">
			<CustomLabel icon={storeLocation} title={t('location.locations')} />
			{storesList.map((storeNbr, index) => {
				return <AddressBox key={index} storeInfo={locationDetails[storeNbr]} />;
			})}
		</Card>
	) : (
		<></>
	);
};

export default Locations;
