import React, { useState } from 'react';
import { Checkbox } from '@walmart-web/livingdesign-components';
import { CheckCircle } from '@livingdesign/icons';
import './LocationCard.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectStore, removeStore, setToggleStore } from '../../../../redux/Location/slice';
import GenericLocationCard from '../../../../components/GenericLocationCard/GenericLocationCard';
import { removePreferredStoreFromCRM } from 'redux/slices/globalSlice';

const LocationCard = ({ store }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const selectedPos = useSelector((state) => state.location.selectedPos);
	const checked = selectedPos.some(
		(selectedStore) => selectedStore.storeNumber === store.storeNumber
	);
	const toggleStore = useSelector((state) => state.location.toggleStore);

	const { storeName, storeNumber } = store;
	const storeTitle = `${storeName} #${storeNumber}`;

	const onChangeHandler = () => {
		if (checked) {
			dispatch(removeStore(store));
			dispatch(removePreferredStoreFromCRM(store));
		} else {
			dispatch(selectStore(store));
		}
	};
	const onClickHandler = () => {
		dispatch(setToggleStore({ ...store }));
	};
	return (
			<div
			className={`LocationCard${
				toggleStore?.storeNumber === store.storeNumber ? ' LocationCard--toggle' : ''
			}`}
			onClick={onClickHandler}>
			<div className="LocationCard_left">
				<GenericLocationCard store={store} />
			</div>
			<div className="LocationCard_right">
				{store.checked ? (
					<CheckCircle size="small" />
				) : (
					<Checkbox
						aria-label={t('ariaLabelTexts.checkbox')}
						aria-checked={checked}
						checked={checked}
						onChange={onChangeHandler}
						a11yLabelledBy={storeTitle}
						id={storeTitle}
					/>
				)}
				{store.storeMiles ? store.storeMiles + ' mi' : ''}
			</div>
		</div>
	);
};

export default LocationCard;
