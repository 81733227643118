const jobStages = [
	'profileStatus',
	'applyStatus',
	'underConsiderationStatus',
	'interviewStatus',
	'jobOfferStatus',
	'orientationStatus'
];

const jobStagesKeys = [
	'profileStatus',
	'appliedStatus',
	'underConsiderationStatus',
	'inteviewStatus',
	'jobOfferStatus',
	'orientationStatus'
];

const decorateAssessmentObj = (response) => {
	const { assessmentStatus } = response.applAsmtInfoResponse || {};

	let assessmentCount = 0;
	const asmtResults = {};
	assessmentStatus &&
		assessmentStatus.forEach((assessmentStatusObj) => {
			const { assessmentExpdate, status, assessmentCode } = assessmentStatusObj;
			let stat = status;
			const isDateExpired = assessmentExpdate !== '' && new Date(assessmentExpdate) < new Date();
			if (assessmentCode !== '' && (isDateExpired || assessmentExpdate === '')){
				assessmentCount = assessmentCount + 1;
			}
			if(status === 'F' && isDateExpired) {
				stat= ''
			}
			asmtResults[assessmentCode]= {status: stat, expDate: assessmentExpdate}
		});
	return { assessmentCount, asmtResults };
};

const findMaxStatus = (trackerInfo) => {
	let count = 0;
	const { jobCode } = trackerInfo || {};
	if (!jobCode) {
		return count;
	}

	jobStages.every((stage, index) => {
		const currentStage = trackerInfo[stage][jobStagesKeys[index]];
		if ((stage === 'jobOfferStatus' && currentStage === '1') || (stage === 'orientationStatus' && currentStage !== '2')){
			count = 4;
			return false;
		} else if (currentStage === '2') {
			count = index - 1;
			return false;
		}
		return true;
	});
	return count;
};

const decorateLocationObj = (response) => {
	const locationDetails = {};
	let applicationStatus = -1;
	const {
		applAsmtInfoResponse = {},
		scStoreNbrs = [],
		trackerResponse = {},
		jobCodeMap,
		withdrawMap
	} = response;
	const positionApplied = applAsmtInfoResponse?.positionApplied || [];
	positionApplied &&
		positionApplied.forEach((positionAppliedObj) => {
			const { workGroupDesc, workGroupId, position, stores, jobClassId, asmtCode } = positionAppliedObj;
			stores.forEach((storesObj) => {
				const { storeNbr } = storesObj;
				const storeDetails = locationDetails[storeNbr] || {
					positionApplied: {}
				};
				const workGroupDetails = storeDetails['positionApplied'][workGroupId] || {};
				const positions = workGroupDetails['positions'] || [];
				position['asmtCode']=asmtCode;
				positions.push(position);
				position['jobClassId'] = jobClassId;

				workGroupDetails['positions'] = positions;
				workGroupDetails['name'] = workGroupDesc;

				const shouldShowJobCard = workGroupDetails['showJobCard'] || false;

				workGroupDetails['showJobCard'] = jobCodeMap[storeNbr][jobClassId] || shouldShowJobCard;

				storeDetails['positionApplied'][workGroupId] = workGroupDetails;
				storeDetails['isStore'] = !scStoreNbrs.includes(parseInt(storeNbr));

				storeDetails['jobCodeMap'] = jobCodeMap[storeNbr];
				storeDetails['withdrawMap'] = withdrawMap[storeNbr];
				storeDetails['showWorkGroup'] = Object.values(jobCodeMap[storeNbr]).some(Boolean);
				storeDetails['storeInfo'] = storesObj;

				storesObj['hasTrackerInfo'] = typeof trackerResponse[storeNbr] !== 'undefined';

				const trackerDetails = storeDetails['trackerDetails'] || {};

				if (trackerResponse[storeNbr]) {
					const previousStatus = storeDetails['overAllStage'] || 0;
					const workGroupLevelStatus = findMaxStatus(
						trackerResponse[storeNbr][workGroupId][jobClassId]
					);

					storeDetails['overAllStage'] =
						previousStatus <= workGroupLevelStatus ? workGroupLevelStatus : previousStatus;

					trackerDetails[jobClassId] = workGroupLevelStatus;

					const previousStoreLevelStatus = applicationStatus;
					applicationStatus =
						previousStoreLevelStatus <= trackerDetails[jobClassId]
							? trackerDetails[jobClassId]
							: previousStoreLevelStatus;

					storeDetails['trackerDetails'] = trackerDetails;
				}
				if (storeDetails.showWorkGroup) {
					locationDetails[storeNbr] = storeDetails;
				}
			});
		});
	return { locationDetails, applicationStatus };
};

const getApplExpDate = (response) => {
	const { applAsmtInfoResponse } = response;
	const positionApplied = applAsmtInfoResponse?.positionApplied || [];
	let expDate = new Date();
	positionApplied &&
		positionApplied.some((positionAppliedObj) => {
			expDate = positionAppliedObj.applExpDate;
			return positionAppliedObj.applExpDate;
		});
	return expDate;
};

const decorateResponse = (response) => {
	const { assessmentCount, asmtResults } = decorateAssessmentObj(response);
	const { locationDetails, applicationStatus } = decorateLocationObj(response);
	const applActiveTill = getApplExpDate(response);
	return {
		assessmentCount,
		asmtResults,
		locationDetails,
		applicationStatus: applicationStatus,
		applActiveTill: applActiveTill
	};
};

export { decorateResponse };
