import {
	updateJobType,
	updateJobCountryType,
	updatePreferredJobWarehouse
} from '../../redux/slices/globalSlice';
import {
	updateAvailability,
	updateShiftPreference,
	updateEmploymentType,
	updateScheduleAvailability
} from '../../redux/slices/availMainSlice';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { isEmptyObject } from '../../utils/validation';
import { renewAvailPanelError } from '../../redux/Renew/slice';
import { shiftIds } from '../../config/const';

export const AvailabilityHelper = () => {
	const dispatch = useDispatch();
	const app = useSelector((state) => state.availMain);
	const jobSearch = useSelector((state) => state.jobSearch);
	const global = useSelector((state) => state.global);
	const { renewInfo } = useSelector((state) => state.renew);

	const setCountryandJobType = (setJobCountryType, setJobType, isRenew) => {
		const jobMode = getPrefferedJob(isRenew);
		setJobCountryType(jobMode.jobCountryType);
		setJobType(jobMode.jobType);
		dispatch(updateJobCountryType(jobMode.jobCountryType));
		dispatch(updateJobType(jobMode.jobType));
		dispatch(updatePreferredJobWarehouse(jobMode.preferedWarehouse));
	};

	const getPrefferedJob = (isRenew) => {
		if (!isRenew) {
			return validatePrefferedJob();
		} else {
			if (
				renewInfo?.data?.storeListSH?.length === 0 &&
				renewInfo?.data?.storeListSC?.length === 0
			) {
				return getPrefferedShift();
			} else {
				return validatePrefferedJob();
			}
		}
	};

	const validatePrefferedJob = () => {
		const selectedJobs = jobSearch?.selectedJobs;
		if (selectedJobs?.length > 0) {
			return getCountryandJobType(selectedJobs);
		} else {
			return getPrefferedShift();
		}
	};

	const getPrefferedShift = () => {
		const storeArray = shiftIds['store'];
		const usWarHuseArr = shiftIds['usWarehouse'];
		const prWarHuseArr = shiftIds['prWarehouse'];
		const shift = app?.availability?.shiftPreference?.shift;
		const isStore = findCommonElements(shift, storeArray);
		const isUSWarehouse = findCommonElements(shift, usWarHuseArr);
		const isPRWarehouse = findCommonElements(shift, prWarHuseArr);

		if (isStore && isUSWarehouse && isPRWarehouse) {
			return { jobType: 'both', jobCountryType: 'both', preferedWarehouse: 'both' };
		} else if (isStore && isUSWarehouse) {
			return { jobType: 'both', jobCountryType: 'US', preferedWarehouse: 'US' };
		} else if (isStore && isPRWarehouse) {
			return { jobType: 'both', jobCountryType: 'PR', preferedWarehouse: 'PR' };
		} else if (isStore) {
			return { jobType: 'store', jobCountryType: 'both', preferedWarehouse: '' };
		} else if (isUSWarehouse && isPRWarehouse) {
			return { jobType: 'warehouse', jobCountryType: 'both', preferedWarehouse: 'both' };
		} else if (isUSWarehouse) {
			return { jobType: 'warehouse', jobCountryType: 'US', preferedWarehouse: 'US' };
		} else if (isPRWarehouse) {
			return { jobType: 'warehouse', jobCountryType: 'PR', preferedWarehouse: 'PR' };
		} else {
			return { jobType: 'both', jobCountryType: 'both', preferedWarehouse: 'both' };
		}
	};

	const findCommonElements = (arr1, arr2) => {
		return arr1.some((item) => arr2.includes(item));
	};

	const validateSaveRequest = (saveAvailRequest) => {
		let saveReqObj = { ...saveAvailRequest };
		saveReqObj = removeInvalidParams(saveReqObj);
		const storeArray = shiftIds['store'];
		const usWarHuseArr = shiftIds['usWarehouse'];
		const prWarHuseArr = shiftIds['prWarehouse'];
		if (global.jobType === 'store') {
			saveReqObj = shftSchdlArrayValidate(saveReqObj, storeArray);
		} else if (global.jobType === 'warehouse') {
			if (global.preferredJobWarehouse === 'US') {
				saveReqObj = shftSchdlArrayValidate(saveReqObj, usWarHuseArr);
			} else if (global.preferredJobWarehouse === 'PR') {
				saveReqObj = shftSchdlArrayValidate(saveReqObj, prWarHuseArr);
			} else if (global.preferredJobWarehouse === 'both') {
				saveReqObj = shftSchdlArrayValidate(saveReqObj, usWarHuseArr.concat(prWarHuseArr));
			}
		} else if (global.jobType === 'both') {
			if (global.preferredJobWarehouse === 'US') {
				saveReqObj = shftSchdlArrayValidate(saveReqObj, usWarHuseArr.concat(storeArray));
			} else if (global.preferredJobWarehouse === 'PR') {
				saveReqObj = shftSchdlArrayValidate(saveReqObj, prWarHuseArr.concat(storeArray));
			}
		}
		return saveReqObj;
	};

	const shftSchdlArrayValidate = (saveReqObj, shftArray) => {
		const newArr = [];
		const shift =
			!isEmptyObject(saveReqObj) && !isEmptyObject(saveReqObj.shiftPreference)
				? saveReqObj.shiftPreference.shift
				: [];
		shift.forEach((shft) => {
			if (shftArray.indexOf(shft) !== -1) {
				newArr.push(shft);
			}
		});
		if (newArr.length > 0) {
			saveReqObj.shiftPreference = { shift: newArr };
		} else {
			saveReqObj.shiftPreference = null;
		}
		if (!isEmptyObject(saveReqObj) && !isEmptyObject(saveReqObj.scheduleAvailability)) {
			validateSchedule(saveReqObj);
		}
		return saveReqObj;
	};

	const validateSchedule = (saveObj) => {
		const isShift = !isEmptyObject(saveObj.shiftPreference);
		const anyHrs = saveObj.scheduleAvailability.anyHours;
		if (
			anyHrs === '' ||
			(isShift && saveObj.shiftPreference.shift.length === 0 && anyHrs !== '') ||
			(isShift && global.jobType === 'warehouse' && anyHrs !== '')
		) {
			saveObj.scheduleAvailability = null;
		}
	};

	const removeInvalidParams = (saveReqObj) => {
		if (saveReqObj !== null && saveReqObj.scheduleAvailability === null) {
			return saveReqObj;
		}
		const schdAvail = saveReqObj.scheduleAvailability;
		schdAvail.sunStartTime = schdAvail.sunStartTime === '--' ? '' : schdAvail.sunStartTime;
		schdAvail.sunEndTime = schdAvail.sunEndTime === '--' ? '' : schdAvail.sunEndTime;
		schdAvail.monStartTime = schdAvail.monStartTime === '--' ? '' : schdAvail.monStartTime;
		schdAvail.monEndTime = schdAvail.monEndTime === '--' ? '' : schdAvail.monEndTime;
		schdAvail.tueStartTime = schdAvail.tueStartTime === '--' ? '' : schdAvail.tueStartTime;
		schdAvail.tueEndTime = schdAvail.tueEndTime === '--' ? '' : schdAvail.tueEndTime;
		schdAvail.wedStartTime = schdAvail.wedStartTime === '--' ? '' : schdAvail.wedStartTime;
		schdAvail.wedEndTime = schdAvail.wedEndTime === '--' ? '' : schdAvail.wedEndTime;
		schdAvail.thuStartTime = schdAvail.thuStartTime === '--' ? '' : schdAvail.thuStartTime;
		schdAvail.thuEndTime = schdAvail.thuEndTime === '--' ? '' : schdAvail.thuEndTime;
		schdAvail.friStartTime = schdAvail.friStartTime === '--' ? '' : schdAvail.friStartTime;
		schdAvail.friEndTime = schdAvail.friEndTime === '--' ? '' : schdAvail.friEndTime;
		schdAvail.satStartTime = schdAvail.satStartTime === '--' ? '' : schdAvail.satStartTime;
		schdAvail.satEndTime = schdAvail.satEndTime === '--' ? '' : schdAvail.satEndTime;
		saveReqObj.scheduleAvailability = schdAvail;
		return saveReqObj;
	};

	const updateOnChangeAvailabilityRedux = (saveAvailRequest, screenType) => {
		if (screenType === 'Employment') {
			dispatch(updateEmploymentType(saveAvailRequest.employmentType));
		} else if (screenType === 'ShiftPreference') {
			dispatch(updateShiftPreference(saveAvailRequest.shiftPreference));
		} else if (screenType === 'AvailableHours') {
			dispatch(updateScheduleAvailability(saveAvailRequest.scheduleAvailability));
		} else if (screenType === 'All') {
			dispatch(updateEmploymentType(saveAvailRequest.employmentType));
			dispatch(updateShiftPreference(saveAvailRequest.shiftPreference));
			dispatch(updateScheduleAvailability(saveAvailRequest.scheduleAvailability));
		}
	};

	const checkforDataUpdate = (screenType, saveAvailRequest) => {
		const reduxData = { ...app.availability };
		const currentData = { ...saveAvailRequest };
		if (screenType === 'Employment') {
			return _.isEqual(reduxData.employmentType, currentData.employmentType);
		} else if (screenType === 'ShiftPreference') {
			return _.isEqual(reduxData.shiftPreference.shift, currentData.shiftPreference.shift);
		} else if (screenType === 'AvailableHours') {
			return _.isEqual(reduxData.scheduleAvailability, currentData.scheduleAvailability);
		} else if (screenType === 'All') {
			return (
				_.isEqual(reduxData.employmentType, currentData.employmentType) &&
				_.isEqual(reduxData.shiftPreference.shift, currentData.shiftPreference.shift) &&
				_.isEqual(reduxData.scheduleAvailability, currentData.scheduleAvailability)
			);
		}
	};

	const storeToRedux = (screenType, saveAvailRequest) => {
		const reduxData = { ...app.availability };
		if (screenType === 'Employment') {
			reduxData.employmentType = saveAvailRequest.employmentType;
		} else if (screenType === 'ShiftPreference') {
			reduxData.shiftPreference = saveAvailRequest.shiftPreference;
			if (
				global.jobType === 'warehouse' &&
				reduxData.scheduleAvailability !== null &&
				reduxData.scheduleAvailability.anyHours !== ''
			) {
				reduxData.scheduleAvailability = availReqTemplate(0).scheduleAvailability;
			}
		} else if (screenType === 'AvailableHours') {
			reduxData.scheduleAvailability = saveAvailRequest.scheduleAvailability;
		} else if (screenType === 'All') {
			reduxData.employmentType = saveAvailRequest.employmentType;
			reduxData.shiftPreference = saveAvailRequest.shiftPreference;
			reduxData.scheduleAvailability = saveAvailRequest.scheduleAvailability;
		}
		if (reduxData.shiftPreference === null) {
			reduxData.shiftPreference = { shift: [] };
		}
		if (reduxData.scheduleAvailability === null) {
			reduxData.scheduleAvailability = availReqTemplate(0).scheduleAvailability;
		}
		dispatch(updateAvailability(reduxData));
	};

	const getOnChangeShift = (shiftPrefObj) => {
		const shiftArray = [];
		if (shiftPrefObj.wd1st) {
			shiftArray.push(shiftIds.SC_US_WEEKDAY_1_ST_SHIFT_ID);
		}
		if (shiftPrefObj.wd2nd) {
			shiftArray.push(shiftIds.SC_US_WEEKDAY_2_ND_SHIFT_ID);
		}
		if (shiftPrefObj.wd3rd) {
			shiftArray.push(shiftIds.SC_US_WEEKDAY_3_RD_SHIFT_ID);
		}
		if (shiftPrefObj.we1st) {
			shiftArray.push(shiftIds.SC_US_WEEKEND_4_TH_SHIFT_ID);
		}
		if (shiftPrefObj.we2nd) {
			shiftArray.push(shiftIds.SC_US_WEEKEND_5_TH_SHIFT_ID);
		}
		if (shiftPrefObj.we3rd) {
			shiftArray.push(shiftIds.SC_US_WEEKEND_6_TH_SHIFT_ID);
		}
		if (shiftPrefObj.pr1st) {
			shiftArray.push(shiftIds.SC_PR_1_ST_SHIFT_ID);
		}
		if (shiftPrefObj.pr2nd) {
			shiftArray.push(shiftIds.SC_PR_2_ND_SHIFT_ID);
		}
		if (shiftPrefObj.pr3rd) {
			shiftArray.push(shiftIds.SC_PR_3_RD_SHIFT_ID);
		}
		if (shiftPrefObj.opening) {
			shiftArray.push(shiftIds.OPENING_SHIFT_ID);
		}
		if (shiftPrefObj.morning) {
			shiftArray.push(shiftIds.MORNING_SHIFT_ID);
		}
		if (shiftPrefObj.midshift) {
			shiftArray.push(shiftIds.MID_SHIFT_SHIFT_ID);
		}
		if (shiftPrefObj.closing) {
			shiftArray.push(shiftIds.CLOSING_SHIFT_ID);
		}
		if (shiftPrefObj.overnight) {
			shiftArray.push(shiftIds.OVERNIGHT_SHIFT_ID);
		}
		if (shiftPrefObj.flex) {
			shiftArray.push(shiftIds.FLEX_SHIFT_ID);
		}
		if (shiftPrefObj.flexPr) {
			shiftArray.push(shiftIds.FLEX_SHIFT_ID_PR);
		}
		return { shift: shiftArray };
	};

	const validateDay = (schdlObj) => {
		return (
			!schdlObj.monCheckBox &&
			!schdlObj.tueCheckBox &&
			!schdlObj.wedCheckBox &&
			!schdlObj.thuCheckBox &&
			!schdlObj.friCheckBox &&
			!schdlObj.satCheckBox &&
			!schdlObj.sunCheckBox
		);
	};

	const validateSchdlTiming = (schdlObj) => {
		return (
			(schdlObj.monCheckBox && !validateTiming(schdlObj.monStartTime, schdlObj.monEndTime)) ||
			(schdlObj.tueCheckBox && !validateTiming(schdlObj.tueStartTime, schdlObj.tueEndTime)) ||
			(schdlObj.wedCheckBox && !validateTiming(schdlObj.wedStartTime, schdlObj.wedEndTime)) ||
			(schdlObj.thuCheckBox && !validateTiming(schdlObj.thuStartTime, schdlObj.thuEndTime)) ||
			(schdlObj.friCheckBox && !validateTiming(schdlObj.friStartTime, schdlObj.friEndTime)) ||
			(schdlObj.satCheckBox && !validateTiming(schdlObj.satStartTime, schdlObj.satEndTime)) ||
			(schdlObj.sunCheckBox && !validateTiming(schdlObj.sunStartTime, schdlObj.sunEndTime))
		);
	};

	const getChangeEmpObj = (contBtn, fullTimeChk, partTimeChk, temporaryChk) => {
		return {
			continuebtn: contBtn,
			fullTime: fullTimeChk,
			partTime: partTimeChk,
			temporary: temporaryChk,
			screen: 'Employment'
		};
	};
	const empTypeObj = {
		F: 'fullTime',
		P: 'partTime',
		T: 'temporary'
	};
	const shiftObj = {
		1: 'Weekday1st',
		2: 'Weekday2nd',
		3: 'Weekday3rd',
		4: 'Weekend1st',
		5: 'Weekend2nd',
		6: 'Weekend3rd',
		7: 'pr1st',
		8: 'pr2nd',
		9: 'pr3rd',
		10: 'Opening',
		11: 'Morning',
		12: 'Midshift',
		13: 'Closing',
		14: 'Overnight',
		17: 'flex7th',
		18: 'flex4th'
	};

	const validateTiming = (startTime, endTime) => {
		return startTime !== '' && startTime !== '--' && endTime !== '' && endTime !== '--';
	};
	const validateTimingSingleIp = (time) => {
		return time === '' ? '--' : time;
	};
	const handleTime = (time) => {
		return time !== '' && time !== '--';
	};

	const availReqTemplate = (applcnt) => {
		return {
			applicantId: applcnt,
			employmentType: {
				fullTime: false,
				partTime: false,
				temporary: false
			},
			shiftPreference: {
				shift: []
			},
			scheduleAvailability: {
				sunStartTime: '',
				sunEndTime: '',
				monStartTime: '',
				monEndTime: '',
				tueStartTime: '',
				tueEndTime: '',
				wedStartTime: '',
				wedEndTime: '',
				thuStartTime: '',
				thuEndTime: '',
				friStartTime: '',
				friEndTime: '',
				satStartTime: '',
				satEndTime: '',
				anyHours: ''
			}
		};
	};

	const scheduleAvailTemplate = () => {
		return {
			sunStartTime: '',
			sunEndTime: '',
			monStartTime: '',
			monEndTime: '',
			tueStartTime: '',
			tueEndTime: '',
			wedStartTime: '',
			wedEndTime: '',
			thuStartTime: '',
			thuEndTime: '',
			friStartTime: '',
			friEndTime: '',
			satStartTime: '',
			satEndTime: '',
			anyHours: true
		};
	};

	const dayObj = (days, fromTime, toTime, dayCheckbox) => {
		return {
			day: days,
			startTime: fromTime,
			endTime: toTime,
			checked: dayCheckbox
		};
	};

	const getCountryandJobType = (jobSearchJobs) => {
		let storeJobs = 0;
		let storeUSJobs = 0;
		let storePRJobs = 0;
		let warehouseJobs = 0;
		let warehouseUSJobs = 0;
		let warehousePRJobs = 0;
		const jobObj = { jobType: '', jobCountryType: '', preferedWarehouse: '' };
		const jobSearchArr = jobSearchJobs;
		jobSearchArr.forEach((val) => {
			if (val.workgroupId >= 5000) {
				warehouseJobs++;
				if (val.countryCode === 'US') {
					warehouseUSJobs++;
				} else {
					warehousePRJobs++;
				}
			} else if (val.workgroupId < 5000) {
				storeJobs++;
				if (val.countryCode === 'US') {
					storeUSJobs++;
				} else {
					storePRJobs++;
				}
			}
		});
		if (storeJobs > 0 && warehouseJobs > 0) {
			jobObj.jobType = 'both';
			if (
				(storeUSJobs > 0 && warehouseUSJobs > 0 && storePRJobs > 0 && warehousePRJobs > 0) ||
				((storeUSJobs > 0 || storePRJobs > 0) && warehouseUSJobs > 0 && warehousePRJobs > 0)
			) {
				jobObj.jobCountryType = 'both';
				jobObj.preferedWarehouse = 'both';
			} else if (storeUSJobs > 0 && warehousePRJobs > 0) {
				jobObj.jobCountryType = 'both';
				jobObj.preferedWarehouse = 'PR';
			} else if (storePRJobs > 0 && warehouseUSJobs > 0) {
				jobObj.jobCountryType = 'both';
				jobObj.preferedWarehouse = 'US';
			} else if (storeUSJobs > 0 && warehouseUSJobs > 0) {
				jobObj.jobCountryType = 'US';
				jobObj.preferedWarehouse = 'US';
			} else if (storePRJobs > 0 && warehousePRJobs > 0) {
				jobObj.jobCountryType = 'PR';
				jobObj.preferedWarehouse = 'PR';
			}
		} else if (storeJobs > 0) {
			const stUsJobs = storeUSJobs > 0 ? 'US' : 'PR';
			jobObj.jobType = 'store';
			jobObj.jobCountryType = storeUSJobs > 0 && storePRJobs > 0 ? 'both' : stUsJobs;
		} else if (warehouseJobs > 0) {
			jobObj.jobType = 'warehouse';
			if (warehouseUSJobs > 0 && warehousePRJobs > 0) {
				jobObj.jobCountryType = 'both';
				jobObj.preferedWarehouse = 'both';
			} else if (warehouseUSJobs > 0) {
				jobObj.jobCountryType = 'US';
				jobObj.preferedWarehouse = 'US';
			} else if (warehousePRJobs > 0) {
				jobObj.jobCountryType = 'PR';
				jobObj.preferedWarehouse = 'PR';
			}
		}
		return jobObj;
	};

	const validateRenewAvailability = () => {
		const shiftPref = app?.shiftPreference?.shift;
		const schdlAvail = app?.scheduleAvailability;
		const empType = app?.employmentType;

		const shift = shftArrayValidate(shiftPref);

		if (!empType.fullTime && !empType.partTime && !empType.temporary) {
			dispatch(renewAvailPanelError(true));
		} else {
			if (global.jobType === 'warehouse') {
				if (shift) {
					dispatch(renewAvailPanelError(false));
				} else {
					dispatch(renewAvailPanelError(true));
				}
			} else {
				if (shift && schdlAvail?.anyHours !== '') {
					if (schdlAvail?.anyHours) {
						dispatch(renewAvailPanelError(false));
					} else if (!schdlAvail?.anyHours) {
						const timingLen = isSchdlTimingValid();
						timingLen === 2
							? dispatch(renewAvailPanelError(false))
							: dispatch(renewAvailPanelError(true));
					}
				} else {
					dispatch(renewAvailPanelError(true));
				}
			}
		}
	};

	const shftArrayValidate = (shiftPref) => {
		if (global.jobType === 'store') {
			return storeShft(shiftPref);
		} else if (global.jobType === 'warehouse') {
			if (global.preferredJobWarehouse === 'US') {
				return usWarehouseShft(shiftPref);
			} else if (global.preferredJobWarehouse === 'PR') {
				return prWarehouseShft(shiftPref);
			} else if (global.preferredJobWarehouse === 'both') {
				return usWarehouseShft(shiftPref) && prWarehouseShft(shiftPref);
			}
		} else if (global.jobType === 'both') {
			if (global.preferredJobWarehouse === 'US') {
				return storeShft(shiftPref) && usWarehouseShft(shiftPref);
			} else if (global.preferredJobWarehouse === 'PR') {
				return storeShft(shiftPref) && prWarehouseShft(shiftPref);
			} else if (global.preferredJobWarehouse === 'both') {
				return storeShft(shiftPref) && usWarehouseShft(shiftPref) && prWarehouseShft(shiftPref);
			}
		}
	};

	const isSchdlTimingValid = () => {
		const timing = app.scheduleAvailability;
		const timeArr = [];
		const tempArr = getTempArrayTime(timing);

		if (validateTiming(timing.monStartTime, timing.monEndTime)) {
			timeArr.push(timing.monStartTime);
			timeArr.push(timing.monEndTime);
		}
		if (validateTiming(timing.tueStartTime, timing.tueEndTime)) {
			timeArr.push(timing.tueStartTime);
			timeArr.push(timing.tueEndTime);
		}

		if (validateTiming(timing.wedStartTime, timing.wedEndTime)) {
			timeArr.push(timing.wedStartTime);
			timeArr.push(timing.wedEndTime);
		}
		if (validateTiming(timing.thuStartTime, timing.thuEndTime)) {
			timeArr.push(timing.thuStartTime);
			timeArr.push(timing.thuEndTime);
		}

		if (validateTiming(timing.friStartTime, timing.friEndTime)) {
			timeArr.push(timing.friStartTime);
			timeArr.push(timing.friEndTime);
		}
		if (validateTiming(timing.satStartTime, timing.satEndTime)) {
			timeArr.push(timing.satStartTime);
			timeArr.push(timing.satEndTime);
		}

		if (validateTiming(timing.sunStartTime, timing.sunEndTime)) {
			timeArr.push(timing.sunStartTime);
			timeArr.push(timing.sunEndTime);
		}

		if (tempArr?.length > 0 && timeArr?.length > 0 && tempArr?.length === timeArr?.length) {
			return 2;
		} else {
			return 1;
		}
	};

	const getTempArrayTime = (timing) => {
		const tempArr = [];

		if (handleTime(timing.monStartTime)) {
			tempArr.push(timing.monStartTime);
		}
		if (handleTime(timing.monEndTime)) {
			tempArr.push(timing.monEndTime);
		}
		if (handleTime(timing.tueStartTime)) {
			tempArr.push(timing.tueStartTime);
		}
		if (handleTime(timing.tueEndTime)) {
			tempArr.push(timing.tueEndTime);
		}
		if (handleTime(timing.wedStartTime)) {
			tempArr.push(timing.wedStartTime);
		}
		if (handleTime(timing.wedEndTime)) {
			tempArr.push(timing.wedEndTime);
		}
		if (handleTime(timing.thuStartTime)) {
			tempArr.push(timing.thuStartTime);
		}
		if (handleTime(timing.thuEndTime)) {
			tempArr.push(timing.thuEndTime);
		}
		if (handleTime(timing.friStartTime)) {
			tempArr.push(timing.friStartTime);
		}
		if (handleTime(timing.friEndTime)) {
			tempArr.push(timing.friEndTime);
		}
		if (handleTime(timing.satStartTime)) {
			tempArr.push(timing.satStartTime);
		}
		if (handleTime(timing.satEndTime)) {
			tempArr.push(timing.satEndTime);
		}
		if (handleTime(timing.sunStartTime)) {
			tempArr.push(timing.sunStartTime);
		}
		if (handleTime(timing.sunEndTime)) {
			tempArr.push(timing.sunEndTime);
		}
		return tempArr;
	};

	const storeShft = (shiftPref) => {
		const storeArr = [];
		shiftPref.forEach((shft) => {
			if (shft >= shiftIds.OPENING_SHIFT_ID && shft <= shiftIds.OVERNIGHT_SHIFT_ID) {
				storeArr.push(shft);
			}
		});
		return storeArr?.length > 0;
	};

	const usWarehouseShft = (shiftPref) => {
		const usWarHuseArr = [];
		shiftPref.forEach((shft) => {
			if (
				shft >= shiftIds.SC_US_WEEKDAY_1_ST_SHIFT_ID &&
				shft <= shiftIds.SC_US_WEEKEND_6_TH_SHIFT_ID
			) {
				usWarHuseArr.push(shft);
			}
		});
		return usWarHuseArr?.length > 0;
	};

	const prWarehouseShft = (shiftPref) => {
		const prWarHuseArr = [];
		shiftPref.forEach((shft) => {
			if (shft >= shiftIds.SC_PR_1_ST_SHIFT_ID && shft <= shiftIds.SC_PR_3_RD_SHIFT_ID) {
				prWarHuseArr.push(shft);
			}
		});
		return prWarHuseArr?.length > 0;
	};

	const isFromEditProfile = (editProfile) => {
		return (
			editProfile.editAvailability ||
			editProfile.shiftUpdateNeeded ||
			editProfile.scheduleUpdateNeeded
		);
	};

	return {
		setCountryandJobType,
		updateOnChangeAvailabilityRedux,
		checkforDataUpdate,
		storeToRedux,
		getOnChangeShift,
		validateDay,
		validateSchdlTiming,
		getChangeEmpObj,
		shiftObj,
		validateTiming,
		availReqTemplate,
		scheduleAvailTemplate,
		dayObj,
		validateSaveRequest,
		validateSchedule,
		validateTimingSingleIp,
		validateRenewAvailability,
		isFromEditProfile,
		getCountryandJobType,
		empTypeObj
	};
};
