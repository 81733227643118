import React from 'react';
import { Card } from '@walmart-web/livingdesign-components';
import styles from './EditCard.module.css';

const EditCard = (props) => {
	return (
		<div className={styles.editCard}>
			<Card>
				<div className={styles.editCardContainer}>
					<div className={styles.editCardTitle}>
						{props.icon} &nbsp; {props.title}
					</div>
				</div>
				<div className={styles.editCardBody}>{props.children}</div>
			</Card>
		</div>
	);
};

export default EditCard;
