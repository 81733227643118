import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { BottomSheet, Modal } from '@walmart-web/livingdesign-components';
import FeedbackContentHolder from './components/FeedbackContentHolder';
import FeedbackAcknowledge from './components/FeedbackAcknowledge';
import { unwrapResult } from '@reduxjs/toolkit';
import { postFeedback } from '../../../../redux/Feedback/request';
import { updateLoading } from '../../../../redux/slices/globalSlice';
import './style.scss';
import { sendGtmEvent } from 'utils/GoogleTagManagerUtils';
import { GTM } from 'config/const';

const FeedbackCollector = (props) => {
	const [displayAck, setDisplayAck] = useState(false);
	const applicantId = useSelector((state) => state.global.applicantId);

	const isDesktop = useMediaQuery({ minWidth: 851 });
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const feedbackTitle = 'feedback.title';

	const handleClose = () => {
		setDisplayAck(false);
		props.setIsOpen(false);
	};

	const handleSubmitButtonClick = (feedback) => {
		sendGtmEvent(GTM.EVENTS.SUBMIT_FEEDBACK);
		dispatch(updateLoading(true));
		dispatch(
			postFeedback({
				applicantId: applicantId,
				feedbackValue: feedback.rating,
				message: feedback.comments
			})
		)
			.then(unwrapResult)
			.then((result) => {
				props.setIsOpen(false);
				setDisplayAck(true);
			})
			.catch((error) => {});
	};

	return (
		<>
			{(isDesktop || isTablet) && (
				<>
				<Modal isOpen={props.isOpen} onClose={handleClose} title={t(feedbackTitle)}>
					<FeedbackContentHolder handleSubmit={handleSubmitButtonClick} />
				</Modal>
				<Modal isOpen={displayAck} onClose={handleClose} title={t(feedbackTitle)}>
					<FeedbackAcknowledge handleClose={handleClose} />
				</Modal>
			</>
			)}
			{isMobile && (
				<>
				<BottomSheet isOpen={props.isOpen} onClose={handleClose} title={t(feedbackTitle)}>
					<FeedbackContentHolder handleSubmit={handleSubmitButtonClick} />
				</BottomSheet>
				<BottomSheet isOpen={displayAck} onClose={handleClose} title={t(feedbackTitle)}>
					<FeedbackAcknowledge handleClose={handleClose} />
				</BottomSheet>
				</>
			)}
		</>
	);
};

export default FeedbackCollector;
