import React, { useState, useEffect } from 'react';
import {
	FormGroup,
	Heading,
	Checkbox,
	Alert,
	Body
} from '@walmart-web/livingdesign-components';
import cookie from 'react-cookies';
import mystyle from './EmploymentType.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { subHeader } from '../../../redux/slices/globalSlice';
import { useTranslation } from 'react-i18next';
import { isEmptyObject } from '../../../utils/validation';
import { useDisplay } from '../../../utils/useDisplay';
import { useLocation } from 'react-router';
import { AvailabilityHelper } from '../AvailabilityHelper';
import { getAutoFillCookieInfo } from 'utils/availabilityCookieUtils';

const EmploymentType = ({ empTypeFunc, isRenew, setAutoFillsDeselected, declineAutoFillsDeselection}) => {
	const { t } = useTranslation();
	const [showAutoFillAlert, setShowAutoFillAlert] = useState(false);
	const [autoFillEmpTypes, setAutoFillEmpTypes] = useState([]);
	const [fullTimeCheckBox, setFullTimeCheckBox] = useState(false);
	const [partTimeCheckBox, setPartTimeCheckBox] = useState(false);
	const [temporaryCheckBox, setTemporaryCheckBox] = useState(false);
	const jobStore = useSelector((state) => state.jobSearch);
	const empStore = useSelector((state) => state.availMain);
	const location = useLocation();
	const { isPortrait } = useDisplay();
	const header = isPortrait ? 'availability.empType' : 'availability.avail';
	const { getChangeEmpObj } = AvailabilityHelper();
	const editProfile = useSelector((state) => state.editProfile);
	let uncheckedAFEmpTypes = [];
	const dispatch = useDispatch();

	const routeToPath = () => {
		if (editProfile.editAvailability) {
			return '/edit-profile';
		} else if (editProfile.shiftUpdateNeeded || editProfile.scheduleUpdateNeeded) {
			return '/login';
		} else {
			return '/job-search';
		}
	};

	useEffect(() => {
		dispatch(
			subHeader({
				title: header,
				routeTo: routeToPath(),
				display: location.pathname !== '/renew'
			})
		);
		setEmploymentTypes();
		// eslint-disable-next-line
	}, [empStore.availability, jobStore.currentSelectedJobs]);

	useEffect(() =>{
		if(declineAutoFillsDeselection){
			setEmploymentTypes();
		}
	},[declineAutoFillsDeselection])

	const setEmploymentTypes = () =>{
		const empTypeResponse = JSON.parse(JSON.stringify(empStore));
		uncheckedAFEmpTypes=[];
		const empObj = {
			continuebtn: false,
			fullTime: false,
			partTime: false,
			temporary: false,
			screen: 'Employment'
		};
		if (
			!isEmptyObject(empTypeResponse) &&
			!isEmptyObject(empTypeResponse.availability) &&
			!isEmptyObject(empTypeResponse.availability.employmentType)
		) {
			const eType = empTypeResponse.availability.employmentType;
			if (eType.fullTime === true) {
				setFullTimeCheckBox(true);
				empObj.continuebtn = true;
				empObj.fullTime = true;
				empTypeFunc(empObj);
			}
			if (eType.partTime === true) {
				setPartTimeCheckBox(true);
				empObj.continuebtn = true;
				empObj.partTime = true;
				empTypeFunc(empObj);
			}
			if (eType.temporary === true) {
				setTemporaryCheckBox(true);
				empObj.continuebtn = true;
				empObj.temporary = true;
				empTypeFunc(empObj);
			}
			if (
				isPortrait &&
				global.applicantId &&
				!empObj.fullTime &&
				!empObj.partTime &&
				!empObj.temporary
			) {
				empTypeFunc(empObj);
			}
		}
		const autoFillEmpDeclined = getAutoFillCookieInfo('employmentTypeAutoFillDeclined');
		if(!autoFillEmpDeclined){
			const selectJobsEmpType = jobStore.currentSelectedJobs.map((i) => i.employmentTypes);
			if (Array.isArray(selectJobsEmpType) && selectJobsEmpType.length) {
				const selectedEmpTypes = [].concat(...selectJobsEmpType);
				setAutoFillEmpTypes(selectedEmpTypes);
				if (selectedEmpTypes.includes('F') && !empObj.fullTime) {
					setFullTimeCheckBox(true);
					setShowAutoFillAlert(true);
					empObj.fullTime = true;
					empObj.continuebtn = true;
					empTypeFunc(empObj);
				}
				if (selectedEmpTypes.includes('P') && !empObj.partTime) {
					setPartTimeCheckBox(true);
					setShowAutoFillAlert(true);
					empObj.partTime = true;
					empObj.continuebtn = true;
					empTypeFunc(empObj);
				}
				if (selectedEmpTypes.includes('T') && !empObj.temporary) {
					setTemporaryCheckBox(true);
					setShowAutoFillAlert(true);
					empObj.temporary = true;
					empObj.continuebtn = true;
					empTypeFunc(empObj);
				}
			}
		}
	}

	const checkIfAutoFillsDeslected = (empType) =>{
		switch(empType){
			case 'F' : fullTimeCheckBox ? uncheckedAFEmpTypes.push('F') 
										: (uncheckedAFEmpTypes = uncheckedAFEmpTypes.filter((item) => item !== 'F'));
					break;

			case 'P' : partTimeCheckBox ? uncheckedAFEmpTypes.push('P') 
										: (uncheckedAFEmpTypes = uncheckedAFEmpTypes.filter((item) => item !== 'P'));
					break;
			
			case 'T' : temporaryCheckBox ? uncheckedAFEmpTypes.push('T') 
										: (uncheckedAFEmpTypes = uncheckedAFEmpTypes.filter((item) => item !== 'T'));
					break;
			default:
		}
		setAutoFillsDeselected((uncheckedAFEmpTypes.length !== 0));
	}

	const onChangeEmpType = (empType) => {
		let isChecked;
		let empTypChk;
		switch (empType) {
			case 'FullTime':
				if (autoFillEmpTypes.includes('F')) {
					//setAutoFillsDeselected(true);
					checkIfAutoFillsDeslected('F');
				}
				isChecked = !fullTimeCheckBox;
				setFullTimeCheckBox(isChecked);
				empTypChk = isChecked || partTimeCheckBox || temporaryCheckBox;
				empTypChk
					? empTypeFunc(getChangeEmpObj(true, isChecked, partTimeCheckBox, temporaryCheckBox))
					: empTypeFunc(getChangeEmpObj(false, isChecked, partTimeCheckBox, temporaryCheckBox));
				break;
			case 'PartTime':
				if (autoFillEmpTypes.includes('P')) {
					 //setAutoFillsDeselected(true);
					checkIfAutoFillsDeslected('P');
				}
				isChecked = !partTimeCheckBox;
				setPartTimeCheckBox(isChecked);
				empTypChk = fullTimeCheckBox || isChecked || temporaryCheckBox;
				empTypChk
					? empTypeFunc(getChangeEmpObj(true, fullTimeCheckBox, isChecked, temporaryCheckBox))
					: empTypeFunc(getChangeEmpObj(false, fullTimeCheckBox, isChecked, temporaryCheckBox));
				break;
			case 'Temporary':
				if (autoFillEmpTypes.includes('T')) {
					//setAutoFillsDeselected(true);
					checkIfAutoFillsDeslected('T');
				}
				isChecked = !temporaryCheckBox;
				setTemporaryCheckBox(isChecked);
				empTypChk = fullTimeCheckBox || partTimeCheckBox || isChecked;
				empTypChk
					? empTypeFunc(getChangeEmpObj(true, fullTimeCheckBox, partTimeCheckBox, isChecked))
					: empTypeFunc(getChangeEmpObj(false, fullTimeCheckBox, partTimeCheckBox, isChecked));
				break;
			default:
		}
	};

	return (
		<div>
			<div className={!isRenew ? mystyle.employment : mystyle.employmentRenew}>
				<FormGroup>
					<div className={mystyle.formContainer}>
						{showAutoFillAlert && (
							<Alert variant="success">{t('availability.autoFillEmpTypeAlert')}</Alert>
						)}
						
						{!isRenew && (
							<div className={mystyle.heading}>
							<Heading as="h1" size="medium" weight="700">
								{t('availability.empHeader')}
							</Heading>
						</div>
						)}

						{isRenew && (
							<Body as="p" size="small">
								{t('renew.empTypeBody')}
							</Body>
						)}
						<div>
							<Checkbox
								label={t('availability.fullTime')}
								checked={fullTimeCheckBox ? true : false}
								onChange={() => onChangeEmpType('FullTime')}
							/>
						</div>
						<div>
							<Checkbox
								label={t('availability.partTime')}
								checked={partTimeCheckBox ? true : false}
								onChange={() => onChangeEmpType('PartTime')}
							/>
						</div>
						<div>
							<Checkbox
								label={t('availability.temporary')}
								checked={temporaryCheckBox ? true : false}
								onChange={() => onChangeEmpType('Temporary')}
							/>
						</div>
					</div>
				</FormGroup>
			</div>
		</div>
	);
};

export default EmploymentType;
