import {
  Body,
  Caption,
  Card,
  Divider,
  Heading,
  Button,
} from '@walmart-web/livingdesign-components';
import {useVirtualJobOffer} from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import React, {memo, useMemo, useCallback} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import './declineMessage.scss';

/**
 * Decline message. Show when VJO is declined.
 * @returns - memoized view
 */
const DeclineMessage = () => {
  const {t} = useTranslation();
  const {jobOffer} = useVirtualJobOffer();
  const navigate = useNavigate();

  const updatePreference = useCallback(() => {
    navigate('../update-preference');
  }, [navigate]);

  const contentView = useMemo(() => {
    return (
      <Card size='small' UNSAFE_className='djo_body'>
        <>
          <Heading
            size='small'
            weight={700}
            children={t('declineMessage.header')}
          />
          <Body size='small' UNSAFE_className='djo_message'>
            <Trans>
              {t('declineMessage.message', {jobName: jobOffer?.jobName})}
            </Trans>
          </Body>
          <Caption>{t('declineMessage.footer')}</Caption>
          <Divider />
          <Button
            variant='primary'
            children={t('declineMessage.updatePreference')}
            onClick={updatePreference}
          />
        </>
      </Card>
    );
  }, [t, jobOffer, updatePreference]);

  return (
    <div>
      <div className='djo_header' />
      {contentView}
    </div>
  );
};

export default memo(DeclineMessage);
