import { TextField } from '@walmart-web/livingdesign-components';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './CustomTextField.module.scss';

const CustomTextField = ({
	label,
	defaultValue,
	onChangeCallback,
	placeholder,
	showAdditionalError,
	emptyValidation,
	onValidationCallback,
	hideError
}) => {
	const [value, setValue] = useState(defaultValue || '');
	const [error, setError] = useState(null);
	const { t } = useTranslation();

	useEffect(() => {
		if (emptyValidation) {
			if (!value) {
				setError(t('wotc.fieldCannotBeEmpty'));
			} else {
				onChangeCallback?.(value);
				setError(null);
			}
		} else {
			onChangeCallback?.(value);
		}

		return () => {
			setError(null);
		};
	}, [value, emptyValidation]);

	useEffect(() => {
		if (onValidationCallback) {
			onValidationCallback(error);
		}
	}, [error]);

	return (
		<TextField
			label={label}
			placeholder={placeholder || ''}
			onChange={(e) => setValue(e.target.value)}
			value={value}
			UNSAFE_className={`${styles.textField} ${!label ? styles.noLabel : ''}`}
			error={!hideError && error ? error : showAdditionalError?.(value)}
		/>
	);
};

CustomTextField.propTypes = {
	label: PropTypes.string,
	defaultValue: PropTypes.string,
	onChangeCallback: PropTypes.func,
	placeholder: PropTypes.string,
	showAdditionalError: PropTypes.func,
	emptyValidation: PropTypes.bool,
	hideError: PropTypes.bool
};

export default CustomTextField;
