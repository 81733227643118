import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import style from './StudentStatus.module.css';
import commonStyles from '../../../../Style/commonStyle.module.css';
import {
	Body,
	FormGroup,
	Radio,
	Button,
	Alert,
	TextField
} from '@walmart-web/livingdesign-components';
import { validateEmail } from '../../../../utils/validation';
import { animateScroll as scroll } from 'react-scroll';
import { Check } from '@livingdesign/icons';
import {
	updateAnswer,
	updateminorStatus,
	updateParentEmail,
	updateRequestApplicantId,
	updateCheckHire,
	updateAboutYouHcTestReponseAnswer,
	updateAboutYouParentInfo
} from '../../../../redux/AboutYou/slice';
import { useTranslation } from 'react-i18next';
import { aboutApplicantStep2 } from '../../../../redux/AboutYou/request';
import { executionErrorOccured, updateLoading } from '../../../../redux/slices/globalSlice';
import { callHiringHelper } from '../../../../redux/HiringHelper/request';

const StudentStatus = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [emailError, setEmailError] = useState({});
	const [disableButton, setDisableButton] = useState({
		student: true,
		age: true,
		parentEmail: true,
		parentName: true
	});
	const [parentName, setParentName] = useState('');
	const [parentEmail, setParentEmail] = useState('');
	const [highSchool, sethighSchool] = useState(false);
	const [college, setCollege] = useState(false);
	const [notStudent, setNotStudent] = useState(false);
	const [ageCriteria1, setAgeCriteria1] = useState(false);
	const [ageCriteria2, setAgeCriteria2] = useState(false);
	const [ageCriteria3, setAgeCriteria3] = useState(false);
	const [success, setSuccess] = useState(false);
	const aboutYou = useSelector((state) => state.aboutYou);
	const global = useSelector((state) => state.global);
	const account = useSelector((state) => state.account);

	useEffect(() => {
		dispatch(updateRequestApplicantId({ applicantId: global.applicantId }));
		dispatch(updateCheckHire({ checkRehire: false }));
		const applInfo = aboutYou.applInfo;
		let schoolStatus = false;
		let ageStatus = false;
		let below18 = false;
		let highSchoolStd = false;
		if (Object.keys(applInfo).length !== 0) {
			if (applInfo.parentFullName) {
				setParentName(applInfo.parentFullName);
				setDisableButton((prevState) => ({ ...prevState, parentName: false }));
			}
			if (applInfo.parentEmailId) {
				setParentEmail(applInfo.parentEmailId);
				setDisableButton((prevState) => ({ ...prevState, parentEmail: false }));
			}

			applInfo.hcTestResponse.forEach((obj) => {
				if (obj.questionNbr === 44 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
					if (obj.answerNbr === 119) {
						sethighSchool(true);
						schoolStatus = true;
						highSchoolStd = true;
					} else if (obj.answerNbr === 120) {
						setCollege(true);
						schoolStatus = true;
					} else if (obj.answerNbr === 118) {
						setNotStudent(true);
						schoolStatus = true;
					}

					setDisableButton((prevState) => ({
						...prevState,
						student: false
					}));

					dispatch(
						updateAnswer({
							ques: obj.questionNbr,
							ans: obj.answerNbr
						})
					);
				}
				if (obj.questionNbr === 4 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
					if (obj.answerNbr === 93) {
						setAgeCriteria1(true);
						ageStatus = true;
						below18 = true;
					} else if (obj.answerNbr === 94) {
						setAgeCriteria2(true);
						ageStatus = true;
						below18 = true;
					} else if (obj.answerNbr === 95) {
						setAgeCriteria3(true);
						ageStatus = true;
					}

					dispatch(
						updateAnswer({
							ques: obj.questionNbr,
							ans: obj.answerNbr
						})
					);

					setDisableButton((prevState) => ({
						...prevState,
						age: false
					}));
				}
			});
			let parentUpdateNeeded = false;
			if (below18 || highSchoolStd) {
				dispatch(updateminorStatus({ minor: true }));
				if (
					!applInfo.parentFullName ||
					!applInfo.parentEmailId ||
					applInfo.parentFullName === '' ||
					applInfo.parentEmailId === ''
				) {
					parentUpdateNeeded = true;
				} else {
					setDisableButton((prevState) => ({
						...prevState,
						parentName: false,
						parentEmail: false
					}));
				}
			}

			if (!parentUpdateNeeded && schoolStatus && ageStatus && props.editProfile === undefined) {
				studentStatusComplete();
			}
		}

		// eslint-disable-next-line
	}, []);

	const handleStudentStatus = (e) => {
		if (props && typeof props.handleVisaScreen === 'function'){
			props.handleVisaScreen(false);
		}
		setSuccess(false);
		const value = e.target.name;
		let highSchoolStudent = false;
		if (value === 'highSchool') {
			highSchoolStudent = true;
			sethighSchool(true);
			setCollege(false);
			setNotStudent(false);
			dispatch(
				updateAnswer({
					ques: 44,
					ans: 119
				})
			);
		}
		if (value === 'collegeOrTradeSchool') {
			setCollege(true);
			sethighSchool(false);
			setNotStudent(false);
			dispatch(
				updateAnswer({
					ques: 44,
					ans: 120
				})
			);
		}
		if (value === 'notStudent') {
			setNotStudent(true);
			sethighSchool(false);
			setCollege(false);
			dispatch(
				updateAnswer({
					ques: 44,
					ans: 118
				})
			);
		}
		if ((ageCriteria3 && highSchoolStudent) || (ageCriteria1 || ageCriteria2)) {
			dispatch(updateminorStatus({ minor: true }));
		} else{
			dispatch(updateminorStatus({ minor: false }));
		}
		if(!emailError && parentEmail!==""){
			setDisableButton((prevState) => ({ ...prevState, parentEmail: false }));
		}
		if(parentName!=="") {
			setDisableButton((prevState) => ({ ...prevState, parentName: false }));
		}
		setDisableButton((prevState) => ({ ...prevState, student: false }));

	};

	const handleStudentAge = (e) => {
		if (props && typeof props.handleVisaScreen === 'function'){
			props.handleVisaScreen(false);
		}
		setSuccess(false);

		const value = e.target.name;
		let age18 = false;
		if (value === 'ageCriteria1') {
			setAgeCriteria1(true);
			setAgeCriteria2(false);
			setAgeCriteria3(false);
			dispatch(
				updateAnswer({
					ques: 4,
					ans: 93
				})
			);
			dispatch(updateminorStatus({ minor: true }));
		} else if (value === 'ageCriteria2') {
			setAgeCriteria2(true);
			setAgeCriteria1(false);
			setAgeCriteria3(false);
			dispatch(
				updateAnswer({
					ques: 4,
					ans: 94
				})
			);
			dispatch(updateminorStatus({ minor: true }));
		} else if (value === 'ageCriteria3') {
			age18 = true;
			setAgeCriteria3(true);
			setAgeCriteria1(false);
			setAgeCriteria2(false);
			dispatch(
				updateAnswer({
					ques: 4,
					ans: 95
				})
			);
			dispatch(updateminorStatus({ minor: false }));
		}

		if (age18 && highSchool) {
			dispatch(updateminorStatus({ minor: true }));
		}
		setDisableButton((prevState) => ({ ...prevState, age: false }));
	};
	const removeInvalidChars = (value) => {
		let check = value;
		check = check.replace(/[^\w\s\,\.\-\'']/gi, '');

		check = check.replace(/^\s+/g, '');
		check = check.replace(/\d+/g, '');
		check = check.replace(/_/g, '');
		return check.toUpperCase();
	};
	const handleParentsName = (e) => {
		if (props && typeof props.handleVisaScreen === 'function'){
			props.handleVisaScreen(false);
		}
		setSuccess(false);
		let value = e.target.value;
		if (value === '') {
			setParentName('');
		}
		value = removeInvalidChars(value);

		setParentName(value.slice(0, 40));
		setDisableButton((prevState) => ({
			...prevState,
			parentName: !(value && value !== '')
		}));
	};

	const handleParentsEmail = (e) => {
		if (props && typeof props.handleVisaScreen === 'function'){
			props.handleVisaScreen(false);
		}
		setSuccess(false);
		const value = e.target.value;
		if (value === '') {
			setParentEmail('');
		} else {
			setParentEmail(value.slice(0, 80));
		}
		const valid = validateEmail(value, emailError, setEmailError);

		if (valid === undefined) {
			setDisableButton((prevState) => ({
				...prevState,
				parentEmail: false
			}));
		} else {
			setDisableButton((prevState) => ({
				...prevState,
				parentEmail: true
			}));
		}
		dispatch(updateParentEmail({ parentEmailId: parentEmail }));
	};

	const handleButton = (e) => {
		dispatch(updateParentEmail({ parentEmailId: parentEmail }));
		const ageData = aboutYou.aboutYouReq.hcTestResponse[0];
		const studentData = aboutYou.aboutYouReq.hcTestResponse[1];
		const req = {
			applicantId: global.applicantId,
			hcTestResponse: [ageData, studentData],
			minor: aboutYou.aboutYouReq.minor,
			parentEmailId: parentEmail,
			parentFullName: parentName
		};
		dispatch(updateLoading(true));
		dispatch(aboutApplicantStep2(req))
			.then((res) => {
				if (res.payload.responseCode === '200 ' || res.payload.responseCode === '201') {
					dispatch(updateAboutYouHcTestReponseAnswer(ageData));
					dispatch(updateAboutYouHcTestReponseAnswer(studentData));
					dispatch(updateAboutYouParentInfo(req));
					if (props.editProfile === undefined) {
						if (props && typeof props.handleVisaScreen === 'function'){
							props.handleVisaScreen(true);
						}
						setSuccess(true);
						scroll.scrollToBottom();
					}
					else{
						dispatch(callHiringHelper(global.applicantId))
						props.handleCloseEdit();
					}
				}
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const studentStatusComplete = (e) => {
		if (props && typeof props.handleVisaScreen === 'function'){
			props.handleVisaScreen(true);
		}
		setSuccess(true);
		scroll.scrollToBottom();
	};

	const shouldDisabledNext = () => {
		if (highSchool || ageCriteria1 || ageCriteria2) {
			return !disableButton.student &&
				!disableButton.age &&
				!disableButton.parentEmail &&
				!disableButton.parentName
				? false
				: true;
		}
		return !disableButton.age && !disableButton.student ? false : true;
	};

	const buttonDisplay = () => {
		return !success ? (
			<div>
				<Button
					data-testid='aboutYouPersonalInfo_next_button'
					id='aboutYouPersonalInfo_next_button'
					size="small"
					variant="primary"
					onClick={handleButton}
					disabled={shouldDisabledNext()}>
					{t('aboutYouPersonalInfo.next')}
				</Button>
			</div>
		) : (
			<div style={{ width: '100%' }}>
				<div className={style.successLogo}>
					<Check size="small" />
				</div>

				<Body as="p" size="small" weight={400} UNSAFE_style={{ color: '#1D5F02' }}>
					{t('aboutYouPersonalInfo.complete')}
				</Body>
			</div>
		);
	};
	return (
		<FormGroup>
			<div className={props.editProfile ? style.pageEditProfile : style.page} id="studentScreen">
				{props.editProfile === undefined &&<div>
					<Body as="p" size="medium" weight={700}>
						{t('student.title')}
					</Body>
				</div>}
					<div>
						<Body as="p" size="medium" weight={400}>
							{t('student.ques1')}
						</Body>
					</div>
				<fieldset className={commonStyles.fieldSet}>
					<legend className={commonStyles.legend}>
						<Body as="p" size="small" weight={400} aria-label={t('student.ques1Text')}>
							{t('student.ques1Text')}
						</Body>
					</legend>
						<Radio
							label={t('student.highSchool')}
							name="highSchool"
							checked={highSchool}
							onChange={handleStudentStatus}
						/>
						<Radio
							label={t('student.college')}
							name="collegeOrTradeSchool"
							checked={college}
							onChange={handleStudentStatus}
						/>
						<Radio
							label={t('student.no')}
							name="notStudent"
							checked={notStudent}
							onChange={handleStudentStatus}
						/>
				</fieldset>
				<fieldset className={commonStyles.fieldSet}>
					<div>
						<legend>
							<Body as="p" size="medium" weight={400}>
								{t('student.ageCriteriaTitle')}
							</Body>
						</legend>
					</div>
						<Radio 
							label={t('student.ageCriteria1')}
							name="ageCriteria1"
							onChange={(e) => {
								handleStudentAge(e);
							}}
							checked={ageCriteria1}
							disabled={account.data.auth.prJobsPreferred || account.data.auth.scJobsPreferred}
						/>
						<Radio
							label={t('student.ageCriteria2')}
							name="ageCriteria2"
							onChange={(e) => {
								handleStudentAge(e);
							}}
							checked={ageCriteria2}
							disabled={account.data.auth.prJobsPreferred || account.data.auth.scJobsPreferred}
						/>
						<Radio
							label={t('student.ageCriteria3')}
							name="ageCriteria3"
							onChange={(e) => {
								handleStudentAge(e);
							}}
							checked={ageCriteria3}
						/>
				</fieldset>
				{(highSchool || ageCriteria1 || ageCriteria2) && (
					<div className={style.flexGap}>
						<div>
							<Alert variant="info">{t('student.alertText')}</Alert>
						</div>

						<div className={style.parentEmail}>
							<TextField
								type="text"
								name="parentName"
								label={t('student.parentName')}
								onChange={(e) => handleParentsName(e)}
								value={parentName}
								textFieldProps={{
									maxLength: 40
								}}
							/>
						</div>
						<div className={style.parentEmail}>
							<TextField
								type="text"
								name="parentEmail"
								label={t('student.parentEmail')}
								onChange={(e) => handleParentsEmail(e)}
								value={parentEmail}
								textFieldProps={{
									maxLength: 80
								}}
								error={emailError && emailError.email ? t('createAccount.' + emailError.email) : ''}
							/>
						</div>
					</div>
				)}
				{props.editProfile ? (
					<div style={{ width: '100%' }}>
						<Button
							size="medium"
							variant="primary"
							onClick={handleButton}
							UNSAFE_style={{ width: '100%' }}
							disabled={shouldDisabledNext()}>
							{t('editProfile.save')}
						</Button>
					</div>
				) : (
					buttonDisplay()
				)}
			</div>
		</FormGroup>
	);
};

export default StudentStatus;
