import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { BottomSheet, Modal, Body, Button } from '@walmart-web/livingdesign-components';
import './style.scss';

const WithDrawConfirmation = (props) => {
	const { messageType, onClick } = props;
	const [isOpenAck, setIsOpenAck] = useState(false);

	const { t } = useTranslation();
	const loading = useSelector((state) => state.global.loading);

	const isDesktop = useMediaQuery({ minWidth: 851 });
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

	const messages = {
		1: { desc: t('jobTracker.confirmation'), buttonText: t('general.remove') },
		2: { desc: t('jobTracker.acknowlege'), buttonText: t('feedback.close') }
	};

	const handleClose = (e) => {
		if (!loading) {
			props.setIsOpen(false);
			onClick('-1');
		}
	};
	const handleCloseAck = (e) => {
		props.setIsOpen(false);
		setIsOpenAck(false);
		onClick('-1');
	};

	const handleRemoveButtonClick = (e) => {
		props.setIsOpen(false);
		if (e.target.id === '1') {
			setIsOpenAck(true);
			onClick(e.target.id);
		} else {
			setIsOpenAck(false);
		}
	};

	const showConfirmationMessage = () => {
		return (
			<>
				<Body as="span" size="small" weight={400}>
					{messages[messageType].desc}
				</Body>
				<div className="withdraw_button_container">
					<Button
						id={messageType}
						size="medium"
						variant="primary"
						onClick={(e) => handleRemoveButtonClick(e)}>
						{messages[messageType].buttonText}
					</Button>
				</div>
			</>
		);
	};

	return (
		<>
			{(isDesktop || isTablet) && (
				<>
					<Modal isOpen={props.isOpen} onClose={handleClose} title={t('jobTracker.withdraw')}>
						{showConfirmationMessage()}
					</Modal>
					<Modal isOpen={isOpenAck} onClose={handleCloseAck} title={t('jobTracker.withdraw')}>
						{showConfirmationMessage()}
					</Modal>
				</>
			)}
			{isMobile && (
				<>
					<BottomSheet isOpen={props.isOpen} onClose={handleClose} title={t('jobTracker.title')}>
						{showConfirmationMessage()}
					</BottomSheet>
					<BottomSheet isOpen={isOpenAck} onClose={handleCloseAck} title={t('jobTracker.title')}>
						{showConfirmationMessage()}
					</BottomSheet>
				</>
			)}
		</>
	);
};

export default WithDrawConfirmation;
