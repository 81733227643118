import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {updateVjoNextStepInd} from 'redux/Account/slice';
import {getUpdatePreferences} from 'redux/updatePreference/request';

export const useUpdatePreference = () => {
  const dispatch = useDispatch();
  const global = useSelector((state) => state.global);
  const isUpdated = useSelector((state) => state.updatePreference.isUpdated);
  const navigate = useNavigate();
  const ALERT_ACTIONS = {
    YES: 1,
    NO: 0,
  };

  const updatePreference = useCallback(
    (payload) => {
      if (global?.applicantId) {
        dispatch(
          getUpdatePreferences({
            applicantId: global?.applicantId,
            removeStoreList: payload?.deletedStoreList, //optional
            removeJobList: payload?.deletedJobList, //optional
            userId: global?.emailId, //optional
          }),
        );
      }
    },
    [global],
  );

  const onAlertActionClick = useCallback((alertAction) => {
    dispatch(updateVjoNextStepInd({nextStepInd: 10}));
    switch (alertAction) {
      case ALERT_ACTIONS.YES:
        return navigate('../location-search');
      case ALERT_ACTIONS.NO:
        return navigate('../login');
    }
  }, []);

  return {
    isUpdated,
    updatePreference,
    ALERT_ACTIONS,
    onAlertActionClick,
  };
};
