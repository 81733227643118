import { t } from 'i18next';

export const getGeneralizedErrorMsg = (error) => {
	if (error.response?.data?.responseMessage && error.response.data.responseMessage !== '') {
		return error.response.data.responseMessage;
	} else if (error.response?.data?.errorMessage && error.response?.data?.errorMessage !== '') {
		return error.response.data.errorMessage;
	} else {
		return t('general.systemErrorText');
	}
};
