import {
	Body,
	Button,
	Divider,
	Caption,
	Checkbox,
	Radio,
	TextField,
	Heading,
	Alert,
	ButtonGroup
} from '@walmart-web/livingdesign-components';
import React, { useEffect, useState, useCallback } from 'react';
import style from './CreateAccount.module.scss';
import commonStyles from '../../../Style/commonStyle.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { errorOccured, executionErrorOccured, subHeader, updateLoading, updateTimeZone } from '../../../redux/slices/globalSlice';
import { useNavigate } from 'react-router-dom';
import {
	hasNumber,
	isEmpty,
	isEmptyObject,
	isLanguageSelected,
	validateConfirmEmail,
	validateConfirmPassword,
	validateCreateAccount,
	validateEmail,
	validateMobile,
	validatePassword
} from '../../../utils/validation';
import { createAccount } from '../../../redux/Account/request';
import { savePreference } from '../../../redux/Preference/request';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';
import { useDisplay } from '../../../utils/useDisplay';
import { useLocation } from 'react-router';
import { DISCLAIMER_VERSION_NBR, GTM } from '../../../config/const';
import { sendGtmEvent } from '../../../utils/GoogleTagManagerUtils';
import {
	deviceType,
	osName,
	osVersion,
	browserName,
	browserVersion,
	mobileVendor,
	mobileModel
} from 'react-device-detect';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Eye, EyeSlash } from '@livingdesign/icons';
import { AvailabilityHelper } from '../../../pages/Availability/AvailabilityHelper';
import { environment } from 'utils/environment/environment';
import AlertDialog from 'components/AlertDialog';

const CreateAccount = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const global = useSelector((state) => state.global);
	const { isPortrait } = useDisplay();
	const location = useLocation();
	const mobRoutePath =
		global.jobType === 'warehouse' ? 'availability/shiftPreference' : 'availability/availableHours';
	const availStore = useSelector((state) => state.availMain);
	const locStore = useSelector((state) => state.location.selectedPos);
	const jobStore = useSelector((state) => state.jobSearch);
	const {
		validateTiming,
		availReqTemplate,
		scheduleAvailTemplate,
		validateSaveRequest,
		validateSchedule
	} = AvailabilityHelper();

	const state = global.location.loaded
		? global.location.coordinates.state.slice(0, 2).toUpperCase()
		: null;

	useEffect(() => {
		if (location.pathname === '/create-account') {
			sendGtmEvent(GTM.EVENTS.LAND_ON_CREATE_ACCOUNT);
			dispatch(
				subHeader({
					title: 'subHeader.createAccount',
					routeTo: isPortrait ? mobRoutePath : '/availability',
					display: 'true'
				})
			);
		}
	}, []);

	const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);
	const [error, setError] = React.useState({});
	const [email, setEmail] = React.useState('');
	const [confirmEmail, setConfirmEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [confirmPassword, setConfirmPassword] = React.useState('');
	const [mobile, setMobile] = React.useState('');
	const [alternatePhoneNumber, setAlternatePhoneNumber] = React.useState('');
	const [checkBox, setCheckBox] = useState(true);
	const [language, setLanguage] = useState({
		label: 'English',
		checked: true
	});
	const [isOpen, setIsOpen] = useState(false);
	const [isSumbit, setIsSumbit] = useState(false);
	const [textFieldType, setTextFieldType] = React.useState({
		password: 'password',
		confirmPassword: 'password'
	});
	const createAccountDot = 'createAccount.';

	useEffect(() => {
		if (
			isEmpty(email) ||
			isEmpty(confirmEmail) ||
			isEmpty(password) ||
			isEmpty(confirmPassword) ||
			!hasNumber(
				mobile && mobile.length > 2
					? parsePhoneNumber(mobile, global.countryCode).nationalNumber
					: mobile
			) ||
			isEmptyObject(language)
		) {
			setIsSubmitDisabled(true);
		} else {
			setIsSubmitDisabled(false);
		}
		// eslint-disable-next-line
	}, [email, password, confirmPassword, confirmEmail, mobile, checkBox, language.label]);

	useEffect(() => {
		if(email && error && error.email && isSumbit){
			setIsOpen(true);
			setIsSumbit(false);
		}
	}, [error]);

	const onLanguageChange = (event, languageSelected) => {
		setLanguage({ ...language, label: languageSelected });
		isLanguageSelected(languageSelected, error, setError);
	};

	const onChange = (event, eventName) => {
		switch (eventName) {
			case 'email':
				setEmail(event.target.value);
				validateEmail(event.target.value, error, setError, confirmEmail);
				break;
			case 'confirm_email':
				setConfirmEmail(event.target.value);
				validateConfirmEmail(event.target.value, email, error, setError);
				break;
			case 'password':
				setPassword(event.target.value);
				validatePassword(event.target.value, error, setError, confirmPassword);
				break;
			case 'confirm_password':
				setConfirmPassword(event.target.value);
				validateConfirmPassword(event.target.value, password, error, setError);
				break;
			case 'language':
				setLanguage({ ...language, label: event.target.value });
				isLanguageSelected(event.target.value, error, setError);
				break;
			case 'mobile_number':
				dispatch(validateMobile(event.target.value, error, setError, setMobile));
				break;
			case 'alternatePhoneNumber':
				dispatch(validateMobile(event.target.value, error, setError, setAlternatePhoneNumber, true));
				break;
			case 'agree':
				setCheckBox(!checkBox);
				break;
			default:
				break;
		}
	};

	const getPreferenceRequest = (applicntId) => {
		return {
			applicantId: applicntId,
			locationPreferances: {
				storeNbr: locStore.map((loc) => Number(loc.storeNumber))
			},
			applicantPreference: {
				preferredJobs: jobStore.currentSelectedJobs.map((i) => i.jobSelectionId),
				metricId: 0,
				ageUpdateNeeded: jobStore.is18
			},
			availability: getAvailabilityData(applicntId)
		};
	};

	const getAvailabilityData = (applicntId) => {
		if (props.source !== 'saveforlater') {
			const availData = { ...availStore.availability };
			availData.jobType = global.jobType;
			if (!isEmptyObject(availData) && !isEmptyObject(availData.scheduleAvailability)) {
				validateSchedule(availData);
			}
			return availData;
		} else {
			let availRequest = availReqTemplate(applicntId);
			availRequest.jobType = global.jobType;
			const empType = { ...availStore.employmentType };
			const shift = [...availStore.shiftPreference.shift];
			const schdlAvail = { ...availStore.scheduleAvailability };
			if (empType && (empType.fullTime || empType.partTime || empType.temporary)) {
				availRequest.employmentType = availStore.employmentType;
			} else {
				availRequest.employmentType = null;
			}
			if (shift && shift.length > 0) {
				availRequest.shiftPreference = availStore.shiftPreference;
			} else {
				availRequest.shiftPreference = null;
			}
			if (schdlAvail && schdlAvail.anyHours !== '') {
				if (schdlAvail.anyHours) {
					availRequest.scheduleAvailability = scheduleAvailTemplate();
				} else {
					availRequest.scheduleAvailability = getScheduleTimings(schdlAvail);
				}
			} else {
				availRequest.scheduleAvailability = null;
			}
			if (
				(availRequest.employmentType === null &&
					availRequest.shiftPreference !== null &&
					availRequest.scheduleAvailability !== null) ||
				(availRequest.employmentType === null && availRequest.shiftPreference !== null)
			) {
				availRequest = null;
			}
			if (
				!isEmptyObject(availRequest) &&
				availRequest.employmentType === null &&
				availRequest.shiftPreference === null &&
				availRequest.scheduleAvailability === null
			) {
				availRequest = null;
			}
			if (!isEmptyObject(availRequest)) {
				availRequest = validateSaveRequest(availRequest);
				const schedule = availRequest.scheduleAvailability;
				const shiftPref = availRequest.shiftPreference;
				if (
					(isEmptyObject(shiftPref) && !isEmptyObject(schedule)) ||
					(!isEmptyObject(schedule) && global.jobType === 'warehouse' && schedule.anyHours !== '')
				) {
					availRequest.scheduleAvailability = null;
				}
			}
			return availRequest;
		}
	};

	const getScheduleTimings = (schdlAvail) => {
		let schdlReq = scheduleAvailTemplate();
		schdlReq.anyHours = false;
		if (validateTiming(schdlAvail.sunStartTime, schdlAvail.sunEndTime)) {
			schdlReq.sunStartTime = schdlAvail.sunStartTime;
			schdlReq.sunEndTime = schdlAvail.sunEndTime;
		}
		if (validateTiming(schdlAvail.monStartTime, schdlAvail.monEndTime)) {
			schdlReq.monStartTime = schdlAvail.monStartTime;
			schdlReq.monEndTime = schdlAvail.monEndTime;
		}
		if (validateTiming(schdlAvail.tueStartTime, schdlAvail.tueEndTime)) {
			schdlReq.tueStartTime = schdlAvail.tueStartTime;
			schdlReq.tueEndTime = schdlAvail.tueEndTime;
		}
		if (validateTiming(schdlAvail.wedStartTime, schdlAvail.wedEndTime)) {
			schdlReq.wedStartTime = schdlAvail.wedStartTime;
			schdlReq.wedEndTime = schdlAvail.wedEndTime;
		}
		if (validateTiming(schdlAvail.thuStartTime, schdlAvail.thuEndTime)) {
			schdlReq.thuStartTime = schdlAvail.thuStartTime;
			schdlReq.thuEndTime = schdlAvail.thuEndTime;
		}
		if (validateTiming(schdlAvail.friStartTime, schdlAvail.friEndTime)) {
			schdlReq.friStartTime = schdlAvail.friStartTime;
			schdlReq.friEndTime = schdlAvail.friEndTime;
		}
		if (validateTiming(schdlAvail.satStartTime, schdlAvail.satEndTime)) {
			schdlReq.satStartTime = schdlAvail.satStartTime;
			schdlReq.satEndTime = schdlAvail.satEndTime;
		}
		if (
			!validateTiming(schdlAvail.sunStartTime, schdlAvail.sunEndTime) &&
			!validateTiming(schdlAvail.monStartTime, schdlAvail.monEndTime) &&
			!validateTiming(schdlAvail.tueStartTime, schdlAvail.tueEndTime) &&
			!validateTiming(schdlAvail.wedStartTime, schdlAvail.wedEndTime) &&
			!validateTiming(schdlAvail.thuStartTime, schdlAvail.thuEndTime) &&
			!validateTiming(schdlAvail.friStartTime, schdlAvail.friEndTime) &&
			!validateTiming(schdlAvail.satStartTime, schdlAvail.satEndTime)
		) {
			schdlReq = null;
		}
		return schdlReq;
	};

	const onSubmit = useCallback(
		async (event) => {
			setIsSumbit(true)
			event.preventDefault();
			if (props.source === 'saveforlater') {
				sendGtmEvent(GTM.EVENTS.SAVE_FOR_LATER);
			}
			let tz = global.timeZone;
			if(!tz) {
				tz=Intl.DateTimeFormat().resolvedOptions().timeZone;
				dispatch(updateTimeZone(tz));
			}
			
			let request = {
				emailId: email,
				confirmEmail: confirmEmail,
				password: password,
				confirmPassword: confirmPassword,
				mobileNumber: mobile,
				alternatePhoneNumber: alternatePhoneNumber,
				language: language.label,
				optInForText: checkBox,
				uniqueApplicationId: 'walMarT-Store-HirinG',
				fileLanguageCode: language.label === 'English' ? '101' : '102',
				timeZone: tz,
				disclaimerVersion: DISCLAIMER_VERSION_NBR,
				countryCode: global.location.loaded
					? global.location.coordinates.country.slice(0, 2)
					: null,
				latitude: global.location.loaded ? global.location.coordinates.lat : null,
				longitude: global.location.loaded ? global.location.coordinates.long : null,
				state: state,
				city: global.location.loaded ? global.location.coordinates.city : null,
				deviceType: deviceType,
				osName: osName,
				osVersion: osVersion,
				browserName: browserName,
				browserVersion: browserVersion,
				mobileVendor: mobileVendor,
				mobileModel: mobileModel
			};
			const errors = dispatch(validateCreateAccount(request, error, setError, setMobile));
			if (isEmptyObject(errors)) {
				//make Api call for the create the account
				const { language: languageCode, ...req } = request;
				request = {
					...request,
					mobileNumber: parsePhoneNumber(mobile, global.countryCode).nationalNumber,
					alternatePhoneNumber: alternatePhoneNumber ? parsePhoneNumber(alternatePhoneNumber, global.countryCode).nationalNumber : alternatePhoneNumber
				};
				dispatch(updateLoading(true));
				dispatch(createAccount(request))
					.unwrap()
					.then(
						(result) =>
							new Promise((resolve, reject) => {
								if ((result.applicantId || result.email) && result.responseCode === '0') {
									sendGtmEvent(GTM.EVENTS.CREATE_ACCOUNT_SUCCESS);
									cookie.save('userId', result.applicantId);
									cookie.save('emailId', email);
									cookie.save('autoFillCutoverModalAlreadyShown','Y');
									sendGtmEvent(GTM.EVENTS.JOB_SUBMIT_BUTTON);
									dispatch(savePreference(getPreferenceRequest(result.applicantId))).then((res) => {
										resolve(dispatch(updateLoading(false)));
										const data = res.payload;
										if (data && data.responseCode === 201) {
											navigate('../login');
										} else if (data && data.responseCode !== 201) {
											const errorObj = {
												open: false,
												title: 'general.error',
												message: 'general.systemErrorText',
												url: '/HiringCenter/error',
												canBeTranslated : true
											};
											dispatch(errorOccured(errorObj));										}
									}).catch((err)=> {
										dispatch(executionErrorOccured());
									});
								} else if (result?.responseCode === '101' || result?.responseCode === '104') {
									resolve(
										setError({
											...error,
											email:
												result?.responseCode === '101'
													? 'userNameAlreadyExists'
													: result?.responseCode === '104'
													? 'invalidEmailRecevied'
													: 'systemErrorText'
										})
									);
									dispatch(updateLoading(false));
								} else if (result?.responseCode === '103') {
									resolve(
										setError({
											...error,
											confirmPassword:
												result?.responseCode === '106' ? 'pwdNotMatch' : 'systemErrorText'
										})
									);
									dispatch(updateLoading(false));
								} else {
									reject(
										new Error(result.responseMessage, {
											cause: result
										})
									);
								}
							})
					)
					.catch((err) => {
						dispatch(executionErrorOccured());
					});
			} else {
				setError({ ...error, ...errors });
			}
		},
		[email, confirmEmail, password, confirmPassword, mobile, language.label, checkBox, error]
	);

	const onToggleInputType = (event, eventName) => {
		if (eventName && eventName === 'password') {
			const passwordType = textFieldType.password === 'password' ? 'text' : 'password';
			setTextFieldType({ ...textFieldType, password: passwordType });
		} else if (eventName && eventName === 'confirmPassword') {
			const confirmPasswordType =
				textFieldType.confirmPassword === 'password' ? 'text' : 'password';
			setTextFieldType({ ...textFieldType, confirmPassword: confirmPasswordType });
		}
	};

	const resetEmail = () =>{
		setEmail("");
		setConfirmEmail("");
	}

	const handleLoginClick = () => {
		if (global.applicantId !== 0) {
			navigate('../login');
		} else if (environment.isPingfed === 'true') {
			window.location.replace(environment.pingFedLoginUrl);
		} else {
			navigate('../applicant-login');
		}
	}

	return (
		<React.Fragment>
			<form onSubmit={onSubmit}>
				<div
					className={`${style.parent} ${
						!isEmpty(props.source) && props.source === 'saveforlater' && style.zero_padding
					}`}>
					{(isEmpty(props.source) || props.source !== 'saveforlater') && (
						<div className={style.blueHead}></div>
					)}
					<div
						className={`${style.createAccount} ${
							!isEmpty(props.source) && props.source === 'saveforlater'
								? style.createAccount__popup
								: ''
						}`}>
						<div className={style.createAccount__heading}>
							{(isEmpty(props.source) || props.source !== 'saveforlater') && (
								<React.Fragment>
									<div className={style.createAccount__heading__title}>
										<Heading as="h1" size="medium" weight="700">
											{t('createAccount.createAccountTitle')}
										</Heading>
									</div>
									<Body as="p" size="medium" weight={400}> 
										{t('general.mandatoryText')}
									</Body>
									<Body as="p" size="medium" weight={400}>
										{t('createAccount.createAccountSubTitle1')}
									</Body>
								</React.Fragment>
							)}
							{!isEmpty(props.source) && props.source === 'saveforlater' && (
								<Body as="p" size="medium" weight={400}>
									{t('createAccount.createAccountSubTitle2')}
								</Body>
							)}
						</div>
						<div className={style.createAccount__body}>
							<div className={style.createAccount__body__content}>
								<div className={style.createAccount__body__content__columns}>
									<TextField
										label={t('createAccount.email')}
										name="email"
										type="email"
										value={email}
										onChange={(event) => onChange(event, 'email')}
									/>
									<TextField
										label={t('createAccount.confirmEmail')}
										name="confirm_email"
										type="email"
										value={confirmEmail}
										onChange={(event) => onChange(event, 'confirm_email')}
										error={
											confirmEmail && error && error.confirmEmail
												? t(createAccountDot + error.confirmEmail)
												: ''
										}
									/>
								</div>
								{email && confirmEmail && !error?.confirmEmail && !error?.email && <div className={style.createAccount__body__content__columns}>
									<Alert variant="info">{t('createAccount.emailAddCannotBeChanged')} </Alert>
								</div>}
								<div className={style.createAccount__body__content__columns}>
									<TextField
										label={t('createAccount.password')}
										trailing={
											textFieldType.password === 'password' ? (
												<Eye
													size="medium"
													style={{ padding: '16px' }}
													onClick={(event) => onToggleInputType(event, 'password')}
												/>
											) : (
												<EyeSlash
													size="medium"
													style={{ padding: '16px' }}
													onClick={(event) => onToggleInputType(event, 'password')}
												/>
											)
										}
										name="password"
										type={textFieldType.password}
										helperText={t('createAccount.passwordHint')}
										value={password}
										onChange={(event) => onChange(event, 'password')}
										aria-describedby={t('createAccount.passwordHint')}
										error={
											password && error && error.pdErrMsg
												? `${t(createAccountDot + error.pdErrMsg)}. ${t('createAccount.passwordHint')}`
												: ''
										}
									/>
									<TextField
										label={t('createAccount.confirmPassword')}
										trailing={
											textFieldType.confirmPassword === 'password' ? (
												<Eye
													size="medium"
													style={{ padding: '16px' }}
													onClick={(event) => onToggleInputType(event, 'confirmPassword')}
												/>
											) : (
												<EyeSlash
													size="medium"
													style={{ padding: '16px' }}
													onClick={(event) => onToggleInputType(event, 'confirmPassword')}
												/>
											)
										}
										name="confirm_password"
										type={textFieldType.confirmPassword}
										value={confirmPassword}
										onChange={(event) => onChange(event, 'confirm_password')}
										aria-describedby={t('createAccount.passwordHint')}
										error={
											confirmPassword && error && error.confirmPassword
												? `${t(createAccountDot + error.pdErrMsg)}. ${t('createAccount.passwordHint')}`
												: ''
										}
									/>
								</div>
								<div className={style.createAccount__body__content__columns__2}>
									<fieldset className={commonStyles.radioGroup}>
										<legend>
											<Body as="p" size="medium" weight={400}>
												{t('createAccount.languageText')}
											</Body>
										</legend>
										<div>
											<Radio
												label={t('createAccount.english')}
												name="language"
												type="radio"
												checked={language && language.label && language.label === 'English'}
												onChange={(event) => onLanguageChange(event, 'English')}
												value="English"
											/>
										</div>
										<div>
											<Radio
												label={t('createAccount.spanish')}
												name="language"
												type="radio"
												checked={language && language.label && language.label === 'Spanish'}
												onChange={(event) => onLanguageChange(event, 'Spanish')}
												value="Spanish"
											/>
										</div>
									</fieldset>
								</div>
								<div className={style.createAccount__body__content__columns__2}>
									<div className={style.createAccount__body__content__columns}>
										<TextField
											label={t('createAccount.mobile')}
											name="mobile_number"
											type="text"
											textFieldProps={{
												inputMode: 'numeric'
											}}
											value={mobile}
											onChange={(event) => onChange(event, 'mobile_number')}
											error={
												mobile && error && error.mobile ? t(createAccountDot + error.mobile) : ''
											}
										/>
										<TextField
											type="text"
											name="alternateContactNumber"
											label={t('aboutYouPersonalInfo.alternateContactNumber')}
											onChange={(e) => onChange(e, 'alternatePhoneNumber')}
											value={alternatePhoneNumber}
											error={
												alternatePhoneNumber && error && error.altContact
													? t('createAccount.' + error.altContact)
													: ''
											}
										/>
									</div>
									<div>
										<Checkbox
											data-testid="checkbox"
											label={t('createAccount.acceptMobileText')}
											value={checkBox}
											checked={checkBox ? 'T' : ''}
											name="agree"
											type="checkbox"
											onChange={(event) => onChange(event, 'agree')}
											error={
												error && error.acceptMobileText
													? t(createAccountDot + error.acceptMobileText)
													: ''
											}
										/>
									</div>
									<Caption
										as="p"
										size="small"
										weight={400}
										UNSAFE_className={style.createAccount__body__content__legal_text}>
										{t('createAccount.legalCaption1')}
										<a target="_blank" href="https://corporate.walmart.com/privacy-security">
											{t('createAccount.legalCaptionLink')}
										</a>
										{t('createAccount.legalCaption2')}
									</Caption>
								</div>
							</div>
						</div>
						<Divider />
						<div className={style.createAccount__sticky_footer}>
							<Button
								size="medium"
								variant="primary"
								isFullWidth
								disabled={!isEmptyObject(error) || isSubmitDisabled}
								type="submit"
								onClick={onSubmit}>
								{!isEmpty(props.source) && props.source === 'saveforlater'
									? t('createAccount.createAccountButtonTitle1')
									: t('createAccount.createAccountButtonTitle2')}
							</Button>
						</div>
					</div>
				</div>
			</form>
			<div>
				<AlertDialog
					actions={
						<ButtonGroup UNSAFE_className={style.emailButtons}>
							<Button variant="tertiary" onClick={() => { setIsOpen(false); resetEmail() }} >{t('createAccount.accountWithAnotherEmail')}</Button>
							<Button variant="primary" onClick={handleLoginClick} >{t('createAccount.backToLogin')}</Button>
						</ButtonGroup>
					}
					modalSize="large"
					isOpen={isOpen}
					onClose={() => { setIsOpen(false); resetEmail() }}
					title={t('createAccount.accountExists')}
				>
					<Body>{t(createAccountDot + error.email)}</Body>
				</AlertDialog>
			</div>
		</React.Fragment>
	);
};

export default CreateAccount;
