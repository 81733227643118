import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import {environment} from '../../utils/environment/environment';
import {updateLoading, apiRequestErrorOccured} from '../slices/globalSlice';

const getScheduleCompleted = createAsyncThunk(
    'vjo/scheduleCompleted',
    async ({scheduleAction, eventId}, { dispatch }) => {
        dispatch(updateLoading(true));
        try{
            const baseURL = environment.hcServiceBaseGetUrl + `/scheduleCompleted?scheduleAction=${scheduleAction}&EventID=${eventId}`;
            const response = await axiosInstance.get(baseURL);
            dispatch(updateLoading(false));
            return response.data;
        } catch (error) {
            dispatch(apiRequestErrorOccured(error));
		}
    }
);

const initiateCandidateDrugScreen = createAsyncThunk(
    'vjo/initiateCandidateDrugScreen',
    async (data, { dispatch }) => {
        dispatch(updateLoading(true));
        try{
            const baseURL = environment.hcServiceBasePostUrl + `/initiateCandidateDrugScreen`;
            const response = await axiosInstance.post(baseURL, data);
            dispatch(updateLoading(false));
            return response.data;
        } catch(error) {
            dispatch(apiRequestErrorOccured(error));
        } 
    }
);

const isDrugScreenRequired = createAsyncThunk(
    'vjo/getVJOisDrugScreenRequired',
    async(data, { dispatch }) => {
        dispatch(updateLoading(true));
        try{
            const baseURL = environment.hcServiceBasePostUrl + `/getVJOisDrugScreenRequired`;
            const response = await axiosInstance.post(baseURL, data);
            dispatch(updateLoading(false));
            return response.data;
        } catch(error) {
           dispatch(apiRequestErrorOccured(error));
        }
    }
);

export {getScheduleCompleted, initiateCandidateDrugScreen, isDrugScreenRequired};
