import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './PasswordMain.module.scss';
import { Button, Heading, Body, Card, TextField } from '@walmart-web/livingdesign-components';
import { isEmpty, isEmptyObject, validateEmail } from '../../../utils/validation';
import { Email } from '@livingdesign/icons';
import { useMediaQuery } from 'react-responsive';
import { updateResetPassword } from '../../../redux/Account/slice';
import { onSubmitRecoverPassword } from './PasswordHelper';
import { environment } from '../../../utils/environment/environment';
import { updateTimeZone } from 'redux/slices/globalSlice';
import { DISCLAIMER_VERSION_NBR } from '../../../config/const';

const RecoverPassword = (props) => {
	const [error, setError] = React.useState({});
	const [email, setEmail] = React.useState('');
	const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);
	const global = useSelector((state) => state.global);
	const isDesktop = useMediaQuery({ minWidth: 851 });
	const isMobile = useMediaQuery({ maxWidth: 425 });
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(
			updateResetPassword({
				nextScreen: 'SentVerifyEmail',
				nextPath: '/sendVerifyEmail/1',
				status: 'PENDING'
			})
		);
	}, []);

	useEffect(() => {
		if (isEmpty(email)) {
			setIsSubmitDisabled(true);
		} else {
			setIsSubmitDisabled(false);
		}
	}, [email]);

	const onChange = (event) => {
		setEmail(event.target.value);
		validateEmail(event.target.value, error, setError);
	};

	const onSubmit = React.useCallback(
		async (event) => {
			event.preventDefault();

			let tz = global.timeZone;
			if(!tz) {
				tz=Intl.DateTimeFormat().resolvedOptions().timeZone;
				dispatch(updateTimeZone(tz));
			}

			const request = {
				emailId: email,
				uniqueApplicationId: 'walMarT-Store-HirinG',
				timeZone: tz,
				disclaimerVersion: DISCLAIMER_VERSION_NBR
			};
			dispatch(onSubmitRecoverPassword(request, error, setError, navigate));
		},
		[email, error]
	);

	const handleLoginClick = () => {
		if (environment.isPingfed === 'true') {
			window.location.replace(environment.pingFedLoginUrl);
		} else {
			navigate('../applicant-login');
		}
	};

	return (
		<React.Fragment>
			<Card UNSAFE_className={style.card}>
				<div className={style.header}>
					<Heading
						size={isDesktop ? 'medium' : 'small'}
						as="h1"
						color="#000000"
						UNSAFE_className={style.heading}>
						{t("recoverPasswordPage.forgotPasswordTitle")}
					</Heading>
				</div>
				<div className={`${style.body} ${isMobile && style.card_bottom_padd_16}`}>
					<Body
						size="medium"
						as="p"
						weight="400"
						color="#000000"
						UNSAFE_className={style.black_text}>
						{t("recoverPasswordPage.forgotPasswordSubTitle")}
					</Body>
					<div>
						{/* <Body size="small">{t("recoverPasswordPage.emailAddress")}</Body> */}
						<TextField
							leadingIcon={<Email />}
							name="email"
							type="email"
							label={t("recoverPasswordPage.emailAddress")}
							value={email}
							onChange={(event) => onChange(event)}
							error={error && error.email ? t('createAccount.' + error.email) : ''}
						/>
					</div>
				</div>
				<div className={style.footer}>
					<Button
						size="medium"
						variant="primary"
						isFullWidth
						disabled={!isEmptyObject(error) || isSubmitDisabled}
						type="button"
						onClick={onSubmit}>
						{t("recoverPasswordPage.resetPdTitle")}
					</Button>
					<Button
						size={isDesktop ? 'medium' : 'small'}
						variant="tertiary"
						isFullWidth
						type="submit"
						onClick={handleLoginClick}
						UNSAFE_className={style.tertiary}>
						{t("recoverPasswordPage.backToLogin")}
					</Button>
				</div>
			</Card>
		</React.Fragment>
	);
};

export default RecoverPassword;
