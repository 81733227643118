import React, { useState, useEffect } from 'react';
import style from './JobHistory.module.css';
import {
	Button,
	BottomSheet,
	TextField,
	Select,
	Modal,
	TextArea
} from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import { industryDetails, categoryDetails } from '../../../config/const';
import { useDispatch, useSelector } from 'react-redux';
import { updateJobHistory, updateEditJob, updateJob } from '../../../redux/JobHistory/slice';
import { saveEmplHistory } from '../../../redux/JobHistory/request';
import { executionErrorOccured, updateLoading } from '../../../redux/slices/globalSlice';
import { callHiringHelper } from '../../../redux/HiringHelper/request';
import { Calendar } from '@livingdesign/icons';
import moment from 'moment';
import { useDisplay } from 'utils/useDisplay';

const JobExpModal = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [company, setCompany] = useState('');
	const [jobTitle, setjobTitle] = useState('');
	const [industry, setIndustry] = useState('');
	const [industryList, setIndustryList] = useState([]);
	const global = useSelector((state) => state.global);
	const account = useSelector((state) => state.account);
	const countryCode = global.countryCode === 'PR' ? 'PR' : 'US';
	const { isPortrait } = useDisplay();
	const [categoryList, setCategorylist] = useState([]);
	const [subCategoryList, setSubCategoryList] = useState([]);
	const [category, setCategory] = useState('');
	const [subCategory, setSubcategory] = useState('');
	const [reason, setReason] = useState('');
	const jobHistory = useSelector((state) => state.jobHistory);
	const [startDateError, setStartDateError] = useState(false);
	const [endDateError, setEndDateError] = useState('');
	const [oldPosition, setOldPosition] = useState('');
	const [oldCompany, setOldCompany] = useState('');

	useEffect(() => {
		setIndustryList(global.language === 'ES' ? industryDetails[102] : industryDetails[101]);
		setCategorylist(categoryDetails);
	}, [global.language]);

	useEffect(() => {
		if (categoryList.length > 0) {
			let subCategory_list = [];
			categoryList.forEach((obj) => {
				if (obj.catg_code === category) {
					subCategory_list = obj.subCategoryList;
				}
			});
			setSubCategoryList(subCategory_list);
		}
		// eslint-disable-next-line
	}, [category, industry]);

	useEffect(() => {
		if (!props.editJobData) {
			setCompany('');
			setIndustry('');
			setjobTitle('');
			setCategory('');
			setSubcategory('');
			setReason('');
			setStartDate('');
			setEndDate('');
		}
		if (props.editJobData) {
			const index = jobHistory.jobIndex;
			if (jobHistory.job.length !== 0) {
				const job = jobHistory.job[index];
				setCompany(job.companyName);
				setjobTitle(job.position);
				setOldCompany(job.companyName);
				setOldPosition(job.position);
				setIndustry(job.industry);
				setCategory(job.category);
				setStartDate(job.startDate);
				setEndDate(job.endDate);
				let subCategory_list = [];
				categoryList.forEach((obj) => {
					if (obj.catg_code === job.category) {
						subCategory_list = obj.subCategoryList;
					}
				});
				setSubCategoryList(subCategory_list);
				setSubcategory(job.subCategory);
				setReason(job.reason);
			}
		}
		// eslint-disable-next-line
	}, [props.editJobData]);

	useEffect(() => {
		if (!props.editJobData) {
			setCompany('');
			setIndustry('');
			setjobTitle('');
			setCategory('');
			setSubcategory('');
			setReason('');
			setStartDate('');
			setEndDate('');
		}
		// eslint-disable-next-line
	}, [props.isOpenExp]);

	const handleCompany = (e) => {
		let value = e.target.value;
		if (value === '') {
			setCompany('');
		} else {
			value = removeInvalidChars(value);
			setCompany(value.slice(0,35));
		}
	};
	const handlejobTitle = (e) => {
		let value = e.target.value;
		if (value === '') {
			setjobTitle('');
		} else {
			value = removeInvalidChars(value);
			setjobTitle(value.slice(0,45));
		}
	};
	const handleIndustry = (e) => {
		const value = e.target.value;
		setIndustry(value);
	};

	const resetValues = () => {
		setCompany('');
		setIndustry('');
		setjobTitle('');
		setCategory('');
		setSubcategory('');
		setReason('');
		setStartDate('');
		setEndDate('');
	};

	const addExp = () => {
		const start = startDate;
		let end = '';
		if (endDate) {
			end = endDate;
		}

		const jobDetail = {
			companyName: company,
			position: jobTitle,
			industry: industry,
			category: category,
			subCategory: subCategory,
			startDate: start,
			endDate: end,
			reason: reason
		};

		if (props.editJobData) {
			const index = jobHistory.jobIndex + 1;
			const editRequest = {
				allEmployers: [
					{
						company_name: company,
						industry_catg_code: industry,
						left_reason_text: reason,
						allPositions: [
							{
								job_postn_seq_nbr: '1',
								job_position_desc: jobTitle,
								old_job_position_desc: oldPosition,
								pos_start_date: startDate,
								pos_end_date: endDate ? endDate : '',
								maj_work_grp_code: category,
								min_work_grp_code: subCategory
							}
						],
						employer_seq_nbr: index,
						last_pay_rate_amt: '',
						old_company_name: oldCompany,
						address_line_1: '',
						address_line_2: null,
						city_name: '',
						state_prov_code: '',
						country_code: countryCode,
						postal_code: '',
						phone_nbr: '',
						supvr_first_name: null,
						supvr_last_name: null,
						contact_allow_ind: 'Y',
						empl_start_date: null,
						empl_end_date: null,
						positionHistory: {
							job_postn_seq_nbr: '1',
							job_position_desc: jobTitle,
							old_job_position_desc: oldPosition,
							pos_start_date: startDate,
							pos_end_date: endDate ? endDate : '',
							maj_work_grp_code: category,
							min_work_grp_code: subCategory
						}
					}
				],
				applicant_id: global.applicantId
			};
			dispatch(updateJob({ jobDetail: jobDetail }));
			dispatch(updateLoading(true));
			dispatch(saveEmplHistory(editRequest))
				.then((res) => {
					const data = res.payload;
					if (data.responseCode === '200' || data.responseCode === '201') {
						if (account?.data?.auth?.stepNbr >= 850) {
							dispatch(callHiringHelper(global.applicantId));
						}
						props.handleEditJob(jobDetail);
						resetValues();
						dispatch(updateEditJob({ editJob: false }));
					}
					dispatch(updateLoading(false));
				})
				.catch((err) => {
					dispatch(executionErrorOccured());
				});
		} else {
			const request = {
				allEmployers: [
					{
						company_name: company,
						industry_catg_code: industry,
						left_reason_text: reason,
						allPositions: [
							{
								job_position_desc: jobTitle,
								pos_start_date: startDate,
								pos_end_date: endDate ? endDate : '',
								maj_work_grp_code: category,
								min_work_grp_code: subCategory,
								old_job_position_desc: jobTitle
							}
						],
						country_code: countryCode,
						contact_allow_ind: 'Y',
						postal_code: '',
						phone_nbr: '',
						address_line_1: '',
						city_name: '',
						state_prov_code: '',
						old_company_name: company,
						positionHistory: {
							job_position_desc: jobTitle,
							pos_start_date: startDate,
							pos_end_date: endDate ? endDate : '',
							maj_work_grp_code: category,
							min_work_grp_code: subCategory,
							old_job_position_desc: jobTitle
						}
					}
				],
				applicant_id: global.applicantId
			};
			dispatch(updateJobHistory({ jobDetail: jobDetail }));
			dispatch(updateLoading(true));
			dispatch(saveEmplHistory(request))
				.then((res) => {
					const data = res.payload;
					if (data.responseCode === '200' || data.responseCode === '201') {
						if (account?.data?.auth?.stepNbr >= 850) {
							dispatch(callHiringHelper(global.applicantId));
						}
						props.handleAddjob();
						resetValues();
					}
					dispatch(updateLoading(false));
				})
				.catch((err) => {
					dispatch(executionErrorOccured());
				});
		}
	};

	const handleReason = (e) => {
		let value = e.target.value;
		if (value === '') {
			setReason('');
		} else {
			value = removeInvalidChars(value);
			setReason(value.slice(0,255));
		}
	};

	const handleCategory = (e) => {
		const value = e.target.value;
		setCategory(value);
	};

	const handleSubCategory = (e) => {
		const value = e.target.value;
		setSubcategory(value);
	};

	const handleCloseExp = () => {
		setCompany('');
		setIndustry('');
		setjobTitle('');
		setCategory('');
		setSubcategory('');
		setReason('');
		setStartDate('');
		setEndDate('');
		props.handleExpModal(false);
	};

	const handleStartDate = (e) => {
		let date = e.target.value.replace(/[^0-9\/']/gi, '');

		if (date.toString().length === 2 && e.nativeEvent.inputType !== 'deleteContentBackward') {
			date = date.concat('/');
		}
		setStartDate(date);
		setStartDateError(
			date &&
				date !== '' &&
				(!moment(date, 'MM/YYYY', true).isValid() ||
					moment(date, 'MM/YYYY', true) > moment() ||
					(moment(endDate, 'MM/YYYY', true).isValid() &&
						moment(date, 'MM/YYYY', true) > moment(endDate, 'MM/YYYY', true)) ||
					moment(date, 'MM/YYYY', true).year() <= 1899 ||
					moment(date, 'MM/YYYY', true).year() > 2099)
		);
	};
	const handleEndDate = (e) => {
		let date = e.target.value.replace(/[^0-9\/']/gi, '');

		if (date.toString().length === 2 && e.nativeEvent.inputType !== 'deleteContentBackward') {
			date = date.concat('/');
		}
		setEndDate(date);
		setEndDateError(
			date &&
				date !== '' &&
				(!moment(date, 'MM/YYYY', true).isValid() ||
					(moment(startDate, 'MM/YYYY', true).isValid() &&
						moment(date, 'MM/YYYY', true) < moment(startDate, 'MM/YYYY', true)) ||
					moment(date, 'MM/YYYY', true).year() <= 1899 ||
					moment(date, 'MM/YYYY', true).year() > 2099)
		);
	};

	const startDateInValidErrMsg = () => {
		if (
			!moment(startDate, 'MM/YYYY', true).isValid() ||
			moment(startDate, 'MM/YYYY', true).year() <= 1899 ||
			moment(startDate, 'MM/YYYY', true).year() > 2099
		) {
			return t('jobHistory.dateFormat');
		} else if (
			moment(endDate, 'MM/YYYY', true).isValid() &&
			moment(startDate, 'MM/YYYY', true) > moment(endDate, 'MM/YYYY', true)
		) {
			return t('jobHistory.startLessThanEnd');
		} else {
			return t('jobHistory.startDateCannotBeFuture');
		}
	};
	const endDateInValidErrMsg =
		!moment(endDate, 'MM/YYYY', true).isValid() ||
		moment(endDate, 'MM/YYYY', true).year() <= 1899 ||
		moment(endDate, 'MM/YYYY', true).year() > 2099
			? t('jobHistory.dateFormat')
			: t('jobHistory.endCannotBeBeforeStart');


	const removeInvalidChars = (value) => {
		let check = value;
		if (countryCode === 'PR') {
			check = check.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F\s\,\.\-\'\@\d\_\!\$\%\^\*']/gi, '');
		} else {
			check = check.replace(/[^\w\s\.\-\'\,\@\d\_\!\$\%\^\*']/gi, '');
		}
		check = check.replace(/^\s+/g, '');
		return check;
	};

	const addJobContent = (
		<div className={style.contentContainer}>
			<div className={style.columnsGroup}>
				<TextField
					type="text"
					name="company"
					label={t('jobHistory.companyName')}
					onChange={(e) => {
						handleCompany(e);
					}}
					textFieldProps={{
						maxLength: 35,
						'data-testid': 'jobHistory_companyName'
					}}
					value={company}
				/>
				<TextField
					type="text"
					name="industryList"
					label={t('jobHistory.positionTitle')}
					onChange={(e) => {
						handlejobTitle(e);
					}}
					textFieldProps={{
						maxLength: 45,
						'data-testid': 'jobHistory_positionTitle'
					}}
					value={jobTitle}
				/>
			</div>
			<div className={style.columnsGroup}>
				<Select
					label={t('jobHistory.industry')}
					value={industry}
					selectProps={{
						'data-testid': 'jobHistory_industry'
					}}
					role="combobox"
					aria-haspopup="listbox"
					onChange={(e) => {
						handleIndustry(e);
					}}>
					<option value="" disabled aria-hidden={true}></option>
					{industryList.map((obj, index) => {
						return (
							<option
								id={'industryList' + index}
								key={index}
								value={obj.value}
								data-testid={'industryList_' + obj.value}
								aria-label={obj.viewValue}>
								{obj.viewValue}
							</option>
						);
					})}
				</Select>
				<Select
					label={t('jobHistory.category')}
					value={category}
					selectProps={{
						'data-testid': 'jobHistory_category'
					}}
					role="combobox"
					aria-haspopup="listbox"
					onChange={(e) => {
						handleCategory(e);
					}}>
					<option value="" disabled aria-hidden={true}></option>
					{categoryList.map((obj, index) => {
						return (
							<option id={'category' + index} key={index} value={obj.catg_code} data-testid={'category_' + obj.catg_code}>
								{global.language === 'ES' ? obj.catg_desc[102] : obj.catg_desc[101]}
							</option>
						);
					})}
				</Select>
			</div>
			<div className={style.columnsGroup}>
				<Select
					label={t('jobHistory.subCategory')}
					value={subCategory}
					selectProps={{
						'data-testid': 'jobHistory_subCategory'
					}}
					role="combobox"
					aria-haspopup="listbox"
					onChange={(e) => {
						handleSubCategory(e);
					}}>
					<option value="" disabled aria-hidden={true}></option>
					{subCategoryList.map((obj, index) => {
						return (
							<option id={'sub-category' + index} key={index} value={obj.subcatg_code} data-testid={'sub-category_' + obj.subcatg_code}>
								{global.language === 'ES' ? obj.subcatg_desc[102] : obj.subcatg_desc[101]}
							</option>
						);
					})}
				</Select>

				<div style={{ width: '100%', display: 'flex', gap: '16px' }}>
					<TextField
						type="text"
						name="startDate"
						label={t('jobHistory.startDate')}
						placeholder={'MM/YYYY'}
						onChange={(e) => {
							handleStartDate(e);
						}}
						textFieldProps={{
							maxLength: 7,
							'data-testid': 'jobHistory_startDate'
						}}
						leadingIcon={<Calendar size="medium" />}
						value={startDate}
						error={startDateError ? startDateInValidErrMsg() : ''}
					/>

					<TextField
						type="text"
						name="endDate"
						label={t('jobHistory.endDate')}
						placeholder={'MM/YYYY'}
						onChange={(e) => {
							handleEndDate(e);
						}}
						textFieldProps={{
							maxLength: 7,
							'data-testid': 'jobHistory_endDate'
						}}
						leadingIcon={<Calendar size="medium" />}
						value={endDate}
						error={endDateError ? endDateInValidErrMsg : ''}
					/>
				</div>
			</div>
			<div style={{ width: '100%' }}>
				<TextArea
					label={t('jobHistory.reason')}
					value={reason}
					onChange={(e) => handleReason(e)}
					size="small"
					textAreaProps={{
						maxLength: 255,
						'data-testid': 'jobHistory_reason'
					}}
				/>
			</div>
		</div>
	);
	const postActions = () => {
		return (
			<Button
				data-testid='jobHistory_addExp'
				size="medium"
				variant="primary"
				UNSAFE_style={{ width: '100%' }}
				onClick={addExp}
				disabled={
					company === '' ||
					jobTitle === '' ||
					industry === '' ||
					category === '' ||
					subCategory === '' ||
					startDateError ||
					endDateError ||
					startDate === '' ||
					(endDate && moment(endDate, 'MM/YYYY', true) < moment(startDate, 'MM/YYYY', true))
				}>
				{props.editJobData ? t('jobHistory.save') : t('jobHistory.add')}
			</Button>
		);
	};
	return (
		<div>
			{!isPortrait && (
				<Modal
					size="large"
					isOpen={props.isOpenExp}
					onClose={handleCloseExp}
					actions={postActions()}
					closeButtonProps={{
						'data-testid': 'jobHistory_closeButton'
					}}
					title={props.editJobData ? t('jobHistory.editJob') : t('jobHistory.addJob')}>
					{addJobContent}
				</Modal>
			)}
			{isPortrait && (
				<BottomSheet
					isOpen={props.isOpenExp}
					onClose={handleCloseExp}
					title={props.editJobData ? t('jobHistory.editJob') : t('jobHistory.addJob')}
					closeButtonProps={{
						'data-testid': 'jobHistory_closeButton'
					}}
					actions={postActions()}>
					{addJobContent}
				</BottomSheet>
			)}
		</div>
	);
};

export default JobExpModal;
