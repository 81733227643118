import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { subHeader } from '../../redux/slices/globalSlice';
import JobTracker from './components/JobTracker';
import AppliedJobsList from './components/AppliedJobsList';
import './style.scss';

const AppliedJobTracker = () => {
  const dispatch = useDispatch();

  const { storeNbr } = useParams();

  useEffect(() => {
    dispatch(
      subHeader({
        title: '',
        routeTo: 'post-apply-dashboard',
        display: 'true'
      })
    );
  }, [dispatch]);

  return (
    <div className="job_tracker_container">
      <JobTracker storeNbr={storeNbr} />
      <AppliedJobsList storeNbr={storeNbr} />
    </div>
  );
};

export default AppliedJobTracker;
