import {createSlice} from '@reduxjs/toolkit';
import {availabilityObject} from './helper';
import {getAvailability} from './request';

const initialState = {data: {}, loading: false, error: {}};

const AvailabilitySlice = createSlice({
  name: 'getAvailability',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAvailability.fulfilled, (state, action) => {
        state.data = availabilityObject(action.payload);
      })
      .addCase(getAvailability.rejected, (state) => {
        state.error = {message: 'Something went wrong'};
      });
  },
});

export const {availability} = AvailabilitySlice.actions;

export default AvailabilitySlice.reducer;
