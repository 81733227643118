import {
  Card,
  Heading,
  List,
  ListItem,
  StyledText,
} from '@walmart-web/livingdesign-components';
import React, {memo, useMemo} from 'react';
import JobOfferItem from './JobOfferItem';
import RegistrationConsentMedicalForm from '@livingdesign/pictograms/dist/svg/Health_Forms_&_Office/RegistrationConsentMedicalForm.svg';
import {useTranslation} from 'react-i18next';
import './offerSteps.scss';
import { useDrugScreenInfo } from 'pages/VirtualJobOffer/hooks/useDrugScreenInfo';


const JobOfferSteps = () => {
  const {t} = useTranslation();
  const { drugScreenRequiredResponse } = useDrugScreenInfo();
  const offerSteps = drugScreenRequiredResponse?.drugScreenRequired ? t('vjoDashboard.pages', {returnObjects: true}) : t('vjoDashboard.pagesWithoutDS', {returnObjects: true});

  return useMemo(()=>{
    return (<Card size='small' UNSAFE_className='dash_body_steps'>
      <>
        <StyledText
          leading={
            <img
              alt={t('vjoDashboard.jobOffer')}
              src={RegistrationConsentMedicalForm}
              className='job_offer_icon'
            />
          }
          size='small'
          children={
            <Heading
              size='small'
              weight={700}
              children={t('vjoDashboard.jobOffer')}
            />
          }
          UNSAFE_className='offer_header'
        />
        <List UNSAFE_className='offer_list_steps'>
          {Array.isArray(offerSteps) && offerSteps.map((offerStep, index) => {
            return (
              <ListItem key={index}>
                <JobOfferItem offerStep={offerStep} />
              </ListItem>
            );
          })}
        </List>
      </>
    </Card>
  )},[t, offerSteps, drugScreenRequiredResponse?.drugScreenRequired])
};

export default memo(JobOfferSteps);
