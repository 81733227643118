import { Location, Search } from "@livingdesign/icons";
import {
    Body,
    Divider,
    List,
    TextField,
} from "@walmart-web/livingdesign-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import style from "./LocationSearchTextField.module.css";

const LocationSearchTextField = (props) => {
    const global = useSelector((state) => state.global);
    const location = useSelector((state) => state.location);
    const { t } = useTranslation();

    return (
        <div className={location.clickLocText && global.path === 'location-search' ? style.inputBorder : null}>
            <div className={style.inputText}>
                <label htmlFor="searchInput" className={style.visuallyHidden}>{t("location.enterZipOrCity")}</label>
                <TextField
                    id="searchInput"
                    leadingIcon={<Search size="small" style={{top: global.path === 'location-search' ? '-8px' : 'auto', position: 'relative'}}/>}
                    error={props.errorMessage}
                    placeholder={t("location.enterZipOrCity")}
                    onChange={props.setSearchText}
                    type="search"
                    value={props.searchText}
                    aria-label={t("location.enterZipOrCity")}
                    role="combobox"
                    aria-controls="suggestionPopUp"
                    aria-expanded = {props.suggestions.length !== 0 ? true : false}
                    aria-autocomplete="list"
                    aria-activedescendant={props.currentlyFocusedSuggestionId}
                />
            </div>

            {props.showCurrentLoc &&
                (props.suggestions.length || global.location.loaded) && (
                    <div className={style.listContainer}>
                        {global.location.loaded &&
                            global.location.coordinates.lat !== "" &&
                            global.location.coordinates.long !== "" && (
                                <div
                                    className={style.currentLocation}
                                    onClick={props.handleCurrentLocClick}
                                >
                                    <Location size="medium" />
                                    <Body
                                        as="p"
                                        size="large"
                                        weight={400}
                                        UNSAFE_style={{ color: "#004F9A" }}
                                    >
                                        {t("location.currentLocation")}
                                    </Body>
                                </div>
                            )}
                        {props.suggestions.length !== 0 && (
                            <React.Fragment>
                                {global.location.loaded && (
                                    <Divider
                                        UNSAFE_style={{
                                            marginBottom: "16px",
                                            paddingTop: "16px",
                                        }}
                                    />
                                )}
                                <List id="suggestionPopUp" role="listbox" aria-label={t('suggestions.suggestions')}>
                                    {props.suggestions.map((value, key) => {
                                        return (
                                            <div
                                                key={key}
                                                id={key}
                                                role="option"
                                                aria-selected = {key ===props.currentlyFocusedSuggestionId}
                                                className={style.listItems}
                                                onClick={() => {
                                                    props.handleSuggestionClick(
                                                        value
                                                    );
                                                }}
                                                onKeyDown={(e) => {
                                                    if(e.key === "Enter"){
                                                        props.handleSuggestionClick(
                                                            value
                                                        );
                                                    }
                                                    
                                                }}
                                                tabIndex={0}
                                                onFocus={() => {props.handleSuggestionFocus(key)}}
                                            >
                                                <Location size="medium" />
                                                <div>
                                                    <Body
                                                        as="span"
                                                        size="large"
                                                        weight={400}
                                                    >
                                                        {value.toLowerCase().startsWith(props.searchText.toLowerCase()) ?
                                                         value.substring(
                                                            0,
                                                            props.searchText
                                                                .length
                                                        ):''}
                                                    </Body>
                                                    <Body
                                                        as="span"
                                                        size="large"
                                                        weight={700}
                                                    >
                                                        {value.replace(
                                                            new RegExp(
                                                                props.searchText,
                                                                "ig"
                                                            ),
                                                            ""
                                                        )}
                                                    </Body>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </List>
                            </React.Fragment>
                        )}
                    </div>
                )}
        </div>
    );
};

export default LocationSearchTextField;
