import React, { useEffect, useState } from 'react';
import { Alert, Divider, Heading, Body } from '@walmart-web/livingdesign-components';
import LocationCard from '../LocationCard/LocationCard';
import { useTranslation } from 'react-i18next';
import './LocationStoreList.css';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as NoResultsFound } from '../../../../assets/svg/noResultsFound.svg';

const LocationStoreList = ({ initList, showStoreList, filteredStores }) => {
	const [noColocated, setNoColocated] = useState([]);
	const [colocated, setColocated] = useState([]);
	const { t } = useTranslation();
	const isDesktop = useMediaQuery({ minWidth: 851 });

	useEffect(() => {
		const newNoColocated = [];
		const newColocated = [];
		filteredStores.forEach((store) => {
			if (store.colocated) {
				newColocated.push(store);
			} else {
				newNoColocated.push(store);
			}
		});
		setNoColocated(newNoColocated);
		setColocated(newColocated);
	}, [filteredStores]);

	return (
		<div
			className={`LocationStoreList__StoreList${
				initList ? ' initList' : showStoreList ? '' : ' displayHide'
			}`}>
			{isDesktop && (
				<Heading as="h6" size="small" weight={700} UNSAFE_className={'LocationStoreList__Heading'}>
					{t('location.locationResults')} ({noColocated.length})
				</Heading>
			)}
			{isDesktop && filteredStores.length === 0 && (
				<div className={'LocationStoreList__NoResult'}>
					<NoResultsFound />
					<Heading
						as={'h1'}
						size={'medium'}
						UNSAFE_className={'LocationStoreList__NoResultHeading'}>
						{t('location.noResultFound')}
					</Heading>
					<Body UNSAFE_className={'LocationStoreList__NoResultBody'}>
						{t('location.zipOrCityError')}
					</Body>
				</div>
			)}
			{isDesktop && filteredStores && filteredStores.some((store) => store.sc) && (
				<div className={'LocationCard_Alert'}>
					<Alert variant={'info'}>{t('location.scAlert')}</Alert>
				</div>
			)}
			{noColocated.map((store) => (
				<div
					className={'LocationStoreList__StoreList__Card'}
					key={store.storeNumber}
					id={`LocationStoreList_${store.storeNumber}`}>
					<LocationCard store={store} />
					<Divider UNSAFE_className={'LocationStoreList__StoreCard_Divider'} />
				</div>
			))}
			{colocated.length > 0 ? (
				<>
					<Heading
						as="h6"
						size="small"
						weight={700}
						UNSAFE_className={'LocationStoreList__Heading'}>
						{t('location.coLocatedTitle')} ({colocated.length})
					</Heading>
					{colocated.map((store) => (
						<div
							className={'LocationStoreList__StoreList__Card'}
							key={store.storeNumber}
							id={`LocationStoreList_${store.storeNumber}`}>
							<LocationCard store={store} />
							<Divider UNSAFE_className={'LocationStoreList__StoreCard_Divider'} />
						</div>
					))}
				</>
			) : null}
		</div>
	);
};

export default LocationStoreList;
