import { Card, Heading, StyledText } from '@walmart-web/livingdesign-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import JobOfferDetailsItem from './JobOfferDetailsItem';
import { useVirtualJobOffer } from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import { isEmpty } from 'lodash';
import money from '../../../../assets/images/Money.svg';
import Calendar from '../../../../assets/images/Calendar.svg';
import Services from '../../../../assets/images/Services.svg';
import Travel from '../../../../assets/images/Travel.svg';
import hiringManager from '../../../../assets/images/hiringManager.svg';
import location from '../../../../assets/images/location.svg';
import { Spark } from '@livingdesign/icons';
import './offerDetails.scss';

const JobOfferDetails = () => {
	const { t } = useTranslation();
	const { isSupplyChain, jobOffer } = useVirtualJobOffer();

	const formatDate = (date) => {
		var arr1 = date.split('-');
		return arr1[1] + '/' + arr1[2] + '/' + arr1[0];
	};

	const JobInfoData = useMemo(() => {
		if (isEmpty(jobOffer)) {
			return [];
		}
		let employmentType = jobOffer?.employmentType;
		if (!isSupplyChain) {
			employmentType =
				jobOffer?.employmentType === 'F'
					? t('virtualJobOffer.fullTime')
					: t('virtualJobOffer.partTime');
		}
		const storeAddress = `${jobOffer?.storeNbr} ${jobOffer?.streetAddr.replace(
			/(^\w|\s\w)(\S*)/g,
			(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
		)}, ${jobOffer?.cityAddr.replace(
			/(^\w|\s\w)(\S*)/g,
			(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
		)}, ${jobOffer?.stateAddr} ${jobOffer?.zipCode}`;
		const jobInfo = [
			{
				title: t('virtualJobOffer.tentativeStartDate'),
				icon: Calendar,
				info: formatDate(jobOffer?.schldDate)
			},
			{
				title: t('virtualJobOffer.hourlyRate'),
				icon: money,
				info: `$${jobOffer?.rateOfPay}${t('general./hr')}`
			},
			{
				title: t('virtualJobOffer.jobTitle'),
				icon: Travel,
				info: jobOffer?.jobName
			},
			{
				title: t('virtualJobOffer.employmentType'),
				icon: Services,
				info: employmentType
			},
			{
				title: t('virtualJobOffer.hiringManager'),
				icon: hiringManager,
				info: jobOffer?.hiringMangerFullName
			},
			{
				title: t('virtualJobOffer.storeAddress'),
				icon: location,
				info: storeAddress
			}
		];
		return jobInfo?.filter((offer) => {
			return offer?.info;
		});
	}, [jobOffer, t, isSupplyChain]);

	return (
		<Card UNSAFE_className="dash_body_details">
			<StyledText
				leading={<Spark />}
				size="large"
				children={
					<Heading size="small" weight={700} children={t('vjoDashboard.jobOfferDetails')} />
				}
				UNSAFE_className="offer_header"
			/>
			{JobInfoData.map((jobInfo, index) => {
				return (
					<JobOfferDetailsItem
						title={jobInfo?.title}
						icon={jobInfo?.icon}
						value={jobInfo?.info}
						key={index}
					/>
				);
			})}
		</Card>
	);
};

export default JobOfferDetails;
