import {Button, StyledText} from '@walmart-web/livingdesign-components';
import React, {useMemo} from 'react';
import {ReactComponent as ScheduleCalendar} from '@livingdesign/pictograms/dist/svg/Health_Forms_&_Office/ScheduleCalendar.svg';
import {ReactComponent as BGCheckIcon} from '../../../../assets/images/bg_check.svg';
import {ReactComponent as DSCheckIcon} from '../../../../assets/images/ds_check.svg';
import {ReactComponent as RequestPersonalInfo} from '../../../../assets/images/RequestPersonalInfo.svg';
import {ReactComponent as RegistrationComplete} from '../../../../assets/images/ RegistrationComplete.svg';
import {ReactComponent as User} from '../../../../assets/images/User.svg';
import {ReactComponent as GreenCheck} from '../../../../assets/images/green_check.svg';
import {useDashboard} from './hooks/useDashboard';
import {useVirtualJobOffer} from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import {useTranslation} from 'react-i18next';

const JobOfferItem = ({offerStep}) => {
  const {isActive, isNotStarted, isFinished, onStepClick} =
    useDashboard(offerStep);
  const {t} = useTranslation();
  const {isSupplyChain} = useVirtualJobOffer();
  const Image = ({style}) => {
    switch (offerStep?.step) {
      case 1:
        return <User className={style} />;
      case 2:
        return <ScheduleCalendar className={style} />;
      case 3:
        return <RequestPersonalInfo className={style} />;
      case 4:
        return <RegistrationComplete className={style} />;
      case 5:
        return <DSCheckIcon className={style} />;
      case 6:
        return <BGCheckIcon className={style} />;
      default:
        return;
    }
  };

  return useMemo(() => {
    return (
      <div
        className={isActive ? 'offer_item_active' : 'offer_item'}
        onClick={() => onStepClick(offerStep?.step)}>
        <div className='offer_item_left'>
          <StyledText
            UNSAFE_className={`offer_item_title ${
              isNotStarted ? 'disable_view' : 'active_view'
            }`}
            leading={isFinished ? (<GreenCheck />):(
              <Image
                style={`offer_icon ${
                  isNotStarted ? 'disable_view' : 'active_view'
                }`}
              />)
            }
            size='large'
            children={isSupplyChain ? offerStep?.scvalue : offerStep?.value}
          />
        </div>
        {isActive && (
          <div className='offer_item_right'>
            <Button variant='primary' children={t('vjoDashboard.start')} />
          </div>
        )}
      </div>
    );
  }, [isActive, isNotStarted, isFinished, offerStep, t, isSupplyChain, onStepClick ]);
};

export default JobOfferItem;
