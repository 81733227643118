import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequestErrorOccured } from 'redux/slices/globalSlice';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';


const getPersonalInfo = createAsyncThunk('aboutYou/getPersonalInfo', async (request, { dispatch }) => {
	try {
		const baseUrl =
			environment.hcServiceBaseGetUrl +
			'/getPersonalInfo?applicantId=' +
			request.applicantId +
			'&emailId=' +
			request.emailId;
		const response = await axiosInstance.get(baseUrl);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});

const aboutApplicantStep1 = createAsyncThunk('aboutYou/aboutApplicantStep1', async (request, { dispatch }) => {
	if (Number(request.birthDate) <= 9) {
		request.birthDate = '0' + request.birthDate;
	}
	request.partialDob = ''+ request.birthMonth + request.birthDate;
	try {
		const baseUrl =
			environment.hcServiceBasePostUrl +
			'/aboutApplicant?step=' +
			1 +
			'&checkRehire=' +
			request.checkRehire +
			'&countryCode=' +
			request.countryCode;
		const response = await axiosInstance.post(baseUrl, request);
		return response.data;
	} catch (error) {
		if(error?.response?.data?.responseCode === '444') {
			return error;
		}
		dispatch(apiRequestErrorOccured(error));
	}
});

const aboutApplicantStep2 = createAsyncThunk('aboutYou/aboutApplicantStep2', async (request, { dispatch }) => {
	try {
		let checkRehire = false;
		let countryCode = 'US';
		const baseUrl =
			environment.hcServiceBasePostUrl +
			'/aboutApplicant?checkRehire=' +
			checkRehire +
			'&countryCode=' +
			countryCode +
			'&step=' +
			2;
		const response = await axiosInstance.post(baseUrl, request);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});

const aboutApplicantStep3 = createAsyncThunk('aboutYou/aboutApplicantStep3', async (request, { dispatch }) => {
	try {
		const baseUrl =
			environment.hcServiceBasePostUrl +
			'/aboutApplicant?step=' +
			3 +
			'&checkRehire=false' +
			'&countryCode=' +
			request.countryCode;
		const response = await axiosInstance.post(baseUrl, request);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});
const aboutApplicantStep0 = createAsyncThunk('aboutYou/aboutApplicantStep0', async (request, { dispatch }) => {
	try {
		const baseUrl =
			environment.hcServiceBasePostUrl +
			'/aboutApplicant?step=' +
			0 +
			'&checkRehire=' +
			request.checkRehire +
			'&countryCode=' +
			request.countryCode;
		const response = await axiosInstance.post(baseUrl, request);
		return response.data;
	} catch (error) {
		if(error?.response?.data?.responseCode === '444') {
			return error;
		}
		dispatch(apiRequestErrorOccured(error));
	}
});

const checkRehire = createAsyncThunk('aboutYou/checkRehire', async (request, { dispatch }) => {
	if (request.partialDob.length < 4) {
		request.partialDob = '0' + request.partialDob;
	}
	try {
		const baseUrl = environment.hcServiceBasePostUrl + '/checkRehire';
		const response = await axiosInstance.post(baseUrl, request);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});

export {
	getPersonalInfo,
	aboutApplicantStep1,
	aboutApplicantStep2,
	aboutApplicantStep3,
	aboutApplicantStep0,
	checkRehire
};
