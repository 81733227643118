import {BottomSheet, Modal} from '@walmart-web/livingdesign-components';
import React, {memo, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDisplay} from 'utils/useDisplay';
/**
 * Launches an alert dialog for Desktop & BottomSheet for Mobile, with the specified title and message.
 * @param isOpen - Show/hide view
 * @param title - Title for the alert
 * @param children - Content view
 * @param actions - actions view - Eg: Ok, Cancel
 * @param onClose -on close click
 * @param modalSize -"small"(Default) | "medium" | "large"
 * @returns Modal for Desktop and BottomSheet for Mobile
 */
const AlertDialog = ({
  isOpen,
  title,
  children,
  actions,
  onClose,
  modalSize,
}) => {
  const {isMobile} = useDisplay();

  const onCloseClick = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);

  const DesktopView = useMemo(() => {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onCloseClick}
        size={modalSize ? modalSize : 'small'}
        title={title}
        actions={actions}>
        {children}
      </Modal>
    );
  }, [isOpen, actions, children, title, onCloseClick, modalSize]);

  const MobileView = useMemo(() => {
    return (
      <BottomSheet
        isOpen={isOpen}
        onClose={onCloseClick}
        title={title}
        actions={actions}>
        {children}
      </BottomSheet>
    );
  }, [isOpen, actions, children, title, onCloseClick]);

  return isMobile ? MobileView : DesktopView;
};
/**
 * PropTypes for the AlertDialog component
 */
AlertDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  modalSize: PropTypes.object,
};
export default memo(AlertDialog);
