import './App.css';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useLocation,
	useNavigate
} from 'react-router-dom';
import Disclaimer from './pages/Disclaimer/Disclaimer';
import Landing from './pages/Landing/Landing';
import Location from './pages/Location/Location';
import JobSelection from './pages/JobSelection/JobSelection';
import JobDetails from './pages/JobSelection/JobDetails/JobDetails';
import Welcome from './pages/Welcome/Welcome';
import AboutYou from './pages/Application/AboutYou/AboutYou';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { Spinner, Modal, Body } from '@walmart-web/livingdesign-components';
import { useSelector, useDispatch } from 'react-redux';
import { onLogOut, openGlobalDialog } from './redux/slices/globalSlice';
import CreateAccount from './pages/Application/ProfileCreation/CreateAccount';
import ApplicantLogin from './pages/ApplicantLogin/ApplicantLogin';
import AvailMainComp from './pages/Availability/AvailMainComp/AvailMainComp';
import PostApplyDashboard from './pages/PostApplyDashboard';
import AppliedJobTracker from './pages/AppliedJobTracker';
import EEOComp from './pages/Application/EEOComp/EEOComp';
import Experience from './pages/Application/Experience/Experience';
import Assessment from './pages/Assessment/Assessment';
import MinimumQualifications from 'pages/MinimumQualifications/MinimumQualifications';
import VirtualJobOfferContainer from './pages/VirtualJobOffer';
import SelfSchedule from 'pages/SelfSchedule/SelfSchedule';
import ErrorScreen from 'pages/Error/ErrorScreen';
import RenewApplication from './pages/RenewApplication';
import RecoverPassword from './pages/Application/RecoverPassword/RecoverPassword';
import PasswordMain from 'pages/Application/RecoverPassword/PasswordMain';
import ResetPassword from 'pages/Application/RecoverPassword/ResetPassword';
import SentVerifyEmail from 'pages/Application/RecoverPassword/SentVerifyEmail';
import PasswordSuccess from 'pages/Application/RecoverPassword/PasswordSuccess';
import JobHistory from 'pages/Application/JobHistory/JobHistory';
import UpdatePreference from 'pages/UpdatePreference';
import EditProfile from './pages/Application/EditProfile/EditProfile';
import { useIdleTimer } from 'react-idle-timer';
import StepContainer from 'pages/VirtualJobOffer/components/steps/stepsContainer/StepContainer';
import ScheduleCompleted from 'pages/VirtualJobOffer/components/scheduleCompleted/ScheduleCompleted';
import SplashScreen from 'pages/SplashScreen';
import { useEffect, useLayoutEffect } from 'react';
import TaxQuestionnaire from 'pages/Application/TaxQuestionnaire/TaxQuestionnaire';
import { useTranslation } from 'react-i18next';
import EditProfileJobList from 'pages/Application/EditProfile/EditProfileJobList/EditProfileJobList';
import EditProfileLicenseList from 'pages/Application/EditProfile/EditProfileLicenseList/EditProfileLicenseList';
import {Availability} from './config/const'

const ScrollToTop = ({ children }) => {
	const location = useLocation();
	const { t } = useTranslation();
	const pathSplit = (location.pathname).split("/");
	const global = useSelector((state) => state.global);
	useLayoutEffect(() => {
		window.scrollTo(0, 0);
		let actualRoute = pathSplit[((pathSplit[1] === Availability && pathSplit[2] !== undefined) ? 2 : 1)]
		document.title = t(`pageTitles.${actualRoute}`);
	}, [location.pathname, global.language]);
	return children;
};

const SubApp = ({ children }) => {
	const navigate = useNavigate();
	const global = useSelector((state) => state.global);
	useEffect(() => {
		if (global && global.error.message !== '') {
			navigate('../error');
		}
	}, [global.error, navigate]);
	return children;
};

function App() {
	const { t } = useTranslation();
	const handleOnIdle = () => {
		dispatch(onLogOut());
	};
	useIdleTimer({
		onIdle: handleOnIdle,
		timeout: 60000 * 60, //1 Hour
		crossTab: true
	});
	const global = useSelector((state) => state.global);
	const dispatch = useDispatch();
	

	const splitRespAndGetByLang = (msg) => {
		return global.language === 'ES' ? msg.split('#@#')[2] : msg.split('#@#')[1];
	};
	const dialogMsg = global.dialog.message.includes('#@#')
		? splitRespAndGetByLang(global.dialog.message)
		: global.dialog.message;

	const handleCloseGlobalDialog = () => {
		dispatch(openGlobalDialog(false));
		if (global.dialog.message.includes('#@#')) {
			dispatch(onLogOut());
		}
	};
	return (
		<Router basename="/HiringCenter">
			<SubApp>
				<ScrollToTop>
					<div className="main">
						<div className={global.loading ? 'activateSpinner' : 'deactivateSpinner'}>
							<div className="spinnerContent">
								<Spinner color="white" size="large" />
							</div>
						</div>
						<Header />
						<div className={global.subHeader.display ? 'mainContentWithSubHeader' : 'mainContent'}>
							<Routes>
								<Route exact path="/disclaimer" element={<Disclaimer />} />
								<Route exact path="/homeScreen" element={<Landing />} />
								<Route exact path="/applicant-login" element={<ApplicantLogin />} />
								<Route exact path="/location-search" element={<Location />} />
								<Route exact path="/job-search" element={<JobSelection />} />
								<Route exact path="/job-details" element={<JobDetails />} />
								<Route index path="/availability" element={<AvailMainComp />} />
								<Route index path="/availability/:screenName" element={<AvailMainComp />} />

								<Route exact path="/create-account" element={<CreateAccount />} />
								<Route
									exact
									path="/recoverPassword"
									element={
										<PasswordMain>
											<RecoverPassword />
										</PasswordMain>
									}
								/>
								<Route
									exact
									path="/sendVerifyEmail/:count"
									element={
										<PasswordMain>
											<SentVerifyEmail />
										</PasswordMain>
									}
								/>
								<Route
									exact
									path="/verifyEmailLink/:encryptedId"
									element={
										<PasswordMain>
											<ResetPassword />
										</PasswordMain>
									}
								/>
								<Route
									exact
									path="/passwordSuccess"
									element={
										<PasswordMain>
											<PasswordSuccess />
										</PasswordMain>
									}
								/>

								<Route exact path="/login" element={<Welcome />} />
								<Route exact path="/about-you" element={<AboutYou />} />
								<Route exact path="/eeo" element={<EEOComp />} />
								<Route exact path="/experience" element={<Experience />} />
								<Route exact path="/job-history" element={<JobHistory />} />
								<Route exact path="/wotc-questionnaire" element={<TaxQuestionnaire />} />
								<Route exact path="/minimum-qualifications" element={<MinimumQualifications />} />
								<Route index path="/assessments" element={<Assessment />} />
								<Route exact path="/post-apply-dashboard" element={<PostApplyDashboard />} />
								<Route
									exact
									path="/applied-job-tracker/:storeNbr"
									element={<AppliedJobTracker />}
								/>
								<Route exact path="/edit-profile" element={<EditProfile />} />
								<Route exact path="/edit-profile-job-history" element={<EditProfileJobList />} />
								<Route
									exact
									path="/edit-profile-certificates-licenses"
									element={<EditProfileLicenseList />}
								/>

								<Route index path="/renew" element={<RenewApplication />} />

								<Route exact path="/virtual-job-offer" element={<VirtualJobOfferContainer />} />
								<Route exact path="/vjo-steps" element={<StepContainer />} />
								<Route exact path="/scheduleCompleted" element={<ScheduleCompleted />} />
								<Route exact path="/update-preference" element={<UpdatePreference />} />

								<Route index path="/SS/:encId" element={<SelfSchedule />} />
								<Route exact path="/error" element={<ErrorScreen />} />

								<Route exact path="/maintenance" element={<SplashScreen />} />
								<Route index path="*" element={<Navigate to="/disclaimer" replace />} />
							</Routes>
						</div>
						<Footer />
						<Modal
							isOpen={global.dialog.open}
							onClose={handleCloseGlobalDialog}
							size="small"
							title={t(global.dialog.title)}>
							<Body as="div" size="medium">
								{dialogMsg}
							</Body>
						</Modal>
					</div>
				</ScrollToTop>
			</SubApp>
		</Router>
	);
}

export default App;
