import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequestErrorOccured, updateLoading } from 'redux/slices/globalSlice';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';


const saveGenderInfo = createAsyncThunk('eeo/saveGenderInfo', async (request, { dispatch }) => {
	try {
		const baseUrl =
			environment.hcServiceBasePostUrl +
			'/saveGenderInfo' ;
		const response = await axiosInstance.post(baseUrl,request);
		dispatch(updateLoading(false));
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});

export { saveGenderInfo };