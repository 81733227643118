import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getPersonalInfo, getAvailability, getRenewInfo, postRenewData } from './request';

const initialState = {
	isRenewed: { data: {}, error: {} },
	personalInfo: { data: {}, error: {} },
	availability: { data: {}, error: {} },
	renewInfo: { data: {}, error: {} },
	dataToRenew: {
		contactInfo: null,
		shiftAvailability: null,
		jobPreference: null
	},
	isRenewPanelClicked: {
		panel1: false,
		panel2: false,
		panel3: false,
		panel4: false
	},
	isContactPanelError: '',
	isRenewAvailPanelError: '',
	isJobPanelError: '',
	isWotcCompleted: false,
	isZipCodeError: '',
	isNoJobPreference:'',
	isNavigateLocation:'',
	isWotcDownError:false,
	triggerRenewAction: false
};
const hcRespTemp = [
	{
		applicantId: '',
		pretestCode: 20,
		revisionNbr: 3,
		questionNbr: 4,
		answerNbr: ''
	},
	{
		applicantId: '',
		pretestCode: 20,
		revisionNbr: 3,
		questionNbr: 44,
		answerNbr: ''
	},
	{
		applicantId: '',
		pretestCode: 20,
		revisionNbr: 3,
		questionNbr: 40,
		answerNbr: ''
	}
	
];

const renewSlice = createSlice({
	name: 'renew',
	initialState,
	reducers: {
		saveContactInfo: (state, action) => {
			const payload = action.payload.hasOwnProperty('hcTestResponse')
				? _.omit(action.payload, 'hcTestResponse')
				: action.payload;
			const contactState = _.cloneDeep(state?.personalInfo?.data);
			const appId = contactState?.applicantId;
			const hcTestRespData = [];
			if (payload?.age) {
				hcTestRespData.push({
					...hcRespTemp[0],
					applicantId: appId,
					answerNbr: payload.age
				});
			}
			if (payload?.isStudent) {
				hcTestRespData.push({
					...hcRespTemp[1],
					applicantId: appId,
					answerNbr: payload.isStudent
				});
			}
			if (payload?.textOpt) {
				hcTestRespData.push({
					...hcRespTemp[2],
					applicantId: appId,
					answerNbr: payload.textOpt
				});
			}
			payload['hcTestResponse'] = hcTestRespData;
			state.dataToRenew.contactInfo = payload;
		},
		saveJobPreferance: (state, action) => {
			const { deletedStoreList, deletedJobList } = action.payload;
			state.dataToRenew.jobPreference = { deletedStoreList, deletedJobList };
		},
		saveShiftAvailability: (state, action) => {
			const { shiftPreference, scheduleAvailability } = action.payload;
			state.dataToRenew.shiftAvailability = { shiftPreference, scheduleAvailability };
		},
		renewPanelClicked: (state, action) => {
			state.isRenewPanelClicked = action.payload;
			state.isRenewPanelClicked = { ...state.isRenewPanelClicked };
		},
		contactPanelError: (state, action) => {
			state.isContactPanelError = action.payload;
		},
		renewAvailPanelError: (state, action) => {
			state.isRenewAvailPanelError = action.payload;
		},
		jobPanelError: (state, action) => {
			state.isJobPanelError = action.payload;
		},
		setWotcCompleted: (state, action) => {
			state.isWotcCompleted = action.payload;
		},
		zipCodeError: (state, action) => {
			state.isZipCodeError = action.payload;
		},
		noJobPreference: (state, action) => {
			state.isNoJobPreference = action.payload;
		},
		navigateLocation: (state, action) => {
			state.isNavigateLocation = action.payload;
		},
		updateTriggerRenewAction : (state, action) =>{
			state.triggerRenewAction = action.payload;
		},
		setWotcDownError : (state, action) =>{
			state.isWotcDownError = action.payload;
		}
	},
	extraReducers(builder) {
		builder
			.addCase(getPersonalInfo.fulfilled, (state, action) => {
				state.personalInfo.data = action.payload;
			})
			.addCase(getPersonalInfo.rejected, (state, action) => {
				state.personalInfo.error = { message: 'Something went wrong' };
			})
			.addCase(getAvailability.fulfilled, (state, action) => {
				state.availability.data = action.payload;
			})
			.addCase(getAvailability.rejected, (state, action) => {
				state.availability.error = { message: 'Something went wrong' };
			})
			.addCase(getRenewInfo.fulfilled, (state, action) => {
				state.renewInfo.data = action.payload;
			})
			.addCase(getRenewInfo.rejected, (state, action) => {
				state.renewInfo.error = { message: 'Something went wrong' };
			})
			.addCase(postRenewData.fulfilled, (state, action) => {
				state.isRenewed.data = action.payload;
			})
			.addCase(postRenewData.rejected, (state, action) => {
				state.isRenewed.error = { message: 'Something went wrong' };
			});
		//  .addCase(getAvailability.fulfilled, (state, action) => {});
	}
});
export const {
	saveContactInfo,
	saveJobPreferance,
	saveShiftAvailability,
	renewPanelClicked,
	contactPanelError,
	renewAvailPanelError,
	jobPanelError,
	zipCodeError,
	noJobPreference,
	navigateLocation,
	updateTriggerRenewAction,
	setWotcCompleted,
	setWotcDownError
} = renewSlice.actions;

export default renewSlice.reducer;
