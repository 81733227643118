import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequestErrorOccured } from 'redux/slices/globalSlice';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';

const getEmplHistory = createAsyncThunk('jobHistoy/getEmplHistory', async (request, { dispatch }) => {
	try {
		let applicantId = request.applicantId;
		const baseUrl = environment.hcServiceBaseGetUrl + '/getEmplHistory?applicantId=' + applicantId+`&timestamp=${new Date().getTime()}`;
		const response = await axiosInstance.get(baseUrl);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});

const getCertificateLicenses = createAsyncThunk(
	'jobHistoy/getCertificateLicenses',
	async (request, { dispatch }) => {
		try {
			let applicantId = request.applicantId;
			const baseUrl =
				environment.hcServiceBaseGetUrl + '/getCertificateLicenses?applicantId=' + applicantId+`&timestamp=${new Date().getTime()}`;
			const response = await axiosInstance.get(baseUrl);
			return response.data;
		} catch (error) {
			dispatch(apiRequestErrorOccured(error));
		}
	}
);

const saveEmplHistory = createAsyncThunk('jobHistoy/saveEmplHistory', async (request, { dispatch }) => {
	try {
		const baseUrl = environment.hcServiceBasePostUrl + '/saveEmplHistory';
		const response = await axiosInstance.post(baseUrl, request);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error))
	}
});

const saveCertificateLicenses = createAsyncThunk(
	'jobHistoy/saveCertificateLicenses',
	async (request, { dispatch }) => {
		try {
			const baseUrl = environment.hcServiceBasePostUrl + '/saveCertificateLicenses';
			const response = await axiosInstance.post(baseUrl, request);
			return response.data;
		} catch (error) {
			dispatch(apiRequestErrorOccured(error));
		}
	}
);

const deleteJobEntry = createAsyncThunk('jobHistoy/deleteJobEntry', async (request, { dispatch }) => {
	try {
		const baseUrl = environment.hcServiceBasePostUrl + '/deleteJobEntry';
		const response = await axiosInstance.post(baseUrl, request);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});

const deleteCertificateLicenses = createAsyncThunk(
	'jobHistoy/deleteCertificateLicenses',
	async (request, { dispatch }) => {
		try {
			const baseUrl = environment.hcServiceBasePostUrl + '/deleteCertificateLicenses';
			const response = await axiosInstance.post(baseUrl, request);
			return response.data;
		} catch (error) {
			dispatch(apiRequestErrorOccured(error));
		}
	}
);


export {
	getEmplHistory,
	getCertificateLicenses,
	saveEmplHistory,
	saveCertificateLicenses,
	deleteJobEntry,
	deleteCertificateLicenses,
};
