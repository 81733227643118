import React from 'react';
import AssessmentItem from './AssessmentItem';
import style from '../Assessment.module.css';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

const AssessmentDesktopScreen = () => {
	const assessment = useSelector((state) => state.assessment);
	return (
		<>
			<div className={style.assessment_screen_container}>
				<div className={style.top_layout} />
				<div className={style.assessment_content}>
					<img
						src={require('../../../assets/images/assessmentCard.png')}
						alt="assessment"
						className={style.assessment_image}
					/>
					<div className={style.assessment_details}>
						<div className={style.assessment_info}>{t('assessment.assessmentInfo')}</div>
						<div>
							{assessment.assessmentList.length > 0 &&
								assessment.assessmentList.map((assessment, index, list) => {
									if (assessment.assessmentNeed && assessment.assessmentCode !== '') {
										return (
											<div
												style={{ display: 'flex', flexDirection: 'column' }}
												key={assessment.assessmentCode}>
												<AssessmentItem
													assessmentCode={assessment.assessmentCode}
													statusVal={assessment.statusVal}
													assessmentExpdate={assessment.assessmentExpdate}
												/>
												{index !== list.length - 1 ? (
													<div
														style={{
															width: '100%',
															margin: 'auto',
															alignItems: 'center',
															borderBottom: '1px solid #e2e2e2'
														}}
													/>
												) : null}
											</div>
										);
									}
								})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AssessmentDesktopScreen;
