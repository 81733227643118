import React, { useEffect } from 'react';
import styles from './JobSearchFilter.module.css';
import {
	BottomSheet,
	BottomSheetCloseButton,
	Radio,
	Button,
	Checkbox,
	Tag,
	Body,
	Popover,
	Chip
} from '@walmart-web/livingdesign-components';
import { ChevronDown, Store, Truck, Close, Filter } from '@livingdesign/icons';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateFilters, updateSort } from '../../../redux/slices/jobSearchSlice';
import ShiftFilterNav from './ShiftFilterNav';

const sortOptions = [
	{ title: 'jobFilter.payH2L', id: 0 },
	{ title: 'jobFilter.availPosH2L', id: 1 },
	{ title: 'jobFilter.availPosL2H', id: 2 }
];
const filterOptions = [
	{ title: 'jobSelection.sortAndFilter', id: 1 },
	{ title: 'jobSelection.shift', id: 2 },
	{ title: 'jobSelection.facility', id: 3 }
];
const facilityOptions = [
	{ title: 'jobFilter.all', subText: '', id: 0 },
	{ title: 'jobFilter.stores', subText: '', id: 1 },
	{ title: 'jobFilter.warehouses', subText: 'jobFilter.warehouseIncludes', id: 2 }
];
const workgroupOptions = [
	{
		facilityType: 'stores',
		icon: <Store />,
		options: [
			{ title: 'Auto Care Center', id: 1001 },
			{ title: 'Cashier & Front End', id: 1002 },
			{ title: 'Digital Pickup & Delivery', id: 1014 },
			{ title: 'Food & Grocery', id: 1003 },
			{ title: 'Fuel Station', id: 1004 },
			{ title: 'General Merchandise', id: 1005 },
			{ title: 'Health & Wellness', id: 1006 },
			{ title: 'Stocking & Unloading', id: 1008 }
		]
	},

	{
		facilityType: 'warehouses',
		icon: <Truck />,
		options: [
			{ title: 'Administration & Clerical', id: 5007 },
			{ title: 'Diesel Truck Mechanic', id: 5004 },
			{ title: 'Distribution Warehouse Maintenance', id: 5005 },
			{ title: 'Freight Handler', id: 5001 },
			{ title: 'Power Equipment Operator', id: 5002 },
			{ title: 'Specialized', id: 5003 },
			{ title: 'Yard Truck & City Driver', id: 5006 }
		]
	}
];
const skillOptions = [
	{ title: 'entry', id: 1 },
	{ title: 'trainer', id: 4 },
	{ title: 'supervisor', id: 2 },
	{ title: 'specialized', id: 3 }
];
const interactionOptions = [
	{ title: 'low', description: '', id: 3 },
	{ title: 'medium', description: '', id: 2 },
	{ title: 'high', description: '', id: 1 }
];
const workingConditionsOptions = [
	{ title: 'outside', description: '', id: 1 },
	{ title: 'cooler', description: '', id: 2 }
];
const empTypeOptions = [
	{ title: 'fullTime', id: 'F' },
	{ title: 'partTime', id: 'P' },
	{ title: 'justTemporary', id: 'T' }
];
const shiftOptions = [
	{ title: 'wd1', id: 1 },
	{ title: 'wd2', id: 2 },
	{ title: 'wd3', id: 3 },
	{ title: 'we1', id: 4 },
	{ title: 'we2', id: 5 },
	{ title: 'we3', id: 6 },
	{ title: 'shift1', id: 7 },
	{ title: 'shift2', id: 8 },
	{ title: 'shift3', id: 9 },
	{ title: 'opening', id: 10 },
	{ title: 'morning', id: 11 },
	{ title: 'midShift', id: 12 },
	{ title: 'closing', id: 13 },
	{ title: 'overnight', id: 14 }
];

const JobSearchFilter = (props) => {
	const dispatch = useDispatch();
	const filterStore = useSelector((state) => state.jobSearch);
	const { t } = useTranslation();

	const [filtersOpen, setFiltersOpen] = React.useState(false);
	const [payFilter, setPayFilter] = React.useState(filterStore.sort);
	const [facilityFilter, setFacilityFilter] = React.useState(filterStore.filters.facility);
	const [drawerType, setDrawerType] = React.useState(filterOptions[0]);
	const [shiftList, setShiftList] = React.useState(filterStore.filters.shift);
	const [workgroupList, setWorkgroupList] = React.useState(filterStore.filters.workgroup);
	const [skillList, setSkillList] = React.useState(filterStore.filters.skill);
	const [empTypeList, setEmpTypeList] = React.useState(filterStore.filters.empType);
	const [interactionList, setinteractionList] = React.useState(filterStore.filters.interaction);
	const [workingConditionsList, setWorkingConditionsList] = React.useState(
		filterStore.filters.workingConditions
	);
	const [openPanel, setOpenPanel] = React.useState(0);
	const [update, setUpdate] = React.useState(false);
	//State for crumb
	const [crumb, setCrumb] = React.useState(false);
	const ref = React.useRef();
	const jobFilterKey = 'jobFilter.';
	const generalSelected = 'general.selected';

	useEffect(() => {
		props.updateData(sortedData());
	}, []);
	useEffect(() => {
		dispatch(updateSort(0));
		setPayFilter(0);
		setUpdate(!update);
		props.updateData(sortedData());
	}, [props.futureTab]);
	useEffect(() => {
		props.updateData(sortedData());
	}, [update]);
	useEffect(() => {
		submitFilter();
	}, [
		workgroupList,
		empTypeList,
		facilityFilter,
		shiftList,
		skillList,
		interactionList,
		workingConditionsList
	]);
	useEffect(() => {
		props.updateData(sortedData());
	}, [payFilter]);
	const setPanel = (selected) => {
		openPanel === selected ? setOpenPanel(0) : setOpenPanel(selected);
	};

	const changePay = (event) => {
		const intEventId = parseInt(event.target.value);
		if (intEventId !== 1 && intEventId !== 2) {
			dispatch(updateSort(0));
			setPayFilter(0);
		} else {
			dispatch(updateSort(intEventId));
			setPayFilter(intEventId);
		}
	};
	const changeFacility = (event) => {
		let filterObj;
		const intEventId = parseInt(event);
		if (intEventId !== 1 && intEventId !== 2) {
			filterObj = {
				facility: 0,
				empType: empTypeList,
				shift: shiftList,
				workgroup: workgroupList,
				skill: skillList,
				interaction: interactionList,
				workingConditions: workingConditionsList
			};
			setFacilityFilter(0);
		} else {
			filterObj = {
				facility: intEventId,
				empType: empTypeList,
				shift: shiftList,
				workgroup: workgroupList,
				skill: skillList,
				interaction: interactionList,
				workingConditions: workingConditionsList
			};
			setFacilityFilter(intEventId);
		}
		dispatch(updateFilters(filterObj));
	};
	const addSkill = (i) => {
		const tempArray = [...filterStore.filters.skill];
		!tempArray.includes(i) ? tempArray.push(i) : tempArray.splice(tempArray.indexOf(i), 1);
		setSkillList(tempArray);
		const filterObj = {
			facility: facilityFilter,
			empType: empTypeList,
			shift: shiftList,
			workgroup: workgroupList,
			skill: tempArray,
			interaction: interactionList,
			workingConditions: workingConditionsList
		};
		dispatch(updateFilters(filterObj));
	};
	const addWorkingConditions = (i) => {
		const tempArray = [...filterStore.filters.workingConditions];
		!tempArray.includes(i) ? tempArray.push(i) : tempArray.splice(tempArray.indexOf(i), 1);
		setWorkingConditionsList(tempArray);
		const filterObj = {
			facility: facilityFilter,
			empType: empTypeList,
			shift: shiftList,
			workgroup: workgroupList,
			skill: skillList,
			interaction: interactionList,
			workingConditions: tempArray
		};
		dispatch(updateFilters(filterObj));
	};

	const addInteraction = (i) => {
		const tempArray = [...filterStore.filters.interaction];
		!tempArray.includes(i) ? tempArray.push(i) : tempArray.splice(tempArray.indexOf(i), 1);
		setinteractionList(tempArray);
		const filterObj = {
			facility: facilityFilter,
			empType: empTypeList,
			shift: shiftList,
			workgroup: workgroupList,
			skill: skillList,
			interaction: tempArray,
			workingConditions: workingConditionsList
		};
		dispatch(updateFilters(filterObj));
	};
	const addEmpType = (i) => {
		const tempArray = [...filterStore.filters.empType];
		!tempArray.includes(i) ? tempArray.push(i) : tempArray.splice(tempArray.indexOf(i), 1);
		setEmpTypeList(tempArray);
		const filterObj = {
			facility: facilityFilter,
			empType: tempArray,
			shift: shiftList,
			workgroup: workgroupList,
			skill: skillList,
			interaction: interactionList,
			workingConditions: workingConditionsList
		};
		dispatch(updateFilters(filterObj));
	};
	const addShift = (i) => {
		const tempArray = [...filterStore.filters.shift];
		!tempArray.includes(i) ? tempArray.push(i) : tempArray.splice(tempArray.indexOf(i), 1);
		setShiftList(tempArray);
		const filterObj = {
			facility: facilityFilter,
			empType: empTypeList,
			shift: tempArray,
			workgroup: workgroupList,
			skill: skillList,
			interaction: interactionList,
			workingConditions: workingConditionsList
		};
		dispatch(updateFilters(filterObj));
	};
	const addWorkgroup = async (i) => {
		const tempArray = [...filterStore.filters.workgroup];
		!tempArray.includes(i) ? tempArray.push(i) : tempArray.splice(tempArray.indexOf(i), 1);
		setWorkgroupList(tempArray);

		const filterObj = {
			facility: facilityFilter,
			empType: empTypeList,
			shift: shiftList,
			workgroup: tempArray,
			skill: skillList,
			interaction: interactionList,
			workingConditions: workingConditionsList
		};
		dispatch(updateFilters(filterObj));
	};
	const submitFilter = () => {
		props.updateData(sortedData());
	};
	const isFilterSelected = (i) => {
		switch (i) {
			case 1:
				return (
					filterStore.filters.workgroup.length +
					filterStore.filters.skill.length +
					filterStore.filters.interaction.length +
					filterStore.filters.workingConditions.length
				);
			case 2:
				return filterStore.filters.shift.length;
			case 3:
				return filterStore.filters.facility !== 0 ? 1 : 0;
			case 0:
				return filterStore.filters.facility !== 0 ||
					filterStore.filters.skill.length > 0 ||
					filterStore.filters.workingConditions.length > 0 ||
					filterStore.filters.interaction.length > 0 ||
					filterStore.filters.workgroup.length > 0 ||
					filterStore.filters.shift.length > 0 ||
					filterStore.filters.empType.length > 0
					? true
					: false;
			default:
				return false;
		}
	};
	const resetFilters = () => {
		setFacilityFilter(0);
		setShiftList([]);
		setEmpTypeList([]);
		setWorkgroupList([]);
		setSkillList([]);
		setinteractionList([]);
		setWorkingConditionsList([]);
		const filterObj = {
			facility: 0,
			empType: [],
			shift: [],
			workgroup: [],
			skill: [],
			interaction: [],
			workingConditions: []
		};
		dispatch(updateFilters(filterObj));
	};
	const getFacilityType = (i) => {
		return i.workgroupId < 5000 ? 'store' : 'warehouse';
	};
	const showWorkgroupCondition = (option) => {
		return (
			(option.facilityType === 'stores' && getFacilityList().store <= 0) ||
			(option.facilityType === 'stores' &&
				!filterStore.filters.workgroup.some((i) => i < 5000) &&
				filterStore.filters.facility === 2) ||
			(option.facilityType === 'warehouses' && getFacilityList().warehouse <= 0) ||
			(option.facilityType === 'warehouses' &&
				!filterStore.filters.workgroup.some((i) => i > 5000) &&
				filterStore.filters.facility === 1)
		);
	};

	const getFacilityList = () => {
		let warehouseJobs = 0;
		let storeJobs = 0;
		props.data.response.map((index) => {
			if (getFacilityType(index) === 'store') {
				storeJobs++;
			} else {
				warehouseJobs++;
			}
		});
		return { store: storeJobs, warehouse: warehouseJobs };
	};
	const applyWorkgroupFilter = (data) => {
		return filterStore.filters.workgroup.length <= 0
			? data
			: data.filter((f) => {
					return f.workgroupId && filterStore.filters.workgroup.some((s) => f.workgroupId === s)
						? true
						: false;
			  });
	};
	const applyEmpTypeFilter = (data) => {
		return filterStore.filters.empType.length <= 0
			? data
			: data.filter((f) => {
					return (f.employmentTypes &&
						filterStore.filters.empType.some((s) => f.employmentTypes.some((x) => x === s))) ||
						f.openPositions === 0
						? true
						: false;
			  });
	};
	const applyShiftFilter = (data) => {
		return filterStore.filters.shift.length <= 0
			? data
			: data.filter((f) => {
					return (f.shiftIds &&
						filterStore.filters.shift.some((s) => f.shiftIds.some((x) => x === s))) ||
						f.openPositions === 0
						? true
						: false;
			  });
	};
	const applyInteractionFilter = (data) => {
		return filterStore.filters.interaction.length <= 0
			? data
			: data.filter((f) => {
					return filterStore.filters.interaction.some(
						(s) => s === f.customerIntrId
					) /*|| getFacilityType(f) === "warehouse"*/
						? true
						: false;
			  });
	};
	const applyWorkingConditionsFilter = (data) => {
		return filterStore.filters.workingConditions.length <= 0
			? data
			: data.filter((f) => {
					return filterStore.filters.workingConditions.some(
						(s) => s === f.workingCondId
					) /*|| getFacilityType(f) === "warehouse"*/
						? true
						: false;
			  });
	};
	const applySkillFilter = (data) => {
		return filterStore.filters.skill.length <= 0
			? data
			: data.filter((f) => {
					return filterStore.filters.skill.some(
						(s) => s === f.skillLevelId
					) /*|| getFacilityType(f) === "warehouse"*/
						? true
						: false;
			  });
	};
	const applyFacilityFilter = (data) => {
		return filterStore.filters.facility === 0
			? data
			: data.filter((f) => {
					const fType = getFacilityType(f);
					let isFiltered = false;
					switch (fType) {
						case 'store':
							isFiltered = filterStore.filters.facility === 1;
							break;
						case 'warehouse':
							isFiltered = filterStore.filters.facility === 2;
							break;
						default:
							break;
					}
					return isFiltered;
			  });
	};

	function sortedData() {
		let dataList = [];
		if (props.data != null) {
			const filteredList =
				filterStore.sort === 0
					? props.data.response
							.slice()
							.sort((a, b) =>
								a.payRange && b.payRange
									? parseFloat(b.payRange.min) -
									  parseFloat(a.payRange.min)
									: a.payRange
									? -1
									: b.payRange
									? 1
									: null
							)
							.map((item, i) => {
								dataList.push(item);
								return item;
							})
					: filterStore.sort === 1
					? props.data.response
							.slice()
							.sort((a, b) => {
								return b.openPositions - a.openPositions;
							})
							.map((item, i) => {
								dataList.push(item);
								return item;
							})
					: filterStore.sort === 2
					? props.data.response
							.slice()
							.sort((a, b) => {
								return a.openPositions - b.openPositions;
							})
							.map((item, i) => {
								dataList.push(item);
								return item;
							})
					: null;
			dataList = applyEmpTypeFilter(dataList);
			dataList = applyShiftFilter(dataList);
			dataList = applyFacilityFilter(dataList);
			dataList = applyWorkgroupFilter(dataList);
			dataList = applySkillFilter(dataList);
			dataList = applyInteractionFilter(dataList);
			dataList = applyWorkingConditionsFilter(dataList);
			return dataList;
		} else {
			return [];
		}
	}
	const empTypeSection = () => {
		return (
			<>
				{empTypeOptions.map((item, i) => {
					return (
						<div key={i} className={styles.filterCheckbox}>
							<Checkbox
								label={t(`availability.${item.title}`)}
								onChange={() => addEmpType(item.id)}
								checked={filterStore.filters.empType.includes(item.id)}
							/>
						</div>
					);
				})}
			</>
		);
	};
	const shiftSection = () => {
		return <ShiftFilterNav jobs={props.data.response} addShift={addShift} />;
	};
	const desktopFilterList = () => {
		return isFilterSelected(0) ? (
			<div className={styles.selectedFilterContainer}>
				<div className={styles.filterListContainer}>
					<div className={styles.filterTitle}>Selected Filters</div>
					<div className={styles.filterClearAll} onClick={resetFilters}>
						{isFilterSelected(0) === true ? t('jobFilter.clearAll') : ''}
					</div>
				</div>
				<div className={styles.filterChipContainer}>
					{filterStore.filters.empType.map((item, i) => {
						return (
							<div className={styles.filterChip} key={i}>
								<Chip
									size="small"
									trailing={<Close size="small" onClick={() => addEmpType(item)} />}>
									{t('availability.' + empTypeOptions.find((x) => x.id === item).title)}
								</Chip>
							</div>
						);
					})}
					{filterStore.filters.shift.map((item, i) => {
						return (
							<div className={styles.filterChip} key={i}>
								<Chip size="small" trailing={<Close size="small" onClick={() => addShift(item)} />}>
									{t('shift.' + shiftOptions.find((x) => x.id === item).title)}
								</Chip>
							</div>
						);
					})}
					{filterStore.filters.skill.map((item, i) => {
						return (
							<div className={styles.filterChip} key={i}>
								<Chip size="small" trailing={<Close size="small" onClick={() => addSkill(item)} />}>
									{t(jobFilterKey + skillOptions.find((x) => x.id === item).title + '.title')}
								</Chip>
							</div>
						);
					})}
					{filterStore.filters.workgroup.map((item, i) => {
						return (
							<div className={styles.filterChip} key={i}>
								<Chip
									size="small"
									trailing={<Close size="small" onClick={() => addWorkgroup(item)} />}>
									{t(
										jobFilterKey +
											[...workgroupOptions[0].options, ...workgroupOptions[1].options].find(
												(x) => x.id === item
											).id
									)}
								</Chip>
							</div>
						);
					})}
					{filterStore.filters.interaction.map((item, i) => {
						return (
							<div className={styles.filterChip} key={i}>
								<Chip
									size="small"
									trailing={<Close size="small" onClick={() => addInteraction(item)} />}>
									{t('jobFilter.customerInteraction')}{' '}
									{t(jobFilterKey + interactionOptions.find((x) => x.id === item).title)}
								</Chip>
							</div>
						);
					})}
					{filterStore.filters.workingConditions.map((item, i) => {
						return (
							<div className={styles.filterChip} key={i}>
								<Chip
									size="small"
									trailing={<Close size="small" onClick={() => addWorkingConditions(item)} />}>
									{t(jobFilterKey + workingConditionsOptions.find((x) => x.id === item).title)}
								</Chip>
							</div>
						);
					})}
					{filterStore.filters.facility !== 0 ? (
						<div className={styles.filterChip}>
							<Chip
								size="small"
								trailing={<Close size="small" onClick={() => changeFacility(0)} />}>
								{t(facilityOptions.find((x) => x.id === filterStore.filters.facility).title)}
							</Chip>
						</div>
					) : null}
				</div>
			</div>
		) : null;
	};
	const facilitySection = () => {
		return (
			<div className={styles.filterCheckboxHolder}>
				{facilityOptions.map((option, i) => (
					<div key={i} className={styles.filterRadio}>
						<Radio
							label={t(option.title)}
							value={option.id}
							checked={filterStore.filters.facility === option.id}
							onChange={(e) => changeFacility(e.target.value)}
						/>
						<div className={styles.filterSubText} style={{ marginLeft: '32px' }}>
							{t(option.subText)}
						</div>
					</div>
				))}
			</div>
		);
	};
	const accordions = () => {
		return (
			<div style={{ width: '100%' }}>
				{drawerType.id === 1 ? (
					<div>
						{props.futureTab ? (
							<Accordion
								sx={{
									'&:before': {
										display: 'none'
									}
								}}
								elevation={0}
								disableGutters
								expanded={openPanel === 1}
								onChange={() => setPanel(1)}
								className={styles.filterExpansionPanelContainerSort}>
								<AccordionSummary
									classes={{ content: styles.accordianSummaryContent }}
									className={styles.removePadding}
									expandIcon={<ChevronDown size="medium" />}>
									<div className={styles.filterTitle} role='button'>
										<div>{t('jobFilter.sortBy')}</div>
										<div className={styles.filterSubText}>
											{t(sortOptions[filterStore.sort].title)}
										</div>
									</div>
								</AccordionSummary>
								<AccordionDetails sx={{ padding: '16px 0' }}>
									<div className={styles.filterCheckboxHolder}>
										{sortOptions.map((option, i) => (
											<Radio
												key={i}
												className={styles.filterRadio}
												label={t(option.title)}
												value={option.id}
												checked={filterStore.sort === option.id}
												onChange={(e) => changePay(e)}
											/>
										))}
									</div>
								</AccordionDetails>
							</Accordion>
						) : null}
						{props.futureTab && (
							<Accordion
								sx={{
									'&:before': {
										display: 'none'
									}
								}}
								elevation={0}
								disableGutters
								expanded={openPanel === 8}
								onChange={() => setPanel(8)}
								className={styles.filterExpansionPanelContainer}>
								<AccordionSummary
									classes={{ content: styles.accordianSummaryContent }}
									className={styles.removePadding}
									expandIcon={<ChevronDown size="medium" />}>
									<div className={styles.filterTitle}>
										<div>{t('availability.empType')}</div>
										{filterStore.filters.empType.length > 0 && (
											<div className={styles.filterSubText}>
												{filterStore.filters.empType.length} {t(generalSelected)}
											</div>
										)}
									</div>
								</AccordionSummary>
								<AccordionDetails sx={{ padding: '0' }}>{empTypeSection()}</AccordionDetails>
							</Accordion>
						)}
						{props.futureTab ? (
							<Accordion
								sx={{
									'&:before': {
										display: 'none'
									}
								}}
								elevation={0}
								disableGutters
								expanded={openPanel === 6}
								onChange={() => setPanel(6)}
								className={styles.filterExpansionPanelContainerDesktop}>
								<AccordionSummary
									classes={{ content: styles.accordianSummaryContent }}
									className={styles.removePadding}
									expandIcon={<ChevronDown size="medium" />}>
									<div className={styles.filterTitle}>
										<div>{t('jobSelection.shift')}</div>
										{filterStore.filters.shift.length ? (
											<div className={styles.filterSubText}>
												{filterStore.filters.shift.length} {t(generalSelected)}
											</div>
										) : null}
									</div>
								</AccordionSummary>
								<AccordionDetails sx={{ padding: '0' }}>{shiftSection()}</AccordionDetails>
							</Accordion>
						) : null}
						{getFacilityList().store <= 0 || getFacilityList().warehouse <= 0 ? null : (
							<Accordion
								sx={{
									'&:before': {
										display: 'none'
									}
								}}
								elevation={0}
								disableGutters
								expanded={openPanel === 7}
								onChange={() => setPanel(7)}
								className={styles.filterExpansionPanelContainerDesktop}>
								<AccordionSummary
									classes={{ content: styles.accordianSummaryContent }}
									className={styles.removePadding}
									expandIcon={<ChevronDown size="medium" />}>
									<div className={styles.filterTitle}>
										<div>{t('jobSelection.facility')}</div>
										{filterStore.filters.facility !== 0 ? (
											<div className={styles.filterSubText}>
												{t(facilityOptions[filterStore.filters.facility].title)}{' '}
												{t(generalSelected)}
											</div>
										) : null}
									</div>
								</AccordionSummary>
								<AccordionDetails sx={{ padding: '0 0 6px' }}>{facilitySection()}</AccordionDetails>
							</Accordion>
						)}
						{getFacilityList().store > 0 &&
						(filterStore.filters.skill.length > 0 || filterStore.filters.facility < 2) ? (
							<Accordion
								elevation={0}
								sx={{
									'&:before': {
										display: 'none'
									}
								}}
								disableGutters
								expanded={openPanel === 2}
								onChange={() => setPanel(2)}
								className={styles.filterExpansionPanelContainer}>
								<AccordionSummary
									classes={{ content: styles.accordianSummaryContent }}
									className={styles.removePadding}
									expandIcon={<ChevronDown size="medium" />}>
									<div className={styles.filterTitle}>
										<div>{t('jobFilter.skillLevel')}</div>
										{filterStore.filters.skill.length ? (
											<div className={styles.filterSubText}>
												{filterStore.filters.skill.length} {t(generalSelected)}
											</div>
										) : null}
									</div>
								</AccordionSummary>
								<AccordionDetails sx={{ padding: '0' }}>
									<div>
										{skillOptions.map((item, i) => {
											return (
												<div key={i} className={styles.filterCheckbox}>
													<Checkbox
														label={t(jobFilterKey + item.title + '.title')}
														onChange={() => addSkill(item.id)}
														checked={filterStore.filters.skill.includes(item.id)}
													/>
													<div className={styles.filterSubText} style={{ marginLeft: '32px' }}>
														{t(jobFilterKey + item.title + '.desc')}
													</div>
												</div>
											);
										})}
									</div>
								</AccordionDetails>
							</Accordion>
						) : null}

						<Accordion
							elevation={0}
							sx={{
								'&:before': {
									display: 'none'
								}
							}}
							disableGutters
							expanded={openPanel === 3}
							onChange={() => setPanel(3)}
							className={styles.filterExpansionPanelContainer}>
							<AccordionSummary
								classes={{ content: styles.accordianSummaryContent }}
								className={styles.removePadding}
								expandIcon={<ChevronDown size="medium" />}>
								<div className={styles.filterTitle}>
									<div>{t('jobFilter.workgroups')}</div>
									{filterStore.filters.workgroup.length ? (
										<div className={styles.filterSubText}>
											{filterStore.filters.workgroup.length} {t(generalSelected)}
										</div>
									) : null}
								</div>
							</AccordionSummary>
							<AccordionDetails sx={{ padding: 0 }}>
								<div>
									<div className={styles.filterSubText} style={{ marginBottom: '16px' }}>
										{t('jobFilter.workgroupSubText')}
									</div>
									{workgroupOptions.map((option, i) => {
										return showWorkgroupCondition(option) ? null : (
											<div key={i} style={{ paddingBottom: '12px' }}>
												<Tag
													size="medium"
													color={option.facilityType === 'stores' ? 'blue' : 'purple'}
													variant="secondary"
													leading={option.icon}
													UNSAFE_style={{ marginBottom: '16px' }}>
													{t(jobFilterKey + option.facilityType)}
												</Tag>
												<div className={styles.filterCheckboxHolder}>
													{option.options.map((item, index) => {
														return (
															<div key={index} className={styles.filterCheckbox}>
																<Checkbox
																	label={t(jobFilterKey + item.id)}
																	onChange={() => addWorkgroup(item.id)}
																	checked={filterStore.filters.workgroup.includes(item.id)}
																/>
															</div>
														);
													})}
												</div>
											</div>
										);
									})}
								</div>
							</AccordionDetails>
						</Accordion>
						{getFacilityList().store > 0 &&
						(filterStore.filters.interaction.length > 0 || filterStore.filters.facility < 2) ? (
							<Accordion
								elevation={0}
								sx={{
									'&:before': {
										display: 'none'
									}
								}}
								disableGutters
								expanded={openPanel === 4}
								onChange={() => setPanel(4)}
								className={styles.filterExpansionPanelContainer}>
								<AccordionSummary
									classes={{ content: styles.accordianSummaryContent }}
									className={styles.removePadding}
									expandIcon={<ChevronDown size="medium" />}>
									<div className={styles.filterTitle}>
										<div>{t('jobFilter.customerInteraction')}</div>
										{filterStore.filters.interaction.length ? (
											<div className={styles.filterSubText}>
												{filterStore.filters.interaction.length} {t(generalSelected)}
											</div>
										) : null}
									</div>
								</AccordionSummary>
								<AccordionDetails sx={{ padding: 0 }}>
									<div>
										<div className={styles.filterSubText} style={{ marginBottom: '16px' }}>
											{t('jobFilter.customerInteractionSubText')}
										</div>

										{interactionOptions.map((item, i) => {
											return (
												<div key={i} className={styles.filterCheckbox}>
													<Checkbox
														label={t(jobFilterKey + item.title)}
														onChange={() => addInteraction(item.id)}
														checked={filterStore.filters.interaction.includes(item.id)}
													/>
													<div className={styles.filterSubText} style={{ marginLeft: '32px' }}>
														{item.description}
													</div>
												</div>
											);
										})}
									</div>
								</AccordionDetails>
							</Accordion>
						) : null}
						{getFacilityList().store > 0 &&
						(filterStore.filters.workingConditions.length > 0 ||
							filterStore.filters.facility < 2) ? (
							<Accordion
								elevation={0}
								sx={{
									'&:before': {
										display: 'none'
									}
								}}
								disableGutters
								expanded={openPanel === 5}
								onChange={() => setPanel(5)}
								className={styles.filterExpansionPanelContainer}>
								<AccordionSummary
									classes={{ content: styles.accordianSummaryContent }}
									className={styles.removePadding}
									expandIcon={<ChevronDown size="medium" />}>
									<div className={styles.filterTitle}>
										<div>{t('jobFilter.workingConditions')}</div>
										{filterStore.filters.workingConditions.length ? (
											<div className={styles.filterSubText}>
												{filterStore.filters.workingConditions.length} {t(generalSelected)}
											</div>
										) : null}
									</div>
								</AccordionSummary>
								<AccordionDetails sx={{ padding: '0' }}>
									<div>
										{workingConditionsOptions.map((item, i) => {
											return (
												<div key={i} className={styles.filterCheckbox}>
													<Checkbox
														label={t(jobFilterKey + item.title)}
														onChange={() => addWorkingConditions(item.id)}
														checked={filterStore.filters.workingConditions.includes(item.id)}
													/>
													<div className={styles.filterSubText} style={{ marginLeft: '32px' }}>
														{item.description}
													</div>
												</div>
											);
										})}
									</div>
								</AccordionDetails>
							</Accordion>
						) : null}
					</div>
				) : drawerType.id === 2 ? (
					shiftSection()
				) : (
					facilitySection()
				)}
			</div>
		);
	};

	const bottomDrawer = () => {
		return (
			<BottomSheet
				isOpen={filtersOpen}
				onClose={() => setFiltersOpen(false)}
				title={({ titleId }) => (
					<div className={styles.filterDrawerTitle}>
						<div className={styles.filterClearAll} onClick={resetFilters}>
							{isFilterSelected(0) === true ? t('jobFilter.clearAll') : ''}
						</div>
						<h3 className={styles.drawerTitle}>{t(drawerType.title)}</h3>
						<BottomSheetCloseButton
							aria-label={t('ariaLabelTexts.closeDialog')}
							UNSAFE_style={{ marginRight: '8px' }}
							onClick={() => setFiltersOpen(false)}
						/>
					</div>
				)}
				actions={
					<Button size="medium" variant="primary" isFullWidth onClick={() => setFiltersOpen(false)}>
						{t('jobFilter.viewResults')}
					</Button>
				}>
				{accordions()}
			</BottomSheet>
		);
	};

	return props.filterCrumb ? (
		<Popover
			hasNubbin
			content={
				<div className={styles.filterSortHolder}>
					{sortOptions.map((option, i) => (
						<Radio
							key={i}
							className={styles.filterRadio}
							label={t(option.title)}
							value={option.id}
							checked={filterStore.sort === option.id}
							onChange={(e) => changePay(e)}
						/>
					))}
				</div>
			}
			onClose={(event) => setCrumb(false)}
			isOpen={crumb}
			triggerRef={ref}>
			<div
				className={styles.sortTitle}
				ref={ref}
				aria-label={t('ariaLabelTexts.sortBy')}
				onClick={(event) => {
					setCrumb((crumb) => !crumb);
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						setCrumb((crumb) => !crumb);
					}
				}}
				tabIndex="0"
				role="button">
				<div>{t('jobFilter.sortBy')}</div>
				<div className={styles.sortLine}></div>
				<div className={styles.sortSubText}>{t(sortOptions[filterStore.sort].title)}</div>
				<ChevronDown size="small" />
			</div>
		</Popover>
	) : (
		<div className={styles.filterSideBar}>
			<div className={styles.filterAllDesktop}>
				<Filter className={styles.filterIcon} size="medium" /> {t('jobFilter.allFilters')}
			</div>
			<div className={styles.filterContainer}>
				<div className={styles.filterScroll}>
					{props.filtersList.map((f, i) => {
						return filterOptions[i].id === 3 &&
							(getFacilityList().store <= 0 || getFacilityList().warehouse <= 0) ? null : (
							<button
								key={i}
								id={t(f.title)}
								className={
									isFilterSelected(i + 1) > 0 ? styles.selectedFilterBubble : styles.filterBubble
								}
								onClick={() => {
									setDrawerType(filterOptions[i]);
									setFiltersOpen(true);
								}}>
								{f.icon}
								<div className={styles.bubbleTitle}>
									<Body
										as="div"
										role='button'
										aria-labelledby={t(f.title)}
										UNSAFE_style={isFilterSelected(i + 1) > 0 ? { color: 'white' } : {}}>
										{t(f.title)}
										{isFilterSelected(i + 1) > 0 ? <span> ({isFilterSelected(i + 1)})</span> : ''}
									</Body>
								</div>
								<ChevronDown size="small" />
							</button>
						);
					})}
				</div>
				{bottomDrawer()}
			</div>
			<div className={styles.desktopFilterContainer}>
				{desktopFilterList()}
				{accordions()}
			</div>
		</div>
	);
};

export default JobSearchFilter;
