import React from 'react';
import { Caption, Body, Button, Alert } from '@walmart-web/livingdesign-components';
import { TrashCan } from '@livingdesign/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './style.scss';
import moment from 'moment';

const JobRow = (props) => {
	const { t } = useTranslation();
	const {
		position: { jobClassId },
		position,
		disabled,
		data: { storeNbr, workGroupId },
		onClick
	} = props;
	const languageCode = useSelector((state) => state.global.language) !== 'ES' ? 101 : 102;
	const locationDetails = useSelector((state) => state.postApplyDashboard.data.locationDetails);
	const asmtResults = useSelector((state) => state.postApplyDashboard.data.asmtResults);
	const trackerDetails = locationDetails && locationDetails[storeNbr].trackerDetails;
	const stage = (trackerDetails && trackerDetails[jobClassId]) || -1;

	const stagesArr = ['Profile', 'Apply', 'Consideration', 'Interview', 'Offer'];

	const handleWithDrawButtonClick = () => {
		onClick(jobClassId, storeNbr, workGroupId);
	};

	const isFailed = asmtResults[position['asmtCode']].status === 'F';
	const retakeAfter = moment(asmtResults[position['asmtCode']].expDate, 'YYYY-MM-DD').format(
		'MM/DD/YYYY'
	);

	return (
		<div className="job_row_container">
			<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
				<Body as="div" size="medium" weight={400} UNSAFE_className="job_row_title">
					{position[languageCode]}
				</Body>
				{!isFailed && stage !== -1 && (
					<Caption as="span" UNSAFE_style={{ color: '#74767C' }}>
						{t('jobTracker.applicationStatus')}: {t('jobTracker.' + stagesArr[stage])}
					</Caption>
				)}

				{isFailed && (
					<Alert as="div" variant="warning" UNSAFE_style={{ marginTop: '8px' }}>
						<b>{t('jobTracker.asmtFailedNotElig1')}</b>
						{t('jobTracker.asmtFailedNotElig2')}{' '}
						{`${t('assessment.assessmentRetake')} ${retakeAfter}`}
					</Alert>
				)}
			</div>
			{!isFailed && disabled && (
				<Button
					size="medium"
					trailing={<TrashCan size="small" />}
					variant="tertiary"
					UNSAFE_className="job_row_button"
					onClick={handleWithDrawButtonClick}>
					{t('jobTracker.withdraw')}
				</Button>
			)}
		</div>
	);
};

export default JobRow;
