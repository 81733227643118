import { BottomSheet, Modal } from "@walmart-web/livingdesign-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import LocationSearch from "./LocationSearch";

const LocSearchContainer = (props) => {
    const isDesktop = useMediaQuery({ minWidth: 851 });
    const iPadLandscape = useMediaQuery({
        minWidth: 426,
        maxWidth: 850,
        orientation: "landscape",
    });
    const iPadPortrait = useMediaQuery({
        minWidth: 426,
        maxWidth: 850,
        orientation: "portrait",
    });
    const isMobile = useMediaQuery({ maxWidth: 425 });

    const { t } = useTranslation();

    return (
        <React.Fragment>
            {(isDesktop || iPadLandscape) && (
                <Modal
                    isOpen={props.isOpen}
                    onClose={() => props.setIsOpen(false)}
                    title={t("location.locationSearchTitle")}
                    size="medium"
                >
                    <LocationSearch
                        isOpen={props.isOpen}
                        setIsOpen={props.setIsOpen}
                        showSearch={true}
                        showCurrentLoc={true}
                    />
                </Modal>
            )}
            {(isMobile || iPadPortrait) && (
				<BottomSheet
					isOpen={props.isOpen}
					title={t('location.locationSearchTitle')}
                    onClose={() => props.setIsOpen?.(false)}>
					<LocationSearch
						isOpen={props.isOpen}
						setIsOpen={props.setIsOpen}
						showSearch={true}
						showCurrentLoc={true}
					/>
				</BottomSheet>
			)}
        </React.Fragment>
    );
};

export default LocSearchContainer;
