import { useMemo, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useSelfSchedule } from '../hooks/useSelfSchedule';
import { selfScheduleType } from '../../../config/const';

const useHRAvailability = () => {
	const [selectedDateIndex, setSelectedDateIndex] = useState(0);
	const [selectedDateIndexForTour, setSelectedDateIndexForTour] = useState(null);
	const [selectedTimeIndex, setSelectedTimeIndex] = useState(1);
	const [morningTimeSlots, setMorningTimeSlots] = useState();
	const [afternoonTimeSlots, setAfternoonTimeSlots] = useState();
	const [eveningTimeSlots, setEveningTimeSlots] = useState();
	const selfSchedule = useSelector((state) => state.selfSchedule);
	const [disableList, setDisableList] = useState(null);

	const { getScheduleType } = useSelfSchedule();
	const scheduleType = getScheduleType();

	const sortTimeSlots = (slotArray) => {
		let sortedSlots = [];
		sortedSlots = slotArray.sort((a, b) => moment(a.utcTime) - moment(b.utcTime));
		return sortedSlots;
	};
	const sortEveningTimeSlots = (slotArray) => {
		const isAM = [];
		const isPM = [];

		slotArray.map((ele) => {
			if (ele.time.split(' ')[1] === 'AM') {
				isAM.push(ele);
			} else {
				isPM.push(ele);
			}
		});

		const sortedIsAM = sortTimeSlots(isAM);
		const sortedIsPM = sortTimeSlots(isPM);

		return sortedIsPM.concat(sortedIsAM);
	};

	const setInterviewSlots = () => {
		const { sortedMorningTimeSlots, sortedAfternoonTimeSlots, sortedEveningTimeSlots } =
			memoizedInterviewSlots;
		setMorningTimeSlots(sortedMorningTimeSlots);
		setAfternoonTimeSlots(sortedAfternoonTimeSlots);
		setEveningTimeSlots(sortedEveningTimeSlots);
	};

	const getSchdlTimesOnDate = (timeSlots, selectedDateIndex) => {
		const schdlTime = [];
		Object.keys(timeSlots).forEach(function (key) {
			let slotArray = timeSlots[key];
			schdlTime.push(slotArray);
		});
		return schdlTime.length > 0 && schdlTime[selectedDateIndex] !== undefined
			? schdlTime[selectedDateIndex]
			: [];
	};


	const getTimeSlots = (selectedDateIndex, selectedDateIndexForTour) => {
		let timeSlots;
		const morningSlotsArray = [];
		const afternoonSlotsArray = [];
		const eveningSlotsArray = [];
		const disableList = [];

		if (scheduleType === selfScheduleType.SC_ORIENTATION) {
			timeSlots = selfSchedule.orientDateAndTimeSlots;
		} else if (scheduleType === selfScheduleType.SC_TOUR) {
			timeSlots = selfSchedule.dateAndTimeSlots;
		} else {
			timeSlots = selfSchedule.intrDateAndTimeSlots;
		}

		if (timeSlots && Object.keys(timeSlots).length > 0) {
			if (scheduleType === selfScheduleType.SC_ORIENTATION) {
				let slots = getSchdlTimesOnDate(timeSlots, selectedDateIndex);
				slots.forEach((timeSlot) => {
					const time = moment(timeSlot.time, 'hh:mm A').format('hh:mm A');

					if (
						selfSchedule.responseData.scheduleTs != null &&
						timeSlot.scheduledTs == selfSchedule.responseData.scheduleTs
					) {
						let currTime = moment(selfSchedule.responseData.scheduleTs).format('hh:mm A');
						disableList.push(currTime);
					}
					if (
						moment(timeSlot.time, 'hh:mm A').format('HH:mm:ss') >= '04:00:00' &&
						moment(timeSlot.time, 'hh:mm A').format('HH:mm:ss') <= '11:59:00'
					) {
						morningSlotsArray.push({ time: time, utcTime: timeSlot.time });
					} else if (
						moment(timeSlot.time, 'hh:mm A').format('HH:mm:ss') >= '12:00:00' &&
						moment(timeSlot.time, 'hh:mm A').format('HH:mm:ss') <= '16:59:00'
					) {
						afternoonSlotsArray.push({ time: time, utcTime: timeSlot.time });
					} else {
						eveningSlotsArray.push({ time: time, utcTime: timeSlot.time });
					}
				});
			} else if (scheduleType === selfScheduleType.SC_TOUR) {
				if (selectedDateIndexForTour == null) {
					// Pick 0th item
					selectedDateIndexForTour = Object.keys(timeSlots)[0];
				}

				const times = [];
				timeSlots[selectedDateIndexForTour].forEach((obj) => {
					if (obj.hrEventId != selfSchedule.responseData.oldHrEventId) {
						const time = moment(obj.eventStartTs, 'YYYY-MM-DD hh:mm:ss').format('hh:mm A');
						if (!times.includes(time)) {
							times.push(time);

							const utcTime = moment(obj.eventStartTs, 'YYYY-MM-DD hh:mm:ss').format('HH:mm:ss');
							if (utcTime >= '04:00:00' && utcTime <= '11:59:00') {
								morningSlotsArray.push({ time: time, utcTime: utcTime });
							} else if (utcTime >= '12:00:00' && utcTime <= '16:59:00') {
								afternoonSlotsArray.push({ time: time, utcTime: utcTime });
							} else {
								eveningSlotsArray.push({ time: time, utcTime: utcTime });
							}
						}
					}
				});
			} else {
				timeSlots = getSchdlTimesOnDate(timeSlots, selectedDateIndex);
				timeSlots.forEach((timeSlot) => {
					if (timeSlot.display) {
						const time = moment(timeSlot.startTime).format('hh:mm A');
						if (
							moment(timeSlot.startTime).format('HH:mm:ss') >= '04:00:00' &&
							moment(timeSlot.startTime).format('HH:mm:ss') <= '11:59:00'
						) {
							morningSlotsArray.push({ time: time, utcTime: timeSlot.startTime });
						} else if (
							moment(timeSlot.startTime).format('HH:mm:ss') >= '12:00:00' &&
							moment(timeSlot.startTime).format('HH:mm:ss') <= '16:59:00'
						) {
							afternoonSlotsArray.push({ time: time, utcTime: timeSlot.startTime });
						} else {
							eveningSlotsArray.push({ time: time, utcTime: timeSlot.startTime });
						}
					}
				});
			}

			setDisableList(disableList);
		}

		const sortedMorningTimeSlots = sortTimeSlots(morningSlotsArray);
		const sortedAfternoonTimeSlots = sortTimeSlots(afternoonSlotsArray);
		const sortedEveningTimeSlots = sortEveningTimeSlots(eveningSlotsArray);
		return {
			sortedMorningTimeSlots,
			sortedAfternoonTimeSlots,
			sortedEveningTimeSlots
		};
	};
	const memoizedInterviewSlots = useMemo(() => {
		return getTimeSlots(selectedDateIndex, selectedDateIndexForTour);
	}, [selectedDateIndex, selectedDateIndexForTour]);

	return {
		morningTimeSlots,
		afternoonTimeSlots,
		eveningTimeSlots,
		selectedDateIndex,
		selectedTimeIndex,
		disableList,
		setInterviewSlots,
		setSelectedDateIndex,
		setSelectedDateIndexForTour,
		setSelectedTimeIndex
	};
};

export default useHRAvailability;
