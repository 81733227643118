import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequestErrorOccured, updateLoading } from 'redux/slices/globalSlice';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';


const saveHcTestResponse = createAsyncThunk('experience/saveHcTestResponse', async (request, { dispatch }) => {
	try {
        let applicantId=request.applicantId;
        let hcTestResponse=request.hcTestResponse
		const baseUrl =
			environment.hcServiceBasePostUrl +
			'/saveHcTestResponse?applicantId=' +
			applicantId;
		const response = await axiosInstance.post(baseUrl,hcTestResponse);
		dispatch(updateLoading(false));
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});



export { saveHcTestResponse };