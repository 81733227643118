import React from 'react';
import styles from './JobSearchFilter.module.css';
import { useTranslation } from 'react-i18next';
import { Checkbox, Tag } from '@walmart-web/livingdesign-components';
import { useSelector } from 'react-redux';

const ShiftFilterItem = (props) => {
	const { t } = useTranslation();
	const shiftOptions = props.shiftOptions;
	const filterStore = useSelector((state) => state.jobSearch);

	return (
		<div>
			{shiftOptions.map((option, i) => {
				return props.showShiftCondition(option) ? null : (
					<div key={i}>
						{option.facilityType === 'stores' && (
							<Tag
								size="medium"
								color="blue"
								variant="secondary"
								leading={option.icon}
								UNSAFE_style={{ marginBottom: '16px' }}>
								{t('jobFilter.' + option.facilityType)}
							</Tag>
						)}
						<div className={styles.filterCheckboxHolder}>
							{option.options.map((item, index) => {
								return (
									<div key={item.title} className={styles.filterCheckbox}>
										<Checkbox
											label={t('shift.' + item.title)}
											onChange={() => props.addShift(item.id)}
											checked={filterStore.filters.shift.includes(item.id)}
										/>
										<div className={styles.filterSubText} style={{ marginLeft: '32px' }}>
											{t('availability.shftTiming', { start: item.startFrom, end: item.startTo })}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default ShiftFilterItem;
