import { Radio } from '@walmart-web/livingdesign-components';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './RadioList.module.scss';
import { parseAndRenderLabel } from 'pages/Application/TaxQuestionnaire/util';

const RadioList = ({ id, label, defaultValue, data, onChangeCallback }) => {
	const [selectedIndex, setSelectedIndex] = useState(-1);

	useEffect(() => {
		if (selectedIndex !== -1 && onChangeCallback) {
			onChangeCallback(data[selectedIndex]?.value);
		}
	}, [selectedIndex]);

	return (
		<div className={styles.container} role="form" aria-labelledby={`radio-${id}`}>
			<div id={`radio-${id}`}>{parseAndRenderLabel(label, 'medium', 400)}</div>
			{data?.map((option, index) => {
				if (defaultValue && selectedIndex === -1 && option.value === defaultValue) {
					setSelectedIndex(index);
				}
				return (
					<Radio
						key={`radio-${id + '$' + index}`}
						UNSAFE_className={styles.radio}
						checked={index === selectedIndex}
						onChange={() => setSelectedIndex(index)}
						label={option.label || 'None'}
					/>
				);
			})}
		</div>
	);
};

RadioList.propTypes = {
	id: PropTypes.string,
	defaultValue: PropTypes.string,
	label: PropTypes.string,
	data: PropTypes.array,
	onChangeCallback: PropTypes.func
};

export default RadioList;
