import React from 'react';
import { Body, Heading } from '@walmart-web/livingdesign-components';
import './style.scss';
import { useTranslation } from 'react-i18next';

const TextBanner = () => {
	const { t } = useTranslation();
	return (
		<div className="renew_banner_container">
			<div>
				<Heading as="h1" size="small" UNSAFE_className="banner_header">
					{t('renew.timeToRenew')}
				</Heading>
				<Body as="div" UNSAFE_className="banner_body">
					{t('renew.timeToRenewDesc')}
				</Body>
				<div className="stripe" />
			</div>
		</div>
	);
};

export default TextBanner;
