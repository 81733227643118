import { Radio } from "@walmart-web/livingdesign-components";
import { GTM } from "config/const";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sendGtmEvent } from "utils/GoogleTagManagerUtils";
import { updateLanguage } from "../../../redux/slices/globalSlice";
import style from "./PreferredLanguage.module.css";
import cookie from 'react-cookies';

const PreferredLanguage = () => {
  const global = useSelector((state) => state.global);
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const handleLanguageChange = (event) => {
    sendGtmEvent(GTM.EVENTS.LANGUAGE_TOGGLE);
    i18n.changeLanguage(event.target.value.toLowerCase());
    cookie.save('languagePref', event.target.value);
    dispatch(updateLanguage(event.target.value));
  };
  return (
    <div className={style.languageInputs}>
      <div className={style.langInpChild}>
        <Radio
          label="English (EN)"
          checked={global.language === "EN"}
          name="language"
          onChange={handleLanguageChange}
          value="EN"
        />
      </div>
      <div className={style.langInpChild}>
        <Radio
          label="Español (ES)"
          checked={global.language === "ES"}
          name="language"
          onChange={handleLanguageChange}
          value="ES"
        />
      </div>
    </div>
  );
};

export default PreferredLanguage;
