import React, { useState, useEffect } from 'react';
import { Body, TextArea, Button } from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import RatingButtonGroup from '../RatingButtonGroup';
import './style.scss';

const FeedbackContentHolder = (props) => {
  const [comments, setComments] = useState('');
  const [disable, setDisable] = useState(true);
  const [rating,setRating] = useState([-1,-1]);


  const { t } = useTranslation();

  const handleTextAreaChange = (e) => {
    setComments(e.target.value);
  };

  const handleRatingButtonClick = (e,keyIndex) => {
    const editedRating=[...rating];
    editedRating[keyIndex]=e;
    setRating(editedRating);
  };

  useEffect(() => {
    if (!rating.includes(-1))
    { setDisable(false);}
  }, [rating]);


  const handleSubmitButtonClick = () => {
    props.handleSubmit({ rating: rating, comments });
  };

  const feedbackQues=[
    {
      feedbackQues:t('feedback.question1'), quesNo:'question1', id:0, initialValue:t('feedback.stronglyDisagree'), finalValue:t('feedback.stronglyAgree'), maxFeedbackValue:5
    },
    {
      feedbackQues:t('feedback.question2'), quesNo:'question2',id:1,initialValue :t('feedback.tooLong') ,finalValue:t('feedback.justRightAmount'), maxFeedbackValue:5
    }
  ];

  return (
    <div className="feedback_content_holder">
      {feedbackQues.map((item,index) => {
        return(
            <div className='feedback_wrapper'>
              <Body UNSAFE_className="feedback_body">{item.feedbackQues}</Body>
              <RatingButtonGroup onClick={e=> handleRatingButtonClick(e,item.id)} question={item.quesNo} maxValue = {item.maxFeedbackValue} intialValue={item.initialValue} finalValue={item.finalValue}/>
            </div>
        )
      })}
      <Body as="span" size="small" weight={700}>
        {t('feedback.comments')}
      </Body>
      <TextArea
        onChange={(e) => {
          handleTextAreaChange(e);
        }}
        id="feedbackTextArea"
        placeholder={t('feedback.placeHolder')}
        value={comments}
        type="text"
      />
      <div className="rating_submit_button_container">
        <Button
          disabled={disable}
          size="medium"
          variant="primary"
          UNSAFE_style={{ width: '100%' }}
          onClick={handleSubmitButtonClick}
        >
          {t('feedback.submit')}
        </Button>
      </div>
    </div>
  );
};

export default FeedbackContentHolder;
