import React from 'react';
import { useSelector } from 'react-redux';
import style from './HeaderLogoSection.module.css';
import { useMediaQuery } from 'react-responsive';
import SubHeader from '../SubHeader/SubHeader';
import { ChevronLeft, Spark } from '@livingdesign/icons';

import walmartLogo from 'assets/images/walmart-title.png';
import { useNavigate, useLocation } from 'react-router-dom';

const HeaderLogoSection = (props) => {
	const navigate = useNavigate();
	const locationHook = useLocation();
	const isDesktop = useMediaQuery({ minWidth: 851 });
	const iPadLandscape = useMediaQuery({
		minWidth: 426,
		maxWidth: 850,
		orientation: 'landscape'
	});
	const iPadPortrait = useMediaQuery({
		minWidth: 426,
		maxWidth: 850,
		orientation: 'portrait'
	});
	const isMobile = useMediaQuery({ maxWidth: 425 });
	const global = useSelector((state) => state.global);

	const handleLogoClick = () => {
		if (locationHook.pathname === '/applicant-login') {
			navigate('../homeScreen');
		}
	};

	const icon =
		global.path === 'edit-profile' ? (
			<ChevronLeft size="large" />
		) : (
			<Spark size="large" onClick={handleLogoClick} />
		);

	return (
		<>
			{global.path !== 'edit-profile' ? (
				<div className={style.sparkLogo}>
					{(isDesktop || iPadLandscape) && global.showSpark && (
						<img
							src={walmartLogo}
							alt="Walmart logo"
							style={{ height: '40px' }}
							onClick={handleLogoClick}
						/>
					)}
					{(isMobile || iPadPortrait) && global.showSpark && !global.subHeader.display && icon}
					{(isMobile || iPadPortrait) &&
						global.subHeader.display &&
						locationHook.pathname !== '/error' && <SubHeader />}
				</div>
			) : (
				<ChevronLeft
					size="large"
					color={'white'}
					style={{ cursor: 'pointer' }}
					onClick={() => navigate('../post-apply-dashboard')}
				/>
			)}
		</>
	);
};

export default HeaderLogoSection;
