import React from 'react';
import styles from './InfoItem.module.css';

const InfoItem = (props) => {
	return (
		<div className={styles.infoItemContainer}>
			<div className={styles.infoItemIcon}>{props.icon}</div>
			{props.children}
		</div>
	);
};

export default InfoItem;
