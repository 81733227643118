import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	questionnaireAns: {},
	questionnaireCompleted: false,
	isSubmissionScreen: false,
	validationError: {},
	language: 'EN',
	zipCode: '',
	screeningId: null
};

export const questionnaireSlice = createSlice({
	name: 'questionnaire',
	initialState,
	reducers: {
		updateQuestionnaireAnswer: (state, action) => {
			state.questionnaireAns = { ...state.questionnaireAns, ...action.payload };
			//{elementId1: {elementId, value, elementType}, elementId2: ...}
		},
		updateQuestionnaireCompletedStatus: (state, action) => {
			state.questionnaireCompleted = action.payload;
		},
		updateSubmissionScreenStatus: (state, action) => {
			state.isSubmissionScreen = action.payload;
		},
		updateValidationError: (state, action) => {
			state.validationError = { ...state.validationError, ...action.payload };
		},
		updateQuestionnaireLanguage: (state, action) => {
			state.language = action.payload;
		},
		updateQuestionnaireZipCode: (state, action) => {
			state.zipCode = action.payload;
		},
		updateScreeningId: (state, action) => {
			state.screeningId = action.payload;
		}
	}
});

export const {
	updateQuestionnaireAnswer,
	updateQuestionnaireCompletedStatus,
	updateSubmissionScreenStatus,
	updateValidationError,
	updateQuestionnaireLanguage,
	updateQuestionnaireZipCode,
	updateScreeningId
} = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
