import React from 'react';
import { useSelector } from 'react-redux';
import {
	Body,
	Caption,
	Card,
	Heading,
	ProgressTracker,
	ProgressTrackerItem,
	Alert
} from '@walmart-web/livingdesign-components';
import './style.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const ApplicationStatus = () => {
	const { t } = useTranslation();
	const data = useSelector((state) => state.postApplyDashboard.data);
	const activeUntilDate = moment(data.applActiveTill, 'YYYY-MM-DD').format('MM/DD/YYYY');
	const statusArr = ['Profile', 'Apply', 'Consideration', 'Interview', 'Offer'];
	return (
		<Card size="medium" UNSAFE_className="as_card_container">
			<Heading as="h1" size="medium" weight={700}>
				{t('jobTracker.applicationStatus')}
			</Heading>
			{data.applActiveTill && (
				<Caption UNSAFE_style={{ color: '#2e2f32' }}>
					{t('jobTracker.activeUntil')} {activeUntilDate}{' '}
				</Caption>
			)}

			{data.applicationStatus !== -1 ? (
				<> 
					<ProgressTracker
					activeIndex={data.applicationStatus}
					variant="info"
					UNSAFE_className="progress_tracker">
					{statusArr.map((status, index) => {
						return (
							<ProgressTrackerItem key={index}>{t('jobTracker.' + status)}</ProgressTrackerItem>
						);
					})}
				</ProgressTracker>
				<Alert variant="info"> {t('jobTracker.completedApplicationMessage')} </Alert>
				</>
			) : (
				<React.Fragment>
					<Body as="span" size="small" weight={400} UNSAFE_style={{ textAlign: 'center' }}>
						{t('jobTracker.noTrackerData_desc')}
					</Body>
				</React.Fragment>
			)}
		</Card>
	);
};

export default ApplicationStatus;
