import { isEmptyObject } from "../../utils/validation";

const applProfileStepsObj = {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    status: 'PENDING'
};

const decorateApplProfileStepsObj = (resp) => {
    let data = resp.applProfileSteps;
    if (typeof data === 'undefined' || data === null) {
        data = applProfileStepsObj;
    }
	const applProfileSteps = {};
	let shouldEnableSteps = true;
    data &&
		Object.entries(data).map((entry) => {
			let key = entry[0];
			let value = entry[1];
			if (value === true && shouldEnableSteps === true) {
				applProfileSteps[key] = true;
				applProfileSteps.status = 'COMPLETED';
			} else {
				shouldEnableSteps = data[11] && (!resp.noAsmtJobsPreferred || (!resp.onlyPrJobsPreferred && data[9]) || (resp.onlyPrJobsPreferred && data[8]));
				applProfileSteps[key] = false;
				applProfileSteps.status = !shouldEnableSteps ? 'PENDING' : 'COMPLETED';
			}
		});
	return applProfileSteps;
};

const decorateResponse = (data) => {
    const applProfileSteps = decorateApplProfileStepsObj(data);
    let auth = {
        divisionNumber: data.divisionNumber,
        applicantName: data.applicantName,
        age18: data.age18,
        stepNbr: data.stepNbr,
        fileLanguageCode: data.fileLanguageCode,
        isApplCompleted: data.isApplCompleted,
        profileCompleted: data.profileCompleted,
        responseCode: data.responseCode,
        expiredApplDate: data.expiredApplDate,
        triggerHH: false,
        virtualJob: data.virtualJob,
        vjo: data.vjo,
        responseMessage: data.responseMessage,
        showJobStatus: data.showJobStatus,
        shExitingJobPreference: data.shExitingJobPreference,
        supplyChain: data.supplyChain ? data.supplyChain : false,
        storeHourly: data.storeHourly ? data.storeHourly : false,
        scJobsPreferred: data.scJobsPreferred ? data.scJobsPreferred : false,
	    prJobsPreferred: data.prJobsPreferred ? data.prJobsPreferred : false,
	    onlyScJobsPreferred: data.onlyScJobsPreferred ? data.onlyScJobsPreferred : false,
	    onlyPrJobsPreferred: data.onlyPrJobsPreferred ? data.onlyPrJobsPreferred : false,
        noAsmtJobsPreferred: data.noAsmtJobsPreferred ? data.noAsmtJobsPreferred : false
    };
    if (!isEmptyObject(data.vjo)) {
        const { nextStepInd, ...vjo } = data.vjo;
        auth = { ...auth, vjo: vjo };
    }
    return { auth, applProfileSteps };
};

const decorateForgetPasswordResponse = (data) => {
    return {
        responseCode: data.responseCode,
        responseMessage: data.responseMessage,
        nextScreen: 'SentVerifyEmail',
        nextPath: '/sendVerifyEmail/1',
        status: 'PENDING',
        resetEmail: '',
    };
};

export { decorateResponse, decorateForgetPasswordResponse };
