import React from 'react';
import { Chip } from '@walmart-web/livingdesign-components';
import style from '../SelfSchedule.module.scss';
const Icon = require('@livingdesign/icons');

const TimeChip = (props) => {
	return (
		<Chip
			size="large"
			disabled = {props.disList && props.disList.length > 0 && props.disList.includes(props.interval)}
			className={style.time_chip}
			style={props.style}
			selected={props.selected}
			onClick={() => props.handleOnTimePick(props.interval, props.dateIndex, props.timeIndex)}
			key={props.interval}>
			<div
				style={{
					color: props.selected ? '#FFFFFF' : '#000'
				}}>
				{props.label}
			</div>
			&nbsp; &nbsp;
			{props.selected ? (
				<div className={style.checkIcon}>
					<Icon.Check />
				</div>
			) : (
				<Icon.Plus size="small" />
			)}
		</Chip>
	);
};

export default TimeChip;
