import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { ChevronDown } from "@livingdesign/icons";

export const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    // border: `10px solid ${theme.palette.divider}`,
    border: `1px solid #E3E4E5`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
    //boxShadow: '0px -1px 2px rgba(0, 0, 0, 0.1), 0px 1px 2px 1px rgba(0, 0, 0, 0.15)',
    ":first-of-type": {
        borderRadius: "8px 8px 0px 0px",
    },
    ":last-of-type": {
        borderRadius: "0px 0px 8px 8px",
    },
}));

export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ChevronDown />} {...props} />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "light"
            ? "rgba(255, 255, 255, .05)"
            : "rgba(0, 0, 0, .03)",
    flexDirection: "row",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(180deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(0),
        marginTop: 16,
        marginBottom: 16,
        gap: "8px",
        marginRight: "8px",
    },
    "& .css-1pnmrwp-MuiTypography-root": {
        fontFamily: "Bogle",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 18,
        lineHeight: "24px",
        color: "#000000",
    },
    "& .css-1m4ljmh-MuiTypography-root": {
        width: "8%",
        height: "24px",
    },
    "& .css-1m4ljmh-MuiTypography-root svg": {
        //color: '#000000',
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    //padding: theme.spacing(2),
    //borderTop: '1px solid rgba(0, 0, 0, .125)',
    paddingTop: "0px",
    paddingBottom: "8px",
}));

export const expandIcon = (
    <ChevronDown style={{ color: "#000000" }} size="medium" />
);
