import { GTM } from '../config/const';

export const pushToGtmDataLayer = (variableName, variableValue) => {
	try {
		if (variableName) {
			window.dataLayer.push({ [variableName]: variableValue });
		}
	} catch (err) {
		console.log(err);
	}
};

export const sendGtmEvent = (eventName) => {
	try {
		if (eventName) {
			window.dataLayer.push({ [GTM.EVENTS.EVENT]: eventName });
		}
	} catch (err) {
		console.log(err);
	}
};
