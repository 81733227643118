import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getScheduleCompleted,
	initiateCandidateDrugScreen,
	isDrugScreenRequired
} from 'redux/VJODrugScreen/request';
import { callGetVjoDsEndpoint } from 'redux/VJODrugScreen/slice';

/**
 * Custom hook to handle Drug Screen info in VJO
 */

export const useDrugScreenInfo = () => {
	const dispatch = useDispatch();

	const scheduleCompletedResponse = useSelector(
		(state) => state?.vjoDrugScreen?.data?.scheduleCompleted
	);
	const drugScreenCheck = useSelector((state) => state?.vjoDrugScreen?.data?.drugScreenCheck);
	const isDrugScreenRequiredResponse = useSelector(
		(state) => state?.vjoDrugScreen?.data?.isDrugScreenRequiredResponse
	);
	const shouldCallGetVjoDsEndpoint = useSelector(
		(state) => state?.vjoDrugScreen?.data?.shouldCallGetVjoDsEndpoint
	);

	const drugScreenRequiredResponse = useMemo(() => {
		if (_.isEmpty(isDrugScreenRequiredResponse)) {
			return null;
		}
		const { drugScreenRequired, drugScreenStatus, expirDate } = isDrugScreenRequiredResponse;
		return {
			drugScreenRequired,
			drugScreenStatus,
			expirDate
		};
	}, [isDrugScreenRequiredResponse]);

	const account = useSelector((state) => state.account);

	const getScheduleCompletedResponse = useCallback(
		(scheduleAction, eventId) => {
			if (scheduleAction && eventId) {
				dispatch(getScheduleCompleted({ scheduleAction, eventId }));
			}
		},
		[dispatch]
	);

	const initiateCandidateDrugScreenResponse = useCallback(() => {
		const { applicantId, storeNbr, reqsnId } = account?.data?.auth?.vjo;
		const isSupplyChain = account?.data?.auth?.supplyChain;
		if (applicantId && storeNbr && reqsnId) {
			dispatch(
				initiateCandidateDrugScreen({
					storeNbr: storeNbr,
					requestInput: {
						storeNbr: storeNbr,
						applicantId: applicantId,
						requisitionId: reqsnId,
						virtualJobOfferFlag: false,
						isSupplyChain: isSupplyChain
					}
				})
			);
		}
	}, [account, dispatch]);

	const getVJOisDrugScreenRequiredResponse = useCallback(
		(stepInd = 0) => {
			const { applicantId, storeNbr, reqsnId, jobOfferNbr } = account?.data?.auth?.vjo;
			const isSupplyChain = account?.data?.auth?.supplyChain;
			if (applicantId && storeNbr && reqsnId && jobOfferNbr) {
				dispatch(
					isDrugScreenRequired({
						storeNbr: storeNbr,
						isSupplyChain: isSupplyChain,
						requestInput: {
							storeNbr: storeNbr,
							applicantId: applicantId,
							requisitionId: reqsnId,
							jobOfferNbr: jobOfferNbr,
							stepInd: stepInd
						}
					})
				);
			}
		},
		[account, dispatch]
	);

	const callGetVjoDsEndpt = useCallback(
		(val) => {
			dispatch(callGetVjoDsEndpoint(val));
		},
		[dispatch]
	);

	return {
		getScheduleCompletedResponse,
		initiateCandidateDrugScreenResponse,
		getVJOisDrugScreenRequiredResponse,
		callGetVjoDsEndpt,
		scheduleCompletedResponse,
		drugScreenCheck,
		drugScreenRequiredResponse,
		shouldCallGetVjoDsEndpoint,
		account
	};
};
