//This file determines the properties from the .env file based on the hostname.
// we can get the hostname by using 'window && window.location && window.location.hostname' in dev console

const hostname = window && window.location && window.location.hostname; // It gets the hostname where the application is running

const servicUrlConfig = {
	hcServiceBaseGetUrl: process.env.REACT_APP_hcServiceBaseGetUrl,
	hcServiceBasePostUrl: process.env.REACT_APP_hcServiceBasePostUrl,

	recruitingServiceBaseGetUrl: process.env.REACT_APP_recruitingServiceBaseGetUrl,
	recruitingServiceBasePostUrl: process.env.REACT_APP_recruitingServiceBasePostUrl,

	selfScheduleServiceBaseGetUrl: process.env.REACT_APP_selfScheduleServiceBaseGetUrl,
	selfScheduleServiceBasePostUrl: process.env.REACT_APP_selfScheduleServiceBasePostUrl,

	securityServiceBaseGetUrl: process.env.REACT_APP_securityServiceBaseGetUrl,
	securityServiceBasePostUrl: process.env.REACT_APP_securityServiceBasePostUrl,

	eaPreferenceServiceBaseGetUrl: process.env.REACT_APP_eaPreferenceServiceBaseGetUrl,
	eaPreferenceServiceBasePostUrl: process.env.REACT_APP_eaPreferenceServiceBasePostUrl
};

const localDevlopmentServicUrlConfig = {
	hcServiceBaseGetUrl: process.env.REACT_APP_hcServiceLocalDevUrl,
	hcServiceBasePostUrl: process.env.REACT_APP_hcServiceLocalDevUrl,

	recruitingServiceBaseGetUrl: process.env.REACT_APP_recruitingServiceLocalDevUrl,
	recruitingServiceBasePostUrl: process.env.REACT_APP_recruitingServiceLocalDevUrl,

	selfScheduleServiceBaseGetUrl: process.env.REACT_APP_selfScheduleServiceLocalDevUrl,
	selfScheduleServiceBasePostUrl: process.env.REACT_APP_selfScheduleServiceLocalDevUrl,

	securityServiceBaseGetUrl: process.env.REACT_APP_securityServiceLocalDevUrl,
	securityServiceBasePostUrl: process.env.REACT_APP_securityServiceLocalDevUrl,

	eaPreferenceServiceBaseGetUrl: process.env.REACT_APP_eaPreferenceServiceLocalDevUrl,
	eaPreferenceServiceBasePostUrl: process.env.REACT_APP_eaPreferenceServiceLocalDevUrl
};
const getEnvironmentDetails = () => {
	if (
		hostname === process.env.REACT_APP_hostname_dev ||
		hostname === process.env.REACT_APP_hostname_dev_CNAME ||
		hostname === process.env.REACT_APP_hostname_dev_EXT
	) {
		//Dev
		return {
			envName: process.env.REACT_APP_envname_dev,
			appname: process.env.REACT_APP_name_dev,
			isPingfed:
				hostname === process.env.REACT_APP_hostname_dev_EXT
					? process.env.REACT_APP_pingfed_dev_EXT
					: process.env.REACT_APP_pingfed_dev,
			accessCode: process.env.REACT_APP_accesscode_dev,
			pingFedLoginUrl: process.env.REACT_APP_ping_login_url_dev,
			pingFedLogoutUrl: process.env.REACT_APP_ping_logout_url_dev,
			onboardHomeUrl: process.env.REACT_APP_onboardhome_url_dev,
			onboardPingUrl: process.env.REACT_APP_onboardping_url_dev,
			...servicUrlConfig
		};
	} else if (hostname === process.env.REACT_APP_hostname_QA) {
		//QA
		return {
			envName: process.env.REACT_APP_envname_QA,
			appname: process.env.REACT_APP_name_QA,
			isPingfed: process.env.REACT_APP_pingfed_QA,
			accessCode: process.env.REACT_APP_accesscode_QA,
			pingFedLoginUrl: process.env.REACT_APP_ping_login_url_QA,
			pingFedLogoutUrl: process.env.REACT_APP_ping_logout_url_QA,
			onboardHomeUrl: process.env.REACT_APP_onboardhome_url_QA,
			onboardPingUrl: process.env.REACT_APP_onboardping_url_QA,
			...servicUrlConfig
		};
	} else if (
		hostname === process.env.REACT_APP_hostname_stage ||
		hostname === process.env.REACT_APP_hostname_stage_CNAME ||
		hostname === process.env.REACT_APP_hostname_stage_EXT
	) {
		//Stage
		return {
			envName: process.env.REACT_APP_envname_stage,
			appname: process.env.REACT_APP_name_stage,
			isPingfed:
				hostname === process.env.REACT_APP_hostname_stage_EXT
					? process.env.REACT_APP_pingfed_stage_EXT
					: process.env.REACT_APP_pingfed_stage,
			accessCode: process.env.REACT_APP_accesscode_stage,
			pingFedLoginUrl: process.env.REACT_APP_ping_login_url_stage,
			pingFedLogoutUrl: process.env.REACT_APP_ping_logout_url_stage,
			onboardHomeUrl: process.env.REACT_APP_onboardhome_url_stage,
			onboardPingUrl: process.env.REACT_APP_onboardping_url_stage,
			...servicUrlConfig
		};
	} else if (
		hostname === process.env.REACT_APP_hostname_preprod ||
		hostname === process.env.REACT_APP_hostname_preprod_CNAME
	) {
		//Preprod
		return {
			envName: process.env.REACT_APP_envname_preprod,
			appname: process.env.REACT_APP_name_preprod,
			isPingfed: process.env.REACT_APP_pingfed_preprod,
			accessCode: process.env.REACT_APP_accesscode_preprod,
			pingFedLoginUrl: process.env.REACT_APP_ping_login_url_preprod,
			pingFedLogoutUrl: process.env.REACT_APP_ping_logout_url_preprod,
			onboardHomeUrl: process.env.REACT_APP_onboardhome_url_preprod,
			onboardPingUrl: process.env.REACT_APP_onboardping_url_preprod,
			...servicUrlConfig
		};
	} else if (
		hostname === process.env.REACT_APP_hostname_prod ||
		hostname === process.env.REACT_APP_hostname_prod_CNAME ||
		hostname === process.env.REACT_APP_hostname_prod_EXT
	) {
		//Prod
		return {
			envName: process.env.REACT_APP_envname_prod,
			appname: process.env.REACT_APP_name_prod,
			isPingfed:
				hostname === process.env.REACT_APP_hostname_prod_EXT
					? process.env.REACT_APP_pingfed_prod_EXT
					: process.env.REACT_APP_pingfed_prod,
			accessCode: process.env.REACT_APP_accesscode_prod,
			pingFedLoginUrl: process.env.REACT_APP_ping_login_url_prod,
			pingFedLogoutUrl: process.env.REACT_APP_ping_logout_url_prod,
			onboardHomeUrl: process.env.REACT_APP_onboardhome_url_prod,
			onboardPingUrl: process.env.REACT_APP_onboardping_url_prod,
			...servicUrlConfig
		};
	} else if (
		hostname === process.env.REACT_APP_hostname_qaops ||
		hostname === process.env.REACT_APP_hostname_qaops_CNAME ||
		hostname === process.env.REACT_APP_hostname_qaops_EXT
	) {
		//Qaops
		return {
			envName: process.env.REACT_APP_envname_qaops,
			appname: process.env.REACT_APP_name_qaops,
			isPingfed:
				hostname === process.env.REACT_APP_hostname_qaops_EXT
					? process.env.REACT_APP_pingfed_qaops_EXT
					: process.env.REACT_APP_pingfed_qaops,
			accessCode: process.env.REACT_APP_accesscode_qaops,
			pingFedLoginUrl: process.env.REACT_APP_ping_login_url_qaops,
			pingFedLogoutUrl: process.env.REACT_APP_ping_logout_url_qaops,
			onboardHomeUrl: process.env.REACT_APP_onboardhome_url_qaops,
			onboardPingUrl: process.env.REACT_APP_onboardping_url_qaops,
			...servicUrlConfig
		};
	} else {
		return {
			//Localhost or default
			envName: process.env.REACT_APP_envname_default,
			appname: process.env.REACT_APP_name_default,
			isPingfed: process.env.REACT_APP_pingfed_default,
			accessCode: process.env.REACT_APP_accesscode_default,
			pingFedLoginUrl: process.env.REACT_APP_ping_login_url_default,
			pingFedLogoutUrl: process.env.REACT_APP_ping_logout_url_default,
			onboardHomeUrl: process.env.REACT_APP_onboardhome_url_default,
			onboardPingUrl: process.env.REACT_APP_onboardping_url_default,
			...localDevlopmentServicUrlConfig
		};
	}
};

//Exporting the properties as object

export const environment = getEnvironmentDetails();

export const environmentName = environment.envName;

export const accessCode = environment.accessCode;
n