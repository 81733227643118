import React from 'react';
import { useSelector } from 'react-redux';
import { Body, Button } from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import AlertDialog from 'components/AlertDialog';

const JobPreferenceAlert = (props) => {
	const { t } = useTranslation();
	const { isNoJobPreference } = useSelector((state) => state?.renew);

	const postRenewActions = () => {
		return (
			<div>
				<Button
					onClick={props.handleNo}
					size="medium"
					variant="tertiary"
					UNSAFE_style={{ padding: '0 32px' }}>
					{t('general.no')}
				</Button>
				<Button onClick={props.handleYes} size="medium" variant="primary">
					{t('general.yes')}
				</Button>
			</div>
		);
	};
	return (
		<div>
			<AlertDialog
				isOpen={props.renewCompleted}
				onClose={() => props.handleClose()}
				title={t('renew.successRenew')}
				actions={postRenewActions()}>
				<Body as="div" size="medium" weight={400}>
					{isNoJobPreference ? t('renew.noJobPref') : t('renew.dialogTitleRenew')}
				</Body>
			</AlertDialog>
		</div>
	);
};

export default JobPreferenceAlert;
