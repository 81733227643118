import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { subHeader } from '../../../redux/slices/globalSlice';
import style from './PasswordMain.module.scss';
import { isEmpty } from '../../../utils/validation';

const PasswordMain = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const location = useLocation();
	const { encryptedId } = useParams();

	useEffect(() => {
		if (location.pathname.includes('verifyEmailLink') && isEmpty(encryptedId)) {
			navigate('../login');
		}
		dispatch(subHeader({ title: '', routeTo: '', display: false }));
	}, [location, encryptedId, dispatch, navigate]);

	return (
		<div className={style.container}>
			<div className={style.container__heading}>
				<div className={style.container__heading__content}>
					<div className={style.title}>
						<div className={style.subtitle}>
							<img
								src={require('../../../assets/images/walmart-title.png')}
								alt="Walmart logo"
								className={style.logo}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={style.container__heading__blue}></div>
			<div className={style.container__body}>
				<div className={style.container__body__content}>{props.children}</div>
			</div>
		</div>
	);
};

export default PasswordMain;
