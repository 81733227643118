import React from 'react';
import { Body, Caption, Tag } from '@walmart-web/livingdesign-components';
import style from './GenericLocationCard.module.css';
import { Store, Truck } from '@livingdesign/icons';
import { useTranslation } from 'react-i18next';

const GenericLocationCard = ({ store, disabled }) => {
	const { t } = useTranslation();
	const { storeName, storeNumber } = store;
	const storeTitle = storeName.includes('#') ? storeName : `${storeName} #${storeNumber}`;

	return (
		<div role="group" aria-label={t('ariaLabelTexts.storeInformation')}>
			<Body as="div" size="small" weight={700} role="heading" aria-label={t('ariaLabelTexts.storeTitle')}>
				{' '}
				{storeTitle}{' '}
			</Body>
			{/* Other Co-Located Facilities casing alone wasn't UpperCase - hence added to have it uniform for all */}
			<Caption as="div" size="small" weight={400} role="text" aria-label= {t('ariaLabelTexts.storeAddress')}>
				{' '}
				{store.storeAddress.replace(
					/(^\w|\s\w)(\S*)/g,
					(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
				)}
				,{' '}
			</Caption>
			<Caption as="div" size="small" weight={400} role="text" aria-label={t('ariaLabelTexts.cityStateAndZip')}>
				{' '}
				{`${store.storeCity.replace(
					/(^\w|\s\w)(\S*)/g,
					(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
				)}, ${store.storeState} ${store.storeZip ? store.storeZip : store.postalCode}`}{' '}
			</Caption>
			<div className={style.icon} role="img" aria-label={t('ariaLabelTexts.storeIcon')}>
				{store.sc ? (
					<Tag
						color="purple"
						leading={<Truck />}
						variant="secondary"
						UNSAFE_className={disabled && style.disabledTag}
						aria-label={t('ariaLabelTexts.warehouseTag')}
						>
						{' '}
						{t('general.warehouse')}{' '}
					</Tag>
				) : (
					<Tag
						color="blue"
						leading={<Store />}
						variant="secondary"
						UNSAFE_className={disabled && style.disabledTag}
						aria-label={t('ariaLabelTexts.storeTag')}
						>
						{' '}
						{t('general.store')}{' '}
					</Tag>
				)}
			</div>
		</div>
	);
};

export default GenericLocationCard;
