import {
	forgotPassword,
	resetPassword,
	validateResetPasswordLink
} from '../../../redux/Account/request';
import { updateResetPassword } from '../../../redux/Account/slice';
import { errorOccured, updateLoading } from '../../../redux/slices/globalSlice';
import {
	isEmptyObject,
	validateConfirmPassword,
	validateEmail,
	validatePassword
} from '../../../utils/validation';

const createAccountSystemErrorText = 'general.systemErrorText';
const tryLater = 'general.somethingWentWrong';
const errorUrl = '/HiringCenter/error';
const createAccountLinkExpired = 'createAccount.linkExpired';
const sorry = 'general.sorry';
const errorTxt = 'general.error';

export const onSubmitRecoverPassword = (request, error, setError, navigate) => (dispatch) => {
	validateEmail(request.emailId, error, setError);
	if (isEmptyObject(error)) {
		//make Api call for the create the account
		dispatch(updateLoading(true));
		dispatch(forgotPassword(request))
			.unwrap()
			.then(
				(result) =>
					new Promise((resolve, reject) => {
						if (result.responseCode === '0') {
							dispatch(
								updateResetPassword({
									resetEmail: request.emailId
								})
							);
							resolve(dispatch(updateLoading(false)));
							navigate('../sendVerifyEmail/1');
						} else if (
							result.responseCode === '106' ||
							result.responseCode === '110' ||
							result.responseCode === '115'
						) {
							resolve(
								setError({
									...error,
									email:
										result.responseCode === '106'
											? 'userNameNotExists'
											: result.responseCode === '110'
											? 'emailNotVerified'
											: 'waitForResetPasswordLink'
								})
							);
							dispatch(updateLoading(false));
						} else {
							reject(
								new Error(result.responseMessage, {
									cause: result
								})
							);
						}
					})
			)
			.catch((err) => {
				const errorObj = {
					open: false,
					title: err?.cause?.response?.data?.errorCode === 500 ? errorTxt : sorry,
					message:
						err?.cause?.response?.data?.errorCode === 500
							? createAccountSystemErrorText
							: tryLater,
					url: errorUrl,
					canBeTranslated : true
				};
				dispatch(updateLoading(false));
				dispatch(errorOccured(errorObj));
			});
	}
};

export const validateResetPasswordUrl =
	(encryptedId, navigate, setIsValidResetPasswordLink) => (dispatch) => {
		dispatch(updateLoading(true));
		dispatch(
			validateResetPasswordLink({
				encryptedId: encryptedId,
				uniqueApplicationId: 'walMarT-Store-HirinG'
			})
		)
			.unwrap()
			.then(
				(result) =>
					new Promise((resolve, reject) => {
						if (result.responseCode === '0') {
							setIsValidResetPasswordLink(true);
							resolve(dispatch(updateLoading(false)));
						} else {
							reject(
								new Error(result.responseMessage, {
									cause: result
								})
							);
						}
					})
			)
			.catch((err) => {
				const errorObj = {
					open: false,
					title: err?.cause?.response?.data?.errorCode === 500 ? errorTxt : sorry,
					message:
						err?.cause?.responseCode === '118'
							? createAccountLinkExpired
							: err?.cause?.response?.data?.errorCode === 500
							? createAccountSystemErrorText
							: tryLater,
					url: errorUrl,
					canBeTranslated : true
				};
				dispatch(updateLoading(false));
				dispatch(errorOccured(errorObj));
			});
	};

export const onSubmitResetPassword = (request, error, setError, navigate) => (dispatch) => {
	validatePassword(request.password, error, setError);
	validateConfirmPassword(request.confirmPassword, request.password, error, setError);
	if (isEmptyObject(error)) {
		//make Api call for the create the account
		dispatch(updateLoading(true));
		dispatch(resetPassword(request))
			.unwrap()
			.then(
				(result) =>
					new Promise((resolve, reject) => {
						if (result.responseCode === '0') {
							resolve(dispatch(updateLoading(false)));
							navigate('/passwordSuccess', { replace: true });
						} else if (result.responseCode === '109') {
							reject(setError({ ...error, pdErrMsg: 'emailAlreadyVerified' }));
						} else {
							reject(
								new Error(result.responseMessage, {
									cause: result
								})
							);
						}
					})
			)
			.catch((err) => {
				const errorObj = {
					open: false,
					title:  err?.cause?.response?.data?.errorCode === 500 ? errorTxt : sorry,
					message:
						err?.cause?.responseCode === '118'
							? createAccountLinkExpired
							: err?.cause?.response?.data?.errorCode === 500
							? createAccountSystemErrorText
							: tryLater,
					url: errorUrl,
					canBeTranslated : true
				};
				dispatch(errorOccured(errorObj));
				dispatch(updateLoading(false));
			});
	}
};
