import React, { useState } from 'react';
import { Caption } from '@walmart-web/livingdesign-components';
import './style.scss';
import { useTranslation } from 'react-i18next';

const RatingButtonGroup = (props) => {
  const { t } = useTranslation();
  const { onClick } = props;
  const [selectedButton, setSelectedButton] = useState(-1);
  const createRatingButton = (title, selected) => {
    return (
      <div
        id={title}
        key={title}
        className={`rating_button_${
          selected.toString() === title.toString() ? 'selected' : 'unselected'
        }`}
        onClick={(e) => handleButtonClick(e)}
      >
        {title}
      </div>
    );
  };

  const handleButtonClick = (e) => {
    setSelectedButton(e.target.id);
    onClick(e.target.id);
  };

  return (
    <>
      <div className="rating_buttongroup_container">
        {[...Array(props.maxValue)].map((value, index) => {
          return createRatingButton(index + 1, selectedButton);
        })}
      </div>
      <div className="rating_info_container">
        <Caption as="span" UNSAFE_style={{color:"#74767C"}}>1 = {props.intialValue}</Caption>
        <Caption as="span" UNSAFE_style={{color:"#74767C"}}>{props.maxValue} = {props.finalValue}</Caption> 
      </div>
    </>
  );
};

export default RatingButtonGroup;
