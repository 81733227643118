import { Card, Heading, Body } from '@walmart-web/livingdesign-components';
import React, { useEffect, useMemo } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useReviewDocuSign } from 'pages/VirtualJobOffer/hooks/useReviewDocuSign';
import { useVirtualJobOffer } from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import { STEPS } from 'pages/VirtualJobOffer/util';

/**
 * Renders the Step4: Acknowledge for the Portrait and Desktop views.
 */

const AcknowledgeOffer = (props) => {
	const { t } = useTranslation();
	const { checkReviewDocuSignResponse, docuSignResp } = useReviewDocuSign();
	const {
		fetchFinalizeJobOffer,
		updateFinalizingJobOffer,
		callGetStepInd,
		isFinalizingJobOffer,
		isJobOfferFinalized,
		nextStepInd
	} = useVirtualJobOffer();

	useEffect(() => {
		window.scroll(0, 0);
	 },[])

	useEffect(() => {
		if (nextStepInd === STEPS.ACKNOWLEDGE + 1 || nextStepInd === STEPS.ACKNOWLEDGE_DECLINED + 1) {
			if (!docuSignResp) {
				checkReviewDocuSignResponse();
			}
			if (docuSignResp) {
				props.handleChangeCurrentStepStatus(true);
			}
		}
	}, [docuSignResp, nextStepInd]);

	useEffect(() => {
		if (isFinalizingJobOffer) {
			fetchFinalizeJobOffer();
			updateFinalizingJobOffer(false);
		}
	}, [isFinalizingJobOffer]);

	useEffect(() => {
		if (nextStepInd === STEPS.ACKNOWLEDGE + 1) {
			if (isJobOfferFinalized) {
				props.handleChangeNextStep();
				callGetStepInd();
			}
		}
	}, [isJobOfferFinalized, nextStepInd]);

	const handleContent = useMemo(() => {
		if (
			docuSignResp?.response?.envelopeStatus === 'accepted' ||
			nextStepInd === STEPS.ACKNOWLEDGE + 1 ||
			nextStepInd > STEPS.ACKNOWLEDGE_DECLINED + 1
		) {
			return <Body children={t('virtualJobOffer.acceptedMsg')} size="medium" weight={700} />;
		} else if (
			docuSignResp?.response?.envelopeStatus === 'declined' ||
			nextStepInd === STEPS.ACKNOWLEDGE_DECLINED + 1
		) {
			return (
				<>
					<Body children={t('virtualJobOffer.declineMsg')} size="medium" weight={700} />
					<Body
						children={t('virtualJobOffer.declineMsgGoBack')}
						size="small"
						weight={400}
						UNSAFE_className="verify_acknowledge_go_back_msg"
					/>
				</>
			);
		}
	}, [t, docuSignResp?.response?.envelopeStatus]);

	return (
		<Card UNSAFE_className="acknowledge_card_container">
			<Heading children={t('virtualJobOffer.ackOffer')} size="small" />
			<Body
				as="p"
				children={t('virtualJobOffer.confirmDocuSign')}
				size="medium"
				UNSAFE_className="verify_acknowledge_info_description"
			/>
			<Card UNSAFE_className="verify_acknowledge_container">
				<Body children={t('virtualJobOffer.selectedFollowing')} size="medium" />
				{handleContent}
			</Card>
		</Card>
	);
};

export default AcknowledgeOffer;
