import { Body } from '@walmart-web/livingdesign-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './TaxQuestionnaire.module.scss';

const Preamble = () => {
	const { t } = useTranslation();
	const generalPoints = ['wotc.description', 'wotc.generalDisclaimer.title'];
	const generalListPoints = t('wotc.generalDisclaimer.points', { returnObjects: true });
	const languagePoints = [
		'wotc.languageDisclaimer.title',
		'wotc.languageDisclaimer.description',
		'wotc.getStarted'
	];

	return (
		<div className={style.question}>
			{generalPoints.map((label) => (
				<Body key={`preamble-general-${label}`} size="medium" weight={400} as="span">
					{t(label)}
				</Body>
			))}
			<ul>
				{generalListPoints.map((label, index) => (
					<li key={`${label + "$" + index}`}>
						<Body>{label}</Body>
					</li>
				))}
			</ul>
			{languagePoints.map((label) => (
				<Body key={`preamble-language-${label}`} size="medium" weight={400} as="span">
					{t(label)}
				</Body>
			))}
		</div>
	);
};

export default Preamble;
