import celebration_rectangle_blue from '../../../assets/images/celebrationWithHands.png';
import React, { useEffect, useState } from 'react';
import style from '../Assessment.module.css';
import { t } from 'i18next';
import { Button } from '@walmart-web/livingdesign-components';
import useAssessment from '../hooks/useAssessment';
import Confetti from 'react-confetti';

function ApplicationSubmitted() {
	const { handleGoToDashboard } = useAssessment();
	const width = window.innerWidth;
	const height = window.innerHeight;

	const [numberOfPieces, setNumberOfPieces] = useState(300);

	useEffect(() => {
		setTimeout(() => setNumberOfPieces(0), 4000);
	});
	return (
		<div className={style.application_submitted}>
			<Confetti width={width} height={height} numberOfPieces={numberOfPieces} />
			<img
				alt="celebration hand"
				src={celebration_rectangle_blue}
				className={style.celebration_img}
			/>
			<div className={style.modal_text}>
				<p className={style.modal_title}>{t('assessment.applicationSubmitted')}</p>
				<p className={style.modal_subtext}> {t('assessment.subText')} </p>
				<ul style={{ paddingInlineStart: '24px' }}>
					<li>
						<p className={style.modal_subtext}> {t('assessment.subTextBullet1')} </p>
					</li>
					<li>
						<p className={style.modal_subtext}> {t('assessment.subTextBullet2')} </p>
					</li>
					<li>
						<p className={style.modal_subtext}> {t('assessment.subTextBullet3')} </p>
					</li>
				</ul>
				<p className={style.modal_subtext}> {t('assessment.subText2')} </p>
				<div className={style.go_to_dashboard_button_container}>
					<Button
						variant="primary"
						isFullWidth
						onClick={handleGoToDashboard}
						style={{ alignItems: 'center' }}
						className={style.go_to_dashboard_button}>
						{t('assessment.goToDashboard')}
					</Button>
				</div>
			</div>
		</div>
	);
}

export default ApplicationSubmitted;
