import {
	Alert,
	Body,
	Button,
	Caption,
	Chip,
	ChipGroup,
	Divider
} from '@walmart-web/livingdesign-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import rightArrow from '../../../../assets/images/remove.svg';
import { useTranslation } from 'react-i18next';
import GenericLocationCard from 'components/GenericLocationCard/GenericLocationCard';
import './style.scss';
import { Store, Truck } from '@livingdesign/icons';
import _ from 'lodash';
import { saveJobPreferance, jobPanelError, noJobPreference } from 'redux/Renew/slice';
import { getRenewInfo } from 'redux/Renew/request';

function JobPreference(props, { vjoHandler }) {
	const dispatch = useDispatch();
	const [filterStores, setfilterStores] = useState([true, false, false]);
	const [storeList, setAllStoreList] = useState([]);
	const [jobList, setAllJobList] = useState([]);
	const [shStoreList, setShStoreList] = useState([]);
	const [scStoreList, setSCStoreList] = useState([]);
	const [shJobList, setSHJobList] = useState([]);
	const [scJobList, setSCJobList] = useState([]);
	const [deletedStoreList, setDeletedStoreList] = useState([]);
	const [deletedJobList, setDeletedJobList] = useState([]);
	const [showAlert, setShowAlert] = useState(false);
	const { t } = useTranslation();
	const global = useSelector((state) => state.global);
	const { renewInfo, isRenewPanelClicked } = useSelector((state) => state.renew);

	useEffect(() => {
		if (renewInfo?.data?.storeListSH || renewInfo?.data?.storeListSC) {
			setShStoreList([...renewInfo?.data?.storeListSH]);
			setSCStoreList([...renewInfo?.data?.storeListSC]);

			renewInfo.data.storeListSH.forEach((store) => {
				if (!store.removable) {
					setShowAlert(true);
				}
			});
			renewInfo.data.storeListSC.forEach((store) => {
				if (!store.removable) {
					setShowAlert(true);
				}
			});
		}
		if (renewInfo?.data?.jobListSC || renewInfo?.data?.jobListSH) {
			setSCJobList(renewInfo?.data?.jobListSC);
			setSHJobList(renewInfo?.data?.jobListSH);
		}
	}, [renewInfo]);

	useEffect(() => {
		dispatch(getRenewInfo(global.applicantId));
	}, [global.applicantId, dispatch]);

	useEffect(() => {
		if (filterStores[0]) {
			setAllStoreList([...shStoreList, ...scStoreList]);
			setAllJobList([...shJobList, ...scJobList]);
		} else if (filterStores[1]) {
			setAllStoreList(shStoreList);
			setAllJobList(shJobList);
		} else if (filterStores[2]) {
			setAllStoreList(scStoreList);
			setAllJobList(scJobList);
		}
	}, [filterStores, scJobList, scStoreList, shJobList, shStoreList]);

	useEffect(() => {
		validateJobPanel();
		// eslint-disable-next-line
	}, [props.expanded]);

	useEffect(() => {
		validateJobPanel();
		// eslint-disable-next-line
	}, [deletedStoreList, deletedJobList]);

	const validateJobPanel = () => {
		if (isRenewPanelClicked.panel3 === true && props.expanded === 'panel3') {
			handleSaveJobPref();
		}
	};

	const handleStoreRemove = (store) => {
		const storeNbr = store?.storeNumber;
		if (deletedStoreList.includes(storeNbr)) {
			const filterdList = deletedStoreList.filter((sNo) => sNo !== storeNbr);
			setDeletedStoreList(filterdList);
		} else {
			setDeletedStoreList([...deletedStoreList, storeNbr]);
		}
	};

	const handleJobRemove = (job) => {
		const jobId = job?.hcJobClassId;

		if (deletedJobList.includes(jobId)) {
			const filterdList = deletedJobList.filter((jId) => jId !== jobId);
			setDeletedJobList(filterdList);
		} else {
			setDeletedJobList([...deletedJobList, jobId]);
		}
	};
	const checkIfCountryCode = useCallback(() => {
		const countryCodes = jobList?.map((ele) => ele.countryCode);
		return countryCodes.indexOf('US') > -1 && countryCodes.indexOf('PR') > -1;
	}, [jobList]);

	const isInDeletedList = useCallback(
		(item) => {
			return deletedStoreList?.includes(item) || deletedJobList?.includes(item);
		},
		[deletedStoreList, deletedJobList]
	);

	const handleStoreTypeChange = (key) => {
		if (key === 1) {
			setfilterStores([true, false, false]);
		} else if (key === 2) {
			setfilterStores([false, true, false]);
		} else if (key === 3) {
			setfilterStores([false, false, true]);
		}
	};
	const getStoreListItems = useCallback(() => {
		if (!storeList || storeList?.length === 0) {
			return null;
		}
		return storeList?.map((store, index) => {
			return (
				<div key={store.storeNumber + '-' + index} className="renew_pref_container">
					<div
						className={`renew_location_wrapper ${
							store?.removable ? '' : 'renew_list_item_disabled'
						}`}>
						<GenericLocationCard store={store} disabled={!store?.removable} />
						{store?.removable && (
							<>
								{isInDeletedList(store?.storeNumber) ? (
									<Button size="small" variant="secondary" onClick={() => handleStoreRemove(store)}>
										{t('renew.add')}
									</Button>
								) : (
									<span className="renew_delete_btn_wrapper">
										<Button
											trailing={
												<img
													style={{
														filter: 'sepia(100%) saturate(300%) brightness(70%) hue-rotate(180deg)'
													}}
													src={rightArrow}
													alt=""
												/>
											}
											size="small"
											disabled={!store?.removable}
											variant="tertiary"
											onClick={() => handleStoreRemove(store)}>
											{t('renew.remove')}
										</Button>
									</span>
								)}
							</>
						)}
					</div>
					{storeList?.length - 1 !== index ? <Divider /> : null}
				</div>
			);
		});
		// eslint-disable-next-line
	}, [storeList, deletedStoreList]);

	const getJobListItem = useCallback(() => {
		if (!jobList || jobList?.length === 0) {
			return null;
		}
		return jobList?.map((jobListItem, index) => (
			<div key={jobListItem.storeNumber + '-' + index} className="renew_pref_container">
				<div
					className={`renew_list_item row m ${
						jobListItem?.removable ? '' : 'renew_list_item_disabled'
					}`}>
					<Body UNSAFE_className="renew_pref_job_title" size="small" weight={700}>
						{jobListItem.jobdescMap[global.language === 'ES' ? '102' : '101']}
						<Caption as="span">
							{checkIfCountryCode() && jobListItem.countryCode ? (
								<span> ({jobListItem.countryCode})</span>
							) : null}
						</Caption>
					</Body>
					{jobListItem?.removable && (
						<>
							{isInDeletedList(jobListItem?.hcJobClassId) ? (
								<span className="">
									<Button
										size="small"
										variant="secondary"
										onClick={() => handleJobRemove(jobListItem)}>
										{t('renew.add')}
									</Button>
								</span>
							) : (
								<span className="renew_delete_btn_wrapper">
									<Button
										trailing={<img alt="" src={rightArrow} />}
										size="small"
										variant="tertiary"
										disabled={!jobListItem?.removable}
										UNSAFE_style={{ padding: '0 !important', height: '20px' }}
										onClick={() => handleJobRemove(jobListItem)}>
										{t('renew.remove')}
									</Button>
								</span>
							)}
						</>
					)}
				</div>
			</div>
		));
		// eslint-disable-next-line
	}, [jobList, deletedJobList, global.language]);
	const storeFilter = useCallback(() => {
		return (
			<ChipGroup UNSAFE_className={'renew_store_type'}>
				<Chip
					selected={filterStores[0]}
					onClick={() => handleStoreTypeChange(1)}
					className={filterStores[0] ? 'chips_style' : {}}>
					{t('general.all')}
				</Chip>
				<Chip
					leading={<Store size="small" />}
					selected={filterStores[1]}
					onClick={() => handleStoreTypeChange(2)}
					className={filterStores[1] ? 'chips_style' : {}}>
					{t('general.stores')}
				</Chip>
				<Chip
					leading={<Truck size="small" />}
					selected={filterStores[2]}
					onClick={() => handleStoreTypeChange(3)}
					className={filterStores[2] ? 'chips_style' : {}}>
					{t('general.warehouses')}
				</Chip>
			</ChipGroup>
		);
		// eslint-disable-next-line
	}, [filterStores]);

	const getStoreList = (storeList, type) => {
		return storeList
			.filter(function (store) {
				return type === 'PR' ? store.storeState === 'PR' : store.storeState !== 'PR';
			})
			.map(function (store) {
				return store.storeNumber;
			});
	};

	const getJobList = (jobList, type) => {
		return jobList
			.filter(function (job) {
				return type === 'PR' ? job.countryCode === 'PR' : job.countryCode !== 'PR';
			})
			.map(function (job) {
				return job.hcJobClassId;
			});
	};

	const handleSaveJobPref = () => {
		const tempDeletedStoreList = _.cloneDeep(deletedStoreList);
		let tempDeletedJobList = _.cloneDeep(deletedJobList);

		const shUSStoreNbrList = getStoreList(shStoreList, 'US');
		const shPRStoreNbrList = getStoreList(shStoreList, 'PR');
		const scUSStoreNbrList = getStoreList(scStoreList, 'US');
		const scPRStoreNbrList = getStoreList(scStoreList, 'PR');

		const shUSJobClassList = getJobList(shJobList, 'US');
		const shPRJobClassList = getJobList(shJobList, 'PR');
		const scUSJobClassList = getJobList(scJobList, 'US');
		const scPRJobClassList = getJobList(scJobList, 'PR');

		const isAllShUSStoreDeleted =
			shUSStoreNbrList.length !== 0 &&
			shUSStoreNbrList.every((val) => {
				return deletedStoreList.indexOf(val) >= 0;
			});

		const isAllShPRStoreDeleted =
			shPRStoreNbrList.length !== 0 &&
			shPRStoreNbrList.every((val) => {
				return deletedStoreList.indexOf(val) >= 0;
			});

		const isAllScUSStoreDeleted =
			scUSStoreNbrList.length !== 0 &&
			scUSStoreNbrList.every((val) => {
				return deletedStoreList.indexOf(val) >= 0;
			});

		const isAllScPRStoreDeleted =
			scPRStoreNbrList.length !== 0 &&
			scPRStoreNbrList.every((val) => {
				return deletedStoreList.indexOf(val) >= 0;
			});

		if (isAllShUSStoreDeleted) {
			tempDeletedJobList = [
				...tempDeletedJobList,
				...shUSJobClassList.filter((item) => tempDeletedJobList.indexOf(item) < 0)
			];
		}

		if (isAllShPRStoreDeleted) {
			tempDeletedJobList = [
				...tempDeletedJobList,
				...shPRJobClassList.filter((item) => tempDeletedJobList.indexOf(item) < 0)
			];
		}

		if (isAllScUSStoreDeleted) {
			tempDeletedJobList = [
				...tempDeletedJobList,
				...scUSJobClassList.filter((item) => tempDeletedJobList.indexOf(item) < 0)
			];
		}

		if (isAllScPRStoreDeleted) {
			tempDeletedJobList = [
				...tempDeletedJobList,
				...scPRJobClassList.filter((item) => tempDeletedJobList.indexOf(item) < 0)
			];
		}

		const payload = { deletedStoreList: tempDeletedStoreList, deletedJobList: tempDeletedJobList };
		if (typeof props.vjoHandler === 'function') {
			props.vjoHandler(payload);
			return;
		}

		if (shStoreList.concat(scStoreList)?.length === tempDeletedStoreList?.length) {
			dispatch(noJobPreference(true));
		} else {
			dispatch(noJobPreference(false));
		}
		dispatch(jobPanelError(false));
		dispatch(saveJobPreferance(payload));
	};
	return (
		<div className="job_pref_wrapper column">
			{scStoreList && scStoreList.length === 0 && shStoreList && shStoreList.length === 0 ? (
				<Body UNSAFE_className="no_position">{t('renew.noPositions')}</Body>
			) : (
				<>
					<Body size="small">{t('renew.jobPrefCaption')}</Body>
					{shStoreList.length > 0 && scStoreList.length > 0 ? (
						storeFilter()
					) : (
						<>
							<br />
						</>
					)}
					<Body UNSAFE_className="job_pref_note" size="small">
						<strong>{t('renew.note')}</strong> {t('renew.jobPrefNote')}
					</Body>
					{showAlert && <Alert variant="warning">{t('renew.infoMsg')}</Alert>}
					<div className="renew_reponsive_wrapper">
						<div className="facilities_list_wrapper">
							<Body UNSAFE_className="job_pref_heading">{t('renew.facilities')}</Body>
							{getStoreListItems()}
						</div>
						<Body UNSAFE_className="job_pref_seprator"> {t('renew.or')} </Body>
						<div className="facilities_list_wrapper">
							<Body UNSAFE_className="job_pref_heading">{t('renew.positions')}</Body>
							{getJobListItem()}
						</div>
					</div>
					{typeof props.vjoHandler === 'function' && (
						<>
							<Divider />
							<div className="row m renew_action_button">
								<Button size="small" color="#0071DC" variant="primary" onClick={handleSaveJobPref}>
									{t('updatePreference.update')}
								</Button>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}

export default JobPreference;
