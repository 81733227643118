import React from 'react';
import style from '../Assessment.module.css';
import { t } from 'i18next';
import AssessmentItem from './AssessmentItem';
import { Card } from '@walmart-web/livingdesign-components';
import { useSelector } from 'react-redux';

const AssessmentMobileScreen = () => {
	const assessment = useSelector((state) => state.assessment);

	return (
		<div>
			<img
				src={require('../../../assets/images/assessmentCard.png')}
				alt="assessment"
				aria-hidden = {true}
				className={style.assessment_image}
			/>
			<div className={style.bottom_container}>
				<div className={style.assessment_details}>
					<div className={style.assessment_info}>{t('assessment.assessmentInfo')}</div>
					<div className={style.assessment_cards}>
						{assessment.assessmentList.length > 0 &&
							assessment.assessmentList.map((assessment) => {
								if (assessment.assessmentNeed && assessment.assessmentCode !== '') {
									return (
										<Card key={assessment.assessmentCode}>
											<AssessmentItem
												assessmentCode={assessment.assessmentCode}
												statusVal={assessment.statusVal}
												assessmentExpdate={assessment.assessmentExpdate}
											/>
										</Card>
									);
								} else {
									return null;
								}
							})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AssessmentMobileScreen;
