import { Body, Card, Divider, Heading } from '@walmart-web/livingdesign-components';
import { useVirtualJobOffer } from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import React, { memo, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDisplay } from 'utils/useDisplay';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Associate } from '../../../../../assets/images/associate_MaleHoldingDevice.svg';
import { ReactComponent as CelebrationHand } from '../../../../../assets/images/celebration_hand_image.svg';
import { ReactComponent as Apple } from '../../../../../assets/images/apple_store.svg';
import { ReactComponent as Google } from '../../../../../assets/images/google_play.svg';
import './styles.scss';
import { useVjoStepIndicator } from 'pages/VirtualJobOffer/hooks/useVjoStepIndicator';
/**
 * VJO Congratulations view. Used in the VJO landing page
 * @returns - Memoized Congratulations view
 */
const Congratulations = () => {
	const { t } = useTranslation();
	const { state } = useLocation();
	const { isSupplyChain, scPreboarding } = useVirtualJobOffer();
	const { isPortrait } = useDisplay();
	const { updateStepIndicator } = useVjoStepIndicator();

	useEffect(() => {
		window.scroll(0, 0);
	 },[])

	useEffect(() => {
		if (state?.isFromLoginPage) {
			updateStepIndicator();
		}
	}, [state?.isFromLoginPage]);

	const AppStoreImage = () => {
		return (
			<div className="vjo_app_Store">
				<a href="https://play.google.com/store/apps/details?id=com.walmart.squiggly">
					<Google href="https://play.google.com/store/apps/details?id=com.walmart.squiggly" />
				</a>
				<a href="https://apps.apple.com/us/app/me-walmart/id1459898418">
					<Apple href="https://apps.apple.com/us/app/me-walmart/id1459898418?ign-itscg=30200&ign-itsct=apps_box" />
				</a>
			</div>
		);
	};

	// SupplyChainView
	const SupplyChainView = useMemo(() => {
		if (!isSupplyChain) {
			return null;
		}
		return <Body size="medium" children={<Trans>{t('vjoCongratulations.SCcontent')}</Trans>} />;
	}, [isSupplyChain, t]);

	// Congratulations page for both StoreHourly and SupplyChain
	const CongratulationsPageView = useMemo(() => {
		return (
			<>
				<Body size="medium" children={isSupplyChain? <Trans>{t('vjoCongratulations.SCcontent_Preboarding') }</Trans> : <Trans>{t('vjoCongratulations.content')}</Trans>} />
				<Divider />
				<div className="vjo_congrats_sign_in_frame">
					<div className="vjo_congrats_sign_in_left">
						<AppStoreImage />
						<Body
							size="medium"
							children={<Trans>{t('vjoCongratulations.sign_in_content')}</Trans>}
						/>
					</div>
					<div className="vjo_congrats_sing_in_right">
						<div className="vjo_congrats_sign_in_right_child_left">
							<Body
								size="medium"
								children={<Trans>{t('vjoCongratulations.candidate_sign_in')}</Trans>}
							/>
							<Body
								as="div"
								children={t('vjoCongratulations.sign_in')}
								UNSAFE_className="vjo_congrats_sign_in"
								weight={700}
							/>
						</div>
						<Associate className="vjo_congrats_sign_in_right_child_right" />
					</div>
				</div>
			</>
		);
	}, [t]);

	return (
		<React.Fragment>
			<Card size="small" UNSAFE_className="vjo_congrats_body">
				<>
					<CelebrationHand className="celebration_img" />
					<div className="vjo_conatiner">
						<Heading
							size="small"
							weight={700}
							children={t('vjoCongratulations.title')}
						/>
						{isSupplyChain?(scPreboarding? CongratulationsPageView:SupplyChainView) : CongratulationsPageView}
					</div>
				</>
			</Card>
		</React.Fragment>
	);
};

export default memo(Congratulations);
