import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
	data: {},
	dataLoaded: false,
	confirmedSlot: {},
	interviewConfirmed: false,
	seeMore: true,
	displaySeeMore: false,
	showPartialSlots: false,
	responseData: {},
	dateAndTimeSlots: [],
	partialDateAndTimeSlots: [],
	duration: '',
	eventCode: '',
	storeAddress: '',
	bottomSheetOpen: false,
	timeChipSelected: false,
	selectedTimeSlot: '',
	isSelfScheduleSC: false,
	phoneNumber: '',
	isHr: false,
	isOrientation: false,
	isRjpt: false,
	dateIndex: 0,
	dateIndexForTour: null,
	timeIndex: 0,
	isFilled: false,
	encryptedLink: '',
	isDirectlyThankYouScreen: false,
	orientDateAndTimeSlots: [],
	intrDateAndTimeSlots: []
};

const selfScheduleSlice = createSlice({
	name: 'selfschedule',
	initialState,
	reducers: {
		updateDataLoaded: (state, action) => {
			state.dataLoaded = action.payload;
		},
		updateIsDirectlyThankYouScreen: (state, action) => {
			state.isDirectlyThankYouScreen = action.payload;
		},
		updateConfirmedSlot: (state, action) => {
			state.confirmedSlot = action.payload;
		},
		updateInterviewConfirmed: (state) => {
			state.interviewConfirmed = true;
		},
		updateSeeMore: (state, action) => {
			state.seeMore = action.payload;
			state.showPartialSlots = action.payload;
		},
		setResponseData: (state, action) => {
			state.responseData = action.payload;
			state.eventCode = action.payload.eventCode;
			state.storeAddress = action.payload.storeAddress;
			state.phoneNumber = action.payload.applDetails.primaryPhone;
		},
		updateDateTimeSlots: (state, action) => {
			state.isHr = true;
			state.dateAndTimeSlots = action.payload.response;
			let intrDateAndTimeObj = {};
			if (action.payload.response) {
				action.payload.response.map((data) => {
					let timeIntrArr = [];
					if (intrDateAndTimeObj[data.date] !== undefined) {
						let tempArr = intrDateAndTimeObj[data.date];
						timeIntrArr = tempArr.concat(data.timeIntervals);
					} else {
						timeIntrArr = data.timeIntervals;
					}
					intrDateAndTimeObj[data.date] = timeIntrArr;
				});
				state.intrDateAndTimeSlots = intrDateAndTimeObj;
				if (action.payload.response.length > 2) {
					state.displaySeeMore = true;
					state.showPartialSlots = true;
					state.partialDateAndTimeSlots = action.payload.response.slice(0, 2);
				}
			}
		},
		updateDateTimeSlotsRjpt: (state, action) => {
			const { tourResponse, transactionEventCode } = action.payload;
			state.isTour = true;

			let finalObj = [];
			let responseList = tourResponse;

			//eventStartTs: "2021-01-18 09:00:00.0"
			responseList.sort((a, b) => moment(a.eventStartTs) - moment(b.eventStartTs));
			let day = new Date();
			let day1 = new Date(day);
			day1.setDate(day.getDate() + 4);
			responseList &&
				responseList.forEach((obj) => {
					const date = obj.eventStartTs.split(' ')[0];
					if (moment(obj.eventStartTs) >= day && obj.eventCode == transactionEventCode) {
						if (finalObj[date]) {
							finalObj[date].push(obj);
						} else {
							finalObj[date] = [obj];
						}
					}
				});

			state.dateAndTimeSlots = finalObj;
			state.dateIndexForTour = Object.keys(finalObj)[0];
		},
		updateDateTimeSlotsOrientation: (state, action) => {
			state.isOrientation = true;
			let eventList = [];
			let sortedEventObj = [];
			let finalEventList = [];

			Object.keys(action.payload.response.eventMap).map((keyName, keyIndex) => {
				Object.keys(action.payload.response.eventMap[keyName]).map((key, i) => {
					const date = action.payload.response.eventMap[keyName][key].scheduledTs.split(' ')[0];
					if (eventList[date]) {
						eventList[date].push(action.payload.response.eventMap[keyName][key]);
					} else {
						eventList[date] = [action.payload.response.eventMap[keyName][key]];
					}
				});
			});

			sortedEventObj = Object.keys(eventList).sort();
			sortedEventObj.map((obj, i) => {
				if (Object.keys(eventList).includes(obj)) {
					finalEventList[obj] = eventList[obj];
				}
			});

			Object.keys(finalEventList).map((keyName) => {
				let slotArr = finalEventList[keyName];
				const uniqueIds = [];
				slotArr.sort(function (compareOne, compareTwo) {
					let schdlTsf = compareOne?.scheduledTs?.split(' ')[1]?.substring(0, 2);
					let schdlTst = compareTwo?.scheduledTs?.split(' ')[1]?.substring(0, 2);
					return Number(schdlTsf) - Number(schdlTst);
				});
				slotArr.filter((element) => {
					const isDuplicate = uniqueIds.filter((item) => item.time === element.time);
					if (isDuplicate?.length === 0) {
						uniqueIds.push(element);
						return true;
					}
					return false;
				});
				finalEventList[keyName] = uniqueIds;
			});

			let events = [];
			let x = 0;

			for (let index = 0; index < sortedEventObj.length; index++) {
				let element = [];
				element = finalEventList[sortedEventObj[index]];
				var len = element.length;
				console.log(typeof element);

				for (var i = 0; i < len; i++) {
					events[x] = element[i];
					x++;
				}
			}

			state.orientDateAndTimeSlots = finalEventList;
			state.dateAndTimeSlots = events;
		},
		updateDuration: (state, action) => {
			if (action.payload === 11) {
				state.duration = 'selfSchedule.15Minutes';
			} else if (action.payload === 3 || action.payload === 1) {
				state.duration = 'selfSchedule.30Minutes';
			} else if (action.payload === 14 || action.payload === 2) {
				state.duration = 'selfSchedule.orientationDuration';
			} else if (action.payload === 13 || action.payload === 12) {
				state.duration = 'selfSchedule.tourDuration';
			}
		},
		updateTimeChipSelected: (state, action) => {
			state.timeChipSelected = action.payload;
		},
		updateDateIndex: (state, action) => {
			state.dateIndex = action.payload;
		},
		updateDateIndexForTour: (state, action) => {
			state.dateIndexForTour = action.payload;
		},
		updateSelectedSlot: (state, action) => {
			state.selectedTimeSlot = action.payload;
		},
		updateSelectedSlotOrientation: (state, action, dateIndex) => {
			state.selectedTimeSlot = action.payload;
			state.selectedDateIndex = dateIndex;
		},
		updateSelectedSlotTour: (state, action) => {
			const { slot, timeIndex } = action.payload;
			state.selectedTimeSlot = slot;
			state.selectedTimeIndex = timeIndex;
		},
		updateIsSelfScheduleSC: (state, action) => {
			state.isSelfScheduleSC = action.payload;
		},
		updateIsFilled: (state, action) => {
			state.isFilled = action.payload;
		},
		updateEncryptedLink: (state, action) => {
			state.encryptedLink = action.payload;
		}
	}
});

export const {
	updateDataLoaded,
	updateConfirmedSlot,
	updateInterviewConfirmed,
	updateSeeMore,
	setResponseData,
	updateDateTimeSlots,
	updateDateTimeSlotsRjpt,
	updateDateTimeSlotsOrientation,
	updateDuration,
	updateTimeChipSelected,
	updateSelectedSlot,
	updateSelectedSlotOrientation,
	updateIsSelfScheduleSC,
	updateDateIndex,
	updateSelectedSlotTour,
	updateDateIndexForTour,
	updateIsFilled,
	updateEncryptedLink,
	updateIsDirectlyThankYouScreen
} = selfScheduleSlice.actions;

export default selfScheduleSlice.reducer;
