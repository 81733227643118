import React from 'react';
import LocationCard from '../LocationCard/LocationCard';
import './LocationCarousel.css';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { useDispatch, useSelector } from 'react-redux';
import { setToggleStore } from '../../../../redux/Location/slice';

const LocationCarousel = ({ storeList, className }) => {
	const dispatch = useDispatch();
	const disabledScrollCheck = useSelector((state) => state.location.disabledScrollCheck);

	return (
		<div className={`LocationCarousel ${className}`}>
			{storeList.map((store, index) => (
				<ReactVisibilitySensor
					scrollCheck
					onChange={(isVisible) =>
						!disabledScrollCheck && isVisible && dispatch(setToggleStore(store))
					}
					key={index}>
					<div
						className="LocationCarousel__Card"
						key={store.storeNumber}
						id={`LocationCarousel_${store.storeNumber}`}>
						<LocationCard store={store} index={index} />
					</div>
				</ReactVisibilitySensor>
			))}
		</div>
	);
};

export default LocationCarousel;
