import axios from 'axios';
import promise from 'promise';
import { environmentName } from './environment/environment';

var axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    try {
      if (environmentName === 'dev' && window.location.hostname === 'localhost') {
        config.headers = {
          'hc-api-token': '01gyWpGKnDAL3/0BNMJFFRUsQbbFwWQ8DvPCmLN0ToY=',
          'hc-applicant-id': 0
        };
      }
    } catch (err) {
      console.log('Error while axiosInstance......' + err);
    }
    return config;
  },
  (error) => {
    return promise.reject(error);
  }
);

export default axiosInstance;
