import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Body } from '@walmart-web/livingdesign-components';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField as MuiTextField } from '@mui/material';
import { ReactComponent as ErrorIcon } from '../../assets/svg/error.svg';

import styles from './CustomDateField.module.scss';

const CustomDateField = ({
	label,
	defaultValue,
	onChangeCallback,
	onValidationCallback,
	hideError,
	showAdditionalError
}) => {
	const [value, setValue] = useState(defaultValue || '');
	const [error, setError] = useState(false);
	const inputRef = createRef();
	const { t } = useTranslation();

	const DateInValidErrMsg = () => {
		if (!moment(value, 'MM-DD-YYYY', true).isValid()) {
			return t('general.dateFormat');
		}
	};

	const handleDateFromTextField = (dateValue) => {
		let date = dateValue.replace(/[^0-9\-']/gi, '');
		setValue(date);
		setError(date && date !== '' && !moment(date, 'MM-DD-YYYY', true).isValid());
	};

	const handleDateFromPicker = (dateValue) => {
		if (dateValue) {
			dateValue = new Date(dateValue);
			const updatedDateValue = dateValue
				.toLocaleDateString('en-US', {
					month: '2-digit',
					day: '2-digit',
					year: 'numeric'
				})
				?.replace(/\//g, '-');
			setValue(updatedDateValue);
			setError(
				updatedDateValue &&
					updatedDateValue !== '' &&
					!moment(updatedDateValue, 'MM-DD-YYYY', true).isValid()
			);
		}
	};

	useEffect(() => {
		if (!error && onChangeCallback) {
			onChangeCallback(value);
		}
	}, [value]);

	useEffect(() => {
		if (onValidationCallback) {
			onValidationCallback(error);
		}
	}, [error]);

	return (
		<div className={styles.container}>
			<Body
				size="small"
				weight={700}
				UNSAFE_className={styles.label}
				onClick={() => {
					if (inputRef) {
						const childInput = inputRef.current?.querySelector('input');
						childInput?.focus?.();
					}
				}}>
				{label}
			</Body>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					inputFormat="MM-dd-yyyy"
					value={value}
					onChange={(date) => handleDateFromPicker(date)}
					renderInput={(params) => (
						<MuiTextField
							{...params}
							className={styles.dateField}
							placeholder={t('general.dateFormatShort')}
							inputProps={{
								placeholder: t('general.dateFormatShort'),
								'aria-label': params.inputProps?.['aria-label'],
								'aria-readonly': params.inputProps?.['aria-readonly'],
								onClick: params.inputProps?.onClick,
								onKeyDown: params.inputProps?.onKeyDown
							}}
							value={value}
							onChange={(e) => handleDateFromTextField(e?.target?.value)}
							error={!hideError && error ? !!error : !!showAdditionalError?.(value)}
							ref={inputRef}
						/>
					)}
				/>
			</LocalizationProvider>
			{(!hideError && error) || showAdditionalError?.(value) ? (
				<Body size="small" weight={400} UNSAFE_className={styles.errorLabel}>
					<ErrorIcon />
					{!hideError && error ? DateInValidErrMsg() : showAdditionalError?.(value)}
				</Body>
			) : null}
		</div>
	);
};

CustomDateField.propTypes = {
	label: PropTypes.string,
	defaultValue: PropTypes.string,
	onChangeCallback: PropTypes.func,
	onValidationCallback: PropTypes.func,
	showAdditionalError: PropTypes.func
};

export default CustomDateField;
