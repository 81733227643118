import {
    Card,
    Heading,
    Body,
    Divider,
    Checkbox,
    Grid,
    GridColumn
} from '@walmart-web/livingdesign-components';
import {isEmpty} from 'lodash';
import React, { useState, useMemo, useEffect } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import {useVirtualJobOffer} from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import JobHourItem from '../../landing/jobHours/JobHourItem';
import { STEPS } from 'pages/VirtualJobOffer/util';

/**
 * Renders the Step2: Availability for the Portrait and Desktop views.
 */

const Availability = (props) => {
    const { t } = useTranslation();
    const {jobAvailability, isSupplyChain, nextStepInd} = useVirtualJobOffer();

    const [checkConfirmBox, setCheckConfirmBox] = useState(false);

    const handleCheckConfirmBox = (e) => {
        setCheckConfirmBox(!checkConfirmBox);
    };

    useEffect(() => {
		window.scroll(0, 0);
	 },[])

    useEffect(()=>{
        if(nextStepInd === (STEPS.AVAILABILITY+1)) {
            if(checkConfirmBox) {
                props.handleChangeCurrentStepStatus(true);
            } else {
                props.handleChangeCurrentStepStatus(false);
            }
        } else if (nextStepInd > (STEPS.AVAILABILITY+1)) {
            setCheckConfirmBox(true);
        }
    },[checkConfirmBox, nextStepInd]);

    const JobInfoData = useMemo(() => {
        if (isEmpty(jobAvailability)) {
            return [];
        }
        return [
            {
                title: t('days.sat'),
                Start: jobAvailability?.sat_start_time,
                End: jobAvailability?.sat_end_time,
            },
            {
                title: t('days.sun'),
                Start: jobAvailability?.sun_start_time,
                End: jobAvailability?.sun_end_time,
            },
            {
                title: t('days.mon'),
                Start: jobAvailability?.mon_start_time,
                End: jobAvailability?.mon_end_time,
            },
            {
                title: t('days.tue'),
                Start: jobAvailability?.tue_start_time,
                End: jobAvailability?.tue_end_time,
            },
            {
                title: t('days.wed'),
                Start: jobAvailability?.wed_start_time,
                End: jobAvailability?.wed_end_time,
            },
            {
                title: t('days.thu'),
                Start: jobAvailability?.thu_start_time,
                End: jobAvailability?.thu_end_time,
            },
            {
                title: t('days.fri'),
                Start: jobAvailability?.fri_start_time,
                End: jobAvailability?.fri_end_time,
            },
        ];
    }, [jobAvailability, t]);

    return (
        <Card UNSAFE_className="availability_card_container">
            <Heading
                children={t(`virtualJobOffer.availheading${isSupplyChain ? 'sc':''}`)}
                size='small'
            />
            <Body as="p"
                children={t(`virtualJobOffer.availpara1${isSupplyChain ? 'sc':''}`)}
                size='medium'
                UNSAFE_className='verify_availability_info_description'
            />
            <Body as="p"
                children={t(`virtualJobOffer.availpara2${isSupplyChain ? 'sc':''}`)}
                size='medium'
                UNSAFE_className='verify_availability_description'
            />
            {isSupplyChain && <Body as="p"
                children={t('virtualJobOffer.availpara3sc')}
                size='medium'
                UNSAFE_className='verify_availability_description'
            />}
            <Card UNSAFE_className='verify_availability_job_hour_container'>
                <Body
                    children={t(`virtualJobOffer.hoursTitle${isSupplyChain ? 'sc':''}`)}
                    weight={700}
                    size='medium'
                />
                <Grid hasGutter={false} className='verify_availability_job_hour_grid'>
                    {JobInfoData.map((hourDetail, index) => {
                        return (
                            <GridColumn
                                sm={1}
                                key={index}
                                UNSAFE_className='verify_availability_job_hour_grid_column'>
                                <JobHourItem
                                    day={hourDetail?.title}
                                    startTime={hourDetail?.Start}
                                    endTime={hourDetail?.End}
                                    isSC={isSupplyChain}
                                />
                            </GridColumn>
                        );
                    })}
                </Grid>
            </Card>
            <Divider />
            <Checkbox
                label={t(`virtualJobOffer.ackAgreeAvailability${isSupplyChain ? 'sc':''}`)}
                disabled={nextStepInd > (STEPS.AVAILABILITY+1)}
                onChange={(e) => handleCheckConfirmBox(e)}
                checked={checkConfirmBox}
                UNSAFE_className='info_correct'
            />
        </Card>
    );
};

export default Availability;
