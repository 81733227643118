import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequestErrorOccured, updateLoading } from "redux/slices/globalSlice";
import axiosInstance from "../../utils/axiosInstance";
import { environment } from "../../utils/environment/environment";


const getCountryDetails = createAsyncThunk(
    "disclaimer/getCountryDetails",
    async (request, { dispatch }) => {
        try {
            const baseUrl =
                environment.hcServiceBasePostUrl +
                "/getCityStateCountryByLatLong";
            const response = await axiosInstance.post(baseUrl, request);
            dispatch(updateLoading(false));
            return response.data;
        } catch (error) {
			dispatch(apiRequestErrorOccured(error));
        }
    }
);

const getDisclaimertexts = createAsyncThunk(
    "disclaimer/getDisclaimerText",
    async (_, { dispatch }) => {
        try{
            const baseUrl = environment.hcServiceBasePostUrl + "/getDisclaimerText";
            const response = await axiosInstance.post(baseUrl,{});
            dispatch(updateLoading(false));
            return response.data;
        }
        catch(error) {
			dispatch(apiRequestErrorOccured(error));
        }
    }
)

const getOutagePeriod = createAsyncThunk(
    "disclaimer/getOutagePeriod",
    async (_, { dispatch }) => {
        try{
            const baseUrl = environment.hcServiceBaseGetUrl + "/getOutagePeriod";
            const response = await axiosInstance.get(baseUrl);
            return response.data.response;
        }
        catch(error) {
			dispatch(apiRequestErrorOccured(error));
        }
    }
)


export { getCountryDetails, getDisclaimertexts, getOutagePeriod };
