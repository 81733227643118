import TimeChip from './TimeChip';
import React from 'react';

export default function TimeSlots(props) {

    return (
        <div>
            {props.data.map((interval, timeIndex) => {
                const label = interval.time;
                const selected = props.selectedSlot === interval.utcTime;
                return <TimeChip
                    key={interval.utcTime}
                    disList = {props.disList}
                    selected={selected}
                    handleOnTimePick={props.handleOnTimePick}
                    timeIndex={timeIndex}
                    dateIndex={props.dateIndex}
                    interval={interval.utcTime}
                    label={label}
                    style={{
                        backgroundColor: selected ? '#2E2F32' : '',
                        minWidth: '117px'
                    }}
                />
            })
            }
        </div>
    )
}
