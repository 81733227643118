import React, { useEffect, useState, useRef } from 'react';
import style from './AboutYou.module.css';
import AboutYouPersonalInfo from './AboutYouPersonalInfo/AboutYouPersonalInfo';
import { useDispatch, useSelector } from 'react-redux';
import { subHeader } from '../../../redux/slices/globalSlice';
import { Body, WizardFooter } from '@walmart-web/livingdesign-components';
import StudentStatus from './StudentStatus/StudentStatus';
import VisaStatus from './VisaStatus/VisaStatus';
import MilitaryService from './MilitaryService/MilitaryService';
import { useNavigate } from 'react-router';
import { updateApplicantId, updateCountryCodeAboutYou } from '../../../redux/AboutYou/slice';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AboutYouSvg } from '../../../assets/svg/aboutYou.svg';
import { sendGtmEvent } from '../../../utils/GoogleTagManagerUtils';
import { GTM } from '../../../config/const';

const AboutYou = () => {
	const { t } = useTranslation();
	const childRef = useRef(null);
	const [studentScreen, setStudentscreen] = useState(false);
	const [visaScreen, setVisaScreen] = useState(false);
	const [militaryScreen, setMilitartScreen] = useState(false);
	const global = useSelector((state) => state.global);
	const countryCode = global.countryCode === 'PR' ? 'PR' : 'US';
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [button, setbutton] = useState(true);

	useEffect(() => {
		dispatch(updateApplicantId({ applicantId: global.applicantId }));
		dispatch(updateCountryCodeAboutYou({ countryCode: countryCode }));
		if (!global.applicantId || global.applicantId === 0) {
			navigate('../login');
		} else {
			dispatch(
				subHeader({
					title: 'welcomePage.aboutYou',
					routeTo: 'login',
					display: 'true'
				})
			);
		}
		// eslint-disable-next-line
	}, []);
	const customStyles = {
		style: {
			lineHeight: '0rem',
			fontSize: '12px'
		}
	};

	const handleStudentScreen = (value) => {
		setStudentscreen(value);
	};
	const handleVisaScreen = (value) => {
		setVisaScreen(value);
	};
	const handleMilitaryScreen = (value) => {
		setMilitartScreen(value);
	};

	const handleMilitaryButton = () => {
		sendGtmEvent(GTM.EVENTS.APPLICATION_STEP1_ABOUT_YOU);
		childRef.current.handleButton();
	};

	const handleDisableButton = () => {
		if (childRef.current !== null) {
			const val = childRef.current.shouldDisableContinue();
			setbutton(val);
		} else {
			setbutton(true);
		}
	};

	return (
		<div>
			<div className={style.main}>
				<div className={style.blueHead}></div>
				<AboutYouSvg className={style.aboutYouImg} aria-hidden={true} />

				<div className={style.container}>
					<div className={style.info}>
						<div className={style.greeting}>
							<Body as="p" size="large" weight={400}>
								{t('aboutYou.letsGet')}
							</Body>
						</div>

						<div className={style.instructionText}>
							<Body as="span" weight={400} UNSAFE_style={customStyles.style}>
								{t('aboutYou.Instruction')}
							</Body>
						</div>
					</div>
					<AboutYouPersonalInfo handleStudentScreen={handleStudentScreen} />
					{studentScreen && (
						<>
							<StudentStatus handleVisaScreen={handleVisaScreen} />
							{visaScreen && (
								<>
									<VisaStatus handleMilitaryScreen={handleMilitaryScreen} />
									{militaryScreen && (
										<MilitaryService ref={childRef} handleDisableButton={handleDisableButton} />
									)}
								</>
							)}
						</>
					)}
				</div>
			</div>
			{studentScreen && visaScreen && militaryScreen && (
				<div className={style.continue}>
					<WizardFooter
						previousActionProps={{
							UNSAFE_className: 'wizard_footer_hide_previous'
						}}
						nextActionProps={{
							UNSAFE_className: 'wizard_footer_next',
							disabled: button,
							onClick: () => {
								handleMilitaryButton();
							},
							children: t('general.continue')
						}}></WizardFooter>
				</div>
			)}
		</div>
	);
};

export default AboutYou;
