import { Body } from '@walmart-web/livingdesign-components';
import React from 'react';
import styles from './TaxQuestionnaire.module.scss';

export const wotcElementTypes = {
	multipleChoiceSingleAnswerInput: 'multipleChoiceSingleAnswerInput',
	textFieldInput: 'textFieldInput',
	dateInput: 'dateInput',
	dropDownListInput: 'dropDownListInput',
	header: 'header',
	text: 'text',
	note: 'note'
};

export const nextButtonTexts = {
	submit: ['submit form', 'enviar forma', 'enviar forma'],
	continue: ['next', 'siguiente']
};

export const answerableWotcElementTypes = [
	wotcElementTypes.multipleChoiceSingleAnswerInput,
	wotcElementTypes.textFieldInput,
	wotcElementTypes.dateInput,
	wotcElementTypes.dropDownListInput
];

export const wotcLanguages = {
	EN: 'en-US',
	ES: 'es-ES'
};

export const parseAndRenderLabel = (label, fontSize, fontWeight) => {
	if (!label) return null;
	return (
		<Body size={fontSize || 'large'} weight={fontWeight || 700} as="span">
			{React.createElement('div', {
				className: styles.parsedContainer,
				dangerouslySetInnerHTML: { __html: label }
			})}
		</Body>
	);
};

export const removeHtmlTags = (htmlString) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlString, 'text/html');
	return doc.body?.textContent || '';
};

export const getInputLabel = (elementType, label, isSubmissionScreen) => {
	if (!elementType) {
		if(isSubmissionScreen) {
			return parseAndRenderLabel(label, 'medium', 400);
		}
		return parseAndRenderLabel(label);
	}
	return null;
};
