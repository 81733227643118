import {createSlice} from '@reduxjs/toolkit';
import {supplyChainAvailabilityObject} from './helper';
import {getsupplyChainAvailability} from './request';

const initialState = {data: {}, loading: false, error: {}};

const SupplyChainAvailabilitySlice = createSlice({
  name: 'getsupplyChainAvailability',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getsupplyChainAvailability.fulfilled, (state, action) => {
        state.data = supplyChainAvailabilityObject(action.payload);
      })
      .addCase(getsupplyChainAvailability.rejected, (state) => {
        state.error = {message: 'Something went wrong'};
      });
  },
});

export const {supplyChainAvailability} = SupplyChainAvailabilitySlice.actions;

export default SupplyChainAvailabilitySlice.reducer;
