import * as React from "react";
import {
    BottomSheet,
    BottomSheetCloseButton,
    Heading,
    Modal,
} from "@walmart-web/livingdesign-components";
import CreateAccount from "../CreateAccount";
import style from "./SaveForLaterPopUp.module.scss";
import MediaQuery from "react-responsive";
import { t } from "i18next";

const SaveForLaterPopUp = (props) => {
    return (
        <React.Fragment>
            <MediaQuery maxWidth={850}>
                <BottomSheet
                    isOpen={props.isOpen}
                    onClose={() => props.setIsOpen(false)}
                    title={({ titleId }) => (
                        <div className={style.title}>
                            <BottomSheetCloseButton
                                className={style.title__close_button}
                                aria-label={t('ariaLabelTexts.closeDialog')}
                                onClick={() => props.setIsOpen(false)}
                            />
                            <Heading size="small" id={titleId}>
                            {t('createAccount.createAccountButtonTitle2')}
                            </Heading>
                        </div>
                    )}
                >
                    <CreateAccount source="saveforlater"></CreateAccount>
                </BottomSheet>
            </MediaQuery>
            <MediaQuery minWidth={851}>
                <Modal
                    isOpen={props.isOpen}
                    onClose={() => props.setIsOpen(false)}
                    size="medium"
                    title={t('createAccount.createAccountButtonTitle2')}
                >
                    <CreateAccount source="saveforlater"></CreateAccount>
                </Modal>
            </MediaQuery>
        </React.Fragment>
    );
};

export default SaveForLaterPopUp;
