import React from 'react';
import { Card } from '@walmart-web/livingdesign-components';
import { useSelector } from 'react-redux';
import registrationComplete from '../../../../assets/images/registrationComplete.svg';
import rightArrow from '../../../../assets/images/right_arrow.svg';
import CustomLabel from '../CustomLabel';
import './style.scss';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const Assessments = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const assessments = useSelector(
    (state) => state.postApplyDashboard.data
  );
  const assessmentCount = assessments?.assessmentCount;
  return assessmentCount ? (
    <Card size="medium" UNSAFE_className="assessments_card" onClick={() => navigate('../assessments')}>
      <CustomLabel
        icon={registrationComplete}
        title={t('assessment.assessments') + ' - '+assessmentCount}
      />
      <img src={rightArrow} alt="right arrow"/>
    </Card>
  ) : null;
};

export default Assessments;
