import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import style from './Experience.module.css';
import {
	Body,
	WizardFooter,
	ProgressTracker,
	ProgressTrackerItem
} from '@walmart-web/livingdesign-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateAnswerExperience,
	updateApplicantIdExperience
} from '../../../redux/Experience/slice';
import { saveHcTestResponse } from '../../../redux/Experience/request';
import { saveHcApplicationStatus } from 'redux/Account/request';
import { updateLoading, subHeader, executionErrorOccured } from '../../../redux/slices/globalSlice';
import { getPersonalInfo } from '../../../redux/AboutYou/request';
import { updatePersonalInfo } from '../../../redux/AboutYou/slice';
import { ReactComponent as ExperienceSvg } from '../../../assets/svg/experience.svg';
import { sendGtmEvent } from '../../../utils/GoogleTagManagerUtils';
import { GTM } from '../../../config/const';

const Experience = () => {
	const navigate = useNavigate();

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [salesExp, setSalesExp] = useState(0);
	const [groceryExp, setGroceryExp] = useState(0);
	const [warehouseExp, setWarehouseExp] = useState(0);
	const [manufacturingExp, setManufacturingExp] = useState(0);
	const global = useSelector((state) => state.global);
	const statusArr =
		global.language === 'ES'
			? ['Ninguno', '1 año', '1-3 años', '3-5 años', '5+ años']
			: ['None', '1 year', '1-3 years', '3-5 years', '5+ years'];
	const Experience = useSelector((state) => state.Experience);

	useEffect(() => {
		dispatch(
			subHeader({
				title: 'welcomePage.experience',
				routeTo: 'login',
				display: 'true'
			})
		);
		dispatch(updateApplicantIdExperience({ applicantId: global.applicantId }));
		const requestInput = {
			applicantId: global.applicantId,
			emailId: global.emailId
		};
		dispatch(updateLoading(true));
		dispatch(getPersonalInfo(requestInput))
			.then((res) => {
				const data = res.payload;
				dispatch(updatePersonalInfo(data));
				const applInfo = data;
				let groceryAns = false;
				let salesAns = false;
				let warehouseAns = false;
				let manufactureAns = false;
				if (Object.keys(applInfo).length !== 0) {
					applInfo.hcTestResponse.forEach((obj) => {
						if (obj.questionNbr === 31 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
							if (obj.answerNbr === 76) {
								setGroceryExp(0);
							} else if (obj.answerNbr === 77) {
								setGroceryExp(1);
							} else if (obj.answerNbr === 78) {
								setGroceryExp(2);
							} else if (obj.answerNbr === 79) {
								setGroceryExp(3);
							} else if (obj.answerNbr === 80) {
								setGroceryExp(4);
							}

							dispatch(
								updateAnswerExperience({
									ques: obj.questionNbr,
									ans: obj.answerNbr
								})
							);
							groceryAns = true;
						}
						if (obj.questionNbr === 30 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
							if (obj.answerNbr === 76) {
								setSalesExp(0);
							} else if (obj.answerNbr === 77) {
								setSalesExp(1);
							} else if (obj.answerNbr === 78) {
								setSalesExp(2);
							} else if (obj.answerNbr === 79) {
								setSalesExp(3);
							} else if (obj.answerNbr === 80) {
								setSalesExp(4);
							}

							dispatch(
								updateAnswerExperience({
									ques: obj.questionNbr,
									ans: obj.answerNbr
								})
							);
							salesAns = true;
						}
						if (obj.questionNbr === 41 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
							if (obj.answerNbr === 76) {
								setWarehouseExp(0);
							} else if (obj.answerNbr === 77) {
								setWarehouseExp(1);
							} else if (obj.answerNbr === 78) {
								setWarehouseExp(2);
							} else if (obj.answerNbr === 79) {
								setWarehouseExp(3);
							} else if (obj.answerNbr === 80) {
								setWarehouseExp(4);
							}

							dispatch(
								updateAnswerExperience({
									ques: obj.questionNbr,
									ans: obj.answerNbr
								})
							);
							warehouseAns = true;
						}
						if (obj.questionNbr === 43 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
							if (obj.answerNbr === 76) {
								setManufacturingExp(0);
							} else if (obj.answerNbr === 77) {
								setManufacturingExp(1);
							} else if (obj.answerNbr === 78) {
								setManufacturingExp(2);
							} else if (obj.answerNbr === 79) {
								setManufacturingExp(3);
							} else if (obj.answerNbr === 80) {
								setManufacturingExp(4);
							}

							dispatch(
								updateAnswerExperience({
									ques: obj.questionNbr,
									ans: obj.answerNbr
								})
							);
							manufactureAns = true;
						}
					});
				}
				if (!groceryAns) {
					dispatch(
						updateAnswerExperience({
							ques: 31,
							ans: 76
						})
					);
				}
				if (!salesAns) {
					dispatch(
						updateAnswerExperience({
							ques: 30,
							ans: 76
						})
					);
				}
				if (!warehouseAns) {
					dispatch(
						updateAnswerExperience({
							ques: 41,
							ans: 76
						})
					);
				}
				if (!manufactureAns) {
					dispatch(
						updateAnswerExperience({
							ques: 43,
							ans: 76
						})
					);
				}
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});

		// eslint-disable-next-line
	}, []);

	const handleSalesExp = (index) => {
		setSalesExp(index);
		if (index === 0) {
			dispatch(
				updateAnswerExperience({
					ques: 30,
					ans: 76
				})
			);
		} else if (index === 1) {
			dispatch(
				updateAnswerExperience({
					ques: 30,
					ans: 77
				})
			);
		} else if (index === 2) {
			dispatch(
				updateAnswerExperience({
					ques: 30,
					ans: 78
				})
			);
		} else if (index === 3) {
			dispatch(
				updateAnswerExperience({
					ques: 30,
					ans: 79
				})
			);
		} else if (index === 4) {
			dispatch(
				updateAnswerExperience({
					ques: 30,
					ans: 80
				})
			);
		}
	};
	const handleGroceryExp = (index) => {
		setGroceryExp(index);
		if (index === 0) {
			dispatch(
				updateAnswerExperience({
					ques: 31,
					ans: 76
				})
			);
		} else if (index === 1) {
			dispatch(
				updateAnswerExperience({
					ques: 31,
					ans: 77
				})
			);
		} else if (index === 2) {
			dispatch(
				updateAnswerExperience({
					ques: 31,
					ans: 78
				})
			);
		} else if (index === 3) {
			dispatch(
				updateAnswerExperience({
					ques: 31,
					ans: 79
				})
			);
		} else if (index === 4) {
			dispatch(
				updateAnswerExperience({
					ques: 31,
					ans: 80
				})
			);
		}
	};
	const handleWarehouseExp = (index) => {
		setWarehouseExp(index);
		if (index === 0) {
			dispatch(
				updateAnswerExperience({
					ques: 41,
					ans: 76
				})
			);
		} else if (index === 1) {
			dispatch(
				updateAnswerExperience({
					ques: 41,
					ans: 77
				})
			);
		} else if (index === 2) {
			dispatch(
				updateAnswerExperience({
					ques: 41,
					ans: 78
				})
			);
		} else if (index === 3) {
			dispatch(
				updateAnswerExperience({
					ques: 41,
					ans: 79
				})
			);
		} else if (index === 4) {
			dispatch(
				updateAnswerExperience({
					ques: 41,
					ans: 80
				})
			);
		}
	};

	const handleManufacturingExp = (index) => {
		setManufacturingExp(index);
		if (index === 0) {
			dispatch(
				updateAnswerExperience({
					ques: 43,
					ans: 76
				})
			);
		} else if (index === 1) {
			dispatch(
				updateAnswerExperience({
					ques: 43,
					ans: 77
				})
			);
		} else if (index === 2) {
			dispatch(
				updateAnswerExperience({
					ques: 43,
					ans: 78
				})
			);
		} else if (index === 3) {
			dispatch(
				updateAnswerExperience({
					ques: 43,
					ans: 79
				})
			);
		} else if (index === 4) {
			dispatch(
				updateAnswerExperience({
					ques: 43,
					ans: 80
				})
			);
		}
	};

	const handleButton = (e) => {
		sendGtmEvent(GTM.EVENTS.APPLICATION_STEP3_EXPERIENCE);
		const req = {
			hcTestResponse: Experience.experience.hcTestResponse,
			applicantId: global.applicantId
		};
		const reqStatus = {
			statusCode: Experience.experience.status,
			applicantId:global.applicantId
		}

		dispatch(updateLoading(true));
		dispatch(saveHcTestResponse(req))
			.then((res) => {
				console.log('response received from backend', res);
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
		dispatch(saveHcApplicationStatus(reqStatus))
			.then((res) => {
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});

		navigate('../job-history');
	};

	return (
		<div>
			<div className={style.main}>
				<div className={style.blueHead}></div>
				<ExperienceSvg className={style.ExperienceImg} aria-hidden={true} />

				<div className={style.container}>
					<div className={style.title}>
						<Body as="p" size="large" weight={700}>
							{t('experience.title')}
						</Body>
					</div>
					<div>
						<Body as="p" size="large" weight={400} id="sales-experience-label">
							{t('experience.sales')}
						</Body>
					</div>
					<div>
						<ProgressTracker activeIndex={salesExp} variant="info">
							{statusArr.map((status, index) => {
								return (
									<ProgressTrackerItem
										key={index}
										onClick={() => {
											handleSalesExp(index);
										}}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												handleSalesExp?.(index);
											}
										}}
										tabIndex="0"
										aria-describedby="sales-experience-label"
										aria-roledescription="option">
										{status}
									</ProgressTrackerItem>
								);
							})}
						</ProgressTracker>
					</div>
					<div>
						<Body as="p" size="large" weight={400} id="grocery-experience-label">
							{t('experience.grocery')}
						</Body>
					</div>
					<div>
						<ProgressTracker activeIndex={groceryExp} variant="info">
							{statusArr.map((status, index) => {
								return (
									<ProgressTrackerItem
										key={index}
										onClick={() => {
											handleGroceryExp(index);
										}}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												handleGroceryExp?.(index);
											}
										}}
										tabIndex="0"
										aria-describedby="grocery-experience-label"
										aria-roledescription="option">
										{status}
									</ProgressTrackerItem>
								);
							})}
						</ProgressTracker>
					</div>
					<div>
						<Body as="p" size="large" weight={400}>
							{t('experience.warehouse')}
						</Body>
					</div>
					<div>
						<ProgressTracker
							activeIndex={warehouseExp}
							variant="info"
							id="warehouse-experience-label">
							{statusArr.map((status, index) => {
								return (
									<ProgressTrackerItem
										key={index}
										onClick={() => {
											handleWarehouseExp(index);
										}}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												handleWarehouseExp?.(index);
											}
										}}
										tabIndex="0"
										aria-describedby="warehouse-experience-label"
										aria-roledescription="option">
										{status}
									</ProgressTrackerItem>
								);
							})}
						</ProgressTracker>
					</div>
					<div>
						<Body as="p" size="large" weight={400} id="manufacturing-experience-label">
							{t('experience.manufacturing')}
						</Body>
					</div>
					<div>
						<ProgressTracker activeIndex={manufacturingExp} variant="info">
							{statusArr.map((status, index) => {
								return (
									<ProgressTrackerItem
										key={index}
										onClick={() => {
											handleManufacturingExp(index);
										}}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												handleManufacturingExp?.(index);
											}
										}}
										tabIndex="0"
										aria-describedby="manufacturing-experience-label"
										aria-roledescription="option">
										{status}
									</ProgressTrackerItem>
								);
							})}
						</ProgressTracker>
					</div>
				</div>

				<WizardFooter
					previousActionProps={{
						UNSAFE_className: 'wizard_footer_previous',
						disabled: false,
						onClick: () => navigate('../eeo'),
						children: t('general.previous')
					}}
					nextActionProps={{
						UNSAFE_className: 'wizard_footer_next',
						disabled: false,
						onClick: () => {
							handleButton();
						},
						children: t('general.continue')
					}}></WizardFooter>
			</div>
		</div>
	);
};

export default Experience;
