import {isEmpty} from 'lodash';

const formattedTime = (time) => {
  if (isEmpty(time)) {
    return 'NA';
  }
  return time.slice(0, 5);
};

export const supplyChainAvailabilityObject = (response) => {
  if (!response) {
    return;
  }
  return {
    ...response,
    sat_start_time: formattedTime(response?.sat_start_time),
    sat_end_time: formattedTime(response?.sat_end_time),
    sun_start_time: formattedTime(response?.sun_start_time),
    sun_end_time: formattedTime(response?.sun_end_time),
    mon_start_time: formattedTime(response?.mon_start_time),
    mon_end_time: formattedTime(response?.mon_end_time),
    tue_start_time: formattedTime(response?.tue_start_time),
    tue_end_time: formattedTime(response?.tue_start_time),
    wed_start_time: formattedTime(response?.wed_start_time),
    wed_end_time: formattedTime(response?.wed_end_time),
    thu_start_time: formattedTime(response?.thu_start_time),
    thu_end_time: formattedTime(response?.thu_end_time),
    fri_start_time: formattedTime(response?.fri_start_time),
    fri_end_time: formattedTime(response?.fri_end_time),
  };
};
