import { Card, Heading, Body, Alert, StyledText } from '@walmart-web/livingdesign-components';
import React, { useState, useEffect } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useReviewDocuSign } from 'pages/VirtualJobOffer/hooks/useReviewDocuSign';
import { useVirtualJobOffer } from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import RegistrationConsentMedicalForm from '@livingdesign/pictograms/dist/svg/Health_Forms_&_Office/RegistrationConsentMedicalForm.svg';
import { PAGES, STEPS } from 'pages/VirtualJobOffer/util';

/**
 * Renders the Step3: Review for the Portrait and Desktop views.
 */

const Review = (props) => {
	const { t } = useTranslation();
	const { getReviewDocuSignUrl, docuSignInfo, docuSignResp } = useReviewDocuSign();
	const { jobOffer, nextStepInd, isSupplyChain } = useVirtualJobOffer();

	const [openDocSignPopUp, setOpenDocSignPopUp] = useState(false);
	const [isError, setIsError] = useState(false);

	useEffect(() => {
		window.scroll(0, 0);
	 },[])

	useEffect(() => {
		if (nextStepInd > PAGES.REVIEW) {
			props.handleChangeCurrentStepStatus(true);
		}
	}, [nextStepInd]);

	useEffect(() => {
		//getDocusignUrl response action
		if (openDocSignPopUp) {
			if (docuSignInfo?.responseCode === '200' && docuSignInfo?.response?.envelopeURL) {
				setIsError(false);
				window.open(docuSignInfo.response.envelopeURL, '_self');
				setOpenDocSignPopUp(!openDocSignPopUp); //close DocuSign
			} else if (docuSignInfo?.responseCode === '500') {
				setIsError(true);
			}
		}
	}, [docuSignInfo, openDocSignPopUp]);

	const handleDocSignPopUp = () => {
		if (docuSignResp?.response?.envelopeStatus !== 'accepted') {
			getReviewDocuSignUrl();
			setOpenDocSignPopUp(!openDocSignPopUp); //open DocuSign
		}
	};

	return (
		<Card UNSAFE_className="review_card_container">
			<Heading children={t('virtualJobOffer.reviewOffer')} size="small" />
			<Body
				as="p"
				children={t('virtualJobOffer.reviewPdfVerbiage')}
				size="medium"
				UNSAFE_className="verify_review_info_description"
			/>
			{isError && (
				<Alert
					children={t(`virtualJobOffer.reviewDocuSignError${isSupplyChain ? 'Sc' : ''}`)}
					variant="error"
					UNSAFE_className="alert_error"
				/>
			)}
			<Card UNSAFE_className="verify_review_container">
				<Body children={t('virtualJobOffer.signMyOffer')} size="medium" />
				<StyledText
					leading={
						<img
							alt={t('vjoDashboard.jobOffer')}
							src={RegistrationConsentMedicalForm}
							className="job_offer_icon_small"
						/>
					}
					size="small"
					children={
						nextStepInd === STEPS.ACKNOWLEDGE + 1 ||
						nextStepInd > STEPS.ACKNOWLEDGE_DECLINED + 1 ? (
							<Body
								children={jobOffer?.jobName}
								size="medium"
								UNSAFE_className="disabled_job_name"
							/>
						) : (
							<Body
								children={jobOffer?.jobName}
								size="medium"
								onClick={handleDocSignPopUp}
								UNSAFE_className="review_job_name"
							/>
						)
					}
				/>
				<Body children={t('virtualJobOffer.signJobOffer')} size="medium" />
			</Card>
			<Alert variant="info">
				{t('virtualJobOffer.resonableAccommodation1')}
				<b>{t('virtualJobOffer.resonableAccommodation2')}</b>
				{t('virtualJobOffer.resonableAccommodation3')}
			</Alert>
		</Card>
	);
};

export default Review;
