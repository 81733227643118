import React from 'react';
import { Body, Alert } from '@walmart-web/livingdesign-components';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

const VJOExpiry = () => {
    const { t } = useTranslation();
    const virtualJobOfferInfo = useSelector((state) => state.virtualJobOffer?.data);

    return virtualJobOfferInfo?.storeNbr ? (
        <Alert variant="warning">
            <Trans>
                <Body as="div">
                    {t('virtualJobOffer.expiredJobOffer',
                        {
                            storeNbr: virtualJobOfferInfo?.storeNbr,
                            jobName: virtualJobOfferInfo?.jobName
                        }
                    )}</Body>
            </Trans>
        </Alert>
    ) : (
        <></>
    );
};

export default VJOExpiry;