import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import {
	blackStoreSvg,
	blackWarehouseSvg,
	mapSearchSvg,
	storeSvg,
	toggleStoreSvg,
	toggleWarehouseSvg,
	warehouseSvg
} from '../LocationIcons/LocationIcons';
import { useDispatch, useSelector } from 'react-redux';
import {
	setDisabledScrollCheck,
	setToggleStore,
	updateLocationSearch
} from '../../../../redux/Location/slice';
import { useMediaQuery } from 'react-responsive';
import { Button } from '@walmart-web/livingdesign-components';
import style from './LocationSearchMap.module.css';
import { getAllFacilities } from '../../../../redux/Location/request';
import { updateLoading } from '../../../../redux/slices/globalSlice';
import { t } from 'i18next';

const containerStyle = {
	width: '100%',
	height: '100%'
};

const getPosition = (store) =>
	store
		? { lat: Number(store.storeLatitude), lng: Number(store.storeLongtitude) }
		: { lat: 36.3729, lng: -94.2088 };

function MyComponent(props) {
	const [filteredStores, setFilteredStores] = useState([]);
	const [currentCenter, setCurrentCenter] = useState(null);
	const [showSearchThisArea, setShowSearchThisArea] = useState(false);
	const mapRef = useRef(null);
	const disabledScrollCheckRef = useRef(null);
	const dispatch = useDispatch();
	const selectedPos = useSelector((state) => state.location.selectedPos);
	const toggleStore = useSelector((state) => state.location.toggleStore);
	const applicantId = useSelector((state) => state.global.applicantId);
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const hostname = window && window.location && window.location.hostname;

	const mapKey = hostname != process.env.REACT_APP_hostname_prod_EXT && hostname != process.env.REACT_APP_hostname_stage_EXT ?
		process.env.REACT_APP_GOOGLE_MAP_API_KEY_DEV : (hostname === process.env.REACT_APP_hostname_prod_EXT ?
		process.env.REACT_APP_GOOGLE_MAP_API_KEY_PROD : process.env.REACT_APP_GOOGLE_MAP_API_KEY_NON_PROD);

	const onMarkerClick = (store) => () => {
		setCurrentCenter(mapRef.current.getCenter());
		dispatch(setToggleStore(store));
		// Scroll Store Carousel/list logic for mobile and desktop
		if (isMobile) {
			dispatch(setDisabledScrollCheck(true));
			const locationCarouselCard = document
				.getElementById(`LocationCarousel_${store.storeNumber}`);
			if (locationCarouselCard) {
				locationCarouselCard.scrollIntoView({ behavior: 'smooth' });
				const checkboxInput = locationCarouselCard.querySelector('input[type="checkbox"]');
				checkboxInput?.focus?.();
			}
			clearTimeout(disabledScrollCheckRef.current);
			disabledScrollCheckRef.current = setTimeout(
				() => dispatch(setDisabledScrollCheck(false)),
				1000
			);
		} else {
			const locationStoreCard = document
				.getElementById(`LocationStoreList_${store.storeNumber}`);
			if (locationStoreCard) {
				locationStoreCard.scrollIntoView({ behavior: 'smooth', block: 'center' });
				const checkboxInput = locationStoreCard.querySelector('input[type="checkbox"]');
				checkboxInput?.focus?.();
			}
		}
	};

	const onSearchThisAreaClick = () => {
		setShowSearchThisArea(false);
		const searchQuery =
			'&latitude=' + mapRef.current.center.lat() + '&longitude=' + mapRef.current.center.lng();
		const requestInput = 'applicantId=' + applicantId + searchQuery;
		dispatch(updateLoading(true));
		dispatch(getAllFacilities(requestInput)).then(() => {
			dispatch(
				updateLocationSearch({
					type: 'mapArea',
					value: t('location.mapArea')
				})
			);
			dispatch(updateLoading(false));
		});
	};

	const triggerSearchThisArea = () => {
		if (showSearchThisArea) {
			return;
		}
		setCurrentCenter(mapRef.current?.getCenter());
		setShowSearchThisArea(true);
	};

	useEffect(() => {
		let filteredList = [];
		if (props.filterMap[1]) {
			filteredList = props.storeList.filter((store) => !store.sc);
		} else if (props.filterMap[2]) {
			filteredList = props.storeList.filter((store) => store.sc);
		} else {
			filteredList = props.storeList;
		}
		filteredList.length === 0
			? setCurrentCenter(mapRef.current?.getCenter())
			: setCurrentCenter(null);
		setShowSearchThisArea(false);
		setFilteredStores(filteredList);
	}, [props.filterMap, props.storeList]);

	useEffect(() => {
		// Change map center if user click store outside of map view bound
		const togglePosition = getPosition(toggleStore);
		if (mapRef.current && !mapRef.current.getBounds().contains(togglePosition)) {
			setCurrentCenter(togglePosition);
		}
	}, [toggleStore]);

	return (
		<div className={style.mapContainer}>
		<LoadScript googleMapsApiKey={mapKey}>
			{showSearchThisArea && (
				<div className={style.searchThisArea}>
					<Button
						leading={mapSearchSvg}
						variant={'secondary'}
						UNSAFE_className={style.searchThisArea__Button}
						onClick={onSearchThisAreaClick}>
						{t('location.searchThisArea')}
					</Button>
				</div>
			)}
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={currentCenter ? currentCenter : getPosition(filteredStores[0])}
				onLoad={(ref) => {
					mapRef.current = ref;
				}}
				zoom={12}
				options={{
					fullscreenControl: false,
					mapTypeControl: !isMobile,
					zoomControl: !isMobile,
					zoomControlOptions: {
						position: 7
					}
				}}
				onDragEnd={triggerSearchThisArea}>
				{filteredStores &&
					filteredStores.map((store) => {
						const checked = selectedPos.some(
							(selectedStore) => selectedStore.storeNumber === store.storeNumber
						);
						const markerIcon =
							toggleStore && toggleStore.storeNumber === store.storeNumber
								? encodeURIComponent(store.sc ? toggleWarehouseSvg : toggleStoreSvg)
								: checked
								? encodeURIComponent(store.sc ? blackWarehouseSvg : blackStoreSvg)
								: encodeURIComponent(store.sc ? warehouseSvg : storeSvg);
						return (
							<Marker
								position={getPosition(store)}
								icon={'data:image/svg+xml;charset=UTF-8,' + markerIcon}
								key={store.storeNumber}
								title={'facility '+store.storeNumber}
								onClick={onMarkerClick(store)}
							/>
						);
					})}
			</GoogleMap>
		</LoadScript>
		</div>
	);
}

export default React.memo(MyComponent);
