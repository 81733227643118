import React from 'react';

import style from './SubHeader.module.css';
import { Heading } from '@walmart-web/livingdesign-components';
import { ChevronLeft } from '@livingdesign/icons';
import { ReactComponent as ShoppingLists } from '@livingdesign/pictograms/dist/svg/App_&_Web_Services/ShoppingLists.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { subHeader } from '../../../redux/slices/globalSlice';
import { useMediaQuery } from 'react-responsive';
import { pathMap } from '../../../config/const';
import LocationSearch from '../../../pages/Location/components/LocationSearch/LocationSearch';
import { useTranslation } from 'react-i18next';
import { setClickLocText } from 'redux/Location/slice';

const SubHeader = (props) => {
	const locationSearchUrl = 'location-search';
	const jobSearchUrl = 'job-search';
	const { t } = useTranslation();
	const isDesktop = useMediaQuery({ minWidth: 851 });
	const iPadLandscape = useMediaQuery({
		minWidth: 426,
		maxWidth: 850,
		orientation: 'landscape'
	});
	const editProfile = useSelector((state) => state.editProfile);

	const global = useSelector((state) => state.global);
	const location = useSelector((state) => state.location);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const goToRoute = () => {
		dispatch(subHeader({ title: '', routeTo: '', display: false }));
		if(editProfile.fromEditProfile) {
			navigate('../edit-profile');
		}
		else if (global.path === locationSearchUrl && global.applicantId === 0) {
			navigate('../homeScreen');
		} else if (
			global.applicantId === 0 ||
			[
				'job-details',
				'job-search',
				'edit-profile-job-history',
				'edit-profile-certificates-licenses',
				'availability'
			].includes(global.path)
		) {
			navigate('../' + global.subHeader.routeTo);
		} else if (
			['availability'].includes(global.path) &&
			(editProfile.editAvailability ||
				editProfile.shiftUpdateNeeded ||
				editProfile.scheduleUpdateNeeded)
		) {
			navigate('../' + global.subHeader.routeTo);
		} else {
			navigate('../login');
		}
	};

	const handleCancel = () => {
		dispatch(setClickLocText(false));
	};

	const locBarStyle = !(isDesktop || iPadLandscape) && location.clickLocText ? style.locBar : '';

	const iconSelection = () => {
		if (pathMap[global.path]?.icon) {
			return global.applicantId === 0 ? pathMap[global.path].icon[0] : pathMap[global.path].icon[1];
		}
		return '';
	};

	const icon = () => {
		if(editProfile.fromEditProfile) {
			return 'back';
		}
		if (pathMap[global.path] && Array.isArray(pathMap[global.path].icon)) {
			return iconSelection();
		}
		return pathMap[global.path] ? pathMap[global.path].icon : '';
	};

	const descSelection = () => {
		if (pathMap[global.path]?.desc) {
			return global.applicantId === 0 ? pathMap[global.path].desc[0] : pathMap[global.path].desc[1];
		}
		return '';
	};

	const desc = () => {
		if (
			pathMap[global.path] &&
			(editProfile.editAvailability ||
				editProfile.shiftUpdateNeeded ||
				editProfile.scheduleUpdateNeeded)
		) {
			pathMap[global.path].desc = '';
		}

		if (pathMap[global.path] && Array.isArray(pathMap[global.path].desc)) {
			return descSelection();
		}

		return pathMap[global.path] ? pathMap[global.path].desc : '';
	};

	const descFill =
		(isDesktop || iPadLandscape) && global.path === locationSearchUrl ? '#2E2F32' : 'white';

	return (
		<div
			className={
				global.path !== locationSearchUrl && global.path !== jobSearchUrl
					? style.container
					: global.path === locationSearchUrl &&
					  location.clickLocText &&
					  !(isDesktop || iPadLandscape)
					? style.containerLocMobSelect
					: style.containerLoc
			}>
			<div className={style.box1}>
				<div 
					onClick={goToRoute} 
					className={style.routeAction} 
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							goToRoute?.();
						}
					}}
					id="backButton"
					tabIndex="0"
					role="button">
					{(isDesktop || iPadLandscape || !location.clickLocText) && (
						<div style={{ display: 'flex' }}>
							{icon() === 'back' ? (
								<ChevronLeft size="large" />
							) : (
								<ShoppingLists
									aria-label={t('ariaLabelTexts.applicationOverview')}
									className={style.svgIcon}
									fill={descFill}
								/>
							)}{' '}
						</div>
					)}
					{(isDesktop || iPadLandscape) && (
						<Heading
							as="h2"
							size="small"
							weight="400"
							UNSAFE_style={
								global.path !== locationSearchUrl && global.path !== jobSearchUrl
									? { color: '#FFFFFF' }
									: {}
							}>
							{pathMap[global.path] ? t(`subHeader.${desc()}`) : !editProfile.fromEditProfile ? t('subHeader.applicationOverview') : ''}
						</Heading>
					)}
				</div>
			</div>

			<div className={style.box2}>
				{global.path !== locationSearchUrl ? (
					<Heading
						as="h1"
						size="medium"
						weight="700"
						UNSAFE_style={
							global.path !== jobSearchUrl || !(isDesktop || iPadLandscape)
								? { color: '#FFFFFF' }
								: {}
						}>
						{t(global.subHeader.title)}
					</Heading>
				) : (
					<div className={locBarStyle}>
						<div className={style.locationSearch}>
							<LocationSearch
								showSearch={false}
								showCurrentLoc={location.clickLocText}
								setIsOpen={handleCancel}
							/>
						</div>
					</div>
				)}
			</div>
			<div className={style.box3}>{/* Filler */}</div>
		</div>
	);
};

export default SubHeader;
