import React from 'react';
import style from './Footer.module.css';
import { Link } from '@walmart-web/livingdesign-components';
import { useSelector } from 'react-redux';
import { feedback } from '../../config/const';
import Feedback from './components/Feedback';
import { useTranslation } from 'react-i18next';
import { useDisplay } from '../../utils/useDisplay';
import { environment } from '../../utils/environment/environment';
import { ReactComponent as PrivacyOptionsSvg } from '../../assets/svg/privacyOptions.svg';

const Footer = () => {
	const global = useSelector((state) => state.global);
	const { isMobile } = useDisplay();
	const { t } = useTranslation();
	const prUrl = global.countryCode === 'PR' ? true : false;

	const customStyles = {
		links: {
			textDecoration: 'none'
		}
	};

	const showFeedback = feedback[global.path] || false;

	if (!global.displayFooter) {
		return null;
	}

	const privacyOptions = (
		<div className={style.linkAlign}>
			<Link
				target="_blank"
				href={
					'https://corporate.walmart.com/privacy-security/california-privacy-rights#what-are-the-categories-of-personal-information-collected'
				}
				UNSAFE_style={customStyles.links}>
				<div className={style.linkVertical}>
					{/* <PrivacyOptionsSvg className={style.privacyOptionsSvg} /> */}
					{/* {t('hcfooter.yourPrivacyChoices')} */}
					{t('hcfooter.noticeAtCollection')}
				</div>
			</Link>
		</div>
	);

	return (
		<div>
			{showFeedback && <Feedback />}
			<div
				className={style.parentFooter}
				style={{
					backgroundColor: showFeedback ? '#F2F8FE' : '#F8F8F8'
				}}>
				<div className={style.footerDummy}></div>
				<div className={style.footerLinksContainer}>
					<div className={style.footerLinks}>
						<div className={style.linkAlign}>
							<Link
								target="_blank"
								href={
									prUrl ? 'https://walmartpr.com/corporativo/' : 'https://corporate.walmart.com/'
								}
								UNSAFE_style={customStyles.links}>
								{t('hcfooter.corporate')}
							</Link>
						</div>
						<span className={style.pipe}></span>
						<div className={style.linkAlign}>
							<Link
								target="_blank"
								href={
									prUrl ? 'https://walmartpr.com/tiendas/' : 'https://careers.walmart.com/sitemap'
								}
								UNSAFE_style={customStyles.links}>
								{t('hcfooter.sitemap')}
							</Link>
						</div>
						<span className={style.pipe}></span>
						<div className={style.linkAlign}>
							<Link
								target="_blank"
								href={
									prUrl
										? 'https://walmartpr.com/terminos-de-uso/'
										: 'https://corporate.walmart.com/terms-of-use'
								}
								UNSAFE_style={customStyles.links}>
								{t('hcfooter.termsofuse')}
							</Link>
						</div>
						<span className={style.pipe}></span>
						<div className={style.linkAlign}>
							<Link
								target="_blank"
								href={
									prUrl
										? 'https://corporate.walmart.com/privacy-security/aviso-de-privacidad-de-walmart'
										: 'https://corporate.walmart.com/privacy-security'
								}
								UNSAFE_style={customStyles.links}>
								{t('hcfooter.privacypolicy')}
							</Link>
						</div>
						{!isMobile && (
							<>
								<span className={style.pipe}></span>
								{privacyOptions}
							</>
						)}
					</div>
					{isMobile && <div className={style.footerLinks}>{privacyOptions}</div>}
				</div>
				<div className={style.footerCopyright}>
					<span> © {new Date().getFullYear()} Walmart Inc</span>
				</div>
			</div>
		</div>
	);
};

export default Footer;
