import React from 'react';
import { useSelector } from 'react-redux';
import {
	Card,
	Heading,
	ProgressTracker,
	ProgressTrackerItem,
	Body,
	Alert
} from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import Stripe from '../../../../components/Stripe';
import './style.scss';

const JobTracker = (props) => {
	const statusArr = ['Profile', 'Apply', 'Consideration', 'Interview', 'Offer'];
	const { storeNbr } = props;
	const { t } = useTranslation();
	const locationDetails = useSelector((state) => state.postApplyDashboard.data.locationDetails);
	const stage = (locationDetails && locationDetails[storeNbr]?.overAllStage) || 2;

	const {
		storeInfo: { storeName = '', streetAddress = '', address = '', hasTrackerInfo = '' }
	} = (locationDetails && locationDetails[storeNbr]) || { storeInfo: {} };

	return (
		<Card size="medium" UNSAFE_className="job_tracker_card_container">
			<Heading as="h1" size="small" weight={700}>
				{storeName}
			</Heading>
			<Body as="span" size="small" weight={400}>
				{`${streetAddress}, ${address}`}
			</Body>
			<Stripe />
			{hasTrackerInfo ? (
				<>
				<ProgressTracker activeIndex={stage} variant="info" UNSAFE_className="job_progress_tracker">
					{statusArr.map((status, index) => {
						return <ProgressTrackerItem key={index}>{t('jobTracker.'+status)}</ProgressTrackerItem>;
					})}
				</ProgressTracker>
				<Alert variant="info"> {t('jobTracker.completedApplicationMessage')} </Alert>
				</>
			) : (
				<React.Fragment>
					<Body as="span" size="medium" weight={700} UNSAFE_style={{ paddingTop: '8px' }}>
						{t('jobTracker.noTrackerData')}
					</Body>
					<Body as="span" size="small" weight={400} UNSAFE_style={{ textAlign: 'center' }}>
						{t('jobTracker.noTrackerData_desc')}
					</Body>
				</React.Fragment>
			)}
		</Card>
	);
};

export default JobTracker;
