import React, { useEffect, useState, useRef } from 'react';
import styles from './EditProfile.module.css';
import { useTranslation } from 'react-i18next';
import { BottomSheet, Button, Divider, Modal } from '@walmart-web/livingdesign-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import EditCard from '../../../components/EditCard/EditCard';
import EditProfileModal from './EditProfileModal';
import StudentStatus from '../AboutYou/StudentStatus/StudentStatus';
import MilitaryService from '../AboutYou/MilitaryService/MilitaryService';
import { useDisplay } from '../../../utils/useDisplay';
import {
	setFromEditProfile,
	upateEditProfileJob,
	updateEditAvailability,
	updateEditProfileShowAsmt
} from '../../../redux/EditProfile/Slice';
import { executionErrorOccured, updateLoading } from '../../../redux/slices/globalSlice';
import { getPersonalInfo } from '../../../redux/AboutYou/request';
import { getMilitaryInfo } from '../../../redux/Military/request';
import { militaryDrop } from '../../../config/const';
import { updatePersonalInfo } from '../../../redux/AboutYou/slice';
import { validateMobile } from '../../../utils/validation';
import { ReactComponent as RegistrationComplete } from '@livingdesign/pictograms/dist/svg/Health_Forms_&_Office/RegistrationComplete.svg';
import { ReactComponent as StoreFinder } from '@livingdesign/pictograms/dist/svg/App_&_Web_Services/StoreFinder.svg';
import { ReactComponent as School } from '@livingdesign/pictograms/dist/svg/Back_to_School/School.svg';
import { minPrefQualifications } from '../../../redux/MinimumQualifications/request';

const Icon = require('@livingdesign/icons');

const EditProfile = () => {
	const childRef = useRef(null);
	const global = useSelector((state) => state.global);
	const account = useSelector((state) => state.account);
	const asmtResults = useSelector((state) => state.postApplyDashboard.data.asmtResults);

	const showAsmt =
		Object.keys(asmtResults).length > (account?.data?.auth?.noAsmtJobsPreferred ? 1 : 0);

	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [editStudent, setEditStudent] = useState(false);
	const [editMilitary, setEditMilitary] = useState(false);
	const [highSchool, sethighSchool] = useState(false);
	const [ageCheck1, setAgeCheck1] = useState(false);
	const [ageCheck2, setAgeCheck2] = useState(false);
	const [ageCriteria, setAgeCriteria] = useState('');
	const [student, setStudent] = useState('');
	const [activeDutyExp, setActiveDutyExp] = useState('');
	const [activeDutyList, setActiveDutyList] = useState(0);
	const [guardList, setGuardList] = useState(0);
	const [leftActiveDuty, setLeftActiveDuty] = useState('');
	const [seperationDate, setSeperationDate] = useState('');
	const [spouseStatus, setSpouseStatus] = useState('');
	const [military, setMilitary] = useState(false);
	const [activeDuty, setActiveDuty] = useState(false);
	const [minQualLoaded, setMinQualLoaded] = useState(false);
	const [showMinQual, setShowMinQual] = useState(false);
	const [button, setbutton] = useState(true);
	const { isPortrait } = useDisplay();
	const [personalInfo, setPersonalInfo] = useState({});
	const [phoneNumber, setPhoneNumber] = useState('');
	const [alternatePhoneNumber, setAlternatePhoneNumber] = useState('');
	const [error, setError] = useState({});
	const [initials, setInitials] = useState('');
	const [displayName, setDisplayName] = useState('');
	const militaryDropdown = militaryDrop[global.language === 'ES' ? '102' : '101'];
	const editProfileNo = 'editProfile.no';
	const editProfileDontWish = 'editProfile.dontWish';
	const editProfileEmploymentType = 'editProfile.employmentType';
	const editProfileShiftPreference = 'editProfile.shiftPreference';
	const editProfileAvailableHours = 'editProfile.availableHours';
	const editProfileStudentStatus = 'editProfile.studentStatus';
	const editProfileEdit = 'editProfile.edit';

	useEffect(() => {
		dispatch(updateEditAvailability(false));
		dispatch(setFromEditProfile(false));
		dispatch(updateLoading(true));
		getProfileDetails();
		getMilitaryDetails();
		getMinQuals();
		// eslint-disable-next-line
	}, []);

	const getProfileDetails = () => {
		const requestInput = {
			applicantId: global.applicantId,
			emailId: global.emailId
		};

		dispatch(getPersonalInfo(requestInput))
			.then((res) => {
				const data = res.payload;
				let name;
				dispatch(updatePersonalInfo(data));
				setPersonalInfo(data);
				if (Object.keys(data).length !== 0) {
					data.hcTestResponse.forEach((obj) => {
						if (obj.questionNbr === 44 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
							if (obj.answerNbr === 119) {
								sethighSchool(true);
								setStudent('editProfile.highSchool');
							} else if (obj.answerNbr === 120) {
								setStudent('editProfile.college');
							} else if (obj.answerNbr === 118) {
								setStudent('editProfile.notStudent');
							}
						}
						if (obj.questionNbr === 4 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
							if (obj.answerNbr === 93) {
								setAgeCheck1(true);
								setAgeCriteria('editProfile.ageCriteria1');
							} else if (obj.answerNbr === 94) {
								setAgeCheck2(true);
								setAgeCriteria('editProfile.ageCriteria2');
							} else if (obj.answerNbr === 95) {
								setAgeCriteria('editProfile.ageCriteria3');
							}
						}
					});

					if (data.preferredFirstName !== '' && data.preferredLastName !== '') {
						name = data.preferredFirstName + ' ' + data.preferredLastName;
						setInitials(data.preferredFirstName.slice(0, 1) + data.preferredLastName.slice(0, 1));
					} else if (data.preferredFirstName === '' && data.preferredLastName !== '') {
						name = data.firstName + ' ' + data.preferredLastName;
						setInitials(data.firstName.slice(0, 1) + data.preferredLastName.slice(0, 1));
					} else if (data.preferredFirstName !== '' && data.preferredLastName === '') {
						name = data.preferredFirstName + ' ' + data.lastName;
						setInitials(data.preferredFirstName.slice(0, 1) + data.lastName.slice(0, 1));
					} else if (data.preferredFirstName === '' && data.preferredLastName === '') {
						name = data.firstName + ' ' + data.lastName;
						setInitials(data.firstName.slice(0, 1) + data.lastName.slice(0, 1));
					}
					setDisplayName(name);

					dispatch(
						validateMobile(data.phoneNumber.replaceAll('-', ''), error, setError, setPhoneNumber)
					);

					if (data.alternatePhoneNumber && data.alternatePhoneNumber !== '') {
						dispatch(
							validateMobile(
								data.alternatePhoneNumber.replaceAll('-', ''),
								error,
								setError,
								setAlternatePhoneNumber
							)
						);
					}
				}
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const getMilitaryDetails = () => {
		let actAnswr = 0;
		const req = { applicantId: global.applicantId };
		dispatch(getMilitaryInfo(req))
			.then((res) => {
				const data = res.payload;
				const answersList = data.answersList;

				let actDropAnswr = 0,
					guardDropAnswr = 0,
					leftAnswr = 0,
					sepAnswr = '',
					spouseAnswr = 0;
				if (answersList?.length > 0) {
					answersList.forEach((obj) => {
						if (obj.questionNumber === 1) {
							actAnswr = obj.optionNumber;
							if (actAnswr === 1) {
								setMilitary(true);
								setActiveDutyExp('editProfile.yes');
							} else if (actAnswr === 2) {
								setActiveDutyExp(editProfileNo);
							} else if (actAnswr === 4) {
								setActiveDutyExp(editProfileDontWish);
							}
						} else if (obj.questionNumber === 2) {
							actDropAnswr = obj.optionNumber;
							setActiveDutyList(actDropAnswr);
						} else if (obj.questionNumber === 3) {
							guardDropAnswr = obj.optionNumber;
							setGuardList(guardDropAnswr);
						} else if (obj.questionNumber === 4) {
							leftAnswr = obj.optionNumber;
							if (leftAnswr === 1) {
								setActiveDuty(true);
								setLeftActiveDuty('editProfile.yes');
							} else if (leftAnswr === 2) {
								setLeftActiveDuty(editProfileNo);
							} else if (leftAnswr === 4) {
								setLeftActiveDuty(editProfileDontWish);
							}
						} else if (obj.questionNumber === 5) {
							sepAnswr = obj.responseTxt;
							if (sepAnswr !== '') {
								setSeperationDate(sepAnswr);
							}
						} else if (obj.questionNumber === 51) {
							spouseAnswr = obj.optionNumber;
							if (spouseAnswr === 25) {
								setSpouseStatus('military.spouseactiveduty');
							} else if (spouseAnswr === 26) {
								setSpouseStatus('military.spouseguard');
							} else if (spouseAnswr === 27) {
								setSpouseStatus('military.spouseveteran');
							} else if (spouseAnswr === 4) {
								setSpouseStatus(editProfileDontWish);
							} else if (spouseAnswr === 2) {
								setSpouseStatus(editProfileNo);
							}
						}
					});
				}
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const getMinQuals = () => {
		setMinQualLoaded(false);
		dispatch(minPrefQualifications({ applicantId: global.applicantId }))
			.then((res) => {
				setMinQualLoaded(true);
				setShowMinQual(
					res.payload.response.scQuals?.minQualsList?.length > 0 ||
						res.payload.response.shQuals?.minQualsList?.length > 0
				);
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const checkMilitaryResponse = (value) => {
		if (value === '200' || value === '201') {
			setEditMilitary(false);
			getMilitaryDetails();
			setMilitary(false);
		}
	};
	const navigateTo = (title) => {
		if (
			title === t(editProfileEmploymentType) ||
			title === t(editProfileShiftPreference) ||
			title === t(editProfileAvailableHours)
		) {
			dispatch(updateEditAvailability(true));
			if (isPortrait) {
				if (title === t(editProfileEmploymentType)) {
					navigate('../availability/employmentType');
				} else if (title === t(editProfileShiftPreference)) {
					navigate('../availability/shiftPreference');
				} else if (title === t(editProfileAvailableHours)) {
					navigate('../availability/availableHours');
				}
			} else {
				navigate('../availability');
			}
		} else if (title === t('editProfile.employmentHistory')) {
			navigate('../edit-profile-job-history');
		} else if (title === t('editProfile.certificates')) {
			dispatch(upateEditProfileJob(true));
			navigate('../edit-profile-certificates-licenses');
		} else {
			dispatch(setFromEditProfile(true));
			dispatch(updateEditProfileShowAsmt(showAsmt));
			if (title === t('editProfile.qualifications')) {
				navigate('../minimum-qualifications');
			} else if (title === t('editProfile.assessments')) {
				navigate('../assessments');
			}
		}
	};

	const navigationSection = (title, line) => {
		return (
			<div
				className={line ? styles.navigationSectionLine : styles.navigationSection}
				onClick={() => navigateTo(title)}>
				<div className={styles.editProfileInfoText}>{title}</div>
				<Icon.ChevronRight size="medium" />
			</div>
		);
	};

	const handleDrawer = (value) => {
		setDrawerOpen(value);
	};

	const handlePersonalInfo = () => {
		getProfileDetails();
	};
	const nameDrawer = () => {
		return (
			<EditProfileModal
				drawerOpen={drawerOpen}
				handleDrawer={handleDrawer}
				personalInfo={personalInfo}
				handlePersonalInfo={handlePersonalInfo}
			/>
		);
	};

	const handleEditStudent = () => {
		return (
			<div>
				{!isPortrait && (
					<Modal
						size="medium"
						isOpen={editStudent}
						onClose={() => setEditStudent(false)}
						title={t(editProfileStudentStatus)}>
						<StudentStatus editProfile={true} handleCloseEdit={handleCloseEdit} />
					</Modal>
				)}

				{isPortrait && (
					<BottomSheet
						isOpen={editStudent}
						onClose={() => setEditStudent(false)}
						title={t(editProfileStudentStatus)}>
						<StudentStatus editProfile={true} handleCloseEdit={handleCloseEdit} />
					</BottomSheet>
				)}
			</div>
		);
	};

	const handleMilitaryButton = () => {
		childRef.current.handleButton();
	};

	const handleCloseEdit = () => {
		setEditStudent(false);
		getProfileDetails();
		sethighSchool(false);
		setAgeCheck1(false);
		setAgeCheck2(false);
	};

	const handleEditMilitary = () => {
		return (
			<div>
				{!isPortrait && (
					<Modal
						size="medium"
						isOpen={editMilitary}
						onClose={() => setEditMilitary(false)}
						title={t('editProfile.militaryService')}
						actions={
							<Button
								onClick={handleMilitaryButton}
								disabled={button}
								isFullWidth
								size="medium"
								variant="primary">
								{t('editProfile.save')}
							</Button>
						}>
						<MilitaryService
							ref={childRef}
							handleDisableButton={handleDisableButton}
							editProfile={true}
							checkMilitaryResponse={checkMilitaryResponse}
						/>
					</Modal>
				)}

				{isPortrait && (
					<BottomSheet
						isOpen={editMilitary}
						onClose={() => setEditMilitary(false)}
						title={t('editProfile.militaryService')}
						actions={
							<Button
								onClick={handleMilitaryButton}
								disabled={button}
								isFullWidth
								size="medium"
								variant="primary">
								{t('editProfile.save')}
							</Button>
						}>
						<MilitaryService
							ref={childRef}
							handleDisableButton={handleDisableButton}
							editProfile={true}
							checkMilitaryResponse={checkMilitaryResponse}
						/>
					</BottomSheet>
				)}
			</div>
		);
	};

	const handleDisableButton = () => {
		if (childRef.current !== null) {
			const val = childRef.current.shouldDisableContinue();
			setbutton(val);
		} else {
			setbutton(true);
		}
	};

	const getActiveDutyName = () => {
		let ans;
		militaryDropdown.forEach((obj) => {
			if (Number(obj.value) === activeDutyList) {
				ans = obj.viewValue;
			}
		});
		return ans;
	};
	const getGuardDutyName = () => {
		let ans;
		militaryDropdown.forEach((obj) => {
			if (Number(obj.value) === guardList) {
				ans = obj.viewValue;
			}
		});
		return ans;
	};

	return (
		<div className={styles.main}>
			<div className={styles.blueHead}></div>
			<div className={styles.container}>
				<div className={styles.editProfileAvatarHolder}>
					<div className={styles.editProfileAvatar}>{initials}</div>
				</div>
				<div className={styles.editProfileName}>{displayName}</div>
				<div className={styles.editProfileAvatarHolder}>
					<div className={styles.editProfileYellowBar}></div>
				</div>
				{nameDrawer()}
				{handleEditStudent()}
				{handleEditMilitary()}
				<EditCard title={t('editProfile.personalInfo')} icon={<Icon.User size="medium" />}>
					<div className={styles.editProfileCardSection}>
						<div className={styles.editProfileSection}>
							<div className={styles.editProfileSubText}>{t('editProfile.name')}</div>
							<div className={styles.editProfileInfoText}>
								{Object.keys(personalInfo).length !== 0
									? personalInfo.firstName +
									  ' ' +
									  personalInfo.middleName +
									  ' ' +
									  personalInfo.lastName
									: ''}
							</div>
						</div>
					</div>
					<div className={styles.editProfileCardSection}>
						<div className={styles.editProfileSection}>
							<div className={styles.editProfileSubText}>{t('editProfile.preferedName')}</div>
							<div className={styles.editProfileInfoText}>
								{personalInfo.preferredFirstName === '' && personalInfo.preferredLastName === ''
									? '-'
									: displayName}
							</div>
						</div>
					</div>

					<div className={styles.editProfileCardSection}>
						<div className={styles.editProfileSection}>
							<div className={styles.editProfileSubText}>{t('editProfile.zipCode1')}</div>
							<div className={styles.editProfileInfoText}>
								<div>{Object.keys(personalInfo).length !== 0 ? personalInfo.zipCode : ''}</div>
							</div>
						</div>
					</div>
					<div className={styles.editProfileCardSection}>
						<div className={styles.editProfileSection}>
							<div className={styles.editProfileSubText}>{t('editProfile.phone')}</div>
							<div className={styles.editProfileInfoText}>
								<div>{Object.keys(personalInfo).length !== 0 ? phoneNumber : ''}</div>
							</div>
						</div>
					</div>

					<div className={styles.editProfileCardSection}>
						<div className={styles.editProfileSection}>
							<div className={styles.editProfileSubText}>{t('editProfile.alternative')}</div>
							<div className={styles.editProfileInfoText}>
								<div>{personalInfo.alternatePhoneNumber !== '' ? alternatePhoneNumber : '-'}</div>
								<Button
									variant="tertiary"
									size="medium"
									onClick={() => setDrawerOpen(true)}
									UNSAFE_style={{ padding: 0, height: 'auto' }}>
									{t(editProfileEdit)}
								</Button>
							</div>
						</div>
					</div>
					<Divider />
					<div className={styles.editProfileCardSection} style={{ paddingBottom: '12px' }}>
						<div className={styles.editProfileSection}>
							<div className={styles.editProfileSubText}>{t('editProfile.email')}</div>
							<div className={styles.editProfileInfoText}>
								<div>{global.emailId}</div>
							</div>
						</div>
					</div>
				</EditCard>
				<EditCard
					title={t(editProfileStudentStatus)}
					icon={<School size="medium" className={styles.cardIcon} />}>
					<div className={styles.editProfileCardSection}>
						<div className={styles.editProfileSection}>
							<div className={styles.editProfileSubText}>{t('editProfile.ageCriteria')}</div>
							<div className={styles.editProfileInfoText}>
								<div>{t(ageCriteria)}</div>
							</div>
						</div>
					</div>
					{(highSchool || ageCheck1 || ageCheck2) && (
						<div>
							<div className={styles.editProfileCardSection}>
								<div className={styles.editProfileSection}>
									<div className={styles.editProfileSubText}>{t('editProfile.parentName')}</div>
									<div className={styles.editProfileInfoText}>
										<div>
											{Object.keys(personalInfo).length !== 0 ? personalInfo.parentFullName : ''}
										</div>
									</div>
								</div>
							</div>
							<div className={styles.editProfileCardSection}>
								<div className={styles.editProfileSection}>
									<div className={styles.editProfileSubText}>{t('editProfile.parentEmail')}</div>
									<div className={styles.editProfileInfoText}>
										<div>
											{Object.keys(personalInfo).length !== 0 ? personalInfo.parentEmailId : ''}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className={styles.editProfileCardSection} style={{ paddingBottom: '12px' }}>
						<div className={styles.editProfileSection}>
							<div className={styles.editProfileSubText}>{t(editProfileStudentStatus)}</div>
							<div className={styles.editProfileInfoText}>
								<div>{t(student)}</div>
								<Button
									variant="tertiary"
									size="medium"
									onClick={() => setEditStudent(true)}
									UNSAFE_style={{ padding: 0, height: 'auto' }}>
									{t(editProfileEdit)}
								</Button>
							</div>
						</div>
					</div>
				</EditCard>
				<EditCard
					title={t('editProfile.militaryStatus')}
					icon={<Icon.WalmartProtectionPlan size="medium" />}>
					<div className={styles.editProfileCardSection}>
						<div className={styles.editProfileSection}>
							<div className={styles.editProfileSubText}>{t('editProfile.activeDuty')}</div>
							<div className={styles.editProfileInfoText}>
								<div>{t(activeDutyExp)}</div>
							</div>
						</div>
					</div>
					{military && (
						<div>
							<div className={styles.editProfileCardSection}>
								<div className={styles.editProfileSection}>
									<div className={styles.editProfileSubText}>
										{t('editProfile.typeofActiveDuty')}
									</div>
									<div className={styles.editProfileInfoText}>
										<div>{getActiveDutyName()}</div>
									</div>
								</div>
							</div>
							<div className={styles.editProfileCardSection}>
								<div className={styles.editProfileSection}>
									<div className={styles.editProfileSubText}>{t('editProfile.typeOfGuard')}</div>
									<div className={styles.editProfileInfoText}>{getGuardDutyName()}</div>
								</div>
							</div>
							<div className={styles.editProfileCardSection}>
								<div className={styles.editProfileSection}>
									<div className={styles.editProfileSubText}>{t('editProfile.left')}</div>
									<div className={styles.editProfileInfoText}>
										<div>{t(leftActiveDuty)}</div>
									</div>
								</div>
							</div>
							{activeDuty && (
								<div className={styles.editProfileCardSection}>
									<div className={styles.editProfileSection}>
										<div className={styles.editProfileSubText}>
											{t('editProfile.seperationDate')}
										</div>
										<div className={styles.editProfileInfoText}>
											<div>{seperationDate}</div>
										</div>
									</div>
								</div>
							)}
						</div>
					)}

					<div className={styles.editProfileSection} style={{ paddingBottom: '12px' }}>
						<div className={styles.editProfileSubText}>{t('editProfile.spouseStatus')}</div>
						<div className={styles.editProfileInfoText}>
							<div>{t(spouseStatus)}</div>
							<Button
								variant="tertiary"
								size="medium"
								onClick={() => setEditMilitary(true)}
								UNSAFE_style={{ padding: 0, height: 'auto' }}>
								{t(editProfileEdit)}
							</Button>
						</div>
					</div>
				</EditCard>
				<EditCard title={t('editProfile.availability')} icon={<Icon.Clock size="medium" />}>
					{navigationSection(t(editProfileEmploymentType), true)}
					<Divider />
					{navigationSection(t(editProfileShiftPreference), true)}

					{!account.data.auth.onlyScJobsPreferred && (
						<>
							<Divider />
							{navigationSection(t(editProfileAvailableHours), false)}
						</>
					)}
				</EditCard>
				<EditCard
					title={t('editProfile.jobHistory')}
					icon={<StoreFinder size="medium" className={styles.cardIcon} />}>
					{navigationSection(t('editProfile.employmentHistory'), true)}
					<Divider />
					{navigationSection(t('editProfile.certificates'), false)}
				</EditCard>
				{minQualLoaded && (showMinQual || showAsmt) && (
					<EditCard
						title={t('editProfile.jobQualifications')}
						icon={<RegistrationComplete size="medium" className={styles.cardIcon} />}>
						{showMinQual && navigationSection(t('editProfile.qualifications'), true)}
						{showAsmt && (
							<>
								{showMinQual && <Divider />}
								{navigationSection(t('editProfile.assessments'), false)}
							</>
						)}
					</EditCard>
				)}
			</div>
		</div>
	);
};

export default EditProfile;
