import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';
import { apiRequestErrorOccured, updateLoading } from '../slices/globalSlice';


const getAvailability = createAsyncThunk(
	'vjo/getAvailability',
	async (applicantId, { dispatch }) => {
		dispatch(updateLoading(true));
		try {
			const baseURL =
				environment.hcServiceBaseGetUrl + `/getAvailability?applicantId=${applicantId}`;
			const response = await axiosInstance.get(baseURL);
			dispatch(updateLoading(false));
			return response.data;
		} catch (error) {
			dispatch(apiRequestErrorOccured(error));
		}
	}
);

const getAvailabilityPreference = createAsyncThunk(
	'availability/getAvailabilityPreference',
	async (applicantId, { dispatch }) => {
		dispatch(updateLoading(true));
		try {
			const baseURL = environment.eaPreferenceServiceBaseGetUrl + `/availability?applicantId=${applicantId}`;
			const response = await axiosInstance.get(baseURL);
			dispatch(updateLoading(false));
			return response.data;
		} catch (error) {
			dispatch(apiRequestErrorOccured(error));
		}
	}
);

const saveAvailabilityPreference = createAsyncThunk(
	'availability/saveAvailabilityPreference',
	async (availRequest, { dispatch }) => {
		dispatch(updateLoading(true));
		try {
			const baseURL = environment.eaPreferenceServiceBasePostUrl + `/availability`;
			const response = await axiosInstance.post(baseURL,availRequest);
			dispatch(updateLoading(false));
			return response.data;
		} catch (error) {
			dispatch(apiRequestErrorOccured(error));
		}
	}
);

export { getAvailability, getAvailabilityPreference ,saveAvailabilityPreference};
