import { createSlice } from '@reduxjs/toolkit';
import { getJobSearch, postJobSearch } from './request';

const initialState = { data: {}, loading: false, error: {} };

const jobSearchSlice = createSlice({
  name: 'jobSearch',
  initialState,
  reducers: {
    updateIs18: (state, action) => {
      state.is18 = action.payload;
      }
  },
  extraReducers(builder) {
    builder
      .addCase(getJobSearch.fulfilled, (state, action) => {
        state.loading = true;
      })
      .addCase(getJobSearch.rejected, (state, action) => {
        state.error = { message: 'Something went wrong' };
      })
      .addCase(postJobSearch.fulfilled, (state, action) => {
        state.loading = true;
      })
      .addCase(postJobSearch.rejected, (state, action) => {
        state.error = { message: 'Something went wrong' };
      });
  }
});

export const {
  updateIs18
} = jobSearchSlice.actions;

export default jobSearchSlice.reducer;
