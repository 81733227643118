import { BottomSheet, Modal } from "@walmart-web/livingdesign-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updatePreferredLangOpen } from "../../../redux/slices/globalSlice";
import style from "./PreferredLanguage.module.css";
import { useMediaQuery } from "react-responsive";
import PreferredLanguage from "./PreferredLanguage";

const LanguageSection = () => {
  const isDesktop = useMediaQuery({ minWidth: 851 });
  const iPadLandscape = useMediaQuery({
    minWidth: 426,
    maxWidth: 850,
    orientation: "landscape",
  });
  const iPadPortrait = useMediaQuery({
    minWidth: 426,
    maxWidth: 850,
    orientation: "portrait",
  });
  const isMobile = useMediaQuery({ maxWidth: 425 });
  const global = useSelector((state) => state.global);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <React.Fragment>
      {(isDesktop || iPadLandscape) && (
        <Modal
          isOpen={global.preferredLangOpen}
          onClose={() => dispatch(updatePreferredLangOpen(false))}
          title={t("general.preferredLang")}
          UNSAFE_className={style.overlay}
        >
          <PreferredLanguage />
        </Modal>
      )}
      {(isMobile || iPadPortrait) && (
        <BottomSheet
          isOpen={global.preferredLangOpen}
          onClose={() => dispatch(updatePreferredLangOpen(false))}
          title={t("general.preferredLang")}
          UNSAFE_className={style.overlay}
        >
          <PreferredLanguage />
        </BottomSheet>
      )}
    </React.Fragment>
  );
};

export default LanguageSection;
