import {
  Body,
  Button,
  Heading,
  Select,
} from '@walmart-web/livingdesign-components';
import React, {memo, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import {useTranslation} from 'react-i18next';
import {useDisplay} from 'utils/useDisplay';
import AlertDialog from 'components/AlertDialog';
import {useDeclineOffer} from './useDeclineOffer';
/**
 * Decline offer view for the VJO login screen
 * @param isOpen - Show/hide view
 * @param onClose -on close click
 * @returns Modal for Desktop and BottomSheet for Mobile
 */
const DeclineOffer = ({isOpen, onClose}) => {
  const [value, setValue] = useState('0');
  const {t} = useTranslation();
  const {isMobile} = useDisplay();
  const {onDeclineOffer} = useDeclineOffer();
  const reasons = t('declineOffer.reasons', {returnObjects: true});

  const onCloseClick = useCallback(() => {
    setValue(0);
    onClose();
  }, [onClose]);

  // Content view
  const ReasonsView = useMemo(() => {
    return (
      <div className='container'>
        <Body
          size='medium'
          children={t('declineOffer.description')}
          UNSAFE_className='decline_description'
        />
        <Body
          size='small'
          weight={700}
          children={t('declineOffer.reasonHeader')}
          UNSAFE_className='decline_reasonHeader'
        />
        <Select
          onChange={(event) => {
            setValue(event.target.value);
          }}
          value={value}
          title={t('declineOffer.reasonHeader')}>
          {Array.isArray(reasons) && reasons.map((object, index) => {
            return (
              <option value={object.value} key={index}>
                {object.reason}
              </option>
            );
          })}
        </Select>
      </div>
    );
  }, [t, value, reasons]);

  // Submit button view
  const submitButton = useMemo(() => {
    const disable = Number(value) === 0;
    return (
      <div className='decline_submit'>
        <Button
          size='medium'
          UNSAFE_className='decline_button'
          variant='primary'
          children={t('declineOffer.Submit')}
          disabled={disable}
          onClick={() => onDeclineOffer(value)}
        />
      </div>
    );
  }, [t, value, onDeclineOffer]);

  const TitleView = useMemo(() => {
    // Mobile view
    if (isMobile) {
      return (
        <Heading
          size='medium'
          weight={700}
          children={t('declineOffer.title')}
        />
      );
    }
    //Desktop View
    return (
      <div className={'decline_header'}>
        <Heading
          size='medium'
          weight={700}
          children={t('declineOffer.title')}
        />
      </div>
    );
  }, [isMobile, t]);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onCloseClick}
      title={TitleView}
      children={ReasonsView}
      actions={submitButton}
    />
  );
};
/**
 * PropTypes for the DeclineOffer component
 */
DeclineOffer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default memo(DeclineOffer);
