import React, { useEffect } from 'react';
import { Button, TextField, Alert } from '@walmart-web/livingdesign-components';
import styles from './ApplicantLogin.module.css';
import { environment } from '../../utils/environment/environment';
import { useDispatch } from 'react-redux';
import {
	apiRequestErrorOccured,
	displayFooter,
	errorOccured,
	loggedInApplicantId,
	loggedInEmailId,
	updateLoading
} from '../../redux/slices/globalSlice';
import { useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';
import axiosInstance from '../../utils/axiosInstance';
import { getOutagePeriod } from 'redux/Disclaimer/request';

const ApplicantLogin = () => {
	const [applicantId, setApplicantId] = React.useState('');
	const [emailId, setEmailId] = React.useState('');
	const [accessCode, setAccessCode] = React.useState('');
	const [error, setError] = React.useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleApplicantIdInput = (e) => {
		if (error) {
			setError(false);
		}
		setApplicantId(e.target.value);
	};
	const handleEmailIdInput = (e) => {
		if (error) {
			setError(false);
		}
		setEmailId(e.target.value);
	};
	const handleAccessCodeInput = (e) => {
		if (error) {
			setError(false);
		}
		setAccessCode(e.target.value);
	};
	const submit = async (event) => {
		event.preventDefault();
		dispatch(updateLoading(true));
		const checkPassCodeUrl =
			environment.envName === 'dev' && window.location.hostname === 'localhost'
				? environment.hcServiceBasePostUrl + '/checkPassCode'
				: '/HiringCenter/postCheckPassCode';
		await axiosInstance
			.post(checkPassCodeUrl, { applicantId: applicantId, passCode: accessCode, emailId: emailId })
			.then((res) => {
				if (res.data && res.data.valid) {
					cookie.save('userId', applicantId);
					cookie.save('emailId', res.data.email);
					cookie.save('fromApplicantLogin', 'Y');
					dispatch(updateLoading(false));
					navigate('../login');
				} else {
					dispatch(updateLoading(false));
					setError(true);
				}
			})
			.catch((e) => {
				let errorObj;
				if (e && e.response && e.response.data && e.response.data.responseMessage) {
					errorObj = {
						open: true,
						title: 'Error!',
						responseMessage: e.response.data.responseMessage
					};
				} else {
					errorObj = { open: true, title: 'Error!', responseMessage: 'System Error Occured' };
				}
				dispatch(errorOccured(errorObj));
				dispatch(updateLoading(false));
			});
	};

	const validateApplEmail = async (applId, email) => {
		dispatch(updateLoading(true));
		await axiosInstance
			.get(
				`${environment.hcServiceBaseGetUrl}/validateApplicantEmail?applicantId=${applId}&emailId=${email}`
			)
			.then((res) => {
				if (res.data) {
					cookie.save('fromApplicantLogin', 'Y');
					navigate('../login');
				}
			})
			.catch((err) => {
				dispatch(apiRequestErrorOccured(err));
			});
	};
	useEffect(() => {
		dispatch(displayFooter(true));
		const hostname = window && window.location && window.location.hostname;

		if (
			hostname === process.env.REACT_APP_hostname_stage_EXT ||
			hostname === process.env.REACT_APP_hostname_prod_EXT
		) {
			dispatch(getOutagePeriod())
				.unwrap()
				.then((result) => {
					if (!result.maintanance) {
						navigate('../disclaimer');
					}
				});
		}

		cookie.save('landedOnApplicantLogin', 'Y');
		const applId = cookie.load('userId') ? cookie.load('userId') : 0;
		dispatch(loggedInApplicantId(applId));

		const email = cookie.load('emailId') ? cookie.load('emailId') : '';
		dispatch(loggedInEmailId(email));

		if (!(!applId || applId === 0 || !email || email === '')) {
			validateApplEmail(applId, email);
		}
		// eslint-disable-next-line
	}, []);
	return (
		<div className={styles.screen}>
			<div>Welcome To Applicant Login</div>
			<form onSubmit={submit}>
				<div className={styles.form}>
					<div className={styles.fields}>
						<TextField
							label="Applicant Id*"
							type="text"
							textFieldProps={{
								inputMode: 'numeric'
							}}
							value={applicantId}
							onChange={(e) => handleApplicantIdInput(e)}></TextField>
					</div>
					<div className={styles.fields}>
						<TextField
							label="Email Id*"
							type="email"
							value={emailId}
							onChange={(e) => handleEmailIdInput(e)}></TextField>
					</div>
					<div className={styles.fields}>
						<TextField
							label="Access Code*"
							value={accessCode}
							type="password"
							onChange={(e) => handleAccessCodeInput(e)}></TextField>
					</div>

					<div>
						<Button
							size="medium"
							variant="primary"
							disabled={applicantId === 0 || accessCode === ''}
							type="submit"
							onClick={submit}>
							Login
						</Button>
					</div>
				</div>
			</form>

			<div>{error ? <Alert variant="error">Invalid Credentials</Alert> : ''}</div>
		</div>
	);
};

export default ApplicantLogin;
