import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';
import { apiRequestErrorOccured, updateLoading } from '../slices/globalSlice';

const getListOfCities = createAsyncThunk('location/getAllCities', async (_, { dispatch }) => {
	try {
		const baseUrl = environment.securityServiceBaseGetUrl + '/allCities';
		const response = await axiosInstance.get(baseUrl);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});

const getAllFacilities = createAsyncThunk(
	'location/getAllFacilities',
	async (request, { dispatch }) => {
		try {
			const baseUrl = environment.eaPreferenceServiceBaseGetUrl.concat(
				'/location/search?' + request
			);
			const response = await axiosInstance.get(baseUrl);
			return response.data;
		} catch (error) {
			if(error.response?.data?.responseCode === 1001){
				return error.response.data;
			}
			else{
				dispatch(apiRequestErrorOccured(error));
			}
		}
	}
);

const getStoreDetails = createAsyncThunk('/location/stores', async (req, { dispatch }) => {
	try {
		const baseURL = `${
			environment.hcServiceBaseGetUrl
		}/location/storeDetails?storeNbrs=${req?.storeNbrs?.join(',')}&applicantId=${req?.applicantId}`;
		const response = await axiosInstance.get(baseURL);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});



const saveLocationPreference = createAsyncThunk(
	'location/savePreference',
	async (payload, { dispatch }) => {
    try {
      const baseURL = environment.eaPreferenceServiceBasePostUrl + '/location/preferences';
      const response = await axiosInstance.post(baseURL, payload);
      dispatch(updateLoading(false));
      return response.data;
    } catch (error) {
      dispatch(apiRequestErrorOccured(error));
    }
  });

export { getListOfCities, getAllFacilities, saveLocationPreference, getStoreDetails };
