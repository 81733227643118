import { createSlice } from '@reduxjs/toolkit';
import { postWithDraw } from './request';

const initialState = { error: {} };

const appliedJobTrackerSlice = createSlice({
  name: 'appliedJobTracker',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(postWithDraw.rejected, (state, action) => {
      state.error = { message: 'Something went wrong' };
    });
  }
});

export default appliedJobTrackerSlice.reducer;
