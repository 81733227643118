import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequestErrorOccured } from 'redux/slices/globalSlice';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';

const getJobSearch = createAsyncThunk(
  'jobSearch/getJobSearch',
  async (req, { dispatch }) => {
    try {
      const baseURL = environment.eaPreferenceServiceBaseGetUrl + '/job/search';
      const response = await axiosInstance.get(baseURL + "?applicantId="+req.applicantId+"&stores=" + req.stores);
      return response.data;
    } 
    catch (error) {
        dispatch(apiRequestErrorOccured(error));
    }
  }
);
const postJobSearch = createAsyncThunk(
  'jobSearch/postJobSearch',
  async (selectedJobs, { dispatch }) => {
    try {
      const baseURL = environment.eaPreferenceServiceBasePostUrl + '/job/preference';
      const response = await axiosInstance.post(baseURL, selectedJobs);
      return response.data;
    } 
    catch (error) {
        dispatch(apiRequestErrorOccured(error));
    }
  }
);

export { getJobSearch, postJobSearch };
