import {createSlice} from '@reduxjs/toolkit';
import {getUpdatePreferences} from './request';

const initialState = {isUpdated: false, loading: false, error: {}};

const updatePreferencesSlice = createSlice({
  name: 'updatePreferences',
  initialState,
  reducers: {
    reset: (state) => {
      state.isUpdated = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUpdatePreferences.fulfilled, (state, action) => {
        if (action.payload.responseCode !== '200') {
          state.error = {message: 'Something went wrong'};
        }
        state.isUpdated = action.payload.responseCode === '200';
      })
      .addCase(getUpdatePreferences.rejected, (state) => {
        state.error = {message: 'Something went wrong'};
      });
  },
});

export const {updatePreferences, reset} = updatePreferencesSlice.actions;

export default updatePreferencesSlice.reducer;
