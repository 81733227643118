import { User, Calendar, Check } from '@livingdesign/icons';
import { Body, Card, Heading, Button } from '@walmart-web/livingdesign-components';
import React, { useState, Suspense, useMemo,useEffect } from 'react';
import TextBanner from './Components/TextBanner';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	expandIcon
} from '../../components/Accordion/MuiAccordion';
import './style.scss';
import style from '../Welcome/ApplicationPanel/ApplicationPanel.module.scss';
import ContactInformation from './Components/ContactInformation';
import { useDispatch, useSelector } from 'react-redux';
import JobPreference from './Components/ReviewJobPreference';
import ShiftAvailability from './Components/ShiftAvailability';
import ServicesIcon from '../../assets/images/Services.svg';
import FooterView from './Components/Footer';
import TaxQuestionnaire from 'pages/Application/TaxQuestionnaire/TaxQuestionnaire';
import { useTranslation } from 'react-i18next';
import { renewPanelClicked, setWotcCompleted, setWotcDownError} from 'redux/Renew/slice';
import { isEmptyObject } from '../../utils/validation';
import { useNavigate } from 'react-router-dom';
import { useDisplay } from 'utils/useDisplay';
import RegistrationConsentMedicalForm from '@livingdesign/pictograms/dist/svg/Health_Forms_&_Office/RegistrationConsentMedicalForm.svg';
import AlertDialog from 'components/AlertDialog';


function RenewApplication() {
	const { t } = useTranslation();
	const { isLandscape } = useDisplay();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [expanded, setExpanded] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const { isRenewPanelClicked, isContactPanelError, isRenewAvailPanelError, isJobPanelError,isZipCodeError, isWotcCompleted, isWotcDownError } =
		useSelector((state) => state.renew);
	const global = useSelector((state) => state.global);
	const account = useSelector((state) => state.account);
	const handleChange = (panel) => (_event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
		setRenewPanel(panel);
	};

	const setRenewPanel = (panel) => {
		const renewPanel = { ...isRenewPanelClicked };
		if (panel === 'panel1') {
			renewPanel.panel1 = true;
		} else if (panel === 'panel2') {
			renewPanel.panel2 = true;
		} else if (panel === 'panel3') {
			renewPanel.panel3 = true;
		} else if(panel === 'panel4'){
			renewPanel.panel4 = true;
		}
		dispatch(renewPanelClicked(renewPanel));
	};

	useEffect(()=>{
		if(isWotcCompleted){
			setWotcDone();
		}
	},[isWotcCompleted])

	const setWotcDone = () => {
		setExpanded('');
		setDisabled('true');
		dispatch(setWotcDownError(false));
	};

	const accordionData = useMemo(() => {
		const data = [
			{
				id: 'panel1',
				title: t('renew.contactInfromation'),
				icon:
					isContactPanelError !== '' && !isContactPanelError ? (
						<Check className="check_icon" size="medium" />
					) : (
						<User size="medium" />
					),
				detailComponent: <ContactInformation panel="panel1" expanded={expanded} />
			},
			{
				id: 'panel2',
				title: t('renew.availability'),
				icon:
					isRenewAvailPanelError !== '' && !isRenewAvailPanelError ? (
						<Check className="check_icon" size="medium" />
					) : (
						<Calendar size="medium" />
					),
				detailComponent: <ShiftAvailability panel="panel2" expanded={expanded} />
			},
			{
				id: 'panel3',
				title: t('renew.jobPreference'),
				icon:
					isJobPanelError !== '' && !isJobPanelError ? (
						<Check className="check_icon" size="medium" />
					) : (
						<img style={{ height: '24px' }} alt="" src={ServicesIcon} />
					),
				detailComponent: <JobPreference panel="panel3" expanded={expanded} />
			}
		]
		if (!account.data.applProfileSteps[9]) {
			data.push({
				id: 'panel4',
				title: t('renew.taxQuestionnaire'),
				icon:
					isWotcCompleted !== '' && isWotcCompleted ? (
						<Check className="check_icon" size="medium" />
					) : (
						<img style={{ height: '24px' }} alt="" src={RegistrationConsentMedicalForm} />
					),
				detailComponent: <>
					<AlertDialog
						isOpen={isWotcDownError}
						onClose={setWotcDone}
						title={t('wotc.error')}
						children={<div className='renew_wotc_error'>
							<img
								alt="Error"
								style={{ height: '100px' }}
								src={require('../../../src/assets/images/Error_Message_Media.png')}
							/>
							<Body size="medium" >{t('wotc.continueForward')}</Body></div>}
						actions={<Button size='small' variant='primary' onClick={setWotcDone}>{t('wotc.continueApplication')}</Button>}
					/>
					<TaxQuestionnaire panel="panel4" expanded={expanded} fromRenew={true} />
				</>
			})
		}
		return data;
	},
		// eslint-disable-next-line
		[global.language ,expanded, isContactPanelError, isRenewAvailPanelError, isJobPanelError, isWotcCompleted, isWotcDownError]
	);

	useEffect(() => {
		if (
			global.applicantId &&
			(isEmptyObject(account.data) ||
				isEmptyObject(account.data.auth) ||
				!account.data.auth.expiredApplDate)
		) {
			navigate('../login');
		}
	});

	useEffect(() => {
		if(isZipCodeError){
			setExpanded('panel1');
		}
	}, [isZipCodeError]);

	const getAccodionItems = (item) => {
		return (
			<Accordion disabled={'panel4' === item.id ? disabled :false} expanded={expanded === item.id} onChange={handleChange(item.id)} key={item.id}>
				<AccordionSummary
					expandIcon={expandIcon}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className={style.accordion__summary}>
					{item.icon}
					<Heading as="h1" size="small" weight="700" UNSAFE_className={style.heading}>
						{item?.title}
					</Heading>
				</AccordionSummary>
				<AccordionDetails sx={isLandscape ? { padding: '0 24px' } : { padding: '0 16px' }}>
					<Suspense fallback={<div>Loading</div>}>{item.detailComponent}</Suspense>
				</AccordionDetails>
			</Accordion>
		);
	};

	return (
		<>
			<div className="banner_bg renew_banner_bg">
				<TextBanner />
			</div>
			<Card className="renew_card_container">
				{accordionData.map((item) => getAccodionItems(item))}
			</Card>
			<FooterView />
		</>
	);
}

export default RenewApplication;
