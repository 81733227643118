import { apiRequestErrorOccured, updateLoading } from 'redux/slices/globalSlice';

const { createAsyncThunk } = require('@reduxjs/toolkit');
const { default: axiosInstance } = require('utils/axiosInstance');
const { environment } = require('utils/environment/environment');

const minPrefQualifications = createAsyncThunk('minPrefQualifications', async (request, { dispatch }) => {
	try {
		const baseUrl =
			environment.hcServiceBaseGetUrl + '/minPrefQualifications?applicantId=' + request.applicantId;
		const response = await axiosInstance.get(baseUrl);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});
const saveQualifications = createAsyncThunk('saveQualifications', async (payload, { dispatch }) => {
	dispatch(updateLoading(true));
	try {
		const baseUrl = environment.hcServiceBasePostUrl + '/saveQualifications';
		const response = await axiosInstance.post(baseUrl, payload);
		dispatch(updateLoading(false));
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});

export { minPrefQualifications, saveQualifications };
