import { useEffect, useState } from 'react';
import * as React from 'react';
import {
	FormGroup,
	Heading,
	Checkbox,
	Alert,
	Body,
	Divider,
	Tag
} from '@walmart-web/livingdesign-components';
import mystyle from './ShiftPreference.module.css';
import cookie from 'react-cookies';
import { Store, Truck } from '@livingdesign/icons';
import { useDispatch, useSelector } from 'react-redux';
import { subHeader } from '../../../redux/slices/globalSlice';
import { useTranslation } from 'react-i18next';
import { useDisplay } from '../../../utils/useDisplay';
import { isEmptyObject } from '../../../utils/validation';
import { useNavigate } from 'react-router-dom';
import { AvailabilityHelper } from '../AvailabilityHelper';
import renewstyle from '../../RenewApplication/Components/ShiftAvailability/style.module.css';
import { getAutoFillCookieInfo } from 'utils/availabilityCookieUtils';

const ShiftPreference = ({ shiftPreference, isRenew, setAutoFillsDeselected, declineAutoFillsDeselection }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [cbOpening, setCbOpening] = useState(false);
	const [cbMorning, setCbMorning] = useState(false);
	const [cbMidshift, setCbMidshift] = useState(false);
	const [cbClosing, setCbClosing] = useState(false);
	const [cbOvernight, setCbOvernight] = useState(false);
	const [cbWd1st, setCbWd1st] = useState(false);
	const [cbWd2nd, setCbWd2nd] = useState(false);
	const [cbWd3rd, setCbWd3rd] = useState(false);
	const [cbWe1st, setCbWe1st] = useState(false);
	const [cbWe2nd, setCbWe2nd] = useState(false);
	const [cbWe3rd, setCbWe3rd] = useState(false);
	const shift = useSelector((state) => state.availMain);
	const { isPortrait } = useDisplay();
	const global = useSelector((state) => state.global);
	const jobType = global.jobType;
	const jobCountryType = global.jobCountryType;
	const preferJobWarehouse = global.preferredJobWarehouse;
	const [usWarehouse, setUsWarehouse] = useState(true);
	const [prWarehouse, setPrWarehouse] = useState(false);
	const [cbPr1st, setCbPr1st] = useState(false);
	const [cbPr2nd, setCbPr2nd] = useState(false);
	const [cbPr3rd, setCbPr3rd] = useState(false);
	const [flex7th, setflex7th] = useState(false);
	const [flex4th, setflex4th] = useState(false);
	const { shiftObj } = AvailabilityHelper();
	const [showAutoFillAlert, setShowAutoFillAlert] = useState(false);
	const [autoFillShifts, setAutoFillShifts] = useState([]);
	const jobStore = useSelector((state) => state.jobSearch);
	let uncheckedAFShifts = [];

	const editProfile = useSelector((state) => state.editProfile);

	const shiftPrefObj = {
		opening: cbOpening,
		morning: cbMorning,
		midshift: cbMidshift,
		closing: cbClosing,
		overnight: cbOvernight,
		wd1st: cbWd1st,
		wd2nd: cbWd2nd,
		wd3rd: cbWd3rd,
		we1st: cbWe1st,
		we2nd: cbWe2nd,
		we3rd: cbWe3rd,
		pr1st: cbPr1st,
		pr2nd: cbPr2nd,
		pr3rd: cbPr3rd,
		flex: flex7th,
		flexPr: flex4th,
		scr: 'ShiftPreference'
	};

	const routeToPath = () => {
		if (editProfile.editAvailability) {
			return '/edit-profile';
		} else if (editProfile.shiftUpdateNeeded || editProfile.scheduleUpdateNeeded) {
			return '/login';
		} else {
			return '/availability/employmentType';
		}
	};
	const getShiftDesc = (shiftId) => {
		switch (shiftId) {
			case 18:
				return 'flexPr';
			case 17:
				return 'flex';
			case 10:
				return 'opening';
			case 11:
				return 'morning';
			case 12:
				return 'midshift';
			case 13:
				return 'closing';
			case 14:
				return 'overnight';
			case 1:
				return 'wd1st';
			case 2:
				return 'wd2nd';
			case 3:
				return 'wd3rd';
			case 4:
				return 'we1st';
			case 5:
				return 'we2nd';
			case 6:
				return 'we3rd';
			case 7:
				return 'pr1st';
			case 8:
				return 'pr2nd';
			case 9:
				return 'pr3rd';
			default:
				return 'all';
		}
	};
	useEffect(() => {
		setJobCountryWarehouse();
		setShifts();
		if (!isRenew && isPortrait) {
			dispatch(
				subHeader({
					title: 'availability.shftPref',
					routeTo: routeToPath(),
					display: 'true'
				})
			);
		}

		// eslint-disable-next-line
	}, [
		shift.availability,
		jobType,
		jobCountryType,
		preferJobWarehouse,
		jobStore.currentSelectedJobs
	]);

	useEffect(() =>{
		if(declineAutoFillsDeselection){
			setShifts();
		}
	},[declineAutoFillsDeselection])

	const setShifts = () => {
		const shiftResponse = JSON.parse(JSON.stringify(shift));
		uncheckedAFShifts=[];
		validateEmpType(shiftResponse);
		if (
			!isEmptyObject(shiftResponse) &&
			!isEmptyObject(shiftResponse.availability) &&
			!isEmptyObject(shiftResponse.availability.shiftPreference)
		) {
			const shiftArr = shiftResponse.availability.shiftPreference.shift;
			shiftArr.forEach((val) => {
				if (val) {
					setShiftPreference(shiftObj[val], true);
				}
			});
			validateEmptyShift(shiftArr);
		}
		const autoFillShiftDeclined = getAutoFillCookieInfo('shiftTypeAutoFillDeclined');
		if(!autoFillShiftDeclined){
			const selectJobsShifts = jobStore.currentSelectedJobs.map((i) => i.shiftIds);
			const selectJobsShiftIndList = jobStore.currentSelectedJobs.map((i) => i.teamingShiftIndList);
			let selectedShifts = [];
			if (Array.isArray(selectJobsShifts) && selectJobsShifts.length) {
				selectedShifts = [...new Set(selectedShifts.concat(...selectJobsShifts.filter(Boolean)))];
			}
			if (Array.isArray(selectJobsShiftIndList) && selectJobsShiftIndList.length) {
				selectedShifts = [...new Set(selectedShifts.concat(...selectJobsShiftIndList.filter(Boolean)))];
			}
			if (Array.isArray(selectedShifts) && selectedShifts.length) {
				setAutoFillShifts(selectedShifts);
				selectedShifts.forEach((sId) => {
					if (!shiftPrefObj[getShiftDesc(sId)]) {
						setShiftPreference(shiftObj[sId], true);
						setShowAutoFillAlert(true);
					}
				});
			}
		}
		
	}

	const validateEmpType = (shiftResponse) => {
		if (
			!isRenew &&
			isPortrait &&
			global.applicantId &&
			!isEmptyObject(shiftResponse) &&
			!isEmptyObject(shiftResponse.availability.employmentType)
		) {
			const empType = shiftResponse.availability.employmentType;
			if (!empType.fullTime && !empType.partTime && !empType.temporary) {
				navigate('../availability/employmentType');
			}
		}
	};

	const validateEmptyShift = (shiftArr) => {
		if (shiftArr.length <= 0 && isPortrait && global.applicantId) {
			shiftPreference(shiftPrefObj);
		}
	};

	const checkIfAutoFillsDeslected = (shiftId) =>{
		 if(shiftPrefObj[getShiftDesc(shiftId)]){
			uncheckedAFShifts.push(shiftId);
		 }
		 else{
			uncheckedAFShifts = uncheckedAFShifts.filter((item) => item !== shiftId);
		 } 
		
		setAutoFillsDeselected((uncheckedAFShifts.length !== 0));
	}

	const onChangeShift = (shiftType) => {
		let shiftId = Number(Object.keys(shiftObj).find((key) => shiftObj[key] === shiftType));

		// if (shiftPrefObj[getShiftDesc(shiftId)] && autoFillShifts.includes(shiftId)) {
		// 	setAutoFillsDeselected(true);
		// }
		if (autoFillShifts.includes(shiftId)) {
			checkIfAutoFillsDeslected(shiftId);
		}


		setShiftPreference(shiftType, false);
	};

	const setJobCountryWarehouse = () => {
		if (global.preferredJobWarehouse === 'PR') {
			setPrWarehouse(true);
			setUsWarehouse(false);
		}
	};

	const setShiftPreference = (shiftType, fromUseEffect) => {
		let checked;
		switch (shiftType) {
			case 'Opening':
				checked = fromUseEffect ? true : !cbOpening;
				shiftPrefObj.opening = checked;
				shiftPreference(shiftPrefObj);
				setCbOpening(checked);
				break;
			case 'Morning':
				checked = fromUseEffect ? true : !cbMorning;
				shiftPrefObj.morning = checked;
				shiftPreference(shiftPrefObj);
				setCbMorning(checked);
				break;
			case 'Midshift':
				checked = fromUseEffect ? true : !cbMidshift;
				shiftPrefObj.midshift = checked;
				shiftPreference(shiftPrefObj);
				setCbMidshift(checked);
				break;
			case 'Closing':
				checked = fromUseEffect ? true : !cbClosing;
				shiftPrefObj.closing = checked;
				shiftPreference(shiftPrefObj);
				setCbClosing(checked);
				break;
			case 'Overnight':
				checked = fromUseEffect ? true : !cbOvernight;
				shiftPrefObj.overnight = checked;
				shiftPreference(shiftPrefObj);
				setCbOvernight(checked);
				break;
			case 'Weekday1st':
				checked = fromUseEffect ? true : !cbWd1st;
				shiftPrefObj.wd1st = checked;
				shiftPreference(shiftPrefObj);
				setCbWd1st(checked);
				break;
			case 'Weekday2nd':
				checked = fromUseEffect ? true : !cbWd2nd;
				shiftPrefObj.wd2nd = checked;
				shiftPreference(shiftPrefObj);
				setCbWd2nd(checked);
				break;
			case 'Weekday3rd':
				checked = fromUseEffect ? true : !cbWd3rd;
				shiftPrefObj.wd3rd = checked;
				shiftPreference(shiftPrefObj);
				setCbWd3rd(checked);
				break;
			case 'Weekend1st':
				checked = fromUseEffect ? true : !cbWe1st;
				shiftPrefObj.we1st = checked;
				shiftPreference(shiftPrefObj);
				setCbWe1st(checked);
				break;
			case 'Weekend2nd':
				checked = fromUseEffect ? true : !cbWe2nd;
				shiftPrefObj.we2nd = checked;
				shiftPreference(shiftPrefObj);
				setCbWe2nd(checked);
				break;
			case 'Weekend3rd':
				checked = fromUseEffect ? true : !cbWe3rd;
				shiftPrefObj.we3rd = checked;
				shiftPreference(shiftPrefObj);
				setCbWe3rd(checked);
				break;
			case 'pr1st':
				checked = fromUseEffect ? true : !cbPr1st;
				shiftPrefObj.pr1st = checked;
				shiftPreference(shiftPrefObj);
				setCbPr1st(checked);
				break;
			case 'pr2nd':
				checked = fromUseEffect ? true : !cbPr2nd;
				shiftPrefObj.pr2nd = checked;
				shiftPreference(shiftPrefObj);
				setCbPr2nd(checked);
				break;
			case 'pr3rd':
				checked = fromUseEffect ? true : !cbPr3rd;
				shiftPrefObj.pr3rd = checked;
				shiftPreference(shiftPrefObj);
				setCbPr3rd(checked);
				break;
			case 'flex7th':
				checked = fromUseEffect ? true : !flex7th;
				shiftPrefObj.flex = checked;
				shiftPreference(shiftPrefObj);
				setflex7th(checked);
				break;
			case 'flex4th':
				checked = fromUseEffect ? true : !flex4th;
				shiftPrefObj.flexPr = checked;
				shiftPreference(shiftPrefObj);
				setflex4th(checked);
				break;
			default:
		}
	};
	const onClickPrWarehouse = () => {
		setPrWarehouse(true);
		setUsWarehouse(false);
	};
	const onClickUsWarehouse = () => {
		setUsWarehouse(true);
		setPrWarehouse(false);
	};

	const getClass = (availClass, renewClass) => {
		return !isRenew ? availClass : renewClass;
	};

	return (
		<div className={getClass(mystyle.shiftPreference, renewstyle.shiftPreference)}>
			<FormGroup>
				<div className={getClass(mystyle.formContainer, renewstyle.formContainer)}>
					{(showAutoFillAlert || jobStore.shiftsAutoFilled) && (
						<Alert variant="success">{t('availability.autoFillShiftAlert')}</Alert>
					)}
					<div>
						{!isRenew && (
							<div className={mystyle.shftHeader}>
								<Heading as="h1" size="medium" weight="700" UNSAFE_style={{ paddingBottom: 0 }}>
									{t('availability.shftHeader')}
								</Heading>
							</div>
						)}

						{isRenew && (
							<Body as="p" size="small">
								{t('renew.shftBody')}
							</Body>
						)}
					</div>

					{(jobType === 'store' || jobType === 'both') && (
						<>
							{jobType === 'both' ? (
								<StoreTag />
							) : (
								<Body as="div" size="medium" UNSAFE_style={{ color: '#74767C' }}>
									{t('availability.shftBodyStartNote')}
								</Body>
							)}
							<StoreComponent
								cbOpening={cbOpening}
								cbMorning={cbMorning}
								cbMidshift={cbMidshift}
								cbClosing={cbClosing}
								cbOvernight={cbOvernight}
								onChangeShift={onChangeShift}
								isRenew={isRenew}
							/>
							{jobType === 'both' && <ShiftDivider />}
						</>
					)}

					{(jobType === 'warehouse' || jobType === 'both') && (
						<>
							{jobType === 'both' ? (
								<WarehouseTag />
							) : (
								<Body as="div" size="medium" UNSAFE_style={{ color: '#74767C' }}>
									{t('availability.shftBodyStartNote')}
								</Body>
							)}
							{preferJobWarehouse === 'both' && (
								<div>
									<div className={getClass(mystyle.tab, renewstyle.tab)}>
										{usWarehouse ? (
											<div
												className={getClass(mystyle.tabUS, renewstyle.tabUS)}
												onClick={() => onClickUsWarehouse()}>
												<Body
													as="div"
													UNSAFE_class={mystyle.textUsWhite}
													UNSAFE_style={{ color: 'white' }}
													weight={700}
													size="small">
													{t('general.usCountryName')}
												</Body>
											</div>
										) : (
											<div
												className={getClass(mystyle.tabUsGr, renewstyle.tabUsGr)}
												onClick={() => onClickUsWarehouse()}>
												<Body
													as="div"
													UNSAFE_class={mystyle.textUsNormal}
													weight={700}
													size="small">
													{t('general.usCountryName')}
												</Body>
											</div>
										)}
										{prWarehouse ? (
											<div
												className={getClass(mystyle.tabPr, renewstyle.tabPr)}
												onClick={() => onClickPrWarehouse()}>
												<Body
													as="div"
													UNSAFE_class={mystyle.textPrWhite}
													UNSAFE_style={{ color: 'white' }}
													weight={700}
													size="small">
													{t('general.prCountryName')}
												</Body>
											</div>
										) : (
											<div
												className={getClass(mystyle.tabPrGr, renewstyle.tabPrGr)}
												onClick={() => onClickPrWarehouse()}>
												<Body
													as="div"
													UNSAFE_class={mystyle.textPrNormal}
													weight={700}
													size="small">
													{t('general.prCountryName')}
												</Body>
											</div>
										)}
									</div>
								</div>
							)}
							<>
								{prWarehouse ? (
									(preferJobWarehouse === 'PR' || preferJobWarehouse === 'both') && (
										<>
											<div className={mystyle.prwarehouse}>
												<div className={getClass(mystyle.alertWarhouse, renewstyle.alertWarhouse)}>
													<Alert variant="info">{t('availability.shftPrAlert')}</Alert>
												</div>
												<WarehousePRComponent
													cbPr1st={cbPr1st}
													cbPr2nd={cbPr2nd}
													cbPr3rd={cbPr3rd}
													flex4th={flex4th}
													onChangeShift={onChangeShift}
													isRenew={isRenew}
												/>
											</div>
										</>
									)
								) : (
									<>
										{(preferJobWarehouse === 'US' || preferJobWarehouse === 'both') && (
											<>
												<WarehouseUSComponent
													cbWd1st={cbWd1st}
													cbWd2nd={cbWd2nd}
													cbWd3rd={cbWd3rd}
													cbWe1st={cbWe1st}
													cbWe2nd={cbWe2nd}
													cbWe3rd={cbWe3rd}
													flex7th={flex7th}
													onChangeShift={onChangeShift}
													isRenew={isRenew}
												/>
											</>
										)}
									</>
								)}
							</>
						</>
					)}
				</div>
			</FormGroup>
		</div>
	);
};

const StoreTag = () => {
	const { t } = useTranslation();
	return (
		<>
			<div>
				<Body size="medium" UNSAFE_style={{ color: '#74767C' }}>
					{t('availability.shftBody')}
				</Body>
			</div>
			<div>
				<Tag color="blue" leading={<Store />} variant="secondary">
					{t('general.stores')}
				</Tag>
			</div>
		</>
	);
};

const WarehouseTag = () => {
	const { t } = useTranslation();
	return (
		<>
			<div>
				<Tag color="purple" leading={<Truck />} variant="secondary">
					{t('general.warehouses')}
				</Tag>
			</div>
		</>
	);
};

const ShiftDivider = () => {
	return (
		<div className={mystyle.divider}>
			<Divider />
		</div>
	);
};

const StoreComponent = (props) => {
	const { t } = useTranslation();
	const availabilityShftTiming = 'availability.shftTiming';
	return (
		<>
			<div className={!props.isRenew ? mystyle.alertShift : renewstyle.alertShift}>
				<Alert variant="info">{t('availability.shftAlert')}</Alert>
			</div>
			<div>
				<Checkbox
					label={t('availability.opening')}
					checked={props.cbOpening}
					onChange={() => props.onChangeShift('Opening')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '4:00am', end: '7:00am' })}
				</Body>
			</div>
			<div>
				<Checkbox
					label={t('availability.morning')}
					checked={props.cbMorning}
					onChange={() => props.onChangeShift('Morning')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '7:00am', end: '9:00am' })}
				</Body>
			</div>
			<div>
				<Checkbox
					label={t('availability.midShift')}
					checked={props.cbMidshift}
					onChange={() => props.onChangeShift('Midshift')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '8:00am', end: '11:00am' })}
				</Body>
			</div>
			<div>
				<Checkbox
					label={t('availability.closing')}
					checked={props.cbClosing}
					onChange={() => props.onChangeShift('Closing')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '1:00pm', end: '5:30pm' })}
				</Body>
			</div>
			<div>
				<Checkbox
					label={t('availability.overnight')}
					checked={props.cbOvernight}
					onChange={() => props.onChangeShift('Overnight')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '5:30pm', end: '11:00pm' })}
				</Body>
			</div>
		</>
	);
};

const WarehouseUSComponent = (props) => {
	const { t } = useTranslation();
	const availabilityShftTiming = 'availability.shftTiming';
	return (
		<>
			<div className={mystyle.weekdays}>
				<Heading as="h1" size="small" weight="700">
					{t('availability.weekday')}
				</Heading>
			</div>
			<div className={!props.isRenew ? mystyle.alertWarhouse : renewstyle.alertWarhouse}>
				<Alert variant="info">{t('availability.wdshift')}</Alert>
			</div>
			<div>
				<Checkbox
					label={t('availability.1stShft')}
					checked={props.cbWd1st}
					onChange={() => props.onChangeShift('Weekday1st')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '3:00am', end: '10:30am' })}
				</Body>
			</div>
			<div>
				<Checkbox
					label={t('availability.2ndShft')}
					checked={props.cbWd2nd}
					onChange={() => props.onChangeShift('Weekday2nd')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '11:00am', end: '5:30pm' })}
				</Body>
			</div>
			<div>
				<Checkbox
					label={t('availability.3rdShft')}
					checked={props.cbWd3rd}
					onChange={() => props.onChangeShift('Weekday3rd')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '6:00pm', end: '2:30am' })}
				</Body>
			</div>
			<div className={mystyle.weekdays}>
				<Heading as="h1" size="small" weight="700">
					{t('availability.weekend')}
				</Heading>
			</div>
			<div className={!props.isRenew ? mystyle.alertWarhouse : renewstyle.alertWarhouse}>
				<Alert variant="info">{t('availability.weshift')}</Alert>
			</div>
			<div>
				<Checkbox
					label={t('availability.4thShft')}
					checked={props.cbWe1st}
					onChange={() => props.onChangeShift('Weekend1st')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '3:00am', end: '10:30am' })}
				</Body>
			</div>
			<div>
				<Checkbox
					label={t('availability.5thShft')}
					checked={props.cbWe2nd}
					onChange={() => props.onChangeShift('Weekend2nd')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '11:00am', end: '5:30pm' })}
				</Body>
			</div>
			<div>
				<Checkbox
					label={t('availability.6thShft')}
					checked={props.cbWe3rd}
					onChange={() => props.onChangeShift('Weekend3rd')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '6:00pm', end: '2:30am' })}
				</Body>
			</div>
			<div className={mystyle.weekdays}>
				<Heading as="h1" size="small" weight="700">
					{t('availability.flex')}
				</Heading>
			</div>
			<div className={!props.isRenew ? mystyle.alertWarhouse : renewstyle.alertWarhouse}>
				<Alert variant="info">{t('availability.shiftsMayVary')}</Alert>
			</div>
			<div>
				<Checkbox
					label={t('availability.flexShft')}
					checked={props.flex7th}
					onChange={() => props.onChangeShift('flex7th')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t('availability.flexShiftDesc')}
				</Body>
			</div>
		</>
	);
};

const WarehousePRComponent = (props) => {
	const { t } = useTranslation();
	const availabilityShftTiming = 'availability.shftTiming';
	return (
		<>
			<div style={{ paddingTop: 12 }}>
				<Checkbox
					label={t('availability.1stShft')}
					checked={props.cbPr1st}
					onChange={() => props.onChangeShift('pr1st')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '3:00am', end: '10:00am' })}
				</Body>
			</div>
			<div style={{ paddingTop: 12 }}>
				<Checkbox
					label={t('availability.2ndShft')}
					checked={props.cbPr2nd}
					onChange={() => props.onChangeShift('pr2nd')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '10:30am', end: '6:00pm' })}
				</Body>
			</div>
			<div style={{ paddingTop: 12 }}>
				<Checkbox
					label={t('availability.3rdShft')}
					checked={props.cbPr3rd}
					onChange={() => props.onChangeShift('pr3rd')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t(availabilityShftTiming, { start: '6:30pm', end: '2:30am' })}
				</Body>
			</div>
			<div style={{ paddingTop: 12 }}>
				<Checkbox
					label={t('availability.flex')}
					checked={props.flex4th}
					onChange={() => props.onChangeShift('flex4th')}
				/>
				<Body as="p" size="small" UNSAFE_className={mystyle.morning}>
					{t('availability.flexShiftDesc')}
				</Body>
			</div>
		</>
	);
};

export default ShiftPreference;
