import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequestErrorOccured } from 'redux/slices/globalSlice';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';

const getMilitaryInfo = createAsyncThunk('military/getMilitaryInfo', async (request, { dispatch }) => {
	try {
		const baseUrl =
        environment.hcServiceBaseGetUrl +
        '/getMilitaryExp?applicantId=' +
        request.applicantId;
		const response = await axiosInstance.get(baseUrl);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});

const saveMilitaryExp = createAsyncThunk('military/saveMilitaryExp', async (request, { dispatch }) => {
	try {
		const baseUrl =
        environment.hcServiceBasePostUrl + '/saveMilitaryExp';
		const response = await axiosInstance.post(baseUrl, request);
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});

export { getMilitaryInfo,saveMilitaryExp};