import React, { useEffect, useState } from 'react';
import {ChipGroup, Chip, Modal, Body, TabNavigation, TabNavigationItem} from '@walmart-web/livingdesign-components';
import style from './Location.module.css';
import { Store, Truck } from '@livingdesign/icons';
import LocationSearchMap from './components/LocationSearchMap/LocationSearchMap';
import LocationFooter from './components/LocationFooter/LocationFooter';
import { useDispatch, useSelector } from 'react-redux';
import { subHeader } from '../../redux/slices/globalSlice';
import { useTranslation } from 'react-i18next';
import {useMediaQuery} from "react-responsive";
import LocationStoreList from "./components/LocationStoreList/LocationStoreList";
import {setOpenCoLocationDialog, updateCurrentTab,} from "../../redux/Location/slice";
import {updateCurrentSelectedJobs} from "../../redux/slices/jobSearchSlice";

const Location = () => {
  const isDesktop = useMediaQuery({ minWidth: 851 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const location = useSelector((state) => state.location);
  const jobSearch = useSelector((state) => state.jobSearch);
  const openCoLocationDialog = useSelector(state => state.location.openCoLocationDialog);
  const dispatch = useDispatch();
  const [filterMap, setfilterMap] = useState([true, false, false]);
  const [filteredStores, setFilteredStores] = useState([]);

  const { t } = useTranslation();

  const chipStyle = { background: '#2E2F32', color: '#FFFFFF' };

  useEffect(() => {
    dispatch(
      subHeader({
        title: 'Location Search',
        routeTo: 'login',
        display: 'true'
      })
    );
    dispatch(updateCurrentSelectedJobs(jobSearch.selectedJobs))
  }, [location, dispatch]);

  useEffect(()=>{
    let filteredList = [];
    const storeList = location.storesList;
    if(filterMap[1]){
      filteredList = storeList.filter(store => !store.sc);
    }
    else if(filterMap[2]){
      filteredList = storeList.filter(store => store.sc);
    }
    else{
      filteredList = storeList;
    }
    setFilteredStores(filteredList);

  },[filterMap,location.storesList])

  const handleFilter = (key) => {
    if (key === 1) {
      setfilterMap([true, false, false]);
    } else if (key === 2) {
      setfilterMap([false, true, false]);
    } else if (key === 3) {
      setfilterMap([false, false, true]);
    }
  };

  const switchTab = (selected) => {
		window.scroll(0, 0);
		dispatch(updateCurrentTab(selected));
	};
  
  return (
    <div className={style.Location}>
      <Modal isOpen={openCoLocationDialog}
             onClose={() => dispatch(setOpenCoLocationDialog(false))}
             size={isMobile? "small" : "medium"}
             title={t('location.notice')}>
               <Body as="div" size="medium">{t('location.co-located')}</Body>
      </Modal>
      <div className={style.Location__ChipGroup}>
        <ChipGroup UNSAFE_className={style.Location__Chips}>
          <Chip
            selected={filterMap[0]}
            onClick={() => handleFilter(1)}
            UNSAFE_style={filterMap[0] ? chipStyle : {}}
          >
            {t('general.all')}
          </Chip>
          <Chip
            leading={<Store size="small" />}
            selected={filterMap[1]}
            onClick={() => handleFilter(2)}
            UNSAFE_style={filterMap[1] ? chipStyle : {}}
          >
            {t('general.stores')}
          </Chip>
          <Chip
            leading={<Truck size="small" />}
            selected={filterMap[2]}
            onClick={() => handleFilter(3)}
            UNSAFE_style={filterMap[2] ? chipStyle : {}}
          >
            {t('general.warehouses')}
          </Chip>
        </ChipGroup>
      { isDesktop && <LocationStoreList filteredStores={filteredStores} showStoreList={true}/>}
      </div>
      {!isDesktop && 
          <div className={style.tabsContainer}>
						<div className={style.desktopTabsContainer}>
							<TabNavigation
								UNSAFE_className={style.tabContainer}>
								<div className={style.tabPositions}>
									<div className={style.tabLayout}>
										<TabNavigationItem
											UNSAFE_style={{ width: '100%' }}
											onClick={() => switchTab(true)}
											isCurrent={location.currentTab}
                      >
											<div style={{ width: '100%' }}>
												{t('general.list')} 
											</div>
										</TabNavigationItem>
										<TabNavigationItem
											UNSAFE_style={{ width: '100%' }}
											onClick={() => switchTab(false)}
											isCurrent={!location.currentTab}
                      >
											{t('general.map')} 
										</TabNavigationItem>
									</div>
								</div>
							</TabNavigation>
						</div>
              {location.currentTab ? (
                <LocationStoreList 
                  filteredStores={filteredStores} 
                  showStoreList={true}/>
							) :  
              <LocationSearchMap
              storeList={location.storesList}
              filterMap={filterMap}/>}
					</div>  
        }
      {isDesktop && 
        <div className={style.Location__Map}>
        <LocationSearchMap
          storeList={location.storesList}
          filterMap={filterMap}
        />
      </div>}
      <LocationFooter filteredStores={filteredStores} />
    </div>
  );
};

export default Location;
