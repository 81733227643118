import React from 'react';
import ApplicationSubmitted from './ApplicationSubmitted';

function ApplicationSubmitModal() {
	return (
		<div>
			<div className="Overlay-module_container__Nt8zd" style={{ width: '800px' }}>
				<div
					data-focus-guard="true"
					tabIndex="0"
					style={{
						width: '1px',
						height: '0px',
						padding: '0px',
						overflow: 'hidden',
						position: 'fixed',
						top: '1px',
						left: '1px'
					}}></div>
				<div
					data-focus-lock-disabled="false"
					className="Overlay-module_trap__8--S5"
					style={{ overflow: 'hidden' }}>
					<div className="ModalPortal-module_container__a5kaH">
						<ApplicationSubmitted />
					</div>
					<div className="OverlayScrim-module_scrim__NVCgq ModalPortal-module_scrim__jP8Yi"></div>
				</div>
			</div>
		</div>
	);
}

export default ApplicationSubmitModal;
