import React from 'react';
import { Body, BottomSheet, Button, Divider, Modal } from '@walmart-web/livingdesign-components';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import style from './AboutYouPersonalInfo.module.css';

const ExitModal = (props) => {
	const { t } = useTranslation();
	const isDesktop = useMediaQuery({ minWidth: 851 });
	const isMobile = useMediaQuery({ maxWidth: 767 });
	return (
		<div>
			{isMobile && (
				<BottomSheet
					isOpen={props.exit}
					onClose={() => props.handleExit(false)}
					title={t('aboutYouPersonalInfo.sorry')}>
					<div className={style.preEmployment}>
						<Body as="p" size="small" weight={400}>
							{props.exitText}
						</Body>
					</div>
					<div style={{  paddingTop: '16px'  ,textAlign:"center"}}>
						<Button variant="primary" onClick={() => props.handleExit(false)}>
							{t('aboutYouPersonalInfo.exit')}
						</Button>
					</div>
				</BottomSheet>
			)}
			{isDesktop && (
				<Modal
                    size="medium"
					isOpen={props.exit}
					onClose={() => props.handleExit(false)}
					title={t('aboutYouPersonalInfo.sorry')}>
					<div className={style.preEmployment}>
						<Body as="p" size="small" weight={400}>
							{props.exitText}
						</Body>
					</div>
					<Divider />
					<div style={{ paddingTop: '16px' ,textAlign:"center"}}>
						<Button variant="primary" onClick={() => props.handleExit(false)}>
							{t('aboutYouPersonalInfo.exit')}
						</Button>
					</div>
				</Modal>
			)}
		</div>
	);
};

export default ExitModal;
