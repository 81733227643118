import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    jobDetails: '',
    selectedJobs: [],
    currentSelectedJobs: [],
    jobSearchResponse: null,
    currentTab: true,
    sort: 0,
    is18 : false,
    filters: {
        facility: 0,
        shift: [],
        empType:[],
        workgroup: [],
        skill: [],
        interaction: [],
        workingConditions: [],
    },
    shiftsAutoFilled : false
}

export const jobSearchSlice = createSlice({
    name: 'jobSearch',
    initialState,
    reducers: {
        updateSelectedJobs: (state, action) => {
            state.selectedJobs = action.payload;
        },
        updateCurrentSelectedJobs: (state, action) => {
            state.currentSelectedJobs = action.payload;
        },
        updateJobDetails: (state, action) => {
            state.jobDetails = action.payload;
        },
        updateJobSearchResponse: (state, action) => {
            state.jobSearchResponse = action.payload;
        },
        updateFilters: (state, action) => {
            state.filters = action.payload;
        },
        updateSort: (state, action) => {
            state.sort = action.payload;
        },
        updateCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
        updateIs18: (state, action) => {
            state.is18 = action.payload;
        },
        updateJobPreferred: (state, action) => {
            state.currentSelectedJobs = state.currentSelectedJobs.map(job => { return {...job, jobPreferred: action.payload} }
            );
            state.selectedJobs = state.selectedJobs.map(job => { return {...job, jobPreferred: action.payload} }
                );
        },
        updateShiftsAutoFilled : (state, action) => {
            state.shiftsAutoFilled = action.payload;
        }
        }
})

// Action creators are generated for each case reducer function
export const { updateSelectedJobs, updateCurrentSelectedJobs, updateJobDetails, updateJobSearchResponse, updateFilters, updateSort, updateCurrentTab, updateIs18, updateJobPreferred, updateShiftsAutoFilled } = jobSearchSlice.actions

export default jobSearchSlice.reducer