import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    assessmentList: [],
    raaAsmtSkip: false,
    assessmentStatus: [],
    posList: [],
    applicationSubmitted: false,
    showModal: false
}

const AssessmentSlice = createSlice({
    name: "assessment",
    initialState,
    reducers: {
        updateAssessmentList: (state, action) => {
            state.assessmentList = action.payload;
        },
        updateRaaAsmtSkip: (state, action) => {
            state.raaAsmtSkip = action.payload;
        },
        updatePosList: (state, action) => {
            state.posList = action.payload;
        },
        updateAssessmentStatus: (state, action) => {
            state.assessmentStatus = action.payload;
        },
        updateAppplicationSubmitted: (state, action) => {
            state.applicationSubmitted = action.payload;
        },
        updateShowModal: (state, action) => {
            state.showModal = action.payload;
        }
    }
})

export const {
    updateAssessmentList,
    updateRaaAsmtSkip,
    updatePosList,
    updateAssessmentStatus,
    updateAppplicationSubmitted,
    updateShowModal
} = AssessmentSlice.actions;

export default AssessmentSlice.reducer;