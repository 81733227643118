import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequestErrorOccured, updateLoading } from "../../redux/slices/globalSlice";
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';

export const callHiringHelper = createAsyncThunk('hiringHelper/callHiringHelper', async (applId, { dispatch }) => {
	try {
		const baseURL = environment.hcServiceBasePostUrl + '/getHiringHelperScore';
		const response = await axiosInstance.post(baseURL, { applicantId: applId });
		return response.data;
	} catch (error) {
		dispatch(apiRequestErrorOccured(error));
	}
});
