import React, { useEffect } from 'react';
import style from './Header.module.css';
import { Globe, User } from '@livingdesign/icons';
import { Body, Button } from '@walmart-web/livingdesign-components';
import { useDispatch, useSelector } from 'react-redux';
import {
	onLogOut,
	path,
	showSpark,
	subHeader,
	updatePreferredLangOpen
} from '../../redux/slices/globalSlice';
import { useMediaQuery } from 'react-responsive';
import SubHeader from './SubHeader/SubHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderLogoSection from './HeaderLogoSection/HeaderLogoSection';
import LanguageSection from './PreferredLanguage/LanguageSection';
import { setClickLocText } from 'redux/Location/slice';

export default function Header() {
	const isDesktop = useMediaQuery({ minWidth: 851 });
	const iPadLandscape = useMediaQuery({
		minWidth: 426,
		maxWidth: 850,
		orientation: 'landscape'
	});

	const locationHook = useLocation();
	const global = useSelector((state) => state.global);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useSelector((state) => state.location);

	const signOut = () => {
		dispatch(onLogOut());
	};
	useEffect(() => {
		const pagesToHideSpark = [
			'disclaimer',
			'homeScreen',
			'recoverPassword',
			'sendVerifyEmail',
			'verifyEmailLink',
			'passwordSuccess',
			'error',
			'maintenance'
		];
		const pathname = window.location.pathname.split('/')[2];
		if (!['availability', 'assessments'].includes(pathname)) {
			dispatch(subHeader({ title: '', routeTo: '', display: false }));
		}
		dispatch(path(pathname));
		dispatch(showSpark(!pagesToHideSpark.includes(pathname)));
		if (pathname !== 'location-search') {
			dispatch(setClickLocText(false));
		}
		// eslint-disable-next-line
	}, [global.applicantId, dispatch, navigate]);

	return (
		<div className={style.fixedHeader}>
			<div className={style.header}>
				<HeaderLogoSection />
				<div className={style.rightContainer}>
					{global.path === 'post-apply-dashboard' && (
						<div className={style.profile} onClick={() => navigate('../edit-profile')}>
							<User />
							<Body size="medium" weight={700} UNSAFE_style={{ color: '#FFFFFF' }}>
								{t('general.profile')}
							</Body>
						</div>
					)}
					{global.applicantId !== 0 &&
						!global.subHeader.display &&
						global.path !== 'post-apply-dashboard' &&
						global.path !== 'error' &&
						!global.dialog.open && (
							<Body
								as="p"
								size="medium"
								UNSAFE_className={style.signOut}
								UNSAFE_style={{ color: '#FFFFFF' }}
								onClick={signOut}>
								{t('general.signOut')}
							</Body>
						)}
					{global.languageToggle.display && !(location.clickLocText && !(isDesktop || iPadLandscape)) && (
						<Button
							leading={<Globe size="medium" />}
							variant="tertiary"
							size="medium"
							aria-label={t('ariaLabelTexts.changeLanguage')}
							UNSAFE_style={{ color: '#FFFFFF', padding: 0 }}
							onClick={() => dispatch(updatePreferredLangOpen(true))}>
							{global.language}
						</Button>
					)}
				</div>
			</div>
			<div>{(isDesktop || iPadLandscape) && global.subHeader.display && locationHook.pathname!=='/error' && <SubHeader />}</div>
			<LanguageSection /> {/* Bottom Sheet / Modal*/}
		</div>
	);
}
