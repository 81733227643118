import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';
import { errorOccured, updateLoading } from 'redux/slices/globalSlice';
import { t } from 'i18next';
import {
    setResponseData,
    updateConfirmedSlot,
    updateDataLoaded,
    updateDateTimeSlots,
    updateInterviewConfirmed,
    updateDateTimeSlotsOrientation,
    updateDateTimeSlotsRjpt,
    updateDuration,
    updateIsFilled,
    updateEncryptedLink,
    updateIsSelfScheduleSC,
    updateIsDirectlyThankYouScreen
} from './slice';
import moment from "moment";
import { schedulableActionTypeCodes, selfScheduleEventCodeMap } from 'config/const';
import { getGeneralizedErrorMsg } from 'utils/errorDecorator';

const errorTxt = 'general.error';

const getHRAvailability = async (data, { dispatch }) => {
    try {
        let requestBody = {
            "applicantId": data.response.applicantId,
            "requisitionId": data.response.requisitionId,
            "transactionId": JSON.stringify(data.response.transactionId),
            "eventCode": data.response.eventCode,
            "actionTypeCode": data.response.actionTypeCode,
            "storeNbr": [data.response.storeNbr],
            "zoneId": Intl.DateTimeFormat().resolvedOptions().timeZone.toString()
        }
        const url = environment.selfScheduleServiceBasePostUrl + '/getHrAvailability';
        
        const response = await axiosInstance.post(url, requestBody);
        return response.data;
    } catch (error) {
        const errorObj = {
            title : t(errorTxt),
            message : getGeneralizedErrorMsg(error),
            canBeTranslated : false
        }
        dispatch(errorOccured(errorObj));
    }
}

const getRjptEvents = async (data, { dispatch }) => {
    try {
        const res = await axiosInstance.post(environment.selfScheduleServiceBasePostUrl + "/getAllTourEventsFromRjpt", data);
        return res.data;
    } catch (error) {
        const errorObj = {
            title : t(errorTxt),
            message : getGeneralizedErrorMsg(error),
            canBeTranslated : false
        }
        dispatch(errorOccured(errorObj));
    }
};

const getOrientationEvents =  async (data, {dispatch}) => {

    try {
        const request = await axiosInstance.post(environment.selfScheduleServiceBasePostUrl + "/getAllOrientationEvents", data);
        return request.data;
    } catch(error) {
        const errorObj = {
            title : t(errorTxt),
            message : getGeneralizedErrorMsg(error),
            canBeTranslated : false
        }
        dispatch(errorOccured(errorObj));
    }
};

const loadData = createAsyncThunk('selfSchedule/getSelfSchedule', async (encId, { dispatch }) => {
    dispatch(updateLoading(true));
    dispatch(updateEncryptedLink(encId));
    const url = environment.selfScheduleServiceBasePostUrl + "/getSSDecryptedTxnIdDetails";
    
    const requestBody = { "uniqueApplicationId": "walMarT-Store-HirinG", "encryptedId": encId }
    try {
        const response = await axiosInstance.post(url, requestBody);
        if(response.data.responseCode === 300) {
            dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.rehireNotEligible" , canBeTranslated : true}))
        }
        else if (response.data.responseCode === 200) {
            
            let resp = response.data.response
            let cutOffDate = new Date(resp.createdTs);
            let expired = false;
            let today = new Date();
            let scTs = moment(resp.scheduleTs)
            let currentTs = moment();

            if (
                resp.eventCode === selfScheduleEventCodeMap.SC_RJPT_EVENT_CODE ||
                resp.eventCode === selfScheduleEventCodeMap.SC_FT_EVENT_CODE ||
                resp.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_1 ||
                resp.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_2
            ) {
                cutOffDate.setDate(cutOffDate.getDate() + 7);
                cutOffDate.setHours(0, 0, 0);
            } else {
                cutOffDate.setDate(cutOffDate.getDate() + 2);
                cutOffDate.setHours(0, 0, 0);
            }

            if (resp && ((resp.completedTs == null && today > cutOffDate) || resp.dispositioned)) {
                expired = true;
            }

            if (
                !(
                    resp.completedTs === null &&
                    schedulableActionTypeCodes.includes(resp.actionTypeCode)
                )
            ) {
                // Already Scheduled
                if (scTs !== null && scTs > currentTs && resp.scheduled === true) {
                    // Update confirmed slot too.
                    let scheduleTs = resp.scheduleTs;
                    dispatch(updateIsDirectlyThankYouScreen(true));
                    const interviewSlot = {
                        date: moment(scheduleTs).format('dddd, MM-DD-YYYY'),
                        startTime: scheduleTs
                    };
                    if (
                        resp.eventCode === selfScheduleEventCodeMap.SC_INTERVIEW_EVENT_CODE ||
                        resp.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_2 ||
                        resp.eventCode === selfScheduleEventCodeMap.SC_RJPT_EVENT_CODE ||
                        resp.eventCode === selfScheduleEventCodeMap.SC_FT_EVENT_CODE ||
                        resp.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_1
                    ) {
                        dispatch(updateIsSelfScheduleSC(true));
                    }
                    dispatch(updateConfirmedSlot(interviewSlot));
                    dispatch(updateInterviewConfirmed());
                }
            }

            if (expired || (resp.scheduled === true && (scTs !== null && scTs < currentTs))) {
                dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.expiredLink", canBeTranslated : true}))
            } else {
                
                dispatch(updateDuration(response.data.response.eventCode));
                dispatch(setResponseData(response.data.response));
                var orData = {
                    "applicantId" : resp.applicantId,
                    "requisitionId" : resp.requisitionId,
                    "eventId" : resp.eventCode,
                    "storeNbr" : [resp.storeNbr]
                }

                if(response.data.response.eventCode === selfScheduleEventCodeMap.SC_RJPT_EVENT_CODE || response.data.response.eventCode === selfScheduleEventCodeMap.SC_FT_EVENT_CODE) {
                    const rjptResponse = await getRjptEvents(orData, { dispatch });
                    if (rjptResponse != null && rjptResponse.response != null) {
                        if(rjptResponse.response.length === 0) {
                            dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.noEvents selfSchedule.noEventCheck", canBeTranslated : true}))
                        }
                        else {
                            let transactionEventCode = response.data.response.eventCode;
                            let tourResponse=rjptResponse.response.filter((obj)=>obj.hrEventId!=resp.oldHrEventId)
                            const actionObject = {
                                tourResponse: tourResponse,
                                transactionEventCode: transactionEventCode
                            };
                            dispatch(updateDateTimeSlotsRjpt(actionObject));
                        }
                    }
                    else {
                        dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.somethingWentWrong", canBeTranslated : true }))
                    }
                }
                else if((response.data && response.data.response.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_1)|| (response.data && response.data.response.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_2)) {
                    
                    var orData = {
                      "requestInput": {
                        "storeNbr": JSON.stringify(response.data.response.storeNbr),
                        "candidateCount":1,
                        "earliestHireDt":response.data.response.earliestHireDt
                      },
                      "applicantId" : resp.applicantId,
                      "requisitionId" : resp.requisitionId,
                      "eventId" : resp.eventCode
                    }
                    const orientationResponse = await getOrientationEvents(orData, { dispatch })
                    if(orientationResponse != null && orientationResponse.response != null) {
                        const eventMapLength = orientationResponse.response?.eventMap !==null ? Object.keys(orientationResponse.response.eventMap).length : 0;
                        if(eventMapLength == 0) {
                            dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.noEvents selfSchedule.noEventCheck", canBeTranslated : true}))
                        }
                        else {
                            dispatch(updateDateTimeSlotsOrientation(orientationResponse)); 
                        }
                    }
                    else {
                        dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.somethingWentWrong", canBeTranslated : true }))
                    }
                }
                else{
          
                    const hrAvailabilityResponse = await getHRAvailability(response.data, { dispatch });
                    if (hrAvailabilityResponse.response != null) {
                        if(hrAvailabilityResponse.response.length === 0) {
                            dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.noEvents selfSchedule.noEventCheck", canBeTranslated : true}))
                        } else {
                            dispatch(updateDateTimeSlots(hrAvailabilityResponse));
                        }
                    }
                    else {
                        dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.somethingWentWrong", canBeTranslated : true }))
                    }
                }
            }
        }
        else {
            //Invalid Link
            dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.accessError", canBeTranslated : true }))
        }
    } catch (err) {
        if(err.response.request.status != 200) {
            dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.errorFetchingDecryptedDetails", canBeTranslated : true }))
        }
        else {
            dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.somethingWentWrong", canBeTranslated : true }))
        }
    } finally {
        dispatch(updateLoading(false));
        dispatch(updateDataLoaded(true));
    }
});

const confirmScheduleSlot = createAsyncThunk('selfSchedule/scheduleCandidate', async (args, { dispatch }) => {
    dispatch(updateLoading(true));
    const {
        reqPayload,
        interviewSlot
    } = args;
    dispatch(updateConfirmedSlot(interviewSlot));
    
    const url = environment.selfScheduleServiceBasePostUrl + '/scheduleCandidate';
    axiosInstance.post(url, reqPayload).then((res) => {

        if( (res.data.responseMessage).includes("EventAlreadyExistsException") || res.data.responseCode === 400 || res.data.responseMessage === 'HRM Availability is no longer available.') {
            dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.timeSlotNA selfSchedule.pleaseSelectAnotherOption", canBeTranslated:true}))
            dispatch(updateIsFilled(true));
        }
        else if (res.data.responseCode !== 200) {
            dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.somethingWentWrong", canBeTranslated : true}))
        } else {
            dispatch(updateInterviewConfirmed());
        }
    }
    ).catch((e) =>
        dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.somethingWentWrong", canBeTranslated : true }))
    ).finally(() =>
        dispatch(updateLoading(false))
    )
})

const confirmScheduleSlotForTour = createAsyncThunk('selfSchedule/scheduleCandidateForTour', async (args, { dispatch }) => {
    dispatch(updateLoading(true));
    const {
        reqPayload, 
        interviewSlot
    } = args;
    dispatch(updateConfirmedSlot(interviewSlot));
    
    const url = environment.selfScheduleServiceBasePostUrl + '/scheduleCandidateToTour';
    axiosInstance.post(url, reqPayload).then((res) => {

        // 403 from BE in this case means : HR Availability is no longer available.
        if(res.data.responseCode === 403|| res.data.responseCode === 201){
            dispatch(updateIsFilled(true));
            dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.timeSlotNA selfSchedule.pleaseSelectAnotherOption", canBeTranslated : true}))
        }
        else if (res.data.responseCode !== 200) {
            dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.somethingWentWrong", canBeTranslated : true }))
        } else {
            dispatch(updateInterviewConfirmed());
        }
    }
    ).catch((e) =>
        dispatch(errorOccured({ title: "selfSchedule.sorry", message: "selfSchedule.somethingWentWrong", canBeTranslated : true }))
    ).finally(() =>
        dispatch(updateLoading(false))
    )
})

export { getHRAvailability, loadData, confirmScheduleSlot, confirmScheduleSlotForTour };