import React, { useEffect, useState } from 'react';
import styles from './JobSelectionFooter.module.css';
import {
	Alert,
	Button,
	BottomSheet,
	Checkbox,
	Divider,
	Panel,
	ButtonGroup
} from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import GenericJobCard from '../../../components/GenericJobCard/GenericJobCard';
import { useDispatch, useSelector } from 'react-redux';
import { updateIs18 } from '../../../redux/JobSearch/slice';
import { useDisplay } from 'utils/useDisplay';

const JobSelectionFooter = (props) => {
	const global = useSelector((state) => state.global);
	const dispatch = useDispatch();
	const { isPortrait } = useDisplay();
	const { t } = useTranslation();
	const [drawerOpen, setDrawerOpen] = React.useState(false);
	const selectedJobsStore = useSelector((state) => state.jobSearch);
	const [is18, setIs18] = useState(selectedJobsStore.is18);

	const selectedAccount = useSelector((state) => state.account);
	const [isAgeAlreadyUpdated, setIsAgeAlreadyUpdated] = useState(selectedAccount.data.auth.age18);

	const shouldBe18 = () => {
		return props.selected.some((i) => {
			if ((getFacilityType(i) === 'warehouse' || i.countryCode === 'PR') && !isAgeAlreadyUpdated) {
				props.setIsAgeUpdateRequired(true);
				return true;
			}
		});
	};
	const selectedPR = () => {
		return props.selected.some((i) => {
			if (i.countryCode === 'PR') {
				return true;
			}
		});
	};
	const getFacilityType = (i) => {
		return i.workgroupId < 5000 ? 'store' : 'warehouse';
	};

	const detailsMenu = () => {
		return (
			<React.Fragment>
				{props.selected.length > 0 ? (
					props.selected.map((item, i) => {
						return (
							<div key={i}>
								<GenericJobCard
									showCountryCode={props.showCountryCode}
									item={item}
									remove={item.jobPreferred === 'Y' ? null : props.remove}
								/>{' '}
								{i === props.selected?.length - 1 ? null : <Divider />}
							</div>
						);
					})
				) : (
					<Alert variant="info">{t('jobDetails.noJobsSelected')}</Alert>
				)}
			</React.Fragment>
		);
	};

	const handleCheckBox = () => {
		setIs18(!is18);
		dispatch(updateIs18(!is18));
	};

	const alertMessage = () => {
		return (
			<>{selectedPR() ? t('jobSelection.mustBe18AlertPR') : t('jobSelection.mustBe18AlertSC')}</>
		);
	};

	const getShouldBe18Warning = () => {
		return (
			shouldBe18() && (
				<div
					className={
						isPortrait ? styles.footerWarningContainer : styles.footerWarningContainerDesktop
					}>
					<Alert variant="warning" style={{ marginBottom: '10px' }}>
						{alertMessage()}
					</Alert>
					<div>
						<Checkbox
							label={t('jobSelection.iAm18Acknowlege')}
							onChange={handleCheckBox}
							checked={is18}
						/>
					</div>
				</div>
			)
		);
	};

	const getFooterActionButtons = () => {
		return (
			<div className={styles.actionButtonsContainer}>
				{getShouldBe18Warning()}
				<ButtonGroup UNSAFE_className={styles.actionButtons}>
					<Button
						variant="tertiary"
						onClick={() => setDrawerOpen(false)}
						UNSAFE_style={{ padding: 0 }}>
						{t('jobSelection.addJobSelections')}
					</Button>
					<Button
						variant="primary"
						onClick={props.continue}
						disabled={props.selected.length <= 0 || (shouldBe18() && !is18)}>
						{t('general.continue')}
					</Button>
				</ButtonGroup>
			</div>
		);
	};

	useEffect(() => {
		if (global.isCpRedirect && global.preferedJobsFromRedirect?.length) {
			setDrawerOpen(true);
		} else if (!props.selected?.length) {
			setDrawerOpen(false);
		}
	}, [global.isCpRedirect, global.preferedJobsFromRedirect?.length]);

	return (
		<div className={styles.footerContainer}>
			{isPortrait && getShouldBe18Warning()}
			<div className={styles.footerButtonContainer}>
				<Button
					variant={'tertiary'}
					size={isPortrait ? 'medium' : 'large'}
					UNSAFE_style={{ padding: '0px' }}
					onClick={() => setDrawerOpen(true)}
					disabled={props.selected.length === 0}>
					{t('jobSelection.selectedjobs')} ({props.selected.length})
				</Button>

				{!isPortrait && getShouldBe18Warning()}
				<Button
					onClick={props.continue}
					size={isPortrait ? 'medium' : 'large'}
					variant="primary"
					disabled={props.selected.length <= 0 || (shouldBe18() && !is18)}>
					{t('general.continue')}
				</Button>
				{isPortrait ? (
					<BottomSheet
						isOpen={drawerOpen}
						onClose={() => setDrawerOpen(false)}
						title={t('jobSelection.selectedJobs')}
						actions={getFooterActionButtons()}>
						{detailsMenu()}
					</BottomSheet>
				) : (
					<Panel
						isOpen={drawerOpen}
						onClose={() => setDrawerOpen(false)}
						title={t('jobSelection.selectedJobs')}
						position="right"
						size="medium"
						actions={getFooterActionButtons()}>
						{detailsMenu()}
					</Panel>
				)}
			</div>
		</div>
	);
};

export default JobSelectionFooter;
