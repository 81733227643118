import React, { useState, useRef, useEffect } from 'react';
import { Card, Body } from '@walmart-web/livingdesign-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import JobRow from './components/JobRow';
import WithDrawConfirmation from './components/WithDrawConfirmation';
import { postWithDraw } from '../../../../redux/AppliedJobTracker/request';
import { updateLoading, errorOccured, executionErrorOccured } from '../../../../redux/slices/globalSlice';
import { updateJobTrackerData } from '../../../../redux/PostApplyDashboard/slice';
import { unwrapResult } from '@reduxjs/toolkit';
import './style.scss';
import { sendGtmEvent } from 'utils/GoogleTagManagerUtils';
import { GTM } from 'config/const';
import { t } from 'i18next';

const AppliedJobsList = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [messageType, setMessageType] = useState(1);

	const dispatch = useDispatch();
	const selectedJobInfo = useRef({});
	const navigate = useNavigate();

	const { storeNbr } = props;
	const languageCode = useSelector((state) => state.global.language) !== 'ES' ? 101 : 102;
	const locationDetails = useSelector((state) => state.postApplyDashboard.data.locationDetails);
	const applicantId = useSelector((state) => state.global.applicantId);
	const {
		positionApplied,
		jobCodeMap = {},
		withdrawMap = {},
		showWorkGroup
	} = (locationDetails && locationDetails[storeNbr]) || {
		positionApplied: {}
	};

	useEffect(() => {
		if (!showWorkGroup) {
			navigate('/post-apply-dashboard');
		}
	}, [showWorkGroup]);

	const showWithDrawPopup = (jobClassId, storeNbr, workGroupId) => {
		selectedJobInfo.current = { jobClassId, storeNbr, workGroupId };
		setMessageType(1);
		setIsOpen(true);
	};

	const handleError = (responseMessage, result = {}) => {
		setIsOpen(false);
		dispatch(
			errorOccured({
				open: true,
				title: t('general.error'),
				message:
					responseMessage ||
					result?.response?.data?.responseMessage ||
					t('general.somethingWentWrong'),
				canBeTranslated : false
			})
		);
	};

	const handleWithDraw = (type) => {
		sendGtmEvent(GTM.EVENTS.WITHDRAW);
		if (type === '2' || type === '-1') {
			setMessageType(1);
			return;
		}
		const { jobClassId, storeNbr } = selectedJobInfo.current;
		const payload = {
			applicantId: applicantId,
			applSeqNbr: 0,
			jobClassId: jobClassId,
			storeNbr: storeNbr
		};

		setMessageType(1);
		dispatch(updateLoading(true));
		dispatch(postWithDraw(payload))
			.then(unwrapResult)
			.then((result) => {
				const { responseCode, responseMessage } = result;
				if (responseCode === '200') {
					setMessageType(2);
					dispatch(updateJobTrackerData(selectedJobInfo));
				} else {
					handleError(responseMessage, result);
				}
			})
			.catch((error) => {
				dispatch(executionErrorOccured());
			});
	};

	return showWorkGroup ? (
		<>
			{Object.keys(positionApplied).map((workGroupId, index) => {
				const workGroupObj = positionApplied[workGroupId];
				const { name = {}, positions = [], showJobCard } = workGroupObj;
				return (
					showJobCard && (
						<Card key={index} size="small" UNSAFE_className="applied_jobs_list_container">
							<Body as="div" size="large" weight={700}>
								{name[languageCode]}
							</Body>
							{positions.map((position, index) => {
								const { jobClassId } = position;

								const showJobRow = jobCodeMap[jobClassId] || false;
								const isWithDrawEnabled = withdrawMap[jobClassId] || false;
								return (
									showJobRow && (
										<JobRow
											key={index}
											position={position}
											data={{ storeNbr, workGroupId }}
											disabled={isWithDrawEnabled}
											onClick={showWithDrawPopup}
										/>
									)
								);
							})}
						</Card>
					)
				);
			})}
			<WithDrawConfirmation
				messageType={messageType}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				onClick={handleWithDraw}
			/>
		</>
	) : (
		<></>
	);
};

export default AppliedJobsList;
