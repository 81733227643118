import React from 'react';
import style from './Error.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Body, Button, ErrorMessage, Heading } from '@walmart-web/livingdesign-components';
import { useNavigate } from 'react-router';
import { errorOccured, onLogOut } from 'redux/slices/globalSlice';
import { updateDataLoaded, updateIsFilled } from 'redux/SelfSchedule/slice';

const ErrorScreen = () => {
	const global = useSelector((state) => state.global);
	const dispatch = useDispatch();
	const selfSchedule = useSelector((state) => state.selfSchedule);
	const Icon = require('@livingdesign/icons');
	const { t } = useTranslation();
	const navigate = useNavigate();
	const canBeTranslated = global.error.canBeTranslated;
	let globalMsg =
		global.error.message?.toLowerCase()?.includes('sql') ||
		global.error.message?.toLowerCase()?.includes('db')
			? 'Issue occurred while performing an operation'
			: global.error.message?.toLowerCase()?.includes('token') ||
			  global.error.message?.includes('UNAUTHORIZED')
			? t('general.systemErrorText')
			: global.error.message;
	let message = globalMsg;
	let multipleTranslations = false;
	if (canBeTranslated && globalMsg.includes(' ')) {
		message = globalMsg.split(' ');
		multipleTranslations = true;
	}
	const isWotcError = global.error.isWotcError;
	const errorBtnText = selfSchedule.isFilled
		? t('selfSchedule.backToCalendar')
		: isWotcError
		? t('wotc.continueApplication')
		: t('general.exitErrorScreenBtn');

	const handleNavigation = () => {
		if (selfSchedule.isFilled) {
			dispatch(updateIsFilled(false));
			dispatch(errorOccured({ open: false, title: '', message: '', canBeTranslated: true }));
			dispatch(updateDataLoaded(false));
			navigate(`../SS/${selfSchedule.encryptedLink}`);
		} else if (isWotcError) {
			dispatch(errorOccured({ open: false, title: '', message: '', canBeTranslated: true }));
			navigate('../minimum-qualifications');
		} else {
			dispatch(onLogOut());
		}
	};

	return (
		<div className={style.ErrorScreen}>
			<ErrorMessage
				actions={
					<Button
						size="medium"
						variant="secondary"
						leading={selfSchedule.isFilled && <Icon.ArrowLeft />}
						onClick={handleNavigation}>
						{errorBtnText}
					</Button>
				}
				media={
					<img
						alt="Error"
						className={style.error_image}
						src={require('../../../src/assets/images/Error_Message_Media.png')}
					/>
				}
				title={
					<Heading size="large" UNSAFE_style={{ color: '#FFFFFF' }}>
						{global.error.title === ''
							? 'Error Occured'
							: canBeTranslated
							? t(global.error.title)
							: global.error.title}
					</Heading>
				}>
				<Body size="medium" UNSAFE_style={{ color: '#FFFFFF' }}>
					{canBeTranslated
						? multipleTranslations
							? message.map((msg) => <div>{t(msg)}</div>)
							: t(message)
						: message}
				</Body>
			</ErrorMessage>

			<div className="row">
				<img
					className={style.walmart_title}
					src={require('../../../src/assets/images/walmart-title.png')}
					alt="walmart-logo"
				/>
			</div>
		</div>
	);
};

export default ErrorScreen;
