import { Body } from '@walmart-web/livingdesign-components';
import { useVirtualJobOffer } from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import celebration_rectangle_blue from '../../../../../assets/images/celebration_rectangle_blue.svg';
import './styles.scss';

/**
 * VJO Banner view. Used in the VJO landing page
 * @returns - Memoized banner view
 */
const Banner = () => {
	const { t } = useTranslation();
	const { jobOffer } = useVirtualJobOffer();

	return (
		<React.Fragment>
			{jobOffer && (
				<>
					<img
						alt="celebration hand"
						src={celebration_rectangle_blue}
						className="celebration_img_vjo"
					/>
					<Body size="medium" UNSAFE_className={'description'}>
						<Trans>
							{t('virtualJobOffer.description', {
								jobName: jobOffer?.jobName,
								rateOfPay: jobOffer?.rateOfPay,
								storeNbr: jobOffer?.storeNbr,
								streetAddr: jobOffer?.streetAddr.replace(
									/(^\w|\s\w)(\S*)/g,
									(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
								),
								cityAddr: jobOffer?.cityAddr.replace(
									/(^\w|\s\w)(\S*)/g,
									(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
								),
								stateAddr: jobOffer?.stateAddr,
								zipCode: jobOffer?.zipCode
							})}
						</Trans>
					</Body>
				</>
			)}
		</React.Fragment>
	);
};

export default memo(Banner);
