import { Card, Heading, Body, Button, Divider, Alert } from '@walmart-web/livingdesign-components';
import React, { useEffect, useMemo, useState } from 'react';
import './styles.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useVirtualJobOffer } from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import { useDrugScreenInfo } from 'pages/VirtualJobOffer/hooks/useDrugScreenInfo';
import AlertDialog from 'components/AlertDialog';
import { DRUG_SCREEN_STATUS } from './util';
import moment from 'moment-timezone';
import { STEPS } from 'pages/VirtualJobOffer/util';
import { useDispatch } from 'react-redux';
import { isDrugScreenRequired } from 'redux/VJODrugScreen/request';
import { refreshDrugScreenCheck } from 'redux/VJODrugScreen/slice';
import { executionErrorOccured } from 'redux/slices/globalSlice';

/**
 * Renders the Step6: Drug Screen Check for the Portrait and Desktop views.
 */

const DrugScreenCheck = (props) => {
	const { t } = useTranslation();
	const { callGetStepInd, isSupplyChain, nextStepInd } = useVirtualJobOffer();
	const {
		initiateCandidateDrugScreenResponse,
		getVJOisDrugScreenRequiredResponse,
		callGetVjoDsEndpt,
		drugScreenCheck,
		drugScreenRequiredResponse,
		shouldCallGetVjoDsEndpoint,
		account
	} = useDrugScreenInfo();
	const dispatch = useDispatch();
	const vjoDSCheckSchedule_now = 'vjoDSCheck.schedule_now';

	const [open, setOpen] = useState(false);
	const [isInitiateDSError, setIsInitiateDSError] = useState(false);
	const [isScheduleNowEnabled, setIsScheduleNowEnabled] = useState(false);
	const [eScreenUrl, setEScreenUrl] = useState(null);
	const [duplicateDsInitiation, setDuplicateDsInitiation] = useState(false);

	useEffect(() => {
		window.scroll(0, 0);
	 },[])

	useEffect(() => {
		//land on DS screen behaviour
		if (nextStepInd === STEPS.DRUG_SCREEN_CHECK + 1) {
			getVJOisDrugScreenRequiredResponse(6);
		}
	}, []);

	useEffect(() => {
		//getVJOisDrugScreenRequired response behaviour
		if (drugScreenRequiredResponse?.drugScreenStatus < DRUG_SCREEN_STATUS.COMPLETED) {
			setIsScheduleNowEnabled(true);
		} else {
			props.handleChangeCurrentStepStatus(true);
			if (nextStepInd === STEPS.DRUG_SCREEN_CHECK + 1) {
				callGetStepInd();
			}
		}
	}, [drugScreenRequiredResponse, nextStepInd]);

	useEffect(() => {
		//initiateCandidateDrugScreen response behaviour
		if (nextStepInd === STEPS.DRUG_SCREEN_CHECK + 1 && drugScreenCheck) {
			const responseCode = drugScreenCheck?.response?.responseCode;
			setDuplicateDsInitiation(false);
			if (responseCode === '200') {
				setStatesForInitiateResponse();
			}else if(responseCode === '405'){
				setIsInitiateDSError(true);
				setDuplicateDsInitiation(true);
			}
			 else {
				setIsInitiateDSError(true);
			}
			dispatch(refreshDrugScreenCheck());
		}
	}, [drugScreenCheck]);

	useEffect(() => {
		//on continue button click in progressTrackerComp
		if (shouldCallGetVjoDsEndpoint) {
			const { applicantId, storeNbr, reqsnId, jobOfferNbr } = account?.data?.auth?.vjo;
			const isSupplyChain = account?.data?.auth?.supplyChain;
			dispatch(
				isDrugScreenRequired({
					storeNbr: storeNbr,
					isSupplyChain: isSupplyChain,
					requestInput: {
						storeNbr: storeNbr,
						applicantId: applicantId,
						requisitionId: reqsnId,
						jobOfferNbr: jobOfferNbr,
						stepInd: STEPS.BACKGROUND_CHECK_WITH_DS_REQUIRED + 1
					}
				})
			).then((res) => {
				if(res?.payload?.drugScreenStatus < DRUG_SCREEN_STATUS.COMPLETED){
					initiateCandidateDrugScreenResponse();
				}
				else{
					props.handleChangeNextStep();
				}
			}).catch((err)=>{ dispatch(executionErrorOccured()) });
			callGetVjoDsEndpt(false);
		}
	}, [shouldCallGetVjoDsEndpoint]);

	const setStatesForInitiateResponse = () =>{
		setIsScheduleNowEnabled(false);
		setIsInitiateDSError(false);
		setOpen(true);
		setEScreenUrl(drugScreenCheck.response.eScreenUrl);
		props.handleChangeCurrentStepStatus(true);
	}

	const SupplyChainView = useMemo(() => {
		if (!isSupplyChain) {
			return null;
		}
		return (
			<Body
				as="p"
				children={<Trans>{t('vjoDSCheck.content_sc')}</Trans>}
				size="medium"
				UNSAFE_className="ds_check_content"
			/>
		);
	}, [isSupplyChain, t]);

	const StoreHourlyView = useMemo(() => {
		if (isSupplyChain) {
			return null;
		}
		return (
			<Body
				as="p"
				children={<Trans>{t('vjoDSCheck.content_sh')}</Trans>}
				size="medium"
				UNSAFE_className="ds_check_content"
			/>
		);
	}, [isSupplyChain, t]);

	const handlePopUpOpen = () => {
		initiateCandidateDrugScreenResponse();
	};

	const handlePopUpClose = () => {
		setOpen(false);
	};
	const handleDrugScreenOpen = () => {
		if(eScreenUrl) {
			window.open(eScreenUrl, '_blank');
			setEScreenUrl(null);
		}
		setOpen(false);
		setIsScheduleNowEnabled(false);
		props.handleChangeCurrentStepStatus(true);
	};

	const dateFormatForDrugScreen = (str) => {
		if (str) {
			var check = moment(str, 'YYYY/MM/DD');
			check = check.subtract(59, 'days');
			var month = check.format('MMM');
			var day = check.format('DD');
			var year = check.format('YYYY');
			return month + ' ' + day + ', ' + year;
		}
		return '';
	};

	const contentOnDrugScreenStatus = useMemo(() => {
		if (!Number.isFinite(drugScreenRequiredResponse?.drugScreenStatus)) {
			return;
		}
		switch (drugScreenRequiredResponse.drugScreenStatus) {
			case DRUG_SCREEN_STATUS.NOT_STARTED:
			case DRUG_SCREEN_STATUS.INITIATED:
				return (
					<Card UNSAFE_className="ds_check_initiate_container">
						<Body
							children={t('vjoDSCheck.initiate_ds')}
							weight={700}
							size="medium"
							UNSAFE_className="ds_check_container_content"
						/>
						{isScheduleNowEnabled ? (
							<Button
								children={t(vjoDSCheckSchedule_now)}
								size="medium"
								variant="tertiary"
								onClick={handlePopUpOpen}
								UNSAFE_className="link"
							/>
						) : (
							<Body
								children={t(vjoDSCheckSchedule_now)}
								size="medium"
								UNSAFE_className="ds_check_container_content"
							/>
						)}
					</Card>
				);
			case DRUG_SCREEN_STATUS.COMPLETED:
				return (
					<Card UNSAFE_className="ds_check_initiate_container">
						<Body
							children={t('vjoDSCheck.drug_success')}
							weight={700}
							size="medium"
							UNSAFE_className="ds_check_container_content"
						/>
						{drugScreenRequiredResponse?.expirDate &&
						<Body
							children={`${t('vjoDSCheck.completed_on')}${dateFormatForDrugScreen(
								drugScreenRequiredResponse?.expirDate
							)}`}
							weight={700}
							size="medium"
							UNSAFE_className="ds_check_container_content"
						/>
							}
						<Body
							children={t('vjoDSCheck.test_duration')}
							size="medium"
							onClick={handlePopUpOpen}
							UNSAFE_className="ds_check_container_content"
						/>
					</Card>
				);
			default:
				break;
		}
	}, [
		t,
		drugScreenRequiredResponse?.drugScreenStatus,
		drugScreenRequiredResponse?.expirDate,
		isScheduleNowEnabled
	]);

	return (
		<Card UNSAFE_className="ds_check_card_container">
			<Heading children={t('vjoDSCheck.ds_required')} size="small" />
			{isSupplyChain ? SupplyChainView : StoreHourlyView}
			{isInitiateDSError && (
				<Alert
					children={duplicateDsInitiation ? t('vjoDSCheck.tryLater') : t(`vjoDSCheck.initiateDsFailure${isSupplyChain ? 'Sc' : 'Sh'}`)}
					variant="error"
					UNSAFE_className="alert_error"
				/>
			)}
			{contentOnDrugScreenStatus}
			<AlertDialog isOpen={open} title={t('vjoDSCheck.initiate_ds')} onClose={handlePopUpClose}>
				<div className="ds_pop_up_container">
					<Body
						children={t('vjoDSCheck.popup_content')}
						size="small"
						UNSAFE_className="popup_content"
					/>
					<Divider />
					<Button
						variant="primary"
						size="small"
						isFullWidth={true}
						children={t('vjoDSCheck.confirm')}
						UNSAFE_className="confirm_button"
						onClick={handleDrugScreenOpen}
					/>
				</div>
			</AlertDialog>
		</Card>
	);
};

export default DrugScreenCheck;
