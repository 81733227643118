import {createSlice} from '@reduxjs/toolkit';
import {fetchExternalPersonalDetails, saveExternalPersonalDetails} from './request';

const initialState = {data: {}, loading: false, error: {}};

const ContactInfoSlice = createSlice({
  name: 'getContactInfo',
  initialState,
  reducers: {
    updateContactInfoRequestInput: (state, action) => {
      state.data.requestInput = { ...state.data.requestInput, ...action.payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchExternalPersonalDetails.fulfilled, (state, action) => {
        state.data.response = action.payload;
      })
      .addCase(saveExternalPersonalDetails.fulfilled, (state, action) => {
        state.data.saveResponse = action.payload;
      })
      .addCase(fetchExternalPersonalDetails.rejected, (state) => {
        state.error = {message: 'Something went wrong'};
      })
      .addCase(saveExternalPersonalDetails.rejected, (state) => {
        state.error = {message: 'Something went wrong'};
      });
  },
});

export const {updateContactInfoRequestInput} = ContactInfoSlice.actions;

export default ContactInfoSlice.reducer;
