import React, { useEffect, useState } from 'react';
import { useSelfSchedule } from '../hooks/useSelfSchedule';
import moment from 'moment';
import style from '../SelfSchedule.module.scss';
import { useSelector } from 'react-redux';
import TimeChip from './TimeChip';
import { useTranslation } from 'react-i18next';
import { WizardFooter } from '@walmart-web/livingdesign-components';
const Icon = require('@livingdesign/icons');

const HRAvailability = () => {
	const { t } = useTranslation();
	const { handleOnTimePick } = useSelfSchedule();
	const { handleOnTimePickOrientation } = useSelfSchedule();
	const [ slot, setSlot] = useState();
	const [ confirmDisabled,setConfirmDisabled]=useState(true);
	const [dateTimeEntries, setDateTimeEntries] = useState([]);
	const selfSchedule = useSelector((state) => state.selfSchedule);
	const global = useSelector((state) => state.global);

	const getOrientationTime = (obj) => {
		let label = moment(obj.time, 'HH:mm').format('LT');
		console.log('LABEL = ' + label);
		const selected = obj.time === selfSchedule.selectedTimeSlot.time;
		label = selected ? `Confirm ${label} ?` : label;
		return (
			<TimeChip
				style={{
					backgroundColor: selected ? '#2E2F32' : '',
					width: selected ? '165px' : '102px'
				}}
				selected={selected}
				handleOnTimePick={(slot) => handleOnTimePickOrientation(slot)}
				interval={obj}
				label={label}
				key={obj.time}
			/>
		);
	};

	useEffect(() => {
		if (selfSchedule.dateAndTimeSlots) {
			if (selfSchedule.showPartialSlots) {
				setDateTimeEntries(selfSchedule.partialDateAndTimeSlots);
			} else {
				setDateTimeEntries(selfSchedule.dateAndTimeSlots);
			}
		}
	}, [selfSchedule.responseData, selfSchedule.seeMore]);
	return (
		<>
			{selfSchedule.isHr
				? dateTimeEntries.map((availability, dateIndex) => (
						<div className={style.date_and_time} key={dateIndex}>
							<div>
								<div className={style.day}>
									<Icon.Calendar size="medium" />
									<div id={'day'}>
										<div className={'selected'}>
											{moment(availability.date)
												.locale(global.language === 'ES' ? 'es' : 'en')
												.format('dddd | MM-DD-YYYY')}{' '}
										</div>
									</div>
								</div>
							</div>
							<div>
								<div className={style.time}>
									{availability.timeIntervals &&
										availability.timeIntervals.map((interval, timeIndex) => {
											if (interval.display) {
												let label = moment(interval.startTime).format('LT');
												const selected =
													interval.startTime === selfSchedule.selectedTimeSlot.startTime;
													if(moment(interval.startTime).isAfter(moment().add(30,'minutes'))){
													return (
													<TimeChip
														isInterview={true}
														style={{
															backgroundColor: selected ? '#2E2F32' : '',
															width: selected ? '165px' : '102px'
														}}
														selected={selected}
														handleOnTimePick={(currentSlot) => {
															if (slot !== currentSlot) {
															  setSlot(currentSlot);
															  handleOnTimePick(currentSlot);
															  setConfirmDisabled(false);
															}
														}}
														interval={interval}
														label={label}
														key={interval.startTime}
													/>
												);
												}
											}
										})}
								</div>
							</div>
						</div>
				  ))
				: dateTimeEntries.map((obj, key) => (
						<div className={style.date_and_time} key={key}>
							<div>
								<div className={style.day}>
									<Icon.Calendar size="medium" />
									<div id={'day'}>
										<div className={'selected'}>
											{moment(obj.startDate).locale('en').format('dddd | MM-DD-YYYY')}{' '}
										</div>
									</div>
								</div>
							</div>
							<div className={style.time}>{getOrientationTime(obj)}</div>
						</div>
				  ))}

					
					<div className={style.sh_selfschedule_footer}>
					<WizardFooter
     					 previousActionProps={{
       						UNSAFE_className: 'wizard_footer_hide_previous'
      					}}
      					nextActionProps={{
       					UNSAFE_className: 'wizard_footer_next',
       					disabled: confirmDisabled,
       					onClick: () => {handleOnTimePick(slot)},
       					children: t('general.confirm')
      				}}></WizardFooter>
					</div>
		</>
	);
};

export default HRAvailability;
