import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './Disclaimer.module.css';
import { Body, Caption, Checkbox, Button } from '@walmart-web/livingdesign-components';
import { useDispatch, useSelector } from 'react-redux';
import {
	acceptedDisclaimer,
	displayFooter,
	errorOccured,
	updateCountryCode,
	updateLanguage,
	updateLoading,
	updateLocation,
	updateTimeZone
} from '../../redux/slices/globalSlice';
import { useTranslation } from 'react-i18next';
import {
	getCountryDetails,
	getDisclaimertexts,
	getOutagePeriod
} from '../../redux/Disclaimer/request';
import { unwrapResult } from '@reduxjs/toolkit';
import { useMediaQuery } from 'react-responsive';
import cookie from 'react-cookies';
import { useRedirect } from 'utils/useRedirect';

function Disclaimer() {
	const iPadPortrait = useMediaQuery({
		minWidth: 426,
		maxWidth: 850,
		orientation: 'portrait'
	});
	const isMobile = useMediaQuery({ maxWidth: 425 });
	const navigate = useNavigate();
	const global = useSelector((state) => state.global);
	const dispatch = useDispatch();
	const countryCode = 'US';
	const languageCode = global.language === 'ES' ? 102 : 101;
	const { t, i18n } = useTranslation();
	const { loadCookiesAndUpdateRedirectStore } = useRedirect();

	//to set disclaimer text based on country
	const [disclaimerText, setDisclaimerText] = useState();

	//to set if user has agreed to terms
	const [checkBox, setCheckBox] = useState(false);

	const errorPayload = {
		open: true,
		title: t('general.error'),
		message: t('general.systemErrorText'),
		canBeTranslated : false
	};

	//set location
	const onSuccess = async (location) => {
		//make a call to service & get the country details
		const requestPayload = {
			requestInput: {
				lat: location.coords.latitude,
				long: location.coords.longitude
			}
		};

		try {
			const result = await dispatch(getCountryDetails(requestPayload));
			const orignalPromise = unwrapResult(result);
			const res = orignalPromise;
			//assign the country/Disclaimer to country variable & Disclaimer
			let countryName = '';
			if (res && res.country !== '') {
				countryName = res.country;
				if (countryName === 'PR') {
					i18n.changeLanguage('ES'.toLowerCase());
				}
				dispatch(updateCountryCode(countryName));
				dispatch(
					updateLocation({
						loaded: true,
						coordinates: {
							lat: location.coords.latitude,
							long: location.coords.longitude,
							country: countryName,
							city: res.city,
							state: countryName === 'PR' ? 'PR' : res.state
							// For TESTING
							// lat:36.3729,
							// long:-94.2088,
							// country: "US",
							// city: "Bentonville",
							// state: "AR"
						}
					})
				);
			}
		} catch (error) {
			// DO NOT dispatch(errorOccured({open:true, title:"Error", message: "Something went Wrong. Please Try again Later"}))
			// Let's NOT break User Experience if we can not get the Country for a given Lat, Long
			dispatch(
				updateLocation({
					loaded: false,
					error: error
				})
			);
			console.log('error : ', error);
		}
	};

	const getDisclaimerText = async () => {
		try {
			const result = await dispatch(getDisclaimertexts());
			const orignalPromise = unwrapResult(result);
			const res = orignalPromise;
			if (res.response && res.response.code !== 200) {
				dispatch(errorOccured(errorPayload));
			} else {
				let maxKey = 0;
				for (const [key, value] of Object.entries(res)) {
					if (value[countryCode] && maxKey < key) {
						maxKey = key;
						setDisclaimerText(value);
					}
				}
			}
		} catch (error) {
			dispatch(errorOccured(errorPayload));
		}
	};

	//handle geolocation error
	const onGeoLocationError = (error) => {
		dispatch(
			updateLocation({
				loaded: false,
				error: error
			})
		);

		console.log('error : ', error);
	};

	useEffect(() => {
		loadCookiesAndUpdateRedirectStore();
		if (cookie.load('languagePref')) {
			i18n.changeLanguage(cookie.load('languagePref').toLowerCase());
			dispatch(updateLanguage(cookie.load('languagePref')));
		}
		dispatch(updateLoading(true));
		dispatch(getOutagePeriod())
			.unwrap()
			.then((result) => {
					if (result.maintanance) {
					dispatch(updateLoading(false));
					navigate('../maintenance');
				} else {
					dispatch(displayFooter(true));
					getDisclaimerText().then(() => {
						//get timezone and assign country before asking location access
						const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
						dispatch(updateTimeZone(tz));
						if (tz === 'America/Puerto_Rico') {
							i18n.changeLanguage('ES'.toLowerCase());
							dispatch(updateCountryCode('PR'));
						}

						//check if browser doesn't support geolocation
						if (!('geolocation' in navigator)) {
							onGeoLocationError({
								code: 0,
								message: 'Geolocation not supported'
							});
						} else {
							navigator.geolocation.getCurrentPosition(onSuccess, onGeoLocationError);
						}
						dispatch(updateLoading(false));
					});
				}
			});

		// eslint-disable-next-line
	}, []);

	const onChangeCheckBox = () => {
		setCheckBox(!checkBox);
	};

	const acceptDisclaimer = () => {
		cookie.save('isDisclaimerAccepted', 'Y');
		dispatch(acceptedDisclaimer(true));
		navigate('../homeScreen');
	};

	const customStyles = {
		text: {
			color: '#FFFFFF'
		}
	};

	return (
		<div className={style.parent}>
			<div className={style.disclaimer}>
				<div className={style.disclaimerContent}>
					<div className={style.walmartTitle}>
						<div className={style.walmartTitleChild}>
							<img
								src={require('../../assets/images/walmart-title.png')}
								alt="Walmart logo"
								className={style.walmartImg}
							/>
						</div>
					</div>
					<div className={style.left}>
						<Body as="p" size="large" weight={700} UNSAFE_style={customStyles.text}>
							{disclaimerText && t('disclaimer.pleaseRead')}
						</Body>
					</div>

					<Caption
						as="p"
						weight={400}
						UNSAFE_style={customStyles.text}
						UNSAFE_className={style.disclaimerTextSize}>
						{disclaimerText && disclaimerText[countryCode]
							? disclaimerText[countryCode][languageCode].split('<br/>')[0]
							: ''}
					</Caption>
					<Caption
						as="p"
						weight={400}
						UNSAFE_style={customStyles.text}
						UNSAFE_className={style.disclaimerTextSize}>
						{disclaimerText && disclaimerText[countryCode]
							? disclaimerText[countryCode][languageCode].split('<br/>')[1]
							: ''}
					</Caption>
				</div>
			</div>
			{disclaimerText && (
				<div className={style.agree}>
					<div className={style.agreeFlex}>
						<Checkbox
							data-testid="disclaimer_checbox"
							label={t('disclaimer.terms')}
							checked={checkBox ? 'T' : ''}
							onChange={onChangeCheckBox}
							value="true"
						/>
						<Button
							size={isMobile || iPadPortrait ? 'medium' : 'large'}
							variant="primary"
							disabled={!checkBox}
							onClick={acceptDisclaimer}
							isFullWidth={isMobile || iPadPortrait}>
							{t('general.continue')}
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}

export default Disclaimer;
