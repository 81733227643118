import { WizardFooter } from '@walmart-web/livingdesign-components';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import { subHeader, updateLanguageToggle } from '../../../redux/slices/globalSlice';
import taxQuestionnaireIcon from '../../../assets/svg/taxQuestionnaireIcon.svg';
import useTaxQuestionnaire from './useTaxQuestionnaire';
import PreambleContent from './Preamble';
import QuestionsContent from './Questions';

import styles from './TaxQuestionnaire.module.scss';

const TaxQuestionnaire = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const global = useSelector((state) => state.global);
	const isSubmissionScreen = useSelector((state) => state.questionnaire?.isSubmissionScreen);
	const welcomePageTaxQuestionnaire = 'welcomePage.taxQuestionnaire';
	const {
		taxQuestions,
		activeTaxQuestionsIndex,
		handlePrevious,
		handleSubmit,
		onChangeAnsCallback,
		onValidationCallback,
		validateAnswers,
		handleUpdatePersonalInfo,
		checkQuestionnaireStatusAndRedirect,
	} = useTaxQuestionnaire();
	const showPreamble = !taxQuestions?.length;

	useEffect(() => {
		if (global?.error?.message) {
			navigate('../error');
		}
	}, [global, global.error.message]);

	useEffect(() => {
		if (!global.applicantId || global.applicantId === 0) {
			navigate('../login');
		} else {
			dispatch(subHeader({ display: true, title: welcomePageTaxQuestionnaire }));
			handleUpdatePersonalInfo();
		}
	}, []);

	useEffect(() => {
		checkQuestionnaireStatusAndRedirect();
	}, []);

	useEffect(() => {
		dispatch(updateLanguageToggle({ display: showPreamble }));

		return () => {
			dispatch(updateLanguageToggle({ display: true }));
		};
	}, [showPreamble]);

	return (
		<>
			{
				props.fromRenew ? 
				<div className={styles.container__body__content}>
				<div className={styles.body}>
					{showPreamble ? (
						<PreambleContent />
					) : (
						<QuestionsContent
							data={taxQuestions[activeTaxQuestionsIndex] || []}
							onChangeAnsCallback={onChangeAnsCallback}
							onValidationCallback={onValidationCallback}
						/>
					)}
				</div> 
				</div>:
					<div className={styles.container}>
						<div className={styles.container__heading}>
							<img
								src={taxQuestionnaireIcon}
								alt={t(welcomePageTaxQuestionnaire)}
								className={styles.assessment_image}
							/>
						</div>
						<div className={styles.container__heading__blue}></div>
						<div className={styles.container__body}>
							<div className={styles.container__body__content}>
								<div className={styles.body}>
									{showPreamble ? (
										<PreambleContent />
									) : (
										<QuestionsContent
											data={taxQuestions[activeTaxQuestionsIndex] || []}
											onChangeAnsCallback={onChangeAnsCallback}
											onValidationCallback={onValidationCallback}
										/>
									)}
								</div>
							</div>
						</div>
						
					</div>
			}
			<div className={styles.container__footer}>
							<WizardFooter
								previousActionProps={{
									disabled: props.fromRenew && showPreamble ? true : false,
									UNSAFE_className: 'wizard_footer_previous',
									onClick: () => handlePrevious(showPreamble),
									children: t('general.previous')
								}}
								nextActionProps={{
									UNSAFE_className: 'wizard_footer_next',
									disabled: false,
									onClick: () => {
										if (validateAnswers(taxQuestions[activeTaxQuestionsIndex])) {
											handleSubmit(showPreamble, props.fromRenew, props);
										} else {
											scroll?.scrollToTop?.();
										}
									},
									children: isSubmissionScreen ? t('general.submit') : t('general.continue')
								}}></WizardFooter>
						</div>
			</>
	);
};

export default TaxQuestionnaire;
