import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enText from "./en.json";
import esText from "./es.json";
import enTextPR from "./en_pr.json";
import esTextPR from "./es_pr.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: enText,
      prTranslations: enTextPR
    },
    es: {
      translations: esText,
      prTranslations: esTextPR
    },
  },
  ns: ["translations,prTranslations"],
  defaultNS: "translations",
  fallbackNS: "translations",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'dev',
});

export default i18n;
