import { Body, Card, CardContent, Heading, Button } from '@walmart-web/livingdesign-components';
import { Search, User } from '@livingdesign/icons';
import React, { useEffect, useState } from 'react';
import style from './Landing.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { environment } from '../../utils/environment/environment';
import { useTranslation } from 'react-i18next';
import LocSearchContainer from '../Location/components/LocationSearch/LocSearchContainer';
import { sendGtmEvent } from 'utils/GoogleTagManagerUtils';
import { GTM } from 'config/const';
import cookie from 'react-cookies';
import { useRedirect } from 'utils/useRedirect';
import { updateLoading } from 'redux/slices/globalSlice';

const Landing = () => {
	const global = useSelector((state) => state.global);

	//this prop is for bottomsheet visiblity toggle
	const [isOpen, setIsOpen] = useState(false);

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const { handleRedirect } = useRedirect();

	const { t } = useTranslation();

	useEffect(() => {
		if (cookie.load('isDisclaimerAccepted') !== 'Y' && cookie.load('landedOnApplicantLogin') !== 'Y') {
			navigate('../disclaimer');
		}
	}, [navigate]);

	const handleJobSearchClick = async () => {
		sendGtmEvent(GTM.EVENTS.GUEST_JOB_SEARCH);
		const preferredStoresFromATS = global.preferedStoresFromRedirect;
		if (global.isCpRedirect && preferredStoresFromATS.length) {
			dispatch(updateLoading(true));
			const isRedirectSuccessful = await handleRedirect(preferredStoresFromATS);
			if (!isRedirectSuccessful) {
				setIsOpen(true);
			}
		} else {
			setIsOpen(true);
		}
	};

	const handleLoginClick = () => {
		sendGtmEvent(GTM.EVENTS.LOGIN_BUTTON);

		if (global.applicantId !== 0) {
			navigate('../login');
		} else if (environment.isPingfed === 'true') {
			window.location.replace(environment.pingFedLoginUrl);
		} else {
			navigate('../applicant-login');
		}
	};
	const handleForgotPwdClick = () => {
		navigate('../recoverPassword');	
	};
	

	/*
  -----------------JSX PART -------------------------
  */
	return (
		<div className={style.container}>
			<div className={style.title}>
				<div className={style.walmartLogo}>
					<div className={style.walmartTitleChild}>
						<img
							src={require('../../assets/images/walmart-title.png')}
							alt="Walmart logo"
							className={style.walmartImg}
						/>
					</div>
				</div>
				<div className={style.welcomeText}>
					<Heading as="h2" size="small" weight={400} UNSAFE_style={{ color: '#FFF' }}>
						{t('landingPage.welcome')}
					</Heading>
				</div>
			</div>
			<div className={style.cardContainer}>
				<Card size="medium" UNSAFE_className={style.cardItem}>
					<CardContent>
						<div className={style.cardItems}>
							<User size="medium" />
							<Heading as="h3" size="small" weight={700}>
								{t('landingPage.newCandidateTitle')}
							</Heading>
						</div>
						<Body
							as="p"
							weight={400}
							size="small"
							UNSAFE_style={{
								paddingTop: '8px',
								paddingBottom: '16px'
							}}>
							{t('landingPage.jobSearchSubTitle')}
						</Body>
						<Button isFullWidth size="medium" variant="primary" onClick={handleJobSearchClick}>
							{(global.isCpRedirect && global.preferedStoresFromRedirect.length) ? t('landingPage.jobSearchRedirectButtonTitle') : t('landingPage.jobSearchButtonTitle') }
						</Button>
					</CardContent>
				</Card>
				<Card size="medium" UNSAFE_className={style.cardItem}>
					<CardContent>
						<div className={style.cardItems}>
							<User size="medium" />
							<Heading as="h3" size="small" weight={700}>
								{t('landingPage.returningCandidatesTitle')}
							</Heading>
						</div>
						<Body
							as="p"
							weight={400}
							size="small"
							UNSAFE_style={{
								paddingTop: '8px',
								paddingBottom: '16px'
							}}>
							{' '}
							{t('landingPage.alreadyHaveAnAccount')} <br></br>
							{t('landingPage.returningCandidatesSubTitle')}
						</Body>
						<Button isFullWidth size="medium" variant="secondary" onClick={handleLoginClick}>
							{t('landingPage.logIn')}
						</Button>
						<Button isFullWidth size="medium" variant="tertiary" onClick={handleForgotPwdClick}  UNSAFE_style={{paddingTop : 16 }}>
							{t('landingPage.forgotPassword')}
						</Button>
					</CardContent>
				</Card>
				<div></div>
			</div>

			<LocSearchContainer isOpen={isOpen} setIsOpen={setIsOpen} />
		</div>
	);
};

export default Landing;
