import {Body, StyledText} from '@walmart-web/livingdesign-components';
import React, {useMemo, memo} from 'react';
import PropTypes from 'prop-types';

const JobOfferDetailsItem = ({title, value, icon}) => {
  return useMemo(() => {
    return (
      <div className='detail_item'>
        <div className='detail_item_left'>
          <StyledText
            UNSAFE_className='detail_item_title'
            leading={<img alt={title} src={icon} />}
            size='medium'
            children={title}
          />
        </div>
        <div className='detail_item_right'>
          <Body size='medium' weight={700} children={value} />
        </div>
      </div>
    );
  }, [icon, title, value]);
};
/**
 * PropTypes for the JobOfferDetailsItem component
 */
JobOfferDetailsItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
export default memo(JobOfferDetailsItem);
