import React, { useEffect, useRef, useState } from 'react';
import './LocationFooter.css';
import {
	Body,
	BottomSheet,
	Button,
	ButtonGroup,
	Panel
} from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import LocationCard from '../LocationCard/LocationCard';
import LocationCarousel from '../LocationCarousel/LocationCarousel';
import { mapSvg } from '../LocationIcons/LocationIcons';
import { ReactComponent as ShoppingLists } from '@livingdesign/pictograms/dist/svg/App_&_Web_Services/ShoppingLists.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import LocationStoreList from '../LocationStoreList/LocationStoreList';
import { saveLocationPreference } from '../../../../redux/Location/request';
import { callHiringHelper } from 'redux/HiringHelper/request';
import { updateLoading } from '../../../../redux/slices/globalSlice';
import { clearSelectedStore, updateSelectedStore } from '../../../../redux/Location/slice';
import { updateCurrentSelectedJobs, updateSelectedJobs } from 'redux/slices/jobSearchSlice';
import { useDisplay } from 'utils/useDisplay';
const LocationFooter = ({ filteredStores }) => {
	const { isPortrait } = useDisplay();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const global = useSelector((state) => state.global);
	const location = useSelector((state) => state.location);
	const account = useSelector((state) => state.account);
	const [initList, setInitList] = useState(false);
	const [showStoreList, setShowStoreList] = useState(false);
	const [showSelectedLocations, setShowSelectedLocations] = useState(false);
	const [selectedLocations, setSelectedLocations] = useState(null);
	const selectedPos = useSelector((state) => state.location.selectedPos);
	const selectedJobs = useSelector((state) => state.jobSearch.currentSelectedJobs);
	const retainLocationsRef = useRef(false);

	const controlListDisplay = () => {
		setInitList(false);
		setShowStoreList(!showStoreList);
	};

	const { t } = useTranslation();

	const openSelectedLocations = () => {
		if (selectedPos.length > 0) {
			setShowSelectedLocations(true);
			setSelectedLocations(selectedPos);
		}
	};
	const closeSelectedLocations = () => {
		setShowSelectedLocations(false);
		setSelectedLocations(null);
	};
	const onContinue = () => {
		dispatch(updateLoading(true));

		const selectedScFacilities = selectedPos.filter((selectedStore) => selectedStore.sc);
		const selectedShStores = selectedPos.filter((selectedStore) => !selectedStore.sc);

		if (!selectedShStores.length) {
			//remove all SH jobs alone if last 1 and only SH store removed
			const selectedScJobs = selectedJobs.filter((job) => job.workgroupId >= 5000); //retain only SC
			dispatch(updateCurrentSelectedJobs(selectedScJobs));
			dispatch(updateSelectedJobs(selectedScJobs));
		}
		if (!selectedScFacilities.length) {
			//remove all SC jobs alone if last 1 and only SC store removed
			const selectedShJobs = selectedJobs.filter((job) => job.workgroupId < 5000); //retain only SH
			dispatch(updateCurrentSelectedJobs(selectedShJobs));
			dispatch(updateSelectedJobs(selectedShJobs));
		}

		if (global.applicantId !== 0) {
			const request = {
				applicantId: global.applicantId,
				storeNbr: selectedPos.map((loc) => Number(loc.storeNumber))
			};
			dispatch(saveLocationPreference(request, dispatch)).then(() => {
				dispatch(updateSelectedStore(selectedPos.map((store) => ({ ...store, checked: true }))));
				if (account?.data?.auth?.stepNbr >= 850) {
					dispatch(callHiringHelper(global.applicantId));
				}
				retainLocationsRef.current = true;
				navigate('../job-search');
			});
		} else {
			retainLocationsRef.current = true;
			navigate('../job-search');
		}
	};

	useEffect(() => {
		return () => {
			if (!retainLocationsRef.current) {
				dispatch(clearSelectedStore());
			}
		};
	}, []);

	useEffect(() => {
		if (global.isCpRedirect && global.preferedStoresFromRedirect?.length) {
			openSelectedLocations();
		}
	}, [global.isCpRedirect, global.preferedStoresFromRedirect?.length]);

	useEffect(() => {
		setInitList(false);
		setShowStoreList(false);
	}, [location.location]);

	const renderSelectedLocations = (selectedLocations) => {
		return (
			<React.Fragment>
				{selectedLocations &&
					selectedLocations.map((store) => (
						<div className={'LocationFooter__Selected__Card'} key={store.storeNumber}>
							<LocationCard store={store} />
						</div>
					))}
			</React.Fragment>
		);
	};

	const getLocationFooterActionButtons = () => {
		return  (
			<ButtonGroup UNSAFE_className={'LocationFooter__ActionButtons'}>
				<Button variant="tertiary" onClick={closeSelectedLocations}>
					{t('location.addFacilities')}
				</Button>
				<Button variant="primary" onClick={onContinue} disabled={selectedPos.length === 0}>
					{t('general.continue')}
				</Button>
			</ButtonGroup>
		);
	};

	return (
		<div className={'LocationFooter'}>
			{isPortrait && !location.currentTab &&(
				<>
					<LocationCarousel
						storeList={filteredStores}
						className={initList || showStoreList ? 'displayHide' : ''}
					/>
					<LocationStoreList
						filteredStores={filteredStores}
						initList={initList}
						showStoreList={showStoreList}
					/>
				</>
			)}
			<div className="LocationFooter__Actions">
				<Button
					variant={'tertiary'}
					size={isPortrait ? 'medium' : 'large'}
					UNSAFE_style={{ padding: '0px' }}
					onClick={openSelectedLocations}
					disabled={selectedPos.length === 0}>
					{t('location.selectedLocation')} ({selectedPos.length})
				</Button>
				<Button
					variant={'primary'}
					disabled={selectedPos.length === 0}
					size={isPortrait ? 'medium' : 'large'}
					onClick={onContinue}>
					{t('general.continue')}
				</Button>
			</div>
			{isPortrait ? (
				<BottomSheet
					isOpen={showSelectedLocations}
					onClose={closeSelectedLocations}
					title={t('location.applyingTo')}
					actions={getLocationFooterActionButtons()}>
					{renderSelectedLocations(selectedLocations)}
				</BottomSheet>
			) : (
				<Panel
					isOpen={showSelectedLocations}
					onClose={closeSelectedLocations}
					title={t('location.applyingTo')}
					position="right"
					size="medium"
					actions={getLocationFooterActionButtons()}>
					{renderSelectedLocations(selectedLocations)}
				</Panel>
			)}
		</div>
	);
};

export default LocationFooter;
