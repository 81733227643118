import {createSlice} from '@reduxjs/toolkit';
import {getDocuSignUrl, checkDocuSignResponse} from './request';

const initialState = {data: {}, loading: false, error: {}};

const ReviewSlice = createSlice({
  name: 'reviewDocInfo',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDocuSignUrl.fulfilled, (state, action) => {
        state.data.docuSignInfo = action.payload;
      })
      .addCase(checkDocuSignResponse.fulfilled, (state, action) => {
        state.data.docuSignResp = action.payload;
      })
      .addCase(getDocuSignUrl.rejected, (state) => {
        state.error = {message: 'Something went wrong'};
      })
      .addCase(checkDocuSignResponse.rejected, (state) => {
        state.error = {message: 'Something went wrong'};
      });
  },
});

export const {Review} = ReviewSlice.actions;

export default ReviewSlice.reducer;
