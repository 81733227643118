import React, { memo } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { Body } from '@walmart-web/livingdesign-components';

const JobHourItem = ({ day, startTime, endTime, isSC }) => {
	const getEndTimeToDisplay = () => {
		if (!isSC && startTime === '00:00' && endTime === '00:00') {
			return '23:59';
		} else {
			return endTime;
		}
	};
	return (
		<div className="job_hour_item">
			<Body size="small" children={day} />
			<Body size="small" children={startTime} />
			<Body size="small" children={getEndTimeToDisplay()} />
		</div>
	);
};

/**
 * PropTypes for the JobHourItem component
 */
JobHourItem.propTypes = {
	day: PropTypes.string.isRequired,
	startTime: PropTypes.string.isRequired,
	endTime: PropTypes.string.isRequired
};

export default memo(JobHourItem);
