import {createSlice} from '@reduxjs/toolkit';
import {validateCityStateZipCombination} from './request';

const initialState = {data: {}, loading: false, error: {}};

const ValidateAddressSlice = createSlice({
  name: 'validateAddress',
  initialState,
  reducers: {
    updateValidatingAddressFlag: (state, action) => {
      state.isValidatingAddress = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(validateCityStateZipCombination.fulfilled, (state, action) => {
        state.data.validateCombination = action.payload;
      })
      .addCase(validateCityStateZipCombination.rejected, (state) => {
        state.error = {message: 'Something went wrong'};
      });
  },
});

export const {updateValidatingAddressFlag} = ValidateAddressSlice.actions;

export default ValidateAddressSlice.reducer;
