import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardInfo } from '../../redux/PostApplyDashboard/request';
import { updateLoading } from '../../redux/slices/globalSlice';
import Banner from './components/Banner';
import ApplicationStatus from './components/ApplicationStatus';
import Assessments from './components/Assessments';
import Locations from './components/Locations';
import JobSearch from './components/JobSearch';
import VJOExpiry from './components/VJOExpiry'
import './style.scss';
import { useNavigate } from 'react-router';
import { useDisplay } from 'utils/useDisplay';
import {
	isShiftUpdateNeeded,
	isScheduleUpdateNeeded,
	updateEditAvailability
} from 'redux/EditProfile/Slice';
import cookie from 'react-cookies';
import { sendGtmEvent } from '../../utils/GoogleTagManagerUtils';
import { GTM } from '../../config/const';
import CutOverModal from '../../components/CutOverModal/CutOverModal';
import {
	setAreShiftsUpdateNeeded,
	setIsEmploymentTypeUpdateNeeded
} from 'redux/slices/availMainSlice';
import { saveAvailabilityPreference } from 'redux/Availability/request';
import { callHiringHelper } from 'redux/HiringHelper/request';
import { getExternalJobOfferInfo } from 'redux/VirtualJobOffer/request';

const PostApplyDashboard = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const applicantId = useSelector((state) => state.global.applicantId);
	const global = useSelector((state) => state.global);
	const account = useSelector((state) => state.account);
	const availability = useSelector((state) => state.availMain);

	const { isPortrait } = useDisplay();

	const [vjoAlert, setVjoAlert] = useState(false);
	const { storeNbr, reqsnId, nextStepInd } = account?.data?.auth?.vjo || {};

	const shiftUpdateNeeded = !account?.data?.applProfileSteps[3];
	const scheduleUpdateNeeded = !account?.data?.applProfileSteps[4];
	const employmentTypeUpdateNeeded = availability?.isEmploymentTypeUpdateNeeded;
	const shiftIdsUpdateNeeded = availability?.areShiftsUpdateNeeded;

	const autoFill =
		cookie.load('autoFillCutoverModalAlreadyShown') !== 'Y' &&
		(employmentTypeUpdateNeeded || shiftIdsUpdateNeeded);

	useEffect(() => {
		dispatch(updateLoading(true));
		dispatch(updateEditAvailability(false));
		dispatch(getDashboardInfo(applicantId));
		sendGtmEvent(GTM.EVENTS.VIEW_POST_APPLY_DASHBOARD);
		
		if (applicantId && storeNbr && reqsnId && (!nextStepInd || nextStepInd < 10)) {
			setVjoAlert(true);
			dispatch(
				getExternalJobOfferInfo({
					storeNbr: storeNbr,
					requestInput: {
						applicantId: Number(applicantId),
						requisitionId: reqsnId,
						isSupplyChain: account?.data?.auth?.supplyChain
					}
				})
			);
		}
		
	}, [dispatch, applicantId]);

	const goToShift = () => {
		dispatch(setIsEmploymentTypeUpdateNeeded(false));
		dispatch(setAreShiftsUpdateNeeded(false));

		shiftUpdateNeeded
			? dispatch(isShiftUpdateNeeded(true))
			: dispatch(isScheduleUpdateNeeded(true));

		const request = {
			applicantId: global.applicantId,
			jobType: global.jobType,
			employmentType: availability.selectedAndAutoFilledEmpTypes,
			shiftPreference: availability.selectedAndAutoFilledshiftPreference
		};
		if (autoFill) {
			dispatch(saveAvailabilityPreference(request)).then(() => {
				cookie.save('autoFillCutoverModalAlreadyShown', 'Y');
				dispatch(callHiringHelper(global.applicantId));
				if (isPortrait) {
					if (employmentTypeUpdateNeeded) {
						navigate('../availability/employmentType');
					} else if (shiftIdsUpdateNeeded) {
						navigate('../availability/shiftPreference');
					} else {
						navigate('../availability/availableHours');
					}
				} else {
					navigate('../availability');
				}
			});
		} else {
			if (isPortrait) {
				shiftUpdateNeeded
					? navigate('../availability/shiftPreference')
					: navigate('../availability/availableHours');
			} else {
				navigate('../availability');
			}
		}
	};
	return (
		<>
			<div className="banner_bg">
				<Banner />
			</div>
			<div className="pad_container">
				<ApplicationStatus />
				{vjoAlert && <VJOExpiry />}
				<Assessments />
				<Locations />
				<JobSearch />
			</div>
			<CutOverModal
				isOpen={autoFill || shiftUpdateNeeded || scheduleUpdateNeeded}
				onClose={goToShift}
				text={
					autoFill
						? 'availability.autoFillCutOverPopUpMessage'
						: shiftUpdateNeeded
						? 'availability.inCompleteShifts'
						: 'availability.shftTimeAlert'
				}
			/>
		</>
	);
};

export default PostApplyDashboard;
