import { createSlice } from "@reduxjs/toolkit";
import { checkRegisteredUser, getStepInd, createAccount, forgotPassword } from "./request";
import { decorateForgetPasswordResponse, decorateResponse } from "./helper";

const initialState = {
    data: {
        auth: {},
        applProfileSteps: {
            0: false,
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false,
            8: false,
            9: false,
            10: false,
            11: false,
            status: 'PENDING'
        },
    },
    error: {},
};

const userAccountSlice = createSlice({
    name: "userAccountSlice",
    initialState,
    reducers: {
        updateResetPassword: (state, action) => {
			state.data.resetPassword = { ...state.data.resetPassword, ...action.payload };
		},
        updateVjoNextStepInd: (state, action) => {
			state.data.auth.vjo = { ...state.data.auth.vjo, ...action.payload };
		},
        updateApplicantName: (state, action) => {
            state.data.auth.applicantName = action.payload;
        },
        updateAccountAuth: (state, action) => {
            state.data.auth = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(checkRegisteredUser.fulfilled, (state, action) => {
                state.data = decorateResponse(action.payload);
            })
            .addCase(checkRegisteredUser.rejected, (state, action) => {
                state.error = { message: "Something went wrong" };
            })
            .addCase(getStepInd.fulfilled, (state, action) => {
                state.data.auth.vjo.nextStepInd = action.payload.vjo.nextStepInd;
            })
            .addCase(getStepInd.rejected, (state, action) => {
                state.error = { message: "Something went wrong" };
            })
            .addCase(createAccount.fulfilled, (state, action) => {
                state.data.auth = action.payload;
            })
            .addCase(createAccount.rejected, (state, action) => {
                state.error = { message: "Something went wrong" };
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.data.resetPassword = decorateForgetPasswordResponse(action.payload);
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.error = { message: "Something went wrong" };
            });
    },
});

// Action creators are generated for each case reducer function
export const { updateResetPassword, updateVjoNextStepInd, updateApplicantName ,updateAccountAuth} = userAccountSlice.actions;

export default userAccountSlice.reducer;
