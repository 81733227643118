import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequestErrorOccured, updateLoading } from "../../redux/slices/globalSlice";
import axiosInstance from "../../utils/axiosInstance";
import { environment } from "../../utils/environment/environment";

const getPersonalInfo = createAsyncThunk("renew/getPersonalInfo", async (postObj, { dispatch }) => {
  try {
    const baseURL =
      environment.hcServiceBaseGetUrl +
      `/getPersonalInfo?applicantId=${postObj.applicantId}&emailId=${postObj.emailId}`;
    const response = await axiosInstance.get(baseURL);
    return response.data;
  } catch (error) {
      dispatch(apiRequestErrorOccured(error));
  }
});

const getAvailability = createAsyncThunk("renew/getAvailability", async (applicantId, { dispatch }) => {
  try {
    const baseURL =
      environment.hcServiceBasePostUrl +
      `/getAvailability?applicantId=${applicantId}`;
    const response = await axiosInstance.get(baseURL);
    return response.data;
  } catch (error) {
      dispatch(apiRequestErrorOccured(error));
  }
});

const  getRenewInfo= createAsyncThunk("renew/getRenewInfo", async (applicantId, {dispatch}) => {
  try {
    dispatch(updateLoading(true));
    const baseURL =
      environment.hcServiceBaseGetUrl +
      `/getRenewInfo?applicantId=${applicantId}`;
    const response = await axiosInstance.get(baseURL);
    dispatch(updateLoading(false));
    return response.data;
  } catch (error) {
      dispatch(apiRequestErrorOccured(error));
  } 
});

const  postRenewData= createAsyncThunk("renew/postRenewData", async (payload, { dispatch }) => {
  try {
    const baseURL =
      environment.hcServiceBasePostUrl +
      `/updateRenewApplication`;
    const response = await axiosInstance.post(baseURL, payload);
    return response.data;
  } catch (error) {
      dispatch(apiRequestErrorOccured(error));
  }
});

const  rehireCheck= createAsyncThunk("renew/rehireCheck", async (payload, { dispatch }) => {
  try {
    const baseURL =
      environment.hcServiceBasePostUrl +
      `/findAscMatch`;
    const response = await axiosInstance.post(baseURL, payload);
    return response.data;
  } catch (error) {
      dispatch(apiRequestErrorOccured(error));
  }
});

export { getPersonalInfo, getAvailability, getRenewInfo, postRenewData ,rehireCheck};