import React, { useState, useEffect } from 'react';
import EmploymentType from '../EmploymentType/EmploymentType';
import { FormGroup, Divider, Body, Button } from '@walmart-web/livingdesign-components';
import mystyle from './AvailMainComp.module.css';
import { style } from 'dom-helpers';
import { useNavigate, useParams } from 'react-router-dom';
import ScheduleAvailability from '../ScheduleAvailability/ScheduleAvailability';
import SaveForLaterPopUp from '../../Application/ProfileCreation/SaveLater/SaveForLaterPopUp';
import ShiftPreference from '../ShiftPreference/ShiftPreference';
import { isEmpty } from '../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateShiftPreference,
	updateEmploymentType,
	updateScheduleAvailability,
	updateGlobalAvailability,
	updateAutoFillDeclined,
	updateTriggerAutoFillCheckInRenew
} from '../../../redux/slices/availMainSlice';
import {
	getAvailabilityPreference,
	saveAvailabilityPreference
} from '../../../redux/Availability/request';
import { useTranslation } from 'react-i18next';
import { useDisplay } from '../../../utils/useDisplay';
import { callHiringHelper } from '../../../redux/HiringHelper/request';
import { AvailabilityHelper } from '../AvailabilityHelper';
import {
	isShiftUpdateNeeded,
	updateEditAvailability,
	isScheduleUpdateNeeded
} from '../../../redux/EditProfile/Slice';
import { updateShiftsAutoFilled } from 'redux/slices/jobSearchSlice';
import { updateAccountAuth } from '../../../redux/Account/slice';
import { shiftIds } from '../../../config/const';
import { executionErrorOccured } from 'redux/slices/globalSlice';
import cookie from 'react-cookies';
import AlertDialog from 'components/AlertDialog';
import { updateTriggerRenewAction } from 'redux/Renew/slice';
import { getAutoFillCookieInfo } from 'utils/availabilityCookieUtils';
/**
 *                       AvailMainComp.js
 *                             *
 *    **********************************************************
 *    *                        *                               *
 *    EmploymentType.js    ShiftPreference.js      ScheduleAvailability.js
 *                                                             *
 *                                                    **********************
 *                                                    *                    *
 *                                         CopyAvailableHoursPopUp.js     Day.js
 *
 * Layout => [Desktop] -  Single Screen [Three Components]
 *           [Mobile]  -  Seperate Screen [Three Components]
 *
 * Two type of Redux Store is used to reduce the page transition complexity issue.
 * OnLoad   => updateAvailability (Save)
 * onChange => updateEmploymentType,updateShiftPreference,updateScheduleAvailability (Save for Later)
 */
const AvailMainComp = ({ isRenew }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [continueButton, setContinueButton] = useState(false);
	const [showShiftPreference, setShowShiftPreference] = useState(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const [showAvlScreen, setShowAvlScreen] = useState(false);
	const { screenName } = useParams();
	const global = useSelector((state) => state.global);
	const [fullTime, setFullTime] = useState(false);
	const [partTime, setPartTime] = useState(false);
	const [temporary, setTemporary] = useState(false);
	const [cbOpening, setCbOpening] = useState(false);
	const [cbMorning, setCbMorning] = useState(false);
	const [cbMidshift, setCbMidshift] = useState(false);
	const [cbClosing, setCbClosing] = useState(false);
	const [cbOvernight, setCbOvernight] = useState(false);
	const [cbWd1st, setCbWd1st] = useState(false);
	const [cbWd2nd, setCbWd2nd] = useState(false);
	const [cbWd3rd, setCbWd3rd] = useState(false);
	const [cbWe1st, setCbWe1st] = useState(false);
	const [cbWe2nd, setCbWe2nd] = useState(false);
	const [cbWe3rd, setCbWe3rd] = useState(false);
	const [cbPr1st, setCbPr1st] = useState(false);
	const [cbPr2nd, setCbPr2nd] = useState(false);
	const [cbPr3rd, setCbPr3rd] = useState(false);
	const [flex4th, setFlex4th] = useState(false);
	const [flex7th, setFlex7th] = useState(false);
	const [fromShiftMon, setFromShiftMon] = useState('');
	const [fromShiftTue, setFromShiftTue] = useState('');
	const [fromShiftWed, setFromShiftWed] = useState('');
	const [fromShiftThu, setFromShiftThu] = useState('');
	const [fromShiftFri, setFromShiftFri] = useState('');
	const [fromShiftSat, setFromShiftSat] = useState('');
	const [fromShiftSun, setFromShiftSun] = useState('');
	const [toShiftMon, setToShiftMon] = useState('');
	const [toShiftTue, setToShiftTue] = useState('');
	const [toShiftWed, setToShiftWed] = useState('');
	const [toShiftThu, setToShiftThu] = useState('');
	const [toShiftFri, setToShiftFri] = useState('');
	const [toShiftSat, setToShiftSat] = useState('');
	const [toShiftSun, setToShiftSun] = useState('');
	const [anyHours, setAnyHours] = useState('');
	const [screen, setScreen] = useState('');
	const [deviceType, setDeviceType] = useState('');
	const [declineAutoFillsDeselection, setDeclineAutoFillsDeselection] = useState(false);
	const [showAvailabilityScreen, setShowAvailabilityScreen] = useState(false);
	const [jobType, setJobType] = useState('');
	const [jobCountryType, setJobCountryType] = useState('');
	const account = useSelector((state) => state.account);
	const [showShiftDesktop, setshowShiftDesktop] = useState(false);
	const { isPortrait, isLandscape } = useDisplay();
	const editProfile = useSelector((state) => state.editProfile);
	const [monCheckBox, setMonCheckBox] = useState(false);
	const [tueCheckBox, setTueCheckBox] = useState(false);
	const [wedCheckBox, setWedCheckBox] = useState(false);
	const [thuCheckBox, setThuCheckBox] = useState(false);
	const [friCheckBox, setFriCheckBox] = useState(false);
	const [satCheckBox, setSatCheckBox] = useState(false);
	const [sunCheckBox, setSunCheckBox] = useState(false);
	const [showAutoFillPopUp, setShowAutoFillPopUp] = useState(false);
	const [autoFillsDeselected, setAutoFillsDeselected] = useState(false);
	const [renewSchdlAvail, setRenewSchdlAvail] = useState(false);
	const jobSearch = useSelector((state) => state.jobSearch);
	const { renewInfo } = useSelector((state) => state.renew);
	const availStore = useSelector((state) => state.availMain);


	const {
		setCountryandJobType,
		updateOnChangeAvailabilityRedux,
		checkforDataUpdate,
		storeToRedux,
		getOnChangeShift,
		validateDay,
		validateSchdlTiming,
		validateTiming,
		validateSaveRequest,
		isFromEditProfile
	} = AvailabilityHelper();

	const resetFocus = () => {
		const topElement = document.getElementById("backButton");
		topElement?.focus?.();
	}

	React.useEffect(() => {
		if (!isEmpty(screenName) && screenName === 'shiftPreference') {
			setShowShiftPreference(true);
			setShowAvlScreen(false);
		} else if (!isEmpty(screenName) && screenName === 'availableHours') {
			setShowShiftPreference(true);
			setShowAvlScreen(true);
		} else if (screenName === 'employmentType') {
			setShowShiftPreference(false);
		} else if (!isEmpty(screenName)) {
			navigate('../disclaimer');
		}

		if (
			screenName === 'shiftPreference' ||
			screenName === 'availableHours' ||
			screenName === 'employmentType'
		) {
			resetFocus();
		}
		// eslint-disable-next-line
	}, [screenName]);

	useEffect(() => {
		getAvailabilityPref();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!isRenew) {
			setCountryandJobType(setJobCountryType, setJobType, isRenew);
		}
		// eslint-disable-next-line
	}, [jobSearch.selectedJobs]);

	useEffect(() => {
		if (isRenew) {
			setCountryandJobType(setJobCountryType, setJobType, isRenew);
		}
		// eslint-disable-next-line
	}, [renewInfo]);

	useEffect(()=>{
		if(availStore.triggerAutoFillCheckInRenew){
			if(autoFillsDeselected){
				setShowAutoFillPopUp(true);
				setDeclineAutoFillsDeselection(false);
			}
			else{
				dispatch(updateTriggerRenewAction(true));
			}
		}
	},[availStore.triggerAutoFillCheckInRenew])

	const getAvailabilityPref = async () => {
		const applicantId = global.applicantId;
		if (applicantId) {
			dispatch(getAvailabilityPreference(applicantId))
				.then((res) => {
					const data = res.payload.response;
					if (data && res.payload.responseCode === 200) {
						dispatch(updateGlobalAvailability(data));
					}
				})
				.catch((err) => {
					dispatch(executionErrorOccured());
				});
		}
	};

	const saveAvailPreference = async (screenType) => {
		const applicantId = global.applicantId;
		const saveAvailRequest = validateSaveRequest(getSaveAvailRequest(screenType, applicantId));
		updateOnChangeAvailabilityRedux(saveAvailRequest, screenType);
		const isDataUpdated = checkforDataUpdate(screenType, saveAvailRequest);
		if (!isDataUpdated) {
			if (applicantId) {
				dispatch(saveAvailabilityPreference(saveAvailRequest))
					.then((res) => {
						cookie.save('autoFillCutoverModalAlreadyShown', 'Y');
						const data = res.payload;
						if (data && data.responseCode === 201) {
							storeToRedux(screenType, saveAvailRequest);
							if (account?.data?.auth?.stepNbr >= 850) {
								dispatch(callHiringHelper(applicantId));
							}
							viewAvailability(screenType, applicantId);
						}
					})
					.catch((err) => {
						dispatch(executionErrorOccured());
					});
			} else {
				storeToRedux(screenType, saveAvailRequest);
				viewAvailability(screenType, applicantId);
			}
		} else {
			viewAvailability(screenType, applicantId);
		}
	};

	const viewAvailability = (screenType, applicantId) => {
		if (screenType === 'Employment') {
			if (continueButton) {
				setContinueButton(false);
				setShowShiftPreference(true);
				navigate('../availability/shiftPreference');
			}
		} else if (screenType === 'ShiftPreference') {
			if (jobType === 'store' || jobType === 'both') {
				setContinueButton(false);
				setShowAvlScreen(true);
				navigate('../availability/availableHours');
			} else {
				navigateAfterAvail(applicantId);
			}
		} else if (screenType === 'AvailableHours') {
			navigateAfterAvail(applicantId);
		} else if (screenType === 'All') {
			navigateAfterAvail(applicantId);
		}
	};
	const navigateAfterAvail = (applicantId) => {
		if (applicantId) {
			if (editProfile.editAvailability) {
				dispatch(updateEditAvailability(false));
				navigate('../edit-profile');
			} else if (editProfile.shiftUpdateNeeded || editProfile.scheduleUpdateNeeded) {
				editProfile.shiftUpdateNeeded
					? dispatch(isShiftUpdateNeeded(false))
					: dispatch(isScheduleUpdateNeeded(false));
				dispatch(updateAccountAuth({}));
				navigate('../login');
			} else {
				if (account?.data?.auth?.stepNbr >= 250) {
					navigate('../minimum-qualifications');
				} else {
					navigate('../login');
				}
			}
		} else {
			navigate('../create-account');
		}
	};

	const getSaveAvailRequest = (screenType, applicantId) => {
		const requestInput = getRequestTemplate(applicantId);
		switch (screenType) {
			case 'Employment':
				requestInput.shiftPreference = null;
				requestInput.scheduleAvailability = null;
				break;
			case 'ShiftPreference':
				requestInput.employmentType = null;
				requestInput.scheduleAvailability = null;
				break;
			case 'AvailableHours':
				requestInput.employmentType = null;
				requestInput.shiftPreference = null;
				break;
			case 'All':
				break;
			default:
		}
		return requestInput;
	};

	const getRequestTemplate = (applicantId) => {
		const shift = returnShifts();
		return {
			applicantId: applicantId,
			jobType: global.jobType,
			employmentType: {
				fullTime: fullTime,
				partTime: partTime,
				temporary: temporary
			},
			scheduleAvailability: {
				sunStartTime: fromShiftSun,
				sunEndTime: toShiftSun,
				monStartTime: fromShiftMon,
				monEndTime: toShiftMon,
				tueStartTime: fromShiftTue,
				tueEndTime: toShiftTue,
				wedStartTime: fromShiftWed,
				wedEndTime: toShiftWed,
				thuStartTime: fromShiftThu,
				thuEndTime: toShiftThu,
				friStartTime: fromShiftFri,
				friEndTime: toShiftFri,
				satStartTime: fromShiftSat,
				satEndTime: toShiftSat,
				anyHours: anyHours
			},
			shiftPreference: {
				shift: shift
			}
		};
	};

	const returnShifts = () => {
		const shift = [];
		if (cbWd1st) {
			shift.push(shiftIds.SC_US_WEEKDAY_1_ST_SHIFT_ID);
		}
		if (cbWd2nd) {
			shift.push(shiftIds.SC_US_WEEKDAY_2_ND_SHIFT_ID);
		}
		if (cbWd3rd) {
			shift.push(shiftIds.SC_US_WEEKDAY_3_RD_SHIFT_ID);
		}
		if (cbWe1st) {
			shift.push(shiftIds.SC_US_WEEKEND_4_TH_SHIFT_ID);
		}
		if (cbWe2nd) {
			shift.push(shiftIds.SC_US_WEEKEND_5_TH_SHIFT_ID);
		}
		if (cbWe3rd) {
			shift.push(shiftIds.SC_US_WEEKEND_6_TH_SHIFT_ID);
		}
		if (cbPr1st) {
			shift.push(shiftIds.SC_PR_1_ST_SHIFT_ID);
		}
		if (cbPr2nd) {
			shift.push(shiftIds.SC_PR_2_ND_SHIFT_ID);
		}
		if (cbPr3rd) {
			shift.push(shiftIds.SC_PR_3_RD_SHIFT_ID);
		}
		if (cbOpening) {
			shift.push(shiftIds.OPENING_SHIFT_ID);
		}
		if (cbMorning) {
			shift.push(shiftIds.MORNING_SHIFT_ID);
		}
		if (cbMidshift) {
			shift.push(shiftIds.MID_SHIFT_SHIFT_ID);
		}
		if (cbClosing) {
			shift.push(shiftIds.CLOSING_SHIFT_ID);
		}
		if (cbOvernight) {
			shift.push(shiftIds.OVERNIGHT_SHIFT_ID);
		}
		if (flex7th) {
			shift.push(shiftIds.FLEX_SHIFT_ID);
		}
		if (flex4th) {
			shift.push(shiftIds.FLEX_SHIFT_ID_PR);
		}
		return shift;
	};

	const checkIfNeedsToShowPopUp = ()=>{
		if(autoFillsDeselected){
			const autoFillEmpDeclined = getAutoFillCookieInfo('employmentTypeAutoFillDeclined');
			const autoFillShiftDeclined = getAutoFillCookieInfo('shiftTypeAutoFillDeclined');
			if(isPortrait){
				if((screen === 'Employment' && !autoFillEmpDeclined)
					|| (screen === 'ShiftPreference' && !autoFillShiftDeclined)){
						return true;
					}
			}
			else if(!autoFillEmpDeclined && !autoFillShiftDeclined){
				return true;
			}
			else{
				return false;
			}
		}
		else{
			return false;
		}
	}

	const onClickSaveAvailPreference = (device) => {
		setDeviceType(device);
		if(checkIfNeedsToShowPopUp()){
			setShowAutoFillPopUp(true);
			setDeclineAutoFillsDeselection(false);
		}
		else{
			handleSaveAvailPrefs(device);
		}
	};

	const handleSaveAvailPrefs = (device) =>{
		if (device === 'Mobile') {
			if (continueButton && screen === 'Employment') {
				saveAvailPreference('Employment');
			} else if (screen === 'ShiftPreference') {
				saveAvailPreference('ShiftPreference');
				dispatch(updateShiftsAutoFilled(false));
			} else if (screen === 'AvailableHours') {
				saveAvailPreference('AvailableHours');
			}
		} else if (device === 'Desktop') {
			saveAvailPreference('All');
		}
	}

	const empTypeFunc = (empObj) => {
		setFullTime(empObj.fullTime);
		setPartTime(empObj.partTime);
		setTemporary(empObj.temporary);
		setScreen(empObj.screen);
		setshowShiftDesktop(empObj.continuebtn);
		if (isPortrait) {
			setContinueButton(empObj.fullTime || empObj.partTime || empObj.temporary);
		} else {
			if (!empObj.fullTime && !empObj.partTime && !empObj.temporary) {
				setContinueButton(false);
				setShowAvailabilityScreen(false);
				setAnyHours('');
			}
		}
		dispatch(
			updateEmploymentType({
				fullTime: empObj.fullTime,
				partTime: empObj.partTime,
				temporary: empObj.temporary
			})
		);
	};

	const saveLater = () => {
		setIsOpen(true);
	};

	const shiftPreference = (shiftPrefObj) => {
		if (!isRenew) {
			continueShiftValidation(shiftPrefObj);
		} else {
			renewShiftValidation(shiftPrefObj);
		}
		setCbOpening(shiftPrefObj.opening);
		setCbMorning(shiftPrefObj.morning);
		setCbMidshift(shiftPrefObj.midshift);
		setCbClosing(shiftPrefObj.closing);
		setCbOvernight(shiftPrefObj.overnight);
		setCbWd1st(shiftPrefObj.wd1st);
		setCbWd2nd(shiftPrefObj.wd2nd);
		setCbWd3rd(shiftPrefObj.wd3rd);
		setCbWe1st(shiftPrefObj.we1st);
		setCbWe2nd(shiftPrefObj.we2nd);
		setCbWe3rd(shiftPrefObj.we3rd);
		setScreen(shiftPrefObj.scr);
		setCbPr1st(shiftPrefObj.pr1st);
		setCbPr2nd(shiftPrefObj.pr2nd);
		setCbPr3rd(shiftPrefObj.pr3rd);
		setFlex7th(shiftPrefObj.flex);
		setFlex4th(shiftPrefObj.flexPr);
		dispatch(updateShiftPreference(getOnChangeShift(shiftPrefObj)));
	};

	const renewShiftValidation = (shiftPrefObj) => {
		const condChk1 =
			shiftPrefObj.opening ||
			shiftPrefObj.morning ||
			shiftPrefObj.midshift ||
			shiftPrefObj.closing ||
			shiftPrefObj.overnight;
		if (condChk1) {
			setRenewSchdlAvail(true);
		} else {
			setRenewSchdlAvail(false);
		}
	};

	const continueShiftValidation = (shiftPrefObj) => {
		const condChk1 =
			shiftPrefObj.opening ||
			shiftPrefObj.morning ||
			shiftPrefObj.midshift ||
			shiftPrefObj.closing ||
			shiftPrefObj.overnight;
		const condChk2 =
			shiftPrefObj.wd1st ||
			shiftPrefObj.wd2nd ||
			shiftPrefObj.wd3rd ||
			shiftPrefObj.we1st ||
			shiftPrefObj.we2nd ||
			shiftPrefObj.we3rd ||
			shiftPrefObj.flex;
		const condChk3 = shiftPrefObj.pr1st || shiftPrefObj.pr2nd || shiftPrefObj.pr3rd || shiftPrefObj.flexPr;
		if (isPortrait) {
			if (jobType === 'both') {
				if (jobCountryType === 'PR') {
					doMbleContinueShftValidation(condChk1 && condChk3);
				} else if (jobCountryType === 'both') {
					if (global.preferredJobWarehouse === 'both') {
						doMbleContinueShftValidation(condChk1 && condChk2 && condChk3);
					} else if (global.preferredJobWarehouse === 'US') {
						doMbleContinueShftValidation(condChk1 && condChk2);
					} else if (global.preferredJobWarehouse === 'PR') {
						doMbleContinueShftValidation(condChk1 && condChk3);
					}
				} else if (jobCountryType === 'US') {
					doMbleContinueShftValidation(condChk1 && condChk2);
				}
			} else if (jobType === 'store' && condChk1) {
				setContinueButton(true);
			} else if (jobType === 'warehouse') {
				if (jobCountryType === 'both') {
					doMbleContinueShftValidation(condChk2 && condChk3);
				} else if (jobCountryType === 'PR') {
					doMbleContinueShftValidation(condChk3);
				} else if (jobCountryType === 'US') {
					doMbleContinueShftValidation(condChk2);
				}
			} else {
				setContinueButton(false);
			}
		} else {
			if (jobType === 'both') {
				if (jobCountryType === 'PR') {
					onChangeShiftValidation(condChk1, condChk3);
				} else if (jobCountryType === 'both') {
					if (global.preferredJobWarehouse === 'both') {
						onChangeShiftValidation(condChk1, condChk2 && condChk3);
					} else if (global.preferredJobWarehouse === 'US') {
						onChangeShiftValidation(condChk1, condChk2);
					} else if (global.preferredJobWarehouse === 'PR') {
						onChangeShiftValidation(condChk1, condChk3);
					}
				} else if (jobCountryType === 'US') {
					onChangeShiftValidation(condChk1, condChk2);
				}
			} else if (jobType === 'store') {
				onChangeShiftValidation(condChk1, condChk2);
			} else if (jobType === 'warehouse') {
				if (jobCountryType === 'PR') {
					doContinueShftValidation(condChk3);
				} else if (jobCountryType === 'both') {
					doContinueShftValidation(condChk2 && condChk3);
				} else if (jobCountryType === 'US') {
					doContinueShftValidation(condChk2);
				}
			} else {
				setContinueButton(false);
				setShowAvailabilityScreen(false);
			}
		}
	};

	const doMbleContinueShftValidation = (condition) => {
		if (condition) {
			setContinueButton(true);
		} else {
			setContinueButton(false);
		}
	};
	const doContinueShftValidation = (condition) => {
		if (condition) {
			setContinueButton(true);
			setShowAvailabilityScreen(false);
		} else {
			setContinueButton(false);
			setShowAvailabilityScreen(false);
		}
	};

	const onChangeShiftValidation = (condChk1, condChk2) => {
		const checkCondition = jobType === 'both' ? condChk1 && condChk2 : condChk1;
		if (checkCondition) {
			setShowAvailabilityScreen(true);
			if (anyHours === true && (anyHours !== '' || anyHours !== undefined)) {
				setContinueButton(true);
			} else if (anyHours === false && (anyHours !== '' || anyHours !== undefined)) {
				if (monCheckBox) {
					valContinueOnDayTiming(fromShiftMon, toShiftMon);
				}
				if (tueCheckBox) {
					valContinueOnDayTiming(fromShiftTue, toShiftTue);
				}
				if (wedCheckBox) {
					valContinueOnDayTiming(fromShiftWed, toShiftWed);
				}
				if (thuCheckBox) {
					valContinueOnDayTiming(fromShiftThu, toShiftThu);
				}
				if (friCheckBox) {
					valContinueOnDayTiming(fromShiftFri, toShiftFri);
				}
				if (satCheckBox) {
					valContinueOnDayTiming(fromShiftSat, toShiftSat);
				}
				if (sunCheckBox) {
					valContinueOnDayTiming(fromShiftSun, toShiftSun);
				}
				if (validateDayShft()) {
					setContinueButton(false);
				}
			} else {
				setContinueButton(false);
			}
		} else {
			setContinueButton(false);
			setShowAvailabilityScreen(false);
			setAnyHours('');
		}
	};

	const scheduleTimings = (schdlObj) => {
		setAnyHours(schdlObj.anyHours);
		setFromShiftSun(schdlObj.sunStartTime);
		setToShiftSun(schdlObj.sunEndTime);
		setFromShiftMon(schdlObj.monStartTime);
		setToShiftMon(schdlObj.monEndTime);
		setFromShiftTue(schdlObj.tueStartTime);
		setToShiftTue(schdlObj.tueEndTime);
		setFromShiftWed(schdlObj.wedStartTime);
		setToShiftWed(schdlObj.wedEndTime);
		setFromShiftThu(schdlObj.thuStartTime);
		setToShiftThu(schdlObj.thuEndTime);
		setFromShiftFri(schdlObj.friStartTime);
		setToShiftFri(schdlObj.friEndTime);
		setFromShiftSat(schdlObj.satStartTime);
		setToShiftSat(schdlObj.satEndTime);
		setScreen(schdlObj.scr);
		setMonCheckBox(schdlObj.monCheckBox);
		setTueCheckBox(schdlObj.tueCheckBox);
		setWedCheckBox(schdlObj.wedCheckBox);
		setThuCheckBox(schdlObj.thuCheckBox);
		setFriCheckBox(schdlObj.friCheckBox);
		setSatCheckBox(schdlObj.satCheckBox);
		setSunCheckBox(schdlObj.sunCheckBox);
		if (!isRenew) {
			onChangeSchdlValidation(schdlObj);
		}
		dispatch(
			updateScheduleAvailability({
				anyHours: schdlObj.anyHours,
				sunStartTime: schdlObj.sunStartTime,
				sunEndTime: schdlObj.sunEndTime,
				monStartTime: schdlObj.monStartTime,
				monEndTime: schdlObj.monEndTime,
				tueStartTime: schdlObj.tueStartTime,
				tueEndTime: schdlObj.tueEndTime,
				wedStartTime: schdlObj.wedStartTime,
				wedEndTime: schdlObj.wedEndTime,
				thuStartTime: schdlObj.thuStartTime,
				thuEndTime: schdlObj.thuEndTime,
				friStartTime: schdlObj.friStartTime,
				friEndTime: schdlObj.friEndTime,
				satStartTime: schdlObj.satStartTime,
				satEndTime: schdlObj.satEndTime
			})
		);
	};

	const onChangeSchdlValidation = (schdlObj) => {
		if (schdlObj.anyHours) {
			setContinueButton(true);
		} else if (!schdlObj.anyHours) {
			if (schdlObj.monCheckBox) {
				valContinueOnDayTiming(schdlObj.monStartTime, schdlObj.monEndTime);
			}
			if (schdlObj.tueCheckBox) {
				valContinueOnDayTiming(schdlObj.tueStartTime, schdlObj.tueEndTime);
			}
			if (schdlObj.wedCheckBox) {
				valContinueOnDayTiming(schdlObj.wedStartTime, schdlObj.wedEndTime);
			}
			if (schdlObj.thuCheckBox) {
				valContinueOnDayTiming(schdlObj.thuStartTime, schdlObj.thuEndTime);
			}
			if (schdlObj.friCheckBox) {
				valContinueOnDayTiming(schdlObj.friStartTime, schdlObj.friEndTime);
			}
			if (schdlObj.satCheckBox) {
				valContinueOnDayTiming(schdlObj.satStartTime, schdlObj.satEndTime);
			}
			if (schdlObj.sunCheckBox) {
				valContinueOnDayTiming(schdlObj.sunStartTime, schdlObj.sunEndTime);
			}

			if (validateSchdlTiming(schdlObj)) {
				setContinueButton(false);
			}
			if (validateDay(schdlObj)) {
				setContinueButton(false);
			}
		} else if (schdlObj.anyHours === '') {
			setContinueButton(false);
		}
	};

	const valContinueOnDayTiming = (startTime, endTime) => {
		if (validateTiming(startTime, endTime)) {
			setContinueButton(true);
		} else {
			setContinueButton(false);
		}
	};

	const validateDayShft = () => {
		return (
			!monCheckBox &&
			!tueCheckBox &&
			!wedCheckBox &&
			!thuCheckBox &&
			!friCheckBox &&
			!satCheckBox &&
			!sunCheckBox
		);
	};

	const updateAutoFillDeclinedCookies = (scrn) =>{
		if(scrn === 'Employment'){
			cookie.save('employmentTypeAutoFillDeclined', true);
		}
		else if(scrn === 'ShiftPreference'){
			cookie.save('shiftTypeAutoFillDeclined', true);
		}
		else if(scrn === 'All'){
			cookie.save('shiftTypeAutoFillDeclined', true);
			cookie.save('employmentTypeAutoFillDeclined', true);
		}
	}

	const turnOffAutoFill = () =>{
		updateAutoFillDeclinedCookies('All');
		dispatch(updateShiftsAutoFilled(false));
	}

	const handleAutoFillsDeselectionConfirmation = (answer) =>{
		if(isRenew && answer){
			dispatch(updateTriggerRenewAction(true));
			turnOffAutoFill();
		}
		else if(answer){
			handleSaveAvailPrefs(deviceType);
			if(isPortrait){
				updateAutoFillDeclinedCookies(screen);
				if(screen ==='ShiftPreference'){
					dispatch(updateShiftsAutoFilled(false));
				}
			}
			else{
				turnOffAutoFill();
			}
		}
		else{
			setAutoFillsDeselected(false);
			dispatch(updateTriggerAutoFillCheckInRenew(false));
			setDeclineAutoFillsDeselection(true);
		}
		setShowAutoFillPopUp(false);
	}

	const autoFillAlertPopUp = () =>{ 
		return (
		<AlertDialog
				isOpen={showAutoFillPopUp}
				onClose={() => handleAutoFillsDeselectionConfirmation(false)}
				title={t('location.notice')}
				actions={
					<div>
						<Button
							onClick={() => handleAutoFillsDeselectionConfirmation(false)}
							size="medium"
							variant="tertiary"
							UNSAFE_style={{ padding: '0 32px' }}>
							{t('general.no')}
						</Button>
						<Button
							onClick={() => handleAutoFillsDeselectionConfirmation(true)}
							size="medium"
							variant="primary"
						>
							{t('general.yes')}
						</Button>
					</div>
				}>
				<Body as="div" size="medium" weight={400}>
					{t('availability.autoFillPopUpMessage')}
				</Body>				
			</AlertDialog>
	)}

	if (isRenew) {
		return (
			<div>
				<EmploymentType empTypeFunc={empTypeFunc} isRenew={true} setAutoFillsDeselected={setAutoFillsDeselected} declineAutoFillsDeselection={declineAutoFillsDeselection} />
				<Divider/>
				<ShiftPreference shiftPreference={shiftPreference} isRenew={true} setAutoFillsDeselected={setAutoFillsDeselected} declineAutoFillsDeselection={declineAutoFillsDeselection} />
				{global.jobType !== 'warehouse' && renewSchdlAvail && (
					<ScheduleAvailability scheduleTimings={scheduleTimings} isRenew={true} />
				)}
				{autoFillAlertPopUp()}
			</div>
		);
	}

	const availabityRenderMobile = () => {
		if (showShiftPreference) {
			if (showAvlScreen) {
				return <ScheduleAvailability scheduleTimings={scheduleTimings} />;
			} else {
				return <ShiftPreference shiftPreference={shiftPreference} setAutoFillsDeselected={setAutoFillsDeselected} declineAutoFillsDeselection={declineAutoFillsDeselection} />;
			}
		} else {
			return <EmploymentType empTypeFunc={empTypeFunc} setAutoFillsDeselected={setAutoFillsDeselected} declineAutoFillsDeselection={declineAutoFillsDeselection} />;
		}
	};
	const availabityFooterRender = () => {
		if (isFromEditProfile(editProfile)) {
			return (
			<div className={mystyle.editFooter}>
				<FormGroup>
					<Button
						UNSAFE_style={{ width: '100%' }}
						size="medium"
						variant="primary"
						disabled={!continueButton}
						onClick={() => onClickSaveAvailPreference('Mobile')}>
						{t('editProfile.save')}
					</Button>
				</FormGroup>
			</div>
			);
		} else {
			return(
			<div className={mystyle.bottomFooter}>
				<div className={mystyle.link}>
					{global.applicantId === 0 && (
						<Button variant="tertiary" size="medium" onClick={saveLater}>
							{t('availability.saveLater')}
						</Button>
					)}
				</div>
				<div className={mystyle.footer}>
					<FormGroup>
						<Button
							size="medium"
							variant="primary"
							disabled={!continueButton}
							onClick={() => onClickSaveAvailPreference('Mobile')}>
							{t('general.continue')}
						</Button>
					</FormGroup>
				</div>
			</div>
			);
		}
	};

	return (
		<React.Fragment>
			{isLandscape ? (
				<>
					<div className={mystyle.availComp}>
						<div className={mystyle.blueHead}></div>
						<div className={mystyle.card}>
							<div style={{padding: '12px'}}>
								<EmploymentType empTypeFunc={empTypeFunc} setAutoFillsDeselected={setAutoFillsDeselected} declineAutoFillsDeselection={declineAutoFillsDeselection} />
							</div>
							{showShiftDesktop && (
								<div>
									<div style={{padding: '12px'}}>
										<ShiftPreference shiftPreference={shiftPreference} setAutoFillsDeselected={setAutoFillsDeselected} declineAutoFillsDeselection={declineAutoFillsDeselection} />
									</div>
									{showAvailabilityScreen && (
										<div style={{padding: '12px'}}>
											<ScheduleAvailability scheduleTimings={scheduleTimings} />
										</div>
									)}
								</div>
							)}
						</div>

						<div className={style.divider}>
							<Divider style={{ paddingTop: 10 }} />
						</div>

						<div className={mystyle.bottomFooter}>
							<div className={mystyle.link}>
								{global.applicantId === 0 && (
									<Button
										size="large"
										UNSAFE_style={{padding: 0}}
										variant="tertiary"
										onClick={saveLater}>
										{t('availability.saveLater')}
									</Button>
								)}
							</div>
							<div className={mystyle.footer}>
								<FormGroup>
									<Button
										size="large"
										variant="primary"
										disabled={!continueButton}
										onClick={() => onClickSaveAvailPreference('Desktop')}>
										{isFromEditProfile(editProfile) ? t('editProfile.save') : t('general.continue')}
									</Button>
								</FormGroup>
							</div>
						</div>
						<SaveForLaterPopUp isOpen={isOpen} setIsOpen={setIsOpen}></SaveForLaterPopUp>
					</div>
				</>
			) : (
				<>
					<div className={mystyle.availComp}>
						<div className={mystyle.blueHead}></div>
						<div className={mystyle.card}>{availabityRenderMobile()}</div>

						<div className={style.divider}>
							<Divider />
						</div>

						<div className={mystyle.bottomFooter}>
						{availabityFooterRender()}
						</div>

						<SaveForLaterPopUp isOpen={isOpen} setIsOpen={setIsOpen}></SaveForLaterPopUp>
					</div>
				</>
			)}
			{autoFillAlertPopUp()}
		</React.Fragment>
	);
};
export default AvailMainComp;
