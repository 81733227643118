import React from 'react';
import styles from './JobList.module.css';
import { Body, Button, Caption, Tag } from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { useSelector } from 'react-redux';
import { ReactComponent as SearchFutureIcon } from '../../../assets/svg/searchFuture.svg';

const Icon = require('@livingdesign/icons');

const JobList = (props) => {
	const global = useSelector((state) => state.global);
	const { t } = useTranslation();
    
	const getFacilityType = (i) => {
		return i.workgroupId < 5000 ? 'store' : 'warehouse';
	};
	const getTagColor = (i) => {
		const fType = getFacilityType(i);
		switch (fType) {
			case 'store':
				return 'blue';
			case 'warehouse':
				return 'purple';
			default:
				return 'blue';
		}
	};

	const getIcon = (i) => {
		const fType = getFacilityType(i);
		switch (fType) {
			case 'store':
				return <Icon.Store />;
			case 'warehouse':
				return <Icon.Truck />;
			default:
				return <Icon.Store />;
		}
	};
	const getEmploymentTypeText = (empType, i) => {
		switch (empType) {
			case 'F':
				return t('availability.fullTime');
			case 'P':
				return t('availability.partTime');
			case 'T':
				return t('availability.justTemporary');
			default:
				return 'All';
		}
	};
	const mapShift = (shift, i) => {
		const fType = getFacilityType(i);
		switch (shift) {
			case 1:
				return fType === 'store' ? t('shift.wdMorning') : t('shift.wd1');
			case 2:
				return fType === 'store' ? t('shift.wdAfternoon') : t('shift.wd2');
			case 3:
				return fType === 'store' ? t('shift.wdOvernight') : t('shift.wd3');
			case 4:
				return fType === 'store' ? t('shift.weMorning') : t('shift.we1');
			case 5:
				return fType === 'store' ? t('shift.weAfternoon') : t('shift.we2');
			case 6:
				return fType === 'store' ? t('shift.weOvernight') : t('shift.we3');
			case 7:
				return t('shift.shift1');
			case 8:
				return t('shift.shift2');
			case 9:
				return t('shift.shift3');
			case 10:
				return t('shift.opening');
			case 11:
				return t('shift.morning');
			case 12:
				return t('shift.midShift');
			case 13:
				return t('shift.closing');
			case 14:
				return t('shift.overnight');
			case 17:
				return t('shift.flex');
			case 18:
				return t('shift.flex');
			default:
				return 'All';
		}
	};

	const getJobCurrency = (future) => {
		const futureJobs = [];
		const currentJobCards = [];
		props.data.forEach((index, i) => {
			if (index.openPositions > 0) {
				currentJobCards.push(jobCard(index, i));
			} else {
				futureJobs.push(index);
			}
		});
		futureJobs
			.sort((a, b) => a.skillLevelId - b.skillLevelId)
			.sort((a) => {
				if (a.workgroupId === 5001 || a.skillLevelId === 1) {
					//We want Freight Handler + Entry Level to be displayed first in Future Jobs
					return -1;
				}
				return 0;
			});
		const futureJobCards = futureJobs.map((index, i) => {
			return jobCard(index, i);
		});

		return !future ? futureJobCards : currentJobCards;
	};

	const employmentTypeTags = (item) => {
		return (
			<>
				{item.employmentTypes && item.employmentTypes.length > 0 ? (
                <>
                    <div className={styles.joblistCardCaptionText}> {t('jobSelection.employmentType')} </div>
					<div>
						{item.employmentTypes.map((empType, i) => {
							return (
								<Tag
									key={i}
									color={getTagColor(item)}
									variant="tertiary"
									className={styles.joblistCardEmpChip}>
									{getEmploymentTypeText(empType, item)}
								</Tag>
							);
						})}
					</div>
                </>
				) : (
					(!item.shiftIds || item.shiftIds.length === 0) && (
						<div className={styles.joblistCardCaptionText}>
							{t('jobSelection.empTypeShiftsMayVary')}
						</div>
					)
				)}
			</>
		);
	};

	const shiftTags = (item) => {
		return (
			<>
				{item.shiftIds && item.shiftIds.length > 0 ? (
					<>
						<div className={styles.joblistCardCaptionText}>{t('jobSelection.availableShifts')}</div>
						<div>
							{item.shiftIds.map((shift, i) => {
								return (
									<Tag
										key={i}
										color={getTagColor(item)}
										variant="tertiary"
										className={styles.joblistCardShiftChip}>
										{mapShift(shift, item)}
									</Tag>
								);
							})}
						</div>
					</>
				) : (
					item.employmentTypes &&
					item.employmentTypes.length > 0 && (
						<div className={styles.joblistCardCaptionText}>{t('jobSelection.shiftsMayVary')}</div>
					)
				)}
			</>
		);
	};

	const jobInfoTags = (item) => {
		return (
			<>
				{employmentTypeTags(item)}
				{shiftTags(item)}
			</>
		);
	};

	const jobCard = (item, i) => {
		return (
			<div key={i}>
				{/*Mobile card*/}
				<MediaQuery maxWidth={851}>
					<div key={i} className={styles.joblistCardContainer}>
						<div>
							<img
								src={require('../../../assets/images/workgroups/' + item.workgroupId + '.svg')}
								alt="Job icon"
								aria-hidden = {true}
								className={styles.joblistIconHolder}
							/>
						</div>
						<div className={styles.joblistCardInfoHolder} onClick={() => props.details(item, true)}>
							<div className={styles.joblistCardTitleContainer}>
								<div className={styles.joblistCardTitle} aria-label={item.jobSelectionDesc[global.language === 'ES' ? 102 : 101]}>
									{item.jobSelectionDesc[global.language === 'ES' ? 102 : 101] +
										(props.showCountryCode ? ' ('.concat(item.countryCode).concat(')') : '')}
								</div>
								<div>
									<Tag color={getTagColor(item)} variant="secondary" leading={getIcon(item)}>
										{t('general.' + getFacilityType(item))}
									</Tag>
								</div>
							</div>
							{item.openPositions !== 0 && (
								<div className={styles.joblistCardCaptionText}>
									{item.openPositions} {t('jobSelection.positionsOpen')}
								</div>
							)}
							{item.payRange && (
								<div className={styles.joblistCardPayInfo}>
									<div className={styles.joblistCardCaptionText}>{t('jobSelection.pay')} ({t('jobSelection.variesBy')})</div>
									<Caption className={styles.joblistCardInfoText}>
										{`$${Number(item.payRange.min).toFixed(2)} - $${Number(item.payRange.max).toFixed(2)}`}
										{t('general./hr')}
									</Caption> 
								</div>
							)}
							{jobInfoTags(item)}

							<div className={styles.joblistCardSelectContainer}>
								<div >
									<Button
										variant="tertiary"
										aria-label={`${t('general.view')} ${item.jobSelectionDesc[global.language === 'ES' ? 102 : 101]} ${t('general.details')}`}
										UNSAFE_className={styles.joblistCardLink}
										onClick={() => props.details(item, true)}>
										{t('jobSelection.viewJobDetails')}
									</Button>
								</div>
								<div>
									{props.checkIfPreferred(item) ? (
										<Button disabled>{t('jobFilter.selected')}</Button>
									) : (
										<Button
											size="small"
											aria-label={`${props.selected.some((i) => i.jobSelectionId === item.jobSelectionId) ? 'remove' : 'select'} ${item.jobSelectionDesc[global.language === 'ES' ? 102 : 101] +
											(props.showCountryCode ? ' ('.concat(item.countryCode).concat(')') : '')}`}
											variant={
												props.selected.some((i) => i.jobSelectionId === item.jobSelectionId)
													? 'secondary'
													: 'primary'
											}
											onClick={(e) => {
												e.cancelBubble = true;
												if (e.stopPropagation) {
													e.stopPropagation();
												}
												props.select(item);
											}}>
											{props.selected.some((i) => i.jobSelectionId === item.jobSelectionId)
												? t('general.remove')
												: t('general.select')}
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				</MediaQuery>
				<MediaQuery minWidth={851}>
					<div className={styles.joblistCardContainer}>
						<div className={styles.joblistCardTitleInfoHolder}>
							<div className={styles.jobListCardTag}>
								<Tag color={getTagColor(item)} variant="secondary" leading={getIcon(item)}>
									{t('general.' + getFacilityType(item))}
								</Tag>
							</div>
							<div className={styles.imageHolderDesktop}>
								<img
									src={require('../../../assets/images/workgroups/' + item.workgroupId + '.svg')}
									alt="Job icon"
									aria-hidden = {true}
									className={styles.joblistIconHolder}
								/>
							</div>
							<div>
								{props.checkIfPreferred(item) ? (
									<Button disabled>{t('jobFilter.selected')}</Button>
								) : (
									<Button
										aria-label={`${props.selected.some((i) => i.jobSelectionId === item.jobSelectionId) ? 'remove' : 'select'} ${item.jobSelectionDesc[global.language === 'ES' ? 102 : 101] +
											(props.showCountryCode ? ' ('.concat(item.countryCode).concat(')') : '')}`}
										size="small"
										variant={
											props.selected.some((i) => i.jobSelectionId === item.jobSelectionId)
												? 'secondary'
												: 'primary'
										}
										onClick={() => props.select(item)}>
										{props.selected.some((i) => i.jobSelectionId === item.jobSelectionId)
											? t('general.remove')
											: t('general.select')}
									</Button>
								)}
							</div>
						</div>
						<div
							className={styles.joblistCardInfoHolder}
							style={{ cursor: 'pointer' }}
							onClick={() => props.details(item, false)}>
							<div className={styles.joblistCardTitleContainer}>
								<div className={styles.joblistCardTitle}>
									{item.jobSelectionDesc[global.language === 'ES' ? 102 : 101] +
										(props.showCountryCode ? ' ('.concat(item.countryCode).concat(')') : '')}
								</div>
							</div>
							{item.openPositions !== 0 && (
								<div className={styles.joblistCardCaptionText}>
									{item.openPositions} {t('jobSelection.positionsOpen')}
								</div>
							)}
							{item.payRange && (
								<div className={styles.joblistCardPayInfo}>
									<div className={styles.joblistCardCaptionText}>{t('jobSelection.pay')} ({t('jobSelection.variesBy')})</div>
									{item.payRangesByFacility &&
                                        <Body as="div" size="medium">
                                            {`$${Number(item.payRange.min).toFixed(2)} - $${Number(item.payRange.max).toFixed(2)}`}
                                            {t('general./hr')}
                                        </Body>
                                    }
								</div>
							)}
							{jobInfoTags(item)}

							<div className={styles.joblistCardSelectContainer}>
								<div>
									<Button
										variant="tertiary"
										aria-label={`${t('general.view')} ${item.jobSelectionDesc[global.language === 'ES' ? 102 : 101]} ${t('general.details')}`}
										UNSAFE_className={styles.joblistCardLink}
										onClick={() => props.details(item, false)}>
										{t('jobSelection.viewJobDetails')}
									</Button>
								</div>
							</div>
						</div>
						<br />
					</div>
				</MediaQuery>
			</div>
		);
	};
 
	return (
		<div
			className={!props.futureTab ? styles.joblistCardScrollWarehouse : styles.joblistCardScroll}>
			{getJobCurrency(props.futureTab)}
			{/*Display a card to suggest other jobs*/}
			{props.futureTab && getJobCurrency(props.futureTab).length > 0 ? (
				<>
					<MediaQuery maxWidth={851}>
						<div className={styles.joblistCardContainer}>
							<div className={styles.joblistSrIconHolder}>
								<SearchFutureIcon className={styles.seachFutureIcon} aria-hidden={true} />
							</div>
							<div className={styles.joblistCardInfoHolder}>
								<div className={styles.joblistCardTitleContainer}>
									<div className={styles.joblistCardTitle}>
										{t('jobSelection.stillLookingTtile')}
									</div>
								</div>
								<div className={styles.joblistCardText}>
									{t('jobSelection.stillLookingSubText')}
								</div>

								<div className={styles.joblistCardSelectContainer}>
									<div className={styles.joblistCardLink}></div>
									<div>
										<Button size="small" variant="secondary" onClick={props.swapTab}>
											{t('jobSelection.futureOpenings')}
										</Button>
									</div>
								</div>
							</div>
						</div>
					</MediaQuery>
					<MediaQuery minWidth={851}>
						<div className={styles.joblistCardContainer}>
							<div className={styles.joblistCardTitleInfoHolder}>
								<div style={{ display: 'flex', placeContent: 'center' }}>
									<div className={styles.imageHolderDesktopFuture}>
										<SearchFutureIcon className={styles.seachFutureIcon} aria-hidden={true} />
									</div>
								</div>
								<div>
									<Button size="small" variant="secondary" onClick={props.swapTab}>
										{t('jobSelection.futureOpenings')}
									</Button>
								</div>
							</div>
							<div className={styles.joblistCardInfoHolder}>
								<div className={styles.joblistCardTitleContainer}>
									<div className={styles.joblistCardTitle}>
										{t('jobSelection.stillLookingTtile')}
									</div>
								</div>
								<div className={styles.joblistCardText}>
									{t('jobSelection.stillLookingSubText')}
								</div>
							</div>
						</div>
					</MediaQuery>
				</>
			) : getJobCurrency(props.futureTab).length <= 0 ? (
				<div className={styles.jobListNoJobsContainer}>
					<div className={styles.jobListCatImageHolder}>
						<img
							src={require('../../../assets/images/cat_chewingCord_circle.png')}
							alt="Job icon"
							aria-hidden = {true}
							className={styles.joblistCatImage}
						/>
					</div>
					<Body as="div" weight={700} size="large">
						{t('jobSelection.presentMatches')}
					</Body>
					{props.futureTab ? (
						<div className={styles.joblistCardText}>{t('jobSelection.stillLookingSubText')} </div>
					) : null}
					<div className={styles.joblistCardText}>{t('jobSelection.tryUpdatingFilters')}</div>
				</div>
			) : (
				<div />
			)}
		</div>
	);
};

export default JobList;
