import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	
	applicantId: '',
	eeocCode: '',
	genderCode: ''
	
};

export const eeoSlice = createSlice({
	name: 'Eeo',
	initialState,
	reducers: {
		updateApplicantIdEeo: (state, action) => {
			state.applicantId = action.payload.applicantId;
		},
		updateEeocCode: (state, action) => {
			state.eeocCode = action.payload.eeocCode;
		},
		updateGenderCode: (state, action) => {
			state.genderCode = action.payload.genderCode;
		}
	},
});

export const { updateApplicantIdEeo,updateEeocCode,updateGenderCode} = eeoSlice.actions;

export default eeoSlice.reducer;
