import React, { useEffect, useState } from 'react';
import style from './EEOComp.module.css';
import commonStyles from '../../../Style/commonStyle.module.css';
import { useTranslation } from 'react-i18next';
import { Body, Radio, WizardFooter, Divider } from '@walmart-web/livingdesign-components';
import { useDispatch, useSelector } from 'react-redux';
import { executionErrorOccured, subHeader, updateLoading } from '../../../redux/slices/globalSlice';
import { useNavigate } from 'react-router';
import { updatePersonalInfo } from '../../../redux/AboutYou/slice';
import { updateApplicantIdEeo, updateEeocCode, updateGenderCode } from '../../../redux/EEO/slice';
import { saveGenderInfo } from '../../../redux/EEO/request';
import { getPersonalInfo } from '../../../redux/AboutYou/request';
import { useDisplay } from '../../../utils/useDisplay';
import { ReactComponent as EeoSvg } from '../../../assets/svg/eeo.svg';
import { sendGtmEvent } from '../../../utils/GoogleTagManagerUtils';
import { GTM } from '../../../config/const';
import { common } from '@mui/material/colors';

const EEOComp = () => {
	const global = useSelector((state) => state.global);
	const { isPortrait } = useDisplay();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const Eeo = useSelector((state) => state.Eeo);
	const [gender, setGender] = useState({
		male: false,
		female: false,
		dontWishToAnswer: false
	});
	const [ethnicGroup, setEthnicGroup] = useState({
		hispanic: false,
		white: false,
		asian: false,
		americanIndianAlaska: false,
		blackOrAfrican: false,
		twoOrMoreRaces: false,
		dontWishToProvide: false,
		nativeHawaiiOrpacificIslander:false
	});
	const [disableButton, setDisableButton] = useState({
		gender: true,
		ethnic: true
	});

	useEffect(() => {
		dispatch(updateApplicantIdEeo({ applicantId: global.applicantId }));
		dispatch(
			subHeader({
				title: isPortrait ? 'EEO' : 'eeo.title',
				routeTo: 'eeo',
				display: 'true'
			})
		);

		const requestInput = {
			applicantId: global.applicantId,
			emailId: global.emailId
		};
		dispatch(updateLoading(true));
		dispatch(getPersonalInfo(requestInput))
			.then((res) => {
				const data = res.payload;
				dispatch(updatePersonalInfo(data));
				const applInfo = data;
				if (Object.keys(applInfo).length !== 0) {
					if (applInfo.ethnicGroup === '3') {
						setEthnicGroup((prevState) => ({
							...prevState,
							hispanic: true
						}));
					} else if (applInfo.ethnicGroup === '6') {
						setEthnicGroup((prevState) => ({
							...prevState,
							asian: true
						}));
					} else if (applInfo.ethnicGroup === '1') {
						setEthnicGroup((prevState) => ({
							...prevState,
							white: true
						}));
					} else if (applInfo.ethnicGroup === '5') {
						setEthnicGroup((prevState) => ({
							...prevState,
							americanIndianAlaska: true
						}));
					} else if (applInfo.ethnicGroup === '2') {
						setEthnicGroup((prevState) => ({
							...prevState,
							blackOrAfrican: true
						}));
					} else if (applInfo.ethnicGroup === '8') {
						setEthnicGroup((prevState) => ({
							...prevState,
							twoOrMoreRaces: true
						}));
					} else if (applInfo.ethnicGroup === '7') {
						setEthnicGroup((prevState) => ({
							...prevState,
							nativeHawaiiOrpacificIslander: true
						}));
					}	 else if (applInfo.ethnicGroup === '0') {
						setEthnicGroup((prevState) => ({
							...prevState,
							dontWishToProvide: true
						}));
					}
					if (applInfo.gender === 'M') {
						setGender((prevState) => ({
							...prevState,
							male: true
						}));
					} else if (applInfo.gender === 'F') {
						setGender((prevState) => ({
							...prevState,
							female: true
						}));
					} else if (applInfo.gender === '-') {
						setGender((prevState) => ({
							...prevState,
							dontWishToAnswer: true
						}));
					}
					if (applInfo.ethnicGroup !== null && applInfo.gender !== null) {
						dispatch(updateGenderCode({ genderCode: applInfo.gender }));
						dispatch(updateEeocCode({ eeocCode: applInfo.ethnicGroup }));
						setDisableButton({ gender: false, ethnic: false });
					}
				}
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
		// eslint-disable-next-line
	}, []);

	const handleGender = (e) => {
		const value = e.target.name;
		if (value === 'male') {
			setGender({
				male: true,
				female: false,
				dontWishToAnswer: false
			});
			dispatch(updateGenderCode({ genderCode: 'M' }));
		} else if (value === 'female') {
			setGender({
				male: false,
				female: true,
				dontWishToAnswer: false
			});
			dispatch(updateGenderCode({ genderCode: 'F' }));
		} else if (value === 'dontWishToAnswer') {
			setGender({
				male: false,
				female: false,
				dontWishToAnswer: true
			});
			dispatch(updateGenderCode({ genderCode: '-' }));
		}
		setDisableButton((prevState) => ({
			...prevState,
			gender: false
		}));
	};

	const handleEthnicGroup = (e) => {
		const value = e.target.name;
		if (value === 'hispanic') {
			setEthnicGroup({
				hispanic: true,
				white: false,
				asian: false,
				americanIndianAlaska: false,
				blackOrAfrican: false,
				twoOrMoreRaces: false,
				nativeHawaiiOrpacificIslander: false,
				dontWishToProvide: false
			});
			dispatch(updateEeocCode({ eeocCode: '3' }));
		} else if (value === 'white') {
			setEthnicGroup({
				hispanic: false,
				white: true,
				asian: false,
				americanIndianAlaska: false,
				blackOrAfrican: false,
				twoOrMoreRaces: false,
				nativeHawaiiOrpacificIslander: false,
				dontWishToProvide: false
			});
			dispatch(updateEeocCode({ eeocCode: '1' }));
		} else if (value === 'asian') {
			setEthnicGroup({
				hispanic: false,
				white: false,
				asian: true,
				americanIndianAlaska: false,
				blackOrAfrican: false,
				twoOrMoreRaces: false,
				nativeHawaiiOrpacificIslander: false,
				dontWishToProvide: false
			});
			dispatch(updateEeocCode({ eeocCode: '6' }));
		} else if (value === 'americanIndianAlaska') {
			setEthnicGroup({
				hispanic: false,
				white: false,
				asian: false,
				americanIndianAlaska: true,
				blackOrAfrican: false,
				twoOrMoreRaces: false,
				nativeHawaiiOrpacificIslander: false,
				dontWishToProvide: false
			});
			dispatch(updateEeocCode({ eeocCode: '5' }));
		} else if (value === 'blackOrAfrican') {
			setEthnicGroup({
				hispanic: false,
				white: false,
				asian: false,
				americanIndianAlaska: false,
				blackOrAfrican: true,
				twoOrMoreRaces: false,
				nativeHawaiiOrpacificIslander: false,
				dontWishToProvide: false
			});
			dispatch(updateEeocCode({ eeocCode: '2' }));
		} else if (value === 'twoOrMoreRaces') {
			setEthnicGroup({
				hispanic: false,
				white: false,
				asian: false,
				americanIndianAlaska: false,
				blackOrAfrican: false,
				twoOrMoreRaces: true,
				nativeHawaiiOrpacificIslander: false,
				dontWishToProvide: false
			});
			dispatch(updateEeocCode({ eeocCode: '8' }));
		} else if (value === 'nativeHawaiiOrpacificIslander') {
			setEthnicGroup({
				hispanic: false,
				white: false,
				asian: false,
				americanIndianAlaska: false,
				blackOrAfrican: false,
				twoOrMoreRaces: false,
				nativeHawaiiOrpacificIslander: true,
				dontWishToProvide: false
			});
			dispatch(updateEeocCode({ eeocCode: '7' }));
		} else if (value === 'dontWishToProvide') {
			setEthnicGroup({
				hispanic: false,
				white: false,
				asian: false,
				americanIndianAlaska: false,
				blackOrAfrican: false,
				twoOrMoreRaces: false,
				nativeHawaiiOrpacificIslander: false,
				dontWishToProvide: true
			});
			dispatch(updateEeocCode({ eeocCode: '0' }));
		}
		setDisableButton((prevState) => ({
			...prevState,
			ethnic: false
		}));
	};

	const handleButton = (e) => {
		sendGtmEvent(GTM.EVENTS.APPLICATION_STEP2_EEO);
		const req = {
			applicantId: Eeo.applicantId,
			eeocCode: Eeo.eeocCode,
			genderCode: Eeo.genderCode
		};
		dispatch(updateLoading(true));
		dispatch(saveGenderInfo(req))
			.then((res) => {
				if (res.payload.responseCode === '0') {
					navigate('../experience');
				}
				console.log('response received from backend', res);
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	return (
		<div>
			<div className={style.main}>
				<div className={style.blueHead}></div>
				<EeoSvg className={style.EEOImg} aria-hidden={true} />

				<div className={style.container}>
					<div className={style.title}>
						<Body as="p" size="large" weight={700}>
							{t('eeo.title')}
						</Body>
					</div>
					<Body as="div" size="medium" weight={400}>
						{t('general.mandatoryText')}
					</Body>
					<Body as="div" size="small" weight={400} UNSAFE_style={{ color: '#707070' }}>
						{t('eeo.nonDiscriminationText')}
					</Body>
					<fieldset className={commonStyles.fieldSet}>
						<legend className={commonStyles.legend}>
							<Body as="p" size="medium" weight={400}>
								{t('eeo.gender')}
							</Body>
						</legend>
							<Radio
								label={t('eeo.female')}
								name="female"
								onChange={(e) => {
									handleGender(e);
								}}
								checked={gender.female}
							/>
							<Radio
								label={t('eeo.male')}
								name="male"
								onChange={(e) => {
									handleGender(e);
								}}
								checked={gender.male}
							/>
							<Radio
								label={t('eeo.dontWishAnswer')}
								name="dontWishToAnswer"
								onChange={(e) => {
									handleGender(e);
								}}
								checked={gender.dontWishToAnswer}
							/>
					</fieldset>				
						<Body as="div" size="medium" weight={400}>
							{t('eeo.group')}
						</Body>
					<fieldset className={commonStyles.fieldSet}>
					<legend className={commonStyles.legend}>
						<Body as="p" size="small" weight={400} UNSAFE_style={{ color: '#707070' }}>
							{t('eeo.assistIdentification')}
						</Body>
					</legend>
						<div className={commonStyles.radio}>
							<Radio 
								label={
									<>
										<b> {t('eeo.hispanic')}</b>
										{'—'}
										{t('eeo.hispanicText')}
									</>
								}
								name="hispanic"
								onChange={(e) => {
									handleEthnicGroup(e);
								}}
								checked={ethnicGroup.hispanic}
							/>
						</div>
						<div className={commonStyles.radio}>
							<Radio
								label={
									<>
										<b> {t('eeo.white')}</b>
										{t('eeo.whiteText')}
									</>
								}
								name="white"
								onChange={(e) => {
									handleEthnicGroup(e);
								}}
								checked={ethnicGroup.white}
							/>
						</div>
						<div className={commonStyles.radio}>
							<Radio
								label={
									<>
										<b> {t('eeo.asian')}</b>
										{'—'}
										{t('eeo.asianText')}
									</>
								}
								name="asian"
								onChange={(e) => {
									handleEthnicGroup(e);
								}}
								checked={ethnicGroup.asian}
							/>
						</div>
						<div className={commonStyles.radio}>
							<Radio
								label={
									<>
										<b> {t('eeo.americanIndian')}</b>
										<br />
										{t('eeo.americanIndianText')}
									</>
								}
								name="americanIndianAlaska"
								onChange={(e) => {
									handleEthnicGroup(e);
								}}
								checked={ethnicGroup.americanIndianAlaska}
							/>
						</div>
						<div className={commonStyles.radio}>
							<Radio
								label={
									<>
										<b> {t('eeo.blackorAfrican')}</b>
										{'—'}
										{t('eeo.blackorAfricanText')}
									</>
								}
								name="blackOrAfrican"
								onChange={(e) => {
									handleEthnicGroup(e);
								}}
								checked={ethnicGroup.blackOrAfrican}
							/>
						</div>
						<div className={commonStyles.radio}>
							<Radio
								label={
									<>
										<b> {t('eeo.twoRace')}</b>
										{t('eeo.twoRaceText')}
									</>
								}
								name="twoOrMoreRaces"
								onChange={(e) => {
									handleEthnicGroup(e);
								}}
								checked={ethnicGroup.twoOrMoreRaces}
							/>
						</div>
						<div className={commonStyles.radio}>
							<Radio
								label={
									<>
										<b> {t('eeo.nativeHawaii')}</b>
										{'—'}
										{t('eeo.hawaiiText')}
									</>
								}
								name="nativeHawaiiOrpacificIslander"
								onChange={(e) => {
									handleEthnicGroup(e);
								}}
								checked={ethnicGroup.nativeHawaiiOrpacificIslander}
							/>
						</div>
						<div className={commonStyles.radio}>
							<Radio
								label={t('eeo.dontWish')}
								name="dontWishToProvide"
								onChange={(e) => {
									handleEthnicGroup(e);
								}}
								checked={ethnicGroup.dontWishToProvide}
							/>
						</div>
					</fieldset>
				</div>
			</div>
			<div style={{ width: '100%' }}>
				<Divider />
			</div>
			<div>
				<WizardFooter
					previousActionProps={{
						UNSAFE_className: 'wizard_footer_previous',
						onClick: () => navigate('../about-you'),
						children: t('general.previous')
					}}
					nextActionProps={{
						UNSAFE_className: 'wizard_footer_next',
						disabled: disableButton.ethnic || disableButton.gender,
						onClick: () => {
							handleButton();
						},
						children: t('general.continue')
					}}></WizardFooter>
			</div>
		</div>
	);
};

export default EEOComp;
