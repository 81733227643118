import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateLoading, wotcDownErrorOccured } from 'redux/slices/globalSlice';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';
import { useSelector } from 'react-redux';

const getWOTCQuestions = createAsyncThunk(
	'taxQuestionnaire/getWOTCQuestions',
	async (request, { dispatch }) => {
			const baseUrl = environment.hcServiceBasePostUrl + '/questions';
			const response = await axiosInstance.post(baseUrl, request);
			dispatch(updateLoading(false));
			return response.data;
	}
);

export { getWOTCQuestions };
