import React, { useEffect, useState } from 'react';
import style from './ApplicationPanel.module.scss';
import { Check, User, Spark, UserPlus, Calendar } from '@livingdesign/icons';
import { ReactComponent as RequestPersonalInfo } from '@livingdesign/pictograms/dist/svg/App_&_Web_Interface/RequestPersonalInfo.svg';
import { ReactComponent as RegistrationComplete } from '@livingdesign/pictograms/dist/svg/Health_Forms_&_Office/RegistrationComplete.svg';
import { ReactComponent as RegistrationConsentMedicalForm } from '@livingdesign/pictograms/dist/svg/Health_Forms_&_Office/RegistrationConsentMedicalForm.svg';
import { ReactComponent as Travel } from '@livingdesign/pictograms/dist/svg/Miscellaneous/Travel.svg';
import { ReactComponent as ScheduleCalendar } from '@livingdesign/pictograms/dist/svg/Health_Forms_&_Office/ScheduleCalendar.svg';
import { ReactComponent as ServicesInterface } from '@livingdesign/pictograms/dist/svg/App_&_Web_Interface/ServicesInterface.svg';
import { ReactComponent as Clock } from '@livingdesign/pictograms/dist/svg/Home/Clock.svg';
import { Body, Button, Divider, Heading, BottomSheet, Alert } from '@walmart-web/livingdesign-components';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
	Accordion,
	AccordionSummary,
	expandIcon,
	AccordionDetails
} from '../../../components/Accordion/MuiAccordion';
import GenericLocationCard from '../../../components/GenericLocationCard/GenericLocationCard';
import GenericJobCard from '../../../components/GenericJobCard/GenericJobCard';
import LocSearchContainer from '../../Location/components/LocationSearch/LocSearchContainer';
import { isEmptyObject } from '../../../utils/validation';
import { useTranslation } from 'react-i18next';
import { useDisplay } from 'utils/useDisplay';
import { updateQuestionnaireCompletedStatus } from 'redux/TaxQuestionnaire/slice';

export default function ApplicationPanel() {
	const navigate = useNavigate();
	const [expanded, setExpanded] = useState(false);
	const location = useSelector((state) => state.location);
	const jobSearch = useSelector((state) => state.jobSearch);
	const account = useSelector((state) => state.account);
	const [isOpen, setIsOpen] = useState(false);
	const [showTaxQuestionnaire, setShowTaxQuestionnaire] = useState(true);
	const [showAvailability, setShowAvailability] = useState(true);
	const [showCountryCode, setShowCountryCode] = useState(false);
	const { isPortrait, isMobile } = useDisplay();
	const { t } = useTranslation();
	const availabilityUrl = '../availability';
	const [showTaxQuestionnaireWarning, setShowTaxQuestionnaireWarning] = useState(true);
	const dispatch = useDispatch();
	const taxQuestionnaireCompleted = useSelector(
		(state) => state.questionnaire?.questionnaireCompleted
	);

	useEffect(() => {
		if (!isEmptyObject(account.data.auth)) {
			setShowTaxQuestionnaire(!account.data.auth.onlyPrJobsPreferred);
			setShowAvailability(!account.data.auth.onlyScJobsPreferred);
		}
	}, [account.data.auth]);

	useEffect(() => {
		if (!isEmptyObject(account.data.applProfileSteps)) {
			if (account.data.applProfileSteps['0'] === false) {
				setExpanded('panel1');
			}
			if (
				account.data.applProfileSteps['1'] === false &&
				account.data.applProfileSteps['0'] === true
			) {
				setExpanded('panel2');
			}
			if (
				(account.data.applProfileSteps['2'] === false ||
					account.data.applProfileSteps['3'] === false ||
					account.data.applProfileSteps['4'] === false) &&
				account.data.applProfileSteps['1'] === true
			) {
				setExpanded('panel3');
			}
			if (
				(account.data.applProfileSteps['5'] === false ||
					account.data.applProfileSteps['6'] === false ||
					account.data.applProfileSteps['7'] === false ||
					account.data.applProfileSteps['8'] === false ||
					account.data.applProfileSteps['9'] === false) &&
				account.data.applProfileSteps['4'] === true
			) {
				setExpanded('panel4');
			}
			if (
				(account.data.applProfileSteps['10'] === false ||
					account.data.applProfileSteps['11'] === false) &&
				account.data.applProfileSteps['9'] === true
			) {
				setExpanded('panel5');
			}
			dispatch(updateQuestionnaireCompletedStatus(account.data.applProfileSteps['9']));
		}
		//Finding if country code to be shown in Job selection
		const countryCodes = jobSearch.selectedJobs?.map((ele) => ele.countryCode);
		if (countryCodes.indexOf('US') > -1 && countryCodes.indexOf('PR') > -1) {
			setShowCountryCode(true);
		}
	}, [account.data.applProfileSteps]);

	const handleJobSearchClick = () => {
		setIsOpen(true);
	};

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	const checkIcon = <Check size="medium" className={style.accordion__check_icon} a11yLabel={t('general.completed')} />;

	const locationIcon = (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden={true}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.4739 3C20.6975 3 22.5 4.64374 22.5 7.05719C22.5 8.76393 21.4081 10.9055 19.2242 13.482L19.2239 21H11.8243L11.8237 16.5408H8.8995L8.89961 21H1.5V7.87374C1.5 7.38458 1.86891 6.98225 2.34165 6.93387L2.4375 6.929L5.31525 6.92861L9.7832 3.39197C10.0939 3.14596 10.5205 3.12475 10.8513 3.32871L10.939 3.38992L14.5259 6.20889C14.8942 4.26557 16.5218 3 18.4739 3ZM10.3635 4.85395L5.8345 8.44059L3 8.4402V19.4877H7.3995L7.39961 15.0295H13.3243L13.3237 19.4877H17.7233L17.723 13.4809C15.9931 11.439 14.9484 9.67077 14.5891 8.17617L10.3635 4.85395ZM18.4739 4.51159C17.0072 4.51159 15.9478 5.54743 15.9478 7.05719C15.9478 8.21794 16.7377 9.85715 18.3689 11.8983L18.474 12.028L18.5791 11.8986C20.1498 9.93387 20.9403 8.34102 20.9967 7.18825L21 7.05719C21 5.54849 19.9399 4.51159 18.4739 4.51159ZM18.4739 5.94955C19.0331 5.94955 19.4864 6.40636 19.4864 6.96986C19.4864 7.53337 19.0331 7.99018 18.4739 7.99018C17.9147 7.99018 17.4614 7.53337 17.4614 6.96986C17.4614 6.40636 17.9147 5.94955 18.4739 5.94955Z"
				fill="#000000"
			/>
		</svg>
	);

	const requestPersonalInfo = (
		<RequestPersonalInfo size="medium" className={style.accordion__pictograms_icon} aria-hidden={true} />
	);
	const registrationCompleteIcon = (
		<RegistrationComplete size="medium" className={style.accordion__pictograms_icon} aria-hidden={true} />
	);

	const getAvailPath = (key) => {
		let url = '';
		if (key === 'employmentType') {
			url = isPortrait ? '../availability/employmentType' : availabilityUrl;
		} else if (key === 'shiftPreference') {
			url = isPortrait ? '../availability/shiftPreference' : availabilityUrl;
		} else if (key === 'availableHours') {
			url = isPortrait ? '../availability/availableHours' : availabilityUrl;
		}
		return url;
	};

	const applicationPanelButton = (size, step, url, panelTitle, disabled, customOnClick, additionalClassName) => {
		return (
			(account.data.applProfileSteps[step] === true ||
				(account.data.applProfileSteps[step] === false && step === 0) ||
				(account.data.applProfileSteps[step] === false &&
					step > 0 &&
					account.data.applProfileSteps[step - 1] === true)) && (
				<Button
					size={size}
					variant={
						account.data.applProfileSteps[step] === false &&
						((step > 0 && account.data.applProfileSteps[step - 1] === true) || step === 0)
							? 'primary'
							: 'tertiary'
					}
					disabled={disabled}
					type="button"
					UNSAFE_className={`${additionalClassName} ${
						account.data.applProfileSteps[step] === true && style.button_tertiary
					}`}
					aria-label={panelTitle}
					onClick={() => (customOnClick ? customOnClick(): navigate(url))}>	
					{account.data.applProfileSteps[step] === false &&
					((step > 0 && account.data.applProfileSteps[step - 1] === true) || step === 0)
						? t('welcomePage.start')
						: t('welcomePage.edit')}
				</Button>
			)
		);
	};

	return (
		<div>
			<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
				<AccordionSummary
					expandIcon={expandIcon}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className={style.accordion__summary}>
					{account.data.applProfileSteps['0'] === false ? locationIcon : checkIcon}
					<Heading as="h1" size="small" weight="700" UNSAFE_className={style.heading}>
						{t('welcomePage.location')}
					</Heading>
				</AccordionSummary>
				<AccordionDetails className={style.extend_padd}>
					{location.selectedPos &&
						location.selectedPos.map((store) => (
							<div key={store.storeNumber}>
								<div className={style.genericCards}>
									<GenericLocationCard store={store} />
								</div>
								<Divider />
							</div>
						))}
					<div className={style.accordion__detail}>
						<div className={`${style.accordion__detail__content}`}>
							<Button
								variant={`${location.selectedPos.length > 0 ? 'tertiary' : 'primary'}`}
								onClick={handleJobSearchClick}
								aria-label={t('ariaLabelTexts.manageLocationPreferences')}
								UNSAFE_className={`${
									location.selectedPos.length > 0 && style.accordion__button_with_padd
								} ${location.selectedPos.length > 0 && style.accordion__button_font}`}>
								{location.selectedPos.length > 0 ? t('welcomePage.add') : 'Select locations'}
							</Button>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
				<AccordionSummary
					expandIcon={expandIcon}
					aria-controls="panel2bh-content"
					id="panel2bh-header"
					className={style.accordion__summary}>
					{account.data.applProfileSteps['1'] === false ? (
						<Travel
							size="small"
							className={`${style.icon} ${style.accordion__pictograms_icon}`}
							fill="currentColor"
							aria-hidden={true}
						/>
					) : (
						checkIcon
					)}
					<Heading as="h1" size="small" weight="700" UNSAFE_className={style.heading}>
						{t('welcomePage.jobSelection')}
					</Heading>
				</AccordionSummary>
				<AccordionDetails className={style.extend_padd}>
					{jobSearch.selectedJobs.length > 0 &&
						jobSearch.selectedJobs.map((item, i) => {
							return (
								<div>
									<GenericJobCard key={i} item={item} showCountryCode={showCountryCode} />
									<Divider />
								</div>
							);
						})}
					<div className={style.accordion__detail}>
						<div className={`${style.accordion__detail__content}`}>
							<Button
								variant={`${jobSearch.selectedJobs.length > 0 ? 'tertiary' : 'primary'}`}
								onClick={() => navigate('../job-search')}
								aria-label={t('ariaLabelTexts.manageJobSelectionPreferences')}
								UNSAFE_className={`${
									jobSearch.selectedJobs.length > 0 && style.accordion__button_with_padd
								} ${jobSearch.selectedJobs.length > 0 && style.accordion__button_font}`}>
								{jobSearch.selectedJobs.length > 0 ? t('welcomePage.add') : 'Select jobs'}
							</Button>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
				<AccordionSummary
					expandIcon={expandIcon}
					aria-controls="panel3bh-content"
					id="panel3bh-header"
					className={style.accordion__summary}>
					{account.data.applProfileSteps['2'] === false ||
					account.data.applProfileSteps['3'] === false ||
					account.data.applProfileSteps['4'] === false ? (
						<ScheduleCalendar
							size="small"
							className={`${style.icon} ${style.accordion__pictograms_icon}`}
							fill="currentColor"
							aria-hidden={true}
						/>
					) : (
						checkIcon
					)}
					<Heading as="h1" size="small" weight="700" UNSAFE_className={style.heading}>
						{t('welcomePage.availability')}
					</Heading>
				</AccordionSummary>
				<AccordionDetails>
					<div className={style.accordion__detail}>
						<div className={`${style.accordion__detail__content}`}>
							<div className={style.left_section}>
								<ServicesInterface
									size="small"
									className={`${style.icon} ${
										account.data.applProfileSteps['2'] === false &&
										account.data.applProfileSteps['1'] === false &&
										style.disabled
									}`}
									fill="currentColor"
									aria-hidden={true}
								/>
								<Body
									as="p"
									size="medium"
									weight="400"
									UNSAFE_className={
										account.data.applProfileSteps['2'] === false &&
										account.data.applProfileSteps['1'] === false &&
										style.disabled
									}>
									{t('welcomePage.employmentType')}
								</Body>
							</div>
							{applicationPanelButton('small', 2, getAvailPath('employmentType'), t('applicationPanelTitle.manageEmploymentType'), false)}
						</div>
						<Divider className={style.divider} />
						<div className={`${style.accordion__detail__content}`}>
							<div className={`${style.left_section}`}>
								<Calendar
									size="small"
									className={`${style.icon} ${
										account.data.applProfileSteps['3'] === false &&
										account.data.applProfileSteps['2'] === false &&
										style.disabled
									}`}
									fill="currentColor"
									aria-hidden={true}
								/>
								<Body
									as="p"
									size="medium"
									weight="400"
									UNSAFE_className={
										account.data.applProfileSteps['3'] === false &&
										account.data.applProfileSteps['2'] === false &&
										style.disabled
									}>
									{t('welcomePage.shiftPreference')}
								</Body>
							</div>
							{applicationPanelButton('small', 3, getAvailPath('shiftPreference'), t('applicationPanelTitle.manageShiftPreference'), false)}
						</div>
						{showAvailability && (
							<React.Fragment>
								<Divider className={style.divider} />
								<div className={`${style.accordion__detail__content}`}>
									<div className={style.left_section}>
										<Clock
											size="small"
											className={`${style.icon} ${
												account.data.applProfileSteps['4'] === false &&
												account.data.applProfileSteps['3'] === false &&
												style.disabled
											}`}
											fill="currentColor"
											aria-hidden={true}
										/>
										<Body
											as="p"
											size="medium"
											weight="400"
											UNSAFE_className={
												account.data.applProfileSteps['4'] === false &&
												account.data.applProfileSteps['3'] === false &&
												style.disabled
											}>
											{t('welcomePage.availableHours')}
										</Body>
									</div>
									{applicationPanelButton('small', 4, getAvailPath('availableHours'), t('applicationPanelTitle.setAvailableHours'), false)}
								</div>
							</React.Fragment>
						)}
					</div>
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
				<AccordionSummary
					expandIcon={expandIcon}
					aria-controls="panel4bh-content"
					id="panel4bh-header"
					className={style.accordion__summary}>
					{account.data.applProfileSteps['5'] === false ||
					account.data.applProfileSteps['6'] === false ||
					account.data.applProfileSteps['7'] === false ||
					account.data.applProfileSteps['8'] === false ||
					account.data.applProfileSteps['9'] === false
						? requestPersonalInfo
						: checkIcon}
					<Heading as="h1" size="small" weight="700" UNSAFE_className={style.heading}>
						{t('welcomePage.application')}
					</Heading>
				</AccordionSummary>
				<AccordionDetails>
					<div className={style.accordion__detail}>
						<div className={`${style.accordion__detail__content}`}>
							<div className={style.left_section}>
								<User
									size="small"
									className={`${style.icon} ${
										account.data.applProfileSteps['5'] === false &&
										account.data.applProfileSteps['4'] === false &&
										style.disabled
									}`}
									aria-hidden={true}
								/>
								<Body
									as="p"
									size="medium"
									weight="400"
									UNSAFE_className={`${style.heading} ${
										account.data.applProfileSteps['5'] === false &&
										account.data.applProfileSteps['4'] === false &&
										style.disabled
									}`}>
									{t('welcomePage.aboutYou')}
								</Body>
							</div>
							{applicationPanelButton('small', 5, '../about-you',t('applicationPanelTitle.manageAboutYou'), false)}
						</div>
						<Divider className={style.divider} />
						<div className={`${style.accordion__detail__content}`}>
							<div className={`${style.left_section}`}>
								<Spark
									size="small"
									className={`${style.icon} ${
										account.data.applProfileSteps['6'] === false &&
										account.data.applProfileSteps['5'] === false &&
										style.disabled
									}`}
									aria-hidden={true}
								/>
								<Body
									as="p"
									size="medium"
									weight="400"
									UNSAFE_className={`${style.heading} ${
										account.data.applProfileSteps['6'] === false &&
										account.data.applProfileSteps['5'] === false &&
										style.disabled
									}`}>
									{t('welcomePage.equalEmployment')}
								</Body>
							</div>
							{applicationPanelButton('small', 6, '../eeo',t('applicationPanelTitle.manageEqualEmploymentOppurtunity'), false)}
						</div>
						<Divider className={style.divider} />
						<div className={`${style.accordion__detail__content}`}>
							<div className={style.left_section}>
								<UserPlus
									size="small"
									className={`${style.icon} ${
										account.data.applProfileSteps['7'] === false &&
										account.data.applProfileSteps['6'] === false &&
										style.disabled
									}`}
									aria-hidden={true}
								/>
								<Body
									as="p"
									size="medium"
									weight="400"
									UNSAFE_className={`${style.heading} ${
										account.data.applProfileSteps['7'] === false &&
										account.data.applProfileSteps['6'] === false &&
										style.disabled
									}`}>
									{t('welcomePage.experience')}
								</Body>
							</div>
							{applicationPanelButton('small', 7, '../experience', t('applicationPanelTitle.setExperience'), false)}
						</div>
						<Divider className={style.divider} />
						<div className={`${style.accordion__detail__content}`}>
							<div className={style.left_section}>
								<svg
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="currentColor"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden={true}
									className={`${style.icon} ${
										account.data.applProfileSteps['8'] === false &&
										account.data.applProfileSteps['7'] === false &&
										style.disabled
									}`}>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M15 4.7048C15 3.09583 13.7983 2 12.3159 2C11.0145 2 9.92932 2.84382 9.68389 4.13949L7.29264 2.25995L7.20374 2.20153C6.98799 2.08476 6.71986 2.10476 6.52213 2.26131L3.543 4.61907L1.625 4.61933L1.53264 4.62616C1.23126 4.67115 1 4.93294 1 5.24916V14H5.93307L5.933 11.0272H7.882L7.88287 14H12.8159V8.98821C14.272 7.27047 15 5.84267 15 4.7048ZM11.8159 8.98786C10.6622 7.62629 9.96558 6.44722 9.72605 5.45064L6.908 3.23546L3.88966 5.62706L2 5.6268V12.9913H4.933L4.93307 10.0197H8.88287L8.882 12.9913H11.815L11.8159 8.98786ZM10.6319 4.7048C10.6319 3.69829 11.3381 3.00773 12.3159 3.00773C13.2933 3.00773 14 3.69899 14 4.7048L13.9951 4.83683C13.9398 5.60161 13.4131 6.64777 12.3861 7.93243L12.316 8.01814L12.246 7.93218C11.1585 6.57143 10.6319 5.47863 10.6319 4.7048ZM12.9909 4.62628C12.9909 4.99907 12.6887 5.30127 12.3159 5.30127C11.9432 5.30127 11.641 4.99907 11.641 4.62628C11.641 4.25349 11.9432 3.95129 12.3159 3.95129C12.6887 3.95129 12.9909 4.25349 12.9909 4.62628Z"
									/>
								</svg>
								<Body
									as="p"
									size="medium"
									weight="400"
									UNSAFE_className={`${style.heading} ${
										account.data.applProfileSteps['8'] === false &&
										account.data.applProfileSteps['7'] === false &&
										style.disabled
									}`}>
									{t('welcomePage.jobHistory')}
								</Body>
							</div>
							{applicationPanelButton('small', 8, '../job-history', t('applicationPanelTitle.setJobHistory'), false)}
						</div>
						{showTaxQuestionnaire && (
							<React.Fragment>
								<Divider className={style.divider} />
								<div className={`${style.accordion__detail__content}`}>
									<div className={style.left_section}>
										<RegistrationConsentMedicalForm
											size="small"
											className={`${style.icon} ${
												account.data.applProfileSteps['9'] === false &&
												account.data.applProfileSteps['8'] === false &&
												style.disabled
											}`}
											fill="currentColor"
											aria-hidden={true}
										/>
										<Body
											as="p"
											size="medium"
											weight="400"
											UNSAFE_className={`${style.heading} ${
												account.data.applProfileSteps['9'] === false &&
												account.data.applProfileSteps['8'] === false &&
												style.disabled
											}`}>
											{t('welcomePage.taxQuestionnaire')}
										</Body>
									</div>
									{applicationPanelButton(
                                        'small',
                                        9,
                                        '../wotc-questionnaire',
                                        t('applicationPanelTitle.manageWotcQuestionnaire'),
                                        false,
                                        taxQuestionnaireCompleted && (() => setShowTaxQuestionnaireWarning(true)),
                                        taxQuestionnaireCompleted ? style.button_tertiary__disabled : ''
                                    )}
								</div>
								{taxQuestionnaireCompleted && showTaxQuestionnaireWarning && !isMobile && (
									<Alert variant="warning">{`${t('wotc.sorry')} ${t(
										'wotc.completedWarning'
									)}`}</Alert>
								)}
								{taxQuestionnaireCompleted && isMobile && (
									<BottomSheet
										actions={
											<Button
												isFullWidth
												size="small"
												variant="primary"
												onClick={() => setShowTaxQuestionnaireWarning(false)}>
												{t('wotc.close')}
											</Button>
										}
										isOpen={showTaxQuestionnaireWarning}
										onClose={() => setShowTaxQuestionnaireWarning(false)}
										title={t('wotc.sorry')}>
										<Body>{t('wotc.completedWarning')}</Body>
									</BottomSheet>
								)}
							</React.Fragment>
						)}
					</div>
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
				<AccordionSummary
					expandIcon={expandIcon}
					aria-controls="panel5bh-content"
					id="panel5bh-header"
					className={style.accordion__summary}>
					{account.data.applProfileSteps['10'] === false ||
					account.data.applProfileSteps['11'] === false
						? registrationCompleteIcon
						: checkIcon}
					<Heading as="h1" size="small" weight="700" UNSAFE_className={style.heading}>
						{t('welcomePage.assessment')}
					</Heading>
				</AccordionSummary>
				<AccordionDetails>
					<div className={style.accordion__detail}>
						<div className={`${style.accordion__detail__content}`}>
							<div className={style.left_section}>
								<svg
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="currentColor"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden={true}
									className={`${style.icon} ${
										account.data.applProfileSteps['10'] === false &&
										account.data.applProfileSteps['9'] === false &&
										style.disabled
									}`}>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M10.5 1C10.7453 1 10.9496 1.17699 10.9919 1.41016L11 1.5V2H13C13.5126 2 13.9355 2.38629 13.9933 2.88343L14 3V14C14 14.5126 13.6137 14.9355 13.1166 14.9933L13 15H3C2.48743 15 2.06453 14.6137 2.00673 14.1166L2 14V3C2 2.48743 2.38629 2.06453 2.88343 2.00673L3 2H5V1.5C5 1.25467 5.17699 1.05042 5.41016 1.00806L5.5 1H10.5ZM13 3H3V14H13V3ZM9.6094 6.1875C9.7824 5.9725 10.0964 5.9395 10.3124 6.1095C10.5044 6.26328 10.5526 6.52846 10.4401 6.7372L10.3904 6.8125L7.7234 10.1455C7.6522 10.2343 7.55412 10.2956 7.44452 10.3206L7.34653 10.3334L7.3334 10.3335C7.2278 10.3335 7.12476 10.2996 7.03964 10.2379L6.9794 10.1865L5.6464 8.8535C5.4514 8.6585 5.4514 8.3415 5.6464 8.1465C5.81973 7.97317 6.08946 7.95391 6.28422 8.08872L6.3534 8.1465L7.2924 9.0845L9.6094 6.1875Z"
									/>
								</svg>
								<Body
									as="p"
									size="medium"
									weight="400"
									UNSAFE_className={`${style.heading} ${
										account.data.applProfileSteps['10'] === false &&
										account.data.applProfileSteps['9'] === false &&
										style.disabled
									}`}>
									{t('welcomePage.qualifications')}
								</Body>
							</div>
							{applicationPanelButton('small', 10, '../minimum-qualifications', t('applicationPanelTitle.setMinimumQualifications'), false)}
						</div>
						<Divider className={style.divider} />
						<div className={`${style.accordion__detail__content}`}>
							<div className={`${style.left_section}`}>
								<RegistrationConsentMedicalForm
									size="small"
									className={`${style.icon} ${
										account.data.applProfileSteps['11'] === false &&
										account.data.applProfileSteps['10'] === false &&
										style.disabled
									}`}
									fill="currentColor"
									aria-hidden={true}
								/>
								<Body
									as="p"
									size="medium"
									weight="400"
									UNSAFE_className={`${style.heading} ${
										account.data.applProfileSteps['11'] === false &&
										account.data.applProfileSteps['10'] === false &&
										style.disabled
									}`}>
									{t('welcomePage.assessment')}
								</Body>
							</div>
							{applicationPanelButton('small', 11, '../assessments', t('applicationPanelTitle.manageAssessments'), false)}
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<LocSearchContainer isOpen={isOpen} setIsOpen={setIsOpen} />
		</div>
	);
}
