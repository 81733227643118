import React from 'react';
import { useSelector } from 'react-redux';
import holding_device from '../../../../assets/images/maleHoldingDevice.png';
import { Heading } from '@walmart-web/livingdesign-components';
import Stripe from '../../../../components/Stripe';
import './style.scss';
import { useTranslation } from 'react-i18next';
import cookie from 'react-cookies';

const Banner = () => {
	const { t } = useTranslation();
	const account = useSelector((state) => state.account);
	return (
		<div className="banner_container">
			<div>
				<Heading as="h1" size="small" UNSAFE_className="banner_header">
					{t('general.hi')}
					{', '}
					{account?.data?.auth?.applicantName
						? account?.data?.auth?.applicantName.replace(
								/(^\w|\s\w)(\S*)/g,
								(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
						  )
						: cookie
								.load('applicantName')
								.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())}
					!
				</Heading>
				<Stripe width={50} />
			</div>
			<img src={holding_device} alt="banner with device" className="banner_holing_device_img" />
		</div>
	);
};

export default Banner;
