import { createSlice } from '@reduxjs/toolkit';
import { postFeedback } from './request';

const initialState = { error: {} };

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(postFeedback.rejected, (state, action) => {
      state.error = { message: 'Something went wrong' };
    });
  }
});

export default feedbackSlice.reducer;
