import * as React from "react";
import * as ReactDOM from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "@livingdesign/bogle/dist/Bogle.css";
import "@walmart-web/livingdesign-components/index.esm.css";
import {LivingDesignProvider} from "@walmart-web/livingdesign-components";
import {persistor, store} from './redux/store';
import { Provider } from 'react-redux';
import "./config/i18n-translator";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <LivingDesignProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
      </Provider>
  </LivingDesignProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
