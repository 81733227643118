import React, { useState, useEffect } from 'react';
import mystyle from './ScheduleAvailability.module.css';
import { Body, Radio, Heading, Alert, Button, Divider, Caption } from '@walmart-web/livingdesign-components';
import { useDispatch, useSelector } from 'react-redux';
import { subHeader } from '../../../redux/slices/globalSlice';
import CopyAvailableHoursPopUp from './CopyAvailableHoursPopUp';
import { useTranslation } from 'react-i18next';
import { isEmptyObject } from '../../../utils/validation';
import { useDisplay } from '../../../utils/useDisplay';
import { AvailabilityHelper } from '../AvailabilityHelper';
import Day from './Day';
import renewstyle from '../../RenewApplication/Components/ShiftAvailability/style.module.css';

const ScheduleAvailability = (props) => {
	const { t } = useTranslation();
	const [specficHrsOnButton, setSpecficHrsOnButton] = useState(false);
	const [specficHrsOffButton, setSpecficHrsOffButton] = useState(false);
	const [monCheckBox, setMonCheckBox] = useState(false);
	const [tueCheckBox, setTueCheckBox] = useState(false);
	const [wedCheckBox, setWedCheckBox] = useState(false);
	const [thuCheckBox, setThuCheckBox] = useState(false);
	const [friCheckBox, setFriCheckBox] = useState(false);
	const [satCheckBox, setSatCheckBox] = useState(false);
	const [sunCheckBox, setSunCheckBox] = useState(false);
	const [fromShiftMon, setFromShiftMon] = useState('');
	const [fromShiftTue, setFromShiftTue] = useState('');
	const [fromShiftWed, setFromShiftWed] = useState('');
	const [fromShiftThu, setFromShiftThu] = useState('');
	const [fromShiftFri, setFromShiftFri] = useState('');
	const [fromShiftSat, setFromShiftSat] = useState('');
	const [fromShiftSun, setFromShiftSun] = useState('');
	const [toShiftMon, setToShiftMon] = useState('');
	const [toShiftTue, setToShiftTue] = useState('');
	const [toShiftWed, setToShiftWed] = useState('');
	const [toShiftThu, setToShiftThu] = useState('');
	const [toShiftFri, setToShiftFri] = useState('');
	const [toShiftSat, setToShiftSat] = useState('');
	const [toShiftSun, setToShiftSun] = useState('');
	const [shiftErr, setShiftErr] = useState(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const schedule = useSelector((state) => state.availMain);
	const [copyArr, setCopyArr] = useState([]);
	const [copyValueArr, setCopyValueArr] = useState([]);
	const [showCopy, setShowCopy] = useState(false);
	const [copyEnable, setCopyEnable] = useState(false);
	const global = useSelector((state) => state.global);
	const { validateTiming, dayObj } = AvailabilityHelper();
	const { isPortrait } = useDisplay();
	const alertBottom = !props.isRenew ? 2 : 15;
	const editProfile = useSelector((state) => state.editProfile);

	const weekDayData = [
		{
			lable: t('days.mon'),
			startTime: fromShiftMon,
			endTime: toShiftMon,
			checked: monCheckBox
		},
		{
			lable: t('days.tue'),
			startTime: fromShiftTue,
			endTime: toShiftTue,
			checked: tueCheckBox
		},
		{
			lable: t('days.wed'),
			startTime: fromShiftWed,
			endTime: toShiftWed,
			checked: wedCheckBox
		},
		{
			lable: t('days.thu'),
			startTime: fromShiftThu,
			endTime: toShiftThu,
			checked: thuCheckBox
		},
		{
			lable: t('days.fri'),
			startTime: fromShiftFri,
			endTime: toShiftFri,
			checked: friCheckBox
		}
	];
	const weekEndData = [
		{
			lable: t('days.sat'),
			startTime: fromShiftSat,
			endTime: toShiftSat,
			checked: satCheckBox,
			showTitle: true
		},
		{
			lable: t('days.sun'),
			startTime: fromShiftSun,
			endTime: toShiftSun,
			checked: sunCheckBox
		}
	];

	const onClickCopy = () => {
		setCopyValueArr([]);
		if (
			monCheckBox ||
			tueCheckBox ||
			wedCheckBox ||
			thuCheckBox ||
			friCheckBox ||
			satCheckBox ||
			sunCheckBox
		) {
			if (monCheckBox && validateTiming(fromShiftMon, toShiftMon)) {
				const mon = dayObj('Mon', fromShiftMon, toShiftMon, monCheckBox);
				copyValueArr.push(mon);
			}
			if (tueCheckBox && validateTiming(fromShiftTue, toShiftTue)) {
				const tue = dayObj('Tue', fromShiftTue, toShiftTue, tueCheckBox);
				copyValueArr.push(tue);
			}
			if (wedCheckBox && validateTiming(fromShiftWed, toShiftWed)) {
				const wed = dayObj('Wed', fromShiftWed, toShiftWed, wedCheckBox);
				copyValueArr.push(wed);
			}
			if (thuCheckBox && validateTiming(fromShiftThu, toShiftThu)) {
				const thu = dayObj('Thu', fromShiftThu, toShiftThu, thuCheckBox);
				copyValueArr.push(thu);
			}
			if (friCheckBox && validateTiming(fromShiftFri, toShiftFri)) {
				const fri = dayObj('Fri', fromShiftFri, toShiftFri, friCheckBox);
				copyValueArr.push(fri);
			}
			if (satCheckBox && validateTiming(fromShiftSat, toShiftSat)) {
				const sat = dayObj('Sat', fromShiftSat, toShiftSat, satCheckBox);
				copyValueArr.push(sat);
			}
			if (sunCheckBox && validateTiming(fromShiftSun, toShiftSun)) {
				const sun = dayObj('Sun', fromShiftSun, toShiftSun, sunCheckBox);
				copyValueArr.push(sun);
			}
			setCopyArr(copyValueArr);
			setIsOpen(true);
		}
		if (
			validateTiming(fromShiftSun, toShiftSun) ||
			validateTiming(fromShiftMon, toShiftMon) ||
			validateTiming(fromShiftTue, toShiftTue) ||
			validateTiming(fromShiftWed, toShiftWed) ||
			validateTiming(fromShiftThu, toShiftThu) ||
			validateTiming(fromShiftFri, toShiftFri) ||
			validateTiming(fromShiftSat, toShiftSat)
		) {
			setShowCopy(true);
		}
	};
	const onClickCopyPromt = (day, st, et, isChecked) => {
		setCopyValueArr([]);
		const obj = dayObj(day, st, et, isChecked);
		copyValueArr.push(obj);
		setCopyArr(copyValueArr);
		setIsOpen(true);
		setShowCopy(true);
	};
	const onChangeSpecficHrsOn = () => {
		setSpecficHrsOnButton(true);
		setSpecficHrsOffButton(false);
		setShiftErr(false);
		props.scheduleTimings(getSchdlEmptyObj());
	};
	const onChangeSpecficHrsOff = () => {
		setSpecficHrsOffButton(true);
		setSpecficHrsOnButton(false);
		props.scheduleTimings(getSchdlObj());
	};
	const copyValue = (copy) => {
		const schdlObj = getSchdlObj();
		copyReferesh(schdlObj);
		copy.forEach((val) => {
			if ('Sun' === val.day) {
				schdlObj.sunStartTime = val.startTime;
				schdlObj.sunEndTime = val.endTime;
				props.scheduleTimings(schdlObj);
				setFromShiftSun(val.startTime);
				setToShiftSun(val.endTime);
				setSunCheckBox(val.checked);
			}
			if ('Mon' === val.day) {
				schdlObj.monStartTime = val.startTime;
				schdlObj.monEndTime = val.endTime;
				props.scheduleTimings(schdlObj);
				setFromShiftMon(val.startTime);
				setToShiftMon(val.endTime);
				setMonCheckBox(val.checked);
			}
			if ('Tue' === val.day) {
				schdlObj.tueStartTime = val.startTime;
				schdlObj.tueEndTime = val.endTime;
				props.scheduleTimings(schdlObj);
				setFromShiftTue(val.startTime);
				setToShiftTue(val.endTime);
				setTueCheckBox(val.checked);
			}
			if ('Wed' === val.day) {
				schdlObj.wedStartTime = val.startTime;
				schdlObj.wedEndTime = val.endTime;
				props.scheduleTimings(schdlObj);
				setFromShiftWed(val.startTime);
				setToShiftWed(val.endTime);
				setWedCheckBox(val.checked);
			}
			if ('Thu' === val.day) {
				schdlObj.thuStartTime = val.startTime;
				schdlObj.thuEndTime = val.endTime;
				props.scheduleTimings(schdlObj);
				setFromShiftThu(val.startTime);
				setToShiftThu(val.endTime);
				setThuCheckBox(val.checked);
			}
			if ('Fri' === val.day) {
				schdlObj.friStartTime = val.startTime;
				schdlObj.friEndTime = val.endTime;
				props.scheduleTimings(schdlObj);
				setFromShiftFri(val.startTime);
				setToShiftFri(val.endTime);
				setFriCheckBox(val.checked);
			}
			if ('Sat' === val.day) {
				schdlObj.satStartTime = val.startTime;
				schdlObj.satEndTime = val.endTime;
				props.scheduleTimings(schdlObj);
				setFromShiftSat(val.startTime);
				setToShiftSat(val.endTime);
				setSatCheckBox(val.checked);
			}
		});
		setShowCopy(false);
	};

	const copyReferesh = (schdlObj) => {
		if (sunCheckBox && !validateTiming(fromShiftSun, toShiftSun)) {
			schdlObj.tueStartTime = '';
			schdlObj.tueEndTime = '';
			props.scheduleTimings(schdlObj);
			setFromShiftSun('');
			setToShiftSun('');
			setSunCheckBox(false);
		}
		if (monCheckBox && !validateTiming(fromShiftMon, toShiftMon)) {
			schdlObj.tueStartTime = '';
			schdlObj.tueEndTime = '';
			props.scheduleTimings(schdlObj);
			setFromShiftMon('');
			setToShiftMon('');
			setMonCheckBox(false);
		}
		if (tueCheckBox && !validateTiming(fromShiftTue, toShiftTue)) {
			schdlObj.tueStartTime = '';
			schdlObj.tueEndTime = '';
			props.scheduleTimings(schdlObj);
			setFromShiftTue('');
			setToShiftTue('');
			setTueCheckBox(false);
		}
		if (wedCheckBox && !validateTiming(fromShiftWed, toShiftWed)) {
			schdlObj.wedStartTime = '';
			schdlObj.wedEndTime = '';
			props.scheduleTimings(schdlObj);
			setFromShiftWed('');
			setToShiftWed('');
			setWedCheckBox(false);
		}
		if (thuCheckBox && !validateTiming(fromShiftThu, toShiftThu)) {
			schdlObj.thuStartTime = '';
			schdlObj.thuEndTime = '';
			props.scheduleTimings(schdlObj);
			setFromShiftThu('');
			setToShiftThu('');
			setThuCheckBox(false);
		}
		if (friCheckBox && !validateTiming(fromShiftFri, toShiftFri)) {
			schdlObj.friStartTime = '';
			schdlObj.friEndTime = '';
			props.scheduleTimings(schdlObj);
			setFromShiftFri('');
			setToShiftFri('');
			setFriCheckBox(false);
		}
		if (satCheckBox && !validateTiming(fromShiftSat, toShiftSat)) {
			schdlObj.satStartTime = '';
			schdlObj.satEndTime = '';
			props.scheduleTimings(schdlObj);
			setFromShiftSat('');
			setToShiftSat('');
			setSatCheckBox(false);
		}
	};

	const getSchdlObj = () => {
		return {
			sunStartTime: fromShiftSun,
			sunEndTime: toShiftSun,
			monStartTime: fromShiftMon,
			monEndTime: toShiftMon,
			tueStartTime: fromShiftTue,
			tueEndTime: toShiftTue,
			wedStartTime: fromShiftWed,
			wedEndTime: toShiftWed,
			thuStartTime: fromShiftThu,
			thuEndTime: toShiftThu,
			friStartTime: fromShiftFri,
			friEndTime: toShiftFri,
			satStartTime: fromShiftSat,
			satEndTime: toShiftSat,
			anyHours: false,
			scr: 'AvailableHours',
			monCheckBox: monCheckBox,
			tueCheckBox: tueCheckBox,
			wedCheckBox: wedCheckBox,
			thuCheckBox: thuCheckBox,
			friCheckBox: friCheckBox,
			satCheckBox: satCheckBox,
			sunCheckBox: sunCheckBox
		};
	};
	const getSchdlEmptyObj = () => {
		return {
			sunStartTime: '',
			sunEndTime: '',
			monStartTime: '',
			monEndTime: '',
			tueStartTime: '',
			tueEndTime: '',
			wedStartTime: '',
			wedEndTime: '',
			thuStartTime: '',
			thuEndTime: '',
			friStartTime: '',
			friEndTime: '',
			satStartTime: '',
			satEndTime: '',
			anyHours: true,
			scr: 'AvailableHours',
			monCheckBox: monCheckBox,
			tueCheckBox: tueCheckBox,
			wedCheckBox: wedCheckBox,
			thuCheckBox: thuCheckBox,
			friCheckBox: friCheckBox,
			satCheckBox: satCheckBox,
			sunCheckBox: sunCheckBox
		};
	};

	useEffect(() => {
		if (
			validateTiming(fromShiftSun, toShiftSun) ||
			validateTiming(fromShiftMon, toShiftMon) ||
			validateTiming(fromShiftTue, toShiftTue) ||
			validateTiming(fromShiftWed, toShiftWed) ||
			validateTiming(fromShiftThu, toShiftThu) ||
			validateTiming(fromShiftFri, toShiftFri) ||
			validateTiming(fromShiftSat, toShiftSat)
		) {
			setCopyEnable(true);
		} else {
			setCopyEnable(false);
		}
		props.scheduleTimings(getSchdlObj());
		// eslint-disable-next-line
	}, [
		fromShiftSun,
		toShiftSun,
		fromShiftMon,
		toShiftMon,
		fromShiftTue,
		toShiftTue,
		fromShiftWed,
		toShiftWed,
		fromShiftThu,
		toShiftThu,
		fromShiftFri,
		toShiftFri,
		fromShiftSat,
		toShiftSat
	]);

	const scheduleAvail = (startTime, endTime, day, isChecked, isShftError, startChange) => {
		const schdlObj = getSchdlObj();
		if (isShftError) {
			setShiftErr(true);
		} else {
			setShiftErr(false);
		}
		if (day === t('days.sun')) {
			setFromShiftSun(startTime);
			setToShiftSun(startChange ? '' : endTime);
			setSunCheckBox(isChecked);
			schdlObj.sunStartTime = startTime;
			schdlObj.sunEndTime = endTime;
			schdlObj.sunCheckBox = isChecked;
			props.scheduleTimings(schdlObj);
			if (
				validateTiming(startTime, endTime) ||
				validateTiming(fromShiftMon, toShiftMon) ||
				validateTiming(fromShiftTue, toShiftTue) ||
				validateTiming(fromShiftWed, toShiftWed) ||
				validateTiming(fromShiftThu, toShiftThu) ||
				validateTiming(fromShiftFri, toShiftFri) ||
				validateTiming(fromShiftSat, toShiftSat)
			) {
				setCopyEnable(true);
			} else {
				setCopyEnable(false);
			}
		} else if (day === t('days.mon')) {
			setFromShiftMon(startTime);
			setToShiftMon(startChange ? '' : endTime);
			setMonCheckBox(isChecked);
			schdlObj.monStartTime = startTime;
			schdlObj.monEndTime = endTime;
			schdlObj.monCheckBox = isChecked;
			props.scheduleTimings(schdlObj);
			if (
				validateTiming(fromShiftSun, toShiftSun) ||
				validateTiming(startTime, endTime) ||
				validateTiming(fromShiftTue, toShiftTue) ||
				validateTiming(fromShiftWed, toShiftWed) ||
				validateTiming(fromShiftThu, toShiftThu) ||
				validateTiming(fromShiftFri, toShiftFri) ||
				validateTiming(fromShiftSat, toShiftSat)
			) {
				setCopyEnable(true);
			} else {
				setCopyEnable(false);
			}
		} else if (day === t('days.tue')) {
			setFromShiftTue(startTime);
			setToShiftTue(startChange ? '' : endTime);
			setTueCheckBox(isChecked);
			schdlObj.tueStartTime = startTime;
			schdlObj.tueEndTime = endTime;
			schdlObj.tueCheckBox = isChecked;
			props.scheduleTimings(schdlObj);
			if (
				validateTiming(fromShiftSun, toShiftSun) ||
				validateTiming(fromShiftMon, toShiftMon) ||
				validateTiming(startTime, endTime) ||
				validateTiming(fromShiftWed, toShiftWed) ||
				validateTiming(fromShiftThu, toShiftThu) ||
				validateTiming(fromShiftFri, toShiftFri) ||
				validateTiming(fromShiftSat, toShiftSat)
			) {
				setCopyEnable(true);
			} else {
				setCopyEnable(false);
			}
		} else if (day === t('days.wed')) {
			setFromShiftWed(startTime);
			setToShiftWed(startChange ? '' : endTime);
			setWedCheckBox(isChecked);
			schdlObj.wedStartTime = startTime;
			schdlObj.wedEndTime = endTime;
			schdlObj.wedCheckBox = isChecked;
			props.scheduleTimings(schdlObj);
			if (
				validateTiming(fromShiftSun, toShiftSun) ||
				validateTiming(fromShiftMon, toShiftMon) ||
				validateTiming(fromShiftTue, toShiftTue) ||
				validateTiming(startTime, endTime) ||
				validateTiming(fromShiftThu, toShiftThu) ||
				validateTiming(fromShiftFri, toShiftFri) ||
				validateTiming(fromShiftSat, toShiftSat)
			) {
				setCopyEnable(true);
			} else {
				setCopyEnable(false);
			}
		} else if (day === t('days.thu')) {
			setFromShiftThu(startTime);
			setToShiftThu(startChange ? '' : endTime);
			setThuCheckBox(isChecked);
			schdlObj.thuStartTime = startTime;
			schdlObj.thuEndTime = endTime;
			schdlObj.thuCheckBox = isChecked;
			props.scheduleTimings(schdlObj);
			if (
				validateTiming(fromShiftSun, toShiftSun) ||
				validateTiming(fromShiftMon, toShiftMon) ||
				validateTiming(fromShiftTue, toShiftTue) ||
				validateTiming(fromShiftWed, toShiftWed) ||
				validateTiming(startTime, endTime) ||
				validateTiming(fromShiftFri, toShiftFri) ||
				validateTiming(fromShiftSat, toShiftSat)
			) {
				setCopyEnable(true);
			} else {
				setCopyEnable(false);
			}
		} else if (day === t('days.fri')) {
			setFromShiftFri(startTime);
			setToShiftFri(startChange ? '' : endTime);
			setFriCheckBox(isChecked);
			schdlObj.friStartTime = startTime;
			schdlObj.friEndTime = endTime;
			schdlObj.friCheckBox = isChecked;
			props.scheduleTimings(schdlObj);
			if (
				validateTiming(fromShiftSun, toShiftSun) ||
				validateTiming(fromShiftMon, toShiftMon) ||
				validateTiming(fromShiftTue, toShiftTue) ||
				validateTiming(fromShiftWed, toShiftWed) ||
				validateTiming(fromShiftThu, toShiftThu) ||
				validateTiming(startTime, endTime) ||
				validateTiming(fromShiftSat, toShiftSat)
			) {
				setCopyEnable(true);
			} else {
				setCopyEnable(false);
			}
		} else if (day === t('days.sat')) {
			setFromShiftSat(startTime);
			setToShiftSat(startChange ? '' : endTime);
			setSatCheckBox(isChecked);
			schdlObj.satStartTime = startTime;
			schdlObj.satEndTime = endTime;
			schdlObj.satCheckBox = isChecked;
			props.scheduleTimings(schdlObj);
			if (
				validateTiming(fromShiftSun, toShiftSun) ||
				validateTiming(fromShiftMon, toShiftMon) ||
				validateTiming(fromShiftTue, toShiftTue) ||
				validateTiming(fromShiftWed, toShiftWed) ||
				validateTiming(fromShiftThu, toShiftThu) ||
				validateTiming(fromShiftFri, toShiftFri) ||
				validateTiming(startTime, endTime)
			) {
				setCopyEnable(true);
			} else {
				setCopyEnable(false);
			}
		}
	};

	const dispatch = useDispatch();
	useEffect(() => {
		const scheduleResponse = JSON.parse(JSON.stringify(schedule));
		if (isSchedule(scheduleResponse)) {
			const scheduleObj = scheduleResponse.availability.scheduleAvailability;
			if (scheduleObj.anyHours !== '') {
				if (scheduleObj.anyHours) {
					setSpecficHrsOnButton(true);
					props.scheduleTimings(getSchdlEmptyObj());
				} else {
					const schdStateObj = getSchdlObj();
					setSpecficHrsOffButton(true);
					schdStateObj.anyHours = false;
					if (scheduleObj.monStartTime !== '' && scheduleObj.monStartTime !== '--') {
						schdStateObj.monStartTime = scheduleObj.monStartTime;
						schdStateObj.monEndTime = scheduleObj.monEndTime;
						schdStateObj.monCheckBox = true;
						setFromShiftMon(scheduleObj.monStartTime);
						setToShiftMon(scheduleObj.monEndTime);
						setMonCheckBox(true);
					}
					if (scheduleObj.tueStartTime !== '' && scheduleObj.tueStartTime !== '--') {
						schdStateObj.tueStartTime = scheduleObj.tueStartTime;
						schdStateObj.tueEndTime = scheduleObj.tueEndTime;
						schdStateObj.tueCheckBox = true;
						setFromShiftTue(scheduleObj.tueStartTime);
						setToShiftTue(scheduleObj.tueEndTime);
						setTueCheckBox(true);
					}
					if (scheduleObj.wedStartTime !== '' && scheduleObj.wedStartTime !== '--') {
						schdStateObj.wedStartTime = scheduleObj.wedStartTime;
						schdStateObj.wedEndTime = scheduleObj.wedEndTime;
						schdStateObj.wedCheckBox = true;
						setFromShiftWed(scheduleObj.wedStartTime);
						setToShiftWed(scheduleObj.wedEndTime);
						setWedCheckBox(true);
					}
					if (scheduleObj.thuStartTime !== '' && scheduleObj.thuStartTime !== '--') {
						schdStateObj.thuStartTime = scheduleObj.thuStartTime;
						schdStateObj.thuEndTime = scheduleObj.thuEndTime;
						schdStateObj.thuCheckBox = true;
						setFromShiftThu(scheduleObj.thuStartTime);
						setToShiftThu(scheduleObj.thuEndTime);
						setThuCheckBox(true);
					}
					if (scheduleObj.friStartTime !== '' && scheduleObj.friStartTime !== '--') {
						schdStateObj.friStartTime = scheduleObj.friStartTime;
						schdStateObj.friEndTime = scheduleObj.friEndTime;
						schdStateObj.friCheckBox = true;
						setFromShiftFri(scheduleObj.friStartTime);
						setToShiftFri(scheduleObj.friEndTime);
						setFriCheckBox(true);
					}
					if (scheduleObj.satStartTime !== '' && scheduleObj.satStartTime !== '--') {
						schdStateObj.satStartTime = scheduleObj.satStartTime;
						schdStateObj.satEndTime = scheduleObj.satEndTime;
						schdStateObj.satCheckBox = true;
						setFromShiftSat(scheduleObj.satStartTime);
						setToShiftSat(scheduleObj.satEndTime);
						setSatCheckBox(true);
					}
					if (scheduleObj.sunStartTime !== '' && scheduleObj.sunStartTime !== '--') {
						schdStateObj.sunStartTime = scheduleObj.sunStartTime;
						schdStateObj.sunEndTime = scheduleObj.sunEndTime;
						schdStateObj.sunCheckBox = true;
						setFromShiftSun(scheduleObj.sunStartTime);
						setToShiftSun(scheduleObj.sunEndTime);
						setSunCheckBox(true);
					}
					props.scheduleTimings(schdStateObj);
				}
			}
		} else if (isPortrait && global.applicantId) {
			const scheduleObj = getSchdlEmptyObj();
			scheduleObj.anyHours = '';
			props.scheduleTimings(scheduleObj);
		}
		if (!props?.isRenew && isPortrait) {
			dispatch(
				subHeader({
					title: 'availability.schd',
					routeTo: editProfile.editAvailability ? '/edit-profile' : '/availability/shiftPreference',
					display: 'true'
				})
			);
		}
		if (
			isSchedule(scheduleResponse) &&
			(validateTiming(
				schedule.scheduleAvailability.sunStartTime,
				schedule.scheduleAvailability.sunEndTime
			) ||
				validateTiming(
					schedule.scheduleAvailability.monStartTime,
					schedule.scheduleAvailability.monEndTime
				) ||
				validateTiming(
					schedule.scheduleAvailability.tueStartTime,
					schedule.scheduleAvailability.tueEndTime
				) ||
				validateTiming(
					schedule.scheduleAvailability.wedStartTime,
					schedule.scheduleAvailability.wedEndTime
				) ||
				validateTiming(
					schedule.scheduleAvailability.thuStartTime,
					schedule.scheduleAvailability.thuEndTime
				) ||
				validateTiming(
					schedule.scheduleAvailability.friStartTime,
					schedule.scheduleAvailability.friEndTime
				) ||
				validateTiming(
					schedule.scheduleAvailability.satStartTime,
					schedule.scheduleAvailability.satEndTime
				))
		) {
			setCopyEnable(true);
		}
		// eslint-disable-next-line
	}, [schedule.availability]);

	const isSchedule = (scheduleResponse) => {
		return (
			!isEmptyObject(scheduleResponse) &&
			!isEmptyObject(scheduleResponse.availability) &&
			!isEmptyObject(scheduleResponse.availability.scheduleAvailability)
		);
	};

	const copyPrompt = (startTime, endTime, day, checked) => {
		const checkArr = [];
		if (monCheckBox) {
			checkArr.push('Mon');
		}
		if (tueCheckBox) {
			checkArr.push('Tue');
		}
		if (wedCheckBox) {
			checkArr.push('Wed');
		}
		if (thuCheckBox) {
			checkArr.push('Thu');
		}
		if (friCheckBox) {
			checkArr.push('Fri');
		}
		if (satCheckBox) {
			checkArr.push('Sat');
		}
		if (sunCheckBox) {
			checkArr.push('Sun');
		}
		if (checkArr.length === 1) {
			onClickCopyPromt(day, startTime, endTime, checked);
		}
	};

	const getClass = (availClass, renewClass) => {
		return !props.isRenew ? availClass : renewClass;
	};

	const scheduleAvailabilityTiming = (week) => {
		return (
		<div className={mystyle.avail}>
							{week.map((day, i) => (
								<div key={i} style={{ width: '100%' }}>
									{' '}
									<Day
										key={i}
										label={day.lable}
										checked={day.checked}
										startTime={day.startTime}
										endTime={day.endTime}
										scheduleAvail={scheduleAvail}
										copyPrompt={copyPrompt}
										isRenew={props.isRenew}
										showTitle={day.showTitle}
									/>
								</div>
							))}
						</div>

	);}

	return (
		<div className={getClass(mystyle.schedule, renewstyle.schedule)}>
			{props.isRenew && (
				<>
					<Divider />
					<Body as="p" size="small" UNSAFE_style={{ paddingTop: 20, paddingBottom: 20 }}>
						{t('renew.schdlBody')}
					</Body>
				</>
			)}

			{shiftErr && (
				<Alert variant="warning" style={{ marginBottom: alertBottom }}>
					{t('availability.shftTimeAlert')}
				</Alert>
			)}

			{!props.isRenew && (
				<Heading as="h1" size="medium" weight={700}>
					{t('availability.schdHeader')}
				</Heading>
			)}
			<div>
			<Radio
				label={t('availability.schdRadio')}
				name="work1"
				checked={specficHrsOnButton}
				onChange={onChangeSpecficHrsOn}
			/>

			<Caption as="div" size="small" UNSAFE_className={mystyle.schedule1}>
				{t('availability.schdBody')}
			</Caption>
			</div>
			{specficHrsOffButton ? (
				<div className={mystyle.alertPad}>
					<Radio
						label={t('availability.schdRadio1')}
						name="work2"
						checked={specficHrsOffButton}
						onChange={onChangeSpecficHrsOff}
					/>
					<Caption as="div" size="small" UNSAFE_className={mystyle.schedule1}>
						{t('availability.schdBody1')}
					</Caption>

					<div className={getClass(mystyle.alertPad, renewstyle.alertPad)}>
						<Alert variant="info">{t('availability.schdAlert')}</Alert>
					</div>
					<div className={getClass(mystyle.daysWrapper, renewstyle.daysWrapper)}>
						<div className={getClass(mystyle.link, renewstyle.link)}>
							<div>
								<Body as="p" size="medium" weight={700}>
									{t('availability.weekend')}
								</Body>
							</div>
							<div className={getClass(mystyle.copy, renewstyle.copy)}>
								<div>
									{copyEnable ? (
										<Button
											leading={
												<img
													src={require('../../../assets/images/copy.png')}
													alt="title can't be displayed"
													aria-hidden = {true}
													style={{ marginRight: 0, paddingTop: 4 }}
												/>
											}
											size="medium"
											variant="tertiary"
											onClick={onClickCopy}
											UNSAFE_style={{ padding: 0 }}
											aria-label={t('availability.copyAvailableHours')}>
											{t('availability.copyAvailableHours')}
										</Button>
									) : (
										<Button
											disabled={true}
											leading={
												<img
													disabled={true}
													src={require('../../../assets/images/copydisable.png')}
													alt="title can't be displayed"
													aria-hidden = {true}
													style={{ marginRight: 0, paddingTop: 4 }}
												/>
											}
											size="medium"
											variant="tertiary"
											UNSAFE_style={{ padding: 0 }}
											aria-label={t('availability.copyAvailableHours')}>
											{t('availability.copyAvailableHours')}
										</Button>
									)}
								</div>
							</div>
						</div>
						{scheduleAvailabilityTiming(weekEndData)}
						<div className={mystyle.weekends}>
							<Body as="p" size="medium" weight={700}>
								{t('availability.weekday')}
							</Body>
						</div>
						{scheduleAvailabilityTiming(weekDayData)}
					</div>
				</div>
			) : (
				<Radio
					label={t('availability.schdRadio1')}
					name="work2"
					checked={specficHrsOffButton}
					onChange={onChangeSpecficHrsOff}
				/>
			)}

			{showCopy ? (
				<CopyAvailableHoursPopUp
					copyValueArr={copyArr}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					copyValue={copyValue}
					showCopy={showCopy}></CopyAvailableHoursPopUp>
			) : (
				''
			)}
		</div>
	);
};

export default ScheduleAvailability;
