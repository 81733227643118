import { createSlice } from "@reduxjs/toolkit";
import { getAllFacilities, getListOfCities } from "./request";

const initialState = {
    location: {
        type: "", //zipCode or cityState
        value: "",
    },
    storesList: [],
    allCities: [],
    selectedPos: [],
    error: {},
    toggleStore: null,
    disabledScrollCheck: false,
    openCoLocationDialog: false,
    clickLocText: false,
    currentTab: true,
};


const isStoreSelected = (selectedPos, currentStore) => selectedPos.some((store) => store.storeNumber === currentStore.storeNumber);

const updateStoresListHelper = (state, action) => {
    // Update SelectedPos based on service response "checked":true
   action && action.forEach((store) => {
        store.checked && !isStoreSelected(state.selectedPos, store) && state.selectedPos.push(store);
    });
    // Update SelectedPos storeMiles while doing new search
    state.selectedPos = state.selectedPos.map(selectedStore => {
        const found = action.find(store => store.storeNumber === selectedStore.storeNumber);
        if (found) {
            selectedStore.storeMiles = found.storeMiles;
        } else {
            // Show no storeMiles if not found in current location
            selectedStore.storeMiles = null;
        }
        return selectedStore;
    });
};

export const locationSearchSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        updateLocationSearch: (state, action) => {
            state.location = action.payload;
        },
        updateStoresList: (state, action) => {
            state.storesList = action.payload;
            updateStoresListHelper(state, action.payload);
        },
        updateAllCities: (state, action) => {
            state.allCities = action.payload;
        },
        selectStore: (state, action) => {
            const currentStore = action.payload;
            
            if (isStoreSelected(state.selectedPos, currentStore)) return;
            state.selectedPos.push(currentStore);
            // Handle co-location selection
            if (currentStore.locationGroup) {
                state.storesList.forEach(store => {
                    if (store.locationGroup === currentStore.locationGroup &&
                        !isStoreSelected(state.selectedPos, store)) {
                        state.selectedPos.push(store);
                        state.openCoLocationDialog = true;
                    }
                })
            }
        },
        removeStore: (state, action) => {
            // Do Not allow to uncheck those that have got checked: True from service
            if (action.payload.checked) {
                return;
            }

            state.selectedPos = state.selectedPos.filter(
                (store) => store.storeNumber !== action.payload.storeNumber
            );
        },
        updateSelectedStore: (state, action) => {
            state.selectedPos = action.payload;
        },
        clearSelectedStore: (state) => {
            state.selectedPos = [];
        },
        setToggleStore: (state, action) => {
            state.toggleStore = action.payload;
        },
        setDisabledScrollCheck: (state, action) => {
            state.disabledScrollCheck = action.payload;
        },
        setOpenCoLocationDialog: (state, action) => {
            state.openCoLocationDialog = action.payload;
        },
        setClickLocText: (state, action) => {
            state.clickLocText = action.payload;
        },
        updateCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getListOfCities.fulfilled, (state, action) => {
            state.allCities = action.payload;
        });
        builder.addCase(getListOfCities.rejected, (state, action) => {
            state.error = { message: "something went wrong" };
        });
        builder.addCase(getAllFacilities.fulfilled, (state, action) => {
            state.storesList = action.payload.response;
            updateStoresListHelper(state, action.payload.response);
        });
        builder.addCase(getAllFacilities.rejected, (state, action) => {
            state.error = { message: "something went wrong" };
        });
    },
});

// Action creators are generated for each case reducer function
export const {
    updateLocationSearch,
    updateStoresList,
    updateAllCities,
    selectStore,
    removeStore,
    updateSelectedStore,
    clearSelectedStore,
    setToggleStore,
    setDisabledScrollCheck,
    setOpenCoLocationDialog,
    setClickLocText,
    updateCurrentTab
} = locationSearchSlice.actions;

export default locationSearchSlice.reducer;
