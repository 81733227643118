import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';
import { apiRequestErrorOccured, updateLoading } from '../slices/globalSlice';

const validateCityStateZipCombination = createAsyncThunk(
	'vjo/validateCityStateZipCombination',
	async (data, { dispatch }) => {
		dispatch(updateLoading(true));
		try {
			const baseURL = environment.hcServiceBasePostUrl + `/validateCityStateZipCombination`;
			const response = await axiosInstance.post(baseURL, data);
			dispatch(updateLoading(false));
			return response.data;
		} catch (error) {
			dispatch(apiRequestErrorOccured(error));
		}
	}
);

export { validateCityStateZipCombination };
