import React, { useEffect, useState } from 'react';
import style from '../EditProfileJobList/EditProfileJobList.module.css';
import {
	updateLicenseIndex,
	updateLicense,
	resetLicenseHistory
} from '../../../../redux/JobHistory/slice';
import { useMediaQuery } from 'react-responsive';
import {
	updateLoading,
	subHeader,
	executionErrorOccured
} from '../../../../redux/slices/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { descriptions, issuedby, states, allStates, countries } from '../../../../config/const';
import {
	Body,
	Button,
	Card,
	BottomSheet,
	Divider,
	Modal
} from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import 'moment/min/locales.min';

import {
	getCertificateLicenses,
	deleteCertificateLicenses
} from '../../../../redux/JobHistory/request';
import LicenseAndCertificationModal from '../../JobHistory/LicenseAndCertificationModal';

const EditProfileLicenseList = () => {
	const global = useSelector((state) => state.global);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isOpenLic, setIsOpenLic] = useState(false);
	const [licenseList, setLicenseList] = useState([]);
	const [editLicenseData, setEditLicenseData] = useState(false);
	const jobHistory = useSelector((state) => state.jobHistory);
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const [remove, setRemove] = useState(false);
	const [removeLicenseIndex, setRemoveLicenseIndex] = useState(0);
	const isDesktop = useMediaQuery({ minWidth: 851 });
	moment.locale(global.language === 'ES' ? 'es' : 'en');
	const jobHistoryRemove = 'jobHistory.remove';

	useEffect(() => {
		dispatch(
			subHeader({
				title: 'editProfile.certificates',
				routeTo: 'edit-profile',
				display: 'true'
			})
		);
		const requestInput = {
			applicantId: global.applicantId
		};

		setLicenseList([]);

		dispatch(resetLicenseHistory({ license: [] }));

		dispatch(getCertificateLicenses(requestInput))
			.then((res) => {
				const data = res.payload;

				if (data.certificationLicenses.length !== 0) {
					let licenCertList = [];
					data.certificationLicenses.forEach((obj) => {
						const license = {
							issuedType: obj.certIssuedBy,
							description: obj.certDescriptionCode,
							certYearIssued: obj.certYearIssued,
							certYearExpires: obj.certYearExpires,
							selectedCountry: obj.certIssuedCountry,
							selectedState: obj.certIssuedState,
							oldCertDescriptionCode: obj.oldCertDescriptionCode,
							oldCertIssuedBy: obj.oldCertIssuedBy,
							oldCertIssuedCountry: obj.oldCertIssuedCountry,
							oldCertIssuedState: obj.oldCertIssuedState,
							oldCertYearIssued: obj.oldCertYearIssued
						};
						dispatch(updateLicense({ license: license }));
						licenCertList.push(license);
					});
					setLicenseList(licenCertList);
				}

				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
		// eslint-disable-next-line
	}, []);

	const handleLicModal = (value) => {
		setEditLicenseData(false);
		setIsOpenLic(value);
	};

	const handleEditLicense = (license) => {
		const list = {
			issuedType: license.issuedType,
			description: license.description,
			certYearIssued: license.certYearIssued,
			certYearExpires: license.certYearExpires,
			selectedCountry: license.selectedCountry,
			selectedState: license.selectedState
		};

		const index = jobHistory.licenseIndex;
		const arr = [...licenseList];
		arr[index] = list;
		setLicenseList(arr);
		setEditLicenseData(false);
		setIsOpenLic(false);
	};

	const handleAddLicense = (license) => {
		const list = {
			issuedType: license.issuedType,
			description: license.description,
			certYearIssued: license.certYearIssued,
			certYearExpires: license.certYearExpires,
			selectedCountry: license.selectedCountry,
			selectedState: license.selectedState
		};
		if (list.issuedType !== '') {
			setLicenseList((licenseList) => [...licenseList, list]);
		}
		setEditLicenseData(false);
		setIsOpenLic(false);
	};

	const editLicense = (index) => {
		dispatch(updateLicenseIndex({ licenseIndex: index }));
		setEditLicenseData(true);
		setIsOpenLic(true);
	};

	const getDescriptionName = (value, country) => {
		let descriptionList;
		if (country === 'US') {
			descriptionList = descriptions[101];
		} else {
			descriptionList = descriptions[102];
		}
		let val = '';
		if (value === '') {
			return '';
		} else {
			descriptionList.forEach((obj) => {
				if (obj.value === value) {
					val = obj.viewValue;
				}
				return val;
			});
			return val;
		}
	};

	const getIssuedByDesc = (value, country) => {
		let issuedTypeList;
		if (country === 'US') {
			issuedTypeList = issuedby[101];
		} else {
			issuedTypeList = issuedby[102];
		}
		let val = '';
		if (value === '') {
			return '';
		} else {
			issuedTypeList.forEach((obj) => {
				if (obj.value === value) {
					val = obj.viewValue;
				}
				return val;
			});
			return val;
		}
	};

	const countryDetail = (code, country) => {
		let countrytList;
		if (country === 'US') {
			countrytList = countries[101];
		} else {
			countrytList = countries[102];
		}
		let val = '';
		if (code === '') {
			return '';
		} else {
			countrytList.forEach((obj) => {
				if (obj.value === code) {
					val = obj.viewValue;
				}
				return val;
			});
			return val;
		}
	};

	const stateDetail = (code, country, language) => {
		let stateList;
		if (country === 'US') {
			stateList = allStates;
		} else {
			if (language === 'US') {
				stateList = states[101];
			} else {
				stateList = states[102];
			}
		}
		let val = '';
		if (code === '') {
			return '';
		} else {
			stateList.forEach((obj) => {
				if (obj.value === code) {
					val = obj.viewValue;
				}
				return val;
			});
			return val;
		}
	};

	const getLicenseRequest = (action, index) => {
		const license = jobHistory.license[index];
		const request = {
			applSeqNbr: 0,
			applicantId: global.applicantId,
			certDescriptionCode: license.description,
			certDescriptionName: getDescriptionName(license.description, 'US'),
			certDescriptionSpanishName: getDescriptionName(license.description, 'PR'),
			certIssuedBy: license.issuedType,
			certIssuedByDesc: getIssuedByDesc(license.issuedType, 'US'),
			certIssuedBySpanishDesc: getIssuedByDesc(license.issuedType, 'PR'),
			certIssuedCountry: license.selectedCountry,
			certIssuedCountryDesc: countryDetail(license.selectedCountry, 'US'),
			certIssuedCountrySpanishDesc: countryDetail(license.selectedCountry, 'PR'),
			certIssuedState: license.selectedState,
			certIssuedStateDesc: stateDetail(license.selectedState, license.selectedCountry, 'US'),
			certIssuedStateSpanishDesc: stateDetail(license.selectedState, license.selectedCountry, 'PR'),
			certYearExpires: license.certYearExpires,
			certYearIssued: license.certYearIssued,
			certifLiceStatus: false,
			fromCTS: false,
			oldCertDescriptionCode: null,
			oldCertIssuedBy: null,
			oldCertIssuedCountry: null,
			oldCertIssuedState: null,
			oldCertYearIssued: null,
			saveMode: 'delete'
		};

		if (action === 'edit') {
			request.saveMode = 'edit';
		}

		return request;
	};

	const removeLicense = (index) => {
		const request = getLicenseRequest('delete', index);
		dispatch(updateLoading(true));
		dispatch(deleteCertificateLicenses(request))
			.then((res) => {
				const data = res.payload;
				if (data === true) {
					if (index !== -1) {
						licenseList.splice(index, 1);
					}
					setLicenseList((licenseList) => [...licenseList]);
					setRemove(false);
				}
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const handleAddNewLic = () => {
		setEditLicenseData(false);
		setIsOpenLic(true);
	};

	const removeLicenseModal = (index) => {
		setRemove(true);
		setRemoveLicenseIndex(index);
	};

	const getIssuedType = (item) => {
		let val;
		if (global.language === 'ES') {
			issuedby[102].forEach((obj) => {
				if (obj.value === item.issuedType) {
					val = obj.viewValue;
				}
			});
		} else {
			issuedby[101].forEach((obj) => {
				if (obj.value === item.issuedType) {
					val = obj.viewValue;
				}
			});
		}
		return val;
	};

	const getDescriptionDisplay = (item) => {
		let val;
		if (global.language === 'EN') {
			descriptions[101].forEach((obj) => {
				if (obj.value === item.description) {
					val = obj.viewValue;
				}
			});
		} else {
			descriptions[102].forEach((obj) => {
				if (obj.value === item.description) {
					val = obj.viewValue;
				}
			});
		}
		return val;
	};
	return (
		<div className={style.main}>
			<div className={style.container}>
				<div className={style.jobHistoryText}>
					<Body as="p" size="small" weight={400}>
						{t('jobHistory.historyText2')}
					</Body>
				</div>
				{licenseList.length !== 0
					? licenseList.map((item, index) => {
							return (
								<div style={{ margin: '16px' }} key={index}>
									<Card size="small" UNSAFE_className={style.licenseCard}>
										<div style={{ width: '100%' }}>
											<div style={{ float: 'left' }}>
												<Body as="p" size="large" weight={700}>
													{getIssuedType(item)}
												</Body>
											</div>
											<div className={isMobile ? style.mobileEdit : style.desktopEdit}>
												<div style={{ float: 'left' }}>
													<Button
														variant="tertiary"
														UNSAFE_style={{ padding: 0, height: 'auto' }}
														onClick={() => editLicense(index)}>
														{t('jobHistory.edit')}
													</Button>
												</div>

												<div style={{ float: 'right' }}>
													<Button
														variant="tertiary"
														UNSAFE_style={{ padding: 0, height: 'auto' }}
														onClick={() => removeLicenseModal(index)}>
														{t(jobHistoryRemove)}
													</Button>
												</div>
											</div>
										</div>
										<div style={{ width: '100%' }}>
											<div style={{ float: 'left' }}>
												<Body as="p" size="small" weight={700}>
													{getDescriptionDisplay(item)}
												</Body>
											</div>
										</div>
										<div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
											<div style={{ flexBasis: '50%' }}>
												<Body
													as="p"
													weight={400}
													UNSAFE_style={{ color: '#2E2F32', fontSize: '12px' }}>
													{t('jobHistory.issueDate')}
												</Body>
												<Body as="p" size="small" weight={400} UNSAFE_style={{ color: '#2E2F32' }}>
													{moment(item.certYearIssued, 'MM/YYYY', true).format('MMMM YYYY')}
												</Body>
											</div>
											<div style={{ flexBasis: '50%' }}>
												{moment(item.certYearExpires, 'MM/YYYY', true).isValid() ? (
													<>
														<Body
															as="p"
															weight={400}
															UNSAFE_style={{ color: '#2E2F32', fontSize: '12px' }}>
															{t('jobHistory.expirationDate')}
														</Body>
														<Body
															as="p"
															size="small"
															weight={400}
															UNSAFE_style={{ color: '#2E2F32' }}>
															{moment(item.certYearExpires, 'MM/YYYY', true).format('MMMM YYYY')}
														</Body>
													</>
												) : (
													''
												)}
											</div>
										</div>
									</Card>
								</div>
							);
					  })
					: ''}
				<div style={{ padding: '16px' }}>
					<Button size="medium" variant="secondary" onClick={handleAddNewLic}>
						{t('jobHistory.add')}
					</Button>
				</div>
				<LicenseAndCertificationModal
					isOpenLic={isOpenLic}
					handleLicModal={handleLicModal}
					handleAddLicense={handleAddLicense}
					licenseList={licenseList}
					editLicenseData={editLicenseData}
					handleEditLicense={handleEditLicense}
					getDescriptionName={getDescriptionName}
					getIssuedByDesc={getIssuedByDesc}
					countryDetail={countryDetail}
					stateDetail={stateDetail}
				/>

				{isDesktop && (
					<Modal
						isOpen={remove}
						onClose={() => setRemove(false)}
						title={t('jobHistory.removeLicense')}
						actions={
							<Button
								size="medium"
								variant="primary"
								isFullWidth
								onClick={() => removeLicense(removeLicenseIndex)}>
								{t(jobHistoryRemove)}
							</Button>
						}>
						<Body as="p" size="medium" weight={400}>
							{t('jobHistory.removeLicenseMessage')}
						</Body>
					</Modal>
				)}

				{isMobile && (
					<BottomSheet
						isOpen={remove}
						onClose={() => setRemove(false)}
						title={t('jobHistory.removeLicense')}
						actions={
							<Button
								size="medium"
								variant="primary"
								isFullWidth
								onClick={() => removeLicense(removeLicenseIndex)}>
								{t(jobHistoryRemove)}
							</Button>
						}>
						<Body as="p" size="medium" weight={400}>
							{t('jobHistory.removeLicenseMessage')}
						</Body>
					</BottomSheet>
				)}
			</div>
		</div>
	);
};

export default EditProfileLicenseList;
