import React, { useState, useEffect } from 'react';
import style from './JobHistory.module.css';
import {
	Select,
	Modal,
	BottomSheet,
	Button,
	TextField
} from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import { descriptions, issuedby, states, allStates, countries } from '../../../config/const';
import { useDispatch, useSelector } from 'react-redux';
import { updateLicense, updateEditLicense } from '../../../redux/JobHistory/slice';
import { executionErrorOccured, updateLoading } from '../../../redux/slices/globalSlice';
import { saveCertificateLicenses } from '../../../redux/JobHistory/request';
import moment from 'moment';
import { Calendar } from '@livingdesign/icons';
import { useDisplay } from 'utils/useDisplay';

const LicenseAndCertificationModal = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [issueDateError, setIssueDateError] = useState(false);
	const [expirationDateError, setExpirationDateError] = useState(false);
	const [issueDate, setIssueDate] = useState('');
	const [expirationDate, setExpirationDate] = useState('');
	const [countryList, setCountryList] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState('');
	const [stateList, setStateList] = useState([]);
	const [selectedState, setSelectedState] = useState('');
	const [allState, setAllState] = useState([]);
	const [descriptionList, setDescriptionList] = useState([]);
	const [description, setDescription] = useState('');
	const [issuedTypeList, setIssuedTypeList] = useState([]);
	const [issuedType, setIssuedType] = useState('');
	const global = useSelector((state) => state.global);
	const { isPortrait } = useDisplay();
	const jobHistory = useSelector((state) => state.jobHistory);
	const [previousData, setPreviousData] = useState({});

	useEffect(() => {
		setDescriptionList(global.language === 'ES' ? descriptions[102] : descriptions[101]);
		setIssuedTypeList(global.language === 'ES' ? issuedby[102] : issuedby[101]);
		setCountryList(global.language === 'ES' ? countries[102] : countries[101]);
		setStateList(global.language === 'ES' ? states[102] : states[101]);
		setAllState(allStates);
	}, [global.language]);

	useEffect(() => {
		if (props.editLicenseData) {
			const index = jobHistory.licenseIndex;
			if (jobHistory.license.length !== 0) {
				const license = jobHistory.license[index];
				let issue;

				issuedTypeList.forEach((obj) => {
					if (obj.value === license.issuedType) {
						issue = obj;
					}
				});
				setIssueDate(license.certYearIssued);
				setExpirationDate(license.certYearExpires);
				if (issue !== undefined) {
					setIssuedType(issue.value);
				} else {
					setIssuedType('');
				}
				let des;
				descriptionList.forEach((obj) => {
					if (obj.value === license.description) {
						des = obj;
					}
				});
				if (des !== undefined) {
					setDescription(des.value);
				} else {
					setDescription('');
				}
				setSelectedCountry(license.selectedCountry);
				setSelectedState(license.selectedState);
				setPreviousData({
					oldCertDescriptionCode: des !== undefined ? des.value : '',
					oldCertIssuedBy: issue !== undefined ? issue.value : '',
					oldCertIssuedCountry: license.selectedCountry,
					oldCertIssuedState: license.selectedState,
					oldCertYearIssued: license.certYearIssued
				});
			}
		}
		// eslint-disable-next-line
	}, [props.editLicenseData]);

	useEffect(() => {
		if (!props.editLicenseData) {
			setIssuedType('');
			setDescription('');
			setSelectedCountry('');
			setSelectedState('');
			setIssueDate('');
			setExpirationDate('');
		}
		// eslint-disable-next-line
	}, [props.isOpenLic]);

	const handleCountry = (e) => {
		const value = e.target.value;
		setSelectedCountry(value);
	};

	const handleIssuedType = (e) => {
		const value = e.target.value;
		setIssuedType(value);
	};

	const handleDescription = (e) => {
		const value = e.target.value;
		setDescription(value);
	};

	const handleState = (e) => {
		const value = e.target.value;
		setSelectedState(value);
	};

	const getDescriptionName = (value) => {
		let val = '';
		if (value === '') {
			return '';
		} else {
			descriptionList.map((obj) => {
				if (obj.value === value) {
					val = obj.viewValue;
				}
				return val;
			});
			return val;
		}
	};

	const getIssuedByDesc = (value) => {
		let val = '';
		if (value === '') {
			return '';
		} else {
			issuedTypeList.map((obj) => {
				if (obj.value === value) {
					val = obj.viewValue;
				}
				return val;
			});
			return val;
		}
	};

	const handleIssueDate = (e) => {
		let date = e.target.value.replace(/[^0-9\/']/gi, '');
		if (date.toString().length === 2 && e.nativeEvent.inputType !== 'deleteContentBackward') {
			date = date.concat('/');
		}
		setIssueDate(date);
		setIssueDateError(
			date &&
				date !== '' &&
				(!moment(date, 'MM/YYYY', true).isValid() ||
					moment(date, 'MM/YYYY', true) > moment() ||
					(moment(expirationDate, 'MM/YYYY', true).isValid() &&
						moment(date, 'MM/YYYY', true) > moment(expirationDate, 'MM/YYYY', true)) ||
					moment(date, 'MM/YYYY', true).year() <= 1899 ||
					moment(date, 'MM/YYYY', true).year() > 2099)
		);
	};

	const handleExpirationDate = (e) => {
		let date = e.target.value.replace(/[^0-9\/']/gi, '');

		if (date.toString().length === 2 && e.nativeEvent.inputType !== 'deleteContentBackward') {
			date = date.concat('/');
		}
		setExpirationDate(date);
		setExpirationDateError(
			date &&
				date !== '' &&
				(!moment(date, 'MM/YYYY', true).isValid() ||
					(moment(issueDate, 'MM/YYYY', true).isValid() &&
						moment(date, 'MM/YYYY', true) < moment(issueDate, 'MM/YYYY', true)) ||
					moment(date, 'MM/YYYY', true).year() <= 1899 ||
					moment(date, 'MM/YYYY', true).year() > 2099)
		);
	};

	const resetValuesLicense = () => {
		setIssuedType('');
		setDescription('');
		setIssueDate('');
		setExpirationDate('');
		setSelectedCountry('');
		setSelectedState('');
	};

	const addLicense = () => {
		const license = {
			issuedType: issuedType,
			description: description,
			certYearIssued: issueDate,
			certYearExpires: expirationDate,
			selectedCountry: selectedCountry,
			selectedState: selectedState
		};

		let request = {
			applSeqNbr: 0,
			applicantId: global.applicantId,
			certDescriptionCode: description,
			certDescriptionName: getDescriptionName(description),
			certIssuedBy: issuedType,
			certIssuedByDesc: getIssuedByDesc(issuedType),
			certIssuedCountry: selectedCountry,
			certIssuedState: selectedState,
			certYearExpires: expirationDate ? expirationDate : '',
			certYearIssued: issueDate,
			oldCertDescriptionCode: description,
			oldCertIssuedBy: issuedType,
			oldCertIssuedCountry: selectedCountry,
			oldCertIssuedState: selectedState,
			oldCertYearIssued: expirationDate ? expirationDate : '',
			saveMode: 'insert'
		};

		if (props.editLicenseData) {
			dispatch(updateEditLicense({ license: license }));
			request = {
				applSeqNbr: 0,
				applicantId: global.applicantId,
				certDescriptionCode: description,
				certDescriptionName: props.getDescriptionName(description, 'US'),
				certDescriptionSpanishName: props.getDescriptionName(description, 'PR'),
				certIssuedBy: issuedType,
				certIssuedByDesc: props.getIssuedByDesc(issuedType, 'US'),
				certIssuedBySpanishDesc: props.getIssuedByDesc(issuedType, 'PR'),
				certIssuedCountry: selectedCountry,
				certIssuedCountryDesc: props.countryDetail(selectedCountry, 'US'),
				certIssuedCountrySpanishDesc: props.countryDetail(selectedCountry, 'PR'),
				certIssuedState: selectedState,
				certIssuedStateDesc: props.stateDetail(selectedState, selectedCountry, 'US'),
				certIssuedStateSpanishDesc: props.stateDetail(selectedState, selectedCountry, 'PR'),
				certYearExpires: expirationDate ? expirationDate : '',
				certYearIssued: issueDate,
				certifLiceStatus: false,
				fromCTS: false,
				oldCertDescriptionCode: previousData.oldCertDescriptionCode,
				oldCertIssuedBy: previousData.oldCertIssuedBy,
				oldCertIssuedCountry: previousData.oldCertIssuedCountry,
				oldCertIssuedState: previousData.oldCertIssuedState,
				oldCertYearIssued: previousData.oldCertYearIssued,
				saveMode: 'edit'
			};
		} else {
			dispatch(updateLicense({ license: license }));
		}
		dispatch(updateLoading(true));
		dispatch(saveCertificateLicenses(request))
			.then((res) => {
				const data = res.payload;
				if (data.responseCode === '200' || data.responseCode === '201') {
					if (props.editLicenseData) {
						props.handleEditLicense(license);
						resetValuesLicense();
					} else {
						props.handleAddLicense(license);
						resetValuesLicense();
					}
				}

				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};
	const startDateInValidErrMsg = () => {
		if (
			!moment(issueDate, 'MM/YYYY', true).isValid() ||
			moment(issueDate, 'MM/YYYY', true).year() <= 1899 ||
			moment(issueDate, 'MM/YYYY', true).year() > 2099
		) {
			return t('jobHistory.dateFormat');
		} else if (
			moment(issueDate, 'MM/YYYY', true).isValid() &&
			moment(issueDate, 'MM/YYYY', true) > moment(expirationDate, 'MM/YYYY', true)
		) {
			return t('jobHistory.issueLessThanExpire');
		} else {
			return t('jobHistory.issueDateCannotBeFuture');
		}
	};
	const endDateInValidErrMsg =
		!moment(expirationDate, 'MM/YYYY', true).isValid() ||
		moment(expirationDate, 'MM/YYYY', true).year() <= 1899 ||
		moment(expirationDate, 'MM/YYYY', true).year() > 2099
			? t('jobHistory.dateFormat')
			: t('jobHistory.expireCannotBeBeforeIssue');

	const addLicenseContent = (
		<div className={style.contentContainer}>
			<div className={style.columnsGroup}>
				<Select
					label={t('jobHistory.issuedBy')}
					value={issuedType}
					selectProps={{
						'data-testid': 'jobHistory_issuedBy'
					}}
					role="combobox"
					aria-haspopup="listbox"
					onChange={(e) => {
						handleIssuedType(e);
					}}>
					<option value="" disabled aria-hidden={true}></option>
					{issuedTypeList.map((obj, index) => {
						return (
							<option
								id={'issuedType' + index}
								key={index}
								value={obj.value}
								data-testid={'issuedType_' + obj.value}
								aria-label={obj.viewValue}>
								{obj.viewValue}
							</option>
						);
					})}
				</Select>

				<Select
					label={t('jobHistory.description')}
					value={description}
					selectProps={{
						'data-testid': 'jobHistory_description'
					}}
					role="combobox"
					aria-haspopup="listbox"
					onChange={(e) => {
						handleDescription(e);
					}}>
					<option value="" disabled aria-hidden={true}></option>
					{descriptionList.map((obj, index) => {
						return (
							<option
								id={'industryList' + index}
								key={index}
								value={obj.value}
								data-testid={'industryList_' + obj.value}
								aria-label={obj.viewValue}>
								{obj.viewValue}
							</option>
						);
					})}
				</Select>
			</div>
			<div style={{ width: '100%', display: 'flex', gap: '16px' }}>
				<TextField
					type="text"
					name="startDate"
					label={t('jobHistory.issued')}
					placeholder={'MM/YYYY'}
					onChange={(e) => {
						handleIssueDate(e);
					}}
					textFieldProps={{
						maxLength: 7,
						'data-testid': 'jobHistory_startDate'
					}}
					leadingIcon={<Calendar size="medium" />}
					value={issueDate}
					error={issueDateError ? startDateInValidErrMsg() : ''}
				/>

				<TextField
					type="text"
					name="endDate"
					label={t('jobHistory.expired')}
					placeholder={'MM/YYYY'}
					onChange={(e) => {
						handleExpirationDate(e);
					}}
					textFieldProps={{
						maxLength: 7,
						'data-testid': 'jobHistory_endDate'
					}}
					leadingIcon={<Calendar size="medium" />}
					value={expirationDate}
					error={expirationDateError ? endDateInValidErrMsg : ''}
				/>
			</div>
			<div className={style.columnsGroup}>
				<Select
					label={t('jobHistory.countryIssued')}
					value={selectedCountry}
					selectProps={{
						'data-testid': 'jobHistory_countryIssued'
					}}
					role="combobox"
					aria-haspopup="listbox"
					onChange={(e) => {
						handleCountry(e);
					}}>
					<option value="" disabled aria-hidden={true}></option>
					{countryList.map((obj, index) => {
						return (
							<option
								id={'countryList' + index}
								key={index}
								value={obj.value}
								data-testid={'countryList_' + obj.value}
								aria-label={obj.viewValue}>
								{obj.viewValue}
							</option>
						);
					})}
				</Select>

				<Select
					label={t('jobHistory.stateIssued')}
					value={selectedState}
					selectProps={{
						'data-testid': 'jobHistory_stateIssued'
					}}
					role="combobox"
					aria-haspopup="listbox"
					onChange={(e) => {
						handleState(e);
					}}>
					<option value="" disabled aria-hidden={true}></option>
					{selectedCountry === 'US'
						? allState.map((obj, index) => {
								return (
									<option
										id={'selectedCountry' + index}
										key={index}
										value={obj.value}
										data-testid={'selectedCountry_' + obj.value}
										aria-label={obj.viewValue}>
										{obj.viewValue}
									</option>
								);
						  })
						: stateList.map((obj, index) => {
								return (
									<option
										id={'stateList' + index}
										key={index}
										value={obj.value}
										aria-label={obj.viewValue}>
										{obj.viewValue}
									</option>
								);
						  })}
				</Select>
			</div>
		</div>
	);

	const postAction = () => {
		return (
			<Button
				size="medium"
				variant="primary"
				UNSAFE_style={{ width: '100%' }}
				data-testid='jobHistory_addLicense'
				disabled={
					issueDateError ||
					expirationDateError ||
					issueDate === '' ||
					(expirationDate &&
						moment(expirationDate, 'MM/YYYY', true) < moment(issueDate, 'MM/YYYY', true)) ||
					issuedType === '' ||
					description === '' ||
					selectedCountry === '' ||
					selectedState === ''
				}
				onClick={addLicense}>
				{props.editLicenseData ? t('jobHistory.save') : t('jobHistory.add')}
			</Button>
		);
	};
	return (
		<div>
			{!isPortrait && (
				<Modal
					size="medium"
					isOpen={props.isOpenLic}
					onClose={() => props.handleLicModal(false)}
					title={props.editLicenseData ? t('jobHistory.editLicense') : t('jobHistory.license')}
					closeButtonProps={{
						'data-testid': 'jobHistory_closeButton'
					}}
					actions={postAction()}>
					{addLicenseContent}
				</Modal>
			)}
			{isPortrait && (
				<BottomSheet
					isOpen={props.isOpenLic}
					onClose={() => props.handleLicModal(false)}
					title={props.editLicenseData ? t('jobHistory.editLicense') : t('jobHistory.license')}
					closeButtonProps={{
						'data-testid': 'jobHistory_closeButton'
					}}
					actions={postAction()}>
					{addLicenseContent}
				</BottomSheet>
			)}
		</div>
	);
};

export default LicenseAndCertificationModal;
