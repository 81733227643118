import { useMediaQuery } from "react-responsive";

/**
 * Custom hook to get the display view of the screen.
 *
 * @returns - isLandscape: Desktop, iPad landscape view
 * @returns - isPortrait: Mobile, iPad portrait view
 * @returns - isMobile: Mobile only view
 */
export const useDisplay = () => {
  const isDesktop = useMediaQuery({ minWidth: 851 });
  const iPadLandscape = useMediaQuery({
    minWidth: 426,
    maxWidth: 850,
    orientation: "landscape",
  });
  const iPadPortrait = useMediaQuery({
    minWidth: 426,
    maxWidth: 850,
    orientation: "portrait",
  });
  const isMobile = useMediaQuery({ maxWidth: 425 });
  return {
    isLandscape: isDesktop || iPadLandscape,
    isPortrait: isMobile || iPadPortrait,
    isMobile,
  };
};
