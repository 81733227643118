import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cookie from 'react-cookies';
import {
	executionErrorOccured,
	loggedInApplicantId,
	loggedInEmailId,
	onLogOut,
	subHeader,
	updateGlobalDialog,
	updateLanguage,
	updateLoading,
	updateTimeZone
} from '../../redux/slices/globalSlice';
import { environment } from '../../utils/environment/environment';
import style from './Welcome.module.scss';
import ApplicationPanel from './ApplicationPanel/ApplicationPanel';
import {
	deviceType,
	osName,
	osVersion,
	browserName,
	browserVersion,
	mobileVendor,
	mobileModel
} from 'react-device-detect';
import axiosInstance from '../../utils/axiosInstance';

import { Divider } from '@walmart-web/livingdesign-components';
import { checkRegisteredUser, getStepInd } from '../../redux/Account/request';
import { isEmptyObject } from '../../utils/validation';
import { getPreference } from 'redux/Preference/request';
import { updateSelectedStore } from 'redux/Location/slice';
import {
	updateGlobalAvailability,
	setIsEmploymentTypeUpdateNeeded,
	setAreShiftsUpdateNeeded,
	updateSelectedAndAutoFilledEmploymentType,
	updateSelectedAndAutoFilledShiftPreference
} from 'redux/slices/availMainSlice';
import {
	updateCurrentSelectedJobs,
	updateJobSearchResponse,
	updateSelectedJobs,
	updateShiftsAutoFilled
} from 'redux/slices/jobSearchSlice';
import {
	isShiftUpdateNeeded,
	updateEditAvailability,
	isScheduleUpdateNeeded
} from 'redux/EditProfile/Slice';
import { navigateLocation, setWotcCompleted } from 'redux/Renew/slice';
import { AvailabilityHelper } from 'pages/Availability/AvailabilityHelper';
import { useDisplay } from 'utils/useDisplay';
import CutOverModal from '../../components/CutOverModal/CutOverModal';
import { saveAvailabilityPreference } from 'redux/Availability/request';
import { callHiringHelper } from 'redux/HiringHelper/request';
import { getAutoFillCookieInfo } from 'utils/availabilityCookieUtils';
import { DISCLAIMER_VERSION_NBR } from '../../config/const';
import { useRedirect } from 'utils/useRedirect';

const Welcome = () => {
	const [showWelcome, setShowWelcome] = React.useState(false);
	const global = useSelector((state) => state.global);
	const account = useSelector((state) => state.account);
	const availability = useSelector((state) => state.availMain);
	const [loadedCheckRegistered, setLoadedCheckRegistered] = React.useState(false);
	const [loadedGetPreference, setLoadedGetPreference] = React.useState(false);
	const { isNavigateLocation } = useSelector((state) => state?.renew);
	const { empTypeObj } = AvailabilityHelper();
	const dispatch = useDispatch();
	const { isPortrait } = useDisplay();
	const [showModal, setShowModal] = React.useState(false);
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const isEmploymentTypeUpdateNeeded = availability?.isEmploymentTypeUpdateNeeded;
	const isShiftIdsUpdateNeeded = availability?.areShiftsUpdateNeeded;
	const { loadCookiesAndUpdateRedirectStore, handleRedirect } = useRedirect();
	const [allPreferencesLoaded, setAllPreferencesLoaded] = React.useState(false);

	const state = global.location.loaded
		? global.location.coordinates.state.slice(0, 2).toUpperCase()
		: null;

	const logDeviceInfo = async (applId) => {
		const deviceKeyInfo = 'DEVICE-' + deviceType.toUpperCase() + '-' + osName;
		const otherInfo =
			' v' +
			osVersion +
			'|Browser=' +
			browserName +
			' ' +
			browserVersion +
			'|Model=' +
			mobileVendor +
			' ' +
			mobileModel +
			'|Resolution=' +
			window.screen.width +
			'X' +
			window.screen.height +
			', PixelRatio-' +
			window.devicePixelRatio +
			'|Applicant Id=' +
			applId;

		const postObj = {
			eventOccurred: deviceKeyInfo,
			logInfo: otherInfo
		};
		await axiosInstance.post(environment.hcServiceBasePostUrl + '/logSplunkD', postObj);
	};

	const getSelected = (data) => {
		let tempArray = [];
		data.map((index) => {
			if (index.jobPreferred === 'Y') {
				tempArray = [...tempArray, index];
			}
		});
		return tempArray;
	};
	useEffect(() => {
		dispatch(updateLoading(true));
		if (loadedCheckRegistered && loadedGetPreference) {
			dispatch(updateLoading(false));
		}
	}, [loadedCheckRegistered, loadedGetPreference]);
	useEffect(() => {
		let applId = global.applicantId;
		let emailId = global.emailId;
		if (!applId || applId === 0) {
			applId = cookie.load('userId') ? cookie.load('userId') : 0;
			dispatch(loggedInApplicantId(applId));
		}
		if (!emailId || emailId === '') {
			emailId = cookie.load('emailId') ? cookie.load('emailId') : '';
			dispatch(loggedInEmailId(emailId));
		}
		if (!applId || applId === 0 || !emailId || emailId === '') {
			if (environment.isPingfed === 'true') {
				window.location.replace(environment.pingFedLogoutUrl);
			} else {
				navigate('../disclaimer');
			}
		}
	}, [global.applicantId, global.emailId, dispatch, navigate]);

	const refreshLogin = async (emailId, applId) => {
		logDeviceInfo(applId);
		setLoadedCheckRegistered(false);
		let tz = global.timeZone;
		if (!tz) {
			tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
			dispatch(updateTimeZone(tz));
		}
		dispatch(
			checkRegisteredUser({
				emailId: emailId,
				applicantId: applId,
				timeZone: tz,
				disclaimerVersion: DISCLAIMER_VERSION_NBR,
				countryCode: global.location.loaded
					? global.location.coordinates.country.slice(0, 2)
					: null,
				latitude: global.location.loaded ? global.location.coordinates.lat : null,
				longitude: global.location.loaded ? global.location.coordinates.long : null,
				state: state,
				city: global.location.loaded ? global.location.coordinates.city : null,
				deviceType: deviceType,
				osName: osName,
				osVersion: osVersion,
				browserName: browserName,
				browserVersion: browserVersion,
				mobileVendor: mobileVendor,
				mobileModel: mobileModel
			})
		).then(() => {
			setLoadedCheckRegistered(true);
		});
	};
	useEffect(() => {
		dispatch(subHeader({ title: '', routeTo: '', display: false }));
		const applId = global.applicantId;
		const emailId = global.emailId;

		if (!(!applId || applId === 0 || !emailId || emailId === '')) {
			i18n.changeLanguage(
				(cookie.load('languagePref') ? cookie.load('languagePref') : 'EN').toLowerCase()
			);
			dispatch(updateLanguage(cookie.load('languagePref') ? cookie.load('languagePref') : 'EN'));
			refreshLogin(emailId, applId);
		}
	}, [global.applicantId, global.emailId, global.timeZone, dispatch, navigate]);

	useEffect(() => {
		const applId = global.applicantId;
		const emailId = global.emailId;

		if (!(!applId || applId === 0 || !emailId || emailId === '')) {
			loadPreference(applId);
		}
	}, [global.applicantId, global.emailId]);

	const loadPreference = (applId) => {
		setLoadedGetPreference(false);
		dispatch(getPreference(applId))
			.then(async (res) => {
				const data = res.payload.response;
				if (data && res.payload.responseCode === 200) {
					dispatch(
						updateSelectedStore(
							data.preferenceLocation.map((store) => ({ ...store, checked: true }))
						)
					);
					// Update preferred stores & jobs from cookies
					const { isCpRedirect, preferredStores } = loadCookiesAndUpdateRedirectStore();
					// if isCpRedirect -> Redirect user to /location-search
					if (isCpRedirect) {
						await handleRedirect(preferredStores);
					}
					setLoadedGetPreference(true);
					const selectedJobsByCandidate = getSelected(data.preferrableJob);
					dispatch(updateSelectedJobs(selectedJobsByCandidate));
					dispatch(updateCurrentSelectedJobs(selectedJobsByCandidate));
					dispatch(updateJobSearchResponse(null));
					dispatch(updateGlobalAvailability(data.availability));
					dispatch(updateEditAvailability(false));
					dispatch(isShiftUpdateNeeded(false));
					dispatch(isScheduleUpdateNeeded(false));
					const shiftIds = [
						...new Set(
							[]
								.concat(...selectedJobsByCandidate.map((i) => i.shiftIds).filter(Boolean))
								.concat(
									...selectedJobsByCandidate.map((i) => i.teamingShiftIndList).filter(Boolean)
								)
						)
					];
					const employmentTypes = [
						...new Set(
							[].concat(...selectedJobsByCandidate.map((i) => i.employmentTypes).filter(Boolean))
						)
					];
					const selectedEmpType = { ...data.availability.employmentType };
					let empTypeUpdateNeeded = false;
					const autoFillEmpDeclined = getAutoFillCookieInfo('employmentTypeAutoFillDeclined');
					const autoFillShiftDeclined = getAutoFillCookieInfo('shiftTypeAutoFillDeclined');
					new Promise((resolve) => {
						if (!autoFillEmpDeclined) {
							employmentTypes.forEach((empType, index, array) => {
								if (!data.availability.employmentType[empTypeObj[empType]]) {
									selectedEmpType[empTypeObj[empType]] = true;
									empTypeUpdateNeeded = true;
								}
								if (index === array.length - 1) resolve();
							});
							if (empTypeUpdateNeeded) {
								dispatch(updateSelectedAndAutoFilledEmploymentType(selectedEmpType));
								dispatch(setIsEmploymentTypeUpdateNeeded(true));
							}
						}
					});

					if (
						!autoFillShiftDeclined &&
						shiftIds.some((r) => data.availability.shiftPreference.shift.indexOf(r) < 0)
					) {
						const selectedShifts = [
							...new Set([...data.availability.shiftPreference.shift, ...shiftIds])
						];
						dispatch(updateShiftsAutoFilled(true));
						dispatch(setAreShiftsUpdateNeeded(true));
						dispatch(updateSelectedAndAutoFilledShiftPreference({ shift: selectedShifts }));
					}
				}
				setAllPreferencesLoaded(true);
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	useEffect(() => {
		cookie.save(
			'applicantName',
			account?.data?.auth?.applicantName ? account?.data?.auth?.applicantName : ''
		);
		const techBypassLoginDuringOutage =
			cookie.load('fromApplicantLogin') === 'Y' && account.data.auth.responseCode === '503';

		if(!isEmptyObject(account.data) &&
           			!isEmptyObject(account.data.auth) && account.data.applProfileSteps[9]){
                        dispatch(setWotcCompleted(true))
             }

		if (
			!isEmptyObject(account.data) &&
			!isEmptyObject(account.data.auth) &&
			(account.data.auth.responseCode === '0' || techBypassLoginDuringOutage) &&
			account.data.auth.virtualJob
		) {
			dispatch(updateLoading(true));
			const vjoApplicantId = account.data.auth.vjo.applicantId;
			const jobOfferNbr = account.data.auth.vjo.jobOfferNbr;
			dispatch(
				getStepInd({
					applicantId: vjoApplicantId,
					jobOfferNbr: jobOfferNbr
				})
			)
				.then(() => dispatch(updateLoading(false)))
				.then(() => {
					if (account.data.auth.vjo.nextStepInd < 10) {
						navigate('../virtual-job-offer');
					} else if (
						account.data.auth.vjo.nextStepInd >= 10 &&
						account.data.auth.expiredApplDate &&
						account.data.auth.stepNbr >= 400
					) {
						navigate('../renew');
					} else if (
						account.data.auth.vjo.nextStepInd >= 10 &&
						account.data.applProfileSteps.status === 'COMPLETED'
					) {
						navigate('../post-apply-dashboard');
					}
				});
		} else if (
			!isEmptyObject(account.data) &&
			!isEmptyObject(account.data.auth) &&
			(account.data.auth.responseCode === '0' || techBypassLoginDuringOutage) &&
			account.data.auth.expiredApplDate &&
			account.data.auth.stepNbr >= 400
		) {
			navigate('../renew');
		} else if (account.data && account.data.auth && isNavigateLocation) {
			dispatch(navigateLocation(false));
			navigate('../location-search');
		} else if (account.data.applProfileSteps[11] && !account.data.applProfileSteps[9]) {
			navigate('../wotc-questionnaire')
		} else if (
			account.data &&
			account.data.auth &&
			(account.data.auth.responseCode === '0' || techBypassLoginDuringOutage) &&
			account.data.applProfileSteps.status === 'COMPLETED'
		) {
			navigate('../post-apply-dashboard');
		} else if (!isEmptyObject(account.data.auth)) {
			if (account.data.auth.responseCode === '300') {
				dispatch(updateLoading(false));
				dispatch(
					updateGlobalDialog({
						open: true,
						title: 'createProfile.rehire',
						message: account.data.auth.responseMessage
					})
				);
			} else if (
				cookie.load('fromApplicantLogin') !== 'Y' &&
				account.data.auth.responseCode === '503'
			) {
				dispatch(onLogOut());
			} else {
				setShowWelcome(true);
			}
		}
	}, [account.data.auth]);

	useEffect(() => {
		const alreadyShown = cookie.load('autoFillCutoverModalAlreadyShown');
		if (
			alreadyShown !== 'Y' &&
			showWelcome &&
			(isEmploymentTypeUpdateNeeded || isShiftIdsUpdateNeeded)
		) {
			setShowModal(true);
		}
	}, [showWelcome, isEmploymentTypeUpdateNeeded, isShiftIdsUpdateNeeded]);

	const goToEmpTypeOrShiftPrefPage = () => {
		setShowModal(false);
		dispatch(setIsEmploymentTypeUpdateNeeded(false));
		dispatch(setAreShiftsUpdateNeeded(false));
		const request = {
			applicantId: global.applicantId,
			jobType: global.jobType,
			employmentType: availability.selectedAndAutoFilledEmpTypes,
			shiftPreference: availability.selectedAndAutoFilledshiftPreference
		};
		dispatch(saveAvailabilityPreference(request)).then(() => {
			dispatch(callHiringHelper(global.applicantId));
			cookie.save('autoFillCutoverModalAlreadyShown', 'Y');
			if (isEmploymentTypeUpdateNeeded) {
				isPortrait ? navigate('../availability/employmentType') : navigate('../availability');
			} else {
				isPortrait ? navigate('../availability/shiftPreference') : navigate('../availability');
			}
		});
	};

	return (
		<div className={style.welcome}>
			{allPreferencesLoaded && showWelcome && (
				<React.Fragment>
					<div className={style.welcome__heading}>
						<div className={style.welcome__heading__content}>
							<div className={style.welcome__heading__content__title}>{t('general.welcome')}</div>
							<div className={style.welcome__heading__content__subtitle}>
								{t('welcomePage.subTitle')}
							</div>
							<Divider className={style.welcome__heading__content__palette}></Divider>
							{/* <div className={style.welcome__heading__content__palette}></div> */}
						</div>
					</div>
					<div className={style.welcome__heading__blue}></div>
					<div className={style.welcome__body}>
						<div className={style.welcome__body__content}>
							<ApplicationPanel></ApplicationPanel>
						</div>
					</div>
				</React.Fragment>
			)}
			<CutOverModal
				isOpen={showModal}
				onClose={goToEmpTypeOrShiftPrefPage}
				text={'availability.autoFillCutOverPopUpMessage'}
			/>
		</div>
	);
};

export default Welcome;
