import React, { useEffect, useState } from 'react';
import {
	TextField,
	BottomSheet,
	Button,
	Modal,
	Checkbox,
	Caption
} from '@walmart-web/livingdesign-components';
import { useDispatch, useSelector } from 'react-redux';
import styles from './EditProfile.module.css';
import { useTranslation } from 'react-i18next';
import { validateMobile } from '../../../utils/validation';
import { updateCheckHire } from '../../../redux/AboutYou/slice';
import { updateLoading, onLogOut, executionErrorOccured } from '../../../redux/slices/globalSlice';
import { aboutApplicantStep0, checkRehire } from '../../../redux/AboutYou/request';
import PreEmployMentModal from '../AboutYou/AboutYouPersonalInfo/PreEmploymentModal';
import ExitModal from '../AboutYou/AboutYouPersonalInfo/ExitModal';
import { callHiringHelper } from '../../../redux/HiringHelper/request';
import cookie from 'react-cookies';
import { updateApplicantName } from '../../../redux/Account/slice';
import { useDisplay } from '../../../utils/useDisplay';
import { sendGtmEvent } from '../../../utils/GoogleTagManagerUtils';
import { GTM } from '../../../config/const';

const EditProfileModal = (props) => {
	const { t } = useTranslation();
	const aboutYou = useSelector((state) => state.aboutYou);
	const global = useSelector((state) => state.global);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [prefferedFirstName, setprefferedFirstName] = useState('');
	const [prefferedLastName, setprefferedLastName] = useState('');
	const [zipCode, setZipCode] = useState('');
	const [zipError, setZipError] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [alternatePhoneNumber, setAlternatePhoneNumber] = useState('');
	const [personalInfo, setPersonalInfo] = useState({});
	const [error, setError] = useState({});
	const [altError, setAltError] = useState({});
	const dispatch = useDispatch();
	const [exit, setExit] = useState(false);
	const [exitText, setExitText] = useState('');
	const [rehireStatus, setRehireStatus] = useState(false);
	const [sendWelcomeMessage, setSendWelcomeMessage] = useState(true);
	const [initOptIn, setInitOptIn] = useState(true);
	const countryCode = global.countryCode === 'PR' ? 'PR' : 'US';
	const { isPortrait } = useDisplay();
	const [shouldHHTrigger, setShouldHHTrigger] = useState(false);
	const account = useSelector((state) => state.account);
	const createAccountKey = 'createAccount.';

	const postObj = {
		agree: false,
		agreeDis: true,
		applStatusCode: 300,
		applicantId: global.applicantId,
		checkRehire: aboutYou.aboutYouReq.checkRehire,
		countryCode: countryCode,
		firstName: firstName,
		formerAssociate: false,
		identificationNbr: '',
		isChanged: true,
		lastName: lastName,
		preferredFirstName: prefferedFirstName,
		preferredLastName: prefferedLastName,
		middleName: personalInfo.middleName,
		valid: true,
		partialDob: personalInfo.partialDob,
		partSsnNbr: personalInfo.partSsnNbr,
		zipCode: zipCode,
		phoneNumber: phoneNumber
			.replaceAll('(', '')
			.replaceAll(')', '')
			.replaceAll(' ', '')
			.replaceAll('-', ''),
		alternatePhoneNumber: alternatePhoneNumber
			.replaceAll('(', '')
			.replaceAll(')', '')
			.replaceAll(' ', '')
			.replaceAll('-', ''),
		sendWelcomeMessage: sendWelcomeMessage
	};
	const { identificationNbr, partSsnNbr, partialDob } = personalInfo;
	const canCheckRehire =
		identificationNbr?.length !== 9 && partSsnNbr?.length === 6 && partialDob?.length === 4;
	const aboutYouPersonalInfoMbile = 'aboutYouPersonalInfo.mobile';

	useEffect(() => {
		if (props.drawerOpen) {
			const data = props.personalInfo;
			setPersonalInfo(data);
			setFirstName(data.firstName);
			setLastName(data.lastName);
			setprefferedFirstName(data.preferredFirstName);
			setprefferedLastName(data.preferredLastName);
			setZipCode(data.zipCode);
			dispatch(
				validateMobile(data.phoneNumber.replaceAll('-', ''), error, setError, setPhoneNumber)
			);
			if (data.alternatePhoneNumber && data.alternatePhoneNumber !== '') {
				dispatch(
					validateMobile(
						data.alternatePhoneNumber.replaceAll('-', ''),
						altError,
						setAltError,
						setAlternatePhoneNumber
					)
				);
			}

			data.hcTestResponse.forEach((obj) => {
				if (obj.questionNbr === 40 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
					if (obj.answerNbr === 12) {
						setSendWelcomeMessage(false);
						setInitOptIn(false);
					}
				}
			});
		}
		// eslint-disable-next-line
	}, [props.drawerOpen]);

	const onChange = (event, eventName) => {
		switch (eventName) {
			case 'phoneNumber':
				dispatch(validateMobile(event.target.value, error, setError, setPhoneNumber));
				break;
			case 'alternatePhoneNumber':
				dispatch(
					validateMobile(event.target.value, altError, setAltError, setAlternatePhoneNumber, true)
				);
				break;
			default:
				break;
		}
	};

	const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F\s\,\.\-\'].*$/;
	const handleFirstName = (e) => {
		let value = e.target.value;
		if (value === '') {
			setFirstName('');
		}
		value = removeInvalidChars(value);

		setFirstName(value.slice(0, 15));
	};

	const removeInvalidChars = (value) => {
		let check = value;
		if (countryCode === 'PR') {
			check = check.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F\s\,\.\-\'']/gi, '');
		} else {
			check = check.replace(/[^\w\s\.\-\'']/gi, '');
		}
		check = check.replace(/^\s+/g, '');
		check = check.replace(/\d+/g, '');
		check = check.replace(/_/g, '');
		return check.toUpperCase();
	};

	const handleLastName = (e) => {
		let value = e.target.value;
		if (value === '') {
			setLastName('');
		}
		value = removeInvalidChars(value);

		setLastName(value.slice(0, 20));

		dispatch(updateCheckHire({ checkRehire: canCheckRehire }));
	};

	const handlePrefferedFirstName = (e) => {
		const value = e.target.value;
		if (value === '') {
			setprefferedFirstName('');
		}
		if (!regex.test(value)) {
			return;
		} else {
			setprefferedFirstName(removeInvalidChars(value).slice(0, 15));
		}
	};

	const handlePrefferedLastName = (e) => {
		const value = e.target.value;
		if (value === '') {
			setprefferedLastName('');
		}
		if (!regex.test(value)) {
			return;
		} else {
			setprefferedLastName(removeInvalidChars(value).slice(0, 20));
		}
	};

	const handleZipCode = (e) => {
		setZipError('');
		const regex = /^[0-9]*$/;
		const value = e.target.value;
		if (value === '') {
			setZipCode('');
		}
		if (!regex.test(value)) {
			return;
		} else {
			setZipCode(value);
			setShouldHHTrigger(true);
		}
		dispatch(updateCheckHire({ checkRehire: canCheckRehire }));
	};
	const handlePreEmployment = (value) => {
		let ans;
		if (value.yes) {
			ans = 'Y';
		} else {
			ans = 'N';
		}
		const requestInput = {
			formerAssociate: ans,
			applicantId: global.applicantId,
			lastName: lastName,
			partialDob: personalInfo.partialDob,
			partSsnNbr: personalInfo.partSsnNbr,
			zipCode: zipCode,
			applStatusCode: 0
		};

		dispatch(updateLoading(true));
		dispatch(checkRehire(requestInput))
			.then((res) => {
				if (res.payload.responseCode === '201' || res.payload.responseCode === '200') {
					setRehireStatus(false);
					props.handlePersonalInfo();
				} else if (res.payload.responseCode === '300') {
					const responseText = res.payload.responseMessage.split('#@#');
					if (global.language === 'ES') {
						setExitText(responseText[2]);
					} else {
						setExitText(responseText[1]);
					}
					setRehireStatus(false);
					setExit(true);
				}

				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const handleExit = (value) => {
		setExit(value);
		dispatch(onLogOut());
	};
	const handleModal = (value) => {
		setRehireStatus(false);
	};

	const handleClose = () => {
		props.handleDrawer(false);
		setFirstName('');
		setLastName('');
		setprefferedFirstName('');
		setprefferedLastName('');
		setZipCode('');
		setPhoneNumber('');
		setAlternatePhoneNumber('');
		setZipError('');
	};
	const pushEventsMetric = () => {
		let eventsToSend = [];
		if (postObj.firstName !== personalInfo.firstName) {
			eventsToSend.push(GTM.EVENTS.EDIT_PROFILE_FIRST_NAME);
		}
		if (postObj.lastName !== personalInfo.lastName) {
			eventsToSend.push(GTM.EVENTS.EDIT_PROFILE_LAST_NAME);
		}
		if (postObj.preferredFirstName !== personalInfo.preferredFirstName) {
			eventsToSend.push(GTM.EVENTS.EDIT_PROFILE_PREF_FIRST_NAME);
		}
		if (postObj.preferredLastName !== personalInfo.preferredLastName) {
			eventsToSend.push(GTM.EVENTS.EDIT_PROFILE_PREF_LAST_NAME);
		}
		if (postObj.zipCode !== personalInfo.zipCode) {
			eventsToSend.push(GTM.EVENTS.EDIT_PROFILE_ZIP);
		}
		if (
			postObj.phoneNumber !==
			personalInfo.phoneNumber
				.replaceAll('(', '')
				.replaceAll(')', '')
				.replaceAll(' ', '')
				.replaceAll('-', '')
		) {
			eventsToSend.push(GTM.EVENTS.EDIT_PROFILE_MOBILE);
		}

		if (
			postObj.alternatePhoneNumber !==
			personalInfo.alternatePhoneNumber
				.replaceAll('(', '')
				.replaceAll(')', '')
				.replaceAll(' ', '')
				.replaceAll('-', '')
		) {
			eventsToSend.push(GTM.EVENTS.EDIT_PROFILE_ALT_CONTACT);
		}

		if (sendWelcomeMessage !== initOptIn) {
			eventsToSend.push(GTM.EVENTS.EDIT_PROFILE_OPT_IN_TEXT);
		}

		eventsToSend.forEach((event) => {
			sendGtmEvent(event);
		});
	};
	const handleButton = (e) => {
		pushEventsMetric();
		dispatch(updateLoading(true));
		dispatch(aboutApplicantStep0(postObj))
			.then((res) => {
				cookie.save('applicantName', firstName);
				if (res.payload.responseCode === undefined) {
					if (res.payload.response.data.responseCode === '444') {
						setZipError('renew.zipError');
					}
				} else if (res.payload.responseCode === '201' || res.payload.responseCode === '200') {
					dispatch(
						updateApplicantName(
							prefferedFirstName && prefferedFirstName !== '' ? prefferedFirstName : firstName
						)
					);
					if (res.payload.checkRehire) {
						dispatch(updateLoading(false));
						props.handleDrawer(false);
						setRehireStatus(true);
					}
					if (shouldHHTrigger && account?.data?.auth?.stepNbr >= 850) {
						dispatch(callHiringHelper(global.applicantId));
						setShouldHHTrigger(false);
					}
					props.handleDrawer(false);
					props.handlePersonalInfo();
				}

				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	return (
		<div>
			{!isPortrait && (
				<Modal
					isOpen={props.drawerOpen}
					onClose={handleClose}
					title={t('editProfile.title')}
					size="medium"
					actions={
						<Button
							size="medium"
							variant="primary"
							onClick={handleButton}
							isFullWidth
							disabled={
								firstName === '' ||
								lastName === '' ||
								zipCode === '' ||
								phoneNumber === '' ||
								zipCode.length !== 5 ||
								(alternatePhoneNumber !== '' && altError?.altContact) ||
								error?.mobile
							}>
							{t('editProfile.save')}
						</Button>
					}>
					<div className={styles.editProfileModal}>
						<div className={styles.columns}>
							<TextField
								type="text"
								name="firstName"
								label={t('editProfile.firstName')}
								onChange={(e) => handleFirstName(e)}
								value={firstName}
							/>

							<TextField
								type="text"
								name="lastName"
								label={t('editProfile.lastName')}
								onChange={(e) => handleLastName(e)}
								value={lastName}
							/>
						</div>
						<div className={styles.columns}>
							<TextField
								type="text"
								name="preferedFirstName"
								label={t('editProfile.preferedFirstName')}
								onChange={(e) => handlePrefferedFirstName(e)}
								value={prefferedFirstName}
							/>

							<TextField
								type="text"
								name="preferedLastName"
								label={t('editProfile.preferedLastName')}
								onChange={(e) => handlePrefferedLastName(e)}
								value={prefferedLastName}
							/>
						</div>
						<div className={styles.columns}>
							<TextField
								type="search"
								name="zip"
								label={t('editProfile.zipCode')}
								textFieldProps={{
									maxLength: 5
								}}
								onChange={(e) => handleZipCode(e)}
								value={zipCode}
								error={zipError !== '' ? t(zipError) : ''}
							/>
						</div>
						<div className={styles.columns}>
							<TextField
								type="text"
								name="mobile"
								label={t(aboutYouPersonalInfoMbile)}
								onChange={(e) => onChange(e, 'phoneNumber')}
								value={phoneNumber}
								error={error && error.mobile ? t(createAccountKey + error.mobile) : ''}
							/>
							<TextField
								type="text"
								name="alternateContactNumber"
								label={t('aboutYouPersonalInfo.alternateContactNumber')}
								onChange={(e) => onChange(e, 'alternatePhoneNumber')}
								value={alternatePhoneNumber}
								error={
									alternatePhoneNumber !== '' && altError && altError.altContact
										? t(createAccountKey + altError.altContact)
										: ''
								}
							/>
						</div>

						<Checkbox
							data-testid="checkbox"
							label={t('createAccount.acceptMobileText')}
							value={sendWelcomeMessage}
							checked={sendWelcomeMessage ? 'T' : ''}
							name="agree"
							type="checkbox"
							onChange={() => {
								setSendWelcomeMessage(!sendWelcomeMessage);
							}}
						/>

						<Caption as="div" size="small" weight={400} UNSAFE_className={styles.optInLegal}>
							{t('createAccount.legalCaption1')}
							<a
								target="_blank"
								rel="noreferrer"
								href="https://corporate.walmart.com/privacy-security">
								{t('createAccount.legalCaptionLink')}
							</a>
							{t('createAccount.legalCaption2')}
						</Caption>
					</div>
				</Modal>
			)}

			{isPortrait && (
				<BottomSheet
					isOpen={props.drawerOpen}
					onClose={handleClose}
					title={t('editProfile.title')}
					actions={
						<Button
							size="medium"
							variant="primary"
							isFullWidth
							onClick={handleButton}
							disabled={
								firstName === '' ||
								lastName === '' ||
								zipCode === '' ||
								phoneNumber === '' ||
								zipCode.length !== 5 ||
								(alternatePhoneNumber !== '' && altError?.altContact) ||
								error?.mobile
							}>
							{t('editProfile.save')}
						</Button>
					}>
					<div className={styles.editProfileModal}>
						<TextField
							type="text"
							name="firstName"
							label={t('editProfile.firstName')}
							onChange={(e) => handleFirstName(e)}
							value={firstName}
						/>

						<TextField
							type="text"
							name="lastName"
							label={t('editProfile.lastName')}
							onChange={(e) => handleLastName(e)}
							value={lastName}
						/>
						<TextField
							type="text"
							name="preferedFirstName"
							label={t('editProfile.preferedFirstName')}
							onChange={(e) => handlePrefferedFirstName(e)}
							value={prefferedFirstName}
						/>

						<TextField
							type="text"
							name="preferedLastName"
							label={t('editProfile.preferedLastName')}
							onChange={(e) => handlePrefferedLastName(e)}
							value={prefferedLastName}
						/>
						<TextField
							type="search"
							name="zip"
							label={t('editProfile.zipCode')}
							textFieldProps={{
								maxLength: 5
							}}
							onChange={(e) => handleZipCode(e)}
							value={zipCode}
							error={zipError !== '' ? zipError : ''}
						/>
						<TextField
							type="text"
							name="mobile"
							label={t(aboutYouPersonalInfoMbile)}
							onChange={(e) => onChange(e, 'phoneNumber')}
							value={phoneNumber}
							error={error && error.mobile ? t(createAccountKey + error.mobile) : ''}
						/>
						<TextField
							type="text"
							name="alternateContactNumber"
							label={t('aboutYouPersonalInfo.alternateContactNumber')}
							onChange={(e) => onChange(e, 'alternatePhoneNumber')}
							value={alternatePhoneNumber}
							error={
								alternatePhoneNumber !== '' && altError && altError.altContact
									? t(createAccountKey + altError.altContact)
									: ''
							}
						/>

						<Checkbox
							data-testid="checkbox"
							label={t('createAccount.acceptMobileText')}
							value={sendWelcomeMessage}
							checked={sendWelcomeMessage ? 'T' : ''}
							name="agree"
							type="checkbox"
							onChange={() => {
								setSendWelcomeMessage(!sendWelcomeMessage);
							}}
						/>
						<Caption as="div" size="small" weight={400} UNSAFE_className={styles.optInLegal}>
							{t('createAccount.legalCaption1')}
							<a
								target="_blank"
								rel="noreferrer"
								href="https://corporate.walmart.com/privacy-security">
								{t('createAccount.legalCaptionLink')}
							</a>
							{t('createAccount.legalCaption2')}
						</Caption>
					</div>
				</BottomSheet>
			)}
			{rehireStatus && (
				<PreEmployMentModal
					rehireStatus={rehireStatus}
					handleModal={handleModal}
					handlePreEmployment={handlePreEmployment}
				/>
			)}
			{exit && <ExitModal exit={exit} handleExit={handleExit} exitText={exitText} />}
		</div>
	);
};

export default EditProfileModal;
