import { createSlice } from "@reduxjs/toolkit";
import  {getMilitaryInfo,saveMilitaryExp} from "./request"
 
const initialState = {
   
    militaryInfo: {
        applicantId: "",
        answersList: [
            {
                questionCategoryCd: 1,
                responseDate: "",
                questionNumber: 1,
                optionNumber: "",
                responseTxt: "",
            },
            {
                questionCategoryCd: 1,
                responseDate: "",
                questionNumber: 2,
                optionNumber: 3,
                responseTxt: "",
            },
            {
                questionCategoryCd: 1,
                responseDate: "",
                questionNumber: 3,
                optionNumber: 3,
                responseTxt: "",
            },
            {
                questionCategoryCd: 1,
                responseDate: "",
                questionNumber: 4,
                optionNumber: 3,
                responseTxt: "",
            },
            {
                questionCategoryCd: 1,
                responseDate: "",
                questionNumber: 5,
                optionNumber: 8,
                responseTxt: "",
            },
            {
                questionCategoryCd: 1,
                responseDate: "",
                questionNumber: 51,
                optionNumber: 3,
                responseTxt: "",
            },
        ],
    },
};

export const militarySlice = createSlice({
    name: "military",
    initialState,
    reducers: {
        updateMilitaryInfo: (state, action) => {
            state.militaryInfo = action.payload;
        },
        updateApplicantIdandDate: (state, action) => {
            state.militaryInfo.applicantId = action.payload.applicantId;
            state.militaryInfo.answersList.map(item=>item.responseDate=action.payload.dateString)
        },
        updateMilitaryAnswer: (state, action) => {
            let ques = action.payload.ques;
            let ans = action.payload.ans;
            let answer = state.militaryInfo.answersList.find(
                (res) => res.questionNumber === ques
            );
            answer.optionNumber = ans;
            state.militaryInfo = { ...state.militaryInfo };
        },
        updateResponseText: (state, action) => {
            
            let ques = action.payload.ques;
            let ans = action.payload.ans;
            let answer = state.militaryInfo.answersList.find(
                (res) => res.questionNumber === ques
            );
            answer.responseTxt = ans;
            state.militaryInfo = { ...state.militaryInfo };
        }
     
    },
    extraReducers(builder) {
        builder.addCase(getMilitaryInfo.fulfilled, (state, action) => {
            state.applInfo = action.payload;
        });
        builder.addCase(saveMilitaryExp.fulfilled, (state, action) => {
            state.applInfo = action.payload;
        });
    }
});

export const { updateMilitaryInfo,updateMilitaryAnswer,updateApplicantIdandDate ,updateResponseText} = militarySlice.actions;

export default militarySlice.reducer;
