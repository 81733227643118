import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './PasswordMain.module.scss';
import { Button, Heading, Body, Card, Divider } from '@walmart-web/livingdesign-components';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { updateResetPassword } from '../../../redux/Account/slice';
import { isEmpty, isEmptyObject } from '../../../utils/validation';

const SentVerifyEmail = () => {
	const isDesktop = useMediaQuery({ minWidth: 851 });
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const account = useSelector((state) => state.account);

	const redoIcon = (
		<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.5 8.09375C15.5 4.64197 12.6965 1.84375 9.23815 1.84375C5.77982 1.84375 2.97629 4.64197 2.97629 8.09375C2.97629 8.09377 2.97629 8.09379 2.97629 8.09381L2.35225 7.47089C2.17895 7.29791 1.90993 7.2787 1.71536 7.41323L1.64622 7.47089C1.47292 7.64386 1.45367 7.91236 1.58846 8.10656L1.64622 8.17558L3.14393 9.67045C3.31723 9.84342 3.58624 9.86264 3.78081 9.7281L3.84995 9.67045L5.34766 8.17558C5.54262 7.98098 5.54262 7.66548 5.34766 7.47089C5.17436 7.29791 4.90535 7.2787 4.71078 7.41323L4.64163 7.47089L3.97277 8.13855C3.97409 8.12379 3.97476 8.10885 3.97476 8.09375C3.97476 5.19237 6.33126 2.84033 9.23815 2.84033C12.145 2.84033 14.5015 5.19237 14.5015 8.09375C14.5015 10.9951 12.145 13.3472 9.23815 13.3472C7.66279 13.3472 6.20189 12.6529 5.20739 11.4722C5.02995 11.2616 4.71503 11.2344 4.50399 11.4115C4.29294 11.5886 4.2657 11.9029 4.44313 12.1136C5.62538 13.5171 7.36482 14.3438 9.23815 14.3438C12.6965 14.3438 15.5 11.5455 15.5 8.09375ZM3.52045 8.59005C3.50565 8.59137 3.49067 8.59204 3.47553 8.59204C3.47549 8.59204 3.47546 8.59204 3.47542 8.59204L3.49694 8.61352L3.52045 8.59005Z"
				fill="black"
			/>
		</svg>
	);

	useEffect(() => {
		dispatch(
			updateResetPassword({
				nextScreen: 'ResetPassword',
				nextPath: '/verifyEmailLink/*',
				status: 'PENDING'
			})
		);
	}, []);

	return (
		<React.Fragment>
			<Card UNSAFE_className={style.card}>
				<div className={`${style.header}`}>
					<Heading size={isDesktop ? 'medium' : 'small'} as="h1" color="#2E2F32">
						{t('recoverPasswordPage.forgotPasswordTitle')}
					</Heading>
					<Body
						size="medium"
						as="div"
						weight="400"
						color="#2E2F32"
						UNSAFE_className={style.body_font}>
						{t('sendVerifyEmailPage.sendVerifyEmailSubTitle1')}
						<br />
						<b style={{ display: 'flex', placeContent: 'center' }}>
							{!isEmptyObject(account.data.resetPassword) &&
								!isEmpty(account.data.resetPassword.resetEmail) &&
								account.data.resetPassword.resetEmail}
						</b>
						<br />
						{t('sendVerifyEmailPage.sendVerifyEmailSubTitle2')}
					</Body>
				</div>
				<div className={style.body}>
					<Divider UNSAFE_className={style.divider}></Divider>
				</div>
				<div className={style.footer}>
					<Body size="medium" as="p" weight="400" color="#2E2F32">
						{t('sendVerifyEmailPage.didntReceiveEmail')}
					</Body>
					<Button
						size="medium"
						variant="secondary"
						isFullWidth
						disabled={false}
						type="button"
						color="#2E2F32"
						trailing={redoIcon}
						onClick={() => navigate('../recoverPassword')}>
						{t('sendVerifyEmailPage.resendResetLinkButton')}
					</Button>
				</div>
			</Card>
		</React.Fragment>
	);
};

export default SentVerifyEmail;
