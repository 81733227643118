import React from 'react';
import style from '../ThankYouScreen.module.scss';
import { Body, Card, CardContent, CardMedia, Heading } from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { selfScheduleEventCodeMap } from 'config/const';

const ThankYouScreen = () => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery({ maxWidth: 650 });
	const selfSchedule = useSelector((state) => state.selfSchedule);
	const global = useSelector((state) => state.global);

	let startDate = selfSchedule.isSelfScheduleSC
		? selfSchedule.confirmedSlot.startTime.slice(0, 10)
		: moment(selfSchedule.confirmedSlot.date, 'dddd, MM-DD-YYYY', true);
	let startTime = selfSchedule.isSelfScheduleSC
		? moment(selfSchedule.confirmedSlot.startTime.slice(11), 'HH:mm:ss').format('LT')
		: moment(selfSchedule.confirmedSlot.startTime).format('LT');

	if (
		!selfSchedule.isDirectlyThankYouScreen &&
		(selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_1 ||
			selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_2)
	) {
		startDate = moment(selfSchedule.confirmedSlot.date, 'MM-DD-YYYY', true);
		startTime = selfSchedule.confirmedSlot.startTime;
	}
	const formattedPhoneNumber = selfSchedule.phoneNumber
		? selfSchedule.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3!')
		: '';
	const virtualMeetText = `${t('selfSchedule.NoStoreVisit')} ${formattedPhoneNumber}`;
	const isPrescreenEvent =
		selfSchedule.eventCode === selfScheduleEventCodeMap.SH_PRESCREEN_EVENT_CODE;
	return (
		<div className={style.thankyou_container}>
			<div className={style.thankyou_screen_parent}>
				<div className={style.thankyou_top_layout}></div>
			</div>
			<div className={style.thankyou_bottom_layout}>
				<div style={{ padding: '16px 0' }}>
					<Heading
						as="h1"
						size="medium"
						weight={700}
						UNSAFE_style={{ color: '#FFFF' }}
						UNSAFE_className={style.thankyou_text}>
						{t('selfSchedule.thankYou')}
					</Heading>
					{!isPrescreenEvent ? (
						<Body
							as="p"
							size="medium"
							weight={400}
							UNSAFE_style={{ color: '#FFFF' }}
							UNSAFE_className={style.thankyou_subtext}>
							{t('selfSchedule.thankYou1SC')}
						</Body>
					) : (
						<Body
							as="p"
							size="medium"
							weight={400}
							UNSAFE_style={{ color: '#FFFF' }}
							UNSAFE_className={style.thankyou_subtext}>
							{t('selfSchedule.thankYou1')}
						</Body>
					)}
				</div>
				<Card size="large">
					<CardMedia>
						<img
							src={
								isMobile
									? require('../../../../src/assets/images/celebration_mobile.png')
									: require('../../../../src/assets/images/celebration_rectangle.png')
							}
							alt="Celebration"
							className={style.celebration_img}
							width="100%"
						/>
					</CardMedia>
					<CardContent className={style.card_Item}>
						<div className={style.thankyou_screen_content}>
							<div className={style.thankyou_screen_info}>
								<strong>{`${moment(startDate)
									.locale(global.language === 'ES' ? 'es' : 'en')
									.format('dddd, MM-DD-YYYY')} @ ${startTime} (${t(
									selfSchedule.duration
								)})`}</strong>
							</div>
							{!isPrescreenEvent ? (
								<div className={style.thankyou_screen_content_sc}>
									<br />
									<strong>{t('selfSchedule.at')}</strong>
									<br />
									<strong>{selfSchedule.storeAddress}</strong>
								</div>
							) : (
								<p>{virtualMeetText}</p>
							)}
							<div className={style.thankyou_screen_footer_text}>{t('selfSchedule.thankYou2')}</div>
						</div>
					</CardContent>
				</Card>
			</div>
		</div>
	);
};

export default ThankYouScreen;
