import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './PasswordMain.module.scss';
import { Button, Heading, Body, Card, TextField } from '@walmart-web/livingdesign-components';
import { isEmpty, isEmptyObject, validateConfirmPassword, validatePassword } from '../../../utils/validation';
import { Eye, EyeSlash } from '@livingdesign/icons';
import { useMediaQuery } from 'react-responsive';
import { updateResetPassword } from '../../../redux/Account/slice';
import { onSubmitResetPassword, validateResetPasswordUrl } from './PasswordHelper';
import { updateTimeZone } from 'redux/slices/globalSlice';
import { DISCLAIMER_VERSION_NBR } from '../../../config/const';

const ResetPassword = () => {

	const [isValidResetPasswordLink, setIsValidResetPasswordLink] = React.useState(false);
	const [error, setError] = React.useState({});
	const [textFieldType, setTextFieldType] = React.useState({
		password: 'password',
		confirmPassword: 'password'
	});
	const [password, setPassword] = React.useState('');
	const [confirmPassword, setConfirmPassword] = React.useState('');
	const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);
	const global = useSelector((state) => state.global);
	const isDesktop = useMediaQuery({ minWidth: 851 });
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { encryptedId } = useParams();

	useEffect(() => {
		dispatch(
			updateResetPassword({
				nextScreen: 'PasswordSuccess',
				nextPath: '/passwordSuccess',
				status: 'PENDING'
			})
		);
		dispatch(validateResetPasswordUrl(encryptedId, navigate, setIsValidResetPasswordLink));
	}, []);

	useEffect(() => {
		if (isEmpty(password) || isEmpty(confirmPassword)) {
			setIsSubmitDisabled(true);
		} else {
			setIsSubmitDisabled(false);
		}
	}, [password, confirmPassword]);

	const onChange = (event, eventName) => {
		if (eventName && eventName === 'password') {
			setPassword(event.target.value);
			validatePassword(event.target.value, error, setError, confirmPassword);
		} else if (eventName && eventName === 'confirmPassword') {
			setConfirmPassword(event.target.value);
			validateConfirmPassword(event.target.value, password, error, setError);
		}
	};

	const onToggleInputType = (event, eventName) => {
		if (eventName && eventName === 'password') {
			const passwordType = textFieldType.password === 'password' ? 'text' : 'password';
			setTextFieldType({ ...textFieldType, password: passwordType });
		} else if (eventName && eventName === 'confirmPassword') {
			const confirmPasswordType = textFieldType.confirmPassword === 'password' ? 'text' : 'password';
			setTextFieldType({ ...textFieldType, confirmPassword: confirmPasswordType });
		}
	};

	const onSubmit = React.useCallback(
		async (event) => {
			event.preventDefault();
			let tz = global.timeZone;
			if(!tz) {
				tz=Intl.DateTimeFormat().resolvedOptions().timeZone;
				dispatch(updateTimeZone(tz));
			}
			const request = {
				password: password,
				confirmPassword: confirmPassword,
				encryptedId: encryptedId,
				uniqueApplicationId: 'walMarT-Store-HirinG',
				timeZone: tz,
				disclaimerVersion: DISCLAIMER_VERSION_NBR
			};
			dispatch(onSubmitResetPassword(request, error, setError, navigate));
		},
		[password, confirmPassword, error]
	);

	return (
		<React.Fragment>
			{isValidResetPasswordLink &&
				<Card UNSAFE_className={style.card}>
					<div className={style.header}>
						<Heading size={isDesktop ? 'medium' : 'small'} as="h1" color="#2e2f32">
							{t("verifyEmailLinkPage.enterNewPasswordText")}
						</Heading>
					</div>
					<div className={`${style.body} ${style.flex_gap_wrapper}`}>
						<Body size="medium" as="p" weight="400" color="#2e2f32">
							{t("createAccount.passwordHint")}
						</Body>
						<div className={style.content}>
							{/* <Body size="small">{t("verifyEmailLinkPage.password")}</Body> */}
							<TextField
								// leadingIcon={<Lock />}
								trailing={
									textFieldType.password === 'password' ? (
										<Eye size="medium" style={{padding: '16px'}} onClick={(event) => onToggleInputType(event, 'password')} />
									) : (
										<EyeSlash
											size="medium" style={{padding: '16px'}}
											onClick={(event) => onToggleInputType(event, 'password')}
										/>
									)
								}
								name="password"
								type={textFieldType.password}
								label={t("verifyEmailLinkPage.password")}
								value={password}
								onChange={(event) => onChange(event, 'password')}
								error={error && error.pdErrMsg ? t('createAccount.' + error.pdErrMsg) : ''}
							/>
						</div>
						<div className={style.content}>
							{/* <Body size="small">{t("verifyEmailLinkPage.confirmPassword")}</Body> */}
							<TextField
								// leadingIcon={<Lock />}
								trailing={
									textFieldType.confirmPassword === 'password' ? (
										<Eye
											size="medium" style={{padding: '16px'}}
											onClick={(event) => onToggleInputType(event, 'confirmPassword')}
										/>
									) : (
										<EyeSlash
											size="medium" style={{padding: '16px'}}
											onClick={(event) => onToggleInputType(event, 'confirmPassword')}
										/>
									)
								}
								name="confirmPassword"
								type={textFieldType.confirmPassword}
								label={t("verifyEmailLinkPage.confirmPassword")}
								value={confirmPassword}
								onChange={(event) => onChange(event, 'confirmPassword')}
								error={error && error.confirmPassword ? t('createAccount.' + error.confirmPassword) : ''}
							/>
						</div>
					</div>
					<div className={style.footer}>
						<Button
							size="medium"
							variant="primary"
							isFullWidth
							disabled={!isEmptyObject(error) || isSubmitDisabled}
							type="button"
							onClick={onSubmit}>
							{t("verifyEmailLinkPage.resetPdTitle")}
						</Button>
					</div>
				</Card>
			}
		</React.Fragment>
	);
};

export default ResetPassword;
