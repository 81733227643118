import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	
    experience:{
    hcTestResponse: [
        {
            applicantId: "",
            pretestCode: 20,
            revisionNbr: 3,
            questionNbr: 31,
            answerNbr: "",
        },
        {
            applicantId: "",
            pretestCode: 20,
            revisionNbr: 3,
            questionNbr: 30,
            answerNbr: "",
        },
        {
            applicantId: "",
            pretestCode: 20,
            revisionNbr: 3,
            questionNbr: 43,
            answerNbr: "",
        },
        {
            applicantId: "",
            pretestCode: 20,
            revisionNbr: 3,
            questionNbr: 41,
            answerNbr: "",
        },
     
    ],
    status:230
},

};

export const experienceSlice = createSlice({
	name: 'Experience',
	initialState,
	reducers: {
		updateAnswerExperience: (state, action) => {
            let ques = action.payload.ques;
            let ans = action.payload.ans;
            let hct = state.experience.hcTestResponse.find(
                (res) => res.questionNbr === ques
            );
            hct.answerNbr = ans;
        },
        updateApplicantIdExperience: (state, action) => {
        
            state.experience.hcTestResponse.map(item=>item.applicantId=action.payload.applicantId)
        }
	
	},
   
});

export const { updateAnswerExperience,updateApplicantIdExperience} = experienceSlice.actions;

export default experienceSlice.reducer;
