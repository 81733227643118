import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';
import { apiRequestErrorOccured, updateLoading } from '../../redux/slices/globalSlice';

const getDashboardInfo = createAsyncThunk(
	'postApplyDashboard/getDashboardInfo',
	async (applicantId, { dispatch }) => {
		try {
			const baseURL =
				environment.hcServiceBaseGetUrl +
				`/getJobTrackerStatus?applicantId=${applicantId}`;
			const response = await axiosInstance.get(baseURL);
			dispatch(updateLoading(false));
			return response.data;
		} catch (error) {
			dispatch(apiRequestErrorOccured(error));
		}
	}
);

export { getDashboardInfo };
