import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateAssessmentList,
	updateAssessmentStatus,
	updatePosList,
	updateRaaAsmtSkip,
	updateShowModal
} from 'redux/Assessment/slice';
import { sendGtmEvent } from '../../../utils/GoogleTagManagerUtils';
import { GTM } from 'config/const';
import cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import { takeAssessment } from 'redux/Assessment/request';

const useAssessment = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const global = useSelector((state) => state.global);
	const account = useSelector((state) => state.account);

	const initAsmt = {
		assessmentCode: '',
		assessmentExpdate: '',
		assessmentName: '',
		launchable: '',
		status: '',
		statusText: '',
		statusVal: 0,
		isPass: false
	};
	const initStatus = {
		assessmentNeeded: false,
		rpeaStatus: '',
		rpeaStatusval: 0,
		teaStatus: '',
		teaStatusval: 0
	};
	const initPosition = {
		position: '',
		position101: '',
		position102: '',
		asmtExpDate: '',
		applExpDate: '',
		asmtCode: '',
		stores: [],
		isAssessmentComplete: false,
		isAssessmentPass: false,
		isApplActive: false,
		assessmentName: '',
		isApplShow: true,
		noAssessment: false
	};
	const initStores = {
		address: '',
		streetAddress: '',
		storeName: '',
		position: '',
		assessmentCode: ''
	};

	const getAsmtList = (asmt) => {
		let assessmentList = [];
		if (asmt && asmt.length > 0) {
			assessmentList = asmt.map((respEntry) => {
				const assessment = { ...initAsmt };
				assessment.launchable = respEntry.launchable;
				assessment.status = respEntry.status;
				assessment.assessmentName = respEntry.assessmentName;
				assessment.assessmentCode = respEntry.assessmentCode;
				assessment.assessmentNeed = respEntry.assessmentNeed === 'false' ? false : true;
				assessment.assessmentExpdate = respEntry.assessmentExpdate;

				if (
					assessment.status === '' ||
					(assessment.status === 'P' && assessment.launchable === 'true')
				) {
					assessment.statusVal = 1;
				} else if (assessment.status === 'F') {
					assessment.statusVal = 3;
				} else if (assessment.status === 'P') {
					assessment.statusVal = 4;
				} else if (assessment.status === 'I') {
					assessment.statusVal = 2;
				} else if (assessment.status === 'NA') {
					assessment.statusVal = 0;
				}

				if (assessment.status === 'P') {
					assessment.isPass = true;
				} else if (assessment.status === 'F') {
					assessment.isPass = false;
				}
				return assessment;
			});
		}
		return assessmentList;
	};
	const getAsmtStatus = (asmtList) => {
		const status = { ...initStatus };
		if (asmtList && asmtList.length > 0) {
			asmtList.forEach((assessment) => {
				if (assessment.statusVal === 1 || assessment.statusVal === 2) {
					status.assessmentNeeded = true;
				}
				if (assessment.assessmentCode === '1') {
					if (assessment.statusVal === 1) {
						status.rpeaStatus = 'applicationStatus.notStarted';
						status.rpeaStatusval = 1;
					} else if (assessment.statusVal === 4) {
						status.rpeaStatus = 'applicationStatus.completed';
						status.rpeaStatusval = 4;
					} else if (assessment.statusVal === 2) {
						status.rpeaStatus = 'applicationStatus.started';
						status.rpeaStatusval = 2;
					}
				}

				if (assessment.assessmentCode === '4') {
					if (assessment.statusVal === 1) {
						status.teaStatus = 'applicationStatus.notStarted';
						status.teaStatusval = 1;
					} else if (assessment.statusVal === 4) {
						status.teaStatus = 'applicationStatus.completed';
						status.teaStatusval = 4;
					} else if (assessment.statusVal === 2) {
						status.teaStatus = 'applicationStatus.started';
						status.teaStatusval = 2;
					}
				}
			});
		}
		return status;
	};

	const sortedPositionList = (posList) => {
		let strList = posList?.sort(function (a, b) {
			const aCode = parseInt(a.asmtCode.trim(), 10);
			const bCode = parseInt(b.asmtCode.trim(), 10);
			if (a !== b) {
				return aCode - bCode;
			}
			return 0;
		});

		strList = strList?.sort(function (a, b) {
			if (a.noAssessment !== b.noAssessment) {
				return a.isAssessmentComplete ? 1 : -1;
			}
			if (
				!(a.noAssessment && b.noAssessment) &&
				a.isAssessmentComplete !== b.isAssessmentComplete
			) {
				return a.isAssessmentComplete ? 1 : -1;
			}
			if (new Date(a.asmtExpDate) !== new Date(b.asmtExpDate)) {
				return new Date(a.asmtExpDate).getTime() - new Date(b.asmtExpDate).getTime();
			}
			return 0;
		});
		return strList;
	};

	const getPositionList = (posList, asmtList) => {
		const positionList = posList?.map((pos) => {
			const position = { ...initPosition };
			position.position101 = pos.position['101'];
			position.position102 = pos.position['102'];
			position.asmtCode = pos.asmtCode;
			position.jobClassId = pos.jobClassId;
			position.asmtExpDate = pos.asmtExpDate;
			position.applExpDate = pos.applExpDate;
			position.groupId = pos.workGroupId;
			position.groupDesc = pos.workGroupDesc;
			const storesList = [];
			if (pos.stores) {
				for (const j in pos.stores) {
					const store = { ...initStores };
					store.storeNbr = pos.stores[j].storeNbr;
					store.address = pos.stores[j].address;
					store.streetAddress = pos.stores[j].streetAddress;
					store.storeName = pos.stores[j].storeName;
					store.position = position.position101;
					store.assessmentCode = position.asmtCode;
					storesList.push(store);
				}
			}
			position.stores = storesList;
			if (pos.applExpDate !== '') {
				var fDate = new Date(moment(pos.applExpDate));
				var currentDate = new Date();
				if (fDate.valueOf() > currentDate.valueOf()) {
					position.isApplActive = true;
				}
			}
			if (pos.asmtCode !== '') {
				asmtList.forEach((assessment) => {
					if (position.asmtCode === assessment.assessmentCode) {
						position.assessmentName = assessment.assessmentName;
						position.assessmentNeed = assessment.assessmentNeed;
						if (assessment.statusVal === 4) {
							position.isAssessmentComplete = true;
							position.isAssessmentPass = assessment.isPass;
						} else {
							position.isAssessmentComplete = false;
							position.isApplShow = false;
						}
					}
				});
				position.noAssessment = false;
				position.noNeedAssessment = false;
			} else {
				position.noNeedAssessment = true;
				position.noAssessment = true;
			}
			return position;
		});
		return sortedPositionList(positionList);
	};

	const handleResponse = (data) => {
		const asmtList = getAsmtList(data?.assessmentStatus);

		if (asmtList.length === 1 && asmtList[0].assessmentCode === '') {
			sendGtmEvent(GTM.EVENTS.APPLICATION_SUBMITTED);
			dispatch(updateShowModal(true));
		}
		const asmtStatus = getAsmtStatus(asmtList);
		const posList = getPositionList(data?.positionApplied, asmtList);
		dispatch(updateAssessmentList(asmtList));
		dispatch(updateRaaAsmtSkip(data?.raaAsmtSkip));
		dispatch(updatePosList(posList));
		dispatch(updateAssessmentStatus(asmtStatus));
	};

	const handleSubmitApplication = () => {
		sendGtmEvent(GTM.EVENTS.APPLICATION_SUBMITTED);
		dispatch(updateShowModal(true));
	};
	const handleSubmit = () => {
		if (!account?.data?.auth?.stepNbr) {
			navigate('../login');
		} else {
			navigate('../post-apply-dashboard');
		}
	};

	const handleTakeAssessment = (assessmentCode) => {
		// GTM tracking take assessment clicked
		sendGtmEvent(GTM.EVENTS.TAKE_ASSESSMENT);

		const languageCode = global.language === 'EN' ? 101 : 102;
		const postObj = {
			applicantId:
				global.applicantId && global.applicantId !== 0 ? global.applicantId : cookie.load('userId'),
			emailId: global.email && global.email !== '' ? global.email : cookie.load('emailId'),
			assessmentCode: assessmentCode,
			languageCode: languageCode
		};
		cookie.save(
			'stepNbr',
			account?.data?.auth?.stepNbr ? account?.data?.auth?.stepNbr : cookie.load('stepNbr')
		);
		dispatch(takeAssessment({ account, postObj }));
	};

	const handleGoToDashboard = () => {
		dispatch(updateShowModal(false));
		navigate('../login');
	};

	return {
		handleSubmitApplication,
		handleSubmit,
		handleResponse,
		handleTakeAssessment,
		handleGoToDashboard
	};
};

export default useAssessment;
