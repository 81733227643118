import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
} from '@reduxjs/toolkit';

import locationReducer from './Location/slice';
import aboutYouReducer from './AboutYou/slice';
import jobSearchReducer from './slices/jobSearchSlice';
import postApplyReducer from './PostApplyDashboard/slice';
import feedbackReducer from './Feedback/slice';
import availMainReducer from "./slices/availMainSlice";
import militaryReducer from './Military/slice';
import accountReducer from './Account/slice';
import disclaimerReducer from './Disclaimer/slice';
import appliedJobTrackerReducer from './AppliedJobTracker/slice';
import virtualJobOfferSlice from './VirtualJobOffer/slice';
import AvailabilitySlice from './Availability/slice';
import selfScheduleReducer from "./SelfSchedule/slice";
import SupplyChainAvailabilityReducer from './SupplyChainAvailability/slice';
import declineOfferReducer from './DeclineOffer/slice';
import renewReducer from './Renew/slice';
import EeoReducer from "./EEO/slice"
import ExperienceReducer from "./Experience/slice"
import jobHistoryReducer from "./JobHistory/slice"
import UpdateStepIndicatorReducer from './updateStepIndicator/slice';
import ContactInfoSlice from './ContactInfo/slice';
import ValidateAddressSlice from './ValidateAddress/slice';
import assessmentReducer from "./Assessment/slice"
import hiringHelperReducer from "./HiringHelper/slice";
import updatePreferenceReducer from "./updatePreference/slice"
import ReviewSlice from './Review/slice';
import vjoDrugScreenReducer from './VJODrugScreen/slice';
import editProfileReducer from "./EditProfile/Slice"
import questionnaireReducer from './TaxQuestionnaire/slice'
import persistedReducer from './slices/globalSlice';
import { persistStore } from 'redux-persist';

const rootReducer = combineReducers({
  global: persistedReducer,
  location: locationReducer,
  aboutYou: aboutYouReducer,
  jobSearch: jobSearchReducer,
  postApplyDashboard: postApplyReducer,
  feedback: feedbackReducer,
  appliedJobTracker: appliedJobTrackerReducer,
  military: militaryReducer,
  account: accountReducer,
  disclaimer: disclaimerReducer,
  availMain: availMainReducer,
  virtualJobOffer: virtualJobOfferSlice,
  availability: AvailabilitySlice,
  selfSchedule: selfScheduleReducer,
  supplyChainAvailability: SupplyChainAvailabilityReducer,
  declineOffer: declineOfferReducer,
  renew:renewReducer,
  Eeo:EeoReducer,
  Experience:ExperienceReducer,
  jobHistory:jobHistoryReducer,
  assessment: assessmentReducer ,
  hiringHelper : hiringHelperReducer,
  UpdateStepIndicator: UpdateStepIndicatorReducer,
  ContactInfo: ContactInfoSlice,
  ValidateAddress: ValidateAddressSlice,
  updatePreference: updatePreferenceReducer,
  Review: ReviewSlice,
  vjoDrugScreen: vjoDrugScreenReducer,
  editProfile: editProfileReducer,
  questionnaire: questionnaireReducer 
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware({serializableCheck: false})],
});

const persistor = persistStore(store);

export { store, persistor}
