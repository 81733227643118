import {Card, Heading} from '@walmart-web/livingdesign-components';
import { useVirtualJobOffer } from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDisplay} from '../../../../utils/useDisplay';
import Banner from './banner/Banner';
import DeclineOffer from '../declineOffer';
import DeclineMessage from '../declineOffer/DeclineMessage';
import {useDeclineOffer} from '../declineOffer/useDeclineOffer';
import FooterView from './footer';
import JobHoursContainer from './jobHours';
import JobInfoContainer from './jobInfo';
import './style.scss';

/**
 * Renders the HomeScreen view of the Virtual Job offer
 */
export const VJOHomeScreen = () => {
  const {isPortrait} = useDisplay();
  const {t} = useTranslation();
  const {jobOffer, account} = useVirtualJobOffer();
  const [isDeclineClicked, setDeclineClick] = useState(false);
  const {isOfferDeclined} = useDeclineOffer();

  // show Decline offer
  const showDeclineOffer = useCallback(() => {
    setDeclineClick(true);
  }, []);
  // hide Decline offer
  const hideDeclineOffer = useCallback(() => {
    setDeclineClick(false);
  }, []);
  // Portrait view
  const PortraitView = useMemo(() => {
    return (
      <>
        <JobInfoContainer />
        <Card UNSAFE_className='vjo_bottom_container'>
          <JobHoursContainer />
          <FooterView showDeclineOffer={showDeclineOffer} />
        </Card>
      </>
    );
  }, [showDeclineOffer]);

  // Desktop view
  const DesktopView = useMemo(() => {
    return (
      <>
        <JobInfoContainer />
        <JobHoursContainer />
        <FooterView showDeclineOffer={showDeclineOffer} />
      </>
    );
  }, [showDeclineOffer]);

  if (isOfferDeclined) {
    return <DeclineMessage />;
  }

  return (
    <React.Fragment>
      {jobOffer && (
        <>
          <div className='vjo_header'>
            <Heading
              children={t('virtualJobOffer.header', {
                userName: account?.data?.auth?.applicantName,
              })}
              UNSAFE_className='header_title'
            />
          </div>
          <Card size='small' UNSAFE_className='vjo_body'>
            <Banner />
            <div className='vjo_conatiner'>
              {isPortrait ? PortraitView : DesktopView}
            </div>
          </Card>
        </>
      )}
      <DeclineOffer isOpen={isDeclineClicked} onClose={hideDeclineOffer} />
    </React.Fragment>
  );
};
