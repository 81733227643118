import {
	isValidPhoneNumber,
	formatIncompletePhoneNumber,
	parsePhoneNumber
} from 'libphonenumber-js';
import _ from 'lodash';

export const emailPattern = new RegExp(
	/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);
const passwdRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,11}$/);

export const validateCreateAccount =
	(values, error, setError, setMobile) => (dispatch, getState) => {
		let errors = {};
		const emailError = validateEmail(values.emailId, error, setError);
		const confirmEmailError = validateConfirmEmail(
			values.confirmEmail,
			values.emailId,
			error,
			setError
		);
		const passwordError = validatePassword(values.password, error, setError);
		const confirmPasswordError = validateConfirmPassword(
			values.confirmPassword,
			values.password,
			error,
			setError
		);
		const mobileError = dispatch(validateMobile(values.mobileNumber, error, setError, setMobile));
		const languageError = isLanguageSelected(values.language, error, setError);

		if (!isEmpty(emailError)) {
			errors = { ...errors, email: emailError };
		}
		if (!isEmpty(confirmEmailError)) {
			errors = { ...errors, confirmEmail: confirmEmailError };
		}
		if (!isEmpty(passwordError)) {
			errors = { ...errors, password: passwordError };
		}
		if (!isEmpty(confirmPasswordError)) {
			errors = { ...errors, confirmPassword: confirmPasswordError };
		}
		if (!isEmpty(languageError)) {
			errors = { ...errors, language: languageError };
		}

		if (!isEmpty(mobileError)) {
			errors = { ...errors, mobile: mobileError };
		}
		return errors;
	};

export const validateEmail = (value, error, setError, confirmEmail) => {
	let emailError = {};
	if (typeof value === 'undefined') {
		emailError = 'pleasefillIn';
		setError({ ...error, email: emailError });
		return 'pleasefillIn';
	} else if (!emailPattern.exec(value.trim())) {
		emailError = 'pleaseEntervalidEmail';
		setError({ ...error, email: emailError });
		return 'pleaseEntervalidEmail';
	} else if (confirmEmail && value !== '' && value !== confirmEmail) {
		emailError = 'fieldsmustmatch';
		setError({ ...error, email: emailError });
		return 'fieldsmustmatch';
	} else {
		const { email, confirmEmail, ...errors } = error;
		setError(errors);
		return undefined;
	}
};

export const validateConfirmEmail = (value, email, error, setError) => {
	let emailError = {};
	if (typeof value === 'undefined') {
		emailError = 'pleasefillIn';
		setError({ ...error, confirmEmail: emailError });
		return 'pleasefillIn';
	} else if (!emailPattern.exec(value.trim())) {
		emailError = 'pleaseEntervalidEmail';
		setError({ ...error, confirmEmail: emailError });
		return 'pleaseEntervalidEmail';
	} else if (value !== '' && value !== email) {
		emailError = 'fieldsmustmatch';
		setError({ ...error, confirmEmail: emailError });
		return 'fieldsmustmatch';
	} else {
		const { email, confirmEmail, ...errors } = error;
		setError(errors);
		return undefined;
	}
};

export const validatePassword = (value, error, setError, confirmPassword) => {
	let passwordError = {};
	if (typeof value === 'undefined') {
		passwordError = 'pleasefillIn';
		setError({ ...error, pdErrMsg: passwordError });
		return 'pleasefillIn';
	} else if (!passwdRegex.exec(value.trim())) {
		passwordError = 'notValidPasswdParameter';
		setError({ ...error, pdErrMsg: passwordError });
		return 'notValidPasswdParameter';
	} else if (value.length > 11) {
		passwordError = 'passwordtoolong';
		setError({ ...error, pdErrMsg: passwordError });
		return 'passwordtoolong';
	} else if (confirmPassword && value !== '' && value !== confirmPassword) {
		passwordError = 'fieldsmustmatch';
		setError({ ...error, pdErrMsg: passwordError });
		return 'fieldsmustmatch';
	} else {
		const { pdErrMsg, confirmPassword, ...errors } = error;
		setError(errors);
		return undefined;
	}
};

export const validateConfirmPassword = (value, password, error, setError) => {
	let passwordError = {};
	if (typeof value === 'undefined') {
		passwordError = 'pleasefillIn';
		setError({ ...error, confirmPassword: passwordError });
		return 'pleasefillIn';
	} else if (!passwdRegex.exec(value.trim())) {
		passwordError = 'notValidPasswdParameter';
		setError({ ...error, confirmPassword: passwordError });
		return 'notValidPasswdParameter';
	} else if (value.length > 11) {
		passwordError = 'passwordtoolong';
		setError({ ...error, confirmPassword: passwordError });
		return 'passwordtoolong';
	} else if (value !== '' && value !== password) {
		passwordError = 'fieldsmustmatch';
		setError({ ...error, confirmPassword: passwordError });
		return 'fieldsmustmatch';
	} else {
		const { confirmPassword, pdErrMsg, ...errors } = error;
		setError(errors);
		return undefined;
	}
};

export const validateMobile =
	(value, error, setError, setMobile, isAltContact) => (dispatch, getState) => {
		const parsedMobileNumber = parseMobileNumber(value.substr(value.indexOf('(') + 1));

		const state = getState();
		let mobileError = {};
		const isValidMobileNumber = allowOnlyNumber(parsedMobileNumber, setMobile);
		if (!isValidMobileNumber) {
			return false;
		} else if (value && value.length <= 6) {
			setMobile(
				value.length > 1 ? parsePhoneNumber(value, state.global.countryCode).nationalNumber : value
			);
		} else {
			setMobile(formatIncompletePhoneNumber(value, state.global.countryCode));
		}
		if (
			typeof value === 'undefined' ||
			value === '' ||
			isNaN(
				value && value.length > 2
					? parsePhoneNumber(value, state.global.countryCode).nationalNumber
					: value
			) ||
			parsedMobileNumber.length < 10 ||
			isValidPhoneNumber(value, state.global.countryCode) !== true
		) {
			mobileError = 'contactError';
			if (isAltContact) {
				if (
					value === '' &&
					Object.keys(error).length === 1 &&
					error['altContact'] === 'contactError'
				) {
					setError(_.omit(error, 'altContact'));
				} else {
					setError({ ...error, altContact: mobileError });
				}
			} else {
				setError({ ...error, mobile: mobileError });
			}
			return 'contactError';
		} else {
			if (isAltContact) {
				const { altContact, ...errors } = error;
				setError(errors);
			} else {
				const { mobile, ...errors } = error;
				setError(errors);
			}

			return undefined;
		}
	};

export const allowOnlyNumber = (value, setMobile) => {
	const re = /^[0-9\b]+$/g;
	let isValidMobileNumber = false;
	if (value === '' || (re.test(value) && value.length <= 10)) {
		setMobile(value);
		isValidMobileNumber = true;
	}
	return isValidMobileNumber;
};

export const isLanguageSelected = (value, error, setError) => {
	let languageError = {};
	if (
		typeof value === 'undefined' ||
		value === '' ||
		(value !== 'English' && value !== 'Spanish')
	) {
		languageError = 'languageError';
		setError({ ...error, language: languageError });
		return 'languageError';
	} else {
		const { language, ...errors } = error;
		setError(errors);
		return undefined;
	}
};

export const isMobileAccepted = (value, error, setError) => {
	let acceptError = {};
	if (typeof value === 'undefined' || value === false) {
		acceptError = 'acceptError';
		setError({ ...error, acceptMobileText: acceptError });
		return 'acceptError';
	} else {
		const { acceptMobileText, ...errors } = error;
		setError(errors);
		return undefined;
	}
};

export const hasNumber = (obj) => {
	return typeof obj !== 'undefined' && obj != null && obj.trim() !== '' && !isNaN(obj)
		? true
		: false;
};

export const isEmpty = (obj) => {
	return typeof obj !== 'undefined' && obj != null && obj.trim() !== '' ? false : true;
};

export const isEmptyData = (obj) => {
	return typeof obj !== 'undefined' && obj != null && obj !== '' ? false : true;
};

export const isEmptyObject = (obj) => {
	return typeof obj !== 'undefined' &&
		obj != null &&
		Object.keys(obj).length !== 0 &&
		Object.getPrototypeOf(obj) === Object.prototype
		? false
		: true;
};
export const validateFname = (value, countryCode) => {
	if (typeof value === 'undefined' || !value) {
		return false;
	}
	let regex = /^[A-Za-z ,."-]+$/;
	if (countryCode === 'PR') {
		regex = /^[A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F ,."-]+$/;
	}
	return regex.test(value);
};
export const validateMname = (value, countryCode) => {
	let regex = /^[A-Za-z]+$/;
	if (countryCode === 'PR') {
		regex = /^[A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F]+$/;
	}
	return value === '' || (regex.test(value) && value?.length >= 1);
};
export const validateLname = (value, countryCode) => {
	if (typeof value === 'undefined' || !value) {
		return false;
	}
	let regex = /^[A-Za-z ,."-]+$/;
	if (countryCode === 'PR') {
		regex = /^[A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F ,."-]+$/;
	}
	return regex.test(value);
};
export const validateZip = (value, countryCode) => {
	if (typeof value === 'undefined' || !value) {
		return false;
	}
	const regex = /^\d{5}$/;
	return regex.test(value) && value?.length <= 5;
};

export const formatPhoneNumber = (phoneNumberString) => {
	if (!phoneNumberString || isEmpty(phoneNumberString)) {
		return phoneNumberString;
	}
	return phoneNumberString.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const parseMobileNumber = (mobileNumberString) => {
	const regexExp = /[\-\s()]/g;
	if (!mobileNumberString || isEmpty(mobileNumberString)) {
		return mobileNumberString;
	}
	return mobileNumberString.replace(regexExp, '');
};

export const isValidNumber = (value) => {
	const re = /^[0-9\b]+$/g;
	let isValidMobileNumber = false;
	if (value === '' || (re.test(value) && value.length <= 10)) {
		isValidMobileNumber = true;
	}
	return isValidMobileNumber;
};

export const removeInvalidChars = (value, countryCode) => {
	let check = value;
	if (countryCode === 'PR') {
		check = check.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F\s\,\.\-\'']/gi, '');
	} else {
		check = check.replace(/[^\w\s\.\-\'']/gi, '');
	}
	check = check.replace(/^\s+/g, '');
	check = check.replace(/\d+/g, '');
	check = check.replace(/_/g, '');
	return check.toUpperCase();
};
