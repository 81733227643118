import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { environment } from '../../utils/environment/environment';
import { apiRequestErrorOccured, updateLoading } from '../../redux/slices/globalSlice';

const postFeedback = createAsyncThunk('feedback/postFeedback', async (payload, { dispatch }) => {
  try {
    const baseURL = environment.hcServiceBasePostUrl + '/saveFeedback';
    const response = await axiosInstance.post(baseURL, payload);
    dispatch(updateLoading(false));
    return response.data;
  } catch (error) {
		dispatch(apiRequestErrorOccured(error));
  }
});

export { postFeedback };
