import React, { useEffect } from 'react';
import style from './HRAvailability.module.scss';
import { Chip } from '@walmart-web/livingdesign-components';
import moment from 'moment';
import useHRAvailability from '../hooks/useHRAvailability';
import TimeSlots from './TimeSlots';
import { t } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
	updateTimeChipSelected,
	updateSelectedSlot,
	updateSelectedSlotOrientation,
	updateDateIndex,
	updateSelectedSlotTour,
	updateDateIndexForTour
} from 'redux/SelfSchedule/slice';
import { useSelfSchedule } from '../hooks/useSelfSchedule';
import { selfScheduleType } from '../../../config/const';

const Icon = require('@livingdesign/icons');

export default function HRAvailabilitySC() {
	const {
		morningTimeSlots,
		afternoonTimeSlots,
		eveningTimeSlots,
		selectedDateIndex,
		selectedDateIndexForTour,
		selectedTimeIndex,
		disableList,
		setSelectedTimeIndex,
		setInterviewSlots,
		setSelectedDateIndex,
		setSelectedDateIndexForTour
	} = useHRAvailability();

	const { getScheduleType } = useSelfSchedule();
	const scheduleType = getScheduleType();

	const dispatch = useDispatch();
	const selfSchedule = useSelector((state) => state.selfSchedule);
	const global = useSelector((state) => state.global);

	const handleOnDatePick = (dateIndex) => {
		dispatch(updateDateIndex(dateIndex));
		dispatch(updateSelectedSlot(''));
		dispatch(updateTimeChipSelected(false));
		setSelectedDateIndex(dateIndex);
	};

	const handleOnDatePickTour = (date, dateIndex) => {
		dispatch(updateDateIndexForTour(date));
		dispatch(updateTimeChipSelected(false));
		dispatch(updateSelectedSlot(''));
		setSelectedDateIndexForTour(date);
		setSelectedDateIndex(dateIndex);
	};

	const handleOnTimePick = (slot, dateIndex, timeIndex) => {
		dispatch(updateTimeChipSelected(true));
		dispatch(updateSelectedSlot(slot));
	};

	const handleOnTimePickOrientation = (slot, dateIndex, timeIndex) => {
		dispatch(updateTimeChipSelected(true));
		dispatch(updateSelectedSlotOrientation(slot, dateIndex));
	};

	const handleOnTimePickTour = (slot, dateIndex, timeIndex) => {
		dispatch(updateTimeChipSelected(true));
		const actionObject = {
			slot: slot,
			timeIndex: timeIndex
		};
		dispatch(updateSelectedSlotTour(actionObject));
		setSelectedTimeIndex(timeIndex);
	};

	useEffect(() => {
		setInterviewSlots(selectedDateIndex, selectedDateIndexForTour);
	}, [selectedDateIndex, selectedDateIndexForTour, setInterviewSlots]);

	const renderDatesForTour = () => {
		const rjptEvents = selfSchedule.dateAndTimeSlots;
		return (
			<div className={style.dateChip_container}>
				{Object.entries(rjptEvents).map(([k, value], i) => {
					let selected;
					if (selectedDateIndex === i) {
						selected = true;
					}
					return i < 5 ? (
						<Chip
							size="large"
							className={style.dateChip}
							selected={selected}
							style={{ backgroundColor: selected ? '#2E2F32' : '#FFFFFF' }}
							onClick={() => handleOnDatePickTour(k, i)}
							key={moment(k)
								.locale(global.language === 'ES' ? 'es' : 'en')
								.format('MMM DD')}>
							<div className={style.dateChip_content}>
								<div style={{ color: selected ? '#fff' : '#000' }}>
									<div className={style.date}>
										{moment(k)
											.locale(global.language === 'ES' ? 'es' : 'en')
											.format('MMM DD')}
									</div>
									<div className={style.day}>
										{moment(k)
											.locale(global.language === 'ES' ? 'es' : 'en')
											.format('dddd')}
									</div>
								</div>
							</div>
							<div style={{ height: '7vh' }}>
								{selected ? (
									<div className={style.checkIcon}>
										<Icon.Check size="small" />
									</div>
								) : (
									''
								)}
							</div>
						</Chip>
					) : (
						''
					);
				})}
			</div>
		);
	};

	const renderDates = () => {
		let dates = selfSchedule.dateAndTimeSlots;

		if (scheduleType === selfScheduleType.SC_ORIENTATION) {
			dates = [...new Map(dates.map((item) => [item['startDate'], item])).values()];
		} else if (scheduleType === selfScheduleType.SC_TOUR) {
			dates = selfSchedule.dateAndTimeSlots;
		} else {
			dates = [...new Map(dates.map((item) => [item['date'], item])).values()];
		}
		return scheduleType === selfScheduleType.SC_TOUR ? (
			renderDatesForTour()
		) : (
			<div className={style.dateChip_container}>
				{dates.map((data, dateIndex) => {
					const selected = selectedDateIndex === dateIndex;
					return (
						<Chip
							size="large"
							className={style.dateChip}
							selected={selected}
							style={{ backgroundColor: selected ? '#2E2F32' : '#FFFFFF' }}
							onClick={() => handleOnDatePick(dateIndex)}
							key={
								scheduleType === selfScheduleType.SC_ORIENTATION
									? data.startDate
									: scheduleType === selfScheduleType.SC_TOUR
									? data[selectedDateIndex].scheduleTs
									: data.date
							}>
							<div className={style.dateChip_content}>
								<div style={{ color: selected ? '#fff' : '#000' }}>
									<div className={style.date}>
										{moment(
											scheduleType === selfScheduleType.SC_ORIENTATION
												? data.startDate
												: scheduleType === selfScheduleType.SC_TOUR
												? data[selectedDateIndex].scheduleTs
												: data.date
										)
											.locale(global.language === 'ES' ? 'es' : 'en')
											.format('MMM DD')}
									</div>
									<div className={style.day}>
										{moment(
											scheduleType === selfScheduleType.SC_ORIENTATION
												? data.startDate
												: scheduleType === selfScheduleType.SC_TOUR
												? data[selectedDateIndex].scheduleTs
												: data.date
										)
											.locale(global.language === 'ES' ? 'es' : 'en')
											.format('dddd')}
									</div>
								</div>
								<div>
									{selected ? (
										<div className={style.checkIcon}>
											<Icon.Check />
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</Chip>
					);
				})}
			</div>
		);
	};

	const renderTimes = () => {
		return (
			<div>
				{morningTimeSlots && morningTimeSlots.length > 0 && (
					<div className={style.timeChip_container}>
						{t('selfSchedule.morningSlot')}
						<TimeSlots
							data={morningTimeSlots}
							dateIndex={selectedDateIndex}
							timeIndex={selectedTimeIndex}
							slot={'morning'}
							handleOnTimePick={
								scheduleType === selfScheduleType.SC_ORIENTATION
									? handleOnTimePickOrientation
									: scheduleType === selfScheduleType.SC_TOUR
									? handleOnTimePickTour
									: handleOnTimePick
							}
							selectedSlot={selfSchedule.selectedTimeSlot}
							disList={disableList}
						/>
					</div>
				)}
				{afternoonTimeSlots && afternoonTimeSlots.length > 0 && (
					<div className={style.timeChip_container}>
						{t('selfSchedule.afternoonSlot')}
						<TimeSlots
							data={afternoonTimeSlots}
							dateIndex={selectedDateIndex}
							timeIndex={selectedTimeIndex}
							slot={'afternoon'}
							handleOnTimePick={
								scheduleType === selfScheduleType.SC_ORIENTATION
									? handleOnTimePickOrientation
									: scheduleType === selfScheduleType.SC_TOUR
									? handleOnTimePickTour
									: handleOnTimePick
							}
							selectedSlot={selfSchedule.selectedTimeSlot}
							disList={disableList}
						/>
					</div>
				)}
				{eveningTimeSlots && eveningTimeSlots.length > 0 && (
					<div className={style.timeChip_container}>
						{t('selfSchedule.eveningSlot')}
						<TimeSlots
							data={eveningTimeSlots}
							dateIndex={selectedDateIndex}
							timeIndex={selectedTimeIndex}
							slot={'evening'}
							handleOnTimePick={
								scheduleType === selfScheduleType.SC_ORIENTATION
									? handleOnTimePickOrientation
									: scheduleType === selfScheduleType.SC_TOUR
									? handleOnTimePickTour
									: handleOnTimePick
							}
							selectedSlot={selfSchedule.selectedTimeSlot}
							disList={disableList}
						/>
					</div>
				)}
			</div>
		);
	};

	return (
		<div className={style.availability_screen_layout}>
			<div>{renderDates()}</div>
			<div>{renderTimes()}</div>
		</div>
	);
}
