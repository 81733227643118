import { Select } from '@walmart-web/livingdesign-components';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './CustomSelect.module.scss';

const CustomSelect = ({ label, id, defaultValue, data, onChangeCallback, showAdditionalError }) => {
	const [value, setValue] = useState(defaultValue || '');
	const { t } = useTranslation();

	useEffect(() => {
		if (onChangeCallback) {
			onChangeCallback(value);
		}
	}, [value]);

	return (
		<Select
			label={label}
			onChange={(e) => setValue(e.target.value)}
			value={value}
			error={showAdditionalError?.(value)}
			UNSAFE_className={`${styles.select} ${!label ? styles.noLabel : ''}`}>
			<option value={''}></option>
			{data?.map((option) => (
				<option key={`${id}-${option.value}`} value={option.value}>
					{option.label}
				</option>
			))}
		</Select>
	);
};

CustomSelect.propTypes = {
	label: PropTypes.string,
	id: PropTypes.string,
	defaultValue: PropTypes.string,
	data: PropTypes.array,
	onChangeCallback: PropTypes.func,
	showAdditionalError: PropTypes.func
};

export default CustomSelect;
