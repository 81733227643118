import React, { useEffect } from 'react';
import {
	TabNavigation,
	TabNavigationItem,
	Alert,
	Body,
	Panel,
	Heading,
	Button
} from '@walmart-web/livingdesign-components';
import JobList from './JobList/JobList';
import { useDispatch, useSelector } from 'react-redux';
import { executionErrorOccured, subHeader, updateLoading, updatePreferedJobsFromRedirect } from '../../redux/slices/globalSlice';
import JobSelectionFooter from './JobSelectionFooter/JobSelectionFooter';
import { useNavigate } from 'react-router-dom';
import JobSearchFilter from './JobSearchFilter/JobSearchFilter';
import { Filter, Home, Clock, Wrench, User } from '@livingdesign/icons';
import styles from './JobSelection.module.css';
import {
	updateSelectedJobs,
	updateCurrentSelectedJobs,
	updateJobDetails,
	updateJobSearchResponse,
	updateCurrentTab,
	updateFilters,
	updateJobPreferred,
	updateShiftsAutoFilled
} from '../../redux/slices/jobSearchSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { getJobSearch, postJobSearch } from '../../redux/JobSearch/request';
import { useTranslation } from 'react-i18next';
import InfoItem from './JobDetails/InfoItem/InfoItem';
import JobDetails from './JobDetails/JobDetails';
import { useDisplay } from 'utils/useDisplay';
import cookie from 'react-cookies';
import {
	updateShiftPreference,
	updateEmploymentType,
	updateScheduleAvailability
} from 'redux/slices/availMainSlice';
import { sendGtmEvent } from 'utils/GoogleTagManagerUtils';
import { GTM } from 'config/const';
import { callHiringHelper } from 'redux/HiringHelper/request';
import { AvailabilityHelper } from 'pages/Availability/AvailabilityHelper';
import _ from 'lodash';
import AlertDialog from 'components/AlertDialog';
import axios from 'axios';

const filters = [
	{ icon: <Filter size="small" />, title: 'jobSelection.sortAndFilter' },
	{ icon: <Clock size="small" />, title: 'jobSelection.shift' },
	{ icon: <Home size="small" />, title: 'jobSelection.facility' }
];
let futureJobs = [];
let currentJobs = [];

const JobSelection = (props) => {
	let usedEffect = false;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const selectedJobsStore = useSelector((state) => state.jobSearch);
	const locationSelector = useSelector((state) => state.location);
	const getPrevScreen = useSelector((state) => state.global);
	const isCpRedirect = useSelector((state) => state.global?.isCpRedirect);
	const preferredJobsViaRedirect = useSelector((state) => state.global?.preferedJobsFromRedirect);
	const account = useSelector((state) => state.account);
	const [jobCount, setJobCount] = React.useState([futureJobs.length, currentJobs.length]);
	const [filteredJobs, setFilteredJobs] = React.useState(null);
	const [showCountryCode, setShowCountryCode] = React.useState(false);
	const [detailsOpen, setDetailsOpen] = React.useState(false);
	const [forceOpen, setForceOpen] = React.useState(false);
	const [continueAlertOpen, setContinueAlertOpen] = React.useState(false);
	const { isPortrait } = useDisplay();
	const [isAgeUpdateNeeded, setIsAgeUpdateNeeded] = React.useState(false);
	const { getCountryandJobType, empTypeObj } = AvailabilityHelper();

	const setIsAgeUpdateRequired = (value) => {
		setIsAgeUpdateNeeded(value);
	};
	const availStore = useSelector((state) => state.availMain);

	const logJsIdNotFound = async(jobSelectionIds) => {
		try{
			await axios.post('/HiringCenter/nodeLog', {
				level: 'error',
				message: `CP-REDIRECT | INVALID JOBSELECTIONIDS | JobSelectionIds received from external source via redirect are not found in CP ::: jobSelectionIds : ${jobSelectionIds}`
			  })
		}
		catch(error){
			console.log(error);
		}
	}

	const toggleOpeningsTabOnLoad = (job) => {
		if(job?.openPositions <= 0) {
			switchTab(false);
		} else {
			switchTab(true);
		}
	}

	const getJobs = async () => {
		const storeNumList = locationSelector.selectedPos.map((i) => i.storeNumber);
		const req = {
			stores: storeNumList,
			applicantId: getPrevScreen.applicantId
		};
		sendGtmEvent(GTM.EVENTS.APPLICANT_JOB_SEARCH);
		dispatch(updateLoading(true));
		dispatch(getJobSearch(req))
			.then(unwrapResult)
			.then((result) => {
				dispatch(updateJobSearchResponse(result));
				getJobList(result.response);
				getSelected(result.response);
				const filterObj = {
					facility: 0,
					empType: [],
					shift: [],
					workgroup: [],
					skill: [],
					interaction: [],
					workingConditions: []
				};
				dispatch(updateFilters(filterObj));
				dispatch(updateLoading(false));

				if (isCpRedirect) {
					const filteredJobsFromResponse = Array.isArray(result.response)
						? result.response.filter(
								(v) => v.payRangesByFacility && Object.keys(v.payRangesByFacility).length
						  )
						: [];

					const preferredJobsIdSet = new Set(preferredJobsViaRedirect);
					const jobsToSelect = [];
					let firstJob;
					filteredJobsFromResponse.forEach((job) => {
						if (preferredJobsIdSet.has(job.jobSelectionId)) {
							jobsToSelect.push(job);

							if (job.jobSelectionId === preferredJobsViaRedirect[0]) {
								firstJob = job;
							}
						}
					});
					if(preferredJobsViaRedirect?.length && !jobsToSelect?.length){
						logJsIdNotFound(preferredJobsViaRedirect);
					}
					toggleOpeningsTabOnLoad(firstJob);
					selectMultipleJobs(jobsToSelect);
					dispatch(updatePreferedJobsFromRedirect(jobsToSelect.map((i) => i.jobSelectionId)));
				}

				return result;
			})
			.catch((error) => {
				dispatch(executionErrorOccured());
			});
	};
	const getFacilityType = (i) => {
		return i.workgroupId < 5000 ? 'store' : 'warehouse';
	};

	const getSelected = (data) => {
		let tempArray = [...selectedJobsStore.currentSelectedJobs];
		data
			.filter((v) => v.payRangesByFacility && Object.keys(v.payRangesByFacility).length)
			.map((index) => {
				if (
					index.jobPreferred === 'Y' &&
					!tempArray.some((i) => i.jobSelectionId === index.jobSelectionId)
				) {
					tempArray = [...tempArray, index];
				}
			});
		dispatch(updateCurrentSelectedJobs(tempArray));
	};

	const getJobList = (data) => {
		futureJobs = [];
		currentJobs = [];
		const countryCode = new Set();
		data
			.filter((v) => v.payRangesByFacility && Object.keys(v.payRangesByFacility).length)
			.map((index) => {
				if (index.openPositions > 0) {
					currentJobs.push(index);
				} else {
					futureJobs.push(index);
				}
				countryCode.add(index.countryCode);
			});
		if (countryCode.size > 1) {
			setShowCountryCode(true);
		}

		setFilteredJobs(
			data.filter((v) => v.payRangesByFacility && Object.keys(v.payRangesByFacility).length)
		);
		setJobCount([futureJobs.length, currentJobs.length]);
	};
	const detailsPressed = (i, mobile) => {
		sendGtmEvent(GTM.EVENTS.JOB_OPENING_VIEW_DETAILS);
		dispatch(updateJobDetails(i));
		mobile ? navigate('../job-details') : setDetailsOpen(true);
	};
	const continueButton = () => {
		if (
			getPrevScreen.applicantId !== 0 &&
			selectedJobsStore.currentSelectedJobs.length !== selectedJobsStore.selectedJobs.length
		) {
			setContinueAlertOpen(true);
		} else {
			saveJobPreference();
		}
	};

	const resetAutoFill = () => {
		cookie.save('shiftTypeAutoFillDeclined', false);
		cookie.save('employmentTypeAutoFillDeclined', false);
	};

	const saveJobPreference = () => {
		resetAutoFill();
		dispatch(updateSelectedJobs(selectedJobsStore.currentSelectedJobs));
		if (getPrevScreen.applicantId !== 0) {
			dispatch(updateJobPreferred('Y'));
		}
		if (getPrevScreen.applicantId === 0) {
			dispatch(updateEmploymentType(availStore.availability.employmentType));
			dispatch(updateShiftPreference(availStore.availability.shiftPreference));
			dispatch(updateScheduleAvailability(availStore.availability.scheduleAvailability));
			isPortrait ? navigate('../availability/employmentType') : navigate('../availability');
		} else {
			sendGtmEvent(GTM.EVENTS.JOB_SUBMIT_BUTTON);
			const isJobUpdated = checkForJobUpdate();
			const jobNumList = selectedJobsStore.currentSelectedJobs.map((i) => i.jobSelectionId);
			let preferredEmpTypes = [];
			if (availStore.employmentType.fullTime) {
				preferredEmpTypes.push('F');
			}
			if (availStore.employmentType.partTime) {
				preferredEmpTypes.push('P');
			}
			if (availStore.employmentType.temporary) {
				preferredEmpTypes.push('F');
			}
			const selectJobsShifts = selectedJobsStore.currentSelectedJobs.map((i) => i.shiftIds);
			const selectJobsShiftIndList = selectedJobsStore.currentSelectedJobs.map(
				(i) => i.teamingShiftIndList
			);
			let selectedShifts = availStore.shiftPreference.shift;
			if (Array.isArray(selectJobsShifts) && selectJobsShifts.length) {
				selectedShifts = [...new Set(selectedShifts.concat(...selectJobsShifts.filter(Boolean)))];
			}
			if (Array.isArray(selectJobsShiftIndList) && selectJobsShiftIndList.length) {
				selectedShifts = [
					...new Set(selectedShifts.concat(...selectJobsShiftIndList.filter(Boolean)))
				];
			}
			const employmentTypes = [
				...new Set(
					preferredEmpTypes.concat(
						...selectedJobsStore.currentSelectedJobs.map((i) => i.employmentTypes).filter(Boolean)
					)
				)
			];
			let updatedEmploymentType = false;
			employmentTypes.forEach((empType) => {
				if (!availStore.availability.employmentType[empTypeObj[empType]]) {
					updatedEmploymentType = true;
				}
			});
			const req = {
				applicantId: getPrevScreen.applicantId,
				preferredJobs: jobNumList,
				metricId: 0,
				ageUpdateNeeded: isAgeUpdateNeeded,
				shiftIds: selectedShifts,
				employmentTypes: employmentTypes
			};

			dispatch(updateLoading(true));
			dispatch(postJobSearch(req))
				.then((result) => {
					if (account?.data?.auth?.stepNbr >= 850) {
						dispatch(callHiringHelper(req.applicantId));
					}
					dispatch(updateLoading(false));
					dispatch(
						updateShiftsAutoFilled(
							selectedShifts.some((r) => availStore.shiftPreference.shift.indexOf(r) < 0)
						)
					);
					if (updatedEmploymentType) {
						isPortrait ? navigate('../availability/employmentType') : navigate('../availability');
					} else if (
						!isJobUpdated ||
						selectedShifts.some((r) => availStore.shiftPreference.shift.indexOf(r) < 0)
					) {
						isPortrait ? navigate('../availability/shiftPreference') : navigate('../availability');
					} else {
						if (account?.data?.auth?.stepNbr >= 250) {
							navigate('../minimum-qualifications');
						} else {
							navigate('../login');
						}
					}
					return result;
				})
				.catch((error) => {
					dispatch(executionErrorOccured());
				});
		}
	};

	const checkForJobUpdate = () => {
		const selectedJobs = _.omit(
			getCountryandJobType(selectedJobsStore.selectedJobs),
			'jobCountryType'
		);
		const currentSelectedJobs = _.omit(
			getCountryandJobType(selectedJobsStore.currentSelectedJobs),
			'jobCountryType'
		);
		return _.isEqual(selectedJobs, currentSelectedJobs);
	};

	const selectJob = (job) => {
		const tempArray = [...selectedJobsStore.currentSelectedJobs];
		if (!tempArray.some((i) => i.jobSelectionId === job.jobSelectionId)) {
			tempArray.push(job);
		} else {
			tempArray.splice(
				tempArray.findIndex((i) => i.jobSelectionId === job.jobSelectionId),
				1
			);
			if (isCpRedirect) {
				const updatedPreferredJobs = preferredJobsViaRedirect.filter(
					(jobSelectionId) => jobSelectionId !== job.jobSelectionId
				);
				dispatch(updatePreferedJobsFromRedirect(updatedPreferredJobs));
			}
		}
		dispatch(updateCurrentSelectedJobs(tempArray));
	};

	const selectMultipleJobs = (jobs) => {
		const tempArray = [...selectedJobsStore.currentSelectedJobs];
		const selectedJobIds = new Set(tempArray.map(i => i.jobSelectionId));
		jobs.forEach(job => {
			if (!selectedJobIds.has(job.jobSelectionId)) {
				tempArray.push(job);
				selectedJobIds.add(job.jobSelectionId);
			}
		})
		dispatch(updateCurrentSelectedJobs(tempArray));
	};

	const switchTab = (selected) => {
		window.scroll(0, 0);
		dispatch(updateCurrentTab(selected));
	};
	const closeSideBar = () => {
		if (forceOpen) {
			setForceOpen(false);
		} else {
			setDetailsOpen(false);
		}
	};
	const checkIfPreferred = (item) => {
		return selectedJobsStore.currentSelectedJobs.some(
			(i) => i.jobSelectionId === item.jobSelectionId && i.jobPreferred === 'Y'
		);
	};
	const getFacilityList = () => {
		let warehouseJobs = 0;
		let storeJobs = 0;
		if (filteredJobs === null) {
			usedEffect = false;
		} else {
			filteredJobs.map((index) => {
				if (getFacilityType(index) === 'store') {
					storeJobs++;
				} else {
					warehouseJobs++;
				}
			});
			usedEffect = true;
		}
		return { store: storeJobs, warehouse: warehouseJobs };
	};
	const noResults = () => {
		if (!usedEffect) {
			return null;
		}
		return (
			<div className={styles.tabsContainer}>
				<div className={styles.jobSelectionNoJobsContainer}>
					<div className={styles.jobListCatImageHolder}>
						<img
							src={require('../../assets/images/cat_chewingCord_circle.png')}
							alt="Job icon"
							className={styles.joblistCatImage}
						/>
					</div>
					<Heading as="h3" size="small" weight={700}>
						{t('jobSelection.sorryNoMatch')}
					</Heading>
					<Body as="div" weight={400} size="medium">
						{t('jobSelection.noResultReason')}
					</Body>

					<div>
						<InfoItem icon={<Wrench size="small" />}>
							<Body as="div" size="small">
								{t('jobSelection.updateFilters')}
							</Body>
						</InfoItem>
						{/* <InfoItem icon = {<Location size = "small" />}>
          <Body as="div" size="small">Expand your location search radius.</Body>
        </InfoItem> */}
						{getPrevScreen.applicantId !== 0 && (
							<InfoItem icon={<User size="small" />}>
								<Body as="div" size="small">
									{t('jobSelection.updateShiftOrEmpType')}
								</Body>
							</InfoItem>
						)}
						<InfoItem icon={<Clock size="small" />}>
							<Body as="div" size="small">
								{t('jobSelection.checkBackLater')}
							</Body>
						</InfoItem>
					</div>
				</div>
			</div>
		);
	};
	useEffect(() => {
		const newSearch =
			getPrevScreen.subHeader.routeTo === '' || getPrevScreen.subHeader.routeTo === 'job-search';
		dispatch(
			subHeader({ title: 'jobSelection.screenTitle', routeTo: 'location-search', display: 'true' })
		);
		if (selectedJobsStore.jobSearchResponse == null || !newSearch) {
			if (locationSelector.selectedPos && locationSelector.selectedPos.length > 0) {
				try {
					getJobs();
				} catch (e) {
					getJobList([]);
				}
			} else {
				navigate('../location-search');
			}
		}
		// eslint-disable-next-line
	}, []);

	return selectedJobsStore.jobSearchResponse && !getPrevScreen.loading ? (
		<div className={styles.jobSearchContainer}>
			{' '}
			<div className={styles.desktopLayout}>
				<JobSearchFilter
					updateData={getJobList}
					futureTab={selectedJobsStore.currentTab}
					data={selectedJobsStore.jobSearchResponse}
					filtersList={filters}
				/>
				{getFacilityList().store <= 0 && getFacilityList().warehouse <= 0 ? (
					noResults()
				) : (
					<div className={styles.tabsContainer} role="tabpanel" aria-label={t('ariaLabelTexts.jobTabs')}>
						<div className={styles.desktopTabsContainer}>
							<TabNavigation
								role="tablist"
								aria-orientation="vertical"
								UNSAFE_style={{ width: '100%' }}
								UNSAFE_className={styles.tabContainer}
								aria-label={t('ariaLabelTexts.jobTabNavigation')}>
								<div className={styles.tabPositions}>
									<div className={styles.tabLayout}>
										<TabNavigationItem
											role="tab"
											aria-label={t('ariaLabelTexts.currentOpeningsTab')}
											aria-controls="JobSearchFilterPanel"
											UNSAFE_style={{ width: '100%' }}
											onClick={() => switchTab(true)}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													switchTab(true);
												}
											}}
											tabIndex="0"
											isCurrent={selectedJobsStore.currentTab}
											aria-selected={selectedJobsStore.currentTab}>
											<div style={{ width: '100%' }}>
												{t('jobSelection.currentOpenings')} ({jobCount[1]})
											</div>
										</TabNavigationItem>
										<TabNavigationItem
											role="tab"
											aria-label={t('ariaLabelTexts.futureOpeningsAlert')}
											aria-controls="JobSelectionFutureAlertPanel"
											UNSAFE_style={{ width: '100%' }}
											onClick={() => switchTab(false)}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													switchTab(false);
												}
											}}
											tabIndex="0"
											isCurrent={!selectedJobsStore.currentTab}
											aria-selected={!selectedJobsStore.currentTab}>
											{t('jobSelection.futureOpenings')} ({jobCount[0]})
										</TabNavigationItem>
									</div>
								</div>
							</TabNavigation>
							{selectedJobsStore.currentTab ? (
								<div
									id="JobSearchFilterPanel"
									className={styles.desktopOnly}
									role="tabpanel"
									aria-label={t('ariaLabelTexts.jobSearchFilter')}>
									<JobSearchFilter
										filterCrumb={true}
										updateData={getJobList}
										futureTab={selectedJobsStore.currentTab}
										data={selectedJobsStore.jobSearchResponse}
										filtersList={filters}
									/>
								</div>
							) : null}
						</div>
						<div className={styles.desktopScroll}>
							{!selectedJobsStore.currentTab ? (
								<div
									id="JobSelectionFutureAlertPanel"
									className={styles.jobSelectionFutureAlert}
									role="alert"
									aria-label={t('ariaLabelTexts.futureOpeningsAlert')}>
									<Alert variant="info">
										<Body as="div" size="small">
											{t('jobSelection.futureOpeningsAlertMsg')} <br />{' '}
											{t('jobSelection.futureOpeningsAlertNote')}
										</Body>
									</Alert>
								</div>
							) : null}
							<JobList
								role="list"
								aria-label={t('ariaLabelTexts.jobList')}
								details={detailsPressed}
								futureTab={selectedJobsStore.currentTab}
								data={filteredJobs}
								swapTab={() => switchTab(!selectedJobsStore.currentTab)}
								select={selectJob}
								selected={selectedJobsStore.currentSelectedJobs}
								showCountryCode={showCountryCode}
								checkIfPreferred={checkIfPreferred}
							/>
						</div>
					</div>
				)}
			</div>
			<div className={styles.jobselectionFooter}>
				<JobSelectionFooter
					selected={selectedJobsStore.currentSelectedJobs}
					continue={continueButton}
					remove={selectJob}
					setIsAgeUpdateRequired={setIsAgeUpdateRequired}
					showCountryCode={showCountryCode}
				/>
			</div>
			<Panel
				isOpen={detailsOpen || forceOpen}
				onClose={() => {
					closeSideBar();
				}}
				title={t('jobSelection.jobDetails')}
				position="right"
				size="large">
				<JobDetails select={selectJob} changeClosed={setDetailsOpen} updateForce={setForceOpen} />
			</Panel>
			<AlertDialog
				isOpen={continueAlertOpen}
				onClose={() => setContinueAlertOpen(false)}
				title={t('location.notice')}
				actions={
					<div>
						<Button
							data-testid="saveJobPreference_no"
							onClick={() => setContinueAlertOpen(false)}
							size="medium"
							variant="tertiary"
							UNSAFE_style={{ padding: '0 32px' }}>
							{t('general.no')}
						</Button>
						<Button
							onClick={saveJobPreference}
							size="medium"
							variant="primary"
							data-testid="saveJobPreference_yes">
							{t('general.yes')}
						</Button>
					</div>
				}>
				<Body as="div" size="medium" weight={400}>
					{t('general.confirmJobsContinue')}
				</Body>
			</AlertDialog>
		</div>
	) : null;
};

export default JobSelection;
