import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateVjoNextStepInd } from 'redux/Account/slice';
import { getUpdateStepIndicator } from 'redux/updateStepIndicator/request';
import { useVirtualJobOffer } from './useVirtualJobOffer';
import { updateStepIndicatorResp } from 'redux/updateStepIndicator/slice';

/**
 * Custom hook to consume the logics for VJO step indicators
 * @returns updateStepIndicator - callback to upadte the step indicator
 */
export const useVjoStepIndicator = () => {
	const { account, nextStepInd } = useVirtualJobOffer();
	const dispatch = useDispatch();

	const stepIndicatorResponse = useSelector((state) => state.UpdateStepIndicator?.data);

	useEffect(() => {
		if (stepIndicatorResponse === true) {
			dispatch(updateVjoNextStepInd({ nextStepInd: nextStepInd + 1 }));
			dispatch(updateStepIndicatorResp(false));
		}
	}, [stepIndicatorResponse, dispatch, nextStepInd]);

	// Update Step indicator
	const updateStepIndicator = useCallback(() => {
		const { applicantId, storeNbr, reqsnId, jobOfferNbr } = account?.data?.auth?.vjo;
		if (applicantId && storeNbr && reqsnId && jobOfferNbr) {
			dispatch(
				getUpdateStepIndicator({
					requestInput: {
						jobOfferNbr: jobOfferNbr,
						applicantId: applicantId,
						nextStepInd: nextStepInd + 1
					},
					requisitionId: reqsnId,
					storeNbr: storeNbr
				})
			);
		}
	}, [nextStepInd, account, dispatch]);
	return {
		updateStepIndicator
	};
};
