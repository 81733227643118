import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	editProfileJob: false,
	editProfileLicense: false,
	editAvailability:false,
	shiftUpdateNeeded: false,
	scheduleUpdateNeeded:false,
	fromEditProfile: false,
	editProfileShowAsmt: false
};

export const EditProfileSlice = createSlice({
	name: 'editProfile',
	initialState,
	reducers: {
		upateEditProfileJob: (state, action) => {
			state.editProfileJob = action.payload;
		},
		upateEditProfileLicense: (state, action) => {
			state.editProfileLicense = action.payload;
		},
		updateEditAvailability: (state, action) => {
			state.editAvailability = action.payload;
		},
		isShiftUpdateNeeded: (state, action) => {
			state.shiftUpdateNeeded = action.payload;
		},
		isScheduleUpdateNeeded: (state, action) => {
			state.scheduleUpdateNeeded = action.payload;
		},
		setFromEditProfile: (state, action) => {
			state.fromEditProfile = action.payload;
		},
		updateEditProfileShowAsmt: (state, action) => {
			state.editProfileShowAsmt = action.payload;
		},
	}
});

export const { upateEditProfileJob, upateEditProfileLicense, updateEditAvailability, isShiftUpdateNeeded ,isScheduleUpdateNeeded, setFromEditProfile, updateEditProfileShowAsmt} = EditProfileSlice.actions;

export default EditProfileSlice.reducer;
