import {
	Body,
	Card,
	CardContent,
	Radio,
	Divider,
	Tag,
	Button,
	SkeletonText,
	WizardFooter
} from '@walmart-web/livingdesign-components';
import { Store, Truck } from '@livingdesign/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import style from './MinimumQualifications.module.css';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import mediaImg from 'assets/images/minQualMedia.png';
import { executionErrorOccured, subHeader, updateLoading } from '../../redux/slices/globalSlice';
import AlertDialog from 'components/AlertDialog';
import { useDisplay } from 'utils/useDisplay';

import { minPrefQualifications, saveQualifications } from 'redux/MinimumQualifications/request';
import { useNavigate } from 'react-router-dom';

const MinimumQualifications = () => {
	const navigate = useNavigate();
	const global = useSelector((state) => state.global);
	const account = useSelector((state) => state.account);
	const editProfile = useSelector((state) => state.editProfile);
	const taxQuestionnaireCompleted = useSelector((state) => state.questionnaire?.questionnaireCompleted);
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { isMobile } = useDisplay();

	const answers = [];

	const [isLoading, setIsLoading] = useState(true);

	const [storeJobs, setStoreJobs] = useState([]);
	const [storeMinQuals, setStoreMinQuals] = useState([]);
	const [storePrefQuals, setStorePrefQuals] = useState([]);
	const [warehouseJobs, setWarehouseJobs] = useState([]);
	const [warehouseMinQuals, setWarehouseMinQuals] = useState([]);
	const [warehousePrefQuals, setWarehousePrefQuals] = useState([]);
	const [answeredQuals, setAnsweredQauls] = useState([]);

	const [values, setValues] = useState({});
	const [displayAlert, setDisplayAlert] = useState(false);

	const previousButton = t('general.previous');
	const continueButton = t('general.continue');
	const minimumQualificationsTitle = 'minimumQualifications.title';
	const assessmentsUrl = '../assessments';
	const pad_16_0_16_0 = '16px 0px 16px 0px';
	const generalYes = 'general.yes';
	const generalNo = 'general.no';

	useEffect(() => {
		dispatch(updateLoading(true));
		getMinPrefQualifications();
	}, []);

	const getMinPrefQualifications = async () => {
		dispatch(await minPrefQualifications({ applicantId: global.applicantId }))
			.then((res) => {
				dispatch(updateLoading(false));
				setIsLoading(false);
				const key = 'qualId';
				const scMinQuals = [
					...new Map(
						res.payload.response.scQuals?.minQualsList?.map((item) => [item[key], item])
					).values()
				];
				const scPrefQuals = [
					...new Map(
						res.payload.response.scQuals?.prefQualsList?.map((item) => [item[key], item])
					).values()
				];
				const shMinQuals = [
					...new Map(
						res.payload.response.shQuals?.minQualsList?.map((item) => [item[key], item])
					).values()
				];
				const shPrefQuals = [
					...new Map(
						res.payload.response.shQuals?.prefQualsList?.map((item) => [item[key], item])
					).values()
				];

				const allQuals = [...scMinQuals, ...scPrefQuals, ...shMinQuals, ...shPrefQuals];
				let temp = {};

				allQuals.forEach((quals) => {
					temp = { ...temp, [quals.qualId]: { applAnswer: quals.applAnswer } };
				});
				setValues(temp);

				setStoreMinQuals(shMinQuals);
				setStorePrefQuals(shPrefQuals);
				setWarehouseMinQuals(scMinQuals);
				setWarehousePrefQuals(scPrefQuals);
				const shJobs = new Map();
				shMinQuals.forEach((job) => {
					shJobs.set(job.jobClass, job.jobClassText);
				});
				setStoreJobs(Array.from(shJobs.values()));

				const scJobs = new Map();
				scMinQuals.forEach((job) => {
					scJobs.set(job.jobClass, job.jobClassText);
				});
				setWarehouseJobs(Array.from(scJobs.values()));

				if (shMinQuals.length || scMinQuals.length) {
					dispatch(
						subHeader({
							title: minimumQualificationsTitle,
							routeTo: 'login',
							display: 'true'
						})
					);
				} else {
					navigate(assessmentsUrl);
				}
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const saveMinQualifications = async () => {
		const filterAnswers = [];
		Object.keys(values).forEach(function (key) {
			if (values[key].applAnswer !== null) {
				filterAnswers.push({ qualId: key, applAnswer: values[key].applAnswer });
			}
		});

		const saveQuals = {
			applicantID: global.applicantId,
			applQualAnswers: filterAnswers
		};

		if (!filterAnswers.length) {
			navigate(assessmentsUrl);
		} else {
			dispatch(saveQualifications(saveQuals))
				.then((res) => {
					if(editProfile.fromEditProfile && !editProfile.editProfileShowAsmt){
						navigate('../post-apply-dashboard');
					} else {
						navigate(assessmentsUrl);
					}
				})
				.catch((err) => {
					dispatch(executionErrorOccured());
				});
		}
	};

	const handleQualifications = (e, answer) => {
		answers.push(...answeredQuals, { qualId: parseInt(e.target.name), applAnswer: answer });
		setValues({
			...values,
			// [e.target.name]: { yes: true, no: false },
			[e.target.name]: { qualId: parseInt(e.target.name), applAnswer: answer }
		});
		setAnsweredQauls(answers);
	};

	const eligibleToProceed = () => {
		const questions =
			storeMinQuals.length +
			storePrefQuals.length +
			warehouseMinQuals.length +
			warehousePrefQuals.length;
		if (Object.values(values).filter((value) => value.applAnswer !== null).length === questions) {
			saveMinQualifications();
		} else {
			setDisplayAlert(true);
		}
	};

	const onCloseClick = useCallback(() => {
		setDisplayAlert(false);
	}, [displayAlert]);

	const submitButton = useMemo(() => {
		return (
			<div className={style.submitButtons}>
				<Button
					onClick={onCloseClick}
					size="medium"
					variant="tertiary"
					UNSAFE_style={{ padding: 0 }}>
					{t('general.cancel')}
				</Button>
				<Button onClick={saveMinQualifications} size="medium" variant="primary">
					{t('general.continue')}
				</Button>
			</div>
		);
	}, [t, values]);

	const jobView = (
		<div style={{ paddingBottom: '8px', padding: '16px' }}>
			<Body weight={400} size="medium">
				{t('minimumQualifications.completeRequiredQuals')}
				{isLoading ? (
					<SkeletonText height="16px" variant="rectangle" />
				) : (
					<ul>
						{storeJobs?.map((job, index) => (
							<li key={index}>{job[global.language === 'ES' ? 102 : 101]}</li>
						))}
						{warehouseJobs?.map((job, index) => (
							<li key={index}>{job[global.language === 'ES' ? 102 : 101]}</li>
						))}
					</ul>
				)}
				<span style={{ fontStyle: 'italic' }}>{t('minimumQualifications.note')}</span>
			</Body>
		</div>
	);
	const mobileQualsView = () => {
		return (
			<>
				{storeMinQuals && storeMinQuals.length !== 0 && <Card>{storeQualificationsView}</Card>}
				{warehouseMinQuals && warehouseMinQuals.length !== 0 && (
					<Card UNSAFE_style={{ marginTop: '16px' }}>{warehousesQualificationsView}</Card>
				)}
			</>
		);
	};
	const storeQualificationsView = (
		<div>
			{storeMinQuals && storeMinQuals.length !== 0 && (
				<CardContent UNSAFE_style={{ lineHeight: '24px' }}>
					<Tag
						UNSAFE_style={{ marginBottom: '10px' }}
						variant="secondary"
						color="blue"
						leading={<Store size="medium" />}>
						{t('minimumQualifications.stores')}
					</Tag>
					<div>
						<Body
							as="h1"
							weight={700}
							size="medium"
							UNSAFE_style={{ padding: pad_16_0_16_0 }}>
							{t(minimumQualificationsTitle)}*
						</Body>
					</div>
					{storeMinQuals.map((item, index) => {
						return (
							<div key={index}>
								<Body as="p" weight={400} UNSAFE_style={{ lineHeight: '24px' }} size="small">
									{item.qualDesc[global.language === 'ES' ? 102 : 101]}
								</Body>
								<div style={{ lineHeight: '40px' }}>
									<Radio
										checked={values[item.qualId]?.applAnswer === 1}
										label={t(generalYes)}
										name={item.qualId}
										onChange={(e) => handleQualifications(e, 1)}
										value={1}
									/>
									<br />
									<Radio
										checked={values[item.qualId]?.applAnswer === 2}
										label={t(generalNo)}
										name={item.qualId}
										onChange={(e) => handleQualifications(e, 2)}
										value={2}
									/>
								</div>
								{(index !== storeMinQuals.length - 1 ||
									storePrefQuals.length !== 0 ||
									warehouseMinQuals.length !== 0) && (
									<Divider UNSAFE_style={{ margin: pad_16_0_16_0 }} />
								)}
							</div>
						);
					})}
					{storePrefQuals.length !== 0 && (
						<div>
							<Body
								as="h1"
								weight={700}
								size="medium"
								UNSAFE_style={{ padding: '0px 0px 16px 0px' }}>
								{t('minimumQualifications.preferredQualifications')}
							</Body>
						</div>
					)}
					{storePrefQuals?.map((item, index) => {
						return (
							<div key={index}>
								<Body as="p" weight={400} UNSAFE_style={{ lineHeight: '24px' }} szie="small">
									{item.qualDesc[global.language === 'ES' ? 102 : 101]}
								</Body>
								<div style={{ lineHeight: '40px' }}>
									<Radio
										checked={values[item.qualId]?.applAnswer === 1}
										label={t(generalYes)}
										name={item.qualId}
										onChange={(e) => handleQualifications(e, 1)}
										value={1}
									/>
									<br />
									<Radio
										checked={values[item.qualId]?.applAnswer === 2}
										label={t(generalNo)}
										name={item.qualId}
										onChange={(e) => handleQualifications(e, 2)}
										value={2}
									/>
								</div>
								{(index !== storePrefQuals?.length - 1 ||
									!isMobile ||
									warehouseMinQuals.length !== 0) && (
									<Divider UNSAFE_style={{ margin: '16px 0px 0px 0px' }} />
								)}
							</div>
						);
					})}
				</CardContent>
			)}
		</div>
	);

	const warehousesQualificationsView = (
		<div>
			{warehouseMinQuals && warehouseMinQuals.length !== 0 && (
				<CardContent UNSAFE_style={{ lineHeight: '24px' }}>
					<Tag
						UNSAFE_style={{ marginBottom: '10px' }}
						variant="secondary"
						color="purple"
						leading={<Truck size="medium" />}>
						{t('minimumQualifications.warehouses')}
					</Tag>
					<div>
						<Body
							as="h1"
							weight={700}
							size="medium"
							UNSAFE_style={{ padding: pad_16_0_16_0 }}>
							{t(minimumQualificationsTitle)}*
						</Body>
					</div>
					{warehouseMinQuals.map((item, index) => {
						return (
							<div key={index}>
								<Body as="p" weight={400} UNSAFE_style={{ lineHeight: '24px' }} szie="small">
									{item.qualDesc[global.language === 'ES' ? 102 : 101]}
								</Body>
								<div style={{ lineHeight: '40px' }}>
									<Radio
										checked={values[item.qualId]?.applAnswer === 1}
										label={t(generalYes)}
										name={item.qualId}
										onChange={(e) => handleQualifications(e, 1)}
										value={1}
									/>
									<br />
									<Radio
										checked={values[item.qualId]?.applAnswer === 2}
										label={t(generalNo)}
										name={item.qualId}
										onChange={(e) => handleQualifications(e, 2)}
										value={2}
									/>
								</div>
								{(index !== warehouseMinQuals.length - 1 || warehousePrefQuals.length !== 0) && (
									<Divider UNSAFE_style={{ margin: pad_16_0_16_0 }} />
								)}
							</div>
						);
					})}
					{warehousePrefQuals.length !== 0 && (
						<div>
							<Body
								as="h1"
								weight={700}
								size="medium"
								UNSAFE_style={{ padding: '0px 0px 16px 0px' }}>
								{t('minimumQualifications.preferredQualifications')}
							</Body>
						</div>
					)}
					{warehousePrefQuals?.map((item, index) => {
						return (
							<div key={index}>
								<Body as="p" weight={400} UNSAFE_style={{ lineHeight: '24px' }} szie="small">
									{item.qualDesc[global.language === 'ES' ? 102 : 101]}
								</Body>
								<div style={{ lineHeight: '40px' }}>
									<Radio
										checked={values[item.qualId]?.applAnswer === 1}
										label={t(generalYes)}
										name={item.qualId}
										onChange={(e) => handleQualifications(e, 1)}
										value={1}
									/>
									<br />
									<Radio
										checked={values[item.qualId]?.applAnswer === 2}
										label={t(generalNo)}
										name={item.qualId}
										onChange={(e) => handleQualifications(e, 2)}
										value={2}
									/>
								</div>
								{index !== warehousePrefQuals?.length - 1 && (
									<Divider UNSAFE_style={{ margin: pad_16_0_16_0 }} />
								)}
							</div>
						);
					})}
				</CardContent>
			)}
		</div>
	);

	/*
  -----------------JSX PART -------------------------
  */
	return (
		<>
			<div className={style.container}>
				{!isLoading && (
					<>
						<div className={style.title}></div>

						<div className={style.cardContainer}>
							{isMobile ? (
								<div>
									<img src={mediaImg} alt="" className={style.image} />

									<div>
										{jobView}
										<div style={{ padding: '16px' }}>{mobileQualsView()}</div>
									</div>
								</div>
							) : (
								<Card>
									<img src={mediaImg} alt="" className={style.image} />

									<div className={style.infoContainer}>
										{jobView}
										{storeQualificationsView}
										{warehousesQualificationsView}
									</div>
								</Card>
							)}
						</div>

						<AlertDialog
							isOpen={displayAlert}
							onClose={onCloseClick}
							title={t('declineOffer.title')}
							children={<Body>{t('minimumQualifications.alertToProceed')}</Body>}
							actions={submitButton}
						/>
					</>
				)}
			</div>
			<div className={style.bottomFooter}>
				<WizardFooter
					previousActionProps={{
						UNSAFE_className: 'wizard_footer_previous',
						onClick: () => {
							if(editProfile.fromEditProfile) {
								navigate('../edit-profile');
							} else if (account?.data?.auth?.stepNbr < 850) {
								if (account.data.auth.onlyPrJobsPreferred) {
									navigate('../job-history');
								} else {
									if (taxQuestionnaireCompleted) {
										navigate('../job-history');
									} else {
										navigate('../wotc-questionnaire');
									}
								}
							} else {
								navigate(-1);
							}
						},
						children: previousButton
					}}
					nextActionProps={{
						UNSAFE_className: 'wizard_footer_next',
						onClick: () => eligibleToProceed(),
						children: continueButton
					}}></WizardFooter>
			</div>
		</>
	);
};

export default MinimumQualifications;
