import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';
import {
	executionErrorOccured,
	updateIsCpRedirect,
	updateLoading,
	updatePreferedJobsFromRedirect,
	updatePreferedStoresFromRedirect
} from 'redux/slices/globalSlice';
import { selectStore, updateLocationSearch, updateStoresList } from 'redux/Location/slice';
import { getAllFacilities, getStoreDetails } from 'redux/Location/request';

export const useRedirect = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const global = useSelector((state) => state.global);

	// returns true if redirect is successful else false
	const handleRedirect = async (preferredStoresPreselected) => {
		//dispatch loading true
		dispatch(updateLoading(true));

		//dispatch the selected stores from redirect
		return dispatch(
			getStoreDetails({ storeNbrs: preferredStoresPreselected, applicantId: global.applicantId })
		)
			.then((res) => {
				const storeDetails = res.payload.response;
				if (!storeDetails?.length) {
					dispatch(updateLoading(false));
					return false;
				}
				else if(!storeDetails.some(obj => Number(obj.storeNumber) === preferredStoresPreselected[0])){
					dispatch(updateLoading(false));
					return false;
				} else {
					//make REST call to location search API to get stores By Zip
					const zipcode = storeDetails[0].postalCode;
					const queryParams = `zipCode=${zipcode}&applicantId=${global.applicantId}`;
					return dispatch(getAllFacilities(queryParams))
						.then((res) => {
							dispatch(
								updateLocationSearch({
									type: 'zipSearch',
									value: zipcode
								})
							);
							const data = res.payload.response;
							//below code upshifts the selected store to the top in the view
							const preferredStoresPreselectedSet = new Set(preferredStoresPreselected.map(Number));
                            let selectedObjects = [];
                            let nonSelectedObjects = [];

                            data.forEach(obj => {
                                const storeNumber = Number(obj.storeNumber);
                                if (preferredStoresPreselectedSet.has(storeNumber)) {
                                    selectedObjects.push(obj);
                                } else {
                                    nonSelectedObjects.push(obj);
                                }
                            });

                            const result = [...selectedObjects, ...nonSelectedObjects];
                            dispatch(updateStoresList(result));

                            const sameZipStores = selectedObjects;
							const remainingStores = storeDetails.filter(store => !sameZipStores.some(sameZipStore => store.storeNumber === sameZipStore.storeNumber));

							// Dispatch actions for sameZipStores
							sameZipStores.forEach(store => dispatch(selectStore(store)));

							// Dispatch actions for remainingStores
							remainingStores.forEach(store => dispatch(selectStore(store)));
							
							dispatch(updateLoading(false));
							navigate('../location-search');
							return true;
						})
						.catch(() => {
							dispatch(executionErrorOccured());
							return false;
						});
				}
			})
			.catch(() => {
				dispatch(executionErrorOccured());
				return false;
			});
	};

	const loadCookiesAndUpdateRedirectStore = () => {
		if (cookie.load('storeNbrs') || cookie.load('jobSelectionIds')) {
			// is redirect flow
			const preferredStores = cookie
				.load('storeNbrs')
				?.split(',')
				?.map((storeNbr) => parseInt(storeNbr))
				?.filter((storeNbr) => Number.isInteger(storeNbr));
			const preferredJobs = cookie
				.load('jobSelectionIds')
				?.split(',')
				?.map((jobSelectionId) => parseInt(jobSelectionId))
				?.filter((jobSelectionId) => Number.isInteger(jobSelectionId));
			dispatch(updateIsCpRedirect(true));
			dispatch(updatePreferedStoresFromRedirect(preferredStores));
			dispatch(updatePreferedJobsFromRedirect(preferredJobs));
			cookie.remove('storeNbrs', { path: '/' });
			cookie.remove('jobSelectionIds', { path: '/' });

			return { isCpRedirect: true, preferredStores, preferredJobs };
		} else if (!cookie.load('storeNbrs') && !cookie.load('jobSelectionIds')) {
			// is normal flow
			dispatch(updateIsCpRedirect(false));
			dispatch(updatePreferedStoresFromRedirect([]));
			dispatch(updatePreferedJobsFromRedirect([]));
		}
		return { isCpRedirect: false, preferredStores: [], preferredJobs: [] };
	};

	return { handleRedirect, loadCookiesAndUpdateRedirectStore };
};
