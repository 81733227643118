import { createSlice } from '@reduxjs/toolkit';
import { getDashboardInfo } from './request';
import { decorateResponse } from './helper';
import _ from 'lodash';

const initialState = { data: {}, error: {} };

const postApplyDashboardSlice = createSlice({
	name: 'postApplyDashboard',
	initialState,
	reducers: {
		updateJobTrackerData: (state, action) => {
			const { storeNbr, jobClassId, workGroupId } = action.payload.current;
			const dataClone = _.cloneDeep(state.data);
			const storeInfo = dataClone.locationDetails[storeNbr];
			const trackerDetails = storeInfo.trackerDetails;
			const position = storeInfo.positionApplied[workGroupId]?.positions;

			storeInfo.jobCodeMap[jobClassId] = false;
			storeInfo.positionApplied[workGroupId].showJobCard = position.some(
				(positionDetails, index) => {
					const { jobClassId } = positionDetails;
					return storeInfo.jobCodeMap[jobClassId] === true;
				}
			);
			storeInfo.showWorkGroup = Object.values(storeInfo.jobCodeMap).some(Boolean);
			storeInfo.withdrawMap[jobClassId] = false;

			trackerDetails[jobClassId] = 0;
			const stage = Math.max(...Object.values(trackerDetails));
			storeInfo.overAllStage = stage;

			state.data = dataClone;
		}
	},
	extraReducers(builder) {
		builder
			.addCase(getDashboardInfo.fulfilled, (state, action) => {
				state.data = decorateResponse(action.payload.response);
			})
			.addCase(getDashboardInfo.rejected, (state, action) => {
				state.error = { message: 'Something went wrong' };
			});
	}
});

export const { updateJobTrackerData } = postApplyDashboardSlice.actions;

export default postApplyDashboardSlice.reducer;
