import React from 'react';
import style from '../SelfSchedule.module.scss';
import { t } from 'i18next';
import { Button, Body } from '@walmart-web/livingdesign-components';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import { useSelfSchedule } from '../hooks/useSelfSchedule';
import { selfScheduleEventCodeMap } from 'config/const';

const SelfScheduleTourFooter = () => {
	const isMobile = useMediaQuery({ maxWidth: 425 });

	const { handleConfirm } = useSelfSchedule();
	const { handleConfirmOrientation } = useSelfSchedule();
	const { handleConfirmTour } = useSelfSchedule();
	const selfSchedule = useSelector((state) => state.selfSchedule);

	return (
		<div>
			{isMobile ? (
				<div className={style.ss_tour_footer}>
					<div className={style.ss_tour_footer_childrens}>
						<div>
							<Body as="div" size="medium" weight={400}>
								{t('selfSchedule.noDatesToChooseSc')}
							</Body>
						</div>
						<div className={style.tour_confirm_button}>
							<Button
								size="small"
								variant="primary"
								style={{ width: '100%', height: '5vh' }}
								disabled={!selfSchedule.timeChipSelected}
								onClick={
									selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_1 ||
									selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_2
										? handleConfirmOrientation
										: selfSchedule.eventCode === selfScheduleEventCodeMap.SC_RJPT_EVENT_CODE ||
										  selfSchedule.eventCode === selfScheduleEventCodeMap.SC_FT_EVENT_CODE
										? handleConfirmTour
										: handleConfirm
								}>
								{selfSchedule.eventCode === selfScheduleEventCodeMap.SC_INTERVIEW_EVENT_CODE
									? t('selfSchedule.confirmInterview')
									: selfSchedule.eventCode ===
											selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_1 ||
									  selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_2
									? t('selfSchedule.confirmOrientation')
									: t('selfSchedule.confirmTour')}
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div className={style.ss_tour_footer}>
					<div style={{ width: '25%' }}></div>
					<div style={{ width: '45%' }}>
						<Body as="div" size="medium" weight={400}>
							{t('selfSchedule.noDatesToChooseSc')}
						</Body>
					</div>
					<div
						className={style.tour_confirm_button}
						style={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							size="small"
							variant="primary"
							disabled={!selfSchedule.timeChipSelected}
							onClick={
								selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_1 ||
								selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_2
									? handleConfirmOrientation
									: selfSchedule.eventCode === selfScheduleEventCodeMap.SC_RJPT_EVENT_CODE ||
									  selfSchedule.eventCode === selfScheduleEventCodeMap.SC_FT_EVENT_CODE
									? handleConfirmTour
									: handleConfirm
							}>
							{selfSchedule.eventCode === selfScheduleEventCodeMap.SC_INTERVIEW_EVENT_CODE
								? t('selfSchedule.confirmInterview')
								: selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_1 ||
								  selfSchedule.eventCode === selfScheduleEventCodeMap.SC_ORIENTATION_EVENT_CODE_2
								? t('selfSchedule.confirmOrientation')
								: t('selfSchedule.confirmTour')}
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

export default SelfScheduleTourFooter;
