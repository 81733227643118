import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Stripe = (props) => {
  const { color, width } = props;
  return <div className="stripe" style={{ backgroundColor: color, width: width }} />;
};

Stripe.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number
};

Stripe.defaultProps = {
  color: '#ffc121',
  width: 80
};

export default Stripe;
