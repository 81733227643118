import {Body, Card, StyledText} from '@walmart-web/livingdesign-components';
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import {useDisplay} from '../../../../../utils/useDisplay';

/**
 * Provides information about the Job Offer
 * @param icon: Image
 * @param title: Title of the information Eg: Employee Type
 * @param info: data about the job detail. Eg: Full time
 * @returns - Memoized Portrait and Desktop views.
 */
const JobInfoCardItem = ({icon, title, info}) => {
  const {isLandscape} = useDisplay();

  // Desktop View
  const DeskTopView = useMemo(() => {
    return (
      <Card size='small' className='grid_item' UNSAFE_style={{ boxShadow: 'none' }}>
        <>
          <div className='heading_with_icon'>
          <img alt='celebration hand' src={icon} style={{width: '16px'}}/>
          <Body size='small' children={title} UNSAFE_className='header' />
          </div>
          <Body
            size='small'
            weight={700}
            children={info}
            UNSAFE_className='info'
          />
        </>
      </Card>
    );
  }, [icon, title, info]);

  // Portrait View
  const PortraitView = useMemo(() => {
    return (
      <div className='list_item'>
        <div className='list_item_left'>
          <StyledText
            UNSAFE_className='list_item_title'
            leading={<img alt='celebration hand' src={icon} />}
            size='small'
            children={title}
          />
        </div>
        <div className='list_item_right'>
          <Body
            size='small'
            weight={700}
            children={info}
            UNSAFE_className='header'
          />
        </div>
      </div>
    );
  }, [icon, title, info]);

  return isLandscape ? DeskTopView : PortraitView;
};

/**
 * PropTypes for the JobInfoCardItem component
 */
JobInfoCardItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

export default memo(JobInfoCardItem);
