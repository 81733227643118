import {createSlice} from '@reduxjs/toolkit';
import {getExternalJobOfferInfo, finalizeJobOffer} from './request';

const initialState = {data: {}, loading: false, error: {}};

const virtualJobOfferSlice = createSlice({
  name: 'virtualJobOffer',
  initialState,
  reducers: {
    updateFinalizingJobOfferFlag: (state, action) => {
      state.isFinalizingJobOffer = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getExternalJobOfferInfo.fulfilled, (state, action) => {
        state.data = action.payload.response;
      })
      .addCase(getExternalJobOfferInfo.rejected, (state) => {
        state.error = {message: 'Something went wrong'};
      })
      .addCase(finalizeJobOffer.fulfilled, (state, action) => {
        state.data.finalizeJobOffer = action.payload;
      })
      .addCase(finalizeJobOffer.rejected, (state) => {
        state.error = {message: 'Something went wrong'};
      });
  },
});

export const {updateFinalizingJobOfferFlag} = virtualJobOfferSlice.actions;

export default virtualJobOfferSlice.reducer;
