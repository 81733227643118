import { useVirtualJobOffer } from './useVirtualJobOffer';
import { useDrugScreenInfo } from './useDrugScreenInfo';
import { useMemo } from 'react';
import { STEPS, PAGES } from '../util';

/**
 * This custom hook converts the nextStepInd to the corresponding page.
 * @returns the page corresponding to the step and if drug check required
 */
export const useStepMapper = () => {
	const { nextStepInd } = useVirtualJobOffer();
	const { drugScreenRequiredResponse } = useDrugScreenInfo();

	const getPageFromStep = useMemo(() => {
		const step = nextStepInd - 1;

		if(drugScreenRequiredResponse?.drugScreenRequired) {
			if (step === STEPS.CONTACT_INFO) {
				return PAGES.CONTACT_INFO;
			} else if (step === STEPS.AVAILABILITY) {
				return PAGES.AVAILABILITY;
			} else if (step === STEPS.REVIEW) {
				return PAGES.REVIEW;
			} else if (step === STEPS.ACKNOWLEDGE || step === STEPS.ACKNOWLEDGE_DECLINED) {
				return PAGES.ACKNOWLEDGE;
			} else if (step === STEPS.DRUG_SCREEN_CHECK) {
				return PAGES.DRUG_SCREEN_CHECK;
			} else if (step === STEPS.BACKGROUND_CHECK_WITH_DS_REQUIRED) {
				return PAGES.BACKGROUND_CHECK;
			}
		} else {
			if (step === STEPS.CONTACT_INFO) {
				return PAGES.CONTACT_INFO;
			} else if (step === STEPS.AVAILABILITY) {
				return PAGES.AVAILABILITY;
			} else if (step === STEPS.REVIEW) {
				return PAGES.REVIEW;
			} else if (step === STEPS.ACKNOWLEDGE || step === STEPS.ACKNOWLEDGE_DECLINED) {
				return PAGES.ACKNOWLEDGE;
			} else if (step === STEPS.BACKGROUND_CHECK_WITHOUT_DS_REQUIRED) {
				return PAGES.BACKGROUND_CHECK - 1;
			}
		}
	}, [nextStepInd, drugScreenRequiredResponse?.drugScreenRequired]);

	return {
		getPageFromStep
	};
};
