import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import style from './MilitaryService.module.css';
import commonStyles from './../../../../Style/commonStyle.module.css'
import {
	Body,
	FormGroup,
	Radio,
	BottomSheet,
	BottomSheetCloseButton,
	Button,
	Select,
	Modal
} from '@walmart-web/livingdesign-components';
import moment from 'moment';
import { TextField as MuiTextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { militaryDrop } from '../../../../config/const';
import { animateScroll as scroll } from 'react-scroll';
import { executionErrorOccured, updateLoading } from '../../../../redux/slices/globalSlice';
import { callHiringHelper } from '../../../../redux/HiringHelper/request';

import {
	updateMilitaryAnswer,
	updateApplicantIdandDate,
	updateResponseText
} from '../../../../redux/Military/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getMilitaryInfo, saveMilitaryExp } from '../../../../redux/Military/request';
import { useMediaQuery } from 'react-responsive';

const MilitaryService = forwardRef((props, ref) => {
	const isDesktop = useMediaQuery({ minWidth: 851 });
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const { t } = useTranslation();
	const navigate = useNavigate();
	const military = useSelector((state) => state.military);
	const [dateValue, setDateValue] = useState('');
	const global = useSelector((state) => state.global);
	const account = useSelector((state) => state.account);
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState(false);
	const [militaryService, setMilitaryService] = useState({
		yes: false,
		no: false,
		dontWishToAnswer: false
	});
	const militaryNo = 'military.no';
	const militaryDontWish = 'military.dontWish';

	const [partnerInServices, setPartnerInServices] = useState({
		activeDuty: false,
		guardOrReserve: false,
		veteran: false,
		dontWishToAnswer: false,
		no: false
	});

	const [activeDuty, setActiveDuty] = useState({
		yes: false,
		no: false,
		dontWishToAnswer: false
	});

	const [activeDutyList, setActiveDutyList] = useState({
		answer: ''
	});

	const [guardList, setGuardList] = useState({
		answer: ''
	});

	const [disableButton, setDisableButton] = useState({
		serviceCheck: true,
		partnerServiceCheck: true,
		activeDutyDropdown: true,
		guardDutyDropdown: true,
		activeDutyStatus: true,
		seperationDate: true
	});

	useEffect(() => {
		props.handleDisableButton();
		// eslint-disable-next-line
	}, [disableButton]);

	useEffect(() => {
		const date = new Date();
		const dateString =
			date.getFullYear() +
			'-' +
			('0' + (date.getMonth() + 1)).slice(-2) +
			'-' +
			('0' + date.getDate()).slice(-2);

		dispatch(
			updateApplicantIdandDate({
				applicantId: global.applicantId,
				dateString: dateString
			})
		);
		getMilitaryInformation();
		// eslint-disable-next-line
	}, []);

	const getMilitaryInformation = () => {
		const req = { applicantId: global.applicantId };
		dispatch(updateLoading(true));
		dispatch(getMilitaryInfo(req))
			.then((res) => {
				const data = res.payload;
				const answersList = data.answersList;
				let actAnswr = 0,
					actDropAnswr = 0,
					guardDropAnswr = 0,
					leftAnswr = 0,
					sepAnswr = '',
					spouseAnswr = 0;
				if (answersList?.length > 0) {
					answersList.forEach((obj) => {
						if (obj.questionNumber === 1) {
							actAnswr = obj.optionNumber;
							dispatch(updateMilitaryAnswer({ ques: 1, ans: actAnswr }));
							if (actAnswr === 1) {
								setMilitaryService((prevState) => ({
									...prevState,
									yes: true
								}));
							} else if (actAnswr === 2) {
								setMilitaryService((prevState) => ({
									...prevState,
									no: true
								}));
							} else if (actAnswr === 4) {
								setMilitaryService((prevState) => ({
									...prevState,
									dontWishToAnswer: true
								}));
							}
						} else if (obj.questionNumber === 2) {
							actDropAnswr = obj.optionNumber;
							dispatch(updateMilitaryAnswer({ ques: 2, ans: actDropAnswr }));
							setActiveDutyList({ answer: actDropAnswr });
						} else if (obj.questionNumber === 3) {
							guardDropAnswr = obj.optionNumber;
							dispatch(
								updateMilitaryAnswer({
									ques: 3,
									ans: guardDropAnswr
								})
							);
							setGuardList({ answer: guardDropAnswr });
						} else if (obj.questionNumber === 4) {
							leftAnswr = obj.optionNumber;
							dispatch(updateMilitaryAnswer({ ques: 4, ans: leftAnswr }));
							if (leftAnswr === 1) {
								setActiveDuty((prevState) => ({
									...prevState,
									yes: true
								}));
							} else if (leftAnswr === 2) {
								setActiveDuty((prevState) => ({
									...prevState,
									no: true
								}));
							} else if (leftAnswr === 4) {
								setActiveDuty((prevState) => ({
									...prevState,
									dontWishToAnswer: true
								}));
							}
						} else if (obj.questionNumber === 5) {
							sepAnswr = obj.responseTxt;
							if (sepAnswr !== '') {
								dispatch(updateMilitaryAnswer({ ques: 5, ans: 8 }));
								setDateValue(new Date(sepAnswr));
								let responseText;
								const answr =
									sepAnswr.slice(6) + '-' + sepAnswr.slice(0, 2) + '-' + sepAnswr.slice(3, 5);
								if (answr.includes('-')) {
									const dateVal = moment(answr);
									responseText = Date.UTC(
										dateVal.format('YYYY'),
										dateVal.format('MM') - 1,
										dateVal.format('DD'),
										23,
										59,
										59
									);
								} else {
									responseText = answr;
								}
								dispatch(updateResponseText({ ques: 5, ans: responseText }));
							}
						} else if (obj.questionNumber === 51) {
							spouseAnswr = obj.optionNumber;
							dispatch(updateMilitaryAnswer({ ques: 51, ans: spouseAnswr }));
							if (spouseAnswr === 25) {
								setPartnerInServices((prevState) => ({
									...prevState,
									activeDuty: true
								}));
							} else if (spouseAnswr === 26) {
								setPartnerInServices((prevState) => ({
									...prevState,
									guardOrReserve: true
								}));
							} else if (spouseAnswr === 27) {
								setPartnerInServices((prevState) => ({
									...prevState,
									veteran: true
								}));
							} else if (spouseAnswr === 4) {
								setPartnerInServices((prevState) => ({
									...prevState,
									dontWishToAnswer: true
								}));
							} else if (spouseAnswr === 2) {
								setPartnerInServices((prevState) => ({
									...prevState,
									no: true
								}));
							}
						}
					});
					setDisableButton({
						serviceCheck: false,
						partnerServiceCheck: false,
						activeDutyDropdown: false,
						guardDutyDropdown: false,
						activeDutyStatus: false,
						seperationDate: false
					});
				}
				dispatch(updateLoading(false));
				if (props.editProfile === undefined) {
					scroll.scrollToBottom();
				}
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const handleMilitaryService = (e) => {
		const value = e.target.name;
		if (value === 'yes') {
			setMilitaryService({
				yes: true,
				no: false,
				dontWishToAnswer: false
			});
			dispatch(updateMilitaryAnswer({ ques: 1, ans: 1 }));
		} else if (value === 'no') {
			setMilitaryService({
				yes: false,
				no: true,
				dontWishToAnswer: false
			});
			dispatch(updateMilitaryAnswer({ ques: 1, ans: 2 }));
		} else if (value === 'dontWishToAnswer') {
			setMilitaryService({
				yes: false,
				no: false,
				dontWishToAnswer: true
			});
			dispatch(updateMilitaryAnswer({ ques: 1, ans: 4 }));
		}
		setDisableButton((prevState) => ({
			...prevState,
			serviceCheck: false
		}));
	};

	const updateDateResponse = (dateValue) => {
		if (dateValue && dateValue !== '') {
			const dateMoment = moment(dateValue, 'DD-MM-YYYY', true);
			setDisableButton((prevState) => ({
				...prevState,
				seperationDate: !(
					dateMoment.isValid() &&
					dateMoment.year() > 1899 &&
					dateMoment.year() < 2099
				) //DatePicker allows date between 1899 - 2099 only
			}));

			setDateValue(dateValue);

			dispatch(
				updateMilitaryAnswer({
					ques: 5,
					ans: 8
				})
			);
			dateValue = dateMoment.format('DD-MM-YYYY');
			let responseText;
			const answr = dateValue.slice(6) + dateValue.slice(2, 6) + dateValue.slice(0, 2);
			if (answr.includes('-')) {
				const dateVal = moment(answr);
				responseText = Date.UTC(
					dateVal.format('YYYY'),
					dateVal.format('MM') - 1,
					dateVal.format('DD'),
					23,
					59,
					59
				);
			} else {
				responseText = answr;
			}
			dispatch(updateResponseText({ ques: 5, ans: responseText }));
		}
	};

	const handlePartnerServiceDetail = (e) => {
		const value = e.target.name;
		if (value === 'activeDuty') {
			setPartnerInServices({
				activeDuty: true,
				guardOrReserve: false,
				veteran: false,
				dontWishToAnswer: false,
				no: false
			});
			dispatch(updateMilitaryAnswer({ ques: 51, ans: 25 }));
		} else if (value === 'guardOrReserve') {
			setPartnerInServices({
				activeDuty: false,
				guardOrReserve: true,
				veteran: false,
				dontWishToAnswer: false,
				no: false
			});
			dispatch(updateMilitaryAnswer({ ques: 51, ans: 26 }));
		} else if (value === 'veteran') {
			setPartnerInServices({
				activeDuty: false,
				guardOrReserve: false,
				veteran: true,
				dontWishToAnswer: false,
				no: false
			});
			dispatch(updateMilitaryAnswer({ ques: 51, ans: 27 }));
		} else if (value === 'dontWishToAnswer') {
			setPartnerInServices({
				activeDuty: false,
				guardOrReserve: false,
				veteran: false,
				dontWishToAnswer: true,
				no: false
			});
			dispatch(updateMilitaryAnswer({ ques: 51, ans: 4 }));
		}
		if (value === 'no') {
			setPartnerInServices({
				activeDuty: false,
				guardOrReserve: false,
				veteran: false,
				dontWishToAnswer: false,
				no: true
			});
			dispatch(updateMilitaryAnswer({ ques: 51, ans: 2 }));
		}
		setDisableButton((prevState) => ({
			...prevState,
			partnerServiceCheck: false
		}));
	};

	const handleActiveDutyStatus = (e) => {
		const value = e.target.name;
		if (value === 'yes') {
			setActiveDuty({
				yes: true,
				no: false,
				dontWishToAnswer: false
			});
			dispatch(updateMilitaryAnswer({ ques: 4, ans: 1 }));
			setDisableButton((prevState) => ({
				...prevState,
				seperationDate: true
			}));
			setDateValue('');
		} else if (value === 'no') {
			setActiveDuty({
				yes: false,
				no: true,
				dontWishToAnswer: false
			});
			dispatch(updateMilitaryAnswer({ ques: 4, ans: 2 }));
			setDisableButton((prevState) => ({
				...prevState,
				seperationDate: false
			}));
		} else if (value === 'dontWishToAnswer') {
			setActiveDuty({
				yes: false,
				no: false,
				dontWishToAnswer: true
			});
			dispatch(updateMilitaryAnswer({ ques: 4, ans: 4 }));
			setDisableButton((prevState) => ({
				...prevState,
				seperationDate: false
			}));
		}
		setDisableButton((prevState) => ({
			...prevState,
			activeDutyStatus: false
		}));
	};

	const handleActiveDutyList = (e) => {
		const value = e.target.value;
		setActiveDutyList(value);
		if (value !== '') {
			dispatch(updateMilitaryAnswer({ ques: 2, ans: value }));
			setDisableButton((prevState) => ({
				...prevState,
				activeDutyDropdown: false
			}));
		} else {
			setDisableButton((prevState) => ({
				...prevState,
				activeDutyDropdown: true
			}));
		}
	};

	const handleGuardList = (e) => {
		const value = e.target.value;
		setGuardList(value);
		if (value !== '') {
			dispatch(updateMilitaryAnswer({ ques: 3, ans: value }));
			setDisableButton((prevState) => ({
				...prevState,
				guardDutyDropdown: false
			}));
		} else {
			setDisableButton((prevState) => ({
				...prevState,
				guardDutyDropdown: true
			}));
		}
	};

	const handleMilitaryData = async () => {
		const req = military.militaryInfo;
		dispatch(updateLoading(true));
		dispatch(saveMilitaryExp(req))
			.then((res) => {
				if (account?.data?.auth?.stepNbr >= 850) {
					dispatch(callHiringHelper(global.applicantId));
				}
				dispatch(updateLoading(false));
				if (props.editProfile === undefined) {
					navigate('../eeo');
				}
				if (props.editProfile === true) {
					props.checkMilitaryResponse(res.payload.responseCode);
				}
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	useImperativeHandle(ref, () => ({
		shouldDisableContinue() {
			if (!militaryService.yes) {
				return !disableButton.serviceCheck && !disableButton.partnerServiceCheck ? false : true;
			} else {
				return !disableButton.serviceCheck &&
					!disableButton.partnerServiceCheck &&
					!disableButton.activeDutyDropdown &&
					!disableButton.guardDutyDropdown &&
					!disableButton.activeDutyStatus &&
					!disableButton.seperationDate
					? false
					: true;
			}
		},

		handleButton() {
			handleMilitaryData();
		}
	}));

	return (
		<div>
			<FormGroup>
				<div className={props.editProfile ? style.pageEditProfile : style.page} id="militaryScreen">
					{props.editProfile === undefined && (
						<div>
							<Body as="p" size="Large" weight={700}>
								{t('military.title')}
							</Body>
						</div>
					)}
					{isMobile && (
						<BottomSheet
							isOpen={isOpen}
							onClose={() => setIsOpen(false)}
							title={(titleId) => (
								<div className={style.bottomSheet}>
									<div className={style.crossButton}>
										<BottomSheetCloseButton
											aria-label={t('ariaLabelTexts.closeDialog')}
											onClick={() => setIsOpen(false)}
										/>
									</div>

									<div className={style.militaryText_header}>
										<Body as="p" size="large" weight={700}>
											{t('military.hiringProgram')}
										</Body>
									</div>
								</div>
							)}>
							<div className={style.textPage}>
								<div>
									<Body as="p" size="medium" weight={400}>
										{t('military.text1')}
									</Body>
									<br></br>
									<Body as="p" size="medium" weight={400}>
										{t('military.text2')}
									</Body>
									<br></br>
									<Body as="p" size="medium" weight={400}>
										{t('military.text3')}
									</Body>
									<br></br>
									<Body as="p" size="medium" weight={400}>
										{t('military.text4')}
									</Body>
								</div>
							</div>
						</BottomSheet>
					)}
					{isDesktop && (
						<Modal
							size="large"
							isOpen={isOpen}
							onClose={() => setIsOpen(false)}
							title={t('military.hiringProgram')}>
							<div className={style.textPage}>
								<div>
									<Body as="p" size="medium" weight={400}>
										{t('military.text1')}
									</Body>
									<br></br>
									<Body as="p" size="medium" weight={400}>
										{t('military.text2')}
									</Body>
									<br></br>
									<Body as="p" size="medium" weight={400}>
										{t('military.text3')}
									</Body>
									<br></br>
									<Body as="p" size="medium" weight={400}>
										{t('military.text4')}
									</Body>
								</div>
							</div>
						</Modal>
					)}
					{props.editProfile === undefined && (
						<Button
							variant="tertiary"
							tabIndex="0"
							type="button"
							onClick={(e) => setIsOpen(true)}
							UNSAFE_style={{padding:0}}>
							{t('military.whyText')}
						</Button>
					)}
					<fieldset className={commonStyles.fieldSet}>
						<legend className={commonStyles.legend}>
							<Body as="p" size="medium" weight={400}>
								{t('military.activeDutyQues')}
							</Body>
						</legend>
						<Radio
							label={t('military.yes')}
							name="yes"
							onChange={(e) => {
								handleMilitaryService(e);
							}}
							checked={militaryService.yes}
							data-testid="military_yes_handleMilitaryService"
						/>
						<Radio
							label={t(militaryNo)}
							name="no"
							onChange={(e) => {
								handleMilitaryService(e);
							}}
							checked={militaryService.no}
						/>
						<Radio
							label={t(militaryDontWish)}
							name="dontWishToAnswer"
							onChange={(e) => {
								handleMilitaryService(e);
							}}
							checked={militaryService.dontWishToAnswer}
						/>
					</fieldset>
					{militaryService.yes && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								gap: '16px',
								flexGrow: '0'
							}}>
							<Body as="p" size="medium" weight={400}>
								{t('military.indicateStatus')}
							</Body>
							<div className={style.selectActiveGuardReserve}>
								<Select
									label={t('military.activeDuty')}
									value={activeDutyList.answer}
									onChange={(e) => {
										handleActiveDutyList(e);
									}}>
									<option value=""></option>
									{militaryDrop[global.language === 'ES' ? '102' : '101'].map((obj, index) => {
										return (
											<option key={index} value={obj.value}>
												{obj.viewValue}
											</option>
										);
									})}
								</Select>

								<Select
									label={t('military.guard')}
									value={guardList.answer}
									onChange={(e) => {
										handleGuardList(e);
									}}>
									<option value=""></option>
									{militaryDrop[global.language === 'ES' ? '102' : '101'].map((obj, index) => {
										return (
											<option key={index} value={obj.value}>
												{obj.viewValue}
											</option>
										);
									})}
								</Select>
							</div>
							<fieldset className={commonStyles.fieldSet}>
							<legend className={commonStyles.legend}>
								<Body as="p" size="medium" weight={400}>
									{t('military.alreadyLeft')}
								</Body>
							</legend>
								<Radio
									label={t('military.yes')}
									name="yes"
									onChange={(e) => {
										handleActiveDutyStatus(e);
									}}
									checked={activeDuty.yes}
									{...{'data-testid': 'military_yes_handleActiveDutyStatus'}}
								/>
								<Radio
									label={t(militaryNo)}
									name="no"
									onChange={(e) => {
										handleActiveDutyStatus(e);
									}}
									checked={activeDuty.no}
								/>
								<Radio
									label={t(militaryDontWish)}
									name="dontWishToAnswer"
									onChange={(e) => {
										handleActiveDutyStatus(e);
									}}
									checked={activeDuty.dontWishToAnswer}
								/>
							</fieldset>
							{activeDuty.yes && (
								<div style={{ width: '100%' }}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'flex-start',
											gap: '16px',
											flexGrow: '0',
											width: '100%'
										}}>
										<Body as="p" size="medium" weight={400}>
											{t('military.anticipatedSeparation')}
										</Body>

										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker
												inputFormat="dd/MM/yyyy"
												label=""
												value={dateValue}
												onChange={updateDateResponse}
												renderInput={(params) => (
													<MuiTextField
														placeholder="dd/mm/yyyy"
														sx={{ width: '100% !important' }}
														{...params}
													/>
												)}
											/>
										</LocalizationProvider>
									</div>
									<div>
										<Body as="p" size="small" weight={400} UNSAFE_style={{ fontStyle: 'italic' }}>
											{t('military.example')}:11/11/2020
										</Body>
									</div>
								</div>
							)}
						</div>
					)}
					<fieldset className={commonStyles.fieldSet}>
						<legend className={commonStyles.legend}>
							<Body as="p" size="medium" weight={400}>
								{t('military.spousequestion')}
							</Body>
						</legend>
						<Radio
							label={t('military.spouseactiveduty')}
							name="activeDuty"
							onChange={(e) => {
								handlePartnerServiceDetail(e);
							}}
							checked={partnerInServices.activeDuty}
						/>				
						<Radio
							label={t('military.spouseguard')}
							name="guardOrReserve"
							onChange={(e) => {
								handlePartnerServiceDetail(e);
							}}
							checked={partnerInServices.guardOrReserve}
						/>
							<Radio
								label={t('military.spouseveteran')}
								name="veteran"
								onChange={(e) => {
									handlePartnerServiceDetail(e);
								}}
								checked={partnerInServices.veteran}
							/>
							<Radio
								label={t(militaryNo)}
								name="no"
								onChange={(e) => {
									handlePartnerServiceDetail(e);
								}}
								checked={partnerInServices.no}
							/>
							<Radio
								label={t(militaryDontWish)}
								name="dontWishToAnswer"
								onChange={(e) => {
									handlePartnerServiceDetail(e);
								}}
								checked={partnerInServices.dontWishToAnswer}
							/>
					</fieldset>
				</div>
			</FormGroup>
		</div>
	);
});

export default MilitaryService;
