export const FIELDS = {
	firstName: 'firstName',
	lastName: 'lastName',
	phoneNumber: 'phoneNumber',
	email: 'email',
	streetAddress: 'streetAddress',
	streetAddress2: 'streetAddress2',
	city: 'city',
	stateCode: 'stateCode',
	zipcode: 'zipcode',
	ssn: 'ssn',
	confirmSSN: 'confirmSSN',
	date: 'date',
	month: 'month',
	year: 'year',
};
