import React from 'react';
import { Body, Button } from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import acknowledge from '../../../../../../assets/images/acknowledge.svg';
import './style.scss';

const FeedbackAcknowledge = (props) => {
  const { t } = useTranslation();

  const handleCloseButtonClick = () => {
    props.handleClose();
  };

  return (
    <>
      <Body as="div" weight={400}>
        {t('feedback.ack')}
      </Body>
      <img src={acknowledge} alt="Feeback Acknowledge" className="feedback_ack_img" />
      <div className="ack_close_container">
        <Button
          size="medium"
          variant="primary"
          UNSAFE_style={{ width: '100%' }}
          onClick={handleCloseButtonClick}
        >
          {t('feedback.close')}
        </Button>
      </div>
    </>
  );
};

export default FeedbackAcknowledge;
