import React from 'react';
import { Body, Tag, useSnackbar } from '@walmart-web/livingdesign-components';
import styles from './GenericJobCard.module.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Icon = require('@livingdesign/icons');

const GenericJobCard = (props) => {
	const { addSnack } = useSnackbar();
	const global = useSelector((state) => state.global);
	const { t } = useTranslation();

	const getFacilityType = (i) => {
		return i.workgroupId < 5000 ? 'store' : 'warehouse';
	};
	const getTagColor = (i) => {
		const fType = getFacilityType(i);
		switch (fType) {
			case 'store':
				return 'blue';
			case 'warehouse':
				return 'purple';
			default:
				return 'blue';
		}
	};

	const getIcon = (i) => {
		const fType = getFacilityType(i);
		switch (fType) {
			case 'store':
				return <Icon.Store />;
			case 'warehouse':
				return <Icon.Truck />;
			default:
				return <Icon.Store />;
		}
	};

	return (
		<div className={styles.footerCardContainer}>
			<div>
				<img
					src={require(`../../assets/images/workgroups/${props.item.workgroupId}.svg`)}
					alt={"Job icon"}
					aria-hidden={false}
					className={styles.footerIconHolder}
				/>
			</div>
			<div className={styles.footerCardInfoHolder}>
				<div className={styles.footerCardTitleContainer}>
					<Body
						as="div"
						size="medium"
						weight={400}
						UNSAFE_style={{ width: '100%', marginRight: '10px' }}>
						{props.item.jobSelectionDesc[global.language === 'ES' ? 102 : 101] +
							(props.showCountryCode ? ' ('.concat(props.item.countryCode).concat(')') : '')}
					</Body>
					{props.remove ? (
						<div
							className={styles.footerCardLink}
							onClick={() => {
								addSnack({
									message: t('jobSelection.jobRemoved')
								});
								props.remove(props.item);
							}}>
							{t('general.remove')}
						</div>
					) : null}
				</div>

				<div className={styles.footerCardSelectContainer}>
					<div>
						<Tag color={getTagColor(props.item)} variant="secondary" leading={getIcon(props.item)}>
							{t('general.' + getFacilityType(props.item))}
						</Tag>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GenericJobCard;
