import { Body } from '@walmart-web/livingdesign-components';
import React, { useEffect } from 'react';
import './styles.scss';
import { useTranslation, Trans } from 'react-i18next';
import { useDrugScreenInfo } from 'pages/VirtualJobOffer/hooks/useDrugScreenInfo';

const ScheduleCompleted = () => {
	const { t } = useTranslation();
	const { getScheduleCompletedResponse } = useDrugScreenInfo();

	const query = new URLSearchParams(window.location.search);
	const eventId = query.get('EventID');
	const scheduleAction = query.get('scheduleAction');

	useEffect(() => {
		getScheduleCompletedResponse(scheduleAction, eventId);
	}, []);

	const handleGetScheduleCompletedApiResponse = () => {
			return (
				<Body
					children={<Trans>{t('vjoDSCheck.thanks_screen_content')}</Trans>}
					size="medium"
					UNSAFE_className="vjo_ds_thanks_content"
				/>
			);
	};

	return (
		<Body UNSAFE_className="vjo_ds_thanks_container">
			{handleGetScheduleCompletedApiResponse()}
		</Body>
	);
};
export default ScheduleCompleted;
