import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';
import ContactInfo from '../contactInfo';
import ProgressTrackerComp from '../progressTrackerComp';
import Availability from '../availability';
import Review from '../review';
import AcknowledgeOffer from '../acknowledge';
import { cloneDeep } from 'lodash';
import { useLocation } from 'react-router-dom';
import StepHeader from './StepHeader';
import Congratulations from '../congratulations/Congratulations';
import BackgroundCheck from '../backgroundCheck';
import DrugScreenCheck from '../dsCheck';
import { PAGES } from '../../../util';
import { useDeclineOffer } from '../../declineOffer/useDeclineOffer';
import DeclineMessage from '../../declineOffer/DeclineMessage';
import DeclineOffer from '../../declineOffer';

export const StepContainer = () => {
	const { state } = useLocation();
	const { onDeclineOffer, isOfferDeclined } = useDeclineOffer();

	const [currentStep, setCurrentStep] = useState(state?.step);
	const [stepStatus, setStepStatus] = useState(
		state?.isDrugScreenRequired
			? { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false }
			: { 1: false, 2: false, 3: false, 4: false, 5: false }
	);
	const [isDeclineClicked, setDeclineClick] = useState(false);
	const [totalPages, setTotalPages] = useState(state?.isDrugScreenRequired ? 6 : 5);
	useEffect(() => {
		const stepStatusUpdate = cloneDeep(stepStatus);
		Object.keys(stepStatusUpdate).forEach((key) => {
			if (key < state?.activePageFromDashboard) {
				stepStatusUpdate[key] = true;
			}
		});
		setStepStatus({ ...stepStatus, ...stepStatusUpdate });
	}, [totalPages]);

	const handleChangeNextStep = () => {
		setCurrentStep(currentStep + 1);
	};
	const handleChangePreviousStep = () => {
		setCurrentStep(currentStep - 1);
	};

	const handleChangeCurrentStepStatus = (val) => {
		const currentStepStatus = cloneDeep(stepStatus);
		currentStepStatus[currentStep] = val;
		setStepStatus({ ...stepStatus, ...currentStepStatus });
	};

	// show Decline offer
	const showDeclineOffer = useCallback(() => {
		setDeclineClick(true);
	}, []);
	// hide Decline offer
	const hideDeclineOffer = useCallback(() => {
		setDeclineClick(false);
	}, []);
	// decline offer
	const declineOffer = useCallback((reasonCode) => {
		onDeclineOffer(reasonCode);
	});

	const StepView = () => {
		if (totalPages === 6) {
			switch (currentStep) {
				case PAGES.CONTACT_INFO:
					return (
						<ContactInfo
							handleChangeCurrentStepStatus={handleChangeCurrentStepStatus}
							handleChangeNextStep={handleChangeNextStep}
							declineOffer={declineOffer}
						/>
					);
				case PAGES.AVAILABILITY:
					return <Availability handleChangeCurrentStepStatus={handleChangeCurrentStepStatus} />;
				case PAGES.REVIEW:
					return <Review handleChangeCurrentStepStatus={handleChangeCurrentStepStatus} />;
				case PAGES.ACKNOWLEDGE:
					return (
						<AcknowledgeOffer
							handleChangeCurrentStepStatus={handleChangeCurrentStepStatus}
							handleChangeNextStep={handleChangeNextStep}
						/>
					);
				case PAGES.DRUG_SCREEN_CHECK:
					return (
						<DrugScreenCheck
							handleChangeCurrentStepStatus={handleChangeCurrentStepStatus}
							handleChangeNextStep={handleChangeNextStep}
						/>
					);
				case PAGES.BACKGROUND_CHECK:
					return <BackgroundCheck handleChangeCurrentStepStatus={handleChangeCurrentStepStatus} />;
				default:
					return <Congratulations />;
			}
		} else if (totalPages === 5) {
			switch (currentStep) {
				case PAGES.CONTACT_INFO:
					return (
						<ContactInfo
							handleChangeCurrentStepStatus={handleChangeCurrentStepStatus}
							handleChangeNextStep={handleChangeNextStep}
							declineOffer={declineOffer}
						/>
					);
				case PAGES.AVAILABILITY:
					return <Availability handleChangeCurrentStepStatus={handleChangeCurrentStepStatus} />;
				case PAGES.REVIEW:
					return <Review handleChangeCurrentStepStatus={handleChangeCurrentStepStatus} />;
				case PAGES.ACKNOWLEDGE:
					return (
						<AcknowledgeOffer
							handleChangeCurrentStepStatus={handleChangeCurrentStepStatus}
							handleChangeNextStep={handleChangeNextStep}
						/>
					);
				case PAGES.BACKGROUND_CHECK - 1:
					return <BackgroundCheck handleChangeCurrentStepStatus={handleChangeCurrentStepStatus} />;
				default:
					return <Congratulations />;
			}
		}
	}

	if (isOfferDeclined) {
		return <DeclineMessage />;
	}

	return (
		<React.Fragment>
			{currentStep > 0 && currentStep <= 10 && (
				<div className="vjo_steps_main_container">
					<div className="vjo_steps_main_content_container">
						<div className="vjo_steps_header_container">
							<div className="vjo_steps_header">
								<StepHeader currentStep={currentStep} />
							</div>
						</div>
						{StepView()}
					</div>
					<ProgressTrackerComp
						currentStep={currentStep}
						stepStatus={stepStatus}
						handleChangeNextStep={handleChangeNextStep}
						handleChangePreviousStep={handleChangePreviousStep}
						showDeclineOffer={showDeclineOffer}
					/>
				</div>
			)}
			<DeclineOffer isOpen={isDeclineClicked} onClose={hideDeclineOffer} />
		</React.Fragment>
	);
};

export default StepContainer;
