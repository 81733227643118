import React from 'react';
import { Body, Button, Modal } from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';

const CutOverModal = (props) => {
	const { t } = useTranslation();

	return (
		<Modal size="small" isOpen={props.isOpen} title={t('location.notice')} onClose={props.onClose}>
			<Body as="div">{t(props.text)}</Body>
			<div style={{ paddingTop:'8px', display: 'flex', placeContent: 'flex-end' }}>
				<Button variant="primary" onClick={props.onClose}>
					{t('general.ok')}
				</Button>
			</div>
		</Modal>
	);
};

export default CutOverModal;
