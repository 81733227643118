import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	job: [],
	license: [],
	jobIndex: 0,
	licenseIndex: 0,
	editJob: false,
	allEmployers:[],
	certificationLicenses:[]
};

export const jobHistorySlice = createSlice({
	name: 'jobHistory',
	initialState,
	reducers: {
		updateJobHistory: (state, action) => {
			state.job.push(action.payload.jobDetail);
		},
		resetJobHistory: (state, action) => {
			state.job=[]
		},
		resetLicenseHistory: (state, action) => {
			state.license=[];
		},
		updateJobIndex: (state, action) => {
			state.jobIndex = action.payload.jobIndex;
		},
		updateEditJob: (state, action) => {
			state.editJob = action.payload.editJob;
		},
		updateLicense: (state, action) => {
			state.license.push(action.payload.license);
		},
		updateLicenseIndex: (state, action) => {
			state.licenseIndex = action.payload.licenseIndex;
		},
		updateJob: (state, action) => {
			state.job[state.jobIndex] = action.payload.jobDetail;
		},
		updateEditLicense: (state, action) => {
			state.license[state.licenseIndex] = action.payload.license;
			console.log(state.license)
		},
		resetAllEmployers:(state,action)=>{
			state.allEmployers=[];
		},
		updateAllEmployers:(state,action)=>{
			state.allEmployers.push(action.payload.allEmployers)
		}
	}
});

export const {
	updateJobHistory,
	updateJobIndex,
	updateEditJob,
	updateLicense,
	updateLicenseIndex,
	updateJob,
	updateEditLicense,
	updateAllEmployers,
	resetJobHistory,
	resetLicenseHistory,
	resetAllEmployers
} = jobHistorySlice.actions;

export default jobHistorySlice.reducer;
