import { Card, Grid, GridColumn, List, ListItem } from '@walmart-web/livingdesign-components';
import React, { useMemo } from 'react';
import './styles.scss';
import money from '../../../../../assets/images/Money.svg';
import Calendar from '../../../../../assets/images/Calendar.svg';
import Services from '../../../../../assets/images/Services.svg';
import Travel from '../../../../../assets/images/Travel.svg';
import { useTranslation } from 'react-i18next';
import JobInfoItem from './JobInfoItem';
import { useDisplay } from '../../../../../utils/useDisplay';
import { useVirtualJobOffer } from 'pages/VirtualJobOffer/hooks/useVirtualJobOffer';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import { useSelector } from 'react-redux';

/**
 * Renders the Job information for the Portrait and Desktop views.
 */
const JobInfoContainer = () => {
	const { t } = useTranslation();
	const { isLandscape } = useDisplay();
	const { jobOffer, isSupplyChain } = useVirtualJobOffer();
	const global = useSelector((state) => state.global);
	const formatDate = (date) => {
		var arr1 = date.split('-');
		return format(new Date(arr1[0], arr1[1]-1, arr1[2]), 'MMM. dd, yyyy', {
			locale: global.language === 'ES' ? es : enUS
		});
	};

	const JobInfoData = useMemo(() => {
		if (isEmpty(jobOffer)) {
			return [];
		}
		let employmentType = jobOffer?.employmentType;
		if (!isSupplyChain) {
			employmentType =
				jobOffer?.employmentType === 'F'
					? t('virtualJobOffer.fullTime')
					: t('virtualJobOffer.partTime');
		}
		return [
			{
				title: t('virtualJobOffer.tentativeStartDate'),
				icon: Calendar,
				info: formatDate(jobOffer?.schldDate)
			},
			{
				title: t('virtualJobOffer.hourlyRate'),
				icon: money,
				info: `$${jobOffer?.rateOfPay}${t('general./hr')}.`
			},
			{
				title: t('virtualJobOffer.jobTitle'),
				icon: Services,
				info: jobOffer?.jobName
			},
			{
				title: t('virtualJobOffer.employmentType'),
				icon: Travel,
				info: employmentType
			}
		];
	}, [jobOffer, t, isSupplyChain]);

	// Desktop View
	const DesktopView = useMemo(() => {
		return (
			<Grid hasGutter={true} className="grid">
				{JobInfoData?.length > 0 &&
					JobInfoData.map((jobDetail, index) => {
						return (
							<GridColumn sm={3} className="grid_column" key={index}>
								<JobInfoItem
									title={jobDetail?.title}
									info={jobDetail?.info}
									icon={jobDetail?.icon}
								/>
							</GridColumn>
						);
					})}
			</Grid>
		);
	}, [JobInfoData]);

	// Portrait View
	const PortraitView = useMemo(() => {
		if (JobInfoData?.length === 0) {
			return null;
		}
		return (
			<Card className="list_card">
				<List UNSAFE_className="list">
					{JobInfoData.map((jobDetail, index) => {
						return (
							<ListItem key={index}>
								<JobInfoItem
									title={jobDetail?.title}
									info={jobDetail?.info}
									icon={jobDetail?.icon}
								/>
							</ListItem>
						);
					})}
				</List>
			</Card>
		);
	}, [JobInfoData]);

	return isLandscape ? DesktopView : PortraitView;
};

export default JobInfoContainer;
