import {createSlice} from '@reduxjs/toolkit';
import {getUpdateStepIndicator} from './request';

const initialState = {data: {}, loading: false, error: {}};

const updateStepIndicatorSlice = createSlice({
  name: 'updateStepIndicator',
  initialState,
  reducers: {
    updateStepIndicatorResp: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUpdateStepIndicator.fulfilled, (state, action) => {
        state.data = action.payload.response;
      })
      .addCase(getUpdateStepIndicator.rejected, (state) => {
        state.error = {message: 'Something went wrong'};
      });
  },
});

export const {updateStepIndicatorResp} = updateStepIndicatorSlice.actions;

export default updateStepIndicatorSlice.reducer;
